import React from "react";
import {
  Container, Row, Col, Form, FormGroup, Label, Input,

} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";

import "react-notifications/lib/notifications.css";
import { Link } from "react-router-dom";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { getCountries } from "../Components/helpers/network-calls";

import rightIcon from "../images/right.png"
import wrongIcon from "../images/wrong.png"

class ApplyJob extends React.Component {
  /* add class function */
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      applyData: null,
      jobTitle: "",
      jobId: "",
      contact_methods: [
        { text: "Phone", value: "phone", isChecked: false },
        { text: "Text", value: "text", isChecked: false },
        { text: "Email", value: "email", isChecked: false },
      ],
      certifications: [],
      timings: [
        { text: "Morning", value: "Morning", isChecked: false },
        { text: "Afternoon", value: "Afternoon", isChecked: false },
        { text: "Evening", value: "Evening", isChecked: false },
      ],
      check_certifications: [],
      check_contact_methods: [],
      check_timings: [],
      firstName: "",
      lastName: "",
      code: "+1",
      countryCode: "1",
      phone: "",
      email: "",
      speciality: "",
      errors: [],
      errorsCode: {},
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phone_error: false,
      certificationsError: false,
      contact_methodsError: false,
      timingsError: false,
      codeError: false,
      specialityError: false,
      errMsg: "",
      isLoading: false,
      jobProviderId: "",
      location: "",
      startDate: "",
      endDate: "",
      shift: "",
      positions: "",
      referralSources: [],
      referralSource: "Travel Nurses, Inc. Website",
      referralBy: "N/A",
      referralSourceId: "",
      referralSourceError: false,
      referredByError: false,
      otherReferralSourceError: false,
      respStatus: false,
      recruiters: [],
      recruiter: user?.recruiter ? user?.recruiter : "",
      multipleSpecs: [],
      countries: [],
      workType: "",
      ipAddress: "",
      token: localStorage.getItem("token"),
      thanksPage: false,

      model: false,
      otp: "",
      otpError: false,
      errors: [],
      isLoading: "",
      errMsg: "",
      button_disable: false,
      spId: "",
      sendOtpButton: false,
      otpIcon: "",
      hasRecruiter: null,
      recruiterError: false // Added new state for recruiter dropdown error

    };
    this.handleContactMethod = this.handleContactMethod.bind(this);
    this.handleCertifications = this.handleCertifications.bind(this);
    this.handleTimings = this.handleTimings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    this.handleOtpInputChange = this.handleOtpInputChange.bind(this);
    this.handleRecruiterOptionChange = this.handleRecruiterOptionChange.bind(this);

  }
  handleRecruiterOptionChange(e) {
    this.setState({
      hasRecruiter: e.target.value,
      recruiter: e.target.value === "no" ? "" : this.state.recruiter, // Reset recruiter if "no" is selected
      recruiterError: false // Reset error when changing radio selection

    });
  }
  handleInputChange(e) {
    // this.setState({ [e.target.name]: e.target.value }).trimLeft(/(^\s+|\s+$)/g, '');
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
      ...(e.target.name === "recruiter" && { recruiterError: false }) // Reset error when selecting recruiter
    });
    // if (!this.state.token) {
    //   if (e.target.name === "referralSource") {
    //     if (e.target.value === "") {
    //       this.setState({ referralSourceError: true });
    //     } else {
    //       this.setState({ referralSourceError: false });
    //     }
    //   }
    //   if (e.target.name === "referralBy") {
    //     if (e.target.value === "") {
    //       this.setState({ referredByError: true });
    //     } else {
    //       this.setState({ referredByError: false });
    //     }
    //   }
    //   if (e.target.name === "otherReferralSource") {
    //     if (e.target.value === "") {
    //       this.setState({ otherReferralSourceError: true });
    //     } else {
    //       this.setState({ otherReferralSourceError: false });
    //     }
    //   }
    // }
  }

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
      //Trim Leading and Trailing
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }
  inputChangedHandlerContact = async (values) => {
    this.setState({ phone_error: false });
    this.setState(
      {
        phone: values.value,
      },
      async () => {
        if (this.state.phone.length === 10) {
          await this.handleGetUser()
        } else {
          this.setState({ sendOtpButton: false, otpIcon: '' });
        }
      }
    );
  };
  handleGetUser = async () => {
    let formData = {
      phoneNumber: this.state.phone
        .replaceAll("-", "")
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", ""),
    };
    await axios
      .post(AppConstants.API + "/nurse/applyjob/verifyPhoneNumber", formData)
      .then(async (res) => {
        console.log("------------------", res);
        if (
          res.data.Status === "Failed" &&
          res.data.phoneNumberExist === false
        ) {
          this.setState({ sendOtpButton: true, button_disable: true });
          await this.handleSendOtp();
        } else {
          this.setState({ sendOtpButton: false, button_disable: false });
        }
      });
  }

  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const componentState = this;
    let user = JSON.parse(localStorage.getItem("userInfo"));

    this.setState({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.primaryEmail : "",
      phone: user ? user.primaryPhoneNumber : "",
      speciality: user
        ? user.specialities
          .map((item) => {
            return item["ctmsSpecName"];
          })
          .join(",")
        : "",
      userCerts: user
        ? user.certifications.map((item) => {
          return item["ctmsCertName"];
        })
        : "",
    });

    if (window.innerWidth >= 320 && window.innerWidth <= 767) {
      componentState.setState({ respStatus: true });
    }
    let userId = localStorage.getItem("id");
    this.getAllCountries();
    await axios
      .post(
        AppConstants.Jobs_API + "/jobs/getJob/" + this.props.match.params.id,
        {
          nurseId: userId ? userId : "",
        }
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        console.log('data@@@@', data);
        if (data.Status == "Success" && data.data) {

          var jobData = data.data;
          componentState.setState({
            jobTitle: jobData.title,
            jobId: jobData._id,
            jobProviderId: jobData.providerJobId,
            location: jobData.city,
            startDate: jobData.start_date,
            endDate: jobData.end_date,
            shift: jobData.shift,
            positions: jobData.positions,
            workType: jobData.workType,
            speciality: jobData.speciality,
            spId: "",
            userCerts: jobData.degree,
          });
          // var _this = this
          // const { selectedSpecialities } = _this.state;
          // const newSpeciality = {
          //   label: jobData.speciality,
          //   value: jobData.speciality
          // };
          // console.log("newSpeciality", newSpeciality)
          // const updatedSpecialities = [newSpeciality];
          // componentState.setState({
          //   selectedSpecialities: updatedSpecialities
          // });

          // axios
          //   .get(AppConstants.Jobs_API + "/specialities/getReferralSources")
          //   .then((res) => {
          //     if (res.data.Status === "Success") {
          //       var dataVal = [
          //         ...res.data.data,
          //         { referralSource: "Employee Referral" },
          //         { referralSource: "Nurse Referral" },
          //       ];
          //       var output = dataVal.sort((a, b) =>
          //         a.referralSource.localeCompare(b.referralSource)
          //       );
          //       var finalArray = [...output, { referralSource: "Other" }];
          //       componentState.setState({ referralSources: finalArray });
          //     }
          //   });
        } else {
          console.log("get job failed");
        }
      });

    axios.get(AppConstants.API + "/users/getRecruitersListApp").then((res) => {
      this.setState({
        recruiters: res.data.data.sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        ),
      });
    });
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    const ipAddress = data.ip;
    this.setState({ ipAddress: ipAddress });
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }
    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "speciality") {
      if (e.target.value === "") {
        this.setState({ specialityError: true });
      } else {
        this.setState({ specialityError: false });
      }
    }
  };
  handleTimings(event) {
    var options = [];
    let timings = this.state.timings;
    timings.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.timings.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });

    this.setState({ timings: timings, check_timings: options });
  }
  handleContactMethod(event) {
    var options = [];
    let contact_methods = this.state.contact_methods;
    contact_methods.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.contact_methods.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ contact_methodsError: true });
    } else {
      this.setState({ contact_methodsError: false });
    }
    this.setState({
      contact_methods: contact_methods,
      check_contact_methods: options,
    });
  }
  handleCertifications(event) {
    var options = [];
    let certifications = this.state.certifications;
    certifications.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.certifications.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ certificationsError: true });
    } else {
      this.setState({ certificationsError: false });
    }
    this.setState({
      certifications: certifications,
      check_certifications: options,
    });
  }

  handleValidation() {
    const token = localStorage.getItem("token");
    let errors = {};
    let formIsValid = true;
    if (!token && !this.state.hasRecruiter) {
      formIsValid = false;
      errors["hasRecruiter"] = "Please select whether you have a recruiter";
    }
    if (!token && this.state.hasRecruiter === "yes" && !this.state.recruiter) {
      formIsValid = false;
      errors["recruiter"] = "Please select a recruiter from the dropdown";
      this.setState({ recruiterError: true });
    }
    // if (!this.state.token) {
    //   if (!this.state.referralBy) {
    //     formIsValid = false;
    //     errors["referredBy"] = "Please enter did someone refer you?";
    //     ReactDOM.findDOMNode(this.refs.referralBy).focus();
    //   }
    //   // if (this.state.referralSource === "Other") {
    //   //   if (!this.state.otherReferralSource) {
    //   //     formIsValid = false;
    //   //     errors["otherReferralSource"] = "Please enter other referral source";
    //   //     ReactDOM.findDOMNode(this.refs.otherReferralSource).focus();
    //   //   }
    //   // }
    //   // if (!this.state.referralSource) {
    //   //   formIsValid = false;
    //   //   errors["referralSource"] = "Please select how did you hear about us?";
    //   //   ReactDOM.findDOMNode(this.refs.referralSource).focus();
    //   // }
    //   // if(!token){
    //   //   if (!this.state.speciality) {
    //   //     formIsValid = false;
    //   //     errors["speciality"] = "Please enter your specialty.";
    //   //     // ReactDOM.findDOMNode(this.refs.speciality).focus();
    //   //   }
    //   //   if (this.state.check_certifications.length === 0) {
    //   //     formIsValid = false;
    //   //     errors["certifications"] = " Please select your certification.";
    //   //     // ReactDOM.findDOMNode(this.refs.certifications).focus();
    //   //   }
    //   // }
    // }
    if (this.state.sendOtpButton) {
      if (!this.state.otp) {
        formIsValid = false;
        errors["otp"] = "Please enter OTP.";
        // ReactDOM.findDOMNode(this.refs.otp).focus();
      }
      if (this.state.otp) {
        if (this.state.otp.length !== 4) {
          formIsValid = false;
          errors["otp"] = "Please enter valid OTP.";
          // ReactDOM.findDOMNode(this.refs.otp).focus();
        }
      }
    }
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email id.";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter your valid email address";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;
      errors["phone"] = " Please enter your valid phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    if (this.state.check_contact_methods.length === 0) {
      formIsValid = false;
      errors["contact_methods"] = "Please select preferred contact method.";
      ReactDOM.findDOMNode(this.refs.contact_methods).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter your first name.";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter your last name.";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
      certificationsError: true,
      contact_methodsError: true,
      // timingsError: true,
      // codeError : true,
      specialityError: true,
      otpError: true,
      // referralSourceError: true,
      // referredByError: true,
      // otherReferralSourceError: true,
    });
    return formIsValid;
  }

  handleSubmit(e) {
    if (this.handleValidation()) {
      this.setState({ isLoading: true, button_disable: true });
      const formData = {
        ipAddress: this.state.ipAddress,
        path: "web",
        jobTitle: this.state.jobTitle,
        jobId: this.state.jobId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        contact_methods: JSON.stringify(this.state.check_contact_methods),
        timings: JSON.stringify(this.state.check_timings),
        certifications: JSON.stringify(this.state.userCerts),
        email: this.state.email,
        phone: this.state.phone
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        code: this.state.code,
        speciality: this.state.speciality,
        jobProviderId: this.state.jobProviderId,
        location: this.state.location,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        shift: this.state.shift,
        positions: this.state.positions,
        recruiter: this.state.recruiter,
        referralBy: this.state.referralBy,
        referralSource: this.state.referralSource,
        workType: this.state.workType,
      };
      if (!this.state.sendOtpButton) {
        axios
          .post(AppConstants.Jobs_API + "/jobs/addJobSeekerDetails", formData)
          .then((res) => {
            if (res.data.Status === "Success") {
              this.setState({ button_disable: false, isLoading: false });
              // setTimeout((e) => {
              this.props.history.push("/applyjob-thank-you");
              // }, 4000);
              // setTimeout(() => {
              //   this.setState({
              //     isLoading: false,
              //     errMsg: (
              //       <div className="alert alert-success fade show mt-3">
              //         {res.data.msg}
              //       </div>
              //     ),
              //   });
              // }, 2000);
            } else {
              this.setState({ button_disable: false, isLoading: false });
              NotificationManager.error(res.data.msg, "", 5000);
              // setTimeout(() => {
              //   this.setState({
              //     isLoading: false,
              //     errMsg: (
              //       <div className="alert alert-danger fade show mt-3">
              //         {res.data.msg}
              //       </div>
              //     ),
              //   });
              // }, 1000);
              // setTimeout(() => this.setState({ errMsg: "" }), 2000);
            }
          });
      } else {
        formData["newUser"] = true
        axios
          .post(AppConstants.Jobs_API + "/jobs/addJobSeekerDetails", formData)
          .then((res) => {
            if (res.data.Status === "Success") {
              this.setState({ button_disable: false, isLoading: false });
              this.handleApplyJob();
              // setTimeout((e) => {
              this.props.history.push("/applyjob-thank-you");
              // }, 4000);
              // setTimeout(() => {
              //   this.setState({
              //     isLoading: false,
              //     errMsg: (
              //       <div className="alert alert-success fade show mt-3">
              //         {res.data.msg}
              //       </div>
              //     ),
              //   });
              // }, 2000);
            } else {
              this.setState({ button_disable: false, isLoading: false });
              NotificationManager.error(res.data.msg, "", 5000);
              // setTimeout(() => {
              //   this.setState({
              //     isLoading: false,
              //     errMsg: (
              //       <div className="alert alert-danger fade show mt-3">
              //         {res.data.msg}
              //       </div>
              //     ),
              //   });
              // }, 1000);
              // setTimeout(() => this.setState({ errMsg: "" }), 2000);
            }
          });
      }
    }
  }

  handleApplyJob = async () => {
    let formData = new FormData();
    formData.append("ipAddress", this.state.ipAddress);
    formData.append("path", "web");
    var contactInformation = {};
    contactInformation["primaryEmail"] =
      this.state.email.toLowerCase();
    contactInformation["primaryPhoneNumber"] = this.state.phone;
    formData.append("contactInformation", JSON.stringify(contactInformation));
    formData.append("firstName", this.state.firstName);
    formData.append("lastName", this.state.lastName);
    formData.append("resume", "");
    formData.append("phoneCode", this.state.countryCode);
    formData.append("recruiter", this.state.recruiter);
    formData.append("jobId", this.state.jobId);
    var referralInformation = {};
    referralInformation["referralSource"] = this.state.referralSource;
    referralInformation["referralSourceId"] = this.state.referralSourceId;
    referralInformation["referralBy"] = this.state.referralBy;
    // referralInformation["verifyOtp"] = true;
    formData.append(
      "referralInformation",
      JSON.stringify(referralInformation)
    );
    try {
      axios
        .post(AppConstants.API + "/nurse/applyjob/nurseRegistration", formData)
        .then((res) => {
          if (res.data.Status === "Failed") {
            NotificationManager.error(res.data.msg, "", 5000);
          } else {
            // NotificationManager.success(res.data.msg, "", 5000);
          }
        })
        .catch((err) => {
          if (err) {
            NotificationManager.error(err.response.data.msg, "", 5000);
          }
        });
    } catch (error) {
      console.log(error, "error");
    }
  }
  handleOtpInputChange(values) {
    this.setState({ otpError: false, otpIcon: "" });
    this.setState(
      {
        otp: values.value,
      },
      async () => {
        if (this.state.otp.length === 4) {
          await this.handleOtpSubmit();
        }
      }
    );
  }
  handleOtpSubmit() {
    const userID = localStorage.getItem("userID");
    console.log(userID, "userID");
    const formData = {
      otp: this.state.otp,
      id: userID,
    };
    axios
      .post(AppConstants.API + "/nurse/applyjob/verify/otp", formData)
      .then((res) => {
        if (res.data.Status === "Success") {
          // NotificationManager.success(res.data.msg, "", 3000);
          this.setState({ button_disable: false, otpIcon: "success" })
          // this.handleApplyJob()
          // this.props.history.push(`/set-password/${userID}`);
        } else {
          this.setState({ button_disable: true, otpIcon: "error" })
          NotificationManager.error(res.data.msg, "", 3000);
        }
      })
      .catch((err) => {
        if (err) {
          this.setState({ button_disable: true, otpIcon: "error" });
          NotificationManager.error(err.response.data.msg, "", 3000);
          // this.props.history.push("/resend-otp");
        }
      });
  }
  handleResendOtp = async () => {
    const userID = localStorage.getItem("userID");
    console.log(userID, "userID");
    const formData = {
      id: userID,
    };
    axios
      .post(AppConstants.API + "/nurse/applyjob/reSend/otp", formData)
      .then((res) => {
        if (res.data.Status === "Success") {
          NotificationManager.success(res.data.msg, "", 3000);
          // this.props.history.push(`/otp`);
        }
      })
      .catch((err) => {
        if (err) {
          NotificationManager.warning(
            "Something went wrong, please try again!",
            "",
            5000
          );
        }
      });
  }
  handleSendOtp = async () => {
    let formData = {}
    formData["ipAddress"] = this.state.ipAddress;
    formData["path"] = "web";
    formData["primaryPhoneNumber"] = this.state.phone;
    formData["phoneCode"] = this.state.countryCode;
    axios
      .post(AppConstants.API + "/nurse/applyjob/send/otp", formData)
      .then((res) => {
        if (res.data.Status === "Success") {
          localStorage.setItem("userID", res.data.id);
          // NotificationManager.success(res.data.msg, "", 5000);
          // this.props.history.push(`/otp`);
        }
      })
      .catch((err) => {
        if (err) {
          NotificationManager.warning(
            "Something went wrong, please try again!",
            "",
            5000
          );
        }
      });
  }
  render() {
    const token = localStorage.getItem("token");
    return (
      <div className="apply-form registr-info main-page">
        <NotificationContainer />
        <Container>
          <div className="registration-form">
            <div className="title">{this.state.jobTitle}</div>
            <p className="text-center">
              Ready to explore the nation while making a difference in
              healthcare? Apply now and join our team of amazing healthcare
              professionals!
            </p>
            <Form className="inner-block">
              <Row>
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="FirstName">
                      First Name
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      id="FirstName"
                      value={this.state.firstName}
                      onBlur={() => this.state.firstName}
                      onChange={this.handleChange}
                      ref="firstName"
                    />
                    <span>
                      {this.state.firstNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["firstName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>

                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="Last Name">
                      Last Name
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onBlur={() => this.state.lastName}
                      placeholder="Last Name"
                      id="LastName"
                      onChange={this.handleChange}
                      ref="lastName"
                    />
                    <span>
                      {this.state.lastNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["lastName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <div className="form-block">
                <Row>
                  <Col md={5}>
                    <h4>
                      Preferred Contact Method
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </h4>
                    <div className="check-sec">
                      {this.state.contact_methods.map((planet, index) => {
                        return (
                          <span className="form-action" id={index}>
                            <Input
                              type="checkbox"
                              name={planet.value}
                              id={planet.value}
                              onChange={this.handleContactMethod}
                              value={planet.value}
                              checked={planet.isChecked}
                              ref="contact_methods"
                            ></Input>
                            <Label for={planet.value} className="check-field">
                              {planet.text}
                            </Label>
                          </span>
                        );
                      })}
                      <div>
                        {this.state.contact_methodsError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["contact_methods"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="form-block">
                      <h4>
                        What's the best time to contact you?
                      </h4>
                      <div className="check-sec">
                        {this.state.timings.map((planet, index) => {
                          return (
                            <span className="form-action" id={index}>
                              <Input
                                type="checkbox"
                                name="timings"
                                id={planet.value}
                                onChange={this.handleTimings}
                                value={planet.value}
                                checked={planet.isChecked}
                                ref="timings"
                              ></Input>
                              <Label for={planet.value} className="check-field" check>
                                {planet.text}
                              </Label>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="codephone-blk">
                  <Col xs="1" className="block small-block country-code">
                    <FormGroup>
                      <Label for="Code">Country Code</Label>
                      <Input
                        type="select"
                        value={this.state.code}
                        name="code"
                        ref="code"
                        onChange={this.handleCodeChange}
                        id="Code"
                      >
                        {this.state.countries.map((item) => {
                          return (
                            <option
                              data-countryCode={item.phoneCode}
                              value={"+" + item.phoneCode}
                            >
                              {item.phoneCode === 1 ? "USA" : item.name}{" "}
                              {"+" + item.phoneCode}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="5" className="block widht-40 phone-input position-relative">
                    <FormGroup>
                      <Label for="Phone">
                        Phone
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="phone"
                        id="Phone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact}
                        value={this.state.phone}
                        onBlur={() => this.state.phone}
                        disabled={token}
                        className="form-control"
                        ref="phone"
                      />

                      {this.state.sendOtpButton &&
                        <p className="w-100 text-right text-primary pb-0" style={{ position: "absolute", right: 0, }}>
                          <a style={{ cursor: "pointer" }} onClick={this.handleResendOtp}><u><i>Resend OTP</i></u>
                          </a></p>}

                      {/* {this.state.sendOtpButton && <p className="w-100 text-right text-primary"><a onClick={() => this.setState({ model: true })}><u><i>Send Otp</i></u></a></p>} */}
                      <span>
                        {this.state.phone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <span className="code-validation" id="mbl-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  {/* jhani */}
                  {this.state.sendOtpButton && <Col xs="5" className="block widht-40 phone-input">
                    <FormGroup>
                      <Label for="Phone">
                        Verify Code
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="####"
                        mask=""
                        name="otp"
                        id="otp"
                        onValueChange={this.handleOtpInputChange}
                        onBlur={() => this.state.otp}
                        value={this.state.otp}
                        placeholder="Enter OTP"
                        className="form-control position-relative"
                        ref="otp"
                      />
                      <span
                        style={{ position: "absolute", right: 40, top: 42, fontSize: "18px" }}
                      >
                        {this.state.otpIcon == "success" ? <img src={rightIcon} style={{ width: "25px" }} /> : this.state.otpIcon == "error" ? <img src={wrongIcon} style={{ width: "25px" }} /> : ""}

                      </span>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span>
                          {this.state.otpError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["otp"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>}
                  {/* <Col md={12}/> */}
                  <Col xs="12" className="block mt-3" id="mail-field">
                    {!token && (
                      <FormGroup>
                        <Label>
                          Do you have a recruiter?
                          <span className="error" style={{ color: "red" }}>*</span>
                        </Label>
                        <div
                          className="check-sec"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px'
                          }}
                        >
                          <span>
                            <Input
                              type="radio"
                              name="hasRecruiter"
                              id="hasRecruiterYes"
                              value="yes"
                              checked={this.state.hasRecruiter === "yes"}
                              onChange={this.handleRecruiterOptionChange}
                            />
                            <Label for="hasRecruiterYes" className="check-field ml-3">
                              I already have a recruiter
                            </Label>
                          </span>
                          <span>
                            <Input
                              type="radio"
                              name="hasRecruiter"
                              id="hasRecruiterNo"
                              value="no"
                              checked={this.state.hasRecruiter === "no"}
                              onChange={this.handleRecruiterOptionChange}
                            />
                            <Label for="hasRecruiterNo" className="check-field ml-3">
                              I do not have a recruiter
                            </Label>
                          </span>
                        </div>
                        {!token && this.state.errors["hasRecruiter"] && (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["hasRecruiter"]}
                          </span>
                        )}
                        {/* {this.state.hasRecruiter === "yes" && (
                          <FormGroup className="apply-job-select mt-3">
                            <Label for="RecruiterSelect">
                            Select your recruiter
                              <span className="error" style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="select"
                              name="recruiter"
                              id="RecruiterSelect"
                              value={this.state.recruiter}
                              onChange={this.handleInputChange}
                            >
                              <option value="">Select</option>
                              {this.state.recruiters.map((item, index) => (
                                <option value={item.value} key={index}>
                                  {item.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        )} */}
                      </FormGroup>
                    )}
                  </Col>
                  {!token && this.state.hasRecruiter === "yes" && (<Col xs="6" className="block mt-3" id="mail-field">

                    <FormGroup className="apply-job-select">
                      <Label for="RecruiterSelect">
                        Select your recruiter
                      </Label>
                      <Input
                        type="select"
                        name="recruiter"
                        id="RecruiterSelect"
                        value={this.state.recruiter}
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select</option>
                        {this.state.recruiters.map((item, index) => {
                          return (
                            <option value={item.value} id={index}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Input>
                      {this.state.recruiterError && (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["recruiter"]}
                        </span>
                      )}
                    </FormGroup>

                    <span className="code-validation col" id="desk-validation">
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode["code"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Col>)}
                  <Col xs="6" className="block mt-3" id="mail-field">
                    <FormGroup>
                      <Label for="Email">
                        Email
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="Email"
                        value={this.state.email}
                        onBlur={() => this.state.email}
                        onChange={this.handleChange}
                        disabled={token}
                        ref="email"
                      />
                      <span>
                        {this.state.emailError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["email"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>




                </Row>
              </div>
              {/* {!token && (
                <div className="form-block">
                  <h4>
                    What is your certification?
                    <span className="error" style={{ color: "red" }}>
                      *
                    </span>
                  </h4>
                  <div className="check-sec">
                    {this.state.certifications.map((planet, index) => {
                      return (
                        <span className="form-action" id={index}>
                          <Input
                            type="checkbox"
                            name="certifications"
                            id={planet.value}
                            onChange={this.handleCertifications}
                            value={planet.value}
                            checked={planet.isChecked}
                            ref="certifications"
                          ></Input>
                          <Label for={planet.value} className="check-field">
                            {planet.text}
                          </Label>
                        </span>
                      );
                    })}
                    <div>
                      {this.state.certificationsError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["certifications"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!token && (
                <Row className="form-block">
                  <Col xs="6" className="block">
                    <FormGroup>
                      <h4>
                        What is your specialty?
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </h4>
                      <Input
                        type="text"
                        name="speciality"
                        placeholder="MedSurg, OR, ER, L&D, etc..."
                        value={this.state.speciality}
                        onBlur={() => this.state.speciality}
                        onChange={this.handleChange}
                        ref="speciality"
                      />
                      <div>
                        {this.state.specialityError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["speciality"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {!token && (
                <Row>
                  {" "}
                  <FormGroup>
                    <Label>
                      You need at least one year of recent licensed nursing
                      experience to work as a traveling nurse with TNI. Once you
                      have your license and experience, we would be excited to
                      work with you.
                    </Label>{" "}
                  </FormGroup>
                </Row>
              )} */}
              {/* {!token && <h4>How did you hear about us?</h4>} */}
              {/* {!token && (
                  <Row>
                    <Col xs="6" className="block">
                      <FormGroup className="apply-job-select">
                        <Label for="ReferralSource">
                          How did you hear about us? <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="select"
                          name="referralSource"
                          id="ReferralSource"
                          value={this.state.referralSource}
                          onChange={this.handleInputChange}
                          ref="referralSource"
                        >
                          <option value="">Select</option>
                          {this.state.referralSources.map((ele, index) => {
                            return (
                              <option id={index} value={ele.referralSource}>
                                {ele.referralSource}
                              </option>
                            );
                          })}
                        </Input>
                        <span>
                          {this.state.referralSourceError ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["referralSource"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    {this.state.referralSource === "Other" ? (
                      <Col xs="6" className="block">
                        <FormGroup>
                          <Label for="OtherReferralSource">
                            Other Referral Source
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="otherReferralSource"
                            id="OtherReferralSource"
                            placeholder="Other Referral Source"
                            value={this.state.otherReferralSource}
                            onChange={this.handleInputChange}
                            ref="otherReferralSource"
                          />
                          <span>
                            {this.state.otherReferralSourceError ? (
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["otherReferralSource"]}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="ReferralBy">
                          Did someone refer you?
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="referralBy"
                          id="ReferralBy"
                          placeholder="Referred By"
                          value={this.state.referralBy}
                          onChange={this.handleInputChange}
                          ref="referralBy"
                        />
                        <span
                          style={
                            this.state.respStatus
                              ? { color: "#46ad4d", fontSize: "11px" }
                              : { color: "#46ad4d" }
                          }
                        >
                          Please enter “n/a” if not referred by Word of Mouth.
                        </span>
                        <br />
                        <span>
                          {this.state.referredByError ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["referredBy"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                )} */}

              {/* RE INFo */}
              {/* {!token && <h4>Recruiter Information</h4>}
                {!token && (
                  <Row>
                    <Col xs="6" className="block">
                      <FormGroup className="apply-job-select">
                        <Label for="RecruiterSelect">
                          Do you have a recruiter you want to work with ?
                        </Label>
                        <Input
                          type="select"
                          name="recruiter"
                          id="RecruiterSelect"
                          value={this.state.recruiter}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Select</option>
                          {this.state.recruiters.map((item, index) => {
                            return (
                              <option value={item.value} id={index}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                )} */}
            </Form>
            <Row className="justify-content-center apply-job-btns">
              <Col md="12">
                <Link
                  to={"/job/" + this.props.match.params.id}
                  class="btn btn-prev button"
                >
                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                  Back
                </Link>
                <button
                  class="btn btn-next button"
                  title="Submit"
                  onClick={this.handleSubmit}
                  // onClick={this.state.sendOtpButton ? () => this.setState({ model: true }) : null}
                  disabled={this.state.button_disable}
                  style={{ backgroundColor: "#334ca4" }}
                >
                  Submit{" "}
                  {this.state.isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </Row>
            {this.state.errMsg}
          </div>
        </Container>
      </div>
    );
  }
}

export default ApplyJob;
