import React, { Component } from "react";
import images from "../Components/images";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
 
class ResetPassword extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      confirmPassword: "",
      password: "",
      confirmPasswordError: false,
      passwordError: false,
      errors: [],
      isLoading: false,
      errMsg: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "confirmPassword") {
      if (e.target.value === "") {
        this.setState({ confirmPasswordError: true });
      } else {
        this.setState({ confirmPasswordError: false });
      }
    }
    if (e.target.name === "password") {
      if (e.target.value === "") {
        this.setState({ passwordError: true });
      } else {
        this.setState({ passwordError: false });
      }
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter password";
      ReactDOM.findDOMNode(this.refs.password).focus();
    }
    if (this.state.password !== "") {
      if (this.state.password.length <= 6) {
        formIsValid = false;
        errors["password"] = "Password must be longer than 6 characters";
        ReactDOM.findDOMNode(this.refs.password).focus();
      }
    }
    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please enter confirm password";
      ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
    }
    if (this.state.confirmPassword) {
      if (this.state.password != this.state.confirmPassword) {
        formIsValid = false;
        errors["confirmPassword"] =
          "Your password and confirmation password do not match.";
        ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
      }
    }

    this.setState({
      errors: errors,
      confirmPasswordError: true,
      passwordError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      const formData = {
        password: this.state.password,
        id: this.props.match.params.id,
      };
      axios
        .post(AppConstants.API + "/nurse/setNursePassword", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            setTimeout((e) => {
              this.props.history.push("/login");
            }, 2000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
          }
        });
    }
  }
  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <Container className="inner-block">
          <Row>
            <Col xs="6" className="block left-block">
              <figure className="pic image-fill">
                {images.map((e, idx) => (
                  <img
                    // src={e.LoginPic}
                    src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                    alt={"Reset Password - Travel Nurses Inc - " + idx}
                  />
                ))}
              </figure>
            </Col>
            <Col xs="6" className="block right-block">
              <div className="form-sec text-center">
                <div className="title">Reset Password</div>
                {/* <p>Enter your details below to continue</p> */}
                <Form className="form-block" onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label>New Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="Password"
                      placeholder="Password"
                      onChange={this.handleInputChange}
                      ref="password"
                    />
                    <div>
                      {this.state.passwordError ? (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          {this.state.errors["password"]}
                        </span>
                      ) : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirm New Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm New Password"
                      ref="confirmPassword"
                      onChange={this.handleInputChange}
                    />
                    <div>
                      {this.state.confirmPasswordError ? (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          {this.state.errors["confirmPassword"]}
                        </span>
                      ) : null}
                    </div>
                  </FormGroup>
                  <Button className="button" onClick={this.handleSubmit}>
                    Submit
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image - Travel Nurses Inc"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                  {/* <a href="#" className="forget-psw">
                    Forget Password
                  </a> */}
                  {this.state.errMsg}
                </Form>

                {/* <div className="account-reg">
                  Don't have any account yet? <Link to="/registration">Register here</Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResetPassword;
