import React  from "react";
import { Container  } from "reactstrap";
// import images from "../Components/images";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class Jobdemandblock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialities: [],
      specialtyLoader: false,
    };
  }

  componentDidMount() {
    const componentState = this;
    let defaultSpecialities = {
      ER: {
        name: "Emergency Room",
        image: require("../images/speciality-icons/emergency-room.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "ICU/CCU": {
        name: "Intensive Care Unit / Critical Care Unit",
        image: require("../images/speciality-icons/icu.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      ORRN: {
        name: "Operating Room RN",
        image: require("../images/speciality-icons/operating-room.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "Med-Surg/Tele": {
        name: "Medsurg / Telemetry",
        image: require("../images/speciality-icons/med-surg.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "PACU/RR": {
        name: "Post Anesthesia Care Unit",
        image: require("../images/speciality-icons/post-anesthesia.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "Certified Respiratory Therapist": {
        name: "Certified Respiratory Therapist",
        image: require("../images/speciality-icons/respiratory-icon.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "L&D": {
        name: "Labor & Delivery",
        image: require("../images/speciality-icons/labour-delivery.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "Cardiac Cath Lab": {
        name: "Cath Lab",
        image: require("../images/speciality-icons/cath-lab.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      "OR/CVOR": {
        name: "Cardiovascular Operating Room",
        image: require("../images/speciality-icons/Cardiovascular.png"),
        jobsCount: 0,
        speciality_name: "",
      },
      Stepdown: {
        name: "Stepdown",
        // image: require("../images/speciality-icons/ccu.png"),
        image: require("../images/speciality-icons/stepdown1.png"),
        jobsCount: 0,
        speciality_name: "",
      },
    };

    axios
      .get(AppConstants.Jobs_API + "/specialities/getSpecialitiesWithJobsCount")
      // .get(AppConstants.Jobs_API + "/ctms/getSpecialities")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        // console.log(data.data)
        if (data.Status == "Success" && data.data) {
          data.data.forEach((speciality) => {
            if (defaultSpecialities[speciality.speciality]) {
              var obj = defaultSpecialities[speciality.speciality];
              obj.jobsCount = speciality.jobsCount;
              obj.speciality_name = speciality.speciality;
              defaultSpecialities[speciality.speciality] = obj;
            }
          });
          componentState.setState({
            specialities: Object.values(defaultSpecialities),
            specialtyLoader: true,
          });
        } else {
          console.log("specialities data failed");
        }
      });
  }

  render() {
    const settings = {
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },

        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { specialities } = this.state;
    return (
      <div className="jobdemonad pad-tb text-center">
        <Container>
          <h1 className="title">{AppConstants.Jobs_In_Demand}</h1>
          <p>{AppConstants.Sub_Jobs_In_Demand}</p>
          {this.state.specialtyLoader === true ? (
            <div className="job-section">
              <div className="inner-content row justify-content-center">
                {specialities.map((speciality) => (
                  <div className="job-list block-box box-hover">
                    <figure className="pic">
                      <img src={speciality.image} alt = {speciality.name+ " - Travel Nurses Inc"}/>
                    </figure>
                    <div className="text">
                      <h4>{speciality.name}</h4>
                      {/* <span>{speciality.jobsCount}</span> */}
                    </div>
                    <Link
                      to={{
                        // pathname: "/jobs",
                        pathname: "/",
                        state: { speciality: speciality.speciality_name },
                      }}
                      className="link"
                    ></Link>
                  </div>
                ))}
              </div>
              <div className="responsive-sec">
                <Slider {...settings}>
                  {this.state.specialities.map((speciality) => {
                    return (
                      <item>
                        <div className="job-list block-box box-hover">
                          <figure className="pic">
                            <img src={speciality.image} alt = {speciality.name+" - Travel Nurses Inc"}/>
                          </figure>
                          <div className="text">
                            <h4>{speciality.name}</h4>
                            {/* <span>{speciality.jobsCount}</span> */}
                          </div>
                          <Link
                            to={{
                              // pathname: "/jobs",
                              pathname: "/",
                              state: { speciality: speciality.speciality_name },
                            }}
                            className="link"
                          ></Link>
                        </div>
                      </item>
                    );
                  })}
                </Slider>
              </div>
            </div>
          ) : (
            <div className="inner-content justify-content-center">
              <img
                src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                alt="No Image"
                className="loader-center"
                style={{ width: 80, height: 80 }}
              />
            </div>
          )}
        </Container>
      </div>
    );
  }
}
export default Jobdemandblock;
