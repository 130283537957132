import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardBody,
} from "reactstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import AppConstants from "../AppConstants";
import axios from "axios";
import styles from "../Styles/registration.css";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
 
import { Dropdown, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactAutocomplete from "react-autocomplete";
import { getCountries } from "../Components/helpers/network-calls";

class UserPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applyData: null,
      jobTitle: "",
      jobId: "",
      contact_methods: [
        { text: "Phone", value: "phone", isChecked: false },
        { text: "Text", value: "text", isChecked: false },
        { text: "Email", value: "email", isChecked: false },
      ],
      timings: [
        { text: "Morning", value: "Morning", isChecked: false },
        { text: "Afternoon", value: "Afternoon", isChecked: false },
        { text: "Evening", value: "Evening", isChecked: false },
      ],

      check_certifications: [],
      check_contact_methods: [],
      check_timings: [],
      firstName: "",
      lastName: "",
      code: "+1",
      phone: "",
      email: "",
      speciality: "",
      errors: {},
      errorsCode: {},
      codeError: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phone_error: false,
      contact_methodsError: false,
      timingsError: false,
      codeError: false,
      errMsg: "",
      isLoading: false,
      items: [],
      location: "",
      questions: "",
      selectedSpecialities: [],
      specialityCopy: [],
      specialitiesDuplicate: [],
      specialityOriginal: [],
      selectedCerts: [],
      certsOriginal: [],
      certifications: [],
      certification: [],
      certsDuplicate: [],
      certsCopy: [],
      emailCheck: false,
      phoneCheck: false,
      textCheck: false,
      specialityDropdown: "",
      dropdownStatus: false,
      certDropdown: "",
      button_disable: false,
      state: [],
      states: [],
      stateLoading: true,
      stateCopy: [],
      statesDuplicate: [],
      statesOriginal: [],
      stateDropdown: "",
      showLoc: false,
      selectedStates: [],
      cities: [],
      value1: "",
      searchRadius: "",
      countries: [],
    };
    this.handleContactMethod = this.handleContactMethod.bind(this);
    this.handlesearchRadius = this.handlesearchRadius.bind(this);
    this.handleTimings = this.handleTimings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.toggleOpenCertification = this.toggleOpenCertification.bind(this);
    this.handleCertChange = this.handleCertChange.bind(this);
    this.handleSpecChange = this.handleSpecChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);

    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }
  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }

  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value,
    });
    if (values.value.length === 10) {
      this.setState({ phone_error: false });
    }
  };
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleCityPop(e) {
    this.setState({ showLoc: false, citySearch: this.state.value1 });
  }
  handleStatePop(e) {
    this.setState({ showLoc: true, citySearch: "" });
  }
  toggleOpenState() {
    this.setState({ stateDropdown: true });
  }
  handleStateBlur() {
    this.toggleClosedState();
  }
  toggleClosedState() {
    this.setState({ stateDropdown: false });
  }
  toggleOpenCity() {
    if (this.state.selectedStates.length > 1) {
      this.setState({ cityDropdown: false });
    } else {
      this.setState({ cityDropdown: true });
    }
  }
  handleAutoloadCity(e) {
    this.setState({ value1: e.target.value, city1: e.target.value });
    if (e.target.value.length < 3) {
      this.setState({ cities: [] });
    } else {
      let searchfilterdata = {
        searchtext: e.target.value,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getAllCitiesWithAutoLoadAndJobCount",
          searchfilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({
              cities: [
                {
                  _id: "All",
                  name: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
  }


  handleContactMethod(event) {
    var options = [];
    let contact_methods = this.state.contact_methods;
    contact_methods.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.contact_methods.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ contact_methodsError: true });
    } else {
      this.setState({ contact_methodsError: false });
    }
    this.setState({
      contact_methods: contact_methods,
      check_contact_methods: options,
    });
  }
  handleAutoLoadSelect(val) {
    if (val) {
      this.setState({ value1: val });
      if (this.state.showLoc) {
        this.setState({ citySearch: "" });
      } else {
        this.setState({ citySearch: val });
      }
    }
  }
  handleStateFocus() {
    if (this.state.selectedStates.length === 0) {
      this.setState({
        states: this.state.statesOriginal,
        stateCopy: this.state.statesOriginal,
        statesDuplicate: this.state.statesOriginal,
      });
    } else {
      this.setState({
        states: this.state.states,
        stateCopy: this.state.states,
        statesDuplicate: this.state.states,
      });
    }
  }
  handleStateChange(selectedList, event) {
    if (event.action === "select-option") {
      if (event.option.value === "All") {
        selectedList = selectedList.filter((o) => o.value === "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      } else if (event.option.value === "Compact") {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value === "Compact");
      } else if (
        event.option.value !== "All" ||
        event.option.value !== "Compact"
      ) {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      }
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.states.length - 1) {
      selectedList = this.state.states;
    } else {
      selectedList = selectedList;
    }
    this.setState({ state: selectedList });

    if (selectedList.length > 1) {
      this.setState({ cityDropdown: false, selectedCities: [] });
    }

    let stateSel = [];
    selectedList.map((item, index) => {
      stateSel.push(item);
    });
    this.state.stateCopy.map((item, index) => {
      if (stateSel.includes(item)) {
      } else {
        stateSel.push(item);
      }
    });
    var result = stateSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      states: result,
      state: selectedList,
      selectedStates: selectedList,
      statesDuplicate: result,
    });
    if (selectedList.length > 0) {
      this.setState({ states1: [] });
    }
  }
  handlesearchRadius(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    this.getAllCountries();
    axios
      .get(AppConstants.Jobs_API + "/certification/getAllCertifications")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          var originalArray = [
            {
              _id: "All",
              certification: "All",
              label: "All",
              value: "All",
            },
            ...data.data,
          ];
          var certVal = [];
          if (this.props.selectedCerts.length > 0) {
            this.props.selectedCerts.map((item) => {
              if (item.value) {
                certVal.push(item.value);
              } else {
                certVal.push(item);
              }
            });
          }

          if (certVal.length > 0) {
            const myArrayFiltered = originalArray.filter((array) =>
              certVal.some((filter) => filter === array.value)
            );

            if (myArrayFiltered.length > 0) {
              this.setState({
                selectedCerts: myArrayFiltered,
                certification: myArrayFiltered,
                certifications: originalArray,
                certsOriginal: originalArray,
              });
              this.certFunction(this.props.selectedCerts);
            }
          } else {
            this.setState({
              certifications: originalArray,
              certsOriginal: originalArray,
            });
          }
        }
      });
    axios
      .get(AppConstants.Jobs_API + "/specialities/getAllSpecialities")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          var originalArray = [
            { _id: "All", speciality: "All", label: "All", value: "All" },
            ...data.data,
          ];
          var specialityVal = [];
          if (this.props.selectedSpecialities) {
            this.props.selectedSpecialities.map((item) => {
              if (item.value) {
                specialityVal.push(item.value);
              } else {
                specialityVal.push(item);
              }
            });
          }
          if (specialityVal.length > 0) {
            const myArrayFiltered = originalArray.filter((array) =>
              specialityVal.some((filter) => filter === array.value)
            );
            if (myArrayFiltered.length > 0) {
              this.setState({
                selectedSpecialities: myArrayFiltered,
                value: myArrayFiltered,
                specialityOriginal: originalArray,
                items: originalArray,
              });
              this.specialityFunction(this.props.selectedSpecialities);
            }
          } else {
            this.setState({
              specialityOriginal: originalArray,
              items: originalArray,
            });
          }
        }
      });
    axios.get(AppConstants.Jobs_API + "/jobs/getAllStates").then((res) => {
      if (res.data.Status === "Success") {
        var originalArray = [
          { _id: "All", code: "All", label: "All", value: "All" },
          {
            _id: "Compact",
            code: "Compact",
            label: "Compact",
            value: "Compact",
          },
          ...res.data.data,
        ];
        var stateVal = [];
        if (this.props.selectedStates) {
          this.props.selectedStates.map((item) => {
            if (item.code) {
              stateVal.push(item.code);
            } else {
              stateVal.push(item);
            }
          });
        }
        if (stateVal.length > 0) {
          const myArrayFiltered = originalArray.filter((array) =>
            stateVal.some((filter) => filter === array.code)
          );
          let stateSel = [];
          myArrayFiltered.map((item, index) => {
            stateSel.push(item);
          });
          originalArray.map((item, index) => {
            if (stateSel.includes(item)) {
            } else {
              stateSel.push(item);
            }
          });
          var result = stateSel.reduce((unique, o) => {
            if (!unique.some((obj) => obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
          }, []);
          if (myArrayFiltered.length > 0) {
            this.setState({
              selectedStates: myArrayFiltered,
              showLoc: true,
              state: myArrayFiltered,
              states: result,
            });
          }
        }
        this.setState({
          statesOriginal: [
            {
              _id: "All",
              code: "All",
              label: "All",
              value: "All",
            },
            {
              _id: "Compact",
              code: "Compact",
              label: "Compact",
              value: "Compact",
            },
            ...res.data.data,
          ],
          stateLoading: false,
        });
      }
    });
    if (
      (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
    ) {
      this.setState({ dropdownStatus: true });
    }

    if (this.props) {
      if (this.props.city) {
        this.setState({ city: this.props.city, value1: this.props.city });
      }
      if (this.props.searchRadius) {
        this.setState({ searchRadius: this.props.searchRadius });
      }
    }
  }
  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""), //Trim Leading and Trailing
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }
    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    // if (e.target.name === "speciality") {
    //   if (e.target.value === "") {
    //     this.setState({ specialityError: true });
    //   } else {
    //     this.setState({ specialityError: false });
    //   }
    // }
    // if (e.target.name === 'code') {
    //   if (e.target.value === '') {
    //     this.setState({codeError:true});
    //   } else {
    //     this.setState({codeError:false})
    //   }
    // }
  };

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }

  handleTimings(event) {
    var options = [];
    let timings = this.state.timings;
    timings.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.timings.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ timingsError: true });
    } else {
      this.setState({ timingsError: false });
    }
    this.setState({ timings: timings, check_timings: options });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (this.state.check_timings.length === 0) {
      formIsValid = false;
      errors["timings"] = "Please select the best time to contact you";
      ReactDOM.findDOMNode(this.refs.timings).focus();
    }

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }

    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }

    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Please enter 10 digits phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter last name";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter first name";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (this.state.check_contact_methods.length === 0) {
      formIsValid = false;
      errors["contact_methods"] = "Please select at least one preferred contact method.";
      if (this.refs.contact_methods) {
        ReactDOM.findDOMNode(this.refs.contact_methods).focus();
    }
  }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
      // certificationsError: true,
      contact_methodsError: true,
      timingsError: true,
      // codeError : true,
      // specialityError: true,
      // stateError:true,
      referralSourceError: true,
      referredByError: true,
      otherReferralSourceError: true,
    });
    return formIsValid;
  }
  handleCertFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedCerts.length === 0
    ) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    } else if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedCerts.length !== 0
    ) {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }
  handleSpecialityFocus() {
    if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedSpecialities.length === 0
    ) {
      this.setState({
        items: this.state.specialityOriginal,
        specialitiesDuplicate: this.state.specialityOriginal,
        specialityCopy: this.state.specialityOriginal,
      });
    } else if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedSpecialities.length !== 0
    ) {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  handleSpecChange(value, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === this.state.items.length - 1) {
      value = this.state.items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });

    this.state.specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });

    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      items: result,
      value: value,
      selectedSpecialities: value,
      specialitiesDuplicate: result,
    });
    this.specialityFunction(value);
  }
  specialityFunction(selectedList) {
    var specVal = [];
    selectedList.map((item) => {
      if (item.value) {
        specVal.push(item.value);
      } else {
        specVal.push(item);
      }
    });

    let tablefilterdata = {
      specialities: specVal,
    };
    if (selectedList.length === 0) {
      this.setState({ certifications: this.state.certsOriginal });
    } else if (selectedList.length > 0) {
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getCertsBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                certifications: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                certsCopy: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCerts.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCerts: myArrayFiltered });
              }
            }
          }
        });
    }
  }
  handleCertChange(selectedList, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.certifications.length - 1) {
      selectedList = this.state.certifications;
    } else {
      selectedList = selectedList;
    }

    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    this.state.certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.certFunction(selectedList);
    this.setState({
      certifications: result,
      certification: selectedList,
      selectedCerts: selectedList,
      certsDuplicate: result,
    });
  }
  certFunction(selectedList) {
    var certVal = [];
    selectedList.map((item) => {
      if (item.value) {
        certVal.push(item.value);
      } else {
        certVal.push(item);
      }
    });
    let tablefilterdata = {
      certs: certVal,
    };
    if (selectedList.length === 0) {
      this.setState({ items: this.state.specialityOriginal });
    } else if (selectedList.length > 0) {
      axios
        .post(
          AppConstants.Jobs_API +
          "/specialities/getSpecsWithJobsCountBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                items: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityCopy: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedSpecialities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedSpecialities: myArrayFiltered });
              }
            }
          }
        });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      var specArray = [];
      if (this.state.selectedSpecialities.length > 0) {
        this.state.selectedSpecialities.map((item, index) => {
          specArray.push(item.value);
        });
      }
      var certArray = [];
      if (this.state.selectedCerts.length > 0) {
        this.state.selectedCerts.map((item, index) => {
          certArray.push(item.value);
        });
      }
      var cityName = [];
      if (this.state.value1) {
        cityName = this.state.value1.split(",");
      }
      var stateArray = [];
      if (this.state.selectedStates.length > 0) {
        this.state.selectedStates.map((item, index) => {
          stateArray.push(item.value);
        });
      }
      let formData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        speciality: JSON.stringify(specArray),
        certification: JSON.stringify(certArray),
        phone: this.state.phone
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        code: this.state.code,
        city: cityName[0],
        searchRadius: this.state.searchRadius,
        state: JSON.stringify(stateArray),
        contact_methods: JSON.stringify(this.state.check_contact_methods),
        timings: JSON.stringify(this.state.check_timings),
        questions: this.state.questions,
        title: this.props.title,
      };

      axios
        .post(
          AppConstants.Jobs_API + "/jobs/sendMailToContactRecruiter",
          formData
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            setTimeout((e) => {
              this.handleCancel();
            }, 3000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  handleCancel(e) {
    this.props.handleUserModelPopup();
    this.setState({
      firstName: "",
      phone: "",
      isLoading: false,
    });
  }
  render() {
    // console.log("this.props",this.props)
    return (
      <Modal
        isOpen={this.props.showUserPopUp}
        toggle={this.props.handleUserModelPopup}
        className="modal-lg recruiter-modal request-job-sec-modal"
        id="Tn-popup"
        style={{ color: "#25408f", fontWeight: "500" }}
      >
        <ModalHeader
          toggle={this.handleCancel}
          className="text-center pt-4 pb-1"
          style={{ color: "#25408f" }}
        >
          <h2>Subscribe for Job Alerts</h2>
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="apply-form registr-info main-page">
            <Card
              className="w-100 p-0 m-0 md-pop pb-5"
              style={{ borderRadius: "0px 0px 25px 25px" }}
            >
              <CardBody>
                <Form className="inner-block" onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="FirstName">
                          First Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          id="FirstName"
                          value={this.state.firstName}
                          onBlur={() => this.state.firstName}
                          onChange={this.handleChange}
                          ref="firstName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.firstNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["firstName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="Last Name">
                          Last Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          value={this.state.lastName}
                          onBlur={() => this.state.lastName}
                          placeholder="Last Name"
                          id="LastName"
                          onChange={this.handleChange}
                          ref="lastName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.lastNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["lastName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="">
                    <h2>
                      Search Criteria <span>*</span>
                    </h2>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-input multi-select-field">
                      <div
                        onClick={() => {
                          this.toggleOpenCertification();
                        }}
                      >
                        <label>Certification</label>
                        <ReactMultiSelectCheckboxes
                          options={this.state.certifications}
                          value={this.state.selectedCerts}
                          onChange={(value, event) => {
                            this.handleCertChange(value, event);
                          }}
                          isSearchable={true}
                          // placeholderButtonLabel="Certification"
                          placeholderButtonLabel="All"
                          onFocus={this.handleCertFocus.bind(this)}
                          menuIsOpen={
                            this.state.dropdownStatus === true
                              ? ""
                              : this.state.certDropdown
                          }
                          onBlur={this.handleCertBlur.bind(this)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-12 form-input multi-select-field">
                      <div
                        onClick={() => {
                          this.toggleOpenSpeciality();
                        }}
                      >
                        <label>Specialty</label>
                        <ReactMultiSelectCheckboxes
                          options={this.state.items}
                          value={this.state.selectedSpecialities}
                          onChange={(value, event) => {
                            this.handleSpecChange(value, event);
                          }}
                          isSearchable={true}
                          placeholderButtonLabel="All"
                          onFocus={this.handleSpecialityFocus.bind(this)}
                          menuIsOpen={
                            this.state.dropdownStatus === true
                              ? ""
                              : this.state.specialityDropdown
                          }
                          onBlur={this.handleSpecialityBlur.bind(this)}
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-6 col-md-4 col-sm-12 pt-3 pt-md-0 pt-lg-0"
                      id="location-filter"
                    >
                      <label>Location</label>
                      <Dropdown autoClose={false}>
                        <Dropdown.Toggle className="css-1r4vtzz all-bttn-filter">
                          <b calssName="city">
                            {" "}
                            {this.state.showLoc
                              ? this.state.selectedStates.length > 0
                                ? this.state.selectedStates.map(
                                  (item, index) => {
                                    return (
                                      <span>
                                        {" "}
                                        {this.state.selectedStates.length -
                                          1 ===
                                          index
                                          ? item.code
                                          : item.code + ", "}
                                      </span>
                                    );
                                  }
                                )
                                : "All"
                              : this.state.value1
                                ? // this.state.value1
                                this.state.value1 +
                                ` - ${this.state.searchRadius}m`
                                : "All"}
                          </b>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Card>
                            <Card.Body>
                              <Button
                                variant="light"
                                onClick={this.handleCityPop.bind(this)}
                                className={
                                  !this.state.showLoc
                                    ? "location-bttn active"
                                    : "location-bttn"
                                }
                              >
                                City
                              </Button>
                              <Button
                                variant="light"
                                onClick={this.handleStatePop.bind(this)}
                                className={
                                  this.state.showLoc
                                    ? "location-bttn active"
                                    : "location-bttn"
                                }
                              >
                                State
                              </Button>
                              {this.state.showLoc ? (
                                <div
                                  className="finder"
                                  onClick={() => {
                                    this.toggleOpenState();
                                  }}
                                >
                                  <ReactMultiSelectCheckboxes
                                    options={this.state.states}
                                    value={this.state.selectedStates}
                                    onChange={(value, event) => {
                                      this.handleStateChange(value, event);
                                    }}
                                    isSearchable={true}
                                    // placeholderButtonLabel="State"
                                    placeholderButtonLabel="All"
                                    onFocus={this.handleStateFocus.bind(this)}
                                    menuIsOpen={
                                      this.state.dropdownStatus === true
                                        ? ""
                                        : this.state.stateDropdown
                                    }
                                    onBlur={this.handleStateBlur.bind(this)}
                                  />
                                </div>
                              ) : (
                                <span>
                                  <div
                                    className={
                                      this.state.cities.length > 0
                                        ? "finder city-finder"
                                        : ""
                                    }
                                    onClick={() => {
                                      // this.toggleOpenCity();
                                    }}
                                  >
                                    <ReactAutocomplete
                                      className="test"
                                      items={[...this.state.cities]}
                                      shouldItemRender={(item, value1) =>
                                        item.label
                                          .toLowerCase()
                                          .indexOf(value1.toLowerCase()) > -1
                                      }
                                      getItemValue={(item) => item.value}
                                      renderItem={(item, highlighted) => (
                                        <div
                                          className={
                                            this.state.value1.length >= 3
                                              ? "list"
                                              : ""
                                          }
                                          key={item.id}
                                          style={{
                                            backgroundColor: highlighted
                                              ? "#eee"
                                              : "transparent",
                                          }}
                                        >
                                          {item.label}
                                        </div>
                                      )}
                                      inputProps={{ placeholder: "City" }}
                                      value={this.state.value1}
                                      onChange={this.handleAutoloadCity.bind(
                                        this
                                      )}
                                      onSelect={this.handleAutoLoadSelect.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                  <br />
                                  <div className="city-miles">
                                    <Input
                                      type="select"
                                      value={this.state.searchRadius}
                                      name="searchRadius"
                                      onChange={this.handlesearchRadius}
                                      className={
                                        this.state.city === ""
                                          ? "form-filed radius disabled"
                                          : "form-filed radius test"
                                      }
                                      disabled={
                                        this.state.city === "" &&
                                          this.state.state === ""
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="20" className="optionname">
                                        Within 20 miles
                                      </option>
                                      <option value="50" className="optionname">
                                        Within 50 miles
                                      </option>
                                      <option
                                        value="100"
                                        className="optionname"
                                      >
                                        Within 100 miles
                                      </option>
                                      <option
                                        value="250"
                                        className="optionname"
                                      >
                                        Within 250 miles
                                      </option>
                                    </Input>
                                  </div>
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Row>
                  <Row>
                    <h2>
                      Contact Method <span>*</span>
                    </h2>
                    <div class="block small-block col-1">
                      <FormGroup>
                        <Label for="Code">
                          Country Code
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="select"
                          value={this.state.code}
                          name="code"
                          ref="code"
                          onChange={this.handleCodeChange}
                          id="Code"
                        >
                          {this.state.countries.map((item) => {
                            return (
                              <option
                                data-countryCode={item.phoneCode}
                                value={"+" + item.phoneCode}
                              >
                                {item.phoneCode === 1 ? "USA" : item.name}{" "}
                                {"+" + item.phoneCode}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </div>
                    <div class="block widht-40 col-5 phone-input">
                      <FormGroup>
                        <Label for="Phone">
                          Phone
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <NumberFormat
                          format="(###) ###-####"
                          mask=""
                          name="phone"
                          placeholder="Phone Number"
                          onValueChange={this.inputChangedHandlerContact}
                          value={this.state.phone}
                          onBlur={() => this.state.phone}
                          className="form-control"
                          ref="phone"
                        />
                        <span>
                          {this.state.phone_error === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["phone"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                    <span className="code-validation" id="mbl-validation">
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode["code"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <div class="block col-5">
                      <FormGroup>
                        <Label for="Email">
                          Email
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="email"
                          value={this.state.email}
                          onBlur={() => this.state.email}
                          onChange={this.handleChange}
                          ref="email"
                          autoComplete="off"
                          className="w-100"
                        />
                        <span>
                          {this.state.emailError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["email"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                  </Row>
                  <span className="code-validation col" id="desk-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>


                  <div className="form-block">
                    <h4>
                      Preferred Contact Method
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </h4>
                    <div className="check-sec">
                      {this.state.contact_methods.map((planet, index) => {
                        return (
                          <span className="form-action" id={index}>
                            <Input
                              type="checkbox"
                              name="contact_methods"
                              id={planet.value}
                              onChange={this.handleContactMethod}
                              value={planet.value}
                              checked={planet.isChecked}
                              ref="contact_methods"
                            ></Input>
                            <Label for={planet.value} className="check-field">
                              {planet.text}
                            </Label>
                          </span>
                        );
                      })}
                      <div>
                        {this.state.contact_methodsError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["contact_methods"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-block">
                    <h4>
                      What's the best time to contact you?
                      <span className="error" style={{ color: "red", }}>
                        *
                      </span>
                      <Label style={{ paddingTop: "6px", }}>
                        We will only contact you during your preferred time.{" "}
                      </Label>
                    </h4>
                    <div className="check-sec">
                      {this.state.timings.map((planet, index) => {
                        return (
                          <span className="form-action" id={index}>
                            <Input
                              type="checkbox"
                              name="timings"
                              onChange={this.handleTimings}
                              value={planet.value}
                              checked={planet.isChecked}
                              ref="timings"
                            ></Input>
                            <Label className="check-field">{planet.text}</Label>
                          </span>
                        );
                      })}
                      <div>
                        {this.state.timingsError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["timings"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="check-sec codephone-blk row">
                    <div className="block col">
                      <h5 style={{ color: "#25408f" }}>
                        Questions or Comments
                      </h5>
                      <div className="check-sec">
                        <span className="">
                          <Input
                            type="textarea"
                            name="questions"
                            onChange={this.handleChange}
                            value={this.state.questions}
                            ref="questions"
                            placeholder="Questions or Comments"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="btn-block">
                    <Label>
                      Click on Subscribe to receive alerts when we have jobs
                      meeting your specifications.
                    </Label>
                    <Button
                      value="Submit"
                      className="btn-reg"
                      disabled={this.state.button_disable}
                      style={{ backgroundColor: "#334ca4" }}
                    >
                      Subscribe
                    </Button>
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.errMsg}
                </Form>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default UserPopup;
