import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";
import styles from "../Styles/search-job.css";
import SearchArea from "../Components/searcharea";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import $ from "jquery";
import UserPopup from "./user-pop-up";
import Leaflet from "./Leaflet";
import { BiBookmark } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { FaClone } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ContactRecruiterPopUp from "../Pages/contact_recruiter";
import Fire from "../Components/images.js";
import queryString from "query-string";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

var refreshVal = "";
if (window.performance) {
  if (performance.navigation.type == 1) {
    refreshVal = "val";
  } else {
    refreshVal = "";
  }
}
class Searchjobs extends React.Component {
  constructor(props) {
    // console.log('props', props);
    super(props);
    const workItem = localStorage.getItem("workType");
    // const type = localStorage.getItem("type");
    const query1 = queryString.parse(this.props.location.search);
    // console.log("this.props.location.search-------", query1)
    this.state = {
      jobList: [],
      jobListdata: false,
      currentPage: 1,
      pageSizes: ["5", "10", 20, 50, 100],
      selectedPageSize: 10,
      selectedOrderOption: "",
      searchText: "",
      speciality: "",
      location: "",
      state: "",
      city: "",
      cityval: "",
      cityState: "",
      shift: [],
      searchRadius: "20",
      searchPlaceHolder: "Search",
      stateDuplicate: "",
      totalItemCount: "",
      specialities: [],
      cities: [],
      states: [],
      check_duration: [],
      shiftDropdown: "",
      durationDropdown: "",
      hotJobsFlag: false,
      strData: [],
      type:
        query1 && query1.workType ? query1.workType : workItem ? workItem : "1",
      items: [
        { label: "8 Hr Days", value: "8 Hr Days" },
        { label: "8 Hr Nights", value: "8 Hr Nights" },
        { label: "10 Hr Days", value: "10 Hr Days" },
        { label: "10 Hr Nights", value: "10 Hr Nights" },
        { label: "12 Hr Days", value: "12 Hr Days" },
        { label: "12 Hr Nights", value: "12 Hr Nights" },
        { label: "Evenings", value: "Evenings" },
        { label: "Varied/Rotating", value: "Varied/Rotating" },
      ],
      items1: [
        { label: "Day Shift", value: "Day Shift" },
        { label: "Night Shift", value: "Night Shift" },
      ],
      itemsDuplicates: [
        { label: "8 Hr Days", value: "8 Hr Days" },
        { label: "8 Hr Nights", value: "8 Hr Nights" },
        { label: "10 Hr Days", value: "10 Hr Days" },
        { label: "10 Hr Nights", value: "10 Hr Nights" },
        { label: "12 Hr Days", value: "12 Hr Days" },
        { label: "12 Hr Nights", value: "12 Hr Nights" },
        { label: "Evenings", value: "Evenings" },
        { label: "Varied/Rotating", value: "Varied/Rotating" },
      ],
      selectedShifts: [],
      durations: [
        { label: "Below 13 weeks", value: "Below 13 weeks" },
        { label: "13 - 26 weeks", value: "13 - 26 weeks" },
        { label: "13 weeks", value: "13 - 13 weeks" },
        { label: "26 weeks", value: "26 - 26 weeks" },
        { label: "More than 26 weeks", value: "More than 26" },
      ],
      durationsDuplicates: [
        { label: "Below 13 weeks", value: "Below 13 weeks" },
        { label: "13 - 26 weeks", value: "13 - 26 weeks" },
        { label: "13 weeks", value: "13 - 13 weeks" },
        { label: "26 weeks", value: "26 - 26 weeks" },
        { label: "More than 26 weeks", value: "More than 26" },
      ],
      selectedDuration: [],
      duration: [],
      startDate: "",
      toStartDate: "",
      cbspeciality: [],
      dropdownStatus: false,
      toDateError: "",
      subFilterStatus: false,
      sortVals: ["Select", "Highest Gross", "Lowest Gross"],
      selectedGrossSort: "Highest Gross",
      modal: false,
      certification: [],
      showUserPopUp: false,
      activeTab: "1",
      stateCodeArray: [],
      certificationArray: [],
      specialityArray: [],
      userInfo: {},
      clearFilters: true,
    };
    // console.log(this.state.type, "-----------------")
    this.handlePageValues = this.handlePageValues.bind(this);
    this.handleShift = this.handleShift.bind(this);
    this.onSearchKey = this.onSearchKey.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleDurations = this.handleDurations.bind(this);
    this.toggleOpenShift = this.toggleOpenShift.bind(this);
    this.toggleClosedShift = this.toggleClosedShift.bind(this);
    this.toggleOpenDuration = this.toggleOpenDuration.bind(this);
    this.toggleClosedDuration = this.toggleClosedDuration.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggleOpenShift() {
    this.setState({ shiftDropdown: true });
  }
  handleShiftBlur() {
    this.toggleClosedShift();
  }
  toggleClosedShift() {
    this.setState({ shiftDropdown: false });
  }
  toggleOpenDuration() {
    this.setState({ durationDropdown: true });
  }
  toggleClosedDuration() {
    this.setState({ durationDropdown: false });
  }
  handleDurationBlur() {
    this.toggleClosedDuration();
  }
  handleDurations(event) {
    var options = [];
    let duration = this.state.duration;
    duration.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.duration.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    this.setState({ duration: duration, check_duration: options });
  }
  // componentDidUpdate(prevProps) {
  //   // Parse the search string when the location changes
  //   console.log(this.props.location.search, "------------", prevProps.location.search)
  //   if (this.props.location.search !== prevProps.location.search) {
  //     // this.parseSearchString(this.props.location.search);
  //     const params = queryString.parse(this.props.location.search);
  //     console.log(params);
  //     this.setState({type:params.workType})
  //   }
  // }
  // parseSearchString(search) {
  //   const params = queryString.parse(search);
  //   console.log(params);
  //   this.setState({type:params.workType})
  //   // You can now use the parsed parameters as needed
  // }
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.parseSearchString(this.props.location.search);
    if (
      (this.props,
      window.innerWidth >= 320 && this.props,
      window.innerWidth <= 767)
    ) {
      this.setState({ dropdownStatus: true, subFilterStatus: true });
    }
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ userInfo: user });
    if (this.props.location.search !== "") {
      var query = queryString.parse(this.props.location.search);
      var resertUrl = this.props.location?.search;
      const query123 = resertUrl && resertUrl.split("&")[0];
      if (
        this.props.location?.search == "?hotjobs=1" ||
        query123 == "?hotjobs=1"
      ) {
        this.setState({ hotJobsFlag: true });
      }
      this.updateJobsList(
        this.state.currentPage,
        this.state.selectedPageSize,
        this.state.selectedOrderOption,
        this.state.searchText,
        query.speciality ? query.speciality.split(",") : [],
        query.state ? query.state.split(",") : [],
        query.city ? query.city : "",
        this.state.shift,
        query.radius ? query.radius : "",
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        query.certification ? query.certification.split(",") : [],
        query.workType ? query.workType : this.state.type
      );

      this.setState({
        speciality: query.speciality ? query.speciality.split(",") : [],
        certification: query.certification
          ? query.certification.split(",")
          : [],
        state: query.state ? query.state.split(",") : [],
        city: query.city ? query.city : "",
        radius: query.radius ? query.radius : "",
      });
    } else if (
      this.props.location.state !== "" &&
      this.props.location.state !== null &&
      this.props.location.state !== undefined &&
      this.props.location.state !== "undefined" &&
      refreshVal === ""
    ) {
      var newState = this.props.location.state;
      // console.log('newState', newState);
      this.updateJobsList(
        this.state.currentPage,
        this.state.selectedPageSize,
        this.state.selectedOrderOption,
        this.state.searchText,
        newState && newState.speciality ? newState.speciality : [],
        newState.state ? newState.state : [],
        newState.city ? newState.city : "",
        this.state.shift,
        newState.radius ? newState.radius : "",
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        newState.certification ? newState.certification : [],
        this.state.type
      );
      this.setState({
        speciality: newState.speciality ? newState.speciality : [],
        certification: newState.certification ? newState.certification : [],
        state: newState.state ? newState.state : [],
        city: newState.city ? newState.city : "",
        radius: newState.radius ? newState.radius : "",
      });
    } else {
      var sizeperPage;
      if (
        (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
      ) {
        this.setState({
          selectedPageSize: 5,
        });
        sizeperPage = 5;
      }
      this.updateJobsList(
        this.state.currentPage,
        sizeperPage,
        this.state.selectedOrderOption,
        this.state.searchText,
        this.state.speciality,
        this.state.state,
        this.state.city,
        this.state.shift,
        this.state.searchRadius,
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        this.state.certification,
        this.state.type
      );
    }
  }
  handlePageValues(e) {
    this.setState({ [e.target.name]: parseInt(e.target.value) }, () => {
      this.onChangePage(1);
    });
    let page = 1;
    this.updateJobsList(
      page,
      parseInt(e.target.value),
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  }
  handleOnFocus(e) {
    this.setState({ searchPlaceHolder: "" });
  }
  handleOnBlur(e) {
    this.setState({ searchPlaceHolder: "Search" });
  }
  handleShift(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.onChangePage(1);
    });
    let page = 1;
    this.updateJobsList(
      page,
      parseInt(e.target.value),
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      // this.state.location,
      this.state.state,
      this.state.city,
      e.target.value,
      this.state.searchRadius,
      //this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  }

  onChangePage = (page) => {
    this.setState({
      currentPage: page,
    });
    window.scrollTo(0, 0);
  };
  onSearchClick(e) {
    e.preventDefault();
    let page = 1;
    this.setState({
      currentPage: 1,
      shiftDropdown: false,
      durationDropdown: false,
    });
    this.updateJobsList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      // this.state.location,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      // this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  }
  onSearchKey = (e) => {
    this.setState({ searchText: e.target.value, currentPage: 1 });
    let page = 1;
    this.updateJobsList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      e.target.value,
      this.state.speciality,
      // this.state.location,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      // this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  };

  searchCallback = (data) => {
    var stateVal = [];
    this.setState({ stateDuplicate: data.state });

    var specialityVal = [];
    // var cityVal = [];
    data.state.map((item) => {
      stateVal.push(item.code);
    });
    this.setState({ stateCodeArray: stateVal });
    data.speciality.map((item) => {
      specialityVal.push(item.speciality);
    });
    // data.city.map((item) => {
    //   cityVal.push(item.name);
    // });
    this.setState(
      {
        speciality: data.speciality,
        state: data.state,
        city: data.city,
        searchRadius: data.searchRadius,
        currentPage: 1,
        specialities: data.specialities,
        states: data.states,
        cities: data.cities,
        certification: data.certification,
      },
      () => {
        this.onChangePage(1);
      }
    );
    this.updateJobsList(
      1,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      data.speciality,
      // data.location,
      data.state,
      data.city,
      this.state.shift,
      // this.state.searchRadius,
      data.searchRadius,
      // this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      data.certification,
      this.state.type
    );
  };
  updateJobsList = async (
    currentPage,
    selectedPageSize,
    selectedOrderOption,
    searchText,
    speciality,
    // location,
    state,
    city,
    shift,
    searchRadius,
    duration,
    startDate,
    toStartDate,
    certification,
    type
  ) => {
    // console.log(shift, "=========", duration)
    let nurseId = localStorage.getItem("id");
    if (searchText && searchText !== "") {
      searchText = searchText;
    } else {
      searchText = "";
      this.setState({ jobListdata: false });
    }
    var stateVal = [];
    if (state) {
      state.map((item) => {
        if (item.code) {
          stateVal.push(item.code);
        } else {
          stateVal.push(item);
        }
      });
    }
    var specialityVal = [];
    if (speciality && speciality.length > 0) {
      speciality.map((item) => {
        if (item.value) {
          specialityVal.push(item.value);
          this.setState({ specialityArray: specialityVal });
        } else {
          specialityVal.push(item);
        }
      });
    }
    var certVal = [];
    if (certification) {
      certification.map((item) => {
        if (item.value) {
          certVal.push(item.value);
          this.setState({ certificationArray: certVal });
        } else {
          certVal.push(item);
        }
      });
    }
    if (shift) {
      var shiftVal = [];
      shift.map((item) => {
        shiftVal.push(item.value);
      });
    }
    if (duration) {
      var durationVal = [];
      duration.map((item) => {
        durationVal.push(item.value);
      });
    }
    var cityName = "";
    if (city) {
      cityName = city.split(", ");
      this.setState({ cityState: cityName[1] });
      this.setState({ cityval: cityName[0] });
    }
    var sizeperpage = selectedPageSize;
    if (selectedPageSize === "undefined" || selectedPageSize === undefined) {
      sizeperpage =
        window.innerWidth >= 320 && window.innerWidth <= 767 ? 5 : 10;
    }
    var resertUrl = this.props.location?.search;
    // console.log("resertUrl=============", resertUrl);
    const queryString = resertUrl && resertUrl.split("&")[0];
    // console.log( this.props.location?.search, "-----------", queryString)
    let tablefilterdata = nurseId
      ? {
          page: currentPage,
          sizeperpage: sizeperpage,
          sortname: selectedOrderOption,
          searchtext: searchText,
          defaultSearchText: searchText,
          speciality: specialityVal,
          state: stateVal,
          city: cityName[0],
          cityFullName: city,
          cityState: city ? cityName[1] : "",
          shift: shiftVal,
          searchRadius: cityName[0] ? searchRadius : "",
          duration: durationVal,
          startDate: startDate,
          toStartDate: toStartDate,
          certification: certVal,
          pinned: this.state.hotJobsFlag,
          nurseId: nurseId ? nurseId : "",
          workType: type, //for contarct OR PRN Jobs
          hotJobs:
            this.props.location?.search == "?hotjobs=1" ||
            queryString == "?hotjobs=1"
              ? true
              : false,
        }
      : {
          page: currentPage,
          sizeperpage: sizeperpage,
          sortname: selectedOrderOption,
          searchtext: searchText,
          defaultSearchText: searchText,
          speciality: specialityVal,
          state: stateVal,
          city: cityName[0],
          cityFullName: city,
          cityState: city ? cityName[1] : "",
          shift: shiftVal,
          searchRadius: cityName[0] ? searchRadius : "",
          duration: durationVal,
          startDate: startDate,
          toStartDate: toStartDate,
          certification: certVal,
          pinned: this.state.hotJobsFlag,
          workType: type, //for contarct OR PRN Jobs
          //for contarct OR PRN Jobs
          hotJobs:
            this.props.location?.search == "?hotjobs=1" ||
            queryString == "?hotjobs=1"
              ? true
              : false,
        };
    this.setState({ jobListdata: false });
    // job posting structured data
    // console.log(tablefilterdata.hotJobs, this.props.location?.search)
    await axios
      .post(AppConstants.Jobs_API + "/jobs/getJobs", tablefilterdata)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          var list = data.data;
          var newlist = [];
          // list &&
          //   list.map((item, i) => {
          //     var obj = {
          //       '@context': 'https://schema.org/',
          //       '@type': 'JobPosting',
          //       title: item.title,
          //       // jobBenefits: item && item.ben,
          //       // employmentType: item.shift,
          //       // experienceRequirements: item.minExperienceRequired,
          //       // industry: "Travel Nurses",
          //       // salaryCurrency: "USD",
          //       // skills: item.speciality,
          //       // qualifications: item.degree,

          //       // breadcrumb: "Home >> Job Search >> " + item.title,
          //       // contentReferenceTime: new Date(),
          //       description: item.description,
          //       identifier: {
          //         '@type': 'PropertyValue',
          //         name: 'Travel Nurses, INC',
          //       },
          //       datePosted: item.postDate,
          //       validThrough: item.endDate,
          //       hiringOrganization: {
          //         '@type': 'Organization',
          //         name: 'Travel Nurses, INC',
          //         sameAs: 'https://jobs.travelnursesinc.com/',
          //         logo: 'https://jobs.travelnursesinc.com/tnlogo.png',
          //       },
          //       jobLocation: {
          //         '@type': 'Place',
          //         address: {
          //           '@type': 'PostalAddress',
          //           streetAddress: item.address || 'N/A',
          //           addressLocality: item.city,
          //           addressRegion: item.state,
          //           postalCode: item.zipcode,
          //           addressCountry: 'US',
          //         },
          //       },
          //       baseSalary: {
          //         '@type': 'MonetaryAmount',
          //         currency: 'USD',
          //         value: {
          //           '@type': 'QuantitativeValue',
          //           value: item.grossPay1,
          //           unitText: 'WEEK',
          //         },
          //       },
          //     };
          //     newlist.push(obj);
          //   });
          // const script = document.createElement('script');
          // script.setAttribute('type', 'application/ld+json');
          // script.textContent = JSON.stringify(newlist);
          // // console.log('jobList', JSON.stringify(newlist));
          // var key = document.head.appendChild(script);

          this.setState({
            jobListdata: true,
            strData: newlist,
            totalPage: data.totalPage,
            jobList: data.data,
            pointerSm: `${AppConstants.Bucket_URL}/bannerImages/pointer-small.png`,
            selectedItems: [],
            totalItemCount: data.total_records,
          });
        }
      });
  };
  handlePageClick = (data) => {
    this.onChangePage(data.selected + 1);
    this.updateJobsList(
      data.selected + 1,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  };
  handleChange = (value) => {
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.items.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({ items: result, shift: value, selectedShifts: value });
    this.updateJobsList(
      1,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      // this.state.location,
      this.state.state,
      this.state.city,
      value,
      this.state.searchRadius,
      // this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  };
  pushItem = (item, value) => {
    if (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
      for (var i = 0; i < value.length; i++) {
        if (
          item.label.toLowerCase().charAt(i) === value.toLowerCase().charAt(i)
        ) {
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };
  valueChange = (value) => {
    let vik = [];
    this.state.itemsDuplicates.map((item, index) => {
      if (this.pushItem(item, value)) {
        vik.push(item);
      }
    });
    this.setState({ items: [...vik] });
  };
  handleDurationChange = (value) => {
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.durations.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      durations: result,
      duration: value,
      selectedDuration: value,
    });
    this.updateJobsList(
      1,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      // this.state.check_duration,
      value,
      this.state.startDate,
      this.state.toStartDate,
      this.state.certification,
      this.state.type
    );
  };
  valueDurationChange = (value) => {
    let vik = [];
    this.state.durationsDuplicates.map((item, index) => {
      if (this.pushItem(item, value)) {
        vik.push(item);
      }
    });
    this.setState({ durations: [...vik] });
  };
  handleStartDate(date) {
    this.setState({ startDate: date });
    if (this.state.toStartDate) {
      if (
        new Date(this.state.toStartDate).toISOString() <
        new Date(date).toISOString()
      ) {
        this.setState({
          toDateError: "Please select a date later than From Date",
        });
      } else {
        this.setState({ toDateError: "" });
      }
    }
  }
  handleToStartDate(date1) {
    this.setState({ toStartDate: date1, currentPage: 1 });
    this.updateJobsList(
      1,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.speciality,
      this.state.state,
      this.state.city,
      this.state.shift,
      this.state.searchRadius,
      // this.state.check_duration,
      this.state.duration,
      this.state.startDate,
      date1,
      this.state.certification,
      this.state.type
    );
    if (this.state.startDate) {
      if (
        new Date(date1).toISOString() <
        new Date(this.state.startDate).toISOString()
      ) {
        this.setState({
          toDateError: "Please select a date later than From Date",
        });
      } else {
        this.setState({ toDateError: "" });
      }
    }
  }
  handleSubFilters(e) {
    e.preventDefault();
    this.setState({ subFilterStatus: !this.state.subFilterStatus });
  }
  handleReset(e) {
    e.preventDefault();
    var selectedPageSize = "";
    if (this.state.dropdownStatus === true) {
      selectedPageSize = 5;
    } else {
      selectedPageSize = 10;
    }
    if (selectedPageSize) {
      this.setState(
        {
          currentPage: 1,
          selectedPageSize: this.state.selectedPageSize,
          selectedOrderOption: "",
          searchText: "",
          shift: [],
          selectedShifts: [],
          duration: [],
          selectedDuration: [],
          startDate: "",
          toStartDate: "",
        },
        () => {
          this.updateJobsList(
            1,
            this.state.selectedPageSize,
            this.state.selectedOrderOption,
            this.state.searchText,
            this.state.speciality,
            this.state.state,
            this.state.city,
            this.state.shift,
            this.state.searchRadius,
            // this.state.check_duration,
            this.state.duration,
            this.state.startDate,
            this.state.toStartDate,
            this.state.certification,
            this.state.type
          );
        }
      );
    }
  }
  jobClass() {
    $(".search-bar").toggleClass("bar-open");
  }
  handleGrossSort(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  copyCodeToClipboard(id, e) {
    NotificationManager.info("URL Copied", "", 3000);
    e.preventDefault();
    var cpLink = AppConstants.Base_Url + "/job/" + id;
    navigator.clipboard.writeText(encodeURI(cpLink));
  }
  handleModal() {
    this.setState({ modal: !this.state.modal });
  }
  handlePopUp() {
    this.setState({ modal: true });
  }
  userPopCallback = (val) => {
    this.setState({ showUserPopUp: val });
  };
  handleUserPopup(e) {
    e.preventDefault();
    this.setState({ showUserPopUp: true });
  }
  handleUserModelPopup() {
    this.setState({ showUserPopUp: !this.state.showUserPopUp });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  hotJobsList = async () => {
    var query = queryString.parse(this.props.location.search);
    const searchParams = new URLSearchParams(this.props.location.search);
    searchParams.delete("hotjobs");
    const newPath = searchParams.toString();
    if (!this.state.hotJobsFlag) {
      var path = newPath ? "?hotjobs=1&" : "?hotjobs=1";
      this.props && this.props.history.push(`${path}${newPath}`);
      await this.setState({ hotJobsFlag: true, currentPage: 1 });
      await this.updateJobsList(
        1,
        this.state.selectedPageSize,
        this.state.selectedOrderOption,
        this.state.searchText,
        query.speciality ? query.speciality.split(",") : [],
        query.state ? query.state.split(",") : [],
        query.city ? query.city : "",
        this.state.shift,
        query.radius ? query.radius : "",
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        query.certification ? query.certification.split(",") : [],
        query.workType ? query.workType : this.state.type
      );
    } else {
      this.props && this.props.history.push(`/?${newPath}`);
      await this.setState({ hotJobsFlag: false, currentPage: 1 });
      await this.updateJobsList(
        1,
        this.state.selectedPageSize,
        this.state.selectedOrderOption,
        this.state.searchText,
        query.speciality ? query.speciality.split(",") : [],
        query.state ? query.state.split(",") : [],
        query.city ? query.city : "",
        this.state.shift,
        query.radius ? query.radius : "",
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        query.certification ? query.certification.split(",") : [],
        query.workType ? query.workType : this.state.type
      );
    }
  };

  handleSaveJobClick = async (e, id) => {
    let nurseId = localStorage.getItem("id");
    await axios
      .post(AppConstants.API + "/nurse/SaveJobAction", {
        nurseId: nurseId,
        jobId: id,
      })
      .then((response) => {
        NotificationManager.success(response.data.msg, "", 3000);
        this.updateJobsList(
          1,
          this.state.selectedPageSize,
          this.state.selectedOrderOption,
          this.state.searchText,
          this.state.speciality,
          this.state.state,
          this.state.city,
          this.state.shift,
          this.state.searchRadius,
          this.state.duration,
          this.state.startDate,
          this.state.toStartDate,
          this.state.certification,
          this.state.type
        );
      });
  };

  handleChangeList = (type) => {
    localStorage.removeItem("workType");
    this.setState({ type: type }, () => {
      const queryParams = queryString.parse(this.props.location.search);
      queryParams.workType = type;
      setTimeout(()=>{
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: queryString.stringify(queryParams),
        });
      },1500)
    });
    if (type == "1") {
      this.setState({ type: type });
      var sizeperPage;
      if (
        (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
      ) {
        this.setState({
          selectedPageSize: 5,
        });
        sizeperPage = 5;
      }

      this.updateJobsList(
        this.state.currentPage,
        sizeperPage,
        this.state.selectedOrderOption,
        this.state.searchText,
        this.state.speciality,
        this.state.state,
        this.state.city,
        this.state.shift,
        this.state.searchRadius,
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        this.state.certification,
        type
      );
    } else {
      this.setState({ type: type });
      var sizeperPage;
      if (
        (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
      ) {
        this.setState({
          selectedPageSize: 5,
        });
        sizeperPage = 5;
      }
      this.updateJobsList(
        this.state.currentPage,
        sizeperPage,
        this.state.selectedOrderOption,
        this.state.searchText,
        this.state.speciality,
        this.state.state,
        this.state.city,
        this.state.shift,
        this.state.searchRadius,
        this.state.duration,
        this.state.startDate,
        this.state.toStartDate,
        this.state.certification,
        type
      );
    }
  };

  replaceURL(url) {
    if (!url) {
      url =
        "https://travelnurses.s3.us-west-2.amazonaws.com/default-job-image.png";
    }
    const replaced = url.replace(
      "https://travelnurses.s3.us-west-2.amazonaws.com",
      AppConstants.Bucket_URL
    );
    return replaced;
  }

  render() {
    let nurseId = localStorage.getItem("id");
    return (
      <div className="searchjobs-block pb-50">
        {/* <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Jobs"
            schema={{
              name: 'Top Travel Nursing Jobs',
              description:
                'Travel Nurses Jobs |Travel Nurses Inc has thousands of Nurse and RN jobs with healthcare facilities, All 50 States. Great Benefits & Pay. Apply now!',
              contentReferenceTime: new Date(),
            }}
          ></Generic>
        </JSONLD> */}
        <Helmet>
          <title>
            Travel Nurses Jobs, Nurse Job Opportunities | Travel Nurses Inc
          </title>
          <meta name="title" content="Jobs" />
          <meta
            name="description"
            content="Travel Nurses Jobs |Travel Nurses Inc has thousands of Nurse and RN jobs with healthcare facilities, All 50 States. Great Benefits & Pay. Apply now!"
          />

          <meta property="og:type" content="website" />
          {/* <meta property="og:url" content={AppConstants.Base_Url + "/jobs"} /> */}
          <meta property="og:url" content={AppConstants.Base_Url + "/"} />
          <meta property="og:title" content="Jobs" />
          <meta
            property="og:description"
            content="Travel Nurses Jobs |Travel Nurses Inc has thousands of Nurse and RN jobs with healthcare facilities, All 50 States. Great Benefits & Pay. Apply now!"
          />
          <meta
            property="og:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />

          <meta
            property="twitter:card"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />
          <meta
            property="twitter:url"
            // content={AppConstants.Base_Url + "/jobs"}
            content={AppConstants.Base_Url + "/"}
          />
          <meta property="twitter:title" content="Jobs" />
          <meta
            property="twitter:description"
            content="Travel Nurses Jobs |Travel Nurses Inc has thousands of Nurse and RN jobs with healthcare facilities, All 50 States. Great Benefits & Pay. Apply now!"
          />
          <meta
            property="twitter:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />
        </Helmet>
        <SearchArea
          searchCallback={this.searchCallback}
          userPopCallback={this.userPopCallback}
          type={this.state.type}
          searchJobHistory={this.props}
          resertFilter={this.state.clearFilters}
          homeState={
            this.props.location.state && refreshVal === ""
              ? this.props.location.state.state
              : this.state.state
              ? this.state.state
              : []
          }
          homeStates={
            this.props.location.state ? this.props.location.state.states : []
          }
          homeCity={
            this.props.location.state && refreshVal === ""
              ? this.props.location.state.city
              : this.state.city
              ? this.state.city
              : ""
          }
          // homeCity={this.state.city ? this.state.city : ""}
          homeCities={
            this.props.location.state ? this.props.location.state.cities : []
          }
          homeSpeciality={
            this.props.location.state && refreshVal === ""
              ? this.props.location.state.speciality
              : this.state.speciality
              ? this.state.speciality
              : []
          }
          // homeSpeciality={this.state.speciality ? this.state.speciality : []}
          // homeSpecialities={
          //   this.props.location.state
          //     ? this.props.location.state.specialities
          //     : []
          // }
          homesearchRadius={
            this.props.location.state && refreshVal === ""
              ? this.props.location.state.radius
              : this.state.radius
              ? this.state.radius
              : ""
          }
          // homesearchRadius={this.state.radius ? this.state.radius : ""}
          homeCertification={
            this.props.location.state && refreshVal === ""
              ? this.props.location.state.certification
              : this.state.certification
              ? this.state.certification
              : []
          }
          // homeCertifications={
          //   this.props.location.state
          //     ? this.props.location.state.certifications
          //     : []
          // }
          homeshowLoc={
            this.props.location.state ? this.props.location.state.showLoc : ""
          }
          homeshowLink={
            this.props.location.state && refreshVal
              ? false
              : this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.showLink
          }
        />
        <div className="main-content latestjobs" id="advanced-search">
          <Container>
            <div className="inner-content mt-0">
              {/* Breadcrumb Block  Start Here */}
              <div class="mr-auto">
                <Breadcrumb>
                  <BreadcrumbItem
                    className={
                      this.state.subFilterStatus === true
                        ? "up-selected1 pr-5 py-3  px-4 TN-filter hvr-hang"
                        : "show-selected1 pr-5 py-3 px-4 TN-filter hvr-hang"
                    }
                    onClick={this.handleSubFilters.bind(this)}
                  >
                    Advanced Search
                  </BreadcrumbItem>
                  <br></br>
                  {"    "}
                  {this.state.userInfo ? (
                    " "
                  ) : (
                    <li className="alert-bttn desk-alert-bttn">
                      <Button
                        onClick={this.handleUserPopup.bind(this)}
                        className="copy-btn"
                        title="Subscribe for Alerts"
                        id="circuleExtern"
                      >
                        Subscribe for Job Alerts
                      </Button>
                    </li>
                  )}
                  {this.state.type == "1" && (
                    <li
                      className={`my-auto ml-2 ml-auto desk-hot-job hot-job ${
                        this.state.hotJobsFlag && `active`
                      }`}
                      style={{ color: "rgb(35, 63, 143)" }}
                      onClick={this.hotJobsList.bind(this)}
                    >
                      <a href="javascript:void">
                        <span>Hot Jobs</span>
                        <img
                          src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                          style={{ width: "40px" }}
                        />
                      </a>
                    </li>
                  )}
                </Breadcrumb>
              </div>
              {this.state.showUserPopUp ? (
                <UserPopup
                  showUserPopUp={this.state.showUserPopUp}
                  handleUserModelPopup={this.handleUserModelPopup.bind(this)}
                  selectedCerts={this.state.certification}
                  selectedSpecialities={this.state.speciality}
                  selectedStates={this.state.state}
                  city={this.state.city}
                  searchRadius={this.state.searchRadius}
                  title="Job Alerts"
                />
              ) : (
                ""
              )}
              {/* Breadcrumb Block  End Here */}

              {/* Filter Block  Start Here */}
              <div className="jobs">
                {this.state.subFilterStatus === true ? (
                  <div className="filter-blk">
                    <div className="search-block bg-white mt-3 mt-lg-0">
                      <div className="content">
                        <Row className="justify-content-md-center px-md-3 px-sm-3 px-lg-0 px-3  custum-jobs-search">
                          <Col className="mb-3 col-sm-12 col-md-6 col-lg-3 col-12">
                            <div className="filter-fielld block w-100">
                              <div
                                className="filter-value"
                                onClick={() => {
                                  this.toggleOpenShift();
                                }}
                              >
                                <label class="pl-2  pb-0">Shift</label>
                                <div className="custum-shift">
                                  <ReactMultiSelectCheckboxes
                                    className="w-100 job-select"
                                    options={
                                      this.state.type == "1"
                                        ? this.state.items
                                        : this.state.items1
                                    }
                                    value={this.state.selectedShifts}
                                    onChange={(value) => {
                                      this.handleChange(value);
                                    }}
                                    onInputChange={(value) => {
                                      this.valueChange(value);
                                    }}
                                    isSearchable={true}
                                    placeholderButtonLabel="Shift"
                                    menuIsOpen={
                                      this.state.dropdownStatus === true
                                        ? ""
                                        : this.state.shiftDropdown
                                    }
                                    onBlur={this.handleShiftBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          {this.state.type == "1" && (
                            <Col className="mb-3 col-sm-12 col-md-6 col-lg-3 col-12">
                              <div className="filter-fielld block w-100">
                                <div
                                  className="filter-value"
                                  onClick={() => {
                                    this.toggleOpenDuration();
                                  }}
                                >
                                  <label class="pl-2  pb-0">Duration</label>
                                  <div className="custum-shift">
                                    <ReactMultiSelectCheckboxes
                                      options={this.state.durations}
                                      value={this.state.selectedDuration}
                                      onChange={(value) => {
                                        this.handleDurationChange(value);
                                      }}
                                      onInputChange={(value) => {
                                        this.valueDurationChange(value);
                                      }}
                                      isSearchable={true}
                                      placeholderButtonLabel="Duration"
                                      menuIsOpen={
                                        this.state.dropdownStatus === true
                                          ? ""
                                          : this.state.durationDropdown
                                      }
                                      onBlur={this.handleDurationBlur.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                          <Col className="mb-3 col-sm-12 col-md-6 col-lg-3 col-12">
                            <div className="filter-fielld keyword-search-responsive">
                              <label>Keyword Search</label>
                              <form
                                class="form-block w-100"
                                style={{ background: "transparent" }}
                              >
                                <input
                                  className="w-100"
                                  type="text"
                                  placeholder={this.state.searchPlaceHolder}
                                  name="search"
                                  value={this.state.searchText}
                                  onChange={this.onSearchKey}
                                  onFocus={this.handleOnFocus.bind(this)}
                                  onBlur={this.handleOnBlur.bind(this)}
                                  autoComplete="off"
                                />
                              </form>
                            </div>
                          </Col>
                          <Col md="1" style={{ zIndex: "1" }}>
                            <button
                              className="button1 refresh-btn sync text-center"
                              onClick={this.handleReset.bind(this)}
                            >
                              <i class="fas fa-sync"></i>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                ) : (
                  // : ""
                  ""
                )}
                {/* Filter Block  End Here */}
              </div>
              <h1 className="main-title-sub" style={{ padding: "6px 0 15px" }}>
                Travel Nurse Job Board
              </h1>

              {/* Jobs List Block Start Here */}
              {/* <button onClick={(e)=>this.handleChangeList(e,"1")}>Tab 1</button>
                      <button onClick={(e)=>this.(e,"3")}>Tab 2</button> */}
              <Tabs
                defaultActiveKey={this.state.type}
                onSelect={(k) => this.handleChangeList(k)}
                id="uncontrolled-tab-example"
                className="mb-3 job-tab-grid"
              >
                <Tab eventKey="1" title="Contract Jobs">
                  <div className="job-tab job-tab-cont">
                    <div className="row">
                      {this.state.userInfo ? (
                        " "
                      ) : (
                        <div className="alert-bttn mbl-alert-bttn">
                          <Button
                            onClick={this.handleUserPopup.bind(this)}
                            className="copy-btn"
                            title="Subscribe for Alerts"
                            id="circuleExtern"
                          >
                            <h2>Subscribe for Job Alerts</h2>
                          </Button>
                        </div>
                      )}

                      <div className="job-result-count col-lg-10">
                        <Row>
                          <Col className="result-cont col-8 my-auto">
                            {this.state.activeTab === "1" &&
                            this.state.totalItemCount ? (
                              <h4 style={{ color: "#707070" }}>
                                {this.state.totalItemCount == 1
                                  ? "Results: " +
                                    this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"
                                  : "Results: " +
                                    +this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"}
                              </h4>
                            ) : (
                              <h3 className="map-view-help-text">
                                Exact facility locations are not shown below
                              </h3>
                            )}
                          </Col>
                          {this.state.activeTab === "1" && (
                            <Col className="count-drop col-4 text-right">
                              <div className="form-group">
                                <label
                                  className="text-left pr-3"
                                  style={{
                                    color: "#233f8f",
                                    fontWeight: "700",
                                  }}
                                >
                                  Show
                                </label>
                                <select
                                  style={{
                                    width: "auto",
                                  }}
                                  className="select-box w-auto d-inline"
                                  value={
                                    this.state.selectedPageSize
                                    // this.state.dropdownStatus === true
                                    //   ? this.state.selectedPageSize
                                    //   : this.state.selectedPageSize
                                  }
                                  name="selectedPageSize"
                                  onChange={this.handlePageValues}
                                >
                                  {this.state.pageSizes.map((page, index) => {
                                    return (
                                      <option id={index} value={page}>
                                        {page}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <Nav tabs className="main-tab-sec col-lg-2">
                        <div
                          className={`my-auto ml-2 ml-auto mbl-hot-job hot-job ${
                            this.state.hotJobsFlag && `active`
                          }`}
                          style={{ color: "rgb(35, 63, 143)" }}
                        >
                          <a href="javascript:void">
                            <span onClick={this.hotJobsList.bind(this)}>
                              Hot Jobs
                            </span>
                            <img
                              src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                              style={{ width: "40px" }}
                            />
                          </a>
                        </div>
                        {/* <div>
                      <button onClick={(e)=>this.handleChangeList(e,"1")}>Tab 1</button>
                      <button onClick={(e)=>this.handleChangeList(e,"3")}>Tab 2</button>
                    </div> */}
                        <NavItem className="tab-block">
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <figure>
                              <img
                                src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/list-icon-new.png"
                                alt="list-icon"
                              />
                            </figure>
                          </NavLink>
                        </NavItem>
                        <NavItem className="tab-block">
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <figure>
                              <img
                                src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/map-black-icon.png"
                                alt="list-icon"
                              />
                            </figure>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {this.state.jobListdata === true ? (
                          <div className="list-job pb-50" id="jobs-list-grid">
                            {this.state.jobList.length !== 0 ? (
                              this.state.jobList.map((item, index) => (
                                <div className="job-info block-box">
                                  <div
                                    className="share-btn position-absolute bg-white share-rotate111"
                                    style={{
                                      right: "10px",
                                      top: "8px",
                                    }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Slide to left"
                                      // href="#"
                                      class="button-share pr-0 d-flex"
                                    >
                                      <span class="icon-share">
                                        <i
                                          class="fa fa-share-alt"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text">
                                        <Row className="pr-2 mr-0">
                                          <Col
                                            className="rounded-left text-white text-center px-1 pt-1 "
                                            style={{ background: "#25408f" }}
                                          >
                                            <NotificationContainer />
                                            <FaClone
                                              className=""
                                              onClick={this.copyCodeToClipboard.bind(
                                                this,
                                                item._id
                                              )}
                                            />
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2"
                                            style={{ background: "#00aced" }}
                                          >
                                            <span className="twitter">
                                              <TwitterShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share"
                                                title={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <TwitterIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </TwitterShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 "
                                            style={{ background: "#405992" }}
                                          >
                                            <span className="facebook">
                                              <FacebookShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share w-100"
                                                quote={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <FacebookIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </FacebookShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 rounded-right"
                                            style={{ background: "#007fb1" }}
                                          >
                                            <LinkedinShareButton
                                              url={
                                                AppConstants.Base_Url +
                                                `/job/${item._id}`
                                              }
                                              className="share"
                                              title={`${item.title} Job in ${item.city}, ${item.state} `}
                                              summary={`Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              media="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                            >
                                              <LinkedinIcon
                                                size={32}
                                                round={true}
                                              />
                                            </LinkedinShareButton>
                                          </Col>
                                        </Row>
                                      </span>
                                    </span>
                                  </div>

                                  <ul>
                                    <Link
                                      to={{
                                        pathname: `/job/${item._id}`,
                                        state: {
                                          speciality: this.state.speciality
                                            ? this.state.speciality
                                            : "",
                                          city: this.state.city
                                            ? this.state.city
                                            : "",
                                          state: this.state.stateDuplicate
                                            ? this.state.stateDuplicate
                                            : "",
                                          searchRadius: this.state.searchRadius
                                            ? this.state.searchRadius
                                            : "",
                                        },
                                      }}
                                      target="_blank"
                                    >
                                      <li className="job-location ">
                                        <img
                                          src={this.replaceURL(
                                            item.stateImages[
                                              Math.floor(
                                                Math.random() *
                                                  item.stateImages.length
                                              )
                                            ]
                                          )}
                                          alt={
                                            item.title +
                                            " - " +
                                            item.city +
                                            " - " +
                                            item.state +
                                            " - Travel Nurses Inc"
                                          }
                                        />
                                      </li>
                                      {/* <li className="job-location ">
                                        {stateImages.map((item1, index) => {
                                          if (
                                            item.stateName === item1.name &&
                                            item1.images
                                          ) {
                                            return (
                                              <img
                                                src={
                                                  item1.images[
                                                  Math.floor(
                                                    Math.random() *
                                                    item1.images.length
                                                  )
                                                  ]
                                                }
                                                alt={
                                                  item.title +
                                                  " - " +
                                                  item.city +
                                                  " - " +
                                                  item.state +
                                                  " - Travel Nurses Inc"
                                                }
                                              />
                                            );
                                          }
                                        })}
                                      </li> */}
                                    </Link>
                                    <li className="job-position pt-4">
                                      {/* <h4>{item.title}</h4> */}
                                      <h4
                                        style={{
                                          fontSize: "25px",
                                          color: "#333333",
                                        }}
                                      >
                                        {item.degree}
                                      </h4>
                                      {item.jobSubSpeciality ? (
                                        <p
                                          style={{
                                            fontSize: "23px",
                                            color: "#707070",
                                          }}
                                        >
                                          {item.jobSubSpeciality}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <span className="city-name">
                                        {item.city + ", " + item.state}
                                      </span>
                                    </li>
                                    <li className="job-time">
                                      <p className="date">
                                        <span>
                                          Start Date: <em>{item.start_date}</em>
                                        </span>
                                      </p>
                                      <p className="duration">
                                        <span>
                                          Duration:{" "}
                                          <em>
                                            {item.duration
                                              ? item.duration == 1
                                                ? item.duration + " Week"
                                                : item.duration + " Weeks"
                                              : "N/A"}
                                          </em>
                                        </span>
                                      </p>
                                      <p className="time">
                                        <span>{item.shift}</span>
                                      </p>
                                    </li>
                                    <li className="btn-section pt-0 pt-lg-4 pt-md-4">
                                      {/* {item.grossPay1 === 0 ? ( */}
                                      {item.grossPay1 < 500 ? (
                                        ""
                                      ) : (
                                        <div className="empty-class">
                                          {nurseId && item.workType == "1" && (
                                            <div
                                              className={`saved-text ${
                                                item.isSaved
                                                  ? ""
                                                  : "addSavedClass"
                                              }`}
                                            >
                                              <button
                                                onClick={(e) =>
                                                  this.handleSaveJobClick(
                                                    e,
                                                    item._id
                                                  )
                                                }
                                              >
                                                {item.isSaved ? (
                                                  <BsFillBookmarkFill />
                                                ) : (
                                                  <BiBookmark />
                                                )}
                                                <span>
                                                  {item.isSaved
                                                    ? "Remove"
                                                    : "Save"}
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                          <p className="est-pay-res pb-0 text-right mr-3">
                                            <div className="est-pay-res-in text-left">
                                              <h4
                                                style={{ fontSize: "16px" }}
                                                className="pb-0"
                                              >
                                                Est.pay
                                                <span className="dollar-sign mx-1">
                                                  &#36;
                                                </span>
                                                {/* <span className="currency d-black w-100"></span> */}
                                              </h4>

                                              <h4
                                                className="pb-0"
                                                style={{ color: "#333333" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "27px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {"$" + item.grossPay1}
                                                </span>
                                                /<span>week</span>
                                              </h4>
                                            </div>
                                          </p>
                                        </div>
                                      )}
                                      <Link className="lead-link jobdetail_link" to={{
                                          pathname: `/job/${item._id}`,
                                          search: queryString.stringify({
                                            certification: item.certification || "", 
                                            speciality: item.jobSubSpeciality || "", 
                                            workType: item.workType || "", 
                                          }),
                                          state: {
                                            speciality: this.state.speciality
                                              ? this.state.speciality
                                              : [],
                                            city: this.state.city
                                              ? this.state.city
                                              : [],
                                            state: this.state.stateDuplicate
                                              ? this.state.stateDuplicate
                                              : [],
                                            searchRadius: this.state
                                              .searchRadius
                                              ? this.state.searchRadius
                                              : "",
                                            specialities: this.state
                                              .specialities
                                              ? this.state.specialities
                                              : "",
                                            cities: this.state.cities
                                              ? this.state.cities
                                              : "",
                                            states: this.state.states
                                              ? this.state.states
                                              : "",
                                            duration: this.state.duration,
                                            shift: this.state.shift,
                                            selectedPageSize:
                                              this.state.selectedPageSize,
                                            currentPage: this.state.currentPage,
                                            searchText: this.state.searchText,
                                            // check_duration: this.state.check_duration,
                                            selectedDuration:
                                              this.state.selectedDuration,
                                            selectedShifts:
                                              this.state.selectedShifts,
                                            startDate: this.state.startDate,
                                            toStartDate: this.state.toStartDate,
                                            jobDetailPath: "/job/:id",
                                            pnr: false,
                                          },
                                        }}>View Details</Link>
                                      <Link
                                        to={{
                                          pathname: `/apply-job/${item._id}`,
                                        }}
                                        className="button mt-4 mt-md-0 mt-lg-0 text-center"
                                        target="_blank"
                                      >
                                        Apply
                                      </Link>
                                      {/* <a href="#" className="forward-link">
                              Send to a friend
                            </a> */}
                                      {/* <Link className="forward-link">
                              Share
                            </Link> */}
                                    </li>
                                  </ul>
                                  {item.pinned ? (
                                    <figure
                                      className="position-absolute rounded-circle bg-white"
                                      style={{
                                        top: "43%",
                                        border: "1px solid #ccc",
                                        right: "-23px",
                                      }}
                                    >
                                      <img
                                        src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                                        style={{
                                          width: "43px",
                                          padding: "0px 6px 6px 6px",
                                        }}
                                      />
                                    </figure>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                            ) : (
                              <span className="no-data">
                                {/* No jobs match this criteria. */}
                                Sorry, looks like we don’t have any of those
                                jobs available at the moment. <br />
                                Jobs refresh hourly, so let's find you a match!
                                <br />
                                <Button onClick={this.handlePopUp.bind(this)}>
                                  Submit a request to be notified for openings
                                </Button>
                                {this.state.modal === true ? (
                                  <ContactRecruiterPopUp
                                    workType={this.state.type}
                                    modal={this.state.modal}
                                    speciality={this.state.speciality}
                                    certification={this.state.certification}
                                    city={this.state.city}
                                    state={this.state.state}
                                    searchText={this.state.searchText}
                                    selectedDurationShifts={this.state.selectedDuration.concat(
                                      this.state.selectedShifts
                                    )}
                                    searchRadius={this.state.searchRadius}
                                    dateRegion={
                                      this.state.startDate &&
                                      this.state.toStartDate
                                        ? moment(this.state.startDate).format(
                                            "MMM DD YY"
                                          ) +
                                          " - " +
                                          moment(this.state.toStartDate).format(
                                            "MMM DD YY"
                                          )
                                        : ""
                                    }
                                    title="Requesting Job Match"
                                    handleModal={this.handleModal.bind(this)}
                                    // specialities = {this.props.location.state.speciality}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="list-job pb-50 loader-center">
                            {" "}
                            <img
                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                              alt="No Image - Travel Nurses Inc"
                              className="loader-center"
                              style={{ width: 80, height: 80 }}
                            />{" "}
                          </div>
                        )}
                        {/* Jobs List Block End Here */}
                        {this.state.jobList.length !== 0 ? (
                          <div className="filter-blk bottom-filter">
                            <div className="filter-fielld show-num float-left">
                              <label>Show</label>
                              <select
                                className="select-box"
                                value={
                                  this.state.selectedPageSize
                                  // this.state.dropdownStatus === true
                                  //   ? this.state.selectedPageSize
                                  //   : this.state.selectedPageSize
                                }
                                name="selectedPageSize"
                                onChange={this.handlePageValues}
                              >
                                {this.state.pageSizes.map((page, index) => {
                                  return (
                                    <option id={index} value={page}>
                                      {page}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <ReactPaginate
                              breakLabel={"....."}
                              breakClassName={"break-me pt-2 false mx-0"}
                              pageCount={this.state.totalPage}
                              marginPagesDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 2)
                              }
                              pageRangeDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 3)
                              }
                              forcePage={this.state.currentPage - 1}
                              onPageChange={this.handlePageClick}
                              // onPageChange={this.onChangePage(i)}
                              containerClassName={
                                "pagination pagination-blk d-flex"
                              }
                              subContainerClassName={
                                "pages pagination page-item "
                              }
                              disabledClassName={"disabledcustom-pagi"}
                              previousLinkClassName={
                                "prve-next search-prev  page-link"
                              }
                              nextLinkClassName={
                                "next-next search-next prve-next page-link"
                              }
                              activeClassName={"active"}
                              pageClassName={"false page-link"}
                              pageLinkClassName={"anchor class"}
                            />
                            <div className="clear"></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        <Leaflet
                          hotJobsFlag={this.state.hotJobsFlag}
                          filters={{
                            certification: this.state.certificationArray,
                            speciality: this.state.specialityArray,
                            city: this.state.cityval,
                            cityState: this.state.cityState,
                            state: this.state.stateCodeArray,
                            searchRadius: this.state.searchRadius,
                            shift: this.state.shift,
                          }}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Tab>
                <Tab eventKey="2" title="Permanent Jobs">
                  <div className="job-tab job-tab-cont">
                    <div className="row">
                      {this.state.userInfo ? (
                        " "
                      ) : (
                        <div className="alert-bttn mbl-alert-bttn">
                          <Button
                            onClick={this.handleUserPopup.bind(this)}
                            className="copy-btn"
                            title="Subscribe for Alerts"
                            id="circuleExtern"
                          >
                            Subscribe for Job Alerts
                          </Button>
                        </div>
                      )}
                      <div className="job-result-count col-lg-10">
                        <Row>
                          <Col className="result-cont col-8 my-auto">
                            {this.state.activeTab === "1" &&
                            this.state.totalItemCount ? (
                              <h4 style={{ color: "#707070" }}>
                                {this.state.totalItemCount == 1
                                  ? "Results: " +
                                    this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"
                                  : "Results: " +
                                    +this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"}
                              </h4>
                            ) : (
                              <h3 className="map-view-help-text">
                                Exact facility locations are not shown below
                              </h3>
                            )}
                          </Col>
                          {this.state.activeTab === "1" && (
                            <Col className="count-drop col-4 text-right">
                              <div className="form-group">
                                <label
                                  className="text-left pr-3"
                                  style={{
                                    color: "#233f8f",
                                    fontWeight: "700",
                                  }}
                                >
                                  Show
                                </label>
                                <select
                                  style={{
                                    width: "auto",
                                  }}
                                  className="select-box w-auto d-inline"
                                  value={
                                    this.state.selectedPageSize
                                    // this.state.dropdownStatus === true
                                    //   ? this.state.selectedPageSize
                                    //   : this.state.selectedPageSize
                                  }
                                  name="selectedPageSize"
                                  onChange={this.handlePageValues}
                                >
                                  {this.state.pageSizes.map((page, index) => {
                                    return (
                                      <option id={index} value={page}>
                                        {page}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <Nav tabs className="main-tab-sec col-lg-2">
                        <div
                          className={`my-auto ml-2 ml-auto mbl-hot-job hot-job ${
                            this.state.hotJobsFlag && `active`
                          }`}
                          style={{ color: "rgb(35, 63, 143)" }}
                        >
                          <a href="javascript:void">
                            <span onClick={this.hotJobsList.bind(this)}>
                              Hot Jobs
                            </span>
                            <img
                              src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                              style={{ width: "40px" }}
                            />
                          </a>
                        </div>
                        {/* <div>
                      <button onClick={(e)=>this.handleChangeList(e,"1")}>Tab 1</button>
                      <button onClick={(e)=>this.handleChangeList(e,"3")}>Tab 2</button>
                    </div> */}
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {this.state.jobListdata === true ? (
                          <div className="list-job pb-50" id="jobs-list-grid">
                            {this.state.jobList.length !== 0 ? (
                              this.state.jobList.map((item, index) => (
                                <div className="job-info block-box">
                                  <div
                                    className="share-btn position-absolute bg-white share-rotate111"
                                    style={{
                                      right: "10px",
                                      top: "8px",
                                    }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Slide to left"
                                      // href="#"
                                      class="button-share pr-0 d-flex"
                                    >
                                      <span class="icon-share">
                                        <i
                                          class="fa fa-share-alt"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text">
                                        <Row className="pr-2 mr-0">
                                          <Col
                                            className="rounded-left text-white text-center px-1 pt-1 "
                                            style={{ background: "#25408f" }}
                                          >
                                            <NotificationContainer />
                                            <FaClone
                                              className=""
                                              onClick={this.copyCodeToClipboard.bind(
                                                this,
                                                item._id
                                              )}
                                            />
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2"
                                            style={{ background: "#00aced" }}
                                          >
                                            <span className="twitter">
                                              <TwitterShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share"
                                                title={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <TwitterIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </TwitterShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 "
                                            style={{ background: "#405992" }}
                                          >
                                            <span className="facebook">
                                              <FacebookShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share w-100"
                                                quote={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <FacebookIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </FacebookShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 rounded-right"
                                            style={{ background: "#007fb1" }}
                                          >
                                            <LinkedinShareButton
                                              url={
                                                AppConstants.Base_Url +
                                                `/job/${item._id}`
                                              }
                                              className="share"
                                              title={`${item.title} Job in ${item.city}, ${item.state} `}
                                              summary={`Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              media="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                            >
                                              <LinkedinIcon
                                                size={32}
                                                round={true}
                                              />
                                            </LinkedinShareButton>
                                          </Col>
                                        </Row>
                                      </span>
                                    </span>
                                  </div>

                                  <ul>
                                    <Link
                                      to={{
                                        pathname: `/job/${item._id}`,
                                        state: {
                                          speciality: this.state.speciality
                                            ? this.state.speciality
                                            : "",
                                          city: this.state.city
                                            ? this.state.city
                                            : "",
                                          state: this.state.stateDuplicate
                                            ? this.state.stateDuplicate
                                            : "",
                                          searchRadius: this.state.searchRadius
                                            ? this.state.searchRadius
                                            : "",
                                        },
                                      }}
                                      target="_blank"
                                    >
                                      <li className="job-location ">
                                        <img
                                          src={this.replaceURL(
                                            item.stateImages[
                                              Math.floor(
                                                Math.random() *
                                                  item.stateImages.length
                                              )
                                            ]
                                          )}
                                          alt={
                                            item.title +
                                            " - " +
                                            item.city +
                                            " - " +
                                            item.state +
                                            " - Travel Nurses Inc"
                                          }
                                        />
                                      </li>
                                      {/* <li className="job-location ">
                                        {stateImages.map((item1, index) => {
                                          if (
                                            item.stateName === item1.name &&
                                            item1.images
                                          ) {
                                            return (
                                              <img
                                                src={
                                                  item1.images[
                                                  Math.floor(
                                                    Math.random() *
                                                    item1.images.length
                                                  )
                                                  ]
                                                }
                                                alt={
                                                  item.title +
                                                  " - " +
                                                  item.city +
                                                  " - " +
                                                  item.state +
                                                  " - Travel Nurses Inc"
                                                }
                                              />
                                            );
                                          }
                                        })}
                                      </li> */}
                                    </Link>
                                    <li className="job-position pt-4">
                                      {/* <h4>{item.title}</h4> */}
                                      <h4
                                        style={{
                                          fontSize: "25px",
                                          color: "#333333",
                                        }}
                                      >
                                        {item.degree}
                                      </h4>
                                      {item.jobSubSpeciality ? (
                                        <p
                                          style={{
                                            fontSize: "23px",
                                            color: "#707070",
                                          }}
                                        >
                                          {item.jobSubSpeciality}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <span className="city-name">
                                        {item.city + ", " + item.state}
                                      </span>
                                    </li>
                                    <li className="job-time">
                                      <p className="date">
                                        <span>
                                          Start Date:{" "}
                                          <em>
                                            {item.workType == "1"
                                              ? item.start_date
                                              : "ASAP"}
                                          </em>
                                        </span>
                                      </p>
                                      {item.workType == "1" && (
                                        <p className="duration">
                                          <span>
                                            Duration:{" "}
                                            <em>
                                              {item.duration
                                                ? item.duration == 1
                                                  ? item.duration + " Week"
                                                  : item.duration + " Weeks"
                                                : "N/A"}
                                            </em>
                                          </span>
                                        </p>
                                      )}
                                      <p className="time">
                                        <span>
                                          {item.workType == "1"
                                            ? item.shift
                                            : item.shiftType}
                                        </span>
                                      </p>
                                    </li>
                                    <li className="btn-section pt-0 pt-lg-4 pt-md-4">
                                      {/* {item.grossPay1 === 0 ? ( */}
                                      {item.grossPay1 < 500 ? (
                                        ""
                                      ) : (
                                        <div className="empty-class">
                                          {nurseId && item.workType == "1" && (
                                            <div
                                              className={`saved-text ${
                                                item.isSaved
                                                  ? ""
                                                  : "addSavedClass"
                                              }`}
                                            >
                                              <button
                                                onClick={(e) =>
                                                  this.handleSaveJobClick(
                                                    e,
                                                    item._id
                                                  )
                                                }
                                              >
                                                {item.isSaved ? (
                                                  <BsFillBookmarkFill />
                                                ) : (
                                                  <BiBookmark />
                                                )}
                                                <span>
                                                  {item.isSaved
                                                    ? "Remove"
                                                    : "Save"}
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                          {item.workType == "1" && (
                                            <p className="est-pay-res pb-0 text-right mr-3">
                                              <div className="est-pay-res-in">
                                                <div
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  Est.pay
                                                  <span className="dollar-sign mx-1">
                                                    &#36;
                                                  </span>
                                                  {/* <span className="currency d-black w-100"></span> */}
                                                </div>

                                                <h4
                                                  className="pb-0"
                                                  style={{ color: "#333333" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "27px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {"$" + item.grossPay1}
                                                  </span>
                                                  /<span>week</span>
                                                </h4>
                                              </div>
                                            </p>
                                          )}
                                        </div>
                                      )}
                                      
                                      <Link
                                        to={{
                                          pathname: `/job/${item._id}`,
                                          search: queryString.stringify({
                                            certification: item.certification || "", 
                                            speciality: item.jobSubSpeciality || "", 
                                            workType: item.workType || "", 
                                          }),
                                          state: {
                                            speciality: this.state.speciality
                                              ? this.state.speciality
                                              : [],
                                            city: this.state.city
                                              ? this.state.city
                                              : [],
                                            state: this.state.stateDuplicate
                                              ? this.state.stateDuplicate
                                              : [],
                                            searchRadius: this.state
                                              .searchRadius
                                              ? this.state.searchRadius
                                              : "",
                                            specialities: this.state
                                              .specialities
                                              ? this.state.specialities
                                              : "",
                                            cities: this.state.cities
                                              ? this.state.cities
                                              : "",
                                            states: this.state.states
                                              ? this.state.states
                                              : "",
                                            duration: this.state.duration,
                                            shift: this.state.shift,
                                            selectedPageSize:
                                              this.state.selectedPageSize,
                                            currentPage: this.state.currentPage,
                                            searchText: this.state.searchText,
                                            // check_duration: this.state.check_duration,
                                            selectedDuration:
                                              this.state.selectedDuration,
                                            selectedShifts:
                                              this.state.selectedShifts,
                                            startDate: this.state.startDate,
                                            toStartDate: this.state.toStartDate,
                                            jobDetailPath: "/job/:id",
                                            pnr: true,
                                          },
                                        }}
                                        className="lead-link jobdetail_link"
                                        target="_blank"
                                      >
                                        View Details
                                      </Link>
                                      <Link
                                        to={{
                                          pathname: `/apply-job/${item._id}`,
                                        }}
                                        className="button mt-4 mt-md-0 mt-lg-0 text-center"
                                        target="_blank"
                                      >
                                        Apply
                                      </Link>
                                      {/* <a href="#" className="forward-link">
                              Send to a friend
                            </a> */}
                                      {/* <Link className="forward-link">
                              Share
                            </Link> */}
                                    </li>
                                  </ul>
                                  {item.pinned ? (
                                    <figure
                                      className="position-absolute rounded-circle bg-white"
                                      style={{
                                        top: "43%",
                                        border: "1px solid #ccc",
                                        right: "-23px",
                                      }}
                                    >
                                      <img
                                        src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                                        style={{
                                          width: "43px",
                                          padding: "0px 6px 6px 6px",
                                        }}
                                      />
                                    </figure>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                            ) : (
                              <span className="no-data">
                                {/* No jobs match this criteria. */}
                                Sorry, looks like we don’t have any of those
                                jobs available at the moment. <br />
                                Jobs refresh hourly, so let's find you a match!
                                <br />
                                <Button onClick={this.handlePopUp.bind(this)}>
                                  Submit a request to be notified for openings
                                </Button>
                                {this.state.modal === true ? (
                                  <ContactRecruiterPopUp
                                    workType={this.state.type}
                                    modal={this.state.modal}
                                    speciality={this.state.speciality}
                                    certification={this.state.certification}
                                    city={this.state.city}
                                    state={this.state.state}
                                    searchText={this.state.searchText}
                                    selectedDurationShifts={this.state.selectedDuration.concat(
                                      this.state.selectedShifts
                                    )}
                                    searchRadius={this.state.searchRadius}
                                    dateRegion={
                                      this.state.startDate &&
                                      this.state.toStartDate
                                        ? moment(this.state.startDate).format(
                                            "MMM DD YY"
                                          ) +
                                          " - " +
                                          moment(this.state.toStartDate).format(
                                            "MMM DD YY"
                                          )
                                        : ""
                                    }
                                    title="Requesting Job Match"
                                    handleModal={this.handleModal.bind(this)}
                                    // specialities = {this.props.location.state.speciality}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="list-job pb-50 loader-center">
                            {" "}
                            <img
                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                              alt="No Image - Travel Nurses Inc"
                              className="loader-center"
                              style={{ width: 80, height: 80 }}
                            />{" "}
                          </div>
                        )}
                        {/* Jobs List Block End Here */}
                        {this.state.jobList.length !== 0 ? (
                          <div className="filter-blk bottom-filter">
                            <div className="filter-fielld show-num float-left">
                              <label>Show</label>
                              <select
                                className="select-box"
                                value={
                                  this.state.selectedPageSize
                                  // this.state.dropdownStatus === true
                                  //   ? this.state.selectedPageSize
                                  //   : this.state.selectedPageSize
                                }
                                name="selectedPageSize"
                                onChange={this.handlePageValues}
                              >
                                {this.state.pageSizes.map((page, index) => {
                                  return (
                                    <option id={index} value={page}>
                                      {page}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <ReactPaginate
                              breakLabel={"....."}
                              breakClassName={"break-me pt-2 false mx-0"}
                              pageCount={this.state.totalPage}
                              marginPagesDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 2)
                              }
                              pageRangeDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 3)
                              }
                              forcePage={this.state.currentPage - 1}
                              onPageChange={this.handlePageClick}
                              // onPageChange={this.onChangePage(i)}
                              containerClassName={
                                "pagination pagination-blk d-flex"
                              }
                              subContainerClassName={
                                "pages pagination page-item "
                              }
                              disabledClassName={"disabledcustom-pagi"}
                              previousLinkClassName={
                                "prve-next search-prev  page-link"
                              }
                              nextLinkClassName={
                                "next-next search-next prve-next page-link"
                              }
                              activeClassName={"active"}
                              pageClassName={"false page-link"}
                              pageLinkClassName={"anchor class"}
                            />
                            <div className="clear"></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPane>

                      <TabPane tabId="2">
                        {/* <Leaflet
                          hotJobsFlag={this.state.hotJobsFlag}
                          filters={{
                            certification: this.state.certificationArray,
                            speciality: this.state.specialityArray,
                            city: this.state.cityval,
                            cityState: this.state.cityState,
                            state: this.state.stateCodeArray,
                            searchRadius: this.state.searchRadius,
                            shift: this.state.shift,
                          }}
                        /> */}
                      </TabPane>
                    </TabContent>
                  </div>
                </Tab>
                <Tab eventKey="3" title="PRN Jobs">
                  <div className="job-tab job-tab-cont">
                    <div className="row">
                      {this.state.userInfo ? (
                        " "
                      ) : (
                        <div className="alert-bttn mbl-alert-bttn">
                          <Button
                            onClick={this.handleUserPopup.bind(this)}
                            className="copy-btn"
                            title="Subscribe for Alerts"
                            id="circuleExtern"
                          >
                            Subscribe for Job Alerts
                          </Button>
                        </div>
                      )}
                      <div className="job-result-count col-lg-10">
                        <Row>
                          <Col className="result-cont col-8 my-auto">
                            {this.state.activeTab === "1" &&
                            this.state.totalItemCount ? (
                              <h4 style={{ color: "#707070" }}>
                                {this.state.totalItemCount == 1
                                  ? "Results: " +
                                    this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"
                                  : "Results: " +
                                    +this.state.totalItemCount +
                                    " Travel Nurse Jobs Found"}
                              </h4>
                            ) : (
                              <h3 className="map-view-help-text">
                                Exact facility locations are not shown below
                              </h3>
                            )}
                          </Col>
                          {this.state.activeTab === "1" && (
                            <Col className="count-drop col-4 text-right">
                              <div className="form-group">
                                <label
                                  className="text-left pr-3"
                                  style={{
                                    color: "#233f8f",
                                    fontWeight: "700",
                                  }}
                                >
                                  Show
                                </label>
                                <select
                                  style={{
                                    width: "auto",
                                  }}
                                  className="select-box w-auto d-inline"
                                  value={
                                    this.state.selectedPageSize
                                    // this.state.dropdownStatus === true
                                    //   ? this.state.selectedPageSize
                                    //   : this.state.selectedPageSize
                                  }
                                  name="selectedPageSize"
                                  onChange={this.handlePageValues}
                                >
                                  {this.state.pageSizes.map((page, index) => {
                                    return (
                                      <option id={index} value={page}>
                                        {page}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <Nav tabs className="main-tab-sec col-lg-2">
                        <div
                          className={`my-auto ml-2 ml-auto mbl-hot-job hot-job ${
                            this.state.hotJobsFlag && `active`
                          }`}
                          style={{ color: "rgb(35, 63, 143)" }}
                        >
                          <a href="javascript:void">
                            <span onClick={this.hotJobsList.bind(this)}>
                              Hot Jobs
                            </span>
                            <img
                              src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                              style={{ width: "40px" }}
                            />
                          </a>
                        </div>
                        {/* <div>
                      <button onClick={(e)=>this.handleChangeList(e,"1")}>Tab 1</button>
                      <button onClick={(e)=>this.handleChangeList(e,"3")}>Tab 2</button>
                    </div> */}
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {this.state.jobListdata === true ? (
                          <div className="list-job pb-50" id="jobs-list-grid">
                            {this.state.jobList.length !== 0 ? (
                              this.state.jobList.map((item, index) => (
                                <div className="job-info block-box">
                                  <div
                                    className="share-btn position-absolute bg-white share-rotate111"
                                    style={{
                                      right: "10px",
                                      top: "8px",
                                    }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Slide to left"
                                      // href="#"
                                      class="button-share pr-0 d-flex"
                                    >
                                      <span class="icon-share">
                                        <i
                                          class="fa fa-share-alt"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text">
                                        <Row className="pr-2 mr-0">
                                          <Col
                                            className="rounded-left text-white text-center px-1 pt-1 "
                                            style={{ background: "#25408f" }}
                                          >
                                            <NotificationContainer />
                                            <FaClone
                                              className=""
                                              onClick={this.copyCodeToClipboard.bind(
                                                this,
                                                item._id
                                              )}
                                            />
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2"
                                            style={{ background: "#00aced" }}
                                          >
                                            <span className="twitter">
                                              <TwitterShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share"
                                                title={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <TwitterIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </TwitterShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 "
                                            style={{ background: "#405992" }}
                                          >
                                            <span className="facebook">
                                              <FacebookShareButton
                                                url={
                                                  AppConstants.Base_Url +
                                                  `/job/${item._id}`
                                                }
                                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                                className="share w-100"
                                                quote={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              >
                                                <FacebookIcon
                                                  size={32}
                                                  round={true}
                                                />
                                              </FacebookShareButton>
                                            </span>
                                          </Col>
                                          <Col
                                            className="pl-0 pr-2 rounded-right"
                                            style={{ background: "#007fb1" }}
                                          >
                                            <LinkedinShareButton
                                              url={
                                                AppConstants.Base_Url +
                                                `/job/${item._id}`
                                              }
                                              className="share"
                                              title={`${item.title} Job in ${item.city}, ${item.state} `}
                                              summary={`Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                              media="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                            >
                                              <LinkedinIcon
                                                size={32}
                                                round={true}
                                              />
                                            </LinkedinShareButton>
                                          </Col>
                                        </Row>
                                      </span>
                                    </span>
                                  </div>

                                  <ul>
                                    <Link
                                      to={{
                                        pathname: `/job/${item._id}`,
                                        state: {
                                          speciality: this.state.speciality
                                            ? this.state.speciality
                                            : "",
                                          city: this.state.city
                                            ? this.state.city
                                            : "",
                                          state: this.state.stateDuplicate
                                            ? this.state.stateDuplicate
                                            : "",
                                          searchRadius: this.state.searchRadius
                                            ? this.state.searchRadius
                                            : "",
                                        },
                                      }}
                                      target="_blank"
                                    >
                                      <li className="job-location ">
                                        <img
                                          src={this.replaceURL(
                                            item.stateImages[
                                              Math.floor(
                                                Math.random() *
                                                  item.stateImages.length
                                              )
                                            ]
                                          )}
                                          alt={
                                            item.title +
                                            " - " +
                                            item.city +
                                            " - " +
                                            item.state +
                                            " - Travel Nurses Inc"
                                          }
                                        />
                                      </li>
                                      {/* <li className="job-location ">
                                        {stateImages.map((item1, index) => {
                                          if (
                                            item.stateName === item1.name &&
                                            item1.images
                                          ) {
                                            return (
                                              <img
                                                src={
                                                  item1.images[
                                                  Math.floor(
                                                    Math.random() *
                                                    item1.images.length
                                                  )
                                                  ]
                                                }
                                                alt={
                                                  item.title +
                                                  " - " +
                                                  item.city +
                                                  " - " +
                                                  item.state +
                                                  " - Travel Nurses Inc"
                                                }
                                              />
                                            );
                                          }
                                        })}
                                      </li> */}
                                    </Link>
                                    <li className="job-position pt-4">
                                      {/* <h4>{item.title}</h4> */}
                                      <h4
                                        style={{
                                          fontSize: "25px",
                                          color: "#333333",
                                        }}
                                      >
                                        {item.degree}
                                      </h4>
                                      {item.jobSubSpeciality ? (
                                        <p
                                          style={{
                                            fontSize: "23px",
                                            color: "#707070",
                                          }}
                                        >
                                          {item.jobSubSpeciality}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <span className="city-name">
                                        {item.city + ", " + item.state}
                                      </span>
                                    </li>
                                    <li className="job-time">
                                      <p className="date">
                                        <span>
                                          Start Date:{" "}
                                          <em>
                                            {item.workType == "1"
                                              ? item.start_date
                                              : "ASAP"}
                                          </em>
                                        </span>
                                      </p>
                                      {item.workType == "1" && (
                                        <p className="duration">
                                          <span>
                                            Duration:{" "}
                                            <em>
                                              {item.duration
                                                ? item.duration == 1
                                                  ? item.duration + " Week"
                                                  : item.duration + " Weeks"
                                                : "N/A"}
                                            </em>
                                          </span>
                                        </p>
                                      )}
                                      <p className="time">
                                        <span>
                                          {item.workType == "1"
                                            ? item.shift
                                            : item.shiftType}
                                        </span>
                                      </p>
                                    </li>
                                    <li className="btn-section pt-0 pt-lg-4 pt-md-4">
                                      {/* {item.grossPay1 === 0 ? ( */}
                                      {item.grossPay1 < 500 ? (
                                        ""
                                      ) : (
                                        <div className="empty-class">
                                          {nurseId && item.workType == "1" && (
                                            <div
                                              className={`saved-text ${
                                                item.isSaved
                                                  ? ""
                                                  : "addSavedClass"
                                              }`}
                                            >
                                              <button
                                                onClick={(e) =>
                                                  this.handleSaveJobClick(
                                                    e,
                                                    item._id
                                                  )
                                                }
                                              >
                                                {item.isSaved ? (
                                                  <BsFillBookmarkFill />
                                                ) : (
                                                  <BiBookmark />
                                                )}
                                                <span>
                                                  {item.isSaved
                                                    ? "Remove"
                                                    : "Save"}
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                          {item.workType == "1" && (
                                            <p className="est-pay-res pb-0 text-right mr-3">
                                              <div className="est-pay-res-in">
                                                <div
                                                  style={{ fontSize: "20px" }}
                                                >
                                                  Est.pay
                                                  <span className="dollar-sign mx-1">
                                                    &#36;
                                                  </span>
                                                  {/* <span className="currency d-black w-100"></span> */}
                                                </div>

                                                <h4
                                                  className="pb-0"
                                                  style={{ color: "#333333" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "27px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {"$" + item.grossPay1}
                                                  </span>
                                                  /<span>week</span>
                                                </h4>
                                              </div>
                                            </p>
                                          )}
                                        </div>
                                      )}
                                      <Link
                                        to={{
                                          pathname: `/job/${item._id}`,
                                          search: queryString.stringify({
                                            certification: item.certification || "", 
                                            speciality: item.jobSubSpeciality || "", 
                                            workType: item.workType || "", 
                                          }),
                                          state: {
                                            speciality: this.state.speciality
                                              ? this.state.speciality
                                              : [],
                                            city: this.state.city
                                              ? this.state.city
                                              : [],
                                            state: this.state.stateDuplicate
                                              ? this.state.stateDuplicate
                                              : [],
                                            searchRadius: this.state
                                              .searchRadius
                                              ? this.state.searchRadius
                                              : "",
                                            specialities: this.state
                                              .specialities
                                              ? this.state.specialities
                                              : "",
                                            cities: this.state.cities
                                              ? this.state.cities
                                              : "",
                                            states: this.state.states
                                              ? this.state.states
                                              : "",
                                            duration: this.state.duration,
                                            shift: this.state.shift,
                                            selectedPageSize:
                                              this.state.selectedPageSize,
                                            currentPage: this.state.currentPage,
                                            searchText: this.state.searchText,
                                            // check_duration: this.state.check_duration,
                                            selectedDuration:
                                              this.state.selectedDuration,
                                            selectedShifts:
                                              this.state.selectedShifts,
                                            startDate: this.state.startDate,
                                            toStartDate: this.state.toStartDate,
                                            jobDetailPath: "/job/:id",
                                            pnr: true,
                                          },
                                        }}
                                        className="lead-link jobdetail_link text-center"
                                        target="_blank"
                                      >
                                        View Details
                                      </Link>
                                      <Link
                                        to={{
                                          pathname: `/apply-job/${item._id}`,
                                        }}
                                        className="button mt-4 mt-md-0 mt-lg-0"
                                        target="_blank"
                                      >
                                        Apply
                                      </Link>
                                      {/* <a href="#" className="forward-link">
                              Send to a friend
                            </a> */}
                                      {/* <Link className="forward-link">
                              Share
                            </Link> */}
                                    </li>
                                  </ul>
                                  {item.pinned ? (
                                    <figure
                                      className="position-absolute rounded-circle bg-white"
                                      style={{
                                        top: "43%",
                                        border: "1px solid #ccc",
                                        right: "-23px",
                                      }}
                                    >
                                      <img
                                        src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif"
                                        style={{
                                          width: "43px",
                                          padding: "0px 6px 6px 6px",
                                        }}
                                      />
                                    </figure>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                            ) : (
                              <span className="no-data">
                                {/* No jobs match this criteria. */}
                                Sorry, looks like we don’t have any of those
                                jobs available at the moment. <br />
                                Jobs refresh hourly, so let's find you a match!
                                <br />
                                <Button onClick={this.handlePopUp.bind(this)}>
                                  Submit a request to be notified for openings
                                </Button>
                                {this.state.modal === true ? (
                                  <ContactRecruiterPopUp
                                    workType={this.state.type}
                                    modal={this.state.modal}
                                    speciality={this.state.speciality}
                                    certification={this.state.certification}
                                    city={this.state.city}
                                    state={this.state.state}
                                    searchText={this.state.searchText}
                                    selectedDurationShifts={this.state.selectedDuration.concat(
                                      this.state.selectedShifts
                                    )}
                                    searchRadius={this.state.searchRadius}
                                    dateRegion={
                                      this.state.startDate &&
                                      this.state.toStartDate
                                        ? moment(this.state.startDate).format(
                                            "MMM DD YY"
                                          ) +
                                          " - " +
                                          moment(this.state.toStartDate).format(
                                            "MMM DD YY"
                                          )
                                        : ""
                                    }
                                    title="Requesting Job Match"
                                    handleModal={this.handleModal.bind(this)}
                                    // specialities = {this.props.location.state.speciality}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="list-job pb-50 loader-center">
                            {" "}
                            <img
                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                              alt="No Image - Travel Nurses Inc"
                              className="loader-center"
                              style={{ width: 80, height: 80 }}
                            />{" "}
                          </div>
                        )}
                        {/* Jobs List Block End Here */}
                        {this.state.jobList.length !== 0 ? (
                          <div className="filter-blk bottom-filter">
                            <div className="filter-fielld show-num float-left">
                              <label>Show</label>
                              <select
                                className="select-box"
                                value={
                                  this.state.selectedPageSize
                                  // this.state.dropdownStatus === true
                                  //   ? this.state.selectedPageSize
                                  //   : this.state.selectedPageSize
                                }
                                name="selectedPageSize"
                                onChange={this.handlePageValues}
                              >
                                {this.state.pageSizes.map((page, index) => {
                                  return (
                                    <option id={index} value={page}>
                                      {page}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <ReactPaginate
                              breakLabel={"....."}
                              breakClassName={"break-me pt-2 false mx-0"}
                              pageCount={this.state.totalPage}
                              marginPagesDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 2)
                              }
                              pageRangeDisplayed={
                                (this.props,
                                window.innerWidth >= 320 && this.props,
                                window.innerWidth <= 767 ? 1 : 3)
                              }
                              forcePage={this.state.currentPage - 1}
                              onPageChange={this.handlePageClick}
                              // onPageChange={this.onChangePage(i)}
                              containerClassName={
                                "pagination pagination-blk d-flex"
                              }
                              subContainerClassName={
                                "pages pagination page-item "
                              }
                              disabledClassName={"disabledcustom-pagi"}
                              previousLinkClassName={
                                "prve-next search-prev  page-link"
                              }
                              nextLinkClassName={
                                "next-next search-next prve-next page-link"
                              }
                              activeClassName={"active"}
                              pageClassName={"false page-link"}
                              pageLinkClassName={"anchor class"}
                            />
                            <div className="clear"></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </TabPane>

                      <TabPane tabId="2">
                        {/* <Leaflet
                          hotJobsFlag={this.state.hotJobsFlag}
                          filters={{
                            certification: this.state.certificationArray,
                            speciality: this.state.specialityArray,
                            city: this.state.cityval,
                            cityState: this.state.cityState,
                            state: this.state.stateCodeArray,
                            searchRadius: this.state.searchRadius,
                            shift: this.state.shift,
                          }}
                        /> */}
                      </TabPane>
                    </TabContent>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Searchjobs;
