import React, { Component } from "react";
import styles from "../Styles/print.css";
import AppConstants from "../AppConstants";
import axios from "axios";
import moment from 'moment';
class Resume extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            experiences: [],
            education: {},
            licenses: [],
            certifications: [],
            primaryEmail: "",
            primaryPhoneNumber: "",
            yearOfGraduation: "",
            university: "",
            eduCity: "",
            eduState: "",
            major: "",
            multipleExperinces: [],
            fullName: "",
            healthCareFields: []
        }
    }
    /* add class function */
    componentWillMount() {
        document.getElementById("overall").className = "resume hide-block";
    }
    componentDidMount () {
        const that = this;
        axios.get(AppConstants.API +"/nurse/getSlugBasedNurse/" + this.props.match.params.slug).then((res) => {
          return res.data;
        }).then(function (data) {
          console.log(data, "data.data");
          if (data.Status === "Success") {
            console.log(data,"line587", data.data);
          that.setState({
            id: data.data._id,
            certifications: data.certifications,
            // certifications: data.data.proffesionalInformation.certifications,
            // licenses: data.data.proffesionalInformation.licenses ,
            licenses: data.data.proffesionalInformation.certifications,
            specialityNames: data.specialities,
            firstName:data.data.firstName,
            fullName:data.data.firstName +" "+ data.data.middleName +" "+ data.data.lastName,
            middleName: data.data.middleName,
            lastName: data.data.lastName,
            nickName: data.data.nickName,
            maidenName: data.data.maidenName,
            primaryEmail: data.data.contactInformation.primaryEmail,
            primaryPhoneNumber: data.data.contactInformation.primaryPhoneNumber,
            prefContactMethod: data.data.contactInformation.prefferedContactMethod,
            bestContactTime: data.data.contactInformation.bestTimeToContact,
            alternativePhoneNumber: data.data.contactInformation.alternativePhoneNumber,
            secondaryEmail: data.data.contactInformation.secondaryEmail,
            healthCareFields: data.healthCareField
          });
          if (data.data.education) {
            that.setState({
              eduCity: data.data.education.city,
              eduState: data.data.education.state,
              major: data.data.education.major,
              university: data.data.education.university,
            })
            if (data.data.education.yearOfGraduation) {
            //   that.setState({yearOfGraduation: moment(data.data.education.yearOfGraduation)})
            //   that.setState({yearOfGraduation: new Date(data.data.education.yearOfGraduation)})
              var dateTime =  new Date(data.data.education.yearOfGraduation);
              var yog = dateTime.getFullYear()+'/' + (dateTime.getMonth()+1)//prints expected format.
              that.setState({yearOfGraduation: yog});
             
            }
          }
        //   if (data.data.proffesionalInformation.specialities) {
        //     var specArray = [];
        //     data.data.proffesionalInformation.specialities.map((item,index)=>{
        //       var specData = {};
        //       if (item.speciality === null) {
        //         specData["speciality"] = "other";
        //         specData["otherSpeciality"] = item.otherSpeciality;
        //         specData["yearsOfExperience"] = item.yearsOfExperience;
        //         specArray.push(specData);
        //       } else {
        //         specData["speciality"] = item.speciality;
        //         specData["otherSpeciality"] = item.otherSpeciality;
        //         specData["yearsOfExperience"] = item.yearsOfExperience;
        //         specArray.push(specData);
        //       }
        //     })
        //     that.setState({multipleSpeciality: specArray})
        //   }
          if (data.data.experiences) {
            var multipleExperinces1 = []
            data.data.experiences.map((item,index)=>{
              var exp = {}
              if (item.startDate) {
                var dateTime =  new Date(item.startDate);
                var sDate = dateTime.getFullYear()+'/' + (dateTime.getMonth()+1)+'/'+dateTime.getDate()
                exp["startDate"]= sDate
              } else {
                exp["startDate"]= ""
              }
              if (item.endDate) {
                var dateTime =  new Date(item.endDate);
                var eDate = dateTime.getFullYear()+'/' + (dateTime.getMonth()+1)+'/'+dateTime.getDate()
                exp["endDate"]= eDate
              } else {
                exp["endDate"]= ""
              }
              // exp["endDate"]= item.endDate
              exp["hospital"]= item.hospital
              exp["city"]= item.city
              exp["state"]= item.state
              exp["duties"]= item.duties
              exp["travelNurseUnit"]= item.travelNurseUnit
              exp["present"]= item.present
              multipleExperinces1.push(exp)
            })
            that.setState({multipleExperinces: multipleExperinces1})
          }
        }
      })
    }
    render() {
      window.scrollTo(0, 0);
        return(
           <div className="resume-view">
              <div className="header-block">
                   <div className="container">
                       <h1>{this.state.fullName}</h1>
                   </div>
                   <button  class="resume back-btn" onClick={() => this.props.history.go(-1)}>
                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                        Back
                    </button>
               </div>
               <div className="contact">
               <div className="text">
                    {/* <span to="/"> {this.state.fullName} </span> */}
                    {this.state.primaryPhoneNumber ? <span to="/" className="phone">{this.state.primaryPhoneNumber.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "$1.$2.$3"
                    )}</span> : ""}
                   <span to="/" className="mail"> {this.state.primaryEmail} </span>
                   </div>
               </div>
               <div className="clear"></div>
               <div className="content-area">
               <div className="container">
                 {this.state.multipleExperinces.length > 0 ? 
                   <div className="experience-blk">
                    <h2>Experience</h2>
                    <div className="content">
                        {this.state.multipleExperinces.map((item,index)=>{
                            return <div className="details-sec">
                            <div className="left-block">
                            {item.present === true ? <span className="date">{item.startDate} - present</span> : <span className="date">{item.startDate} - {item.endDate}</span>}
                        
                            </div>
                            <div className="right-block">
                            <div className="info">
                                <b>{item.hospital}, {item.city}, {item.state}. </b>
                                {item.travelNurseUnit ? <p>Travel Nurse: <span>{item.travelNurseUnit}</span></p> : ""} 
                                <p>Duties: <span>{item.duties.length > 0 ? item.duties.toString(): ""}</span></p>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        })}
                    
                    {/* <div className="details-sec">
                        <div className="left-block">
                            <span className="date">11/2018 - present</span>
                        </div>
                        <div className="right-block">
                        <div className="info">
                            <b>Bapist Memorial Hospital, Memphis, TN. </b>
                            <p>Travel Nurse: <span>ICU</span></p>
                            <p>Duties: <span>Worked as a change nurse, provied care, monitored, etc...</span></p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="details-sec">
                        <div className="left-block">
                            <span className="date">06/2018 - 10/2018</span>
                        </div>
                        <div className="right-block">
                        <div className="info">
                            <b>Bapist Memorial Hospital, Memphis, TN. </b>
                            <p>Travel Nurse: <span>ICU</span></p>
                            <p>Duties: <span>Worked as a change nurse, provied care, monitored, etc...</span></p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="details-sec">
                        <div className="left-block">
                        <span className="date">06/2015 - 07/2016</span>
                        </div>
                        <div className="right-block">
                        <div className="info">
                            <b>Bapist Memorial Hospital, Memphis, TN. </b>
                            <p>Travel Nurse: <span>ICU</span></p>
                            <p>Duties: <span>Worked as a change nurse, provied care, monitored, etc...</span></p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="details-sec">
                        <div className="left-block">
                        <span  className="date">05/2013 - 06/2015</span>
                        </div>
                        <div className="right-block">
                        <div className="info">
                            <b>Bapist Memorial Hospital, Memphis, TN. </b>
                            <p>Travel Nurse: <span>ICU</span></p>
                            <p>Duties: <span>Worked as a change nurse, provied care, monitored, etc...</span></p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="details-sec">
                        <div className="left-block">
                        <span className="date">02/2012 - 06/2013</span>
                        </div>
                        <div className="right-block">
                            <div className="info">
                            <b>Bapist Memorial Hospital, Memphis, TN. </b>
                            <p>Travel Nurse: <span>ICU</span></p>
                            <p>Duties: <span>Worked as a change nurse, provied care, monitored, etc...</span></p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div> */}

                    </div>                    
                    </div>: ""}
                    {this.state.yearOfGraduation ?  <div className="education block">
                        <h3>Education</h3>
                        <div className="info">
                   <span  className="date">{this.state.yearOfGraduation}</span>
                        <b>{this.state.university}</b>                            
                            <span>{this.state.major}</span>
                            </div>
                    </div> : ""}
                   
                    {this.state.certifications.length > 0 ? 
                    <div className="block">                    
                   <h3>Certifications: {this.state.certifications.map((item,indexCert)=>{ return <span>{item.certification}{this.state.certifications.length === indexCert +1 ? "" : ", "}</span> })}</h3>
                    </div>
                    : ""}
                    {this.state.licenses.length > 0 ? 
                    <div className="block">
                      <h3>License: <span>{this.state.licenses.map((item,indexLic)=>{
                        // console.log(indexLic,this.state.healthCareFields[indexLic],this.state.healthCareFields[indexLic] === undefined)
                        // +" - "+ this.state.healthCareFields[indexLic].healthCare
                          return <span>{this.state.healthCareFields[indexLic] === undefined ? item.state+" - "+item.licenseNumber : item.state+" - "+item.licenseNumber+" - "+ this.state.healthCareFields[indexLic].healthCare}{this.state.licenses.length === indexLic +1 ? "" : ", "}</span>
                      })}</span></h3>
                    </div>  
                    : ""}                  
               </div>
               </div>
               <div className="bottom-sec">
            </div>
           </div>
       )
   }
}

export default Resume;