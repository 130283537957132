import { SAVE_JOB, SAVE_JOB_ERROR, SAVE_JOB_SUCCESS } from "../actions/types";

const initialState = {
  dashboardSaveAction: false,
  loading: false,
};

export default function (state = initialState, action) {
  // console.log("action.type", action.payload);

  switch (action.type) {
    case SAVE_JOB:
      return {
        ...state,
        loading: true,
        dashboardSaveAction: true,
      };
    case SAVE_JOB_SUCCESS:
      return {
        ...state,
        dashboardSaveAction: false,
      };
    case SAVE_JOB_ERROR:
      return {
        ...state,
        dashboardSaveAction: false,
      };

    default:
      return state;
  }
}
