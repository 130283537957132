import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import MessagePopUp from "../Pages/message_popup";
class Teamblock extends React.Component {
  constructor() {
    super();
    this.state = {
      recruiters: [],
      modal: false,
      slidesToShow: 4,
      phoneNumb: "",
    };
    this.handleModal = this.handleModal.bind(this);
  }

  handleMessage(numb, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    console.log("Opening modal with phone number:", numb);
    this.setState({ modal: true, phoneNumb: numb });
  }

  handleModal() {
    console.log("Toggling modal. Current state:", this.state.modal);
    this.setState((prevState) => ({
      modal: !prevState.modal,
      phoneNumb: prevState.modal ? "" : prevState.phoneNumb, // Reset phone number on close
    }));
  }

  componentDidMount() {
    axios
      .get(AppConstants.API + "/users/getRecruitersListRandomly")
      .then((res) => res.data)
      .then((data) => {
        this.setState({ recruiters: data.data });
      })
      .catch((error) => console.error("Error fetching recruiters:", error));
  }

  render() {
    const settings = {
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      lazyLoad: true,
    };

    return (
      <div className="team-block text-center" id="team-grid-block">
        {this.state.recruiters.length > 0 ? (
          <Container>
            <h3 className="head-title">{AppConstants.R_Specialist}</h3>
            <div className="inner-content">
              <Slider {...settings}>
                {this.state.recruiters.map((item) => (
                  <div className="item" key={item._id}>
                    <Card className="block-box">
                      <figure className="pic image-fill">
                        <img
                          src={item.profileImage}
                          alt={`${item.name} - Travel Nurses Inc`}
                        />
                      </figure>
                      <CardBody>
                        <CardTitle>
                          <Link
                            to={{
                              pathname: "/team/" + item.slug,
                              state: { recruiter_id: item._id },
                            }}
                            target="_blank"
                          >
                            {item.name}
                          </Link>
                          {item.tags.length > 0 ? ", " + item.tags.toString() : ""}
                        </CardTitle>
                        <CardText>
                          {item.title ? item.title : item.designation}
                        </CardText>
                        <div className="social-icons">
                          {item.socialMedia.map((item1, index) => (
                            <a
                              key={index}
                              href={item1.url}
                              target="_blank"
                              className={item1.name}
                              rel="noopener noreferrer"
                            ></a>
                          ))}
                          {item.phone && (
                            <a
                              
                              className="comment"
                              onClick={(e) => this.handleMessage(item.phone, e)}
                             
                            >
                            </a>
                          )}
                        </div>
                      </CardBody>
                      <Link
                        to={{
                          pathname: "/team/" + item.slug,
                          state: { recruiter_id: item._id },
                        }}
                        className="link"
                      />
                    </Card>
                  </div>
                ))}
              </Slider>
            </div>
          </Container>
        ) : (
          ""
        )}

        {this.state.modal && (
          <MessagePopUp
            modal={this.state.modal}
            phone={this.state.phoneNumb}
            handleModal={() => this.handleModal()}
          />
        )}
      </div>
    );
  }
}

export default Teamblock;
