import React, { Component } from "react";
import images from "../Components/images";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
import { connect } from "react-redux";
 
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
class OTP extends React.Component {
  constructor(props, context) {
    console.log(props.userID, "userID");
    super(props, context);
    this.state = {
      otp: "",

      otpError: false,
      errors: [],
      isLoading: "",
      errMsg: "",
      button_disable: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleInputChange(e) {
    console.log("event", e.target.value);

    this.setState({
      otp: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (this.state.otp === "otp") {
      if (e.target.value === "") {
        this.setState({ otpError: true });
      } else {
        this.setState({ otpError: false });
      }
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.otp) {
      formIsValid = false;
      errors["otp"] = "Please enter OTP.";
      ReactDOM.findDOMNode(this.refs.otp).focus();
    }
    if (this.state.otp.length < 4) {
      formIsValid = false;
      errors["otp"] = "Please enter valid OTP.";
      ReactDOM.findDOMNode(this.refs.otp).focus();
    }

    this.setState({
      errors: errors,
      otpError: true,
    });
    return formIsValid;
  }

  handleSubmit() {
    const userID = localStorage.getItem("userID");
    console.log(userID, "userID");
    if (this.handleValidation()) {
      const formData = {
        otp: this.state.otp,
        id: userID,
      };

      this.setState({ isLoading: 1 });
      axios
        .post(AppConstants.API + "/nurse/verify/otp", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            NotificationManager.success(res.data.msg, "", 3000);
            this.props.history.push(`/set-password/${userID}`);
          }
        })
        .catch((err) => {
          if (err) {
            this.setState({ isLoading: 0 });
            NotificationManager.error(err.response.data.msg, "", 3000);
            this.props.history.push("/resend-otp");
          }
        });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <NotificationContainer />
        <Container>
          <div className="login-grid">
            <div className="inner-block">
              <Row>
                <Col xs="6" className="block left-block">
                  <figure className="pic image-fill">
                    {images.map((e, idx) => (
                      <img
                        // src={e.LoginPic}
                        src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                        alt={
                          "Best Travel Nursing Company, Nursing Agency | Travel Nurses Inc-" +
                          idx
                        }
                      />
                    ))}
                  </figure>
                </Col>
                <Col xs="6" className="block right-block" id="otp-form">
                  <div className="form-sec text-center">
                    <div className="title">
                      Verify One Time <br />
                      Passcode
                    </div>

                    <Form
                      className="form-block otp-field-grid"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="otp-input-wrapper">
                        <input
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          autocomplete="off"
                          onChange={this.handleInputChange}
                          ref="otp"
                        />
                        <svg
                          viewBox="0 0 240 1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="0"
                            y1="0"
                            x2="240"
                            y2="0"
                            stroke="#3e3e3e"
                            stroke-width="2"
                            stroke-dasharray="44,22"
                          />
                        </svg>
                      </div>

                      {/* 
                      <FormGroup>
                        {this.state.otpStatus !== true && (
                          <Label for="exampleNumber">Enter Your OTP</Label>
                        )}

                        <Input
                          id="exampleNumber"
                          name="otp"
                          type="text"
                          ref="otp"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          onChange={this.handleInputChange}
                        />
                      </FormGroup> */}
                      <div>
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          {this.state.errors["otp"]}
                        </span>
                      </div>

                      <Button
                        className="button"
                        onClick={this.handleSubmit}
                        disabled={this.state.button_disable}
                      >
                        Verify
                        {this.state.isLoading ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                      <div>
                        <Link className="forget-psw" to="/login">
                          <FiArrowLeft /> <span>Back to Login</span>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userID, msg } = auth;
  return { loading, error, userID, msg };
};

export default connect(mapStateToProps, {})(OTP);
