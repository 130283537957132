import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardBody,
} from "reactstrap";

import AppConstants from "../AppConstants";
import axios from "axios";
 
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCountries } from "../Components/helpers/network-calls";

class AskQuestion extends React.Component {
  /* add class function */
  constructor(props) {
    super(props);
    this.state = {
      applyData: null,
      jobTitle: "",
      jobId: "",
      button_disable: false,
      firstName: "",
      lastName: "",
      code: "+1",
      phone: "",
      email: "",
      question: "",
      errors: {},
      errorsCode: {},
      firstNameError: false,
      lastNameError: false,
      phone_error: false,
      emailError: false,
      questionError: false,
      codeError: false,
      errMsg: "",
      isLoading: false,
      items: [],
      location: "",
      dropdownStatus: false,
      countries: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.getAllCountries();
    this.setState({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.primaryEmail : "",
      phone: user ? user.primaryPhoneNumber : "",
    });
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""), //Trim Leading and Trailing
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }

    if (e.target.name === "phone") {
      if (e.target.value === "") {
        this.setState({ phone_error: true });
      } else {
        this.setState({ phone_error: false });
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "question") {
      if (e.target.value === "") {
        this.setState({ questionError: true });
      } else {
        this.setState({ questionError: false });
      }
    }
  };

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
      //Trim Leading and Trailing
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter your first name.";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter your last name.";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = " Please enter your phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email id.";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }

    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter your valid email.";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.question) {
      formIsValid = false;
      errors["question"] = " Please enter your question.";
      ReactDOM.findDOMNode(this.refs.question).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
      questionError: true,
      codeError: true,
    });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });

      let formData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        code: this.state.code,
        phone: this.state.phone,
        email: this.state.email,
        question: this.state.question,
        title: this.props.title,
        jobId: this.props.jobId,
        workType: this.props.workType,
      };

      axios
        .post(
          AppConstants.Jobs_API + "/jobs/sendMailToContactRecruiter",
          formData
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            setTimeout((e) => {
              this.handleCancel();
            }, 3000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  handleCancel(e) {
    this.props.handleUserModelPopup();
    this.setState({
      firstName: "",
      phone: "",
      isLoading: false,
    });
  }
  render() {
    return (
      <Modal
        isOpen={this.props.showUserPopUp}
        toggle={this.props.handleUserModelPopup}
        className="modal-lg contact-rect recruiter-modal request-job-sec-modal"
        id="Tn-popup"
        style={{ color: "#25408f", fontWeight: "500" }}
      >
        <ModalHeader
          toggle={this.handleCancel}
          className="text-center pt-4 pb-1"
          style={{ color: "#25408f" }}
        >
          <h4> Ask a question about this job</h4>
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="apply-form registr-info main-page">
            <Card
              className="w-100 p-0 m-0 md-pop pb-5"
              style={{ borderRadius: "0px 0px 25px 25px" }}
            >
              <CardBody>
                <Form className="inner-block" onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="FirstName">
                          First Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          id="FirstName"
                          value={this.state.firstName}
                          onBlur={() => this.state.firstName}
                          onChange={this.handleChange}
                          ref="firstName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.firstNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["firstName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="Last Name">
                          Last Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          value={this.state.lastName}
                          onBlur={() => this.state.lastName}
                          placeholder="Last Name"
                          id="LastName"
                          onChange={this.handleChange}
                          ref="lastName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.lastNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["lastName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="codephone-blk">
                    <div class="block small-block country-code col-1">
                      <FormGroup>
                        <Label for="Code">
                          Country Code
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="select"
                          value={this.state.code}
                          name="code"
                          ref="code"
                          onChange={this.handleCodeChange}
                          id="Code"
                        >
                          {this.state.countries.map((item) => {
                            return (
                              <option
                                data-countryCode={item.phoneCode}
                                value={"+" + item.phoneCode}
                              >
                                {item.phoneCode === 1 ? "USA" : item.name}{" "}
                                {"+" + item.phoneCode}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </div>
                    <div class="block widht-40 col-5 phone-input">
                      <FormGroup>
                        <Label for="Phone">
                          Phone
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <NumberFormat
                          format="(###) ###-####"
                          mask=""
                          name="phone"
                          id="Phone"
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                          value={this.state.phone}
                          onBlur={() => this.state.phone}
                          className="form-control"
                          ref="phone"
                        />

                        <span>
                          {this.state.phone_error === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["phone"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                    <span className="code-validation" id="mbl-validation">
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode["code"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <div class="block col-5">
                      <FormGroup>
                        <Label for="Email">
                          Email
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="Email"
                          value={this.state.email}
                          onBlur={() => this.state.email}
                          onChange={this.handleChange}
                          ref="email"
                          autoComplete="off"
                          className="w-100"
                        />
                        <span>
                          {this.state.emailError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["email"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                  </Row>
                  <span className="code-validation" id="desk-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  <Row>
                    <Col xs="12" className="block">
                      <FormGroup>
                        <Label for="Question">
                          Question
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="textarea"
                          name="question"
                          value={this.state.Question}
                          placeholder="Question"
                          id="Question"
                          onChange={this.handleChange}
                          ref="question"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.questionError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["question"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="btn-block">
                    <Button
                      value="Submit"
                      className="btn-reg"
                      disabled={this.state.button_disable}
                      style={{ backgroundColor: "#334ca4" }}
                    >
                      Submit
                    </Button>
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.errMsg}
                </Form>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default AskQuestion;
