import React from "react";
import { Container, Row, Col } from "reactstrap";

import axios from "axios";
import AppConstants from "../AppConstants";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { FaClone } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LazyLoad from "react-lazyload";
 
class Latestjobs extends React.Component {
  constructor() {
    super();
    this.state = {
      jobListdata: false,
      jobList: [],
      currentPage: 1,
      selectedPageSize: 5,
      selectedOrderOption: "",
      searchText: "",
    };
  }
  componentDidMount() {
    let tablefilterdata = {
      page: this.state.currentPage,
      sizeperpage: this.state.selectedPageSize,
      sortname: this.state.selectedOrderOption,
      serchtext: this.state.searchText,
      defaultSearchText: this.state.searchText,
    };
    axios
      .post(AppConstants.Jobs_API + "/jobs/getJobs", tablefilterdata)
      // .post(AppConstants.Jobs_API + "/ctms/getFilteredCtmsJobs", tablefilterdata)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        // console.log(data)
        if (data.Status === "Success") {
          this.setState({
            jobListdata: true,
            jobList: data.data,
            pointerSm: require("../images/pointer.png"),
          });
        }
      });
  }
  copyCodeToClipboard(id, e) {
    NotificationManager.info("URL Copied", "", 3000);
    e.preventDefault();
    var cpLink = AppConstants.Base_Url + "/job/" + id;
    navigator.clipboard.writeText(encodeURI(cpLink));
  }

  replaceURL(url) {
    if (!url) {
      url =
        "https://travelnurses.s3.us-west-2.amazonaws.com/default-job-image.png";
    }
    const replaced = url.replace(
      "https://travelnurses.s3.us-west-2.amazonaws.com",
      AppConstants.Bucket_URL
    );
    return replaced;
  }

  render() {
    return (
      <div className="latestjobs pad-tb text-center">
        <Container>
          <div className="title">{AppConstants.Jobs}</div>
          <p>{AppConstants.Sub_Job_Title}</p>
          {this.state.jobListdata === true ? (
            <div className="inner-content justify-content-center">
              {this.state.jobList.map((item) => (
                <div className="job-info block-box position-relative">
                  <div
                    className="share-btn position-absolute bg-white share-rotate111"
                    style={{
                      right: "10px",
                      top: "8px",
                    }}
                  >
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Slide to left"
                      href="#"
                      class="button-share pr-0 d-flex"
                    >
                      <span class="icon-share">
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                      </span>
                      <span class="text">
                        <Row className="pr-2 mr-0">
                          <Col
                            className="rounded-left text-white text-center px-1 pt-1 "
                            style={{ background: "#25408f" }}
                          >
                            <NotificationContainer />
                            <FaClone
                              className=""
                              onClick={this.copyCodeToClipboard.bind(
                                this,
                                item._id
                              )}
                            />
                          </Col>
                          <Col
                            className="pl-0 pr-2"
                            style={{ background: "#00aced" }}
                          >
                            <span className="twitter">
                              <TwitterShareButton
                                url={AppConstants.Base_Url + `/job/${item._id}`}
                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                className="share"
                                title={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                              >
                                <TwitterIcon size={32} round={true} />
                              </TwitterShareButton>
                            </span>
                          </Col>
                          <Col
                            className="pl-0 pr-2 "
                            style={{ background: "#405992" }}
                          >
                            <span className="facebook">
                              <FacebookShareButton
                                url={AppConstants.Base_Url + `/job/${item._id}`}
                                imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                className="share w-100"
                                quote={`${item.title} Job in ${item.city}, ${item.state}\n Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                              >
                                <FacebookIcon size={32} round={true} />
                              </FacebookShareButton>
                            </span>
                          </Col>
                          <Col
                            className="pl-0 pr-2 rounded-right"
                            style={{ background: "#007fb1" }}
                          >
                            <LinkedinShareButton
                              url={AppConstants.Base_Url + `/job/${item._id}`}
                              className="share"
                              title={`${item.title} Job in ${item.city}, ${item.state} `}
                              summary={`Travel Nurses, Inc. has an opportunity open for a ${item.title} to work ${item.duration} weeks in ${item.city}, ${item.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                              media="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                            >
                              <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                          </Col>
                          {/* <Col
                            className="text-white text-center pt-1 px-1 "
                            style={{ background: "#8651f7" }}
                          >
                            <FaCode />
                          </Col> */}
                        </Row>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <Link to={`/job/${item._id}`}>
                      <li className="job-location">

                        <img
                          src={this.replaceURL(
                            item.stateImages[
                            Math.floor(
                              Math.random() *
                              item.stateImages.length
                            )
                            ]
                          )}
                          alt={
                            item.title +
                            " - " +
                            item.city +
                            " - " +
                            item.state +
                            " - Travel Nurses Inc"
                          }
                        />
                        {/* {stateImages.map((item1, index) => {
                          // if (item.stateName === item1.name) {
                          if (item.stateName === item1.name && item1.images) {
                            return (
                              <LazyLoad>
                                <img
                                  className="img-fluid"
                                  // src={item1.image}
                                  src={
                                    item1.images[
                                      Math.floor(
                                        Math.random() * item1.images.length
                                      )
                                    ]
                                  }
                                  alt={
                                    item.title +
                                    " - " +
                                    item.city +
                                    " - " +
                                    item.state +
                                    " - Travel Nurses Inc"
                                  }
                                />
                              </LazyLoad>
                            );
                          }
                        })} */}
                      </li>
                    </Link>
                    <li className="job-position pt-4">
                      {/* <h4>{item.title}</h4> */}
                      <h4 style={{ fontSize: "25px" }}>{item.degree}</h4>
                      {item.jobSubSpeciality ? (
                        <p
                          style={{
                            fontSize: "23px",
                            color: "rgb(112, 112, 112)",
                          }}
                        >
                          {item.jobSubSpeciality}
                        </p>
                      ) : (
                        ""
                      )}
                      <span className="city-name">
                        {item.city + ", " + item.state}
                      </span>
                    </li>
                    <li className="job-time">
                      <p className="date">
                        Start Date: <em>{item.start_date}</em>
                      </p>
                      <p className="duration">
                        Duration:{" "}
                        <em>
                          {item.duration
                            ? item.duration == 1
                              ? item.duration + " Week"
                              : item.duration + " Weeks"
                            : "N/A"}
                        </em>
                      </p>
                      <p className="time">{item.shift}</p>
                    </li>
                    {/* <li className="btn-section pt-0 pt-lg-4 pt-md-4 position-relative"> */}
                    <li className="btn-section pt-0 pt-lg-4 pt-md-4 ">
                      {item.grossPay1 === 0 ? (
                        ""
                      ) : (
                        <p className="est-pay-res pb-0 text-right mr-3">
                          <div style={{ fontSize: "20px" }}>
                            Est.pay
                            <span className="dollar-sign mx-1">&#36;</span>
                            {/* <span className="currency d-black w-100"></span> */}
                          </div>

                          <h4
                            className="pb-0"
                            style={{ color: "rgb(51, 51, 51)" }}
                          >
                            <span
                              style={{ fontSize: "27px", fontWeight: "bold" }}
                            >
                              {"$" + item.grossPay1}
                            </span>{" "}
                            / week
                          </h4>
                        </p>
                      )}
                      {console.log("line 252", item)}
                      {/* {item.grossPay1 === 0 ? "" :  <p className="duration">
                        Est.pay:<em>{"$"+item.grossPay1}</em><br/>
                      </p>} */}

                      {/* j */}
                      {/* {item.pinned ?  <figure className="position-absolute rounded-circle bg-white" style={{top:'32%', border:'1px solid #ccc', right:'-57px' }}>
                        <img src={`${AppConstants.Bucket_URL}/bannerImages/tn-flame.gif`} style={{width:"40px",height:"33px", marginBottom:'10px'}} />
                      </figure> : ""} */}

                      <Link
                        to={{
                          pathname: `/job/${item._id}`,
                          state: {
                            prevPath: this.props.location.pathname,
                            speciality: [],
                            city: [],
                            state: [],
                            searchRadius: "",
                          },
                        }}
                        className="button mt-0"
                      >
                        View Details
                      </Link>
                      {/* <a href="#" className="forward-link">
                       Send to a friend
                    </a> */}
                      <div className="clear"></div>
                    </li>
                  </ul>
                  {item.pinned ? (
                    <figure
                      className="position-absolute rounded-circle bg-white"
                      style={{
                        top: "43%",
                        border: "1px solid #ccc",
                        right: "-23px",
                      }}
                    >
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/tn-flame.gif`}
                        style={{
                          width: "43px",
                          padding: "0px 6px 6px 6px",
                        }}
                      />
                    </figure>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="inner-content justify-content-center">
              <LazyLoad>
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image"
                  className="loader-center"
                  style={{ width: 80, height: 80 }}
                />
              </LazyLoad>
            </div>
          )}
          {/* <Link to="/jobs" className="button"> */}
          <Link to="/" className="button">
            View More
          </Link>
        </Container>
      </div>
    );
  }
}
// export default Latestjobs;
Latestjobs.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(withRouter(Latestjobs));
