import { useEffect } from "react";

import AppConstants from "../AppConstants";

const ReferFriend = (props) => {


  useEffect(() => {
    window.location.href = `${AppConstants.Word_Press_API}/referral-bonus`;
  }, []);

  return null; // The component doesn't render anything because of the redirect

};

export default ReferFriend;
