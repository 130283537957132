import React, { Component } from "react";
import Slider from "../Components/slider";
import Jobdemandblock from "../Components/job-demand-block";
import Latestjobsblock from "../Components/latest-jobs-block";
import Infoblock from "../Components/info-block";
import Testimonialblock from "../Components/testimonial-block";
import Teamblock from "../Components/team-block";
import Resourceblock from "../Components/resources-block";
import Blogblock from "../Components/blog-block";
import Subscribeblock from "../Components/subscribe-block";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import axios from "axios";
import AppConstants from "../AppConstants";
import SearchPage from "../Components/searchpage";
import { Helmet } from "react-helmet";
import { JSONLD, Generic } from "react-structured-data";
import { Link } from "react-router-dom";
import AbImg from "../images/img_3102.jpg";
import LpnImage from "../images/lpn-demad.png";
class VaccinationPage extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      items: [],
      states: [],
      currentPage: 1,
      selectedPageSize: 5,
      selectedOrderOption: "",
      searchText: "",
      speciality: "",
      location: "",
      address: "",
      phone: "",
      email: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      benefits: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    // axios
    //   .get(AppConstants.Jobs_API + "/specialities/getSpecialitiesWithJobsCount")
    //   // .get(AppConstants.Jobs_API + "/ctms/getSpecialities")
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .then((data) => {
    //     if (data.Status === "Success") {
    //       // console.log(data)
    //       that.setState({ items: data.data });
    //     }
    //   });
    // axios
    //   .get(AppConstants.Jobs_API + "/jobs/getStateAndCities")
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .then((data) => {
    //     if (data.Status === "Success") {
    //       that.setState({ states: data.data });
    //     }
    //   });
    axios
      .get(AppConstants.CMS_API + "/home/allHomeMetaTags")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          if (data.data[0]) {
            that.setState({
              items: data.data,
              metaTitle: data.data[0].metaTitle,
              metaDescription: data.data[0].metaDescription,
              metaTags: data.data[0].metaTags,
              ogTitle: data.data[0].ogTitle,
              ogDescription: data.data[0].ogDescription,
              address: data.data[0].address,
              phone: data.contactInformation[0].phone,
              email: data.contactInformation[0].email,
              facebook: data.contactInformation[0].facebook,
              instagram: data.contactInformation[0].instagram,
              linkedin: data.contactInformation[0].linkedin,
              twitter: data.contactInformation[0].twitter,
            });
          }
        }
      });
    axios
      .get(AppConstants.Jobs_API + "/benefits/jobBenefits")
      // .get(AppConstants.Jobs_API + "/benefits/jobBenefitsFromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data[0]) {
          // console.log(data.data)
          that.setState({
            benefits: data.data[0].benefits,
          });
        } else {
          console.log("invalid data");
        }
      });
  }
  render() {
    const menuStyle = {
      borderRadius: "5px",
      boxShadow: "0px 20px 50px 10px rgba(9, 36, 157, 0.1)",
      background: "#fff",
      padding: "2px 0",
      fontSize: "90%",
      position: "fixed",
      overflow: "auto",
      maxHeight: "300px",
      top: "inherit",
      minWidth: "auto",
      margin: "5px 0 0 10px",
      zindex: "99",

      // TODO: don't cheat, let it flow to the bottom
    };
    return (
      <div className="home">
        {this.props.location.pathname === "/" ? (
          <span>
            <JSONLD>
              <Generic
                type="webpage"
                jsonldtype="Home"
                schema={{
                  name: "Travel Nurses Inc. | Best Travel Nursing Agency",
                  description:
                    "LPNs in Demand - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here.",
                  address: this.state.address,
                  phone: this.state.phone,
                  email: this.state.email,
                  facebook: this.state.facebook,
                  instagram: this.state.instagram,
                  linkedin: this.state.linkedin,
                  twitter: this.state.twitter,
                  contentReferenceTime: new Date(),
                }}
              ></Generic>
            </JSONLD>
            <Helmet>
              <title>
                Best Travel Nursing Company, Nursing Agency | Travel Nurses Inc
              </title>
              <meta name="title" content={this.state.metaTitle} />
              <meta name="description" content={this.state.metaDescription} />

              <meta property="og:type" content="website" />
              <meta property="og:url" content={AppConstants.Base_Url} />
              <meta property="og:title" content={this.state.ogTitle} />
              <meta
                property="og:description"
                content={this.state.ogDescription}
              />
              <meta
                property="og:image"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />

              <meta
                property="twitter:card"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />
              <meta property="twitter:url" content={AppConstants.Base_Url} />
              <meta property="twitter:title" content={this.state.metaTitle} />
              <meta
                property="twitter:description"
                content={this.state.metaDescription}
              />
              <meta
                property="twitter:image"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />
            </Helmet>
          </span>
        ) : (
          <span></span>
        )}
        <Slider />
        <div className="vaccination-page">
          <div className="job-information pad-tb">
            <Container>
              <div className="title">Job Information</div>
              <h3>
                Twenty-seven(27) LPNs needed for Orlando’s COVID Vaccine
                project, these are going to be{" "}
                <Link to="/team">
                  <b>13 week contracts.</b>
                </Link>{" "}
                This will be similar to the Flu Clinics, so you will need to
                know how to give vaccinations. Guaranteed contracts of 36 or 48
                hours.
              </h3>
              <Row>
                <Col xs="6" className="right-block block">
                  <div className="content-box descrop">
                    <ul className="list-arrow">
                      <li>
                        <b>Location:</b> Orlando, FL
                      </li>
                      <li>
                        <b>Specialty Desired:</b> LPN (1 will be a Supervisor, 8
                        will administer vaccines)
                      </li>
                      <li>
                        <b>#Positions Desired:</b> 27
                      </li>
                      <li>
                        <b>
                          Desired Start Date (list ASAP if less than 30 days):
                        </b>{" "}
                        - ASAP
                      </li>
                      <li>
                        <b>Shift Times:</b> -7:00am – 7:00pm, M-F
                      </li>
                      <li>
                        <b>Total # of Hours Per Week:</b> 36 or 48
                      </li>
                      <li>
                        <b># of Weeks Desired:</b> 13 weeks
                      </li>
                      <li>
                        <b>Call Requirement:</b> N/A
                      </li>
                      <li>
                        <b>Weekend Requirement:</b> N/A
                      </li>
                      <li>
                        <b>Float Requirement: </b> Yes, will conduct Covid
                        testing, work in employee clinic, or where needed if not
                        able to give vaccines
                      </li>
                    </ul>
                    <b>
                      <em>
                        NO TRAVELERS! THIS IS LOCAL WORK ONLY. There is no
                        stipend.
                      </em>
                    </b>
                    <br />
                    <br />

                    <div className="btn-sec">
                      <Link
                        to={{
                          pathname: "/apply-job",
                          state: {
                            prevPath: this.props.location.pathname,
                            jobName: "LPN Orlando",
                          },
                        }}
                        className="button"
                      >
                        Apply
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs="6" className="left-block block">
                  <figure className="pic image-fill">
                    <img src={LpnImage} alt="LpnImage - Travel Nurses Inc" />
                  </figure>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="vac-benifits pad-tb">
            <Container>
              <div className="title">Benefits</div>
              <div className="content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.benefits,
                  }}
                />
                <div className="btn-sec"></div>
              </div>
            </Container>
          </div>
          <div className="about-block">
            <Container>
              <div className="title">About Travel Nurses Inc.</div>
              <Row>
                <Col xs="6" className="left-block block">
                  <div className="content-box descrop">
                    <h3>Empowering healthcare professionals to excellence.</h3>
                    <p>
                      <strong>Travel Nurses, Inc.</strong>
                      &nbsp;is a nurse-owned and nurse-operated healthcare
                      staffing business.
                    </p>
                    <p>
                      We began our journey in 1988 with a focus on staffing
                      exclusively OR settings, but soon expanded to&nbsp;
                      <strong>
                        staffing all nurse specialties and allied professionals
                        nationwide
                      </strong>
                      . Since this expansion, we have added two additional
                      offices run with the same mindset: To provide patients
                      with exceptional care by staffing elite healthcare
                      professionals from all specialties in their ideal
                      locations and facilities. With this mentality, in addition
                      to our teams located in three medical meccas–Fort
                      Lauderdale, FL; Dallas, TX; and Memphis, TN–you can count
                      on receiving top quality service at fantastic facilities
                      from the entire Travel Nurses Inc
                      family.
                    </p>
                  </div>
                </Col>
                <Col xs="6" className="right-block block">
                  <figure className="pic image-fill">
                    <img src={AbImg} alt="About - Travel Nurses Inc" />
                  </figure>
                </Col>
              </Row>
            </Container>
          </div>
          <Teamblock />
        </div>
      </div>
    );
  }
}

export default VaccinationPage;
