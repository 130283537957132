import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
class Pagination1 extends React.Component {
  componentDidMount() { }
  onChangePage(e) {
    this.props.onChangePage(e);
  }
  render() {

    console.log('props', this.props);
    const { totalPage = 0, currentPage = 1, numberLimit = 3 } = this.props;

    let startPoint = 1;
    let endPoint = numberLimit;
    if (numberLimit > totalPage) {
      startPoint = 1;
      endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
      startPoint = 1;
      endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
      startPoint = currentPage - parseInt(numberLimit / 2, 10);
      endPoint = currentPage + parseInt(numberLimit / 2, 10);
    } else {
      startPoint = totalPage - (numberLimit - 1);
      endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;
    const points = [];
    for (var i = startPoint; i <= endPoint; i++) {
      points.push(i);
    }

    let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    return (
      <span>
        <Pagination aria-label="Page navigation" className="pagination-blk">
          <PaginationItem>
            <PaginationLink
              className={`prve-next ${firstPageButtonClassName}`}
              onClick={() => this.onChangePage(currentPage - 1)}
              disabled={currentPage <= 1 ? true : false}
            >
              Previous
            </PaginationLink>
          </PaginationItem>
          {points.map((i) => {
            return (
              <PaginationItem key={i}>
                <PaginationLink
                  onClick={() => this.onChangePage(i)}
                  className={`${currentPage === i && "active"}`}
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          {/* {" .... "+totalPage} */}
          <PaginationItem>
            <PaginationLink
              className={`prve-next ${lastPageButtonClassName}`}
              onClick={() => this.onChangePage(currentPage + 1)}
              disabled={currentPage >= totalPage ? true : false}
            >
              Next
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </span>
    );
  }
}

export default Pagination1;
