import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppConstants from "../AppConstants";
import axios from "axios";
// import stateImages from "../Components/stateImages";
class RelatedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rel_jobs: [],
    };
  }
  componentDidMount() {
    axios
      .post(
        AppConstants.Jobs_API +
        "/jobs/getSpecialityBasedJobs/" +
        this.props.speciality, {
        workType: this.props.workType

      }
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({ rel_jobs: data.data });
        }
      });
  }
  viewJob(id, e) {
    this.props.specialityCallback(id);
    this.props.jobHistory.history.push({
      pathname: "/job/" + id,
    });
  }
  replaceURL(url) {
    if (!url) {
      url =
        "https://travelnurses.s3.us-west-2.amazonaws.com/default-job-image.png";
    }
    const replaced = url.replace(
      "https://travelnurses.s3.us-west-2.amazonaws.com",
      AppConstants.Bucket_URL
    );
    return replaced;
  }
  render() {
    const settings = {
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },

        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="related-jobs">
        <Container>
          <div className="title">{AppConstants.R_Jobs}</div>
          <div className="inner-content">
            <Slider {...settings}>
              {this.state.rel_jobs.map((r_job, k) => {
                return (
                  <item>
                    <div className="related-job-grid">
                      <div className="jobs-img-block">

                        <img
                          src={this.replaceURL(
                            r_job.stateImages[
                            Math.floor(
                              Math.random() *
                              r_job.stateImages.length
                            )
                            ]
                          )}
                          alt={
                            r_job.title +
                            " - " +
                            r_job.city +
                            " - " +
                            r_job.state +
                            " - Travel Nurses Inc"
                          }
                        />

                        {/* {stateImages.map((item1, index) => {
                          // console.log(r_job,"state");
                          if (
                            r_job.stateName === item1.name &&
                            item1.images
                          ) {
                            return (
                              <img
                                src={
                                  item1.images[
                                  Math.floor(
                                    Math.random() * item1.images.length
                                  )
                                  ]
                                }
                                alt="tn"
                              />
                            );
                          }
                        })} */}
                        {/* <figure>
                          <img src={jobImg} alt="related-jobs" />
                        </figure> */}
                      </div>
                      <div
                        className="jobs-blk"
                        id={k}
                        onClick={this.viewJob.bind(this, r_job._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <h4>{r_job.title}</h4>
                        <em>{r_job.speciality}</em>
                        <span className="city">
                          {r_job.city},{r_job.state}
                        </span>
                        <span className="job-time">
                          <p className="date">
                            Start Date: <em> {r_job.start_date}</em>{" "}
                          </p>
                          {/* <p className="date">End Date: {r_job.end_date}</p> */}
                          <p className="duration">
                            Duration:{" "}
                            <em>
                              {r_job.duration
                                ? r_job.duration == 1
                                  ? r_job.duration + " Week"
                                  : r_job.duration + " Weeks"
                                : "N/A"}
                            </em>
                          </p>
                          <p className="time">{r_job.shift}</p>
                        </span>
                      </div>
                    </div>
                  </item>
                );
              })}
              {/* <item>
                <div className="jobs-blk">
                  <h4>Travel Respiratory Therapist</h4>
                  <em>Tailored Healthcare Staffing</em>
                  <span className="city">Memphis,TN</span>
                  <p>
                    Tailored Healthcare Staffing is seeking a qualified PICU
                    Travel RN for the following assigment: <a href="#"> More</a>
                  </p>
                </div>
              </item>

              <item>
                <div className="jobs-blk">
                  <h4>Travel Respiratory Therapist</h4>
                  <em>Tailored Healthcare Staffing</em>
                  <span className="city">Memphis,TN</span>
                  <p>
                    Tailored Healthcare Staffing is seeking a qualified PICU
                    Travel RN for the following assigment: <a href="#"> More</a>
                  </p>
                </div>
              </item>

              <item>
                <div className="jobs-blk">
                  <h4>Travel Respiratory Therapist</h4>
                  <em>Tailored Healthcare Staffing</em>
                  <span className="city">Memphis,TN</span>
                  <p>
                    Tailored Healthcare Staffing is seeking a qualified PICU
                    Travel RN for the following assigment: <a href="#"> More</a>
                  </p>
                </div>
              </item>

              <item>
                <div className="jobs-blk">
                  <h4>Travel Respiratory Therapist</h4>
                  <em>Tailored Healthcare Staffing</em>
                  <span className="city">Memphis,TN</span>
                  <p>
                    Tailored Healthcare Staffing is seeking a qualified PICU
                    Travel RN for the following assigment: <a href="#"> More</a>
                  </p>
                </div>
              </item> */}
            </Slider>
          </div>
        </Container>
      </div>
    );
  }
}
export default RelatedJobs;
