import React  from "react";
import {
  Container,
  Row,
  Col,
 
  Form,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import CKEditor from "react-ckeditor-component";
 
class OurStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      image: "",
      video: "",
      nurseId: "",
      upload_videoError: false,
      contenError: false,
      imageError: false,
      videoFile: "",
      story_image: "",
      userId: "",
      isLoading: false,
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.props.history.push("/profile/" + this.props.match.params.slug);
  }
  handleFile(e) {
    if (e.target.files[0]) {
      this.setState({
        [e.target.name]: e.target.files[0],
        story_image: URL.createObjectURL(e.target.files[0]),
        profile_imageError: false,
      });
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      // img.onload = () => {
      //   if (img.width !== img.height ) {
      //     // alert("Wrong size");
      //     this.setState({imageError1:true,errorImage:"Image width and height should be equal"});
      //   } else if (img.width  && img.height < 200) {
      //     // alert("Please upload 100*100 image");
      //     // formIsValid = false;
      //     this.setState({imageError1:true,errorImage:"Image minimum width and height are 200"});
      //   } else if (img.width  && img.height >= 400) {
      //     this.setState({imageError1:true,errorImage:"Image maximum width and height are 400"})
      //   } else {
      //     this.setState({imageError1:false,errorImage:""});
      //   }
      // }
    } else {
      this.setState({ story_image: "", imageError1: false, errorImage: "" });
    }
  }
  handleVideo(e) {
    if (e.target.files[0]) {
      var videoFile = window.URL.createObjectURL(e.target.files[0]);
      this.setState({
        [e.target.name]: e.target.files[0],
        upload_videoError: false,
        videoFile: videoFile,
      });
    }
  }
  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
    if (newContent) {
      this.setState({ contenError: false });
    } else {
      this.setState({ contenError: true });
    }
  }
  handleVallidation() {
    let errors = {};
    let formIsValid = true;
    if (this.state.video && this.state.video.type !== "video/mp4") {
      formIsValid = false;
      errors["video"] = "Please upload Mp4 type file.";
      ReactDOM.findDOMNode(this.refs.video).focus();
    }
    // if (this.state.imageError1 === true) {
    //     formIsValid = false;
    // }
    if (this.state.image && !this.state.image.name.match(/.(jpg|jpeg|png)$/i)) {
      formIsValid = false;
      errors["image"] = "Please upload image in jpg|jpeg|png format.";
      ReactDOM.findDOMNode(this.refs.image).focus();
    }
    if (!this.state.content) {
      formIsValid = false;
      errors["content"] = "Please add story.";
      ReactDOM.findDOMNode(this.refs.content).focus();
    }
    this.setState({
      errors: errors,
      imageError: true,
      upload_videoError: true,
      contenError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    
    if (this.handleVallidation()) {
      console.log("line 123")
      this.setState({ isLoading: true });
      let formData = new FormData();
      formData.append("userId", this.state.userId);
      formData.append("nurseId", this.state.nurseId);
      // formData.append('description', this.state.content);
      // formData.append('image', this.state.image);
      // formData.append('video', this.state.video);
      var ourStory = {};
      // ourStory["userId"] = this.state.userId;
      ourStory["description"] = this.state.content;
      ourStory["approval"] = false;
      ourStory["image"] = "";
      ourStory["video"] = "";
      formData.append("imageFile", this.state.image);
      formData.append("videoFile", this.state.video);
      formData.append("nurseStory", JSON.stringify(ourStory));

      axios
        .post(AppConstants.API + "/nurseStory/addNurseStory", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            setTimeout((e) => {
              this.props.history.push(
                "/profile/" + this.props.match.params.slug
              );
            }, 2000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
          }
        });
    }
  }
  componentDidMount() {
    const that = this;
    axios
      .get(
        AppConstants.API +
          "/nurse/getSlugBasedNurse/" +
          this.props.match.params.slug
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        console.log(data.data.recruiter, "data.data.recruiter");
        if (data.Status === "Success") {
          that.setState({
            nurseId: data.data._id,
            userId: data.data.recruiter,
          });
        }
      });
  }
  render() {
    return (
      <div className="profile-view main-page p-tb-50 bg-grey">
        <Container>
          <div className="registration-form">
            <div className="title">My Story</div>
            <div className="w-100 d-block text-right mb-2">
              <button
                class="button back-btn float-none"
                onClick={() => this.props.history.go(-1)}
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Back
              </button>
            </div>
            {/* <div className="edit-profile"> */}
            <Form
              name=""
              className="view-info profile-info border-pad"
              onSubmit={this.handleLicenseSubmit}
            >
              <Row>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label for="description">
                      Description<span style={{ color: "red" }}>*</span>
                    </Label>
                    <CKEditor
                      activeClass="editor12"
                      content={this.state.content}
                      config={{ allowedContent: true }}
                      events={{
                        // "blur": this.onBlur,
                        // "afterPaste": this.afterPaste,
                        change: this.onChange,
                      }}
                      ref="content"
                    />
                    <span>
                      {this.state.contenError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["content"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label>Image</Label>
                    {this.state.story_image ? (
                      <img
                        src={this.state.story_image}
                        style={{ width: 100, height: 100 }}
                        alt={"Nurse Story - Travel Nurses Inc"}
                      />
                    ) : (
                      ""
                    )}
                    <Input
                      type="file"
                      name="image"
                      // value={this.state.image}
                      ref="image"
                      onChange={this.handleFile}
                      accept="image/png, image/jpeg"
                    />
                    <span>
                      {this.state.imageError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["image"]}
                          {/* {this.state.imageError1 ? this.state.errorImage : ""} */}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label>Video</Label>
                    {this.state.video ? (
                      // <source src="./Videos/video1.mp4" type="video/mp4"/>
                      <iframe
                        src={this.state.videoFile}
                        frameborder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      ""
                    )}
                    <Input
                      type="file"
                      name="video"
                      // value={this.state.video}
                      ref="video"
                      onChange={this.handleVideo}
                      accept="video/mp4"
                    />
                    <span style={{ color: "green" }}>Upload Mp4 type file</span>
                    <span>
                      {this.state.upload_videoError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["video"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <div className="btn-block">
                  <span
                    href="javascript:void(0)"
                    className="btn btn-prev button"
                    title="Cancel"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </span>
                  <span
                    class="btn btn-next button"
                    title="Update"
                    onClick={this.handleSubmit}
                  >
                    Update
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default OurStory;
