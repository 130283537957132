import React, { Component } from "react";
import images from "../Components/images";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { NotificationManager } from "react-notifications";

import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
 
class ChangePassword extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentPassword: "",
      currentPasswordError: false,
      confirmPassword: "",
      confirmPasswordError: false,
      newPassword: "",
      newPasswordError: false,
      errors: [],
      isLoading: false,
      errMsg: "",
      button_disable: false,
      token_val: 0,
      resume: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResumeChange = this.handleResumeChange.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  handleResumeChange(e) {
    this.setState({
      resume: e.target.files[0],
    });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "currentPassword") {
      if (e.target.value === "") {
        this.setState({ currentPasswordError: true });
      } else {
        this.setState({ currentPasswordError: false });
      }
    }
    if (e.target.name === "newPassword") {
      if (e.target.value === "") {
        this.setState({ newPasswordError: true });
      } else {
        this.setState({ newPasswordError: false });
      }
    }
    if (e.target.name === "confirmPassword") {
      if (e.target.value === "") {
        this.setState({ confirmPasswordError: true });
      } else {
        this.setState({ confirmPasswordError: false });
      }
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.currentPassword) {
      formIsValid = false;
      errors["currentPassword"] = "Please enter your current password.";
      ReactDOM.findDOMNode(this.refs.currentPassword).focus();
    }
    if (!this.state.newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Please enter new your password.";
      ReactDOM.findDOMNode(this.refs.newPassword).focus();
    }
    if (this.state.newPassword !== "") {
      if (this.state.newPassword.length <= 6) {
        formIsValid = false;
        errors["newPassword"] = "Password must be longer than 6 characters";
        ReactDOM.findDOMNode(this.refs.newPassword).focus();
      }
    }
    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please enter your confirm password.";
      ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
    }

    if (this.state.confirmPassword) {
      if (this.state.newPassword !== this.state.confirmPassword) {
        formIsValid = false;
        errors["confirmPassword"] =
          "Your new password and confirm password should be same.";
        ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
      }
    }
    if (this.state.currentPassword) {
      if (this.state.currentPassword == this.state.newPassword) {
        formIsValid = false;
        errors["currentPassword"] =
          "Your current password and new password should not be same.";
        ReactDOM.findDOMNode(this.refs.currentPassword).focus();
      }
    }

    this.setState({
      errors: errors,
      currentPasswordError: true,
      newPasswordError: true,
      confirmPasswordError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    const userID = localStorage.getItem("id");

    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      const formData = {
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        id: userID,
        confirmPassword: this.state.confirmPassword,
      };
      let formData2 = new FormData();
      formData2.append("resume", this.state.resume);
      axios
        .post(
          AppConstants.API + "/nurse/uploadFile/" + userID,
          formData2,
          axiosConfig
        )
        .then((data) => {
          console.log(data, "data");
        });

      axios
        .post(AppConstants.API + "/nurse/changePassword", formData, axiosConfig)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true, isLoading: false });
            NotificationManager.success(res.data.msg, "", 3000);
            this.props.history.push("/profile/" + userID);
          } else if (res.data.Status === "Failed") {
            this.setState({
              isLoading: false,
              button_disable: false,
            });
            NotificationManager.error(res.data.msg, "", 3000);
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            button_disable: false,
          });
          NotificationManager.error(error.response.data.msg, "", 3000);
        });
    }
  }
  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <Container>
          {this.state.token_val === 0 ? (
            <div className="login-grid">
              <div className="inner-block">
                <Row>
                  <Col xs="6" className="block left-block">
                    <figure className="pic image-fill">
                      {images.map((e, idx) => (
                        <img
                          // src={e.LoginPic}
                          src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                          alt={"Set Password - Travel Nurses Inc" + idx}
                        />
                      ))}
                    </figure>
                  </Col>
                  <Col xs="6" className="block right-block">
                    <div className="form-sec text-center">
                      <div className="title">Change Password</div>
                      {/* <p>Enter your details below to continue</p> */}
                      <Form className="form-block" onSubmit={this.handleSubmit}>
                        <FormGroup>
                          <Label for="currentPassword">Current Password</Label>
                          <Input
                            type="password"
                            name="currentPassword"
                            id="currentPassword"
                            placeholder="Current Password"
                            onChange={this.handleInputChange}
                            ref="currentPassword"
                          />
                          <div>
                            {this.state.currentPasswordError ? (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                {this.state.errors["currentPassword"]}
                              </span>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label for="newPassword">New Password</Label>
                          <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            placeholder="New password"
                            ref="newPassword"
                            onChange={this.handleInputChange}
                          />
                          <div>
                            {this.state.newPasswordError ? (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                {this.state.errors["newPassword"]}
                              </span>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label for="confirmPassword">Confirm Password</Label>
                          <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm password"
                            ref="confirmPassword"
                            onChange={this.handleInputChange}
                          />
                          <div>
                            {this.state.confirmPasswordError ? (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                {this.state.errors["confirmPassword"]}
                              </span>
                            ) : null}
                          </div>
                        </FormGroup>

                        <Button
                          className="button"
                          onClick={this.handleSubmit}
                          disabled={this.state.button_disable}
                        >
                          Submit
                          {this.state.isLoading ? (
                            <img
                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                              alt="No Image - Set Password - Travel Nurses Inc"
                              style={{ width: 40, height: 40 }}
                            />
                          ) : (
                            ""
                          )}
                        </Button>

                        {this.state.errMsg}
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <Col xs="6">
              This set password link has expired. Please contact admin.
            </Col>
          )}
        </Container>
      </div>
    );
  }
}

export default ChangePassword;
