/* eslint-disable */
// import React from "react";
import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_USER_INFO,
  REGISTER_USER,
  REGISTER_ERROR,
  LOGOUT_USER,
  LOGIN_USER,
  GET_SUCCESS,
} from "./types";
import jwt_decode from "jwt-decode";
import AppConstants from "../AppConstants";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";

//Nurse Registration
export const registerUser = (user, history) => (dispatch) => {
  try {
    axios
      .post(AppConstants.API + "/nurse/nurseRegistration", user)
      .then((res) => {
        if (res.data.Status === "Failed") {
          dispatch({
            type: REGISTER_ERROR,
            payload: res.data,
          });
          history.push("/");
        } else {
          dispatch({
            type: REGISTER_USER,
            payload: res.data,
          });
          localStorage.setItem("userID", res.data.id);
          NotificationManager.success(res.data.msg, "", 5000);
          history.push("/otp");
        }
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(
            err && err.response && err.response.data && err.response.data.msg,
            "",
            5000
          );
        }
      });
  } catch (error) {
    console.log(error, "error");
  }
};

//Nurse login
export const loginUser = (user, history) => (dispatch) => {
  axios.post(AppConstants.API + "/nurse/nurseLogin", user).then((res) => {
    if (res.data.Status === "Failed") {
      dispatch({
        type: GET_ERRORS,
        payload: res.data,
      });
      NotificationManager.error(res.data.msg, "", 5000);
    } else {
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      });
      const { token, tempId, email } = res.data;
      console.log("res.data", res.data);
      localStorage.setItem("tempId", JSON.stringify(tempId));
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("primaryEmail", JSON.stringify(email));
      localStorage.setItem("token", token);
      localStorage.setItem("status", "0")
      const decoded = jwt_decode(token);
      localStorage.setItem("id", decoded._id);
      dispatch(setCurrentUser(decoded));
      dispatch(getUserInfo(decoded._id, history));
      history.push("/profile/" + decoded._id);
    }
  });
};

//Set session current user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//Nurse logout
export const logoutUser = (history) => (dispatch) => {
  console.log("caling here", history);
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("id");
  localStorage.removeItem("userID");
  localStorage.removeItem("tempId");
  localStorage.removeItem("user");
  localStorage.removeItem("primaryEmail");
  Cookies.remove("user", {
    domain: ".travelnursesinc.com",
    path: "/",
  });
  dispatch(setCurrentUser({}));

  history.push("/");
};

//Nurse details
export const getUserInfo = (id) => (dispatch) => {
  if (id) {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(AppConstants.API + "/nurse/getNurse/" + id, axiosConfig)
      .then((res) => {
        if (res.data.Status === "Failed") {
          dispatch({
            type: GET_ERRORS,
            payload: res.data,
          });
        } else {
          let user = {
            primaryEmail: res.data.data.contactInformation.primaryEmail,
            primaryPhoneNumber:
              res.data.data.contactInformation.primaryPhoneNumber,
            firstName: res.data.data.firstName,
            lastName: res.data.data.lastName,
            certifications:
              res.data.data.proffesionalInformation.certifications,
            specialities: res.data.data.proffesionalInformation.specialities,
            tempId: res.data.data.tempId,
          };
          const cookieData = {
            firstName: res.data.data.firstName,
            lastName: res.data.data.lastName,
            profileImage:
              res.data.data && res.data.data.profileImage
                ? res.data.data.profileImage
                : "https://travelnurses.s3.us-west-2.amazonaws.com/static/profile-thumb.jpg",
            token: localStorage.getItem("token"),
            id:res.data.data._id
          };
          Cookies.set('user', JSON.stringify(cookieData),{ domain:'.travelnursesinc.com', path: '/',expires: 7 });

          localStorage.setItem("userInfo", JSON.stringify(user));

          dispatch({
            type: GET_USER_INFO,
            payload: res.data,
          });
        }
      });
  }
};
