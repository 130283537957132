import React from 'react';
import BlogImg from '../images/blog-img.png';
import { FaAngleDoubleLeft } from 'react-icons/fa';

function ArticleDetailPage(props) {
  let id = localStorage.getItem('id');
  return (
    <section className="stories-inner-sec">
      <div className="container">
        <div className="stories-inner-sec-in">
          <figure>
            <img
              src={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.yoast_head_json &&
                props.location.state.yoast_head_json.og_image &&
                props.location.state.yoast_head_json.og_image[0] &&
                props.location.state.yoast_head_json.og_image[0].url
                  ? props.location.state.yoast_head_json.og_image[0].url
                  : BlogImg
              }
              alt={''}
            />
          </figure>
          <h2>
            {props &&
            props.location &&
            props.location.state &&
            props.location.state.title &&
            props.location.state.title.rendered
              ? props.location.state.title.rendered.replace('&#8217;', "'")
              : ''}
          </h2>

          <p
            class="mb-0"
            dangerouslySetInnerHTML={{
              __html:
                props &&
                props.location &&
                props.location.state &&
                props.location.state.content &&
                props.location.state.content.rendered,
            }}
          />
        </div>
        <div className="preview-bttn">
          <button class="button back-btn float-none" onClick={() => props.history.push('/profile/' + id)}>
            <FaAngleDoubleLeft />
            <span>Back To Dashboard</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default ArticleDetailPage;
