import React  from "react";
import images from "../Components/images";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
 
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
 
import { loginUser, getUserInfo } from "../actions/authentication";
 
class SetPassword extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      confirmPassword: "",
      password: "",
      passwordError: false,
      confirmPasswordError: false,
      errors: [],
      isLoading: false,
      errMsg: "",
      button_disable: false,
      thanksPage: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "confirmPassword") {
      if (e.target.value === "") {
        this.setState({ confirmPasswordError: true });
      } else {
        this.setState({ confirmPasswordError: false });
      }
    }
    if (e.target.name === "password") {
      if (e.target.value === "") {
        this.setState({ passwordError: true });
      } else {
        this.setState({ passwordError: false });
      }
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter your password.";
      ReactDOM.findDOMNode(this.refs.password).focus();
    }
    if (this.state.password !== "") {
      if (this.state.password.length <= 6) {
        formIsValid = false;
        errors["password"] =
          "Entered password length should be at least 7 characters.";
        ReactDOM.findDOMNode(this.refs.password).focus();
      }
    }
    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please enter your password confirmation.";
      ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
    }
    if (this.state.confirmPassword) {
      if (this.state.password != this.state.confirmPassword) {
        formIsValid = false;
        errors["confirmPassword"] =
          "password and password confirmation don't match";
        ReactDOM.findDOMNode(this.refs.confirmPassword).focus();
      }
    }

    this.setState({
      errors: errors,
      confirmPasswordError: true,
      passwordError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      const formData = {
        password: this.state.password,
        id: localStorage.getItem("userID"),
      };
      axios
        .post(AppConstants.API + "/nurse/setNursePassword", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            // this.props.history.push("/registration-thank-you");
            console.log("res.data", res.data)
            this.setState({
              isLoading: false,
            });
            let formData = {
              phoneNumber: res.data.data.contactInformation.primaryPhoneNumber,
              password: this.state.password,
            };
            this.props.loginUser(formData, this.props.history);
            // NotificationManager.success(res.data.msg, "", 5000);
          }
        });
    }
  }
  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <Container>
          <div className="login-grid">
            <div className="inner-block">
              <Row>
                {!this.state.thanksPage && (
                  <Col xs="6" className="block left-block">
                    <figure className="pic image-fill">
                      {images.map((e, idx) => (
                        <img
                          // src={e.LoginPic}
                          src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                          alt={"Set Password - Travel Nurses Inc" + idx}
                        />
                      ))}
                    </figure>
                  </Col>
                )}
                <Col xs="6" className="block right-block">
                  <div className="form-sec text-center">
                    <div className="title">Create Password</div>

                    <Form className="form-block" onSubmit={this.handleSubmit}>
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          type="password"
                          name="password"
                          id="Password"
                          placeholder="Password"
                          onChange={this.handleInputChange}
                          ref="password"
                        />
                        <div>
                          {this.state.passwordError ? (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              {this.state.errors["password"]}
                            </span>
                          ) : null}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label>Confirm Password</Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          ref="confirmPassword"
                          onChange={this.handleInputChange}
                        />
                        <div>
                          {this.state.confirmPasswordError ? (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              {this.state.errors["confirmPassword"]}
                            </span>
                          ) : null}
                        </div>
                      </FormGroup>
                      <Button
                        className="button"
                        onClick={this.handleSubmit}
                        disabled={this.state.button_disable}
                      >
                        Submit
                        {this.state.isLoading ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image - Set Password - Travel Nurses Inc"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                      {this.state.errMsg}
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { loading, error, user } = auth;
  return { loading, error, user };
};
export default connect(mapStateToProps, { loginUser, getUserInfo })(SetPassword);
