import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import AppConstants from "../AppConstants";
import { FaFilePdf } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";

import axios from "axios";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocImg from "../images/document-img.png";
import NoDocImg from "../images/no-doc-img.png";
import JPEGImg from "../images/jpeg-icon.png";
import JPGImg from "../images/jpg-icon.png";
import PNGImg from "../images/png-icon.png";
import UploadedIcon from "../images/updated-date-icon.png";
import ExpiresIcon from "../images/expires-icon.png";
import MapIcon from "../images/location-icon.png";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { NotificationManager } from "react-notifications";
import PDFViewer from "mgr-pdf-viewer-react";
import customPDF from "../Components/customPDF";

function Documents(props) {
  const [show, setShow] = useState(false);
  const [priviewShow, setPreviewShow] = useState(false);
  const [priviewFile, setPreviewFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [imports, setImports] = useState([]);
  const [certification, setCertification] = useState([]);
  const [cprLevel, setCPRLevel] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [states, setStates] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [certificationType, setCertificationType] = useState("");
  const [subCat, setSubCat] = useState([]);
  const [subCatType, setSubCatType] = useState("");
  const [subMedicalCat, setSubMedicalCat] = useState([]);
  // const [subMedicalCatType, setSubMedicalCatType] = useState("");
  const [selectedState, setSelectedState] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [licenceNo, setLicenceNo] = useState("");
  const [documentName, setDocName] = useState("");
  const [image, setImage] = useState("");

  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");
  const [error7, setError7] = useState("");
  const [error8, setError8] = useState("");
  const [error9, setError9] = useState("");
  const [e1, setE1] = useState(false);
  const [e2, setE2] = useState(false);
  const [e3, setE3] = useState(false);
  const [e4, setE4] = useState(false);
  const [e5, setE5] = useState(false);
  const [e6, setE6] = useState(false);
  const [e7, setE7] = useState(false);
  const [e8, setE8] = useState(false);
  const [e9, setE9] = useState(false);

  const [loader, setLoader] = useState(false);
  const [hrDocsURL, setHrDocsURL] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [button, setButton] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  useEffect(() => {
    let id = localStorage.getItem("id");
    let user = JSON.parse(localStorage.getItem("userInfo"));
    setFirstName(user ? user.firstName : "");
    getDocumentsList();
    getLicenseList();
    getStatesListAPI();
    getDocumentsListAPI();
  }, []);

  async function getLicenseList() {
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url:
        AppConstants.Jobs_API + "/certification/getCtmsMatchedCertifications",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          console.log(response, "response ------");
          const user = JSON.parse(localStorage.getItem("user"));

          var licencesList = [];
          let cr = [];
          user &&
            user.proffesionalInformation &&
            user.proffesionalInformation.certifications.map((item) => {
              cr.push(item.ctmsCertName);
            });
          if (response.data && response.data.data && response.data.data) {
            licencesList =
              response.data &&
              response.data.data &&
              response.data.data.filter((item) => {
                return cr.includes(item.ctmsCertName);
              });
            setLicenses(licencesList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // above
  async function getDocumentsList() {
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getDocumentsDropdownList",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setImports(
            response.data && response.data.data && response.data.data[0].Import
          );
          setCertification(
            response.data &&
              response.data.data &&
              response.data.data[1].Certification
          );
          setCPRLevel(
            response.data &&
              response.data.data &&
              response.data.data[2].CPRLevel
          );
          setSubMedicalCat(
            response.data &&
              response.data.data &&
              response.data.data[4]["Medical Documents"]
          );
          setSubCat(
            response.data &&
              response.data.data &&
              response.data.data[5]["Misc./Others"]
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getDocumentsListAPI() {
    setLoader(true);
    const userId = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    var config = {
      method: "POST",
      url: AppConstants.API + "/document/getNurseDocuments/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        // console.log(response);
        setDocumentsList(
          response.data && response.data.data
            ? response.data.data.documents
            : []
        );
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getStatesListAPI() {
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllWorkLocationStates",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setStates(response.data && response.data.data && response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handletoggle = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setDocumentType();
    setCertificationType();
    setSelectedState();
    setSelectedDate();
    setLicenceNo();
    setImage();
    setDocName();
    setSubCatType();
  };

  useEffect(() => {
    setCertificationType();
    setSelectedState();
    setSelectedDate();
    setLicenceNo();
    // setImage();
    setDocName();
    setSubCatType();
    setLicenceNo();
  }, [documentType]);

  const handleSubmit = (e) => {
    const re = /^[a-zA-Z0-9/]+$/;
    const userId = localStorage.getItem("id");
    let user = JSON.parse(localStorage.getItem("userInfo"));
    const token = localStorage.getItem("token");
    let tempId = user ? user.tempId : "";
    e.preventDefault();
    if (!!documentType == false) {
      setE1(true);
      setError1("Please select document type.");
    } else {
      if (documentType == "License") {
        let state = selectedState && selectedState.split(",");
        if (!!certificationType == false) {
          setE2(true);
          setError2(" Please select certificate.");
        } else if (!selectedDate) {
          setE8(true);
          setError8("Please select date.");
        } else if (!!licenceNo == false) {
          setE4(true);
          setError4("Please enter license number.");
        } else if (!re.test(licenceNo)) {
          setE4(true);
          setError4("Special characters are not allowed.");
        } else if (!selectedState) {
          setE5(true);
          setError5("Please select state.");
        } else if (!image) {
          setE7(true);
          setError7("Please select file.");
        } else {
          setisLoading(true);
          setButton(true);
          let license = certificationType.split(",");
          let data = new FormData();
          data.append("categoryName", documentType);
          data.append("licenseId", license[0]);
          data.append("licenseName", license[1]);
          data.append("state", state[1]);
          data.append("stateCode", state[0]);
          data.append("licenseExpiration", Moment(selectedDate, "MM-DD-YYYY"));
          data.append("licenseNumber", licenceNo);
          data.append("image", image);
          data.append("tempId", tempId);
          var config = {
            method: "post",
            url: AppConstants.API + "/document/insertNurseDocument/" + userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setButton(false);
              if (response.data.Status == "Failed") {
                setisLoading(false);
                NotificationManager.error(response.data.msg, "", 3000);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE2(false);
                setError2();
                setError3(false);
                setError3();
                setE4(false);
                setError4();
                setE5(false);
                setError5();
                setE8(false);
                setError8();
              } else {
                setisLoading(false);
                NotificationManager.success(response.data.msg, "", 3000);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE2(false);
                setError2();
                setError3(false);
                setError3();
                setE4(false);
                setError4();
                setE5(false);
                setError5();
                setE8(false);
                setError8();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else if (documentType == "Misc./Others") {
        var finalValue = subCatType != "Others" ? subCatType : documentName;
        if (!subCatType) {
          setE9(true);
          setError9("Please select Category");
        } else if (!!finalValue == false) {
          setE6(true);
          setError6("Please enter document name.");
        } else if (!image) {
          setE7(true);
          setError7("Please select file.");
        } else {
          setisLoading(true);
          setButton(true);
          let data = new FormData();
          data.append("categoryName", documentType);
          data.append(
            "documentName",
            subCatType != "Others" ? subCatType : documentName
          );
          data.append("image", image);
          var config = {
            method: "post",
            url: AppConstants.API + "/document/insertNurseDocument/" + userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setButton(false);
              if (response.data.Status == "Failed") {
                NotificationManager.error(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE6(false);
                setError6();
                setE7(false);
                setError7();
                setE9(false);
                setError9();
                setSubCatType();
                setDocName();
              } else {
                NotificationManager.success(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setSubCatType();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE6(false);
                setError6();
                setE7(false);
                setError7();
                setE9(false);
                setError9();
                setDocName();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else if (documentType == "Medical Documents") {
        var finalValue = subCatType != "Others" ? subCatType : documentName;
        if (!subCatType) {
          setE9(true);
          setError9("Please select Category");
        } else if (!!finalValue == false) {
          setE6(true);
          setError6("Please enter document name.");
        } else if (!image) {
          setE7(true);
          setError7("Please select file.");
        } else {
          setisLoading(true);
          setButton(true);
          let data = new FormData();
          data.append("categoryName", documentType);
          data.append(
            "documentName",
            subCatType != "Others" ? subCatType : documentName
          );
          data.append("image", image);
          var config = {
            method: "post",
            url: AppConstants.API + "/document/insertNurseDocument/" + userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setButton(false);
              if (response.data.Status == "Failed") {
                NotificationManager.error(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE6(false);
                setError6();
                setE7(false);
                setError7();
                setE9(false);
                setError9();
                setSubCatType();
                setDocName();
              } else {
                NotificationManager.success(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setSubCatType();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE6(false);
                setError6();
                setE7(false);
                setError7();
                setE9(false);
                setError9();
                setDocName();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        // if (!!documentName == false) {
        //   setE6(true);
        //   setError6("Please enter document name.");
        // } else if (!image) {
        //   setE7(true);
        //   setError7("Please select file.");
        // } else {
        //   setisLoading(true);
        //   setButton(true);
        //   let data = new FormData();
        //   data.append("categoryName", documentType);
        //   data.append("documentName", documentName);
        //   data.append("image", image);
        //   var config = {
        //     method: "post",
        //     url: AppConstants.API + "/document/insertNurseDocument/" + userId,
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: token,
        //     },
        //     data: data,
        //   };
        //   axios(config)
        //     .then(function (response) {
        //       setButton(false);
        //       if (response.data.Status == "Failed") {
        //         NotificationManager.error(response.data.msg, "", 3000);
        //         setisLoading(false);
        //         getDocumentsListAPI();
        //         setShow(false);
        //         setDocumentType();
        //         setCertificationType();
        //         setSelectedState();
        //         setSelectedDate();
        //         setLicenceNo();
        //         setImage();
        //         setE6(false);
        //         setError6();
        //         setE7(false);
        //         setError7();
        //         setDocName();
        //       } else {
        //         NotificationManager.success(response.data.msg, "", 3000);
        //         setisLoading(false);
        //         getDocumentsListAPI();
        //         setShow(false);
        //         setDocumentType();
        //         setCertificationType();
        //         setSelectedState();
        //         setSelectedDate();
        //         setLicenceNo();
        //         setImage();
        //         setE6(false);
        //         setError6();
        //         setE7(false);
        //         setError7();
        //         setDocName();

        //       }
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //     });
        // }
      } else {
        if (!!certificationType == false) {
          setE2(true);
          setError2("Please select certificate.");
        } else if (licenceNo && licenceNo.length > 0) {
          if (licenceNo.length > 0 && !re.test(licenceNo)) {
            setE4(true);
            setError4("Special characters are not allowed.");
          } else if (!image) {
            setE7(true);
            setError7("Please select file.");
          } else {
            setButton(true);
            setisLoading(true);
            let license = certificationType.split(",");
            let data = new FormData();
            data.append("categoryName", documentType);
            data.append("licenseId", license[0]);
            data.append("licenseName", license[1]);
            data.append("state", "");
            data.append("stateCode", "");
            data.append(
              "licenseExpiration",
              Moment(selectedDate, "MM-DD-YYYY")
            );
            data.append("licenseNumber", licenceNo);
            data.append("image", image);
            data.append("tempId", tempId);
            var config = {
              method: "post",
              url: AppConstants.API + "/document/insertNurseDocument/" + userId,
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              data: data,
            };
            axios(config)
              .then(function (response) {
                setButton(false);
                if (response.data.Status == "Failed") {
                  NotificationManager.error(response.data.msg, "", 3000);
                  setisLoading(false);
                  getDocumentsListAPI();
                  setShow(false);
                  setDocumentType();
                  setCertificationType();
                  setSelectedState();
                  setSelectedDate();
                  setLicenceNo();
                  setImage();
                  setE2(false);
                  setError2();
                  setE4(false);
                  setError4();
                } else {
                  NotificationManager.success(response.data.msg, "", 3000);
                  setisLoading(false);
                  getDocumentsListAPI();
                  setShow(false);
                  setDocumentType();
                  setCertificationType();
                  setSelectedState();
                  setSelectedDate();
                  setLicenceNo();
                  setImage();
                  setE2(false);
                  setError2();
                  setE4(false);
                  setError4();
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else if (!image) {
          setE7(true);
          setError7("Please select file.");
        } else {
          setButton(true);
          setisLoading(true);
          let license = certificationType.split(",");
          let data = new FormData();
          data.append("categoryName", documentType);
          data.append("licenseId", license[0]);
          data.append("licenseName", license[1]);
          data.append("state", "");
          data.append("stateCode", "");
          data.append("licenseExpiration", Moment(selectedDate, "MM-DD-YYYY"));
          data.append("licenseNumber", licenceNo);
          data.append("image", image);
          data.append("tempId", tempId);
          var config = {
            method: "post",
            url: AppConstants.API + "/document/insertNurseDocument/" + userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              setButton(false);
              if (response.data.Status == "Failed") {
                NotificationManager.error(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType("");
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE2(false);
                setError2();
                setE4(false);
                setError4();
                setE7(false);
                setError7();
              } else {
                NotificationManager.success(response.data.msg, "", 3000);
                setisLoading(false);
                getDocumentsListAPI();
                setShow(false);
                setDocumentType();
                setCertificationType();
                setSelectedState();
                setSelectedDate();
                setLicenceNo();
                setImage();
                setE2(false);
                setError2();
                setE4(false);
                setError4();
                setE7(false);
                setError7();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }
    setTimeout(() => {
      setE1(false);
      setError1();
      setE2(false);
      setError2();
      setE3(false);
      setError3();
      setE4(false);
      setError4();
      setE5(false);
      setError5();
      setE6(false);
      setError6();
      setE7(false);
      setError7();
      setE8(false);
      setError8();
      setE9(false);
      setError9();
    }, 4000);
  };

  const handlesetImage = (e) => {
    const fType = e.target.files[0].name.split(".").pop().toLowerCase();
    if (subCatType == "Badge Photo") {
      if (fType === "jpg" || fType === "jpeg" || fType === "png") {
        setImage(e.target.files[0]);
        setE7(false);
        setError7("");
      } else {
        setE7(true);
        setError7("File not supported.");
      }
    } else if (
      fType === "jpg" ||
      fType === "jpeg" ||
      fType === "png" ||
      fType === "pdf"
    ) {
      setImage(e.target.files[0]);
      setE7(false);
      setError7("");
    } else {
      setE7(true);
      setError7("File not supported.");
    }
  };
  const handlePriviewModel = (e, item) => {
    getFileAPI(item.ctmsLicenseFileId);
  };
  const handleClosePriview = () => {
    setPreviewShow(false);
    setPreviewFile();
  };
  const getFileAPI = (id) => {
    const token = localStorage.getItem("token");
    setisLoading(true);
    setPreviewShow(true);
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .post(
        AppConstants.API + "/document/getNurseDocumentFile/" + id,
        "",
        axiosConfig
      )
      .then((res) => {
        setPreviewFile(res.data && res.data.data && res.data.data.fileContent);
        setFileType(res.data && res.data.data && res.data.data.contentType);
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e, "error");
        NotificationManager.error(e.response.msg, "", 3000);
      });
  };

  const handleLicenceNo = (e) => {
    const re = /^[a-zA-Z0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value))
      setLicenceNo(e.target.value);
  };

  return (
    <section className="document-sec">
      <div className="container">
        <Modal
          isOpen={show}
          toggle={handletoggle}
          className="modal-lg contact-rect recruiter-modal request-job-sec-modal document-popup"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={handleClose}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            >
              <h4>Upload Document-</h4>
            </ModalHeader>
            <ModalBody className="p-0">
              <Form className="inner-block" onSubmit={handleSubmit}>
                <Row>
                  <Col md="6" className="block">
                    <FormGroup>
                      <Label>
                        Document Type
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name="documents"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                        className="form-control custom-dropdown"
                      >
                        <option value={""}>Select Document Type</option>
                        <option value="Certification">Certification</option>
                        <option value="CPRLevel">CPRLevel</option>
                        <option value="License">Licenses</option>
                        <option value="Medical Documents">
                          Medical Documents
                        </option>
                        <option value="Misc./Others">Misc./Others</option>
                      </select>
                    </FormGroup>
                    {e1 ? (
                      <span className="error" style={{ color: "red" }}>
                        {error1}
                      </span>
                    ) : (
                      ""
                    )}
                  </Col>

                  {/* {documentType === "Medical Documents" ? (
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="Document Name">
                          Document Name
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="documentName"
                          placeholder="Document Name"
                          onChange={(e) => setDocName(e.target.value)}
                          value={documentName}
                          autoComplete="off"
                        />
                      </FormGroup>
                      {e6 ? (
                          <span className="error" style={{ color: "red" }}>
                            {error6}
                          </span>
                        ) : (
                          ""
                        )}
                    </Col>
                  ) : */}
                  {documentType === "Misc./Others" ||
                  documentType === "Medical Documents" ? (
                    <>
                      <Col xs="6" className="block">
                        <FormGroup>
                          <Label for="Document Name">
                            Categories
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <select
                            name="state"
                            className="form-control custom-dropdown"
                            value={subCatType}
                            onChange={(e) => setSubCatType(e.target.value)}
                            disabled={documentType ? false : true}
                          >
                            <option value={""}>Select Category</option>
                            {documentType == "Misc./Others"
                              ? subCat.map((item, idx) => (
                                  <option value={item.value} key={idx}>
                                    {item.label}
                                  </option>
                                ))
                              : subMedicalCat.map((item, idx) => (
                                  <option value={item.value} key={idx}>
                                    {item.label}
                                  </option>
                                ))}
                          </select>
                        </FormGroup>
                        {e9 ? (
                          <span className="error" style={{ color: "red" }}>
                            {error9}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      {subCatType == "others" ||
                        (subCatType == "Others" && (
                          <Col xs="6" className="block">
                            <FormGroup>
                              <Label for="Document Name">
                                Document Name
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                type="text"
                                name="documentName"
                                placeholder="Document Name"
                                onChange={(e) => setDocName(e.target.value)}
                                value={documentName}
                                autoComplete="off"
                              />
                            </FormGroup>
                            {e6 ? (
                              <span className="error" style={{ color: "red" }}>
                                {error6}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        ))}
                    </>
                  ) : (
                    <>
                      <Col md="6" className="block">
                        <FormGroup>
                          <Label>
                            Certification
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <select
                            name="state"
                            className="form-control custom-dropdown"
                            value={certificationType}
                            onChange={(e) =>
                              setCertificationType(e.target.value)
                            }
                            disabled={documentType ? false : true}
                          >
                            <option value={""}>Select Certification</option>
                            {documentType == "Import"
                              ? imports.map((item, idx) => (
                                  <option
                                    value={[item.licenseId, item.licenseName]}
                                    key={idx}
                                  >
                                    {item.licenseName}
                                  </option>
                                ))
                              : documentType == "Certification"
                              ? certification.map((item, idx) => (
                                  <option
                                    value={[item.licenseId, item.licenseName]}
                                    key={idx}
                                  >
                                    {item.licenseName}
                                  </option>
                                ))
                              : documentType == "CPRLevel"
                              ? cprLevel.map((item, idx) => (
                                  <option
                                    value={[item.licenseId, item.licenseName]}
                                    key={idx}
                                  >
                                    {item.licenseName}
                                  </option>
                                ))
                              : licenses.map((item, idx) => (
                                  <option
                                    value={[item.certId, item.ctmsCertName]}
                                    key={idx}
                                  >
                                    {item.ctmsCertName}
                                  </option>
                                ))}
                          </select>
                        </FormGroup>
                        {e2 ? (
                          <span className="error" style={{ color: "red" }}>
                            {error2}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs="6" className="block date-picker">
                        <FormGroup>
                          <Label for="Email">
                            Expiration
                            {documentType == "License" ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : null}
                          </Label>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) =>
                              setSelectedDate(date ? date : "")
                            }
                            disabled={documentType ? false : true}
                            placeholderText="MM/DD/YYYY"
                          />
                          {e8 ? (
                            <span className="error" style={{ color: "red" }}>
                              {error8}
                            </span>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        {e3 ? (
                          <span className="error" style={{ color: "red" }}>
                            {error3}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs="6" className="block">
                        <FormGroup>
                          <Label for="Mobile Phone">
                            License Number
                            {documentType == "License" ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : null}
                          </Label>
                          <Input
                            type="text"
                            name="licence"
                            placeholder="License Number"
                            id="licence"
                            // pattern="^[a-zA-Z0-9]+$"
                            // onChange={(e) => handleLicenceNo(e)}
                            onChange={(e) => setLicenceNo(e.target.value)}
                            value={licenceNo}
                            disabled={documentType ? false : true}
                            autoComplete="off"
                          />
                          <p className="sub-label">
                            Please enter “n/a” if a license number is not
                            applicable.
                          </p>
                        </FormGroup>
                        {e4 ? (
                          <span className="error" style={{ color: "red" }}>
                            {error4}
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                    </>
                  )}

                  {documentType == "License" ? (
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="FirstName">
                          State
                          {documentType == "License" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : null}
                        </Label>
                        <select
                          name="state"
                          className="form-control custom-dropdown"
                          value={selectedState}
                          onChange={(e) => setSelectedState(e.target.value)}
                        >
                          <option value={""}>Select State</option>
                          {states.map((item, idx) => (
                            <option value={[item.code, item.value]} key={idx}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                      {e5 ? (
                        <span className="error" style={{ color: "red" }}>
                          {error5}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  ) : null}

                  {documentType == "Medical Documents" && (
                    <div className="tab-content" style={{ padding: "0 15px" }}>
                      <h2 className="doc-title">
                        Please upload your medical documents pertaining to
                        vaccinations and immunizations, specifically for
                        Measles, Mumps, Rubella, Tdap, Flu, and Covid-19.
                      </h2>
                    </div>
                  )}
                  <Col xs="12" className="block">
                    <FormGroup>
                      <Label>
                        Upload File
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <span className="upload-doc">
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            Upload
                          </InputGroupAddon>
                          <CustomInput
                            type="file"
                            accept={
                              subCatType == "Badge Photo" && ".png, .jpg, .jpeg"
                            }
                            id="upload"
                            name="resume"
                            disabled={documentType ? false : true}
                            onChange={handlesetImage}
                          />
                          {/* {image && (
                            <div>
                              <span>{image.name}</span>
                            </div>
                          )} */}
                        </InputGroup>
                      </span>
                    </FormGroup>
                    {subCatType == "Badge Photo" ? (
                      <p className="helper">Only image files accepted.</p>
                    ) : (
                      <p className="helper">Only image/pdf files accepted.</p>
                    )}
                    {e7 ? (
                      <span className="error" style={{ color: "red" }}>
                        {error7}
                      </span>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <div className="tab-content">
                  <h2 className="doc-title">
                    Please ensure the document you upload is valid and has not
                    expired.
                  </h2>
                </div>
                <div className="btn-block">
                  <Button
                    value="Submit"
                    className="btn-reg"
                    disabled={button}
                    style={{ backgroundColor: "#334ca4" }}
                  >
                    Submit
                    {isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>

        <Modal
          isOpen={priviewShow}
          toggle={handleClosePriview}
          className={`modal-lg contact-rect recruiter-modal request-job-sec-modal ${
            fileType === "application/pdf"
              ? "pdf-thumb-preview"
              : "doc-thumb-preview"
          }`}
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={handleClosePriview}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <div className="list-job pb-50 loader-center">
                {" "}
                <figure className="col-sm-3 p-0 ">
                  {isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : fileType === "application/pdf" ? (
                    <PDFViewer
                      css="pdf-viewer"
                      document={{
                        base64: priviewFile,
                      }}
                      // navigation={customPDF}
                    />
                  ) : priviewFile ? (
                    <img src={`data:image/jpeg;base64,${priviewFile}`} />
                  ) : (
                    <img className="thumb-img" src={NoDocImg} alt="" />
                  )}
                </figure>{" "}
              </div>
            </ModalBody>
          </div>
        </Modal>
        <div className="stories-main-block">
          <div class="preview-bttn">
            <button
              class="button back-btn float-none"
              variant="primary"
              onClick={handletoggle}
            >
              <FiUpload />
              <span>Upload Document</span>
            </button>
          </div>
          <div className="document-grid row">
            {loader ? (
              <div className="list-job pb-50 loader-center">
                {" "}
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image - Travel Nurses Inc"
                  className="loader-center"
                  style={{ width: 80, height: 80 }}
                />{" "}
              </div>
            ) : documentsList && documentsList.length > 0 ? (
              documentsList.map((item, i) => {
                let extension = "";
                if (item && item.ctmsFileName) {
                  const parts = item && item.ctmsFileName.split(".");
                  extension = parts[parts.length - 1];
                }

                return (
                  <div className="document-block col-sm-6" key={i}>
                    <div className="document-in">
                      <a
                        onClick={(e) => handlePriviewModel(e, item)}
                        className="popup-link"
                      ></a>
                      <div className="row">
                        <figure className="col-sm-3 p-0 ">
                          {item.licenseFileType === "application/pdf" ? (
                            <FaFilePdf />
                          ) : extension == "jpeg" ? (
                            <img className="thumb-img" src={JPEGImg} alt="" />
                          ) : extension == "jpg" ? (
                            <img className="thumb-img" src={JPGImg} alt="" />
                          ) : (
                            <img className="thumb-img" src={PNGImg} alt="" />
                          )}
                        </figure>
                        <div className="doc-cont col-sm-9">
                          <span>
                            {item.ctmsFileName ? item.ctmsFileName : ""}
                          </span>
                          <div className="doc-tag">
                            {" "}
                            <span className="doc-cat">{item.categoryName}</span>
                            <br />
                            <span>{item.licenseName}</span>
                          </div>
                          <div className="doc-date">
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>Uploaded</Tooltip>
                              )}
                              placement="top"
                            >
                              <span className="date-block">
                                <em id="TooltipExample">
                                  <img src={UploadedIcon} alt="" />
                                </em>
                                <em>
                                  {Moment(item.createdDate).format(
                                    "MM-DD-YYYY"
                                  )}
                                </em>
                              </span>
                            </OverlayTrigger>
                          </div>
                          {item.licenseExpiration != null && (
                            <div className="doc-date">
                              {" "}
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>Expires</Tooltip>
                                )}
                                placement="top"
                              >
                                <span className="date-block">
                                  <em id="TooltipExample">
                                    <img src={ExpiresIcon} alt="" />
                                  </em>
                                  <em>
                                    {Moment(item.licenseExpiration).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </em>
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}
                          {item.categoryName === "License" ? (
                            <div className="doc-date">
                              <span>
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Location</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <p>
                                    <img src={MapIcon} alt="" />
                                    <em>{item.state ? item.state : "N/A"}</em>
                                  </p>
                                </OverlayTrigger>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="upcoming-text">No Data Found</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Documents;
