import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { BiSad } from "react-icons/bi";
class NotFound404 extends React.Component {
  render() {
    return (
      <div
        className="error-block text-center"
        // className="login-page main-page p-tb-50 bg-grey"
      >
        <div className="not-found-page">
          <Container>
            <div className="not-found-page-in">
              {/* <BiSad /> */}
              <h1>Access Denied</h1>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default NotFound404;
