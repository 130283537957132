import React, { Component } from "react";
import images from "../Components/images";
import Subscribeblock from "../Components/subscribe-block";
// import Destination from "../Components/destination-block";
import { Container, Row, Col } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { JSONLD, Generic  } from "react-structured-data";
class MakeEasy extends React.Component {
  constructor() {
    super();
    this.state = {
      info: [],
      metaTitle: "",
      metaDescription : "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      altImgName: "",
    };
  }
  componentDidMount() {
    let data1 = [];
    axios
      // .get(AppConstants.CMS_API + "/weMakeEasy/allWemakeiteasyContents")
      .get(AppConstants.CMS_API + "/weMakeEasy/allMakeEasyfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ info: data.data });
        if (data.Status === "Success") {
          data.data.map((item) => {
            var obj = {};

            if (item.type === "find") {
              obj["title"] = item.title;
              obj["description"] = item.description;
              obj["link"] = "/make-easy#Job";
              obj["titleicon"] = require("../images/find-job-blue.png");
              obj["id"] = "Job";
              obj["img"] = item.image1;
              data1[0] = obj;
            } else if (item.type === "apply") {
              obj["title"] = item.title;
              obj["description"] = item.description;
              obj["link"] = "/make-easy#Apply";
              obj["titleicon"] = require("../images/apply-icon-blue.png");
              obj["id"] = "Apply";
              obj["img"] = item.image1;
              // data1[1].push(obj);
              data1[1] = obj;
            } else {
              obj["title"] = item.title;
              obj["description"] = item.description;
              obj["link"] = "/make-easy#Pack-bag";
              obj["titleicon"] = require("../images/packyourbags.png");
              obj["id"] = "Pack-bag";
              obj["img"] = item.image1;
              data1[2] = obj;
              // data1[2].push(obj);
            }
            // data1.push(obj);
          });
          this.setState({ 
            info: data1,
            metaTitle: data.data[0].metaTitle,
            metaDescription: data.data[0].metaDescription,
            metaTags: data.data[0].metaTags,
            ogTitle: data.data[0].ogTitle,
            ogDescription: data.data[0].ogDescription,
            altImgName: data.data[0].altImgName,
          });
        }
      });
  }
  render() {
    // let makeiteasy = [
    //   {
    //     title: "Find your dream job",
    //     titleicon: require("../images/find-job-blue.png"),
    //     descrop: [
    //       <p>
    //         Lorem Ipsum is simply dummy text of the printing and typesetting
    //         industry. Lorem Ipsum has been the industry's standard dummy text
    //         ever since the 1500s, when an unknown printer took a galley of type
    //         and scrambled it to make a type specimen book.
    //       </p>,
    //       <p>
    //         Contrary to popular belief, Lorem Ipsum is not simply random text.
    //         It has roots in a piece of classical Latin literature from 45 BC,
    //         making it over 2000 years old.
    //       </p>,
    //     ],
    //     img: require("../images/job-search-img.jpg"),
    //     id: "Job",
    //   },
    //   {
    //     title: "Apply",
    //     titleicon: require("../images/apply-icon-blue.png"),
    //     descrop: [
    //       <p>
    //         Lorem Ipsum is simply dummy text of the printing and typesetting
    //         industry. Lorem Ipsum has been the industry's standard dummy text
    //         ever since the 1500s, when an unknown printer took a galley of type
    //         and scrambled it to make a type specimen book. It has done survived
    //         not only five centuries, but also the leap into electronic the
    //         typesetting, remaining essentially unchanged.
    //       </p>,
    //       <p>
    //         Contrary to popular belief, Lorem Ipsum is not simply random text.
    //         It has roots in a piece of classical Latin literature from 45 BC,
    //         making it over 2000 years old.
    //       </p>,
    //     ],
    //     img: require("../images/apply-img.jpg"),
    //     id: "Apply",
    //   },
    //   {
    //     title: "Pack your bags",
    //     titleicon: require("../images/submit-icon-blue.png"),
    //     descrop: [
    //       <p>
    //         Lorem Ipsum is simply dummy text of the printing and typesetting
    //         industry. Lorem Ipsum has been the industry's standard dummy text
    //         ever since the 1500s, when an unknown printer took a galley of type
    //         and scrambled it to make a type specimen book. It has done survived
    //         not only five centuries
    //       </p>,
    //       <p>
    //         Contrary to popular belief, Lorem Ipsum is not simply random text.
    //         It has roots in a piece of classical Latin literature from 45 BC,
    //         making it over 2000 years old. Richard McClintock, a Latin professor
    //         at the Hampden-Sydney College in Virginia, looked up one of the more
    //         or obscure Latin words, consectetur, from a Lorem Ipsum passage, and
    //         going through the cites of the word.
    //       </p>,
    //     ],
    //     img: require("../images/pack-bag.jpg"),
    //     id: "Pack-bag",
    //   },
    // ];
    return (
      <div className="makeiteasy-page">
        <JSONLD>
          <Generic type="webpage" jsonldtype="We Make It Easy"
            schema={{
            name : this.state.metaTitle, 
            description: this.state.metaDescription,
            contentReferenceTime: new Date()
          }}
          >
          </Generic>
        </JSONLD>
         <Helmet>
          <title>{AppConstants.Make_Easy}</title>
          <meta name="title" content={this.state.metaTitle}/>
          <meta name="description" content={this.state.metaDescription}/>

          <meta property="og:type" content="website"/>
          <meta property="og:url" content={AppConstants.Base_Url+"/make-easy"}/>
          <meta property="og:title" content={this.state.ogTitle}/>
          <meta property="og:description" content={this.state.ogDescription}/>
          <meta property="og:image" content={this.state.image}/>

          <meta property="twitter:card" content={this.state.image}/>
          <meta property="twitter:url" content={AppConstants.Base_Url+"/make-easy"}/>
          <meta property="twitter:title" content={this.state.metaTitle}/>
          <meta property="twitter:description" content={this.state.metaDescription}/>
          <meta property="twitter:image" content={this.state.image}/>
        </Helmet>  
        
        <div className="inner-block pad-tb">
          <Container>
            <h1 className="title">{AppConstants.Make_Easy}</h1>
            <p className="text-center ">{AppConstants.Sub_Make_Easy}</p>
            <div className="inner-content">
              {this.state.info.map((item,index) => (
                <Row id={item.id} className="main-block">
                  <Col xs="6" className="left-block block">
                    <figure className="pic image-fill">
                      <img src={item.img} alt= {"info-pic-name - "+index+" - Travel Nurses Inc"}/>
                    </figure>
                  </Col>
                  <Col xs="6" className="right-block block">
                    <div className="">
                      <div className="small-title">
                        <span>
                          <img src={item.titleicon} alt= {"title-pic-icon - "+index+" - Travel Nurses Inc"}/>
                        </span>
                        {item.title}
                      </div>
                      <div className="descrop">{item.description}</div>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default MakeEasy;
