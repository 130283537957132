import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CompletedIcon from "../images/completed-icon.png";
import DatePicker from "react-datepicker";
import axios from "axios";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert";
import { FaTrashAlt, FaPen } from "react-icons/fa";
 

class ReferenceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleReference: [
        {
          Employer: "",
          Name: "",
          Title: "",
          Email: "",
          Phone: "",
          StartDate: "",
          EndDate: "",
          Unit: "",
          city: "",
          state: "",
          _id: "",
          ctmsReferenceId: "",
        },
      ],
      refItem: {
        refItemId: "",
        Employer: "",
        Name: "",
        Title: "",
        Email: "",
        Phone: "",
        StartDate: "",
        EndDate: "",
        Unit: "",
        city: "",
        state: "",
        ctmsReferenceId: "",
      },
      states: [],
      countrys: [],
      errors: [],
      errMsg: {
        Employer: false,
        Name: false,
        Phone: false,
        Email: false,
      },
      ref: [],
      editStatus: false,
      index: "",
      modalType: "",
      loader: false,
      button: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      userId: localStorage.getItem("id") ? localStorage.getItem("id") : "",
    };
  }

  componentDidMount() {
    this.handleReferencesListAPI();
    this.getStatesListAPI();
  }

  handleReferencesListAPI = async () => {
    const userId = localStorage.getItem("id");
    const that = this;
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          if (
            response.data &&
            response.data.data &&
            response.data.data.Professional_Reference.length > 0
          ) {
            var multipleReference1 = [];
            response.data.data.Professional_Reference.map((item, index) => {
              var exp = {};
              if (item.isActive == true) {
                exp["Employer"] = item.Employer;
                if (item.StartDate) {
                  exp["StartDate"] = new Date(item.StartDate);
                } else {
                  exp["StartDate"] = "";
                }
                if (item.EndDate) {
                  exp["EndDate"] = new Date(item.EndDate);
                } else {
                  exp["EndDate"] = "";
                }
                exp["Name"] = item.Name ? item.Name : "";
                exp["Title"] = item.Title ? item.Title : "";
                exp["Email"] = item.Email ? item.Email : "";
                exp["Phone"] = item.Phone ? item.Phone : "";
                exp["Unit"] = item.Unit ? item.Unit : "";
                exp["city"] = item.city ? item.city : "";
                exp["state"] = item.state ? item.state : "";
                exp["_id"] = item._id;
                exp["ctmsReferenceId"] = item.ctmsReferenceId;
                multipleReference1.push(exp);
              }
              var new1 = [];
              multipleReference1.map((item, i) => {
                if (i < 2) {
                  new1.push(item);
                }
              });
              that.setState({
                multipleReference:
                  item.isActive == true
                    ? multipleReference1
                    : that.state.multipleReference,
                ref: new1,
              });
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getStatesListAPI = async () => {
    const _this = this;
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllWorkLocationStates",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          _this.setState({
            states: response.data && response.data.data && response.data.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleStartDate = (event) => {
    this.setState({
      refItem: { ...this.state.refItem, StartDate: event },
    });
  };

  handleEndDate = (event) => {
    this.setState({
      refItem: { ...this.state.refItem, EndDate: event },
    });
  };

  handleOnChanges = (event) => {
    this.setState({
      refItem: {
        ...this.state.refItem,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleExpValidation() {
    let errors = [];
    let formIsValid = true;
    if (!this.state.refItem.Employer) {
      formIsValid = false;
      errors["Employer"] = "Please enter employer.";
    }
    if (!this.state.refItem.Name) {
      formIsValid = false;
      errors["Name"] = "Please enter your name.";
    }
    if (!this.state.refItem.Phone) {
      formIsValid = false;
      errors["Phone"] = " Please enter your phone number.";
    }
    if (!this.state.refItem.Email) {
      formIsValid = false;
      errors["Email"] = "Please enter your email address.";
    }
    this.setState({
      errors: errors,
      errMsg: {
        ...this.state.errMsg,
        Employer: true,
        Name: true,
        Phone: true,
        Email: true,
      },
    });
    return formIsValid;
  }

  createReferenceUI() {
    return (
      <div className="education-tab">
        <section className="add-form-block row">
          {this.state.multipleReference &&
            this.state.multipleReference.length > 0
            ? this.state.multipleReference.map((item, i) => {
              return (
                <div className="form-block col-md-4" key={i}>
                  {item._id && (
                    <div className="form-block-in">
                      <figure>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => this.editToggle(e, item)}
                          className="edit"
                        >
                          <FaPen />
                        </a>
                        {this.state.multipleReference.length > 2 ? (
                          <a
                            href="javascript:void()"
                            className="delete"
                            onClick={(e) =>
                              this.removeReferenceItem(e, item._id)
                            }
                          >
                            <FaTrashAlt />
                          </a>
                        ) : (
                          ""
                        )}
                      </figure>
                      <h2>{item.Employer}</h2>
                      <span>{item.Name}</span>
                      <br />
                      <span>{item.Email}</span>
                      <br />
                      <span>{item.Phone}</span>
                    </div>
                  )}
                </div>
              );
            })
            : "null"}{" "}
        </section>
      </div>
    );
  }

  addReferenceItem = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "save" });
    this.setState({
      refItem: {
        ...this.state.refItem,
        Employer: "",
        Name: "",
        Title: "",
        Email: "",
        Phone: "",
        StartDate: "",
        EndDate: "",
        Unit: "",
        city: "",
        state: "",
      },
    });
  };

  editToggle = (e, item) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "edit" });
    this.setState({
      refItem: {
        ...this.state.refItem,
        refItemId: item._id,
        Employer: item.Employer,
        Name: item.Name,
        Title: item.Title,
        Phone: item.Phone,
        StartDate: item.StartDate,
        EndDate: item.EndDate,
        Unit: item.Unit,
        city: item.city,
        state: item.state,
        ctmsReferenceId: item.ctmsReferenceId,
      },
    });
  };

  removeReferenceItem = (e, itemId) => {
    e.preventDefault();
    const that = this;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // swal("Reference item has been deleted!", {
        //   icon: "success",
        // });
        if (itemId) {
          var config = {
            method: "post",
            url:
              AppConstants.API +
              "/nurse/deleteProfileSection/" +
              that.state.userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: that.state.token,
            },
            data: JSON.stringify({
              recordId: itemId,
              section_name: "Professional_Reference",
            }),
          };
          axios(config)
            .then(function (response) {
              that.handleReferencesListAPI();
              that.props.getProfilePersentage();
              if (that.state.modal) {
                that.setState({ loader: false, modal: !that.state.modal });
              }
              that.setState({ loader: false });
              if (response.data.Status === "Success") {
                NotificationManager.success(response.data.msg, "", 5000);
              } else {
                NotificationManager.error(response.data.msg, "", 5000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          if (this.state.modal) {
            this.setState({ loader: false });
          }
          this.setState({ loader: false, modal: !this.state.modal });
        }
      }
      this.setState({ loader: false });
    });
  };

  handleReferenceOnSubmit = (e, type) => {
    const that = this;
    let tempId = localStorage.getItem("tempId");
    const userId = localStorage.getItem("id");
    e.preventDefault();
    if (type === "save") {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "Professional_Reference",
          Professional_Reference: [
            {
              Employer: this.state.refItem.Employer,
              Name: this.state.refItem.Name,
              Title: this.state.refItem.Title,
              Email: this.state.refItem.Email,
              Phone: this.state.refItem.Phone,
              StartDate: this.state.refItem.StartDate,
              EndDate: this.state.refItem.EndDate,
              Unit: this.state.refItem.Unit,
              city: this.state.refItem.city,
              state: this.state.refItem.state,
              tempId: tempId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API +
            "/nurse/createProfileSection/" +
            that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleReferencesListAPI();
            that.setState({
              loader: false,
              modal: !that.state.modal,
              modalType: "",
              button: false,
            });
            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              that.props.getProfilePersentage();
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "Professional_Reference",
          Professional_Reference: [
            {
              _id: this.state.refItem.refItemId,
              Employer: this.state.refItem.Employer,
              Name: this.state.refItem.Name,
              Title: this.state.refItem.Title,
              Email: this.state.refItem.Email,
              Phone: this.state.refItem.Phone,
              StartDate: this.state.refItem.StartDate,
              EndDate: this.state.refItem.EndDate,
              Unit: this.state.refItem.Unit,
              city: this.state.refItem.city,
              state: this.state.refItem.state,
              tempId: tempId,
              ctmsReferenceId: this.state.refItem.ctmsReferenceId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API + "/nurse/EditProfileSection/" + that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleReferencesListAPI();
            that.setState({
              loader: false,
              modal: !that.state.modal,
              modalType: "",
              button: false,
            });
            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              that.props.getProfilePersentage();
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  handleClick = () => {
    this.props.HandleAccordion("3");
  };

  signToggle = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "" });
    this.setState({
      errors: [],
      errMsg: {
        ...this.state.errMsg,
        Employer: false,
        Name: false,
        Phone: false,
        Email: false,
      },
    });
  };

  handleAckPop(type) {
    if (type === "edit") {
      this.props.toggle();
    }
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={(e) => this.handleCancel(e)}
          className="modal-md sign-save-popup profile-form-popup"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={(e) => this.handleCancel(e)}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <div className="profile-form-block">
                <Row>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Employer">
                        Employer <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Employer"
                        placeholder="Employer"
                        value={
                          this.state.refItem.Employer
                            ? this.state.refItem.Employer
                            : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                        onClick={this.handleAckPop(this.state.modalType)}
                      />
                      {this.state.errMsg.Employer ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["Employer"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Name">
                        Name<span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Name"
                        placeholder="Name"
                        value={
                          this.state.refItem.Name ? this.state.refItem.Name : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                      {this.state.errMsg.Name ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["Name"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Title">Title</Label>
                      <Input
                        type="text"
                        name="Title"
                        placeholder="Title"
                        value={
                          this.state.refItem.Title
                            ? this.state.refItem.Title
                            : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Email">
                        Email <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Email"
                        placeholder="Email"
                        value={
                          this.state.refItem.Email
                            ? this.state.refItem.Email
                            : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                      {this.state.errMsg.Email ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["Email"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Phone">
                        Phone <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Phone"
                        placeholder="Phone"
                        format="(###) ###-####"
                        mask=""
                        value={
                          this.state.refItem.Phone
                            ? this.state.refItem.Phone
                            : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                      {this.state.errMsg.Phone ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["Phone"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup className="date-picker">
                      <Label for="StartDate">Start Date</Label>
                      <DatePicker
                        name="StartDate"
                        placeholderText="MM/DD/YYYY"
                        className="form-control"
                        selected={this.state.refItem.StartDate}
                        onChange={this.handleStartDate.bind(this)}
                        // autoComplete="off"
                        onKeyDown={(e) => e.preventDefault()}
                        ref="StartDate"
                        icon="iconsminds-calendar-4"
                      // onClick={this.props.toggle}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup className="date-picker">
                      <Label for="EndDate">End Date</Label>
                      <DatePicker
                        name="EndDate"
                        placeholderText="MM/DD/YYYY"
                        className="form-control"
                        selected={this.state.refItem.EndDate}
                        onChange={this.handleEndDate.bind(this)}
                        onKeyDown={(e) => e.preventDefault()}
                        ref="EndDate"
                        icon="iconsminds-calendar-4"
                      // onClick={this.props.toggle}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Unit">Unit</Label>
                      <Input
                        type="text"
                        name="Unit"
                        placeholder="Unit"
                        value={
                          this.state.refItem.Unit ? this.state.refItem.Unit : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={
                          this.state.refItem.city ? this.state.refItem.city : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="state">State</Label>
                      <select
                        name="state"
                        className="form-control custom-dropdown"
                        placeholder="State"
                        value={
                          this.state.refItem.state
                            ? this.state.refItem.state
                            : ""
                        }
                        onChange={(e) => this.handleOnChanges(e)}
                      >
                        <option value={""}>Select State</option>
                        {this.state.states.map((item, idx) => (
                          <option value={item.code} key={idx}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div class="form-bttn">
                <Form className="inner-block">
                  <div className="sign-bttn-sec row justify-content-between">
                    <div className="btn-left">
                      {this.state.modalType === "save" ? (
                        ""
                      ) : this.state.multipleReference.length > 2 ? (
                        <div className="btn-left">
                          <button
                            className="blue-bttn"
                            onClick={(e) =>
                              this.removeReferenceItem(
                                e,
                                this.state.eduItem.refItemId
                              )
                            }
                            style={{ fontFamily: "Figtree Black" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <div className="btn-right">
                      <button
                        className="blue-bttn"
                        onClick={(e) =>
                          this.handleReferenceOnSubmit(e, this.state.modalType)
                        }
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Save{" "}
                        {this.state.loader ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                      <button
                        onClick={(e) => this.handleCancel(e)}
                        className="clear-bttn"
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Button
          color="link"
          onClick={(e) => this.handleClick(e)}
          aria-expanded={this.props.collapse === "3"}
        >
          References
          <div class="w-15 w-xs-100">
            <figure className="form-progress-icon ">
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => <Tooltip {...props}>Incomplete</Tooltip>}
                placement="top"
              >
                {(this.props.status &&
                  this.props.status.finalExperiancesStatus &&
                  this.props.status.finalExperiancesStatus[2] &&
                  this.props.status.finalExperiancesStatus[2].references &&
                  this.props.status.finalExperiancesStatus[2].references ==
                  "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[1] &&
                    this.props.status.finalExperiancesStatus[1].references &&
                    this.props.status.finalExperiancesStatus[1].references ==
                    "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[0] &&
                    this.props.status.finalExperiancesStatus[0].references &&
                    this.props.status.finalExperiancesStatus[0].references ==
                    "Completed") ? (
                  <img src={CompletedIcon} alt="Progress Icon" />
                ) : (
                  <span className="form-tag">
                    {this.state.multipleReference.length} of 2
                  </span>
                )}
              </OverlayTrigger>
            </figure>
          </div>
        </Button>
        <Collapse
          isOpen={this.props.collapse === "3"}
          className="accordian-cont-block"
        >
          <Form
            className={`form-block contact-info ${this.state.editStatus === false ? "input-disable" : ""
              }`}
          >
            {this.createReferenceUI()}

            <a
              href="javascript:void(0)"
              onClick={(e) => this.addReferenceItem(e)}
              className="click-link add-form"
            >
              {" "}
              <span>+</span>
              <em>Add Reference</em>
            </a>
          </Form>
        </Collapse>
      </>
    );
  }
}

export default ReferenceSection;
