import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import styles from "../Styles/registration.css";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import { registerUser } from "../actions/authentication";
import { connect } from "react-redux";
import { FaAngleDoubleLeft } from "react-icons/fa";

class RegistrationNew extends React.Component {
  constructor(props, context) {
    super(props, context);
    const pathname =
      this.props && this.props.location && this.props.location.pathname
        ? this.props.location.pathname
        : "";
    // Use regular expressions to extract fname and lname
    const fnameMatch = pathname ? pathname.match(/fname=([^&]*)/) : "";
    const lnameMatch = pathname ? pathname.match(/lname=([^&]*)/) : "";
    const pEmail = pathname ? pathname.match(/email=([^&]*)/) : "";
    const pPhone = pathname ? pathname.match(/phone=([^&]*)/) : "";
    const pCrtId = pathname ? pathname.match(/certification=([^&]*)/) : "";
    const pSplId = pathname ? pathname.match(/speciality=([^&]*)/) : "";

    this.state = {
      /*Personal Info */
      firstName: fnameMatch ? decodeURIComponent(fnameMatch[1]) : "",
      captch: "6LdVTbElAAAAAF8gpRMHNs7kO9sODz7OjKFB7AjW",
      check: "",
      checkErrMes: "",
      checkError: false,
      lastName: lnameMatch ? decodeURIComponent(lnameMatch[1]) : "",
      /*Contact Info */
      primaryPhoneNumber: pPhone ? pPhone[1] : "",
      primaryEmail: pEmail ? decodeURIComponent(pEmail[1]) : "",
      certifications: [],
      errors: [],
      firstNameError: false,
      lastNameError: false,
      primaryPhoneError: false,
      firstNameError1: "",
      lastNameError1: "",
      primaryPhoneError1: "",
      primaryEmailError: false,
      primaryEmailError1: "",
      cerError1: "",
      cerError: false,
      speError1: "",
      speError: false,
      errMsg: "",
      isLoading: false,
      dropdownStatus: false,
      certDropdown: false,
      specialityDropdown: false,
      items: [],
      certsCopy: [],
      selectedStates: [],
      selectedCerts: [],
      selectedSpecialities: [],
      certsOriginal: [],
      specialityOriginal: [],
      resumeError1: "",
      resumeError: false,
      resume: "",
      countryCodesArr: [],
      countryCode: "1",
      codeError: false,
      errorsCode: "",
      referralSources: [],
      referralSource: "",
      referralBy: "",
      otherReferralSource: "",
      recruiters: [],
      recruiter: "",
      referralSourceErr: "",
      referredByErr: "",
      otherReferralSourceErr: "",
      referralSourceError: false,
      referredByError: false,
      otherReferralSourceError: false,
      button_disable: false,
      referralSourceId: "",
      ipAddress: "",
      crtId: pCrtId ? decodeURIComponent(pCrtId[1]) : "",
      splId: pSplId ? decodeURIComponent(pSplId[1]) : "",
      hasRecruiter: "", // Default value for radio buttons
      recruiterError: false,
      recruiterError1: "",

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputsChange = this.handleInputsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrimaryPhone = this.handlePrimaryPhone.bind(this);
    this.toggleClosedCert = this.toggleClosedCert.bind(this);
    this.handleSelectCrt = this.handleSelectCrt.bind(this);
    this.handleSelectSpl = this.handleSelectSpl.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleResumeChange = this.handleResumeChange.bind(this);
    this.handleRecruiterRadioChange = this.handleRecruiterRadioChange.bind(this);

  }
  handleRecruiterRadioChange(e) {
    this.setState({
      hasRecruiter: e.target.value,
      recruiter: e.target.value === "no" ? "" : this.state.recruiter, // Reset recruiter if "no" is selected
      recruiterError: false,
      recruiterError1: ""
    });
  }
  handleResumeChange(event) {
    const resumeFileType = event.target.files[0].name
      .split(".")
      .pop()
      .toLowerCase();
    if (
      resumeFileType === "doc" ||
      resumeFileType === "docx" ||
      resumeFileType === "pdf"
    ) {
      this.setState({
        resume: event.target.files[0],
        resumeError1: "",
        resumeError: false,
      });
    } else {
      this.setState({
        resumeError1: "Please upload only PDF or DOC files",
        resumeError: true,
      });
    }
  }

  handlePrimaryPhone = (values) => {
    this.setState({
      primaryPhoneNumber: values.value,
    });
    if (values.value) {
      if (values.value.length === 10) {
        this.setState({ primaryPhoneError: false });
      } else {
        this.setState({
          primaryPhoneError1: "Please enter your valid phone number.",
          primaryPhoneError: true,
        });
      }
    } else {
      this.setState({
        primaryPhoneError: true,
        primaryPhoneError1: "Please enter your phone number.",
      });
    }
  };
  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  async componentDidMount() {
    const componentState = this;
    if (
      (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
    ) {
      this.setState({ dropdownStatus: true });
    }
    if (this.props.location.state) {
      this.setState({
        firstName: this.props.location.state.firstName,
        lastName: this.props.location.state.lastName,
        primaryEmail: this.props.location.state.email,
        primaryPhoneNumber: this.props.location.state.phoneNumber,
      });
    }
    // country codes
    axios
      .get(AppConstants.Jobs_API + "/jobs/getAllCountries")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({ countryCodesArr: data.data });
        }
      });

    // certifications
    await axios
      .get(
        AppConstants.Jobs_API + "/certification/getCtmsMatchedCertifications"
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({
            certifications: data.data,
          });
        }
      });
    axios
      .get(AppConstants.Jobs_API + "/specialities/getReferralSources")
      .then((res) => {
        if (res.data.Status === "Success") {
          var dataVal = res.data.data;
          var output = dataVal.sort((a, b) =>
            a.referralSource.localeCompare(b.referralSource)
          );
          const defaultReferral = output.find(
            (source) => source.referralSource === "Travel Nurses, Inc. Website"
          );

          this.setState({
            referralSources: output,
            referralSource: defaultReferral
              ? defaultReferral.referralSource
              : "",
          });
        }
      });

    axios.get(AppConstants.API + "/users/getRecruitersListApp").then((res) => {
      if (res.data.Status === "Success") {
        let array = res.data.data;
        componentState.setState({
          recruiters: array.sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          ),
        });
      }
    });
    if (this.state.crtId) {
      this.handleSelectCrt(this.state.crtId);
    }
    setTimeout(() => {
      this.state.splId && this.handleSelectSpl(this.state.splId);
    }, 1000);
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    const ipAddress = data && data.ip;
    this.setState({ ipAddress: ipAddress });
  }

  handleOnChange = (value) => {
    this.setState({
      checkErrMes: "",
      checkError: false,
      check: value,
    });
  };

  handleInputChange(e) {
    this.setState({
      firstNameError: false,
      lastNameError: false,
      primaryPhoneError: false,
      firstNameError1: "",
      lastNameError1: "",
      primaryPhoneError1: "",
      primaryEmailError: false,
      primaryEmailError1: "",
      cerError1: "",
      cerError: false,
      speError1: "",
      speError: false,
      referralSourceErr: "",
      referredByErr: "",
      otherReferralSourceErr: "",
      referralSourceError: false,
      referredByError: false,
      otherReferralSourceError: false,
      recruiterError: false,
      recruiterError1: ""
    });
    this.setState({ [e.target.name]: e.target.value });
  }

  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }

  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }

  handleSelectCrt(e) {
    this.setState({ selectedCerts: [], selectedSpecialities: [] });
    if (e?.target?.value) {
      const index = this.state.certifications.findIndex(
        (x) => x.value == e?.target?.value
      );
      this.certFunction(this.state.certifications[index]);
      var obj = this.state.certifications[index];
      var crtArray = [];
      crtArray.push(obj);
      this.setState({
        selectedCerts: crtArray,
        selectedSpecialities: [],
      });
      this.setState({ speError: false, cerError: false });
    } else if (e == this.state.crtId) {
      const index = this.state.certifications.findIndex((x) => x.value == e);
      this.certFunction(this.state.certifications[index]);
      var obj = this.state.certifications[index];
      var crtArray = [];
      crtArray.push(obj);
      this.setState({
        selectedCerts: crtArray,
      });
    } else {
      this.setState({ items: [], selectedCerts: [], selectedSpecialities: [] });
      this.setState({ speError: true, cerError: true });
    }
    this.setState({
      firstNameError: false,
      lastNameError: false,
      primaryPhoneError: false,
      firstNameError1: "",
      lastNameError1: "",
      primaryPhoneError1: "",
      primaryEmailError: false,
      primaryEmailError1: "",
      cerError1: "",
      speError1: "",
      referralSourceErr: "",
      referredByErr: "",
      otherReferralSourceErr: "",
      referralSourceError: false,
      referredByError: false,
      otherReferralSourceError: false,
    });
  }

  async certFunction(selectedList) {
    var certVal = [];
    selectedList && certVal.push(selectedList._id);
    let tablefilterdata = {
      ctmsCertId: certVal,
    };
    await axios
      .post(
        AppConstants.Jobs_API + "/specialities/getCtmsMatchedSpecialities",
        tablefilterdata
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          if (data.data.length > 0) {
            this.setState({
              items: data.data,
            });
          } else {
            this.setState({ selectedSpecialities: [] });
          }
        }
      });
  }

  handleCertFocus() {
    if (this.state.selectedCerts.length === 0) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    } else {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }

  handleSpecialityFocus() {
    if (this.state.selectedSpecialities.length === 0) {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    } else {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }

  handleSelectSpl(e) {
    this.setState({ speError: true });
    if (e?.target?.value) {
      const index = this.state.items.findIndex(
        (x) => x.value == e.target.value
      );
      var obj1 = this.state.items[index];
      var spiArray = [];
      spiArray.push(obj1);
      this.setState({
        selectedSpecialities: spiArray,
      });
      this.setState({
        firstNameError: false,
        lastNameError: false,
        primaryPhoneError: false,
        firstNameError1: "",
        lastNameError1: "",
        primaryPhoneError1: "",
        primaryEmailError: false,
        primaryEmailError1: "",
        cerError1: "",
        cerError: false,
        speError1: "",
        speError: false,
        referralSourceErr: "",
        referredByErr: "",
        otherReferralSourceErr: "",
        referralSourceError: false,
        referredByError: false,
        otherReferralSourceError: false,
      });
    } else {
      const index = this.state.items.findIndex((x) => x.value == e);
      var obj1 = this.state.items[index];
      var spiArray = [];
      spiArray.push(obj1);
      this.setState({
        selectedSpecialities: spiArray,
      });
      this.setState({
        firstNameError: false,
        lastNameError: false,
        primaryPhoneError: false,
        firstNameError1: "",
        lastNameError1: "",
        primaryPhoneError1: "",
        primaryEmailError: false,
        primaryEmailError1: "",
        cerError1: "",
        cerError: false,
        speError1: "",
        speError: false,
        referralSourceErr: "",
        referredByErr: "",
        otherReferralSourceErr: "",
        referralSourceError: false,
        referredByError: false,
        otherReferralSourceError: false,
      });
    }

    // var obj1 = this.state.items[index];
    // var spiArray = [];
    // spiArray.push(obj1);
    // this.setState({
    //   selectedSpecialities: spiArray,
    // });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    // Recruiter validation
    if (!this.state.hasRecruiter) {
      formIsValid = false;
      errors["hasRecruiter"] = "Please select whether you have a recruiter";
      this.setState({
        recruiterError1: "Please select whether you have a recruiter",
        recruiterError: true,
      });
    }
    if (this.state.hasRecruiter === "yes" && !this.state.recruiter) {
      formIsValid = false;
      this.setState({
        recruiterError1: "Please select a recruiter from the dropdown",
        recruiterError: true,
      });
      errors["recruiter"] = "Please select a recruiter from the dropdown";
    }
    if (!this.state.referralBy) {
      formIsValid = false;
      this.setState({
        referredByErr: "Please enter did someone refer you?",
        referredByError: true,
      });
      errors["referredBy"] = "Please enter did someone refer you?";
    }
    if (this.state.referralSource === "Other") {
      if (!this.state.otherReferralSource) {
        formIsValid = false;
        this.setState({
          otherReferralSourceErr: "Please enter other referral source",
          otherReferralSourceError: true,
        });
      }
    }
    if (!this.state.referralSource) {
      formIsValid = false;
      this.setState({
        referralSourceErr: "Please select how did you hear about us?",
        referralSourceError: true,
      });
    }

    if (!this.state.primaryEmail) {
      formIsValid = false;
      this.setState({
        primaryEmailError1: "Please enter your email.",
        primaryEmailError: true,
        showContactInfo: false,
      });
      ReactDOM.findDOMNode(this.refs.primaryEmail).focus();
    }
    if (this.state.primaryEmail !== "") {
      let lastAtPos = this.state.primaryEmail.lastIndexOf("@");
      let lastDotPos = this.state.primaryEmail.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.primaryEmail.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.primaryEmail.length - lastDotPos > 2
        )
      ) {
        this.setState(
          {
            primaryEmailError1: "Please enter your valid email.",
            primaryEmailError: true,
            showContactInfo: false,
          },
          () => {
            console.log(this.state.primaryEmailError1);
          }
        );
        ReactDOM.findDOMNode(this.refs.primaryEmail).focus();
      }
    }

    if (this.state.selectedCerts.length === 0) {
      formIsValid = false;
      this.setState({
        cerError1: "Please select your certification.",
        cerError: true,
      });
    }
    if (this.state.selectedSpecialities.length === 0) {
      formIsValid = false;
      this.setState({
        speError1: "Please select your Specialty.",
        speError: true,
      });
    }
    if (!this.state.primaryPhoneNumber) {
      formIsValid = false;
      this.setState({
        primaryPhoneError1: "Please enter your phone number.",
        primaryPhoneError: true,
      });
      // ReactDOM.findDOMNode(this.refs.primaryPhoneNumber).focus();
    }
    if (
      this.state.primaryPhoneNumber &&
      this.state.primaryPhoneNumber.length !== 10
    ) {
      this.setState({
        primaryPhoneError1: "Please enter your phone number.",
        primaryPhoneError: true,
      });
      // ReactDOM.findDOMNode(this.refs.primaryPhoneNumber).focus();
    }
    if (!this.state.lastName) {
      formIsValid = false;
      this.setState({
        lastNameError1: "Please enter last name.",
        lastNameError: true,
      });
      // ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      this.setState({
        firstNameError1: "Please enter your first name.",
        firstNameError: true,
      });
      // ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (!this.state.countryCode) {
      formIsValid = false;

      this.setState({
        errorsCode: "Please select country code",
        codeError: true,
      });
      // ReactDOM.findDOMNode(this.refs.countryCode).focus();
    }

    return formIsValid;
  }
  handleCodeChange = (e) => {
    this.setState({
      countryCode: e.target.value,
    });
  };

  handleValidationCode() {
    let errorsCode = "";
    let formIsValidCode = true;
    if (!this.state.countryCode) {
      formIsValidCode = false;
      errorsCode = "Please select country code";
      ReactDOM.findDOMNode(this.refs.countryCode).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }

  handleInputsChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    this.state.referralSources.filter((x) => {
      if (x.referralSource == e.target.value) {
        var value = x.referralSourceId;
        this.setState({ referralSourceId: value });
      }
    });
    if (e.target.name === "referralSource") {
      if (e.target.value === "") {
        this.setState({ referralSourceError: true });
      } else {
        this.setState({ referralSourceError: false });
      }
    }
    if (e.target.name === "referralBy") {
      if (e.target.value === "") {
        this.setState({ referredByError: true });
      } else {
        this.setState({ referredByError: false });
      }
    }
    if (e.target.name === "otherReferralSource") {
      if (e.target.value === "") {
        this.setState({ otherReferralSourceError: true });
      } else {
        this.setState({ otherReferralSourceError: false });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      let formData = new FormData();
      formData.append("ipAddress", this.state.ipAddress);
      formData.append("path", "web");
      var contactInformation = {};
      contactInformation["primaryEmail"] =
        this.state.primaryEmail.toLowerCase();
      contactInformation["primaryPhoneNumber"] = this.state.primaryPhoneNumber;
      formData.append("contactInformation", JSON.stringify(contactInformation));
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("resume", this.state.resume);
      formData.append("phoneCode", this.state.countryCode);
      formData.append("recruiter", this.state.recruiter);
      // formData.append("hasRecruiter", this.state.hasRecruiter);
      var referralInformation = {};
      referralInformation["referralSource"] = this.state.referralSource;
      referralInformation["referralSourceId"] = this.state.referralSourceId;
      referralInformation["referralBy"] = this.state.referralBy;
      formData.append(
        "referralInformation",
        JSON.stringify(referralInformation)
      );
      var proffesionalInformation = {};
      var cerA = [];
      var speA = [];
      this.state.selectedCerts.map((a) => {
        var certApiArray = {};
        certApiArray["ctmsCertId"] = a._id;
        certApiArray["ctmsCertName"] = a.ctmsCertName;
        cerA.push(certApiArray);
        proffesionalInformation["certifications"] = cerA;
      });
      this.state.selectedSpecialities.map((a) => {
        var specApiArray = {};
        specApiArray["ctmsSpecId"] = a.speciality;
        specApiArray["ctmsSpecName"] = a.ctmsSpecialityName;
        speA.push(specApiArray);
        proffesionalInformation["specialities"] = speA;
      });
      formData.append(
        "proffesionalInformation",
        JSON.stringify(proffesionalInformation)
      );
      this.props.registerUser(formData, this.props.history);
      setTimeout((e) => {
        this.setState({ isLoading: false });
      }, 2000);
    }
  }
  render() {
    return (
      <div className="main-page registr-info apply-form">
        <Container>
          <div className="preview-bttn back-bttn">
            <button
              class="button back-btn float-none"
              onClick={() => this.props.history.push("/login")}
            >
              <FaAngleDoubleLeft />
              <span>Back</span>
            </button>
          </div>
          <div className="registration-form search-block">
            <div className="title">{AppConstants.Nurse_Reg}</div>
            <p className="text-center">
              When you create your profile, you'll get access to a tailored
              job-matching system that uses advanced technology to match you
              with the most relevant job opportunities based on your skills,
              experience, qualifications, and desired location. We prioritize
              your privacy and never sell your information. Our user-friendly
              platform makes it easy to apply for jobs with just one click.
              <br />
              Register now and start exploring healthcare opportunities today.
            </p>
            <h5 className="text-center">{AppConstants.Nurse_Reg_Sub}</h5>
            <Form
              name=""
              className="inner-block"
              id="traveler-registration-form"
              onSubmit={this.handleSubmit}
            >
              <div>
                <Form className="form-block personal-info">
                  <Row>
                    {/* firstname */}
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="FirstName">
                          First Name<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          id="FirstName"
                          onChange={this.handleInputChange}
                          value={this.state.firstName}
                          ref="firstName"
                        />
                        <span>
                          {this.state.firstNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.firstNameError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>

                    {/* lastname */}
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label>
                          Last Name<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          ref="lastName"
                          onChange={this.handleInputChange}
                        />
                        <span>
                          {this.state.lastNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.lastNameError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="codephone-blk">
                    {/* Phone Code */}
                    <Col xs="2" className="block country-code  disable-field ">
                      <FormGroup>
                        <Label for="countryCode">Country Code</Label>
                        <Input
                          type="select"
                          value={this.state.countryCode}
                          name="countryCode"
                          ref="countryCode"
                          onChange={this.handleCodeChange}
                          id="countryCode"
                          disabled={true}
                        >
                          {this.state.countryCodesArr.map((code, i) => {
                            return (
                              <option key={i} id={i} value={code.phoneCode}>
                                {code.phoneCode === 1 ? "USA" : code.name}{" "}
                                {"+" + code.phoneCode}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    {/* Phone Number */}
                    <Col xs="5" className="block phone-input">
                      <FormGroup>
                        <Label>
                          Phone Number
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <NumberFormat
                          format="(###) ###-####"
                          mask=""
                          name="primaryPhoneNumber"
                          placeholder="Phone Number"
                          onValueChange={this.handlePrimaryPhone}
                          value={this.state.primaryPhoneNumber}
                          className="form-control"
                          ref="primaryPhoneNumber"
                        />
                        <span>
                          {this.state.primaryPhoneError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.primaryPhoneError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    {/* email */}
                    <Col xs="5" className="block">
                      <FormGroup>
                        <Label>
                          Email
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="primaryEmail"
                          placeholder="Email"
                          value={this.state.primaryEmail}
                          onChange={this.handleInputChange}
                          ref="primaryEmail"
                        />
                        <span>
                          {this.state.primaryEmailError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.primaryEmailError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    <span>
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Row>
                  <Row>
                    <Col xs="6" className="inout-block certi-bttn">
                      <FormGroup>
                        <Label>
                          Certification
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name="state"
                          className="form-control custom-dropdown"
                          value={
                            this.state.selectedCerts &&
                              this.state.selectedCerts[0]
                              ? this.state.selectedCerts[0].value
                              : ""
                          }
                          onChange={(e) => this.handleSelectCrt(e)}
                        >
                          <option value={""}>Select Any</option>
                          {this.state.certifications.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                        <span>
                          {this.state.cerError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.cerError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                      {/* <div className="form-input">
                        <div
                          className="block_new"
                          onClick={() => {
                            this.toggleOpenCertification();
                          }}
                        >
                          <label>
                            Certification
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactMultiSelectCheckboxes
                            options={this.state.certifications}
                            value={this.state.selectedCerts}
                            onChange={(value, event) => {
                              this.handleCertChange(value, event);
                            }}
                            isSearchable={true}
                            placeholderButtonLabel="Select Any"
                            onFocus={this.handleCertFocus.bind(this)}
                            menuIsOpen={
                              this.state.dropdownStatus === true
                                ? ""
                                : this.state.certDropdown
                            }
                            onBlur={this.handleCertBlur.bind(this)}
                          />
                        </div>
                        <span>
                          {this.state.cerError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.cerError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div> */}
                    </Col>
                    <Col xs="6" className="inout-block">
                      <FormGroup>
                        <Label>
                          Specialty
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name="state"
                          className="form-control custom-dropdown"
                          value={
                            this.state.selectedSpecialities &&
                              this.state.selectedSpecialities[0]
                              ? this.state.selectedSpecialities[0].value
                              : ""
                          }
                          onChange={(e) => this.handleSelectSpl(e)}
                        >
                          <option value={""}>Select Any</option>
                          {this.state.items.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                        <span>
                          {this.state.speError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.speError1}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <p className="px-3">
                    Note : Please only select 1 specialty in which you have 1
                    full year of experience within the last 3 years. If you need
                    to select additional specialties, please notify your
                    recruiter.
                  </p>

                  <Row>
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="ReferralSource">
                          How did you hear about us?
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="select"
                          name="referralSource"
                          id="ReferralSource"
                          value={this.state.referralSource}
                          onChange={this.handleInputsChange}
                          ref="referralSource"
                          className="custom-dropdown"
                        >
                          <option value="">Select</option>
                          {this.state.referralSources.map((ele, index) => {
                            return (
                              <option id={index} value={ele.referralSource}>
                                {ele.referralSource}
                              </option>
                            );
                          })}
                        </Input>
                        <span>
                          {this.state.referralSourceError ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.referralSourceErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    {this.state.referralSource === "Other" ? (
                      <Col xs="6" className="block">
                        <FormGroup>
                          <Label for="OtherReferralSource">
                            Other Referral Source
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="otherReferralSource"
                            id="OtherReferralSource"
                            placeholder="Other Referral Source"
                            value={this.state.otherReferralSource}
                            onChange={this.handleInputsChange}
                            ref="otherReferralSource"
                          />
                          <span>
                            {this.state.otherReferralSourceError ? (
                              <span className="error" style={{ color: "red" }}>
                                {this.state.otherReferralSourceErr}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="ReferralBy">
                          Did someone refer you?
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="referralBy"
                          id="ReferralBy"
                          placeholder="Please enter who refer you?"
                          value={this.state.referralBy}
                          onChange={this.handleInputsChange}
                          ref="referralBy"
                        />

                        <p className="helper">
                          Please enter “n/a” if not referred by Word of Mouth.
                        </p>
                        <br />
                        <span>
                          {this.state.referredByError ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.referredByErr}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col xs="12" className="block mt-3" id="mail-field">
                      <FormGroup>
                        <Label>
                          Do you have a recruiter?
                          <span className="error" style={{ color: "red" }}>*</span>
                        </Label>
                        <div>
                        {!this.state.hasRecruiter && this.state.recruiterError &&(
                          <span className="error" style={{ color: "red" }}>
                            {"Please select whether you have a recruiter"}
                          </span>
                        )}
                        </div>
                        <div 
                          className="check-sec"
                          style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            gap: '20px' 
                          }}
                        >
                          <span className="radio-form-action">
                            <Input
                              type="radio"
                              name="hasRecruiter"
                              id="hasRecruiterYes"
                              value="yes"
                              checked={this.state.hasRecruiter === "yes"}
                              onChange={this.handleRecruiterRadioChange}
                            />
                            <Label for="hasRecruiterYes" className="check-field">
                              I already have a recruiter
                            </Label>
                          </span>
                          <span className="radio-form-action">
                            <Input
                              type="radio"
                              name="hasRecruiter"
                              id="hasRecruiterNo"
                              value="no"
                              checked={this.state.hasRecruiter === "no"}
                              onChange={this.handleRecruiterRadioChange}
                            />
                            <Label for="hasRecruiterNo" className="check-field">
                              I do not have a recruiter
                            </Label>
                          </span>
                          
                        </div>
                        
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.hasRecruiter === "yes" && (
                    <Row>
                      <Col xs="6" className="block">
                        <FormGroup className="custom-radio">
                          <Label for="RecruiterSelect">
                            Do you have a recruiter you want to work with ?
                          </Label>
                          <Input
                            type="select"
                            name="recruiter"
                            id="RecruiterSelect"
                            className="custom-dropdown"
                            value={this.state.recruiter}
                            onChange={this.handleInputsChange}
                          >
                            <option value="">Select</option>
                            {this.state.recruiters.map((item, index) => {
                              return (
                                <option value={item.value} id={index}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Input>
                          <span>
                            {this.state.recruiterError === true ? (
                              <span className="error" style={{ color: "red" }}>
                                {this.state.recruiterError1}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>)}
                  <Row>
                    <Col xs="12" className="block resume-button">
                      <FormGroup>
                        <Label>Resume</Label>
                        <span className="upload-doc">
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              Upload
                            </InputGroupAddon>
                            <CustomInput
                              type="file"
                              id="upload"
                              name="resume"
                              onChange={this.handleResumeChange}
                            />
                          </InputGroup>
                          <span>
                            {this.state.resumeError === true ? (
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["resume"]}
                                {this.state.resumeError1}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs="6" className="block">
                      <Row>
                        {/* <ReCAPTCHA
                          sitekey={this.state.captch}
                          onChange={this.handleOnChange}
                        /> */}
                        {/* <span>
                          {this.state.checkError ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.checkErrMes}
                            </span>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
              <Row className="justify-content-center">
                <Col md="12">
                  <span
                    class="btn btn-next button"
                    title="Update"
                    onClick={this.handleSubmit}
                  >
                    Submit
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </Col>
              </Row>
            </Form>
            {this.state.errMsg}
            <NotificationContainer />

            {/* <div className="all-jobs-list text-center py-5">
              <h1
                className="mb-0"
                style={{ fontFamily: "DomineBold", color: "#25408f" }}>
                Thankyou,
              </h1>
              <h2 style={{ fontFamily: "DomineBold", color: "#21ade4" }}>
                we received your application.
              </h2>
              <p className="my-4">
                A recruiter will be in touch with you soon!
                <br />
                In the mean time, check to see if there are other job openings
                that interest you.
              </p>

              <a class="sky-bttn" href="#">
                SEE ALL JOBS
              </a>
            </div> */}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userID, status, msg } = auth;
  return { loading, error, userID, status, msg };
};
export default connect(mapStateToProps, { registerUser })(RegistrationNew);
