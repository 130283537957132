import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import AppConstants from "../AppConstants";
import { BiBookmark } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { saveJobAction } from '../actions/saved-jobs'

function SavedJobs(props) {
  console.log(props,props.loading, props.dashboardSaveAction,"save job action")
  const [jobsList, setJobsList] = useState([]);

  useEffect(() => {
    let nurseId = localStorage.getItem("id");
    const controller = new AbortController();
    getjobsListAPI(nurseId,controller);
    return () => {
      controller.abort();
    };
  }, [props.dashboardSaveAction]);
  async function getjobsListAPI(nurseId, signal){
    try {
      const response = await axios.post(
        `${AppConstants.API }/nurse/getSavedJobs/${nurseId}`,
        { page: 1, sizeperpage: 10 },
        { signal }
      );
      setJobsList(response.data.savedJobs);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        NotificationManager.error("Error fetching saved jobs", "", 3000);
      }
    } 
  };
  const handleSaveJobClick = async (jobsId) => {
    let nurseId = localStorage.getItem("id");
    await axios
      .post(AppConstants.API + "/nurse/SaveJobAction", {
        nurseId: nurseId,
        jobId: jobsId,
      })
      .then(async (response) => {
        await getjobsListAPI(nurseId);
        response.data.Status
          ? NotificationManager.success(response.data.msg, "", 3000)
          : NotificationManager.error(response.data.msg, "", 3000);
      });
  };
  return (
    <>
      <div className="list-job" id="saved-jobs-sec">
        {jobsList && jobsList.length > 0 ? (
          jobsList.map((item, index) => (
            <div className="job-info block-box" key={index}>
              <ul className="d-flex">
                <Link
                  to={{
                    pathname: `/job/${item._id}`,
                  }}
                >
                  <li className="job-location">
                    <img
                      src={item && item.stateImages && item.stateImages[0]}
                      alt={item.state + " - Travel Nurses Inc"}
                    />
                    <h5>
                      {item.city}
                      <br />
                      {item.state}
                    </h5>
                  </li>
                </Link>

                <li className="job-position">
                  <h4>{item.title}</h4>
                  <p>{item.jobSpeciality}</p>
                  {item.status === "Open" && (
                    <Link
                      to={{
                        pathname: `/job/${item._id}`,
                      }}
                      className="read-more"
                    >
                      View More
                    </Link>
                  )}
                </li>

                <li className="job-time">
                  <p className="date">
                    Start Date:{" "}
                    <em>{moment(item.startDate).format("MM-DD-YYYY")}</em>
                  </p>
                  <p className="date">
                    End Date:{" "}
                    <em>{moment(item.endDate).format("MM-DD-YYYY")}</em>
                  </p>
                  <p className="time">{item.shift}</p>
                </li>
                <li className="btn-section">
                  {item.status === "Closed" && (
                    <div className="w-15 w-xs-100">
                      <span className="badge badge-secondary badge-pill">
                        Expired
                      </span>
                    </div>
                  )}
                  <div
                    className={`saved-text ${item.isSaved ? "RemoveJob" : ""}`}
                    onClick={() => handleSaveJobClick(item._id)}
                  >
                    <button>
                      {item.isSaved ? <BsFillBookmarkFill /> : <BiBookmark />}
                      <span>{item.isSaved ? "Remove" : "Save"}</span>
                    </button>
                  </div>
                  <aside className="view-more-grid">
                    {item.status === "Open" && (
                      <Link
                        to={{
                          pathname: `/job/${item._id}`,
                        }}
                        className="read-more"
                      >
                        View More
                      </Link>
                    )}
                  </aside>
                  <aside className="apply-bttn-grid">
                    {item.status === "Open" && (
                      <Link to={`/apply-job/${item._id}`} className="button">
                        Apply
                      </Link>
                    )}
                  </aside>
                </li>
              </ul>
            </div>
          ))
        ) : (
          <p className="upcoming-text">No Saved Jobs Found</p>
        )}
      </div>
    </>
  );
}
const mapStateToProps = ({ savedJob }) => {
  const { loading, dashboardSaveAction } = savedJob;
  return { loading, dashboardSaveAction };
};
export default connect(mapStateToProps, { saveJobAction })(SavedJobs);
