import React, { Component } from "react";
import Subscribeblock from "../Components/subscribe-block";
// import Destination from "../Components/destination-block";
import images from "../Components/images";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Container, Row, Col, Button } from "reactstrap";
import RecruiterTag from "../images/recruiter-tag.png";
import styles from "../Styles/team.css";
import ReactPlayer from "react-player";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import MessagePopUp from "./message_popup";
import { Helmet } from "react-helmet";
import { JSONLD, Generic } from "react-structured-data";
import ExpandableHTML from "../NewUI/components/ExpandableHTML";

class TeamDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      profile_image: "",
      social_media: [],
      description: "",
      video: "",
      name: "",
      email: "",
      phone: "",
      designation: "",
      tags: [],
      chat: "",
      modal: false,
      pinned_reviews_src: "",
      secondaryEmail: "",
      isGreatRecruiter: "",
      hiddenMails: [
        "bryanb@travelnursesinc.com",
        "panigrahi.sandeep72@gmail.com",
      ],
    };
    this.handleModal = this.handleModal.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }
  handleMessage(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ modal: true });
  }
  handleCollapse = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(
        AppConstants.API + "/users/getUserBySlug/" + this.props.match.params.id
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          axios
            .get(AppConstants.API + "/users/getUser/" + data.data._id)
            .then((res) => {
              return res.data;
            })
            .then((data) => {
              // console.log(data.data)
              if (data.Status === "Success") {
                var val = "";
                if (data.data.videoType === "url") {
                  val = data.data.videoLink;
                } else {
                  val = data.data.videoFile;
                }
                this.setState({
                  // recruiter:data.data,
                  profile_image: data.data.profileImage,
                  social_media: data.data.socialMedia,
                  description: data.data.description,
                  video: val,
                  name: data.data.name,
                  email: data.data.email,
                  phone: data.data.phone,
                  designation: data.data.designation,
                  tags: data.data.tags,
                  secondaryEmail: data.data.secondaryEmail,
                  pinned_reviews_src: data.data.pinned_reviews_src
                    ? data.data.pinned_reviews_src
                    : "",
                  isGreatRecruiter: data.data.isGreatRecruiter,
                });
              }
            });
        }
      });
  }
  handleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };
  render() {
    const regex = /(<([^>]+)>)/gi;
    const htmlContent = `${this.state.description.replace(regex, "")}`; // Your complex HTML content
    const collapsedNumWords = 100;
    var result = "";
    if (this.state.description) {
      result = this.state.description.replace(regex, "");
    }
    // const result = this.state.description.replace(regex, '');
    return (
      <div className="team-detail-blk">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Recruiters"
            schema={{
              name: this.state.name,
              description: this.state.description.replace(/(<([^>]+)>)/gi, ""),
              contentReferenceTime: new Date(),
              breadcrumb: "Home >> Our Recruiters >> " + this.state.name,
            }}
          ></Generic>
        </JSONLD>
        <Helmet>
          <title>{this.state.name + " - Traveling Nurses Inc"}</title>
          <meta
            name="title"
            content={this.state.name + " - Traveling Nurses Inc"}
          />
          <meta
            name="description"
            content="Recruiter Details - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={
              AppConstants.Base_Url + "/team/" + this.props.match.params.id
            }
          />
          <meta
            property="og:title"
            content={this.state.name + " - Traveling Nurses Inc"}
          />
          <meta
            property="og:description"
            content="Recruiter Details - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
          />
          <meta
            property="og:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />

          <meta
            property="twitter:card"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />
          <meta
            property="twitter:url"
            content={
              AppConstants.Base_Url + "/team/" + this.props.match.params.id
            }
          />
          <meta
            property="twitter:title"
            content={this.state.name + " - Traveling Nurses Inc"}
          />
          <meta
            property="twitter:description"
            content="Recruiter Details - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
          />
          <meta
            property="twitter:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />

          {/* gr pinned reviews */}

          <script
            async
            defer
            crossorigin="anonymous"
            src={this.state.pinned_reviews_src}
          ></script>
        </Helmet>

        <div className="inner-block">
          <Container>
            {/* Breadcrumb Block  Start Here */}
            <Breadcrumb>
              <BreadcrumbItem>
                {/* <a href="#">Home</a> */}
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {/* <a href="#">Our Recruiters</a> */}
                <Link to="/team">Our Team</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.name}
                {this.state.tags.length > 0
                  ? ", " + this.state.tags.toString()
                  : ""}
              </BreadcrumbItem>
            </Breadcrumb>
            {/* Breadcrumb Block  End Here */}
            <div className="inner-content">
              <Row>
                <Col xs="4" className="left-block text-center">
                  <figure className="pic image-fill">
                    <img
                      src={this.state.profile_image}
                      alt={this.state.name + " - Travel Nurses Inc"}
                    />
                  </figure>
                  <div className="social-icons">
                    {this.state.social_media.map((item, k) => {
                      return (
                        // <Link to={item.url}>
                        // </Link>
                        <a
                          key={k}
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={item.name}
                        ></a>
                      );
                    })}
                    {this.state.phone ? (
                      <a
                        className="comment"
                        onClick={this.handleMessage}
                      ></a>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div id="gr-pinned-reviews"></div> */}
                  <MessagePopUp
                    modal={this.state.modal}
                    phone={this.state.phone}
                    handleModal={this.handleModal}
                  />
                </Col>
                <Col xs="8" className="right-block">
                  <h1>
                    {this.state.name}
                    {this.state.isGreatRecruiter && (
                      <img src={RecruiterTag} alt="" className="profile-tag" />
                    )}
                    {this.state.tags.length > 0
                      ? ", " + this.state.tags.toString()
                      : ""}{" "}
                  </h1>
                  <p className="designation">
                    {this.state.designation && this.state.designation != "null"
                      ? this.state.designation
                      : ""}
                  </p>
                  {this.state.phone ? (
                    // <a href={"tel:901.425.1628"} className="phone">
                    <a href={`tel:${this.state.phone}`} className="phone">
                      {/* {this.state.phone.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) ($2)-$3"
                    )} Call*/}
                      {window.innerWidth >= 320 && window.innerWidth <= 767
                        ? "Call"
                        : this.state.phone.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          )}
                    </a>
                  ) : (
                    ""
                  )}

                  {this.state.hiddenMails.includes(
                    this.state.secondaryEmail
                  ) ? null : this.state.secondaryEmail ? (
                    <a
                      href={"mailto:" + this.state.secondaryEmail}
                      className="mail"
                    >
                      {window.innerWidth >= 320 && window.innerWidth <= 767
                        ? "Email"
                        : this.state.secondaryEmail}
                    </a>
                  ) : (
                    <a href={"mailto:" + this.state.email} className="mail">
                      {/* {this.state.email} */}
                      {window.innerWidth >= 320 && window.innerWidth <= 767
                        ? "Email"
                        : this.state.email}
                    </a>
                  )}

                  <div>
                    <ExpandableHTML
                      htmlContent={htmlContent}
                      collapsedNumWords={collapsedNumWords}
                      onCollapse={this.handleCollapse}
                    />
                  </div>
                  {this.state.video ? (
                    <div className="video-block">
                      {/* <iframe
                        src="https://www.youtube.com/embed/zT1A7iti6jw"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> */}
                      <ReactPlayer
                        url={this.state.video}
                        playing
                        controls
                        height="auto"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md="12">
                  <div id="gr-pinned-reviews"></div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default TeamDetails;
