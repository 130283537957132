// import { SET_CURRENT_USER, FETCH_USER_DATA, UPDATE_USER_DATA,GET_USER,UPDATE_USER } from '../actions/types';
import {
  SET_CURRENT_USER,
  GET_USER,
  GET_USER_INFO,
  REGISTER_USER,
  REGISTER_ERROR,
  GET_ERRORS,
  GET_SUCCESS,
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  userID: "",
  status: "",
  user: {},
  profileImgUpdated: false,
  userInfo: {},
  msg: "",
  loading: false,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
      };

    case GET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case REGISTER_USER:
      return {
        ...state,
        userID: action.payload.id,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        status: action.payload.Status,
        msg: action.payload.msg,
      };

    case GET_USER_INFO:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        userInfo: action.payload,
        profileImgUpdated: !state.profileImgUpdated
      };

    default:
      return state;
  }
}
