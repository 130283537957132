import React, { Component } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import styles from "../Styles/autoslider.css";
import axios from "axios";
import AppConstants from "../AppConstants";
import ReactAutocomplete from "react-autocomplete";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Dropdown, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
class Destination extends React.Component {
  constructor() {
    super();
    this.state = {
      value: [],
      items: [],
      states: [],
      currentPage: 1,
      selectedPageSize: 10,
      selectedOrderOption: "",
      searchText: "",
      speciality: "",
      location: "",
      cities: [],
      state: [],
      city: [],
      citiesCopy: [],
      searchRadius: "20",
      selectedSpecialities: [],
      selectedStates: [],
      selectedCities: [],
      stateCopy: [],
      cityDropdown: "",
      specialityCopy: [],
      citiesDuplicate: [],
      statesDuplicate: [],
      specialitiesDuplicate: [],
      citiesOriginal: [],
      statesOriginal: [],
      specialityOriginal: [],
      stateDropdown: "",
      specialityDropdown: "",
      dropdownStatus: false,
      items1: [],
      states1: [],
      cities1: [],
      items2: [],
      states2: [],
      cities2: [],
      showLoc: false,
      certifications: [],
      certification: [],
      selectedCerts: [],
      certDropdown: "",
      value1: "",
      isOpen: true,
      citiesAuto: [],
      city1: "",
      certsDuplicate: [],
      certsOriginal: [],
      certsCopy: [], 
    };
    this.handlesearchRadius = this.handlesearchRadius.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.valueStateChange = this.valueStateChange.bind(this);
    this.handlCityChange = this.handlCityChange.bind(this);
    this.valueCityChange = this.valueCityChange.bind(this);
    this.toggleOpenCity = this.toggleOpenCity.bind(this);
    this.toggleClosedCity = this.toggleClosedCity.bind(this);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.toggleClosedState = this.toggleClosedState.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.toggleClosedSpeciality = this.toggleClosedSpeciality.bind(this);
    this.handleCertChange = this.handleCertChange.bind(this);
    this.toggleClosedCert = this.toggleClosedCert.bind(this);
  }
  toggleOpenState() {
    this.setState({ stateDropdown: true });
  }
  handleStateBlur() {
    this.toggleClosedState();
  }
  toggleClosedState() {
    this.setState({ stateDropdown: false });
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenCity() {
    if (this.state.selectedStates.length > 1) {
      this.setState({ cityDropdown: false });
    } else {
      this.setState({ cityDropdown: true });
    }
  }
  handleCityBlur() {
    this.toggleClosedCity();
  }
  toggleClosedCity() {
    this.setState({ cityDropdown: false });
  }
  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }
  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }
  handleCertChange(selectedList,event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.certifications.length - 1) {
      selectedList = this.state.certifications;
    } else {
      selectedList = selectedList;
    }
    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    this.state.certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.certFunction(selectedList, this.state.selectedStates, this.state.value1, this.state.searchRadius );
    this.setState({
      certifications: result,
      certification: selectedList,
      selectedCerts: selectedList,
      certsDuplicate: result,
    });
  }
  certFunction(selectedList, states, city, radius) {
    var certVal = [];
    selectedList.map((item) => {
      certVal.push(item.value);
    });
    var stateVal = [];
    states.map((item) => {
      stateVal.push(item.code);
    })
    var cityName = "";
    if (city) {
      cityName = city.split(',');
    }
    let tablefilterdata = {
      certs: certVal,
      states: this.state.showLoc ? stateVal : [],
      city: this.state.showLoc ? "" : cityName[0], 
      searchRadius: this.state.showLoc ? "" : radius
    };
    if (selectedList.length === 0 && states.length === 0 && city === "") {
      this.setState({ items: this.state.specialityOriginal });
    } else {
      axios.post(AppConstants.Jobs_API + "/specialities/getSpecsWithJobsCountBasedOnFilters", tablefilterdata
      ).then((res) => {
        return res.data;
      }).then((data) => {
        if (data.Status === "Success") {
          if (data.data.length > 0) {
            this.setState({
              items: [
                {
                  _id: "All",
                  speciality: "All",
                  jobsCount: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
              specialityCopy: [
                {
                  _id: "All",
                  speciality: "All",
                  jobsCount: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
            const myArrayFiltered = data.data.filter((array) =>
              this.state.selectedSpecialities.some(
                (filter) => filter.value === array.value
              )
            );
            if (myArrayFiltered.length > 0) {
              this.setState({ selectedSpecialities: myArrayFiltered });
            } else {
              this.setState({ selectedSpecialities: [] });
            }
          } else {
            this.setState({ selectedSpecialities: [] });
          }
        }
      });
    }
  }
  handleCertFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedCerts.length === 0
    ) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    } else if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedCerts.length !== 0
    ) {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }
  handlesearchRadius(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.certFunction(this.state.selectedCerts, this.state.selectedStates, this.state.value1, e.target.value );
    this.specialityFunction(this.state.selectedSpecialities, this.state.selectedStates, this.state.value1, e.target.value);
  }
  componentDidMount() {
    if ((this.props, window.innerWidth >= 320 && this.props, window.innerWidth <= 767)) {
      this.setState({ dropdownStatus: true });
    }
    axios.get(AppConstants.Jobs_API + "/jobs/getAllStates").then((res) => {
      if (res.data.Status === "Success") {
        this.setState({ 
          statesOriginal: [
            {
              _id: "All",
              code: "All",
              label: "All",
              value: "All",
            },
            {
              _id: "Compact",
              code: "Compact",
              label: "Compact",
              value: "Compact",
            },
            ...res.data.data,
          ],
          stateLoading: false,
        });
      }
    });
    axios.get(AppConstants.Jobs_API + "/specialities/getSpecialitiesWithJobsCount").then((res) => {
      return res.data;
    }).then((data) => {
        if (data.Status === "Success") {
          this.setState({
            specialityOriginal: [
              {
                _id: "All",
                speciality: "All",
                jobsCount: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ],
          });
        }
      });
      axios.get(
        AppConstants.Jobs_API + "/certification/getCertificationsWithJobsCount"
      ).then((res) => {
          return res.data;
      }).then((data) => {
        if (data.Status === "Success") {
          this.setState({
            certsOriginal: [
              {
                _id: "All",
                certification: "All",
                jobsCount: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ]
          });
        }
      });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      cityDropdown: false,
      stateDropdown: false,
      specialityDropdown: false,
    });
    if (this.props.destinationHistory) {
      this.props.destinationHistory.history.push({
        // pathname: "/jobs",
        pathname: "/",
        // state: { speciality: this.state.value,specialities: this.state.items, city: this.state.city, cities:this.state.cities, state: this.state.state, states: this.state.states,searchRadius:this.state.searchRadius },
        state: {
          speciality: this.state.selectedSpecialities,
          specialities: this.state.items,
          // city: this.state.selectedCities,
          // cities: this.state.cities,
          // state: this.state.selectedStates,
          states: this.state.states,
          searchRadius: !this.state.showLoc ? this.state.searchRadius : "",
          certification: this.state.selectedCerts,
          city: !this.state.showLoc ? this.state.value1 : "",
          state: this.state.showLoc ? this.state.selectedStates : [],
          certifications: this.state.certifications,
          showLoc: this.state.showLoc,
          showLink: "true" 
        },
      });
    }
  }
  handleReset(e) {
    e.preventDefault();
    this.setState({
      city: "",
      state: "",
      value: "",
      searchRadius: "",
      cities: this.state.citiesOriginal,
      states: this.state.statesOriginal,
      items: this.state.specialityOriginal,
      selectedSpecialities: [],
      selectedStates: [],
      selectedCities: [],
      cityDropdown: false,
      stateDropdown: false,
      specialityDropdown: false,
      certDropdown: false,
      certifications: this.state.certsOriginal,
      selectedCerts: [],
      showLoc: false,
      city1: "",
      value1: ""
    });
  }
  handleChange(value,event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === this.state.items.length - 1) {
      value = this.state.items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      items: result,
      value: value,
      selectedSpecialities: value,
      specialitiesDuplicate: result,
    });
    this.specialityFunction(value, this.state.selectedStates,this.state.value1, this.state.searchRadius);
  };
  pushItem = (item, value) => {
    if (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
      for (var i = 0; i < value.length; i++) {
        if (
          item.label.toLowerCase().charAt(i) === value.toLowerCase().charAt(i)
        ) {
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };
  valueChange = (value) => {
    let vik = [];
    this.state.specialitiesDuplicate.map((item, index) => {
      if (this.pushItem(item, value)) {
        vik.push(item);
      }
    });
    this.setState({ items: [...vik] });
  };
  specialityFunction(selectedList, states, city, radius) {
    var specVal = [];
    selectedList.map((item) => {
      specVal.push(item.value);
    });
    var stateVal = [];
    states.map((item) => {
      stateVal.push(item.code);
    })
    var cityName = "";
    if (city) {
      cityName = city.split(',');
    }
    let tablefilterdata = {
      specialities: specVal,
      states: this.state.showLoc ? stateVal : [],
      city: this.state.showLoc ? "" : cityName[0], 
      searchRadius: this.state.showLoc ? "" : radius
    };
    if (selectedList.length === 0 && states.length == 0 && city == "") {
      this.setState({ certifications: this.state.certsOriginal });
    } else {
      axios.post(
          AppConstants.Jobs_API + "/certification/getCertsBasedOnFilters",
          tablefilterdata
        ).then((res) => {
          return res.data;
        }).then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                certifications: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                certsCopy: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCerts.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCerts: myArrayFiltered });
              } else {
                this.setState({ selectedCerts: [] });
              }
            } else {
              this.setState({ selectedCerts: [] });
            }
          }
        }
      );
    }
  }
  handleStateChange(selectedList,event) {
    if (event.action === "select-option") {
      if(event.option.value === "All" ) {
        selectedList = selectedList.filter((o) => o.value === "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      } else if (event.option.value === "Compact") {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value === "Compact");
      }
      else if(event.option.value !== "All" || event.option.value !== "Compact") {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      }  
    } else if (event.action === "deselect-option" &&
    event.option.value === "All") {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter(o => o.value !== "All")
    } else if (selectedList.length === this.state.states.length - 1) {
      selectedList = this.state.states
    } else {
      selectedList = selectedList
    }
    this.setState({ state: selectedList });
    if (selectedList.length > 1) {
      this.setState({ cityDropdown: false, selectedCities: [] });
    } else {
      // this.setState({cityDropdown : ""})
    }
    // this.stateFunction(selectedList);
    this.certFunction(this.state.selectedCerts, selectedList, this.state.value1, this.state.searchRadius)
    this.specialityFunction(this.state.selectedSpecialities, selectedList, this.state.value1, this.state.searchRadius);
    let stateSel = [];
    selectedList.map((item, index) => {
      stateSel.push(item);
    });
    this.state.stateCopy.map((item, index) => {
      if (stateSel.includes(item)) {
      } else {
        stateSel.push(item);
      }
    });
    var result = stateSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      states: result,
      state: selectedList,
      selectedStates: selectedList,
      statesDuplicate: result,
    });
  }
  valueStateChange(selectedList) {
    // this.setState({ state: selectedList });
    let vik = [];
    this.state.statesDuplicate.map((item, index) => {
      if (this.pushItem(item, selectedList)) {
        vik.push(item);
      }
    });
    this.setState({ states: [...vik] });
  }
  stateFunction(selectedList) {
    this.state.cityLoading = true;
    if (selectedList.length === 1 && this.state.value.length > 0) {
      var val = [];
      this.state.value.map((item) => {
        val.push(item.speciality);
      });
      if (selectedList[0].name === "All") {
        let tablefilterdata = {
          specialities: val,
        };
        axios
          .post(
            AppConstants.Jobs_API + "/jobs/getCitiesBasedOnSpecialities",
            tablefilterdata
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  cities: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                    },
                    ...data.data,
                  ],
                  citiesDuplicate: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesCopy: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  //  selectedCities: [], cityLoading: false
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedCities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedCities: myArrayFiltered });
                }
              }
            }
          });
      } else if (selectedList[0].name === "Compact") {
        let tablefilterdata = {
          specialities: val,
        };
        axios
          .post(
            AppConstants.Jobs_API + "/jobs/getCompactCitiesBasedOnSpecialities",
            tablefilterdata
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  cities: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesDuplicate: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesCopy: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  //  selectedCities: [], cityLoading: false
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedCities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedCities: myArrayFiltered });
                }
              }
            }
          });
      } else {
        let tablefilterdata = {
          specialities: val,
          stateName: selectedList[0].name,
          stateCode: selectedList[0].code,
          stateId: selectedList[0].id,
        };
        axios
          .post(
            AppConstants.Jobs_API +
              "/jobs/getCitiesBasedOnStateNameAndSpeciality",
            tablefilterdata
          )
          // axios
          // .get(AppConstants.CMS_API + "/properties/getCitiesBasedOnStateName/"+ selectedList[0].name)
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  cities: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesDuplicate: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesCopy: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  //  selectedCities: [], cityLoading: false
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedCities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedCities: myArrayFiltered });
                }
              }
            }
          });
      }
    } else if (selectedList.length === 1 && this.state.value.length === 0) {
      if (selectedList[0].name === "All") {
        this.setState({
          cities: this.state.citiesOriginal,
          citiesDuplicate: this.state.citiesOriginal,
          citiesCopy: this.state.citiesOriginal,
          // cityLoading: false,selectedCities: []
        });
      } else if (selectedList[0].name === "Compact") {
        axios
          .get(AppConstants.Jobs_API + "/jobs/getAllCompactCities")
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  cities: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesDuplicate: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesCopy: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  // selectedCities: [],  cityLoading: false
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedCities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedCities: myArrayFiltered });
                }
              }
            }
          });
      } else {
        let tablefilterdata = {
          stateName: selectedList[0].name,
          stateCode: selectedList[0].code,
          stateId: selectedList[0].id,
        };
        axios
          .post(
            AppConstants.Jobs_API + "/jobs/getCitiesBasedOnState",
            tablefilterdata
          )
          // axios.get(AppConstants.CMS_API + "/properties/getCitiesBasedOnStateName/"+ selectedList[0].name)
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  cities: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesDuplicate: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  citiesCopy: [
                    {
                      _id: "All",
                      id: "All",
                      name: "All",
                      nameWithCount: "All",
                      code: "All",
                      adm1code: "All",
                      countryId: "All",
                      jobsData: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  //  selectedCities: [],cityLoading: false
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedCities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedCities: myArrayFiltered });
                }
              }
            }
          });
      }
    }
    if (selectedList.length > 0) {
      if (this.state.city.length > 0) {
        var val = [];
        var val1 = [];
        this.state.city.map((item) => {
          val1.push(item.name);
        });
        selectedList.map((item) => {
          val.push(item.code);
        });
        let tablefilterdata = {
          cities: val1,
          states: val,
        };
        axios
          .post(
            AppConstants.Jobs_API +
              "/specialities/getSpecialitiesBasedOnStatesAndCities",
            tablefilterdata
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  items: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialitiesDuplicate: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityCopy: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityLoading: false,
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedSpecialities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedSpecialities: myArrayFiltered });
                }
              }
            }
          });
      } else {
        var val = [];
        selectedList.map((item) => {
          val.push(item.code);
        });
        let tablefilterdata = {
          states: val,
        };
        axios
          .post(
            AppConstants.Jobs_API +
              "/specialities/getSpecialitiesBasedOnStates",
            tablefilterdata
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  items: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialitiesDuplicate: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityCopy: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityLoading: false,
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedSpecialities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedSpecialities: myArrayFiltered });
                }
              }
            }
          });
      }
    } else if (selectedList.length === 0) {
      if (this.state.value.length> 0 && this.state.city.length> 0) {
        var val = [];
        this.state.selectedCities.map((item) => {
          val.push(item.name);
        });
        let tablefilterdata = {
          cities: val,
        };
        axios
        .post(
          AppConstants.Jobs_API + "/specialities/getSpecialitiesBasedOnCities",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                items: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialitiesDuplicate: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityCopy: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityLoading: false,
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedSpecialities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedSpecialities: myArrayFiltered });
              }
            }
          }
        });
        var stateVal = [];
        this.state.selectedSpecialities.map((item) => {
          stateVal.push(item.speciality);
        });
        let tablefilterdataSpec = {
          specialities: stateVal,
        };
        axios.post(
          AppConstants.Jobs_API + "/jobs/getCitiesBasedOnSpecialities",
          tablefilterdataSpec
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                cities: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                citiesDuplicate: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                citiesCopy: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCities: myArrayFiltered });
              }
            }
          }
        });
      } else if (this.state.value.length > 0 || this.state.city.length === 0) {
        this.setState({
          specialitiesDuplicate: this.state.specialityOriginal,
          specialityCopy: this.state.specialityOriginal,
          items:this.state.specialityOriginal
        })
        const myArrayFiltered = this.state.specialityOriginal.filter((array) =>
          this.state.selectedSpecialities.some(
            (filter) => filter.value === array.value
          )
        );
        if (myArrayFiltered.length > 0) {
          this.setState({ selectedSpecialities: myArrayFiltered });
        }
      } else if (this.state.value.length === 0 && this.state.city.length > 0) {
        this.setState({
          citiesDuplicate: this.state.citiesOriginal,
          citiesCopy: this.state.citiesOriginal,
          cities:this.state.citiesOriginal
        })
        const myArrayFiltered = this.state.citiesOriginal.filter((array) =>
          this.state.selectedCities.some(
            (filter) => filter.value === array.value
          )
        );
        if (myArrayFiltered.length > 0) {
          this.setState({ selectedCities: myArrayFiltered });
        }
      }
    }
  }
  handlCityChange(selectedList,event) {
    if (event.action === "select-option" && event.option.value ===
    "All") {
      selectedList = this.state.cities
    } else if (event.action === "deselect-option" &&
    event.option.value === "All") {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter(o => o.value !== "All")
    } else if (selectedList.length === this.state.cities.length - 1) {
      selectedList = this.state.cities
    } else {
      selectedList = selectedList
    }
    let citySel = [];
    selectedList.map((item, index) => {
      citySel.push(item);
    });
    this.state.citiesCopy.map((item, index) => {
      if (citySel.includes(item)) {
      } else {
        citySel.push(item);
      }
    });
    var result = citySel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.cityFunction(selectedList);
    this.setState({
      cities: result,
      city: selectedList,
      selectedCities: selectedList,
      citiesDuplicate: result,
    });
  }
  valueCityChange(selectedList) {
    let vik = [];
    this.state.citiesDuplicate.map((item, index) => {
      if (this.pushItem(item, selectedList)) {
        vik.push(item);
      }
    });
    this.setState({ cities: [...vik] });
  }
  cityFunction(selectedList) {
    if (this.state.state.length >= 1 && this.state.value.length >= 1 && selectedList.length > 0) {
      var cityVal = [];
      selectedList.map((item) => {
        cityVal.push(item.name);
      });
      var stateVal = [];
      this.state.value.map((item) => {
        stateVal.push(item.speciality);
      });
      let statefilterdata = {
        specialities: stateVal,
        cities: cityVal,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getStatesBasedOnCitiesAndSpecialities",
          statefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                states: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  {
                    _id: "Compact",
                    id: "Compact",
                    name: "Compact",
                    nameWithCount: "Compact",
                    code: "Compact",
                    adm1code: "Compact",
                    countryId: "Compact",
                    jobsData: "Compact",
                    label: "Compact",
                    value: "Compact",
                  },
                  ...data.data,
                ],
                statesDuplicate: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  {
                    _id: "Compact",
                    id: "Compact",
                    name: "Compact",
                    nameWithCount: "Compact",
                    code: "Compact",
                    adm1code: "Compact",
                    countryId: "Compact",
                    jobsData: "Compact",
                    label: "Compact",
                    value: "Compact",
                  },
                  ...data.data,
                ],
                stateCopy: [
                  {
                    _id: "All",
                    id: "All",
                    name: "All",
                    nameWithCount: "All",
                    code: "All",
                    adm1code: "All",
                    countryId: "All",
                    jobsData: "All",
                    label: "All",
                    value: "All",
                  },
                  {
                    _id: "Compact",
                    id: "Compact",
                    name: "Compact",
                    nameWithCount: "Compact",
                    code: "Compact",
                    adm1code: "Compact",
                    countryId: "Compact",
                    jobsData: "Compact",
                    label: "Compact",
                    value: "Compact",
                  },
                  ...data.data,
                ],
                // ,selectedStates: [], stateLoading: false
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedStates.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedStates: myArrayFiltered });
              }
            }
          }
        });
        var valSpec = [];
        var valSpec1 = [];
        this.state.state.map((item) => {
          valSpec1.push(item.code);
        });
        selectedList.map((item) => {
          valSpec.push(item.name);
        });
        let tablefilterdataSpec = {
          cities: valSpec,
          states: valSpec1,
        };
        axios
          .post(
            AppConstants.Jobs_API +
              "/specialities/getSpecialitiesBasedOnStatesAndCities",
              tablefilterdataSpec
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              if (data.data.length > 0) {
                this.setState({
                  items: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialitiesDuplicate: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityCopy: [
                    {
                      _id: "All",
                      speciality: "All",
                      jobsCount: "All",
                      label: "All",
                      value: "All",
                    },
                    ...data.data,
                  ],
                  specialityLoading: false,
                });
                const myArrayFiltered = data.data.filter((array) =>
                  this.state.selectedSpecialities.some(
                    (filter) => filter.value === array.value
                  )
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({ selectedSpecialities: myArrayFiltered });
                }
              }
            }
          });
        } else if (this.state.state.length >= 1 && selectedList.length > 0) {
          var val = [];
          var val1 = [];
          this.state.state.map((item) => {
            val1.push(item.code);
          });
          selectedList.map((item) => {
            val.push(item.name);
          });
          let tablefilterdata = {
            cities: val,
            states: val1,
          };
          axios
            .post(
              AppConstants.Jobs_API +
                "/specialities/getSpecialitiesBasedOnStatesAndCities",
              tablefilterdata
            )
            .then((res) => {
              return res.data;
            })
            .then((data) => {
              if (data.Status === "Success") {
                if (data.data.length > 0) {
                  this.setState({
                    items: [
                      {
                        _id: "All",
                        speciality: "All",
                        jobsCount: "All",
                        label: "All",
                        value: "All",
                      },
                      ...data.data,
                    ],
                    specialitiesDuplicate: [
                      {
                        _id: "All",
                        speciality: "All",
                        jobsCount: "All",
                        label: "All",
                        value: "All",
                      },
                      ...data.data,
                    ],
                    specialityCopy: [
                      {
                        _id: "All",
                        speciality: "All",
                        jobsCount: "All",
                        label: "All",
                        value: "All",
                      },
                      ...data.data,
                    ],
                    specialityLoading: false,
                  });
                  const myArrayFiltered = data.data.filter((array) =>
                    this.state.selectedSpecialities.some(
                      (filter) => filter.value === array.value
                    )
                  );
                  if (myArrayFiltered.length > 0) {
                    this.setState({ selectedSpecialities: myArrayFiltered });
                  }
                }
              }
            });
            var valState = [];
            selectedList.map((item) => {
              valState.push(item.name);
            });
            let tablefilterdataState = {
              cities: valState,
            };
            axios
              .post(
                AppConstants.Jobs_API + "/jobs/getStatesBasedOnCities",
                tablefilterdataState
              )
              .then((res) => {
                return res.data;
              })
              .then((data) => {
                if (data.Status === "Success") {
                  if (data.data.length > 0) {
                    this.setState({
                      states: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                      statesDuplicate: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                      stateCopy: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                    });
                    const myArrayFiltered = data.data.filter((array) =>
                      this.state.selectedStates.some(
                        (filter) => filter.value === array.value
                      )
                    );
                    if (myArrayFiltered.length > 0) {
                      this.setState({ selectedStates: myArrayFiltered });
                    }
                  }
                }
              });
            } else if (this.state.value.length >= 1 && selectedList.length > 0) {
              var cityVal = [];
              selectedList.map((item) => {
                cityVal.push(item.name);
              });
              var stateVal = [];
              this.state.value.map((item) => {
                stateVal.push(item.speciality);
              });
              let statefilterdata = {
                specialities: stateVal,
                cities: cityVal,
              };
              axios
              .post(
                AppConstants.Jobs_API + "/jobs/getStatesBasedOnCitiesAndSpecialities",
                statefilterdata
              )
              .then((res) => {
                return res.data;
              })
              .then((data) => {
                
                if (data.Status === "Success") {
                  if (data.data.length > 0) {
                    this.setState({
                      states: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                      statesDuplicate: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                      stateCopy: [
                        {
                          _id: "All",
                          id: "All",
                          name: "All",
                          nameWithCount: "All",
                          code: "All",
                          adm1code: "All",
                          countryId: "All",
                          jobsData: "All",
                          label: "All",
                          value: "All",
                        },
                        {
                          _id: "Compact",
                          id: "Compact",
                          name: "Compact",
                          nameWithCount: "Compact",
                          code: "Compact",
                          adm1code: "Compact",
                          countryId: "Compact",
                          jobsData: "Compact",
                          label: "Compact",
                          value: "Compact",
                        },
                        ...data.data,
                      ],
                      // ,selectedStates: [], stateLoading: false
                    });
                    const myArrayFiltered = data.data.filter((array) =>
                      this.state.selectedStates.some(
                        (filter) => filter.value === array.value
                      )
                    );
                    if (myArrayFiltered.length > 0) {
                      this.setState({ selectedStates: myArrayFiltered });
                    }
                  }
                }
              });
              var valCity = [];
              selectedList.map((item) => {
                valCity.push(item.name);
              });
              let tablefilterdataCity = {
                cities: valCity,
              };
              axios
                .post(
                  AppConstants.Jobs_API + "/specialities/getSpecialitiesBasedOnCities",
                  tablefilterdataCity
                )
                .then((res) => {
                  return res.data;
                })
                .then((data) => {
                  if (data.Status === "Success") {
                    if (data.data.length > 0) {
                      this.setState({
                        items: [
                          {
                            _id: "All",
                            speciality: "All",
                            jobsCount: "All",
                            label: "All",
                            value: "All",
                          },
                          ...data.data,
                        ],
                        specialitiesDuplicate: [
                          {
                            _id: "All",
                            speciality: "All",
                            jobsCount: "All",
                            label: "All",
                            value: "All",
                          },
                          ...data.data,
                        ],
                        specialityCopy: [
                          {
                            _id: "All",
                            speciality: "All",
                            jobsCount: "All",
                            label: "All",
                            value: "All",
                          },
                          ...data.data,
                        ],
                        specialityLoading: false,
                      });
                      const myArrayFiltered = data.data.filter((array) =>
                        this.state.selectedSpecialities.some(
                          (filter) => filter.value === array.value
                        )
                      );
                      if (myArrayFiltered.length > 0) {
                        this.setState({ selectedSpecialities: myArrayFiltered });
                      }
                    }
                  }
                });
        } else if (selectedList.length > 0) {
          var val = [];
          selectedList.map((item) => {
            val.push(item.name);
          });
          let tablefilterdata = {
            cities: val,
          };
          axios
            .post(
              AppConstants.Jobs_API + "/jobs/getStatesBasedOnCities",
              tablefilterdata
            )
            .then((res) => {
              return res.data;
            })
            .then((data) => {
              if (data.Status === "Success") {
                if (data.data.length > 0) {
                  this.setState({
                    states: [
                      {
                        _id: "All",
                        id: "All",
                        name: "All",
                        nameWithCount: "All",
                        code: "All",
                        adm1code: "All",
                        countryId: "All",
                        jobsData: "All",
                        label: "All",
                        value: "All",
                      },
                      {
                        _id: "Compact",
                        id: "Compact",
                        name: "Compact",
                        nameWithCount: "Compact",
                        code: "Compact",
                        adm1code: "Compact",
                        countryId: "Compact",
                        jobsData: "Compact",
                        label: "Compact",
                        value: "Compact",
                      },
                      ...data.data,
                    ],
                    statesDuplicate: [
                      {
                        _id: "All",
                        id: "All",
                        name: "All",
                        nameWithCount: "All",
                        code: "All",
                        adm1code: "All",
                        countryId: "All",
                        jobsData: "All",
                        label: "All",
                        value: "All",
                      },
                      {
                        _id: "Compact",
                        id: "Compact",
                        name: "Compact",
                        nameWithCount: "Compact",
                        code: "Compact",
                        adm1code: "Compact",
                        countryId: "Compact",
                        jobsData: "Compact",
                        label: "Compact",
                        value: "Compact",
                      },
                      ...data.data,
                    ],
                    stateCopy: [
                      {
                        _id: "All",
                        id: "All",
                        name: "All",
                        nameWithCount: "All",
                        code: "All",
                        adm1code: "All",
                        countryId: "All",
                        jobsData: "All",
                        label: "All",
                        value: "All",
                      },
                      {
                        _id: "Compact",
                        id: "Compact",
                        name: "Compact",
                        nameWithCount: "Compact",
                        code: "Compact",
                        adm1code: "Compact",
                        countryId: "Compact",
                        jobsData: "Compact",
                        label: "Compact",
                        value: "Compact",
                      },
                      ...data.data,
                    ],
                    // selectedStates: [], stateLoading: false
                  });
                  const myArrayFiltered = data.data.filter((array) =>
                    this.state.selectedStates.some(
                      (filter) => filter.value === array.value
                    )
                  );
                  if (myArrayFiltered.length > 0) {
                    this.setState({ selectedStates: myArrayFiltered });
                  }
                }
              }
            });
            axios
              .post(
                AppConstants.Jobs_API + "/specialities/getSpecialitiesBasedOnCities",
                tablefilterdata
              )
              .then((res) => {
                return res.data;
              })
              .then((data) => {
                if (data.Status === "Success") {
                  if (data.data.length > 0) {
                    this.setState({
                      items: [
                        {
                          _id: "All",
                          speciality: "All",
                          jobsCount: "All",
                          label: "All",
                          value: "All",
                        },
                        ...data.data,
                      ],
                      specialitiesDuplicate: [
                        {
                          _id: "All",
                          speciality: "All",
                          jobsCount: "All",
                          label: "All",
                          value: "All",
                        },
                        ...data.data,
                      ],
                      specialityCopy: [
                        {
                          _id: "All",
                          speciality: "All",
                          jobsCount: "All",
                          label: "All",
                          value: "All",
                        },
                        ...data.data,
                      ],
                      specialityLoading: false,
                    });
                    const myArrayFiltered = data.data.filter((array) =>
                      this.state.selectedSpecialities.some(
                        (filter) => filter.value === array.value
                      )
                    );
                    if (myArrayFiltered.length > 0) {
                      this.setState({ selectedSpecialities: myArrayFiltered });
                    }
                  }
                }
              });
            } else if (selectedList.length === 0) {
              if (this.state.state.length >= 1 || this.state.value.length >= 1) {
                var val = [];
                this.state.state.map((item) => {
                  val.push(item.code);
                });
                let tablefilterdata = {
                  states: val,
                };
                axios
                  .post(
                    AppConstants.Jobs_API +
                      "/specialities/getSpecialitiesBasedOnStates",
                    tablefilterdata
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .then((data) => {
                    if (data.Status === "Success") {
                      if (data.data.length > 0) {
                        this.setState({
                          items: [
                            {
                              _id: "All",
                              speciality: "All",
                              jobsCount: "All",
                              label: "All",
                              value: "All",
                            },
                            ...data.data,
                          ],
                          specialitiesDuplicate: [
                            {
                              _id: "All",
                              speciality: "All",
                              jobsCount: "All",
                              label: "All",
                              value: "All",
                            },
                            ...data.data,
                          ],
                          specialityCopy: [
                            {
                              _id: "All",
                              speciality: "All",
                              jobsCount: "All",
                              label: "All",
                              value: "All",
                            },
                            ...data.data,
                          ],
                          specialityLoading: false,
                        });
                        const myArrayFiltered = data.data.filter((array) =>
                          this.state.selectedSpecialities.some(
                            (filter) => filter.value === array.value
                          )
                        );
                        if (myArrayFiltered.length > 0) {
                          this.setState({ selectedSpecialities: myArrayFiltered });
                        }
                      }
                    }
                  });
                  var stateVal = [];
                  this.state.selectedSpecialities.map((item) => {
                    stateVal.push(item.speciality);
                  });
                  let tablefilterdataState = {
                    specialities: stateVal,
                  };
                  axios
                  .post(
                    AppConstants.Jobs_API + "/jobs/getStatesBasedOnSpecialties",
                    tablefilterdataState
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .then((data) => {
                    if (data.Status === "Success") {
                      if (data.data.length > 0) {
                        this.setState({
                          states: [
                            {
                              _id: "All",
                              id: "All",
                              name: "All",
                              nameWithCount: "All",
                              code: "All",
                              adm1code: "All",
                              countryId: "All",
                              jobsData: "All",
                              label: "All",
                              value: "All",
                            },
                            {
                              _id: "Compact",
                              id: "Compact",
                              name: "Compact",
                              nameWithCount: "Compact",
                              code: "Compact",
                              adm1code: "Compact",
                              countryId: "Compact",
                              jobsData: "Compact",
                              label: "Compact",
                              value: "Compact",
                            },
                            ...data.data,
                          ],
                          statesDuplicate: [
                            {
                              _id: "All",
                              id: "All",
                              name: "All",
                              nameWithCount: "All",
                              code: "All",
                              adm1code: "All",
                              countryId: "All",
                              jobsData: "All",
                              label: "All",
                              value: "All",
                            },
                            {
                              _id: "Compact",
                              id: "Compact",
                              name: "Compact",
                              nameWithCount: "Compact",
                              code: "Compact",
                              adm1code: "Compact",
                              countryId: "Compact",
                              jobsData: "Compact",
                              label: "Compact",
                              value: "Compact",
                            },
                            ...data.data,
                          ],
                          stateCopy: [
                            {
                              _id: "All",
                              id: "All",
                              name: "All",
                              nameWithCount: "All",
                              code: "All",
                              adm1code: "All",
                              countryId: "All",
                              jobsData: "All",
                              label: "All",
                              value: "All",
                            },
                            {
                              _id: "Compact",
                              id: "Compact",
                              name: "Compact",
                              nameWithCount: "Compact",
                              code: "Compact",
                              adm1code: "Compact",
                              countryId: "Compact",
                              jobsData: "Compact",
                              label: "Compact",
                              value: "Compact",
                            },
                            ...data.data,
                          ],
                        });
                        const myArrayFiltered = data.data.filter((array) =>
                          this.state.selectedStates.some(
                            (filter) => filter.value === array.value
                          )
                        );
                        if (myArrayFiltered.length > 0) {
                          this.setState({ selectedStates: myArrayFiltered });
                        }
                      }
                    }
                  });
                } else if (this.state.value.length > 0 || this.state.state.length === 0) {
                  this.setState({
                    specialitiesDuplicate: this.state.specialityOriginal,
                    specialityCopy: this.state.specialityOriginal,
                    items:this.state.specialityOriginal
                  })
                  const myArrayFiltered = this.state.specialityOriginal.filter((array) =>
                    this.state.selectedSpecialities.some(
                      (filter) => filter.value === array.value
                    )
                  );
                  if (myArrayFiltered.length > 0) {
                    this.setState({ selectedSpecialities: myArrayFiltered });
                  }
                } else if (this.state.value.length === 0 && this.state.state.length > 0) {
                  this.setState({
                    statesDuplicate: this.state.statesOriginal,
                    stateCopy: this.state.statesOriginal,
                    states:this.state.statesOriginal
                  })
                  const myArrayFiltered = this.state.statesOriginal.filter((array) =>
                    this.state.selectedStates.some(
                      (filter) => filter.value === array.value
                    )
                  );
                  if (myArrayFiltered.length > 0) {
                    this.setState({ selectedStates: myArrayFiltered });
                  }
                }
            }
  }
  handleStateFocus() {
    this.setState({
      states: this.state.statesOriginal,
      stateCopy: this.state.statesOriginal,
      statesDuplicate: this.state.statesOriginal,
    });
    // if (
    //   this.state.selectedSpecialities.length === 0 &&
    //   this.state.selectedCerts.length === 0 &&
    //   this.state.selectedStates.length === 0
    // ) {
    //   this.setState({
    //     states: this.state.statesOriginal,
    //     stateCopy: this.state.statesOriginal,
    //     statesDuplicate: this.state.statesOriginal,
    //   });
    // } else if (
    //   this.state.selectedSpecialities.length === 0 &&
    //   this.state.selectedCerts.length === 0 &&
    //   this.state.selectedStates.length !== 0
    // ) {
    //   this.setState({
    //     states: this.state.states,
    //     stateCopy: this.state.states,
    //     statesDuplicate: this.state.states,
    //   });
    // }
  }
  handleCityFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedCities.length === 0
    ) {
      this.setState({
        cities: this.state.citiesOriginal,
        citiesCopy: this.state.citiesOriginal,
        citiesDuplicate: this.state.citiesOriginal,
      });
    } else if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedCities.length !== 0
    ) {
      this.setState({
        cities: this.state.cities,
        citiesCopy: this.state.cities,
        citiesDuplicate: this.state.cities,
      });
    }
  }
  handleSpecialityFocus() {
    if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedSpecialities.length === 0
    ) {
      this.setState({
        items: this.state.specialityOriginal,
        specialitiesDuplicate: this.state.specialityOriginal,
        specialityCopy: this.state.specialityOriginal,
      });
    } else if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedStates.length === 0 &&
      this.state.selectedSpecialities.length !== 0
    ) {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  handleCityPop(e) {
    this.setState({ showLoc: false });
  }
  handleStatePop(e) {
    this.setState({ showLoc: true });
  }
  handleAutoloadCity(e) {
    this.setState({ value1: e.target.value, city1: e.target.value });
    if (e.target.value.length < 3) {
      this.setState({ cities: [] });
    } else {
      let searchfilterdata = {
        searchtext: e.target.value,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getAllCitiesWithAutoLoadAndJobCount",
          searchfilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({
              cities: [
                {
                  _id: "All",
                  name: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
  }
  handleAutoLoadSelect (val) {
    if(val) {
      this.setState({value1: val})
      this.certFunction(this.state.selectedCerts, this.state.selectedStates, val, this.state.searchRadius );
      this.specialityFunction(this.state.selectedSpecialities, this.state.selectedStates, val, this.state.searchRadius);
    }
  }
  render() {
    const menuStyle = {
      borderRadius: "5px",
      boxShadow: "0px 20px 50px 10px rgba(9, 36, 157, 0.1)",
      background: "#fff",
      padding: "2px 0",
      fontSize: "90%",
      position: "fixed",
      overflow: "auto",
      maxHeight: "300px",
      top: "inherit",
      minWidth: "auto",
      margin: "5px 0 0 10px",
      zIndex: "99",

      // TODO: don't cheat, let it flow to the bottom
    };

    
    return (
      <div className="destination-block">
        <Container>
          <div className="search-block pic-destiny">
            <div className="title">{AppConstants.pick_dest}</div>

            <form className="form-block mb-4">
              <Container>
                <Row className="justify-content-center">
                  <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                    <div
                      className="block_new"
                      onClick={() => {
                        this.toggleOpenCertification();
                      }}
                    >
                      <label>Certification</label>
                      <ReactMultiSelectCheckboxes
                        options={this.state.certifications}
                        value={this.state.selectedCerts}
                        onChange={(value, event) => {
                          this.handleCertChange(value, event);
                        }}
                        isSearchable={true}
                        // placeholderButtonLabel="Certification"
                        placeholderButtonLabel="All"
                        onFocus={this.handleCertFocus.bind(this)}
                        menuIsOpen={
                          this.state.dropdownStatus === true
                            ? ""
                            : this.state.certDropdown
                        }
                        onBlur={this.handleCertBlur.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                    <div
                      className="block_new"
                      onClick={() => {
                        this.toggleOpenSpeciality();
                      }}
                    >
                      <label>My Specialty is...</label>
                      <ReactMultiSelectCheckboxes
                        options={this.state.items}
                        value={this.state.selectedSpecialities}
                        onChange={(value, event) => {
                          this.handleChange(value, event);
                        }}
                        // onInputChange={(value) => {
                        //   this.valueChange(value);
                        // }}
                        isSearchable={true}
                        // placeholderButtonLabel="Specialty"
                        placeholderButtonLabel="All"
                        onFocus={this.handleSpecialityFocus.bind(this)}
                        // menuIsOpen = {this.state.specialityDropdown}
                        menuIsOpen={
                          this.state.dropdownStatus === true
                            ? ""
                            : this.state.specialityDropdown
                        }
                        onBlur={this.handleSpecialityBlur.bind(this)}
                      />
                    </div>
                  </div>
                  <div
                className="col-lg-3 col-md-4 col-sm-12 pt-3 pt-md-0 pt-lg-0"
                id="location-filter"
              >
                <label>Location</label>
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenCertification();
                  }}
                ></div>
                <Dropdown autoClose={false}>
                  <Dropdown.Toggle className="css-1r4vtzz all-bttn-filter">
                    {/* <b className="city">All</b> */}
                <b className="city">{this.state.showLoc ? this.state.selectedStates.length > 0 ? this.state.selectedStates.map((item,index) => {return (<span> {this.state.selectedStates.length-1 === index ? item.code: item.code+", "}</span>)} ): "All": this.state.city1 ? this.state.city1+` - ${this.state.searchRadius}m` : "All" }</b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Card>
                      <Card.Body>
                        <Button
                          variant="light"
                          onClick={this.handleCityPop.bind(this)}
                          className={!this.state.showLoc ? "location-bttn active" : "location-bttn"}
                        >
                          City
                        </Button>
                        <Button
                          variant="light"
                          onClick={this.handleStatePop.bind(this)}
                          className={this.state.showLoc ? "location-bttn active" : "location-bttn"}
                        >
                          State
                        </Button>
                        {this.state.showLoc ? (
                          <div
                            className="finder"
                            onClick={() => {
                              this.toggleOpenState();
                            }}
                          >
                            <ReactMultiSelectCheckboxes
                              options={this.state.states}
                              value={this.state.selectedStates}
                              onChange={(value, event) => {
                                this.handleStateChange(value, event);
                              }}
                              isSearchable={true}
                              // placeholderButtonLabel="State"
                              placeholderButtonLabel="All"
                              onFocus={this.handleStateFocus.bind(this)}
                              menuIsOpen={
                                this.state.dropdownStatus === true
                                  ? ""
                                  : this.state.stateDropdown
                              }
                              onBlur={this.handleStateBlur.bind(this)}
                            />
                          </div>
                        ) : (
                          <span>
                            <div
                              className={
                                this.state.cities.length > 0
                                  ? "finder city-finder"
                                  : ""
                              }
                              onClick={() => {
                                // this.toggleOpenCity();
                              }}
                            >
                              <ReactAutocomplete
                                className="test"
                                items={[...this.state.cities]}
                                shouldItemRender={(item, value1) =>
                                  item.label
                                    .toLowerCase()
                                    .indexOf(value1.toLowerCase()) > -1
                                }
                                getItemValue={(item) => item.value}
                                renderItem={(item, highlighted) => (
                                  <div
                                    className="list"
                                    key={item.id}
                                    style={{
                                      backgroundColor: highlighted
                                        ? "#eee"
                                        : "transparent",
                                    }}
                                  >
                                    {item.label}
                                  </div>
                                )}
                                inputProps={{ placeholder: "City" }}
                                value={this.state.value1}
                                onChange={this.handleAutoloadCity.bind(this)}
                                onSelect = {this.handleAutoLoadSelect.bind(this)}
                              />
                            </div>
                            <br />
                            <div className="city-miles">
                              <Input
                                type="select"
                                value={this.state.searchRadius}
                                name="searchRadius"
                                onChange={this.handlesearchRadius}
                                className={
                                  this.state.city === ""
                                    ? "form-filed radius disabled"
                                    : "form-filed radius test"
                                }
                                disabled={
                                  this.state.city === "" &&
                                  this.state.state === ""
                                    ? true
                                    : false
                                }
                              >
                                <option value="20" className="optionname">
                                  Within 20 miles
                                </option>
                                <option value="50" className="optionname">
                                  Within 50 miles
                                </option>
                                <option value="100" className="optionname">
                                  Within 100 miles
                                </option>
                                <option value="250" className="optionname">
                                  Within 250 miles
                                </option>
                              </Input>
                            </div>
                          </span>
                        )}
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
                  <div className="col-lg col-md-3 pt-4" id="search-bttn">
                    <div className="search-field  block_new TN-search_reset w-100">
                      <button
                        style={{ width: "100%" }}
                        type="submit"
                        className={
                          this.state.certification.length > 0 ||
                          this.state.city1 ||
                          this.state.state.length > 0 ||
                          this.state.value.length > 0
                            ? "button"
                            : "button disabled"
                        }
                        onClick={this.handleSubmit.bind(this)}
                        disabled={
                          this.state.certification.length > 0 ||
                          this.state.city1 ||
                          this.state.state.length > 0 ||
                          this.state.value.length > 0
                            ? false
                            : true
                        }
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-lg col-md-3 pt-4">
                    <div className="reset-field1 block_new TN-search_reset">
                      <button
                        className={
                          this.state.certification.length > 0 ||
                          this.state.city1 ||
                          this.state.state.length > 0 ||
                          this.state.value.length > 0
                            ? "button reset-btn1 w-100"
                            : "button reset-btn1 disabled w-100"
                        }
                        onClick={this.handleReset.bind(this)}
                        disabled={
                          this.state.certification.length > 0 ||
                          this.state.city1 ||
                          this.state.state.length > 0 ||
                          this.state.value.length > 0
                            ? false
                            : true
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </Row>
              </Container>
            </form>
          </div>
        </Container>
        <div class="slideshow">
          <div class="slideshow-image"></div>
          <div class="slideshow-image"></div>
          <div class="slideshow-image"></div>
          <div class="slideshow-image"></div>
        </div>
      </div>
    );
  }
}

export default Destination;
