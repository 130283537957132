import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Row } from "reactstrap";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { connect } from "react-redux";
import axios from "axios";
import AppConstants from "../AppConstants";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Cookies from "js-cookie";
import { logoutUser } from "../actions/authentication";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";

class DeleteAccount extends Component {
  constructor(props, context) {
    super(props, context);
    console.log("props", this.props);
    this.state = {
      modal: false,
      user: {},
    };
  }
  async componentDidMount() {
    const token = localStorage.getItem("token");
    var userId = this.props.location.pathname.split("/")[2];
    console.log("userId", userId);
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then((response) => {
        this.setState({ user: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const handleDelButton = (e) => {
      e.preventDefault();
      swal({
        title: "Are you sure?",
        text: "Do you want to delete your account?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const token = localStorage.getItem("token");
          if (token) {
            e.preventDefault();
            this.setState({ modal: false });
            var config = {
              method: "post",
              url: AppConstants.API + "/nurse/deleteNurse/",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            };
            this.props.logoutUser(this.props.history);
            await axios(config)
              .then(function (response) {
                console.log("res", response.status);
                if (response.status == "200") {
                  console.log("insertin");

                  Cookies.remove("user", {
                    domain: ".travelnursesinc.com",
                    path: "/",
                  });
                  NotificationManager.success(response.data.msg, "", 3000);
                } else {
                  NotificationManager.error(response.data.msg, "", 3000);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            this.setState({ modal: !this.state.modal });
          }
        }
      });
    };

    return (
      <>
        <div className="login-page delete-profile-grid main-page p-tb-50 bg-grey">
          <Container>
            <div className="login-grid">
              <div className="inner-block">
                <div className="preview-bttn">
                  <button
                    class="button back-btn float-none"
                    onClick={() => this.props.history.push("/view-profile/")}
                  >
                    <FaAngleDoubleLeft />
                    <span>Back To Profile</span>
                  </button>
                </div>
                <Row>
                  <div className="delete-profile">
                    <div className="delete-profile-in">
                      <div class="title">Delete Profile ?</div>
                      <p>Deleting your profile will remove all your data.</p>
                      <figure>
                        <img src={`${AppConstants.Bucket_URL}/bannerImages/profile-thumb.jpg`} alt="" />
                      </figure>
                      <h3>
                        {this.state.user.firstName} {this.state.user.lastName}{" "}
                      </h3>
                      <p>
                        <span className="phone">
                          {this.state.user &&
                            this.state.user.contactInformation &&
                            this.state.user.contactInformation
                              .primaryPhoneNumber}
                        </span>
                      </p>
                      <p>
                        <span className="mail">
                          {" "}
                          {this.state.user &&
                            this.state.user.contactInformation &&
                            this.state.user.contactInformation.primaryEmail}
                        </span>
                      </p>
                      <div class="button-sec">
                        <button class="blue-bttn" onClick={handleDelButton} style={{ fontFamily: "Figtree Black" }}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userInfo } = auth;
  return { loading, error, userInfo };
};

export default connect(mapStateToProps, {
  logoutUser,
})(withRouter(DeleteAccount));
