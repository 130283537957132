import React, { Component } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import AppConstants from "../AppConstants";
import { Multiselect } from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import onClickOutside from "react-onclickoutside";
import { Dropdown, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import TN_welcome from "../images/start-your-next-adventure.png";
class SearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      value: [],
      items: [],
      states: [],
      currentPage: 1,
      selectedPageSize: 10,
      selectedOrderOption: "",
      searchText: "",
      speciality: "",
      location: "",
      cities: [],
      city: [],
      state: [],
      citiesCopy: [],
      searchRadius: "20",
      stateLoading: true,
      cityLoading: true,
      specialityLoading: true,
      options: [],
      selectedSpecialities: [],
      selectedStates: [],
      selectedCities: [],
      stateCopy: [],
      cityDropdown: "",
      specialityCopy: [],
      citiesDuplicate: [],
      statesDuplicate: [],
      specialitiesDuplicate: [],
      citiesOriginal: [],
      statesOriginal: [],
      specialityOriginal: [],
      stateDropdown: "",
      specialityDropdown: "",
      dropdownStatus: false,
      items1: [],
      states1: [],
      cities1: [],
      items2: [],
      states2: [],
      cities2: [],
      showLoc: false,
      certifications: [],
      certification: [],
      selectedCerts: [],
      certDropdown: "",
      value1: "",
      isOpen: true,
      citiesAuto: [],
      city1: "",
      certsDuplicate: [],
      certsOriginal: [],
      certsCopy: [],
    };
    this.handlesearchRadius = this.handlesearchRadius.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.toggleOpenCity = this.toggleOpenCity.bind(this);
    this.toggleClosedCity = this.toggleClosedCity.bind(this);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.toggleClosedState = this.toggleClosedState.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.toggleClosedSpeciality = this.toggleClosedSpeciality.bind(this);
    this.handleCertChange = this.handleCertChange.bind(this);
    this.toggleClosedCert = this.toggleClosedCert.bind(this);
  }
  toggleOpenState() {
    this.setState({ stateDropdown: true });
  }
  handleStateBlur() {
    this.toggleClosedState();
  }
  toggleClosedState() {
    this.setState({ stateDropdown: false });
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenCity() {
    if (this.state.selectedStates.length > 1) {
      this.setState({ cityDropdown: false });
    } else {
      this.setState({ cityDropdown: true });
    }
  }
  handleCityBlur() {
    this.toggleClosedCity();
  }
  toggleClosedCity() {
    this.setState({ cityDropdown: false });
  }
  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }
  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }
  handleCertChange(selectedList, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.certifications.length - 1) {
      selectedList = this.state.certifications;
    } else {
      selectedList = selectedList;
    }
    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    this.state.certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    // this.certFunction(selectedList, this.state.selectedStates, this.state.value1, this.state.searchRadius );
    this.certFunction(selectedList);
    this.setState({
      certifications: result,
      certification: selectedList,
      selectedCerts: selectedList,
      certsDuplicate: result,
    });
  }
  // certFunction(selectedList, states, city, radius) {
  certFunction(selectedList) {
    var certVal = [];
    selectedList.map((item) => {
      certVal.push(item.value);
    });
    // var stateVal = [];
    // states.map((item) => {
    //   stateVal.push(item.code);
    // })
    // var cityName = "";
    // if (city) {
    //   cityName = city.split(',');
    // }
    let tablefilterdata = {
      certs: certVal,
      // states: this.state.showLoc ? stateVal : [],
      // city: this.state.showLoc ? "" : cityName[0],
      // searchRadius: this.state.showLoc ? "" : radius
    };
    // if (selectedList.length === 0 && states.length === 0 && city === "") {
    if (selectedList.length === 0) {
      this.setState({ items: this.state.specialityOriginal });
    } else {
      axios
        .post(
          AppConstants.Jobs_API +
            "/specialities/getSpecsWithJobsCountBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                items: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityCopy: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedSpecialities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedSpecialities: myArrayFiltered });
              } else {
                this.setState({ selectedSpecialities: [] });
              }
            } else {
              this.setState({ selectedSpecialities: [] });
            }
          }
        });
    }
  }
  handleCertFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      // this.state.selectedStates.length === 0 &&
      this.state.selectedCerts.length === 0
    ) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    } else if (
      this.state.selectedSpecialities.length === 0 &&
      // this.state.selectedStates.length === 0 &&
      this.state.selectedCerts.length !== 0
    ) {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }
  handlesearchRadius(e) {
    this.setState({ [e.target.name]: e.target.value });
    // this.certFunction(this.state.selectedCerts, this.state.selectedStates, this.state.value1, e.target.value );
    // this.specialityFunction(this.state.selectedSpecialities, this.state.selectedStates, this.state.value1, e.target.value);
  }
  componentDidMount() {
    if (
      (this.props,
      window.innerWidth >= 320 && this.props,
      window.innerWidth <= 767)
    ) {
      this.setState({ dropdownStatus: true });
    }
    axios.get(AppConstants.Jobs_API + "/jobs/getAllStates").then((res) => {
      if (res.data.Status === "Success") {
        this.setState({
          statesOriginal: [
            {
              _id: "All",
              code: "All",
              label: "All",
              value: "All",
            },
            {
              _id: "Compact",
              code: "Compact",
              label: "Compact",
              value: "Compact",
            },
            ...res.data.data,
          ],
          stateLoading: false,
        });
      }
    });
    axios
      // get(AppConstants.Jobs_API + "/specialities/getSpecialitiesWithJobsCount")
      .get(AppConstants.Jobs_API + "/specialities/getAllSpecialities")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({
            specialityOriginal: [
              {
                _id: "All",
                speciality: "All",
                jobsCount: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ],
          });
        }
      });
    axios
      // get(AppConstants.Jobs_API + "/certification/getCertificationsWithJobsCount")
      .get(AppConstants.Jobs_API + "/certification/getAllCertifications")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({
            certsOriginal: [
              {
                _id: "All",
                certification: "All",
                jobsCount: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ],
          });
        }
      });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      cityDropdown: false,
      stateDropdown: false,
      specialityDropdown: false,
    });
    var cbData = {
      speciality: this.state.selectedSpecialities,
      specialities: this.state.items,
      state: this.state.selectedStates,
      states: this.state.states,
      searchRadius: this.state.searchRadius,
      certification: this.state.selectedCerts,
      city: !this.state.showLoc ? this.state.value1 : "",
      certifications: this.state.certifications,
      showLoc: this.state.showLoc,
      jobDetailPath:
        this.props.searchHistory.location.pathname === "/" ? "" : "/job/:id",
      showLink: true,
    };
    this.props.searchHistory.history.push({
      // pathname: "/jobs",
      pathname: "/",
      state: cbData,
    });
    // localStorage.setItem("theme", JSON.stringify(cbData));
  }
  handleReset = (e) => {
    e.preventDefault();
    this.setState({
      city: [],
      state: [],
      value: [],
      searchRadius: "20",
      cities: this.state.citiesOriginal,
      states: this.state.statesOriginal,
      items: this.state.specialityOriginal,
      selectedSpecialities: [],
      selectedStates: [],
      selectedCities: [],
      cityDropdown: false,
      stateDropdown: false,
      specialityDropdown: false,
      certDropdown: false,
      certifications: this.state.certsOriginal,
      selectedCerts: [],
      showLoc: false,
      city1: "",
      value1: "",
      certification: [],
    });
    localStorage.removeItem("theme");
  };
  handleChange(value, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === this.state.items.length - 1) {
      value = this.state.items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      items: result,
      value: value,
      selectedSpecialities: value,
      specialitiesDuplicate: result,
    });
    // this.specialityFunction(value, this.state.selectedStates,this.state.value1, this.state.searchRadius);
    this.specialityFunction(value);
  }

  // specialityFunction(selectedList, states, city, radius) {
  specialityFunction(selectedList) {
    var specVal = [];
    selectedList.map((item) => {
      specVal.push(item.value);
    });
    // var stateVal = [];
    // states.map((item) => {
    //   stateVal.push(item.code);
    // })
    // var cityName = "";
    // if (city) {
    //   cityName = city.split(',');
    // }
    let tablefilterdata = {
      specialities: specVal,
      // states: this.state.showLoc ? stateVal : [],
      // city: this.state.showLoc ? "" : cityName[0],
      // searchRadius: this.state.showLoc ? "" : radius
    };
    // if (selectedList.length === 0 && states.length == 0 && city == "") {
    if (selectedList.length === 0) {
      this.setState({ certifications: this.state.certsOriginal });
    } else {
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getCertsBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                certifications: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                certsCopy: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCerts.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCerts: myArrayFiltered });
              } else {
                this.setState({ selectedCerts: [] });
              }
            } else {
              this.setState({ selectedCerts: [] });
            }
          }
        });
    }
  }
  handleStateChange(selectedList, event) {
    if (event.action === "select-option") {
      if (event.option.value === "All") {
        selectedList = selectedList.filter((o) => o.value === "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      } else if (event.option.value === "Compact") {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value === "Compact");
      } else if (
        event.option.value !== "All" ||
        event.option.value !== "Compact"
      ) {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      }
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.states.length - 1) {
      selectedList = this.state.states;
    } else {
      selectedList = selectedList;
    }
    this.setState({ state: selectedList });
    if (selectedList.length > 1) {
      this.setState({ cityDropdown: false, selectedCities: [] });
    } else {
      // this.setState({cityDropdown : ""})
    }
    // this.certFunction(this.state.selectedCerts, selectedList, this.state.value1, this.state.searchRadius)
    // this.specialityFunction(this.state.selectedSpecialities, selectedList, this.state.value1, this.state.searchRadius);
    let stateSel = [];
    selectedList.map((item, index) => {
      stateSel.push(item);
    });
    this.state.stateCopy.map((item, index) => {
      if (stateSel.includes(item)) {
      } else {
        stateSel.push(item);
      }
    });
    var result = stateSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      states: result,
      state: selectedList,
      selectedStates: selectedList,
      statesDuplicate: result,
    });
  }

  handleStateFocus() {
    this.setState({
      states: this.state.statesOriginal,
      stateCopy: this.state.statesOriginal,
      statesDuplicate: this.state.statesOriginal,
    });
  }
  handleSpecialityFocus() {
    if (
      this.state.selectedCerts.length === 0 &&
      // this.state.selectedStates.length === 0 &&
      this.state.selectedSpecialities.length === 0
    ) {
      this.setState({
        items: this.state.specialityOriginal,
        specialitiesDuplicate: this.state.specialityOriginal,
        specialityCopy: this.state.specialityOriginal,
      });
    } else if (
      this.state.selectedCerts.length === 0 &&
      // this.state.selectedStates.length === 0 &&
      this.state.selectedSpecialities.length !== 0
    ) {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  handleClickOutside() {
    this.setState({
      specialityDropdown: false,
      cityDropdown: false,
      stateDropdown: false,
    });
  }
  handleCityPop(e) {
    this.setState({ showLoc: false });
  }
  handleStatePop(e) {
    this.setState({ showLoc: true });
  }
  handleAutoloadCity(e) {
    this.setState({ value1: e.target.value, city1: e.target.value });
    if (e.target.value.length < 3) {
      this.setState({ cities: [] });
    } else {
      let searchfilterdata = {
        searchtext: e.target.value,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getAllCitiesWithAutoLoadAndJobCount",
          searchfilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({
              cities: [
                {
                  _id: "All",
                  name: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
  }
  handleAutoLoadSelect(val) {
    if (val) {
      this.setState({ value1: val });
      // this.certFunction(this.state.selectedCerts, this.state.selectedStates, val, this.state.searchRadius );
      // this.specialityFunction(this.state.selectedSpecialities, this.state.selectedStates, val, this.state.searchRadius);
    }
  }
  render() {
    const menuStyle = {
      borderRadius: "5px",
      boxShadow: "0px 20px 50px 10px rgba(9, 36, 157, 0.1)",
      background: "#fff",
      padding: "2px 0",
      fontSize: "90%",
      position: "fixed",
      overflow: "auto",
      maxHeight: "300px",
      top: "inherit",
      minWidth: "auto",
      margin: "5px 0 0 10px",
      zIndex: "99",
    };
    return (
      <div className="search-block search-job-grid">
        <Container>
          {this.props.searchHistory.location.pathname === "/" ? (
            ""
          ) : (
            // <h1 className="pick-dest">{AppConstants.pick_dest}</h1>
            <Row>
              <Col className="col-md-6 offset-md-3 ">
                {/* <img
                  className="img-fluid mb-4"
                  // src={TN_welcome}
                  src={`${AppConstants.Bucket_URL}/start-your-next-adventure.png`}
                  alt="TN_welcome"
                /> */}
                <h1 style={{ textAlign: "center", color: "white" }}>
                  Start your next adventure!
                </h1>
              </Col>
            </Row>
          )}
          <form className="form-block mb-4 profile-search-filter">
            <Row className="justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenCertification();
                  }}
                >
                  <h5 className="pb-0 text-white">Certification</h5>
                  <ReactMultiSelectCheckboxes
                    options={this.state.certifications}
                    value={this.state.selectedCerts}
                    onChange={(value, event) => {
                      this.handleCertChange(value, event);
                    }}
                    isSearchable={true}
                    // placeholderButtonLabel="Certification"
                    placeholderButtonLabel="All"
                    onFocus={this.handleCertFocus.bind(this)}
                    menuIsOpen={
                      this.state.dropdownStatus === true
                        ? ""
                        : this.state.certDropdown
                    }
                    onBlur={this.handleCertBlur.bind(this)}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenSpeciality();
                  }}
                >
                  <h5 className="pb-0 text-white">My Specialty is...</h5>
                  <ReactMultiSelectCheckboxes
                    options={this.state.items}
                    value={this.state.selectedSpecialities}
                    onChange={(value, event) => {
                      this.handleChange(value, event);
                    }}
                    isSearchable={true}
                    placeholderButtonLabel="All"
                    onFocus={this.handleSpecialityFocus.bind(this)}
                    menuIsOpen={
                      this.state.dropdownStatus === true
                        ? ""
                        : this.state.specialityDropdown
                    }
                    onBlur={this.handleSpecialityBlur.bind(this)}
                  />
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-sm-12 pt-3 pt-md-0 pt-lg-0"
                id="location-filter"
              >
                <h5 className="pb-0 text-white">Location</h5>
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenCertification();
                  }}
                ></div>
                <Dropdown autoClose={false}>
                  <Dropdown.Toggle className="css-1r4vtzz all-bttn-filter">
                    <b className="city">
                      {this.state.showLoc
                        ? this.state.selectedStates.length > 0
                          ? this.state.selectedStates.map((item, index) => {
                              return (
                                <span>
                                  {" "}
                                  {this.state.selectedStates.length - 1 ===
                                  index
                                    ? item.code
                                    : item.code + ", "}
                                </span>
                              );
                            })
                          : "All"
                        : this.state.value1
                        ? this.state.value1 + ` - ${this.state.searchRadius}m`
                        : "All"}
                    </b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Card>
                      <Card.Body>
                        <Button
                          variant="light"
                          onClick={this.handleCityPop.bind(this)}
                          className={
                            !this.state.showLoc
                              ? "location-bttn active"
                              : "location-bttn"
                          }
                        >
                          City
                        </Button>
                        <Button
                          variant="light"
                          onClick={this.handleStatePop.bind(this)}
                          className={
                            this.state.showLoc
                              ? "location-bttn active"
                              : "location-bttn"
                          }
                        >
                          State
                        </Button>
                        {this.state.showLoc ? (
                          <div
                            className="finder"
                            onClick={() => {
                              this.toggleOpenState();
                            }}
                          >
                            <ReactMultiSelectCheckboxes
                              options={this.state.states}
                              value={this.state.selectedStates}
                              onChange={(value, event) => {
                                this.handleStateChange(value, event);
                              }}
                              isSearchable={true}
                              // placeholderButtonLabel="State"
                              placeholderButtonLabel="All"
                              onFocus={this.handleStateFocus.bind(this)}
                              menuIsOpen={
                                this.state.dropdownStatus === true
                                  ? ""
                                  : this.state.stateDropdown
                              }
                              onBlur={this.handleStateBlur.bind(this)}
                            />
                          </div>
                        ) : (
                          <span>
                            <div
                              className={
                                this.state.cities.length > 0
                                  ? "finder city-finder"
                                  : ""
                              }
                              onClick={() => {
                                // this.toggleOpenCity();
                              }}
                            >
                              <ReactAutocomplete
                                className="test"
                                items={[...this.state.cities]}
                                shouldItemRender={(item, value1) =>
                                  item.label
                                    .toLowerCase()
                                    .indexOf(value1.toLowerCase()) > -1
                                }
                                getItemValue={(item) => item.value}
                                renderItem={(item, highlighted) => (
                                  <div
                                    className="list"
                                    key={item.id}
                                    style={{
                                      backgroundColor: highlighted
                                        ? "#eee"
                                        : "transparent",
                                    }}
                                  >
                                    {item.label}
                                  </div>
                                )}
                                inputProps={{ placeholder: "City" }}
                                value={this.state.value1}
                                onChange={this.handleAutoloadCity.bind(this)}
                                onSelect={this.handleAutoLoadSelect.bind(this)}
                              />
                            </div>
                            <br />
                            <div className="city-miles">
                              <Input
                                type="select"
                                value={this.state.searchRadius}
                                name="searchRadius"
                                onChange={this.handlesearchRadius}
                                className={
                                  this.state.city === ""
                                    ? "form-filed radius disabled"
                                    : "form-filed radius test"
                                }
                                disabled={
                                  this.state.city === "" &&
                                  this.state.state === ""
                                    ? true
                                    : false
                                }
                              >
                                <ReactAutocomplete
                                  className="test"
                                  items={[...this.state.cities]}
                                  shouldItemRender={(item, value1) =>
                                    item.label
                                      .toLowerCase()
                                      .indexOf(value1.toLowerCase()) > -1
                                  }
                                  getItemValue={(item) => item.value}
                                  renderItem={(item, highlighted) => (
                                    <div
                                      className="list"
                                      key={item.id}
                                      style={{
                                        backgroundColor: highlighted
                                          ? "#eee"
                                          : "transparent",
                                      }}
                                    >
                                      {item.label}
                                    </div>
                                  )}
                                  inputProps={{ placeholder: "City" }}
                                  value={this.state.value1}
                                  onChange={this.handleAutoloadCity.bind(this)}
                                  onSelect={this.handleAutoLoadSelect.bind(
                                    this
                                  )}
                                />
                              </Input>
                            </div>
                            <br />
                            <div className="city-miles">
                              <Input
                                type="select"
                                value={this.state.searchRadius}
                                name="searchRadius"
                                onChange={this.handlesearchRadius}
                                className={
                                  this.state.city === ""
                                    ? "form-filed radius disabled"
                                    : "form-filed radius test"
                                }
                                disabled={
                                  this.state.city === "" &&
                                  this.state.state === ""
                                    ? true
                                    : false
                                }
                              >
                                <option value="20" className="optionname">
                                  Within 20 miles
                                </option>
                                <option value="50" className="optionname">
                                  Within 50 miles
                                </option>
                                <option value="100" className="optionname">
                                  Within 100 miles
                                </option>
                                <option value="250" className="optionname">
                                  Within 250 miles
                                </option>
                              </Input>
                            </div>
                          </span>
                        )}
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-lg col-md-3 pt-4" id="search-bttn">
                <div className="search-field  block_new TN-search_reset w-100">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className={
                      this.state.certification.length > 0 ||
                      this.state.city1 ||
                      this.state.state.length > 0 ||
                      this.state.value.length > 0
                        ? "button"
                        : "button disabled"
                    }
                    onClick={this.handleSubmit.bind(this)}
                    disabled={
                      this.state.certification.length > 0 ||
                      this.state.city1 ||
                      this.state.state.length > 0 ||
                      this.state.value.length > 0
                        ? false
                        : true
                    }
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-lg col-md-3 pt-4" id="search-bttn">
                <div className="reset-field1 block_new TN-search_reset">
                  <button
                    className={
                      this.state.certification.length > 0 ||
                      this.state.city1 ||
                      this.state.state.length > 0 ||
                      this.state.value.length > 0
                        ? "button reset-btn1 w-100"
                        : "button reset-btn1 disabled w-100"
                    }
                    onClick={this.handleReset.bind(this)}
                    disabled={
                      this.state.certification.length > 0 ||
                      this.state.city1 ||
                      this.state.state.length > 0 ||
                      this.state.value.length > 0
                        ? false
                        : true
                    }
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Row>
          </form>
        </Container>
      </div>
    );
  }
}

export default onClickOutside(SearchPage);
