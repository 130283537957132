import React from "react";

import { Container, Row, Col } from "reactstrap";
import styles from "../Styles/about.css";
import axios from "axios";
import AppConstants from "../AppConstants";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { JSONLD, Generic } from "react-structured-data";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
class About extends React.Component {
  constructor() {
    super();
    this.state = {
      about: [],
      content: "",
      image: "",
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      altImgName: "",
      block2title: "",
      block2Description: "",
      block3Title: "",
      block3SubTitle: "",
      block3Images: [],
      block2Images: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      // .get(AppConstants.CMS_API + "/about/TotalAboutContent")
      .get(AppConstants.CMS_API + "/about/allAboutfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success" && data.data[0]) {
          let about = [
            {
              imgName: "Texas Division",
              block2Image: require("../images/hou-division.jpg"),
              link: "",
            },
            {
              imgName: "Florida Division",
              block2Image: require("../images/fl-division.jpg"),
            },
            {
              imgName: "Memphis/Mid-South Division",
              block2Image: require("../images/mem-division.jpg"),
            },
            {
              imgName: "Travel Nurses, Inc",
              block2Image: require("../images/tni-division.jpg"),
            },
            {
              imgName: "Memphis/Mid-South Division",
              block2Image: require("../images/mem-division.jpg"),
            },

          ];
          this.setState({
            about: about,
            content: data.data[0].content,
            image: data.data[0].image1,
            metaTitle: data.data[0].metaTitle,
            metaDescription: data.data[0].metaDescription,
            metaTags: data.data[0].metaTags,
            ogTitle: data.data[0].ogTitle,
            ogDescription: data.data[0].ogDescription,
            altImgName: data.data[0].altImgName,
            block2title: data.data[0].block2title,
            block2Description: data.data[0].block2Description,
            block3Title: data.data[0].block3Title,
            block3SubTitle: data.data[0].block3SubTitle,
            block3Images: data.data[0].block3Images,
            block2Images: data.data[0].block2Images,
          });
        }
      });
  }

  render() {
    const settings = {
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const settings2 = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrow: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrow: false,
            dots: true,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrow: false,
            dots: true,
          },
        },
      ],
    };
    let AwardsCertify = [
      {
        awardsImg: require("../images/bluepipes-2017.png"),
      },
      {
        awardsImg: require("../images/bluepipes-2018.png"),
      },
      {
        awardsImg: require("../images/american-staffing-member.png"),
      },
      // {

      //   awardsImg: require("../images/natho-logo.jpg"),
      // },
      // {

      //   awardsImg: require("../images/sia-logo.jpg"),
      // },
      // {

      //   awardsImg: require("../images/aha-training-center.png"),
      // },
      // {

      //   awardsImg: require("../images/gold-seal.png"),
      // },
    ];
    return (
      <div className="about-block">
        <FadeIn>
          <JSONLD>
            <Generic
              type="webpage"
              jsonldtype="Webpage"
              schema={{
                name: this.state.metaTitle,
                description: this.state.metaDescription,
                contentReferenceTime: new Date(),
              }}
            ></Generic>
          </JSONLD>
          <Helmet>
            <title>{AppConstants.About}</title>
            {/* <title>Travel Nurses, Inc. | Top Rated Travel Nursing Agency</title> */}
            <meta name="title" content={this.state.metaTitle} />
            <meta name="description" content={this.state.metaDescription} />

            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              // content={AppConstants.Base_Url + "/about-us"}
              content={AppConstants.Word_Press_API + "/about"}
            />
            <meta property="og:title" content={this.state.ogTitle} />
            <meta
              property="og:description"
              content={this.state.ogDescription}
            />
            <meta property="og:image" content={this.state.image} />

            <meta property="twitter:card" content={this.state.image} />
            <meta
              property="twitter:url"
              // content={AppConstants.Base_Url + "/about-us"}
              content={AppConstants.Word_Press_API + "/about"}
            />
            <meta property="twitter:title" content={this.state.metaTitle} />
            <meta
              property="twitter:description"
              content={this.state.metaDescription}
            />
            <meta property="twitter:image" content={this.state.image} />
          </Helmet>
          <h1 className="title">{AppConstants.About}</h1>
          <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
          {this.state.block2title ? (
            <div className="about-travel">
              <Container>
                <Row>
                  <Col xs="3" className="block text-block">
                    <div className="content-box">
                      <h3>{this.state.block2title}</h3>
                      <p>{this.state.block2Description}</p>
                    </div>
                  </Col>
                  <Col xs="9">
                    <Slider {...settings}>
                      {this.state.block2Images.map((item, index) => (
                        <div className="block">
                          <div className="info-box mission">
                            <figure className="pic image-fill">
                              <img
                                src={item.block2Image}
                                alt={item.imgName + " - Travel Nurses Inc"}
                              />
                            </figure>
                            <div className="text">
                              <h6 className="show-text">{item.imgName}</h6>
                              {item.imgName === "Travel Nurses, Inc" ? (
                                <a
                                  href={item.imgLink}
                                  className="hide-text"
                                  target="blank"
                                >
                                  Visit Our Sister Site
                                </a>
                              ) : (
                                <Link
                                  to={{
                                    pathname: "/div-contact",
                                    state: {
                                      imgLink: item.imgLink,
                                      imgName: item.imgName,
                                    },
                                  }}
                                  className="hide-text"
                                >
                                  {" "}
                                  Contact Us
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            ""
          )}
          <div className="awards-certifications pad-tb ">
            <Container>
              {this.state.block3Title ? (
                <h2 className="title">{this.state.block3Title}</h2>
              ) : (
                ""
              )}
              {this.state.block3SubTitle ? (
                <p className="text-center ">{this.state.block3SubTitle}</p>
              ) : (
                ""
              )}
              {this.state.block3Images.length > 0 ? (
                <div className="inner-content">
                  <Slider {...settings2}>
                    {this.state.block3Images.map((item1, index) => (
                      <item id={index}>
                        {console.log(index, "{console.log(index)}")}
                        {item1.block3Image || item1.imgLink ? (
                          <a
                            href={item1.imgLink}
                            className="pic image-auto"
                            target="_blank"
                          >
                            {item1.block3Image ? (
                              <img
                                src={item1.block3Image}
                                alt={
                                  "certifications - " +
                                  index +
                                  " - Travel Nurses Inc"
                                }
                              />
                            ) : (
                              ""
                            )}
                          </a>
                        ) : (
                          ""
                        )}
                      </item>
                    ))}
                  </Slider>
                </div>
              ) : (
                ""
              )}
            </Container>
          </div>
          {/* <Subscribeblock /> */}
        </FadeIn>
        {/* <Destination destinationHistory={this.props} /> */}
      </div>
    );
  }
}

export default About;
