import React from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import CKEditor from "react-ckeditor-component";
 
import { NotificationManager } from "react-notifications";
class CreateStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      nurseId: "",
      title: "",
      storyId: "",
      content: "",
      public: false,
      contentError: false,
      titleError: false,
      imageEirror: false,
      story_mage: "",
      userId: "",
      isLoading: false,
      isDraftLoading: false,
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDraftSubmit = this.handleDraftSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handlePublic = this.handlePublic.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.setState({
      userId: localStorage.getItem("id"),
      nurseId: localStorage.getItem("id"),
    });
  }

  handleCancel() {
    this.props.history.push("/profile/" + this.state.userId);
  }
  handlePublic() {
    this.setState({ public: !this.state.public });
  }
  handleFile(e) {
    if (e.target.files[0]) {
      this.setState({
        [e.target.name]: e.target.files[0],
        story_image: URL.createObjectURL(e.target.files[0]),
        profile_imageError: false,
      });
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
    } else {
      this.setState({ story_image: "", imageError1: false, errorImage: "" });
    }
  }

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: evt.editor.getData(),
    });
    if (newContent) {
      this.setState({ contentError: false });
    } else {
      this.setState({ contentError: true });
    }
  }
  onChangeTitle(evt) {
    var newContent1 = evt.target.value;
    this.setState({
      title: evt.target.value,
    });
    if (newContent1) {
      this.setState({ titleError: false });
    } else {
      this.setState({ titleError: true });
    }
  }
  handleVallidation() {
    let errors = {};
    let formIsValid = true;

    if (this.state.image && !this.state.image.name.match(/.(jpg|jpeg|png)$/i)) {
      formIsValid = false;
      errors["image"] = "Please upload image in jpg|jpeg|png format.";
      ReactDOM.findDOMNode(this.refs.image).focus();
    }
    if (!this.state.content) {
      formIsValid = false;
      errors["content"] = "Please add story description.";
      ReactDOM.findDOMNode(this.refs.content).focus();
    }
    if (!this.state.title) {
      formIsValid = false;
      errors["title"] = "Please add story title.";
      ReactDOM.findDOMNode(this.refs.title).focus();
    }
    this.setState({
      errors: errors,
      imageError: true,
      contentError: true,
      titleError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    if (this.handleVallidation()) {
      this.setState({ isLoading: true });
      let formData = new FormData();
      formData.append("userId", this.state.userId);
      formData.append("nurseId", this.state.nurseId);
      var nurseStory = {};
      nurseStory["title"] = this.state.title;
      nurseStory["description"] = this.state.content;
      nurseStory["public"] = this.state.public;
      nurseStory["storyStatus"] = this.state.public ? "Pending" : "Private";
      nurseStory["isStory"] = true;
      formData.append("image", this.state.image);
      formData.append("nurseStory", JSON.stringify(nurseStory));

      axios
        .post(
          AppConstants.API + "/nurseStory/addNurseStory",
          formData,
          axiosConfig
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ isLoading: false });
            NotificationManager.success(res.data.msg, "", 5000);
            this.props.history.push("/profile/" + this.state.userId);
          } else {
            this.setState({ isLoading: false });
            NotificationManager.error(res.data.msg, "", 5000);
          }
        });
    }
  }
  handleDraftSubmit() {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    if (this.handleVallidation()) {
      this.setState({ isDraftLoading: true });
      let formData = new FormData();
      formData.append("userId", this.state.userId);
      formData.append("nurseId", this.state.nurseId);
      var nurseStory = {};
      nurseStory["title"] = this.state.title;
      nurseStory["description"] = this.state.content;
      nurseStory["public"] = this.state.public;
      nurseStory["storyStatus"] = "Draft";
      nurseStory["isStory"] = false;
      formData.append("image", this.state.image);
      formData.append("nurseStory", JSON.stringify(nurseStory));

      axios
        .post(
          AppConstants.API + "/nurseStory/addNurseStory",
          formData,
          axiosConfig
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ isLoading: false });
            NotificationManager.success(res.data.msg, "", 5000);
            this.props.history.push("/profile/" + this.state.userId);
          } else {
            this.setState({ isLoading: false });
            NotificationManager.error(res.data.msg, "", 5000);
          }
        });
    }
  }

  render() {
    // console.log(this.props, 'props render');
    return (
      <div className="profile-view main-page p-tb-50 bg-grey">
        <Container>
          <div className="registration-form" id="create-story-form">
            <div className="title">Create Story</div>
            <div className="w-100 d-block text-right mb-2">
              <button
                class="button back-btn float-none"
                onClick={() => this.props.history.go(-1)}
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Back
              </button>
            </div>

            <Form
              name=""
              className="view-info profile-info border-pad"
              onSubmit={this.handleLicenseSubmit}
            >
              <Row>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label for="title">
                      Title<span style={{ color: "red" }}>*</span>
                    </Label>

                    <Input
                      type="text"
                      name="title"
                      value={this.state.title}
                      ref="title"
                      onChange={this.onChangeTitle}
                    />
                    <span>
                      {this.state.titleError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["title"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label for="description">
                      Description<span style={{ color: "red" }}>*</span>
                    </Label>
                    <CKEditor
                      activeClass="editor12"
                      content={this.state.content}
                      config={{ allowedContent: true,versionCheck: false }}
                      events={{
                        change: this.onChange,
                      }}
                      ref="content"
                    />
                    <span>
                      {this.state.contentError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["content"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs="12" className="block">
                  <FormGroup>
                    <Label>Image</Label>
                    {this.state.story_image ? (
                      <img
                        src={this.state.story_image}
                        style={{ width: 100, height: 100 }}
                        alt={"Nurse Story - Travel Nurses Inc"}
                      />
                    ) : (
                      ""
                    )}
                    <Input
                      type="file"
                      name="image"
                      // value={this.state.image}
                      ref="image"
                      onChange={this.handleFile}
                      accept="image/png, image/jpeg"
                    />
                    <span>
                      {this.state.imageError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["image"]}
                          {/* {this.state.imageError1 ? this.state.errorImage : ""} */}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs="12" className="block check-box-label">
                  <FormGroup>
                    <Input
                      type="checkbox"
                      id="public"
                      name="public"
                      ref="public"
                      onClick={this.handlePublic}
                    />
                    <Label for="public">
                      Do you want to post this story public?
                    </Label>
                  </FormGroup>
                </Col>

                <div className="btn-block col-sm-12">
                  <span
                    href="javascript:void(0)"
                    className="btn btn-prev button"
                    title="Cancel"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </span>

                  <span
                    class="btn btn-next button"
                    title="Update"
                    onClick={this.handleSubmit}
                  >
                    Submit
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    class="btn btn-next button"
                    title="Update"
                    onClick={this.handleDraftSubmit}
                  >
                    Save as Draft
                    {this.state.isDraftLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default CreateStory;
