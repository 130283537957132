import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../AppConstants";
import axios from "axios";
import "../Styles/fillForms.css";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";

import DateIcon from "../images/calender-icon.png";
import { JSONLD, Generic } from "react-structured-data";
import moment from "moment";
import ReactAutocomplete from "react-autocomplete";
import TimesheetPopup from "./timesheet-popup";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { getCountries } from "../Components/helpers/network-calls";
import SignaturePad from "react-signature-canvas";
import "../Styles/signcanvas.css";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy HH:MM");

const formAreas = [
  {
    heading: "Patient Assessment",
    name: "patientAssessment",
    comment:
      "Assesses patients in a timely, thorough and individualized manner according to patient need and expected knowledge level."
  },
  {
    heading: "Teamwork/Collaboration",
    name: "teamwork",
    comment:
      "Works collaboratively with other members of the team to develop an individualized plan of patient care."
  },
  {
    heading: "Clinical/Technical Competency",
    name: "clinicalCompetency",
    comment: "Performs interventions in a timely, accurate and safe manner."
  },
  {
    heading: "Accurate Documentation",
    name: "accurateDocumentation",
    comment:
      "Documents the patient care process accurately and timely. Documents correctly in computerized systems."
  },
  {
    heading: "Age Specific Competency",
    name: "ageCompetency",
    comment:
      "Demonstrates competency appropriate for assigned patient population including adaptations for age specific care."
  },
  {
    heading: "Communication Skills",
    name: "communicationSkills",
    comment:
      "Communicates respectfully and effectively with patients, families, visitors and all facility staff and physicians."
  },
  {
    heading: "Policies and Procedures",
    name: "policiesProcedures",
    comment: "Adheres to facility policies and procedures."
  },
  {
    heading: "Reliability/Attendance",
    name: "reliability",
    comment:
      "Reports to work on time as scheduled. Notifies immediate supervisor if unable to work or tardy."
  },
  {
    heading: "Professionalism",
    name: "professionalism",
    comment:
      "Exhibits a high level of professionalism. Presents professionally."
  },
  {
    heading: "Flexibility/Adaptability",
    name: "flexibility",
    comment:
      "Exhibits flexibility and adaptability. Able to handle stress load of assigned activities."
  },
  {
    heading: "Overall",
    name: "overall",
    comment: "Quality of work, interest and enthusiasm."
  }
];

class ClientEvaluationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applyData: null,
      //FIELDS
      name: "",
      evaluatorName: "",
      email: "",
      code: "+1",
      phone: "",
      hospital: "",
      cities: [],

      city: "",
      unit: "",
      startDateRange: null,
      endDateRange: null,
      position: [
        { text: "Nursing", value: "Nursing", isChecked: false },
        {
          text: "Allied (ex. SLP, PT, OT)",
          value: "Allied (ex. SLP, PT, OT)",
          isChecked: false
        },
        { text: "CNA", value: "CNA", isChecked: false },
        { text: "ORT", value: "ORT", isChecked: false }
      ],
      check_position: [],
      otherChecked: false, // Tracks if the 'Other' checkbox is checked
      otherPosition: "", // Stores the value entered in the 'Other' input field
      patientType: "",
      beds: "",
      comments: "",
      Date1: null,
      // ERRORS
      errors: [],
      firstNameError: false,
      evaluatorError: false,
      emailError: false,
      errorsCode: {},
      codeError: false,
      phone_error: false,
      hospitalError: false,
      cityError: false,
      unitError: false,
      startDateError: false,
      endDate_err: false,
      correctDateError: false,
      patientTypeError: false,
      bedsError: false,
      bedsError: false,
      currentDateError: false,
      positionError: false,
      Date1Error: false,
      signatureError: false,
      errMsg: "",
      isLoading: false,
      button_disable: false,
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      popUp: false,
      patientAssessment: "",
      teamwork: "",
      clinicalCompetency: "",
      accurateDocumentation: "",
      ageCompetency: "",
      communicationSkills: "",
      policiesProcedures: "",
      reliability: "",
      professionalism: "",
      flexibility: "",
      overall: "",

      radioboxError: false,
      message: "",
      toggle: false,
      toggle1: false,
      toggle2: false,
      personalevaluation: [
        "Excellent",
        "Good",
        "Fair",
        "Poor",
        "No Knowledge Of"
      ],
      countries: []
    };
    this.sigCanvas = React.createRef();
    this.handleSignature = this.handleSignature.bind(this);
    this.handlePosition = this.handlePosition.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.inputChangedHandlerContact2 =
      this.inputChangedHandlerContact2.bind(this);
    this.openDatepicker = this.openDatepicker.bind(this);
    this.baseState = this.state;
  }
  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value
    });
    if (values.value.length === 10) {
      this.setState({ phone_error: false });
    }
  };
  inputChangedHandlerContact2 = (values) => {
    this.setState({
      telephone: values.value
    });
    if (values.value.length === 10) {
      this.setState({ telephone_error: false });
    }
  };
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (
      name === "patientAssessment" ||
      name === "teamwork" ||
      name === "clinicalCompetency" ||
      name === "accurateDocumentation" ||
      name === "ageCompetency" ||
      name === "communicationSkills" ||
      name === "policiesProcedures" ||
      name === "reliability" ||
      name === "professionalism" ||
      name === "flexibility" ||
      name === "overall"
    ) {
      if (value === "") {
        this.setState({ radioboxError: true });
      } else {
        this.setState({ radioboxError: false });
      }
    }
  };

  handleBedsChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        beds: e.target.value,
        bedsError: e.target.value === ""
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, "")
    });

    if (e.target.name === "name") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }

    if (e.target.name === "evaluatorName") {
      if (e.target.value === "") {
        this.setState({ evaluatorError: true });
      } else {
        this.setState({ evaluatorError: false });
      }
    }
    if (e.target.name === "unit") {
      if (e.target.value === "") {
        this.setState({ unitError: true });
      } else {
        this.setState({ unitError: false });
      }
    }

    if (e.target.name === "speciality") {
      if (e.target.value === "") {
        this.setState({ specialityError: true });
      } else {
        this.setState({ specialityError: false });
      }
    }
    if (e.target.name === "hospital") {
      if (e.target.value === "") {
        this.setState({ hospitalError: true });
      } else {
        this.setState({ hospitalError: false });
      }
    }
    if (e.target.name === "city") {
      if (e.target.value === "") {
        this.setState({ cityError: true });
      } else {
        this.setState({ cityError: false });
      }
    }
    if (e.target.name === "patientType") {
      if (e.target.value === "") {
        this.setState({ patientTypeError: true });
      } else {
        this.setState({ patientTypeError: false });
      }
    }

    if (e.target.name === "beds") {
      const regex = /^[0-9]*$/;
      if (!regex.test(e.target.value)) {
        this.setState({ bedsError: true });
      } else {
        this.setState({ bedsError: false });
      }
    }
  };
  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true
    });
    return formIsValidCode;
  }

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        // console.log("coming here to code change");
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        // console.log("coming here to code change not +");
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handlePosition = (event) => {
    const { value, checked } = event.target;
    let position = this.state.position.map((planet) =>
      planet.value === value ? { ...planet, isChecked: checked } : planet
    );

    const options = position
      .filter((option) => option.isChecked)
      .map((option) => option.value);

    if (this.state.otherChecked && this.state.otherPosition !== "") {
      options.push(this.state.otherPosition);
    }
    const positionError = options.length === 0;

    this.setState({ position, check_position: options, positionError });
  };

  handleOtherCheckbox = (event) => {
    const { checked } = event.target;
    let updatedCheckPosition = this.state.check_position;

    // If Other checkbox is unchecked, remove Other input value from check_position
    if (!checked) {
      updatedCheckPosition = updatedCheckPosition.filter(
        (value) => value !== this.state.otherPosition
      );
    }

    this.setState({
      otherChecked: checked,
      otherPosition: checked ? this.state.otherPosition : "",
      check_position: updatedCheckPosition
    });
  };

  handleOtherInputChange = (event) => {
    this.setState({ otherPosition: event.target.value });
    const options = this.state.position
      .filter((option) => option.isChecked)
      .map((option) => option.value);
    if (event.target.value !== "") {
      options.push(event.target.value);
      // options.push("Other");
    }
    this.setState({ check_position: options });
  };

  initialFunction = () => {
    const search = new URL(window.location).searchParams;
    this.getAllCountries();
    const name = search.get("name");
    if (name) {
      this.setState({
        name: name
      });
    }
  };

  componentDidMount() {
    // const search = new URL(window.location).searchParams;
    // this.getAllCountries();
    // const name = search.get("name");
    // if (name) {
    //   this.setState({
    //     name: name,
    //   });
    // }
    window.scrollTo(0, 0);
    this.initialFunction();
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };
  openDatepicker = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  openDatepickerFromDate = () => {
    this.setState({ toggle1: !this.state.toggle1 });
  };
  openDatepickerToDate = () => {
    this.setState({ toggle2: !this.state.toggle2 });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    let nodeToFocus = false;

    if (!this.state.name) {
      formIsValid = false;
      errors["firstName"] = "Please enter healthcare professional name.";
      if (nodeToFocus == false) {
        console.log("coming here");
        ReactDOM.findDOMNode(this.refs.name).focus();
        nodeToFocus = true;
      }
    }
    if (!this.state.evaluatorName) {
      formIsValid = false;
      errors["evaluator"] = "Please enter the evaluator name.";

      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.evaluatorName).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.phone).focus();
        nodeToFocus = true;
      }
    }
    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;

      errors["phone"] = "Please enter 10 digits phone number.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.phone).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.hospital) {
      formIsValid = false;
      errors["hospital"] = "Please enter Facility/Hospital name.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.hospital).focus();
        nodeToFocus = true;
      }
    }
    if (!this.state.city) {
      formIsValid = false;
      errors["city"] = "Please enter city and state.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.city).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.unit) {
      formIsValid = false;
      errors["unit"] = "Please enter unit.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.unit).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.startDateRange) {
      formIsValid = false;
      errors["fromdate"] = "Please select from date.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.unit).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.endDateRange) {
      formIsValid = false;
      errors["todate"] = "Please select to date.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.unit).focus();
        nodeToFocus = true;
      }
    } else if (this.state.correctDateError) {
      formIsValid = false;
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.unit).focus();
        nodeToFocus = true;
      }
    }

    if (this.state.check_position.length === 0) {
      formIsValid = false;
      errors["position"] = "Please select atleast one.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.position).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.patientType) {
      formIsValid = false;
      errors["patientType"] = "Please enter patient type.";

      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.patientType).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.beds) {
      formIsValid = false;
      errors["beds"] = "Please enter number of beds.";

      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.beds).focus();
        nodeToFocus = true;
      }
    }

    if (
      !this.state.patientAssessment ||
      !this.state.teamwork ||
      !this.state.clinicalCompetency ||
      !this.state.accurateDocumentation ||
      !this.state.ageCompetency ||
      !this.state.communicationSkills ||
      !this.state.policiesProcedures ||
      !this.state.reliability ||
      !this.state.professionalism ||
      !this.state.flexibility ||
      !this.state.overall
    ) {
      formIsValid = false;
      errors["radiobox"] = "Please rate every personal evaluation.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.evaluations).focus();
        nodeToFocus = true;
      }
    }

    if (!this.state.Date1) {
      formIsValid = false;
      errors["date1"] = "Please select a date.";
      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.comments).focus();
        nodeToFocus = true;
      }
    } else if (this.state.currentDateError) {
      formIsValid = false;

      if (nodeToFocus == false) {
        ReactDOM.findDOMNode(this.refs.comments).focus();
        nodeToFocus = true;
      }
    }

    if (this.sigCanvas.current.isEmpty()) {
      formIsValid = false;
      errors["signature"] = "Please give your signature.";
      if (nodeToFocus == false) {
        nodeToFocus = true;
      }
    }

    this.setState({
      errors: errors,
      firstNameError: true,
      phone_error: true,
      specialityError: true,
      referralSourceError: true,
      otherReferralSourceError: true,
      startDateError: true,
      endDate_err: true,
      titleError: true,
      hospitalError: true,
      positionError: true,
      cityError: true,
      patientTypeError: true,
      bedsError: true,
      reportcompletedbyError: true,
      Date1Error: true,
      strengthsError: true,
      unitError: true,
      evaluatorError: true,
      radioboxError: true,
      signatureError: true
    });
    return formIsValid;
  }

  handleSignature() {
    return (
      this.sigCanvas.current
        // .getTrimmedCanvas()
        .toDataURL("image/png")
        .split(",")[1]
    );

    // console.log(image);
  }

  handleCanvasMouseDown = () => {
    if (!this.sigCanvas.current.isEmpty()) {
      // Signature field is not empty when touched
      this.setState({ signatureError: false });
    } else {
      this.setState({ signatureError: true });
    }
  };

  handleClearSignature = () => {
    this.sigCanvas.current.clear(); // Clear the signature canvas
    this.setState({ signatureError: true });
  };

  handleSubmit(e) {
    e.preventDefault();
    let image = this.handleSignature();

    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      let formData = {
        name: this.state.name,
        evaluatorNameTitle: this.state.evaluatorName,
        evaluatorEmail: this.state.email,
        phone: this.state.phone,
        hospital: this.state.hospital,
        primaryUnit: this.state.unit,
        fromDate: this.state.startDateRange
          ? moment(this.state.startDateRange).format("MM/DD/YYYY")
          : "",
        toDate: this.state.endDateRange
          ? moment(this.state.endDateRange).format("MM/DD/YYYY")
          : "",
        position: this.state.check_position,
        citystate: this.state.city,
        comments: this.state.comments,
        date: this.state.Date1
          ? moment(this.state.Date1).format("MM/DD/YYYY")
          : "",
        patientType: this.state.patientType ? this.state.patientType : "",
        beds: this.state.beds ? this.state.beds + "" : "",
        patientAssessment: this.state.patientAssessment,
        teamwork: this.state.teamwork,
        clinicalCompetency: this.state.clinicalCompetency,
        accurateDocumentation: this.state.accurateDocumentation,
        ageSpecificCompetency: this.state.ageCompetency,
        communicationSkills: this.state.communicationSkills,
        policiesProcedures: this.state.policiesProcedures,
        reliability: this.state.reliability,
        professionalism: this.state.professionalism,
        flexibility: this.state.flexibility,
        overall: this.state.overall,
        signature: image
      };

      axios
        .post(AppConstants.API + "/nurse/clientEvaluation", formData)

        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({
              button_disable: true,
              popUp: true,
              isLoading: false,
              message: res.data.msg
            });
          } else {
            console.log(res.data.msg, "error message");
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                )
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        })
        .catch((err) => {
          console.log(err, "error in post request");
        });
    }
  }

  handleStartDate(date) {
    // console.log(date);
    if (date && date != null) {
      this.setState({
        startDateRange: date,
        startDateError: false,
        toggle1: !this.state.toggle1
      });
    }
    var sDate = new Date(date).toISOString();
    var eDate = new Date(this.state.endDateRange).toISOString();
    if (this.state.endDateRange != null && sDate > eDate) {
      this.setState({
        correctDateError: true
      });
    } else {
      this.setState({
        correctDateError: false
      });
    }
  }

  handleStartDate1(date) {
    // console.log("coming here to handle today date");
    // console.log(date, "date");

    if (date && date != null) {
      this.setState({
        Date1: date,
        Date1Error: false,
        toggle: !this.state.toggle
      });
    }

    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    var enteredDate = new Date(date);
    enteredDate.setHours(0, 0, 0, 0);
    // console.log(todayDate, "today date", enteredDate, "entered dates");
    if (todayDate > enteredDate) {
      this.setState({
        currentDateError: true
      });
    } else {
      this.setState({
        currentDateError: false
      });
    }
  }

  handleEndDate(date) {
    if (date && date != null) {
      this.setState({
        endDate_err: false,
        endDateRange: date,
        toggle2: !this.state.toggle2
      });
    }
    var sDate = new Date(this.state.startDateRange).toISOString();
    var eDate = new Date(date).toISOString();
    if (sDate > eDate) {
      this.setState({
        correctDateError: true
      });
    } else {
      this.setState({
        correctDateError: false
      });
    }
  }

  handleTimesheetPopup() {
    this.setState(
      {
        popUp: !this.state.popUp
      },
      () => {
        this.setState(this.baseState);
        this.sigCanvas.current.clear();
        this.initialFunction();
        window.scrollTo({ top: 0, behavior: "smooth" });
        // window.location.reload();
      }
    );
  }
  render() {
    return (
      <div className="apply-form registr-info main-page">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Webpage"
            schema={{
              name: this.state.metaTitle,
              description: this.state.metaDescription,
              contentReferenceTime: new Date()
            }}
          ></Generic>
        </JSONLD>

        <Container>
          <div className="registration-form" id="professional-form">
            <div className="title">Client Evaluation Form</div>

            <Form className="inner-block">
              <Row>
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="FirstName">
                      Healthcare Professional Name
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      ref="name"
                    />
                    <span>
                      {this.state.firstNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["firstName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>

                <Col xs="6" className="block" id="mail-field">
                  <FormGroup>
                    <Label for="evaluatorName">
                      Evaluator Name/Title
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="evaluatorName"
                      value={this.state.evaluatorName}
                      id="evaluatorName"
                      onChange={this.handleChange}
                      ref="evaluatorName"
                    />
                    <span>
                      {this.state.evaluatorError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["evaluator"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <div className="form-block">
                <Row className="codephone-blk">
                  <div class="block col-6">
                    {" "}
                    <FormGroup>
                      <Label for="hospital">
                        Facility/Hospital
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="hospital"
                        value={this.state.hospital}
                        id="hospital"
                        onChange={this.handleChange}
                        ref="hospital"
                      />
                      <span>
                        {this.state.hospitalError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["hospital"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </div>
                  <div class="block small-block col-1 ">
                    <FormGroup>
                      <Label for="Code">Country Code</Label>
                      <Input
                        type="select"
                        value={this.state.code}
                        name="code"
                        ref="code"
                        onChange={this.handleCodeChange}
                        id="Code"
                        disabled={true}
                        style={{
                          background: "#f0f0f0",
                          borderColor: "#f0f0f0"
                        }}
                      >
                        {this.state.countries.map((item) => {
                          // const isUSA = item.phoneCode === 1;
                          return (
                            <option
                              data-countryCode={item.phoneCode}
                              value={"+" + item.phoneCode}

                              // disabled={!isUSA} // Disable options that are not USA
                              // selected={isUSA} // Set USA as selected
                            >
                              {item.phoneCode === 1 ? "USA" : item.name}{" "}
                              {"+" + item.phoneCode}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </div>

                  <div class="block width-40 col-4 phone-input">
                    <FormGroup>
                      <Label for="Phone">
                        Phone
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="phone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact}
                        value={this.state.phone}
                        className="form-control"
                        ref="phone"
                      />
                      <span>
                        {this.state.phone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </div>
                  <span
                    className="code-validation col-sm-12"
                    // id="mbl-validation"
                    id="desk-validation"
                  >
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </Row>
                <Row>
                  <Col xs="6" className="block">
                    <FormGroup className="professional-search">
                      <Label for="Last Name">
                        City & State
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="city"
                        value={this.state.city}
                        id="city"
                        onChange={this.handleChange}
                        ref="city"
                      />

                      <span>
                        {this.state.cityError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["city"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label>
                        Primary Unit/Specialty
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="unit"
                        value={this.state.unit}
                        id="unit"
                        onChange={this.handleChange}
                        ref="unit"
                      />
                      <span>
                        {this.state.unitError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["unit"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="form-block">
                <h4>Dates Worked</h4>
                <Row>
                  <Col xs="6" className="block date_picker_field">
                    <FormGroup>
                      <Label for="fromDate">
                        From
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <div className="date_picker_grid">
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          id="fromDate"
                          openToDate={new Date()}
                          className="form-control pointer-on-hover"
                          placeholderText="MM/DD/YYYY"
                          selected={this.state.startDateRange}
                          onChange={this.handleStartDate.bind(this)}
                          open={this.state.toggle1}
                          onClickOutside={this.openDatepickerFromDate.bind(
                            this
                          )}
                          onFocus={this.openDatepickerFromDate.bind(this)}
                          // minDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()}
                          // onClick={this.openDatepickerFromDate.bind(this)}
                          // minDate={new Date()}
                          // selectsStart
                          // showMonthDropdown
                          // showYearDropdown
                          autoComplete="off"
                          // onKeyDown={(e) => e.preventDefault()}
                          startDate={this.state.startDateRange}
                          endDate={this.state.endDateRange}
                          icon="iconsminds-calendar-4"
                          ref="fromDate"
                        />
                        <figure className="date-icon">
                          <img
                            src={DateIcon}
                            onClick={this.openDatepickerFromDate.bind(this)}
                          />
                        </figure>
                      </div>
                      {this.state.startDateError ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["fromdate"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6" className="block date_picker_field">
                    <FormGroup>
                      <Label for="toDate">
                        To
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <div className="date_picker_grid">
                        <DatePicker
                          id="toDate"
                          className="form-control pointer-on-hover"
                          placeholderText="MM/DD/YYYY"
                          openToDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          selected={this.state.endDateRange}
                          onChange={this.handleEndDate.bind(this)}
                          open={this.state.toggle2}
                          onClickOutside={this.openDatepickerToDate.bind(this)}
                          onFocus={this.openDatepickerToDate.bind(this)}
                          onKeyDown={(e) => e.preventDefault()}
                          autoComplete="off"
                          startDate={this.state.startDateRange}
                          endDate={this.state.endDateRange}
                          icon="iconsminds-calendar-4"
                          ref="toDate"
                        />
                        <figure className="date-icon">
                          <img
                            src={DateIcon}
                            onClick={this.openDatepickerToDate.bind(this)}
                          />
                        </figure>
                      </div>
                      <span>
                        {this.state.endDate_err === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["todate"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <div>
                        {this.state.correctDateError ? (
                          <span className="error" style={{ color: "red" }}>
                            End date should be greater than Start date{" "}
                          </span>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="form-block">
                <h4>
                  Position
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <FormGroup check>
                  <div>
                    {this.state.position.map((planet, index) => {
                      return (
                        <span className="form-action" id={index} key={index}>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="position"
                              onChange={this.handlePosition}
                              value={planet.value}
                              checked={planet.isChecked}
                              ref="position"
                            ></Input>
                            {planet.text}
                          </Label>
                        </span>
                      );
                    })}
                    <span className="form-action" id="other">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="position"
                          onChange={this.handleOtherCheckbox}
                          checked={this.state.otherChecked}
                          ref="otherCheckbox"
                        ></Input>
                        Other
                        <input
                          type="text"
                          name="otherPosition"
                          value={this.state.otherPosition}
                          onChange={(e) => {
                            this.handleOtherInputChange(e);
                          }}
                          onClick={(e) => {
                            // console.log("hi");
                            this.state.otherPosition.length == 0 &&
                              this.setState({ otherChecked: true });
                            // console.log(
                            //   this.state.otherChecked,
                            //   "other checked"
                            // );
                          }}
                          placeholder={
                            this.state.otherChecked ? "Type here" : ""
                          }
                          // disabled={!this.state.otherChecked}
                          style={{
                            border: "none",
                            borderBottom: "1px solid black" /* Bottom border */,
                            // marginBottom:
                            //   "10px" /* Adjust the bottom margin as needed */,
                            padding:
                              "5px" /* Add padding for better appearance */,
                            width: "200px" /* Set the desired width */,
                            fontSize: "14px" /* Set the font size */,
                            display: "inline" /* Set display inline-block */,
                            verticalAlign: "middle", // Align input vertically with the checkbox
                            marginLeft: "30px", // Adjust margin as needed
                            borderRadius: "0px",
                            backgroundColor: "inherit",
                            height: "100%",
                            cursor: "text"
                          }}
                        />
                      </Label>
                    </span>

                    <div>
                      {this.state.positionError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["position"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>
              <Row>
                <Col xs="8" className="block">
                  <FormGroup>
                    <Label for="hospital">
                      Type of Patients
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>{" "}
                      (Example: Cardio, Ortho, Peds vs. Adults)
                    </Label>
                    <Input
                      type="text"
                      name="patientType"
                      value={this.state.patientType}
                      id="patientType"
                      onChange={this.handleChange}
                      ref="patientType"
                    />
                    <div>
                      {this.state.patientTypeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["patientType"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="3" className="block">
                  <FormGroup>
                    <Label>
                      Number of Beds
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      name="beds"
                      value={this.state.beds}
                      min={0}
                      id="beds"
                      onChange={this.handleBedsChange.bind(this)}
                      ref="beds"
                    />
                    <div>
                      {this.state.bedsError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["beds"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div className="form-block">
                <h4>
                  Please rate the following: (Excellent, good, fair, poor, No
                  knowledge of)
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <Table responsive borderless className="professional-form-list">
                  <thead>
                    <tr>
                      <th>Personal Evaluation</th>
                      <th>Excellent</th>
                      <th>Good</th>
                      <th>Fair</th>
                      <th>Poor</th>
                      <th>No Knowledge Of</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formAreas.map((area) => {
                      return (
                        <tr>
                          <th scope="row" style={{ lineHeight: "1" }}>
                            {area.heading}
                            <i
                              style={{ fontSize: "12px", fontWeight: "normal" }}
                            >
                              {" "}
                              ({area.comment})
                            </i>
                          </th>
                          {this.state.personalevaluation.map((value, index) => {
                            const isLastItem =
                              index ===
                              this.state.personalevaluation.length - 1;
                            return (
                              <td>
                                <input
                                  type="radio"
                                  value={value}
                                  name={area.name}
                                  checked={this.state[area.name] == value}
                                  onChange={this.handleRadioChange}
                                  ref={isLastItem ? "evaluations" : null}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <span className="table-validation">
                  {this.state.radioboxError === true ? (
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["radiobox"]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>

                <div className="block">
                  <FormGroup>
                    <Label for="Last Name">
                      Additional Comments
                      {/* <span className="error" style={{ color: "red" }}>
                        *
                      </span> */}
                    </Label>
                    <Input
                      id="comments"
                      name="comments"
                      type="textarea"
                      value={this.state.comments}
                      onChange={this.handleChange}
                      ref="comments"
                    />
                  </FormGroup>
                  <div className="row">
                    <Col xs="4" className="block date_picker_field">
                      <FormGroup>
                        <Label for="Last Name">
                          Date
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <div className="date_picker_grid">
                          <DatePicker
                            className="form-control pointer-on-hover"
                            placeholderText="MM/DD/YYYY"
                            selected={this.state.Date1}
                            showMonthDropdown
                            showYearDropdown
                            onChange={this.handleStartDate1.bind(this)}
                            onClickOutside={this.openDatepicker}
                            open={this.state.toggle}
                            onFocus={this.openDatepicker}
                            // minDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()}
                            autoComplete="off"
                            icon="iconsminds-calendar-4"
                          />
                          <figure className="date-icon pointer-on-hover">
                            <img src={DateIcon} onClick={this.openDatepicker} />
                          </figure>
                        </div>
                        {this.state.Date1Error ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["date1"]}
                          </span>
                        ) : (
                          ""
                        )}
                        <div>
                          {this.state.currentDateError ? (
                            <span className="error" style={{ color: "red" }}>
                              Date should not be past date.{" "}
                            </span>
                          ) : null}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col
                      xs="8"
                      className="block d-flex justify-content-start justify-content-md-end"
                      id="mail-field"
                    >
                      <FormGroup style={{ position: "relative" }}>
                        <Label for="signature">
                          Signature
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>

                        <SignaturePad
                          ref={this.sigCanvas}
                          canvasProps={{
                            className: "clientSignatureCanvas",
                            onMouseUp: this.handleCanvasMouseDown
                          }}
                        />
                        <span>
                          {this.state.signatureError === true ? (
                            <span
                              className="error"
                              style={{ color: "red" }}
                              ref="signature"
                            >
                              {this.state.errors["signature"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            top: "27px",
                            right: "8px",
                            cursor: "pointer"
                          }}
                          onClick={this.handleClearSignature}
                        >
                          <span
                            role="img"
                            aria-label="Clear Signature"
                            style={{
                              fontSize: "24px",
                              color: "#b9c8df", // Set the color to b9c8df
                              fontWeight: "bold"
                            }}
                          >
                            &times;
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                  </div>
                </div>
              </div>
            </Form>
            <Row className="justify-content-center submit-bttn">
              <Col md="12">
                <button
                  class="btn btn-next button"
                  title="Submit"
                  onClick={this.handleSubmit}
                  // disabled={this.state.button_disable}
                  disabled={this.state.isLoading}
                  style={{ backgroundColor: "#334ca4" }}
                >
                  Submit{" "}
                  {this.state.isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </Row>
            {this.state.errMsg}
            {this.state.popUp ? (
              <TimesheetPopup
                popup={this.state.popUp}
                handleTimesheetPopup={this.handleTimesheetPopup.bind(this)}
                message={this.state.message}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default ClientEvaluationForm;
