import React from "react";

// import Destination from "../Components/destination-block";
import { Container, Row, Col } from "reactstrap";
import styles from "../Styles/news.css";

import AppConstants from "../AppConstants";
import { Link } from "react-router-dom";

import axios from "axios";
import { Helmet } from "react-helmet";

import { JSONLD, Generic } from "react-structured-data";
import ReactPaginate from "react-paginate";
import FadeIn from "react-fade-in";
class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      blogs: [],
      currentPage: 1,
      pageSizes: [6, 12, 18, 24],
      selectedPageSize: 6,
      selectedOrderOption: "",
      searchText: "",
      categories: [],
      categoryFilter: "",
      blogListdata: false,
    };
    this.handleFilterData = this.handleFilterData.bind(this);
    this.onSearchKey = this.onSearchKey.bind(this);
  }
  handleFilterData(e) {
    let page = 1;
    this.setState({ categoryFilter: e.target.value, currentPage: 1 });
    this.updateBlogList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      e.target.value
    );
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    axios
      .get(AppConstants.CMS_API + "/categories/getCategories")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status === "Success") {
          that.setState({ categories: data.data });
        }
      });
    if (this.props.location.state) {
      if (this.props.location.state.category) {
        this.state.categoryFilter = this.props.location.state.category;
      }
      if (this.props.location.state.viewCount) {
        this.state.selectedOrderOption = this.props.location.state.viewCount;
      }
    }
    this.updateBlogList(
      this.state.currentPage,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.categoryFilter
    );
  }
  onChangePage = (page) => {
    this.updateBlogList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.categoryFilter
    );
    this.setState({
      currentPage: page,
    });
  };
  onSearchKey = (e) => {
    let page = 1;
    this.updateBlogList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      e.target.value,
      this.state.categoryFilter
    );
    this.setState({ searchText: e.target.value, currentPage: page });
  };
  updateBlogList(
    currentPage,
    selectedPageSize,
    selectedOrderOption,
    searchText,
    categoryFilter
  ) {
    if (searchText && searchText !== "") {
      searchText = searchText;
    }
    //  else if (this.state.searchText && this.state.searchText !== "") {
    //   searchText = this.state.searchText;
    // }
    else {
      searchText = "";
    }
    let tablefilterdata = {
      page: currentPage,
      sizeperpage: selectedPageSize,
      sortname: selectedOrderOption,
      serchtext: searchText,
      defaultSearchText: searchText,
      category: categoryFilter,
    };
    axios
      .post(
        AppConstants.CMS_API + "/blogs/getAllBlogsFrontEnd",
        tablefilterdata
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({
          blogListdata: true,
          totalPage: data.totalPage,
          blogs: data.data,
          selectedItems: [],
          totalItemCount: data.total_records,
          isLoading: true,
        });
      });
  }
  viewBlog(id, e) {
    var data1 = {};
    // https://json.geoiplookup.io/api
    // http://www.geoplugin.net/json.gp?jsoncallback=?
    // https://ipapi.co/json
    axios
      .get("https://ipapi.co/json")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        data1 = {
          viewerDetails: data,
          blog_id: id,
        };
        axios
          .post(AppConstants.CMS_API + "/blogs/blogView", data1)
          .then((res) => {
            return res.data;
          })
          .then((data4) => {
            if (data4.Status) {
              console.log(data4.Status);
            }
          });
      });
  }
  handlePageClick = (data) => {
    this.onChangePage(data.selected + 1);
  };
  render() {
    // window.scrollTo(0, 0);
    return (
      <div className="news-block news-page">
        <FadeIn>
          <JSONLD>
            <Generic
              type="webpage"
              jsonldtype="Blog"
              schema={{
                name: 'Travel Nurses Blog, Travel Nursing Agency, Staffing Company | Travel Nurses Inc',
                description:
                  'Travel Nurses Blog | A blog where Travel Nurses share insights from the field. Join Travel Nurses Inc. and start your new adventure. Great Pay and Benefits!',
                contentReferenceTime: new Date(),
              }}
            ></Generic>
          </JSONLD>
          <Helmet>
            <title>Travel Nurses Blog, Travel Nursing Agency, Staffing Company | Travel Nurses Inc</title>
            <meta
              name="title"
              content="Travel Nurses Blog, Travel Nursing Agency, Staffing Company | Travel Nurses Inc"
            />
            <meta
              name="description"
              content="Travel Nurses Blog | A blog where Travel Nurses share insights from the field. Join Travel Nurses Inc. and start your new adventure. Great Pay and Benefits!"
            />

            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              // content={AppConstants.Base_Url + "/blog"}
              content={AppConstants.Word_Press_API + '/blog'}
            />
            <meta
              property="og:title"
              content="Travel Nurses Blog, Travel Nursing Agency, Staffing Company | Travel Nurses Inc"
            />
            <meta
              property="og:description"
              content="Travel Nurses Blog | A blog where Travel Nurses share insights from the field. Join Travel Nurses Inc. and start your new adventure. Great Pay and Benefits!"
            />
            <meta property="og:image" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />

            <meta property="twitter:card" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />
            <meta
              property="twitter:url"
              // content={AppConstants.Base_Url + "/blog"}
              content={AppConstants.Word_Press_API + '/blog'}
            />
            <meta
              property="twitter:title"
              content="Travel Nurses Blog, Travel Nursing Agency, Staffing Company | Travel Nurses Inc"
            />
            <meta
              property="twitter:description"
              content="Travel Nurses Blog | A blog where Travel Nurses share insights from the field. Join Travel Nurses Inc. and start your new adventure. Great Pay and Benefits!"
            />
            <meta property="twitter:image" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />
          </Helmet>
          <div className="inner-block">
            <Container>
              <h1 className="title">{AppConstants.Blog}</h1>
              <p className="text-center ">{AppConstants.Sub_Blog}</p>

              <div className="blog-search">
                <span className="seach-box">
                  <input type="text" className="form-filed" placeholder="Search" onChange={this.onSearchKey} />
                </span>
                <span className="categary-select">
                  <label for="CategoryFilter">Select Category</label>
                  <select
                    className="form-filed select-box"
                    value={this.state.categoryFilter}
                    name="categoryFilter"
                    id="CategoryFilter"
                    onChange={this.handleFilterData}
                  >
                    {/* <select className="form-filed select-box"> */}
                    <option value="">All</option>
                    {this.state.categories.map((item, index) => {
                      return <option value={item._id}>{item.category}</option>;
                    })}
                  </select>
                </span>
                <div className="clear"></div>
              </div>
              {this.state.blogListdata === true ? (
                <div className="inner-content">
                  {this.state.totalItemCount ? (
                    <h4 style={{ color: '#25408f' }}>
                      {this.state.totalItemCount == 1
                        ? 'Results: ' + this.state.totalItemCount + ' Blog Found'
                        : 'Results: ' + +this.state.totalItemCount + ' Blogs Found'}
                    </h4>
                  ) : (
                    ''
                  )}
                  <Row className="justify-content-center">
                    {this.state.blogs.length !== 0 ? (
                      this.state.blogs.map((item, index) => (
                        <Col xs="4" className="block">
                          <div className="block-box">
                            <figure className="pic image-fill">
                              {item.images ? (
                                <img src={item.images[0]} alt={item.title + ' - Travel Nurses Inc'} />
                              ) : (
                                ''
                              )}
                              <span className="category" style={{ backgroundColor: item.color }}>
                                {item.category}
                              </span>
                            </figure>

                            <div className="text">
                              <p className="date">{item.created_date}</p>
                              <h4>{item.title}</h4>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content.substring(0, 100) + '  ...',
                                }}
                              />
                              <Link
                                // to={"/blog-details/" + item._id}
                                to={{
                                  pathname: '/blog-details/' + item.slug,
                                  state: { blog_id: item._id },
                                }}
                                className="read-more"
                                onClick={this.viewBlog.bind(this, item._id)}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <span className="no-data">No data available.</span>
                    )}
                  </Row>
                  {this.state.blogs.length !== 0 ? (
                    // <Pagination1
                    //   aria-label="Page navigation"
                    //   className="pagination-blk"
                    //   currentPage={this.state.currentPage}
                    //   totalPage={this.state.totalPage}
                    //   onChangePage={(i) => this.onChangePage(i)}
                    // />
                    <ReactPaginate
                      breakLabel={'.....'}
                      breakClassName={'break-me page-link false mx-0'}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      forcePage={this.state.currentPage - 1}
                      // onPageChange={this.onChangePage(i)}
                      containerClassName={'pagination pagination-blk d-flex'}
                      subContainerClassName={'pages pagination page-item '}
                      disabledClassName={'disabledcustom-pagi'}
                      previousLinkClassName={'prve-next  page-link'}
                      nextLinkClassName={'prve-next next-next  page-link'}
                      activeClassName={'active'}
                      pageClassName={'false page-link'}
                      pageLinkClassName={'anchor class'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div className="inner-content loader-center">
                  {' '}
                  <img src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`} alt="No Image" className="loader-center" style={{ width: 80, height: 80 }} />{' '}
                </div>
              )}
            </Container>
          </div>
          {/* <Subscribeblock /> */}
        </FadeIn>
        {/* <Destination destinationHistory={this.props} /> */}
      </div>
    );
  }
}

export default Blog;
