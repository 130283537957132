import React from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
 

class DivContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_methods: [
        { text: "Phone", value: "phone", isChecked: false },
        { text: "Text", value: "text", isChecked: false },
        { text: "Email", value: "email", isChecked: false },
      ],
      certifications: [
        { text: "RN", value: "RN", isChecked: false },
        {
          text: "Surgical Technician",
          value: "Surgical Technician",
          isChecked: false,
        },
        {
          text: "Allied Health Professional",
          value: "Allied Health Professional",
          isChecked: false,
        },
        { text: "Other", value: "Other", isChecked: false },
      ],
      timings: [
        { text: "Morning", value: "Morning", isChecked: false },
        { text: "Afternoon", value: "Afternoon", isChecked: false },
        { text: "Evening", value: "Evening", isChecked: false },
      ],
      check_certifications: [],
      check_contact_methods: [],
      check_timings: [],
      firstName: "",
      lastName: "",
      code: "",
      phone: "",
      email: "",
      speciality: "",
      errors: [],
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phone_error: false,
      certificationsError: false,
      contact_methodsError: false,
      timingsError: false,
      errMsg: "",
      isLoading: false,
      reasonError: false,
    };
    this.handleContactMethod = this.handleContactMethod.bind(this);
    this.handleTimings = this.handleTimings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
  }
  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value,
    });
    if (values.value.length === 10) {
      this.setState({ phone_error: false });
    }
  };
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const componentState = this;
    let userId = localStorage.getItem("id");
    axios
      .get(
        AppConstants.Jobs_API + "/jobs/getJob/" + this.props.match.params.id,
        { nurseId: userId ? userId : "" }
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data) {
          var jobData = data.data;

          componentState.setState({
            jobTitle: jobData.title,
            jobId: jobData._id,
            jobProviderId: jobData.providerJobId,
            location: jobData.city,
            startDate: jobData.start_date,
            endDate: jobData.end_date,
            shift: jobData.shift,
            positions: jobData.positions,
          });
        }
      });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "reason") {
      if (e.target.value === "") {
        this.setState({ reasonError: true });
      } else {
        this.setState({ reasonError: false });
      }
    }
  };
  handleTimings(event) {
    var options = [];
    let timings = this.state.timings;
    timings.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.timings.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });

    this.setState({ timings: timings, check_timings: options });
  }
  handleContactMethod(event) {
    var options = [];
    let contact_methods = this.state.contact_methods;
    contact_methods.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.contact_methods.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ contact_methodsError: true });
    } else {
      this.setState({ contact_methodsError: false });
    }
    this.setState({
      contact_methods: contact_methods,
      check_contact_methods: options,
    });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (this.state.check_contact_methods.length === 0) {
      formIsValid = false;
      errors["contact_methods"] = "Please select your contact method.";
      ReactDOM.findDOMNode(this.refs.contact_methods).focus();
    }
    if (!this.state.reason) {
      formIsValid = false;
      errors["reason"] = "Please add your comments in the 'Reason to Contact' box.";
      ReactDOM.findDOMNode(this.refs.reason).focus();
    }
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter your valid email.";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Please enter your valid digits phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter your name.";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
      certificationsError: true,
      contact_methodsError: true,
      // timingsError: true,
      reasonError: true,
    });
    return formIsValid;
  }
  handleSubmit(e) {
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      const formData = {
        name: this.state.firstName,
        contactMethods: JSON.stringify(this.state.check_contact_methods),
        contactTimings: JSON.stringify(this.state.check_timings),
        reason: this.state.reason,
        email: this.state.email,
        phone: this.state.phone
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
      };
      axios
        .post(AppConstants.CMS_API + "/contactUS/addContactUsDetails", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            setTimeout((e) => {
              this.props.history.push("/");
            }, 2000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  render() {
    const { jobTitle } = this.state;
    return (
      <div className="apply-form registr-info main-page">
        <Container>
          <div className="registration-form">
            <div className="title">Contact Us</div>
            <p className="text-center">
              We're looking for amazing Medical Travelers. Are you ready to see
              the country?
            </p>
            <Form
              className="inner-block contact-form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-block">
                <h4>
                  Contact Info
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <Row className="codephone-blk">
                  <Col xs="4" className="block">
                    <FormGroup>
                      <Label for="FirstName">
                        Name
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="Name"
                        id="Name"
                        value={this.state.firstName}
                        onBlur={() => this.state.firstName}
                        onChange={this.handleInputChange}
                        ref="firstName"
                      />
                      <span>
                        {this.state.firstNameError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["firstName"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs="4" className="block widht-40">
                    <FormGroup>
                      <Label for="Phone">
                        Phone
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="phone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact}
                        value={this.state.phone}
                        onBlur={() => this.state.phone}
                        className="form-control"
                        ref="phone"
                      />
                      <span>
                        {this.state.phone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>

                  <Col xs="4" className="block">
                    <FormGroup>
                      <Label for="Email">
                        Email
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        value={this.state.email}
                        onBlur={() => this.state.email}
                        onChange={this.handleInputChange}
                        ref="email"
                      />
                      <span>
                        {this.state.emailError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["email"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="form-block reason-sec">
                <Row>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Reason">
                        Reason to Contact
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="textarea"
                        name="reason"
                        value={this.state.reason}
                        className="textarea"
                        placeholder="Reason"
                        onChange={this.handleInputChange}
                        ref="reason"
                      />
                      <span>
                        {this.state.reasonError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["reason"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="form-block">
                <h4>
                  Best Contact Method
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <div className="check-sec">
                  {this.state.contact_methods.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="contact_method"
                          onChange={this.handleContactMethod}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="contact_methods"
                        ></Input>
                        <Label className="check-field">{planet.text}</Label>
                      </span>
                    );
                  })}
                  <div>
                    {this.state.contact_methodsError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errors["contact_methods"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="form-block">
                <h4>
                  What's the best time to contact you?
                  {/* <span className="error" style={{ color: "red" }}>
                    *
                  </span> */}
                </h4>
                <div className="check-sec">
                  {this.state.timings.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="timings"
                          onChange={this.handleTimings}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="timings"
                        ></Input>
                        <Label className="check-field">{planet.text}</Label>
                      </span>
                    );
                  })}
                </div>
              </div>
            </Form>
            <Row className="justify-content-center">
              <Col md="12">
                <a
                  href={AppConstants.Word_Press_API + "/about"}
                  class="btn btn-prev button"
                >
                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                  Back
                </a>
                <button
                  class="btn btn-next button"
                  title="Submit"
                  onClick={this.handleSubmit}
                >
                  Submit{" "}
                  {this.state.isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </Row>
            {this.state.errMsg}
          </div>
        </Container>
      </div>
    );
  }
}

export default DivContact;
