import React from "react";
import images from "../Components/images";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
import { loginUser, getUserInfo } from "../actions/authentication";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { FaAngleDoubleLeft } from "react-icons/fa";

class LogIn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      phone: "",
      password: "",
      phoneError: false,
      passwordError: false,
      errors: [],
      isLoading: false,
      errMsg: "",
      button_disable: false,
      loginStatus: false,
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handlePhoneSubmit = this.handlePhoneSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "phone") {
      if (e.target.value === "") {
        this.setState({ phoneError: true });
      } else {
        this.setState({ phoneError: false });
      }
    }
    if (e.target.name === "password") {
      if (e.target.value === "") {
        this.setState({ passwordError: true });
      } else {
        this.setState({ passwordError: false });
      }
    }
  }

  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value,
    });
  };

  handlePhoneValidation() {
    let errors = {};
    let formIsValid = true;

    let filter =
      /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    if (filter.test(this.state.phone)) {
      if (this.state.phone.length == 10) {
        formIsValid = true;
      } else {
        errors["phone"] = " Please enter a valid phone number";
        formIsValid = false;
      }
    } else {
      errors["phone"] = " Please enter a valid phone number";
      formIsValid = false;
    }

    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = " Please enter a valid phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    this.setState({
      errors: errors,
      phoneError: true,
      passwordError: true,
    });
    return formIsValid;
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter your password";
      ReactDOM.findDOMNode(this.refs.password).focus();
    }
    if (this.state.password !== "") {
      if (this.state.password.length <= 6) {
        formIsValid = false;
        errors["password"] = "Password must be longer than 6 characters";
        ReactDOM.findDOMNode(this.refs.password).focus();
      }
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter a valid phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    this.setState({
      errors: errors,
      phoneError: true,
      passwordError: true,
    });
    return formIsValid;
  }
  toggle() {
    this.setState({ modal: !this.state.modal });
  }
  handleOnClick() {
    this.props.history.push("/otp");
  }

  handlePhoneSubmit() {
    if (this.handlePhoneValidation()) {
      let formData = {
        phoneNumber: this.state.phone
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
      };

      this.setState({ isLoading: true });
      axios
        .post(AppConstants.API + "/nurse/verifyPhonenumber", formData)
        .then((res) => {
          if (res.data.Status === "Success" && res.data.password === true) {
            this.setState({ isLoading: false, loginStatus: true });
          } else if (
            res.data.Status === "Failed" &&
            res.data.phoneNumberExist === false
          ) {
            this.setState({ isLoading: false });
            NotificationManager.warning(res.data.msg, "", 6000);
          } else if (
            res.data.phoneNumberExist === true &&
            res.data.password === false
          ) {
            localStorage.setItem("userID", res.data.id);
            this.setState({ isLoading: false });
            this.toggle();
            NotificationManager.warning(res.data.msg, "", 3000);
          } else {
            this.setState({ isLoading: false });
            localStorage.setItem("userID", res.data.id);
            NotificationManager.warning(res.data.msg, "", 3000);
            this.props.history.push("/otp");
          }
        });
    }
  }

  handleSubmit() {
    if (this.handleValidation()) {
      let formData = {
        phoneNumber: this.state.phone,
        password: this.state.password,
      };
      this.setState({ isLoading: true });
      this.props.loginUser(formData, this.props.history);
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 2000);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    Cookies.remove("user", {
      domain: ".travelnursesinc.com",
      path: "/",
    });
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("id");
    localStorage.removeItem("userID");
    localStorage.removeItem("tempId");
  }
  handleBack = (e) => {
    localStorage.setItem("status", "1");
    this.props.history.push("/");
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-lg recruiter-modal edit-profile-modal"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={this.toggle}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <Form className="inner-block">
                <div className="btn-block">
                  <h6>
                    Welcome to Travel Nurses, Inc. Please create a secure
                    password to login.
                  </h6>
                  <a onClick={this.handleOnClick} className="blue-bttn">
                    Yes
                  </a>
                  <a onClick={this.toggle} className="clear-bttn">
                    No
                  </a>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        <div className="login-page main-page p-tb-50 bg-grey">
          <Container>
            <div className="login-grid">
              <div className="inner-block">
                <div
                  className="preview-bttn back-bttn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    class="button back-btn float-none"
                    onClick={(e) => this.handleBack(e)}
                    style={{ margin: "0" }}
                  >
                    <FaAngleDoubleLeft />
                    <span>Back</span>
                  </button>
                </div>
                <Row>
                  <Col xs="6" className="block left-block">
                    <figure className="pic image-fill">
                      {images.map((e, idx) => (
                        <img
                          // src={e.LoginPic}
                          src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                          alt={
                            "Best Travel Nursing Company, Nursing Agency | Travel Nurses Inc-" +
                            idx
                          }
                        />
                      ))}
                    </figure>
                  </Col>
                  <Col xs="6" className="block right-block">
                    <div className="form-sec text-center">
                      <h1 className="title">
                        Login to the Travel Nurse Portal
                      </h1>

                      <p>Enter your details below to continue</p>
                      <Form className="form-block" onSubmit={this.handleSubmit}>
                        <FormGroup>
                          <Label>Phone Number</Label>

                          <NumberFormat
                            format="(###) ###-####"
                            mask=""
                            name="phone"
                            placeholder="Phone Number"
                            onValueChange={this.inputChangedHandlerContact}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={this.state.phone}
                            className="form-control"
                            ref="phone"
                          />
                          <div>
                            {this.state.phoneError ? (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                {this.state.errors["phone"]}
                              </span>
                            ) : null}
                          </div>
                        </FormGroup>
                        {this.state.loginStatus ? (
                          <>
                            <FormGroup>
                              <Label>Password</Label>
                              <Input
                                type="password"
                                name="password"
                                id="Password"
                                placeholder="Password"
                                onChange={this.handleInputChange}
                                ref="password"
                              />
                              <div>
                                {this.state.passwordError ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["password"]}
                                  </span>
                                ) : null}
                              </div>
                            </FormGroup>
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.loginStatus ? (
                          <Button
                            className="button"
                            onClick={this.handleSubmit}
                            disabled={this.state.button_disable}
                          >
                            Login
                            {this.state.isLoading ? (
                              <img
                                src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                alt="No Image"
                                style={{ width: 40, height: 40 }}
                              />
                            ) : (
                              ""
                            )}
                          </Button>
                        ) : (
                          <Button
                            className="button"
                            onClick={this.handlePhoneSubmit}
                            disabled={this.state.button_disable}
                          >
                            Next
                            {this.state.isLoading ? (
                              <img
                                src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                alt="No Image"
                                style={{ width: 40, height: 40 }}
                              />
                            ) : (
                              ""
                            )}
                          </Button>
                        )}
                        {this.state.loginStatus ? (
                          <Link className="forget-psw" to="/forgot-password">
                            Forgot Password
                          </Link>
                        ) : null}
                      </Form>
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errMsg}
                      </span>
                      <div className="account-reg">
                        New to Travel Nurses, Inc. ?&nbsp;
                        <Link to="/registration">
                          <h2 className="main-title-two-sub">Join Us</h2>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, user } = auth;
  return { loading, error, user };
};
export default connect(mapStateToProps, { loginUser, getUserInfo })(LogIn);
