import React, { useEffect, useState } from "react";
import axios from "axios";
import AppConstants from "../AppConstants";
// import stateImages from "../Components/stateImages";
import BlogImg from "../images/blog-img.png";
import { Link } from "react-router-dom";
import { BiRightArrowAlt, BiBookmark } from "react-icons/bi";
import moment from "moment";
import { BsFillBookmarkFill } from "react-icons/bs";
import { NotificationManager } from "react-notifications";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { saveJobAction } from "../actions/saved-jobs";

import ContactRecruiterPopUp from "../Pages/contact_recruiter";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function DashboarddDfault(props) {
  const { popularStories, history } = props;
  let userData1 = JSON.parse(localStorage.getItem("userInfo"));
  let userData2 = JSON.parse(localStorage.getItem("user"));
  // var workItem = localStorage.getItem("workType");
  // var tab = workItem != null ? workItem : "1";
  var userData =
    userData1 != null ? userData1 : userData2.proffesionalInformation;
  const [jobsList, setJobsList] = useState([]);
  const [userInformation, setUserInformation] = useState(userData);
  const [loader, setLoader] = useState(false);
  const [Articles, setArticles] = useState([]);
  const [workType, setWorkType] = useState("1");
  const [model, setModel] = useState(false);

  const settings = {
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSaveJobClick = async (jobsId) => {
    let nurseId = localStorage.getItem("id");
    let user = JSON.parse(localStorage.getItem("userInfo"));
    const Obj = { nurseId: nurseId, jobId: jobsId };
    props.saveJobAction(jobsId);
    await axios
      .post(AppConstants.API + "/nurse/SaveJobAction", {
        nurseId: nurseId,
        jobId: jobsId,
      })
      .then(async (response) => {
        getDashboardJobs(user, nurseId);
        response.data.Status
          ? NotificationManager.success(response.data.msg, "", 3000)
          : NotificationManager.error(response.data.msg, "", 3000);
      });
  };

  useEffect(() => {
    let user1 = JSON.parse(localStorage.getItem("userInfo"));
    let user2 = JSON.parse(localStorage.getItem("user"));
    var user = user1 != null ? user1 : user2.proffesionalInformation;
    let userId = localStorage.getItem("id");
    localStorage.setItem("workType", workType);
    getDashboardJobs(user, userId, workType);
    setLoader(false);
    getDashboardArticles();
  }, [workType]);

  async function getDashboardJobs(user, userId, type) {
    let sp = [];
    let cr = [];
    user &&
      user.specialities &&
      user.specialities.map((item) => {
        item.robotixSpecName !== null && sp.push(item.robotixSpecName);
      });
    user &&
      user.certifications &&
      user.certifications.map((item) => {
        cr.push(item.robotixCertName);
      });

    let tablefilterdata = {
      nurseId: userId ? userId : "",
      page: 1,
      sizeperpage: 10,
      speciality: sp,
      state: "",
      city: "",
      cityFullName: "city",
      cityState: "",
      searchRadius: "",
      duration: "",
      startDate: "",
      toStartDate: "",
      certification: cr,
      pinned: false,
      workType: type,
    };
    await axios
      .post(AppConstants.Jobs_API + "/jobs/getJobs", tablefilterdata)
      .then(async (data) => {
        if (data.data.data.length > 0) {
          setJobsList(data.data.data);
          setLoader(true);
        } else {
          let tablefilterdata = {
            nurseId: userId ? userId : "",
            page: 1,
            sizeperpage: 10,
            speciality: "",
            state: "",
            city: "",
            cityFullName: "city",
            cityState: "",
            searchRadius: "",
            duration: "",
            startDate: "",
            toStartDate: "",
            certification: cr,
            pinned: false,
            workType: type,
          };
          await axios
            .post(AppConstants.Jobs_API + "/jobs/getJobs", tablefilterdata)
            .then((data) => {
              setJobsList(data.data.data);
              setLoader(true);
            });
        }
      });
  }

  const handleSeeAllJobs = () => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    history.push(
      `/?certification=${
        user &&
        user.certifications.map((item) => {
          return item["ctmsCertName"];
        })
      }`
    );
  };

  // &speciality=${
  //   user &&
  //   user.specialities
  //     .map((item) => {
  //       return item["ctmsSpecName"];
  //     })
  //     .join(",")
  // }
  async function getDashboardArticles() {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .post(
        AppConstants.API + "/nurse/getAllArticles",
        {
          page: 1,
          sizeperpage: 2,
        },
        axiosConfig
      )
      .then((data) => {
        setArticles(data.data.data);
      });
  }

  const handleModel = (e) => {
    setModel(!model);
    console.log(e);
  };

  const replaceURL = (url) => {
    if (!url) {
      url =
        "https://travelnurses.s3.us-west-2.amazonaws.com/default-job-image.png";
    }
    const replaced = url.replace(
      "https://travelnurses.s3.us-west-2.amazonaws.com",
      AppConstants.Bucket_URL
    );
    return replaced;
  };

  return (
    <>
      {" "}
      <div className="sub-title">Jobs Recommended for you</div>
      <span>
        Recommendations are based on your{" "}
        <a href="javascript:void()">profile, job preferences, </a>
        and activity on Travel Nurses
      </span>
      <Tabs
        defaultActiveKey={1}
        onSelect={(k) => setWorkType(k)}
        id="uncontrolled-tab-example"
        className="mb-3 job-tab-grid"
      >
        <Tab eventKey="1" title="Contract Jobs">
          {loader ? (
            <div className="list-job profile-cont-grid">
              {jobsList &&
                jobsList.map((item, index) => (
                  <div
                    className="job-info block-box tab-job-info-box"
                    key={index}
                  >
                    <ul className="d-flex">
                      <Link
                        to={{
                          pathname: `/job/${item._id}`,
                        }}
                      >
                        <li className="job-location">
                          {/* <img
                            src={replaceURL(
                              item.stateImages[
                              Math.floor(
                                Math.random() *
                                item.stateImages.length
                              )
                              ]
                            )}
                            alt={
                              item.title +
                              " - " +
                              item.city +
                              " - " +
                              item.state +
                              " - Travel Nurses Inc"
                            }
                          /> */}

                          <img
                            src={replaceURL(
                              item.stateImages[
                                Math.floor(
                                  Math.random() * item.stateImages.length
                                )
                              ]
                            )}
                            alt={
                              item.title +
                              " - " +
                              item.city +
                              " - " +
                              item.state +
                              " - Travel Nurses Inc"
                            }
                          />

                          <h5>
                            {item.city}
                            <br />
                            {item.state}
                          </h5>
                        </li>
                      </Link>
                      <li className="job-position my-auto">
                        <h4>{item.title}</h4>
                        <p>{item.jobSpeciality}</p>
                        <Link
                          to={{
                            pathname: `/job/${item._id}`,
                          }}
                          className="read-more"
                        >
                          View More
                        </Link>
                      </li>
                      <li className="job-time my-auto">
                        <p className="date">
                          Start Date:{" "}
                          <em>
                            {moment(item.start_date).format("MM-DD-YYYY")}
                          </em>
                        </p>
                        <p className="date">
                          End Date:{" "}
                          <em>{moment(item.endDate).format("MM-DD-YYYY")}</em>
                        </p>
                        <p className="time">{item.shift}</p>
                      </li>

                      <li className="btn-section my-auto">
                        <div
                          className={`saved-text ${
                            item.isSaved ? "RemoveJob" : ""
                          }`}
                          onClick={() => handleSaveJobClick(item._id)}
                        >
                          <button>
                            {item.isSaved ? (
                              <BsFillBookmarkFill />
                            ) : (
                              <BiBookmark />
                            )}
                            <span> {item.isSaved ? "Remove" : "Save"}</span>
                          </button>
                        </div>
                        <aside className="view-more-grid">
                          {item.status === "Open" && (
                            <Link
                              to={{
                                pathname: `/job/${item._id}`,
                              }}
                              className="read-more"
                            >
                              View More
                            </Link>
                          )}
                        </aside>
                        <aside className="apply-bttn-grid">
                          <Link
                            to={`/apply-job/${item._id}`}
                            className="button"
                          >
                            Apply
                          </Link>
                        </aside>
                      </li>
                    </ul>
                  </div>
                ))}

              <div className="btn-block">
                <a
                  href="javascript:void()"
                  className="read-more"
                  onClick={handleSeeAllJobs}
                >
                  See all Jobs
                </a>
              </div>
            </div>
          ) : (
            <div className="loader-grid">
              <figure>
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image"
                  style={{ width: 40, height: 40 }}
                />
              </figure>
            </div>
          )}
        </Tab>
        <Tab eventKey="2" title="Permanent Jobs">
          {loader ? (
            <div className="list-job profile-cont-grid">
              {jobsList && jobsList.length > 0 ? (
                jobsList.map((item, index) => (
                  <div
                    className="job-info block-box tab-job-info-box"
                    key={index}
                  >
                    <ul className="d-flex">
                      <Link
                        to={{
                          pathname: `/job/${item._id}`,
                        }}
                      >
                        <li className="job-location">
                          <img
                            src={replaceURL(
                              item.stateImages[
                                Math.floor(
                                  Math.random() * item.stateImages.length
                                )
                              ]
                            )}
                            alt={
                              item.title +
                              " - " +
                              item.city +
                              " - " +
                              item.state +
                              " - Travel Nurses Inc"
                            }
                          />

                          <h5>
                            {item.city}
                            <br />
                            {item.state}
                          </h5>
                        </li>
                      </Link>
                      <li className="job-position">
                        <h4>{item.title}</h4>
                        <p>{item.jobSpeciality}</p>
                        <Link
                          to={{
                            pathname: `/job/${item._id}`,
                          }}
                          className="read-more"
                        >
                          View More
                        </Link>
                      </li>
                      <li className="job-time">
                        <p className="date">
                          Start Date: <em>{item.start_date}</em>
                        </p>
                        <p className="date">
                          End Date: <em>{item.end_date}</em>
                        </p>
                        <p className="time">{item.shiftType}</p>
                      </li>

                      <li className="btn-section">
                        <div
                          className={`saved-text ${
                            item.isSaved ? "RemoveJob" : ""
                          }`}
                          onClick={() => handleSaveJobClick(item._id)}
                        >
                          <button>
                            {item.isSaved ? (
                              <BsFillBookmarkFill />
                            ) : (
                              <BiBookmark />
                            )}
                            <span> {item.isSaved ? "Remove" : "Save"}</span>
                          </button>
                        </div>
                        <aside className="view-more-grid">
                          {item.status === "Open" && (
                            <Link
                              to={{
                                pathname: `/job/${item._id}`,
                              }}
                              className="read-more"
                            >
                              View More
                            </Link>
                          )}
                        </aside>
                        <aside className="apply-bttn-grid">
                          <Link
                            to={`/apply-job/${item._id}`}
                            className="button"
                          >
                            Apply
                          </Link>
                        </aside>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <span className="no-data">
                  Sorry, looks like we don’t have any of those jobs available at
                  the moment. <br />
                  Jobs refresh hourly, so let's find you a match!
                  <br />
                  {/* <Button onClick={this.handlePopUp.bind(this)}> */}
                  <Button onClick={(e) => handleModel(e)}>
                    Submit a request to be notified for openings
                  </Button>
                  {model ? (
                    <ContactRecruiterPopUp
                      workType={workType}
                      modal={model}
                      speciality={userInformation.specialities}
                      certification={userInformation.certifications}
                      city={""}
                      state={""}
                      searchText={""}
                      selectedDurationShifts={[]}
                      searchRadius={""}
                      dateRegion={""}
                      title="Requesting Job Match"
                      handleModal={handleModel}
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}

              {jobsList.length > 0 && (
                <div className="btn-block">
                  <a
                    href="javascript:void()"
                    className="read-more"
                    onClick={handleSeeAllJobs}
                  >
                    See all Jobs
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className="loader-grid">
              <figure>
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image"
                  style={{ width: 40, height: 40 }}
                />
              </figure>
            </div>
          )}
        </Tab>
        <Tab eventKey="3" title="PRN Jobs">
          {loader ? (
            <div className="list-job profile-cont-grid">
              {jobsList && jobsList.length > 0 ? (
                jobsList.map((item, index) => (
                  <div
                    className="job-info block-box tab-job-info-box"
                    key={index}
                  >
                    <ul className="d-flex">
                      <Link
                        to={{
                          pathname: `/job/${item._id}`,
                        }}
                      >
                        <li className="job-location">
                          <img
                            src={replaceURL(
                              item.stateImages[
                                Math.floor(
                                  Math.random() * item.stateImages.length
                                )
                              ]
                            )}
                            alt={
                              item.title +
                              " - " +
                              item.city +
                              " - " +
                              item.state +
                              " - Travel Nurses Inc"
                            }
                          />

                          <h5>
                            {item.city}
                            <br />
                            {item.state}
                          </h5>
                        </li>
                      </Link>
                      <li className="job-position">
                        <h4>{item.title}</h4>
                        <p>{item.jobSpeciality}</p>
                        <Link
                          to={{
                            pathname: `/job/${item._id}`,
                          }}
                          className="read-more"
                        >
                          View More
                        </Link>
                      </li>
                      <li className="job-time">
                        <p className="date">
                          Start Date: <em>{item.start_date}</em>
                        </p>
                        <p className="date">
                          End Date: <em>{item.end_date}</em>
                        </p>
                        <p className="time">{item.shiftType}</p>
                      </li>

                      <li className="btn-section">
                        <div
                          className={`saved-text ${
                            item.isSaved ? "RemoveJob" : ""
                          }`}
                          onClick={() => handleSaveJobClick(item._id)}
                        >
                          <button>
                            {item.isSaved ? (
                              <BsFillBookmarkFill />
                            ) : (
                              <BiBookmark />
                            )}
                            <span> {item.isSaved ? "Remove" : "Save"}</span>
                          </button>
                        </div>
                        <aside className="view-more-grid">
                          {item.status === "Open" && (
                            <Link
                              to={{
                                pathname: `/job/${item._id}`,
                              }}
                              className="read-more"
                            >
                              View More
                            </Link>
                          )}
                        </aside>
                        <aside className="apply-bttn-grid">
                          <Link
                            to={`/apply-job/${item._id}`}
                            className="button"
                          >
                            Apply
                          </Link>
                        </aside>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <span className="no-data">
                  Sorry, looks like we don’t have any of those jobs available at
                  the moment. <br />
                  Jobs refresh hourly, so let's find you a match!
                  <br />
                  {/* <Button onClick={this.handlePopUp.bind(this)}> */}
                  <Button onClick={(e) => handleModel(e)}>
                    Submit a request to be notified for openings
                  </Button>
                  {model ? (
                    <ContactRecruiterPopUp
                      workType={workType}
                      modal={model}
                      speciality={userInformation.specialities}
                      certification={userInformation.certifications}
                      city={""}
                      state={""}
                      searchText={""}
                      selectedDurationShifts={[]}
                      searchRadius={""}
                      dateRegion={""}
                      title="Requesting Job Match"
                      handleModal={handleModel}
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}

              {jobsList.length > 0 && (
                <div className="btn-block">
                  <a
                    href="javascript:void()"
                    className="read-more"
                    onClick={handleSeeAllJobs}
                  >
                    See all Jobs
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className="loader-grid">
              <figure>
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image"
                  style={{ width: 40, height: 40 }}
                />
              </figure>
            </div>
          )}
        </Tab>
      </Tabs>
      <div className="news-block">
        <div className="sub-title">Popular Articles</div>
        <div className="row justify-content-center">
          {Articles &&
            Articles.length > 0 &&
            Articles.map((item) => {
              return (
                <div className="blog-block col-lg-6 col-md-6">
                  <div className="blog-block-in">
                    <div className="img-grid">
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          props &&
                          props.history &&
                          props.history.push({
                            pathname: "/article-detail/",
                            state: item,
                          })
                        }
                      >
                        <img
                          src={
                            item.yoast_head_json &&
                            item.yoast_head_json.og_image &&
                            item.yoast_head_json.og_image[0] &&
                            item.yoast_head_json.og_image[0].url
                              ? item.yoast_head_json.og_image[0].url
                              : BlogImg
                          }
                          alt={""}
                        />
                      </a>
                      <span class="category">A Nurse's Voice</span>
                    </div>
                    <div className="blog-cont">
                      <div className="author-block row">
                        <div className="author-grid col-sm-7">
                          <figure>
                            <img
                              src={
                                item.yoast_head_json &&
                                item.yoast_head_json.og_image &&
                                item.yoast_head_json.og_image[0] &&
                                item.yoast_head_json.og_image[0].url
                                  ? item.yoast_head_json.og_image[0].url
                                  : BlogImg
                              }
                              alt={""}
                            />
                          </figure>
                          <span>{item.yoast_head_json.author}</span>
                        </div>
                        <div className="date col-sm-5">
                          <span>
                            {moment(item && item.date).format("MM-DD-YYYY")}
                          </span>
                        </div>
                      </div>
                      <h2>
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            props &&
                            props.history &&
                            props.history.push({
                              pathname: "/article-detail/",
                              state: item,
                            })
                          }
                        >
                          {" "}
                          {item && item.title && item.title.rendered
                            ? item.title.rendered.length > 35
                              ? item.title.rendered
                                  .replace("&#8217;", "'")
                                  .substring(0, 35) + "..."
                              : item.title.rendered.replace("&#8217;", "'")
                            : ""}
                        </a>
                      </h2>
                      <a
                        class="blog-read-more"
                        href="javascript:void(0)"
                        className="blue-bttn"
                        onClick={() =>
                          props &&
                          props.history &&
                          props.history.push({
                            pathname: "/article-detail/",
                            state: item,
                          })
                        }
                      >
                        <span style={{ fontFamily: "Figtree Black" }}>
                          Read More
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}

          <div class="btn-block">
            <a class="read-more" href={AppConstants.Word_Press_API + "/blog"}>
              See all Articles
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = ({ savedJob }) => {
  const { loading, dashboardSaveAction, savedJobs } = savedJob;
  return { loading, dashboardSaveAction, savedJobs };
};
export default connect(mapStateToProps, { saveJobAction })(DashboarddDfault);
