import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function CookiesBanner(props) {
  const [showCookiesBanner, setShowCookiesBanner] = useState(true);
  const [showPopupModal, setShowPopupModal] = useState(false);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem('cookies_accepted');
    setShowCookiesBanner(!acceptedCookies);
    props.onDisplayToggle(!acceptedCookies);
  }, []);

  const handleAcceptCookies = () => {
    setShowCookiesBanner(false);
    props.onDisplayToggle(false);
    Cookies.set('cookies_accepted', 'true', { expires: 30 });
    localStorage.setItem('cookies_accepted', 'true');
  };

  const handlePrivacyPolicyLink = (event) => {
    event.preventDefault();
    setShowPopupModal(true);
  };

  const handleClosePopup = () => {
    setShowPopupModal(false);
  };

  const handleCloseCookiesBanner = () => {
    setShowCookiesBanner(false);
    props.onDisplayToggle(false);
  };

  return (
    <>
      {showPopupModal && (
        <div id="popup-modal">
          <div class="popup-content">
            <h2>Cookie Policy</h2>
            <p>
              Please take a moment to review our cookie policy. By using this site, you consent to our use of your
              information as set forth below. The contents of this notice may change over time. Please visit this page
              to view it in its current form.
            </p>
            <h3>Do we use cookies?</h3>
            <p>
              Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive
              through your web browser, enabling the site's or service provider's systems to recognize your browser and
              capture and remember certain information.
            </p>
            <p>
              We use cookies to compile aggregate data about site traffic and site interaction so that we can offer
              better site experiences and tools in the future. We may contract with third-party service providers to
              assist us in better understanding our site visitors. These service providers are not permitted to use the
              information collected on our behalf except to help us conduct and improve our business.
            </p>
            <p>
              We may also release your information when we believe that its release is appropriate to comply with the
              law, enforce our site policies, or protect ours or others' rights, property or safety.
            </p>
            <h3>Your consent</h3>
            <p>By using our site, you consent to our website cookie policy.</p>
            <button id="ok-button" onClick={handleClosePopup}>
              OK
            </button>
            <button id="close-button" onClick={handleClosePopup}>
              ×
            </button>
          </div>
        </div>
      )}
      {!showPopupModal && showCookiesBanner && (
        <div>
          <p>
            {' '}
            We use cookies to improve your experience.Please read our
            <a id="privacy-policy-link" href="#" onClick={handlePrivacyPolicyLink}>
              {' '}
              Cookie Policy.
            </a>{' '}
            or Click{' '}
            <button id="accept-cookies" onClick={handleAcceptCookies}>
              Accept
            </button>
          </p>
          <button id="close-banner" onClick={handleCloseCookiesBanner}>
            ×
          </button>
        </div>
      )}
    </>
  );
}

export default CookiesBanner;
