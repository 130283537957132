import React from "react";
import { Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
class RigThankYou extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <Helmet>
          <link
            rel="icon"
            type="image/gif"
            href="https://px.ads.linkedin.com/collect/?pid=7677049&fmt=gif"
            sizes="1x1"
          />
        </Helmet>
        <Container>
          <div className="login-grid">
            <div className="inner-block">
              <Row>
                <div className="success-reg text-center py-5 w-100">
                  <h1 className="mb-0" style={{ color: "#25408f" }}>
                    Thank you,
                  </h1>

                  <h2 style={{ color: "#21ade4" }}>
                    we received your registration
                  </h2>
                  <p className="my-4" style={{ color: "#25408f" }}>
                    Login to the portal to complete the next steps!
                    <br />
                    The nurse portal will give you access to your tailored job
                    openings, quick apply and more.
                  </p>

                  <a class="sky-bttn" href="/login">
                    LOGIN NOW
                  </a>
                </div>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default RigThankYou;
