import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Row, Card, CardTitle, Button, Col } from "reactstrap";
 
import { BiSad } from "react-icons/bi";
class Error extends React.Component {
  render() {
    return (
      <div
        className="error-block text-center"
      // className="login-page main-page p-tb-50 bg-grey"
      >
        <div className="not-found-page">
          <Container>
            <div className="not-found-page-in">
              <BiSad />
              {/* <h1>404 - Page not found</h1> */}
              <p>The job you are searching for has already been filled.</p>
              <a
                href="https://jobs.travelnursesinc.com/"
                className="blue-bttn"
              >
                {" "}
                Click here to view all jobs. 
              </a>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Error;
