import React from "react";
import { Container, Row, Col } from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";
import styles from "../Styles/make-easy.css";
import axios from "axios";
import AppConstants from '../AppConstants';
import LazyLoad from 'react-lazyload';

class Infoblock extends React.Component {
  constructor() {
    super();
    this.state = {
      info: [],
    }
  }
  componentDidMount() {
    let data1 = [];

    axios

      .get(AppConstants.CMS_API + "/weMakeEasy/allMakeEasyfromJsonFile")
      .then(res => {
        return res.data;
      }).then(data => {

        if (data.Status === "Success") {
          data.data.map((item) => {
            var obj = {};
            if (item.type === "find") {
              obj['title'] = item.title;
              obj['description'] = item.description;
              obj['link'] = "/";
              obj['icon'] = `${AppConstants.Bucket_URL}/bannerImages/adventure_awaits_910172163.png`;
              data1[0] = obj;
            } else if (item.type === "pack") {
              obj['title'] = item.title;
              obj['description'] = item.description;
              obj['link'] = "/benefits";

              obj['icon'] = `${AppConstants.Bucket_URL}/bannerImages/benefits_page_910172163.png`;
              data1[1] = obj;
            } else {
              obj['title'] = item.title;
              obj['description'] = item.description;
              obj['link'] = "/";

              obj['icon'] = `${AppConstants.Bucket_URL}/bannerImages/apply_globe_910172163.png`;
              data1[2] = obj;
            }
          })
          this.setState({ info: data1 })
        }
      }
      )
  }

  render() {
    return (
      <div className="info-block pad-tb text-center">
        <Container>
          <div className="title">{AppConstants.Make_Easy}</div>
          <p>{AppConstants.Sub_Make_Easy}</p>
          <Row className="inner-content  justify-content-center">
            {this.state.info.map((item) => (
              <Col xs="4" id={item.id} className="block">
                <div className="block-box">
                  <LazyLoad>
                    <figure className="pic">
                      <img src={item.icon} alt={item.title + " - Travel Nurses Inc"} />
                    </figure>
                  </LazyLoad>
                  <div className="text">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    {item.link === "https://packtor.com/#home" ? <a href="https://packtor.com" target="_blank">Click Here</a> : <Link to={item.link} className="read-more">
                      Click Here
                    </Link>}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}
export default Infoblock;
