import React from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { JSONLD, Generic } from "react-structured-data";
import { Helmet } from "react-helmet";
import { getCountries } from "../Components/helpers/network-calls";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
;


class ApplyJobHome extends React.Component {
  /* add class function */
  constructor(props) {
    super(props);
    this.state = {
      applyData: null,
      contact_methods: [
        { text: "Phone", value: "phone", isChecked: false },
        { text: "Text", value: "text", isChecked: false },
        { text: "Email", value: "email", isChecked: false },
      ],
      certifications: [
        { text: "RN", value: "RN", isChecked: false },
        {
          text: "Surgical Technician",
          value: "Surgical Technician",
          isChecked: false,
        },
        {
          text: "Allied Health Professional",
          value: "Allied Health Professional",
          isChecked: false,
        },
        { text: "Other", value: "Other", isChecked: false },
      ],
      timings: [
        { text: "Morning", value: "Morning", isChecked: false },
        { text: "Afternoon", value: "Afternoon", isChecked: false },
        { text: "Evening", value: "Evening", isChecked: false },
      ],
      check_certifications: [],
      check_contact_methods: [],
      check_timings: [],
      firstName: "",
      lastName: "",
      code: "+1",
      phone: "",
      email: "",
      speciality: "",
      errors: [],
      errorsCode: {},
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phone_error: false,
      certificationsError: false,
      contact_methodsError: false,
      timingsError: false,
      codeError: false,
      specialityError: false,
      errMsg: "",
      isLoading: false,
      jobProviderId: "",
      location: "",
      startDate: "",
      endDate: "",
      shift: "",
      positions: "",
      jobTitle: "",
      referralSources: [],
      referralSource: "",
      referralBy: "",
      referralSourceError: false,
      referredByError: false,
      otherReferralSourceError: false,
      respStatus: false,
      recruiters: [],
      recruiter: "",
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      userCerts: [],
      multipleSpecs: [],
      countries: [],
    };
    this.handleContactMethod = this.handleContactMethod.bind(this);
    this.handleCertifications = this.handleCertifications.bind(this);
    this.handleTimings = this.handleTimings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  inputChangedHandlerContact = (values) => {
    console.log(values, "values");
    this.setState({
      phone: values.value,
    });
    if (values.value.length === 10) {
      this.setState({ phone_error: false });
    }
  };
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.getAllCountries();
    this.setState({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.primaryEmail : "",
      phone: user ? user.primaryPhoneNumber : "",
      speciality: user
        ? user.specialities
            .map((item) => {
              return item["ctmsSpecName"];
            })
            .join(",")
        : "",
      userCerts: user
        ? user.certifications.map((item) => {
            return item["ctmsCertName"];
          })
        : "",
    });

    const myArrayFiltered = this.state.certifications.filter((array) => {
      if (user !== null) {
        user.certifications.some((filter) => {
          if (filter.ctmsCertName === array.value) {
            array.isChecked = true;
            this.setState({ check_certifications: [array.value] });
          }
        });
      }
    });
    if (
      (this.props,
      window.innerWidth >= 320 && this.props,
      window.innerWidth <= 767)
    ) {
      this.setState({ respStatus: true });
    }
    if (this.props.location.state) {
      if (this.props.location.state.jobName) {
        this.setState({ jobTitle: this.props.location.state.jobName });
      }
    }
    axios
      .get(AppConstants.CMS_API + "/applyJob/allApplyJobMetaTags")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          if (data.data[0]) {
            this.setState({
              metaTitle: data.data[0].metaTitle,
              metaDescription: data.data[0].metaDescription,
              metaTags: data.data[0].metaTags,
              ogTitle: data.data[0].ogTitle,
              ogDescription: data.data[0].ogDescription,
            });
          }
        }
      });
    axios
      .get(AppConstants.Jobs_API + "/specialities/getReferralSources")
      .then((res) => {
        if (res.data.Status === "Success") {
          var dataVal = [
            ...res.data.data,
            { referralSource: "Employee Referral" },
            { referralSource: "Nurse Referral" },
          ];
          var output = dataVal.sort((a, b) =>
            a.referralSource.localeCompare(b.referralSource)
          );
          var finalArray = [...output, { referralSource: "Other" }];
          this.setState({ referralSources: finalArray });
        }
      });

    axios.get(AppConstants.API + "/users/getRecruitersListApp").then((res) => {
      this.setState({
        recruiters: res.data.data.sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        ),
      });
    });
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }
    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "speciality") {
      if (e.target.value === "") {
        this.setState({ specialityError: true });
      } else {
        this.setState({ specialityError: false });
      }
    }
  };
  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }
  handleTimings(event) {
    var options = [];
    let timings = this.state.timings;
    timings.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.timings.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });

    this.setState({ timings: timings, check_timings: options });
  }
  handleContactMethod(event) {
    var options = [];
    let contact_methods = this.state.contact_methods;
    contact_methods.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.contact_methods.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ contact_methodsError: true });
    } else {
      this.setState({ contact_methodsError: false });
    }
    this.setState({
      contact_methods: contact_methods,
      check_contact_methods: options,
    });
  }
  handleCertifications(event) {
    var options = [];
    let certifications = this.state.certifications;
    certifications.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.certifications.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ certificationsError: true });
    } else {
      this.setState({ certificationsError: false });
    }
    this.setState({
      certifications: certifications,
      check_certifications: options,
    });
  }
  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "referralSource") {
      if (e.target.value === "") {
        this.setState({ referralSourceError: true });
      } else {
        this.setState({ referralSourceError: false });
      }
    }
    if (e.target.name === "referralBy") {
      if (e.target.value === "") {
        this.setState({ referredByError: true });
      } else {
        this.setState({ referredByError: false });
      }
    }
    if (e.target.name === "otherReferralSource") {
      if (e.target.value === "") {
        this.setState({ otherReferralSourceError: true });
      } else {
        this.setState({ otherReferralSourceError: false });
      }
    }
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.referralBy) {
      formIsValid = false;
      errors["referredBy"] = "Please enter referral name";
      ReactDOM.findDOMNode(this.refs.referralBy).focus();
    }
    if (this.state.referralSource === "Other") {
      if (!this.state.otherReferralSource) {
        formIsValid = false;
        errors["otherReferralSource"] = "Please enter other referral source";
        ReactDOM.findDOMNode(this.refs.otherReferralSource).focus();
      }
    }
    if (!this.state.referralSource) {
      formIsValid = false;
      errors["referralSource"] = "Please select referral source";
      ReactDOM.findDOMNode(this.refs.referralSource).focus();
    }
    if (!this.state.speciality) {
      formIsValid = false;
      errors["speciality"] = "Please enter specialty";
      ReactDOM.findDOMNode(this.refs.speciality).focus();
    }
    if (this.state.check_certifications.length === 0) {
      formIsValid = false;
      errors["certifications"] = "Please select certifications";
      ReactDOM.findDOMNode(this.refs.certifications).focus();
    }

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Please enter 10 digits phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    if (this.state.check_contact_methods.length === 0) {
      formIsValid = false;
      errors["contact_methods"] = "Please select contact method";
      ReactDOM.findDOMNode(this.refs.contact_methods).focus();
    }
    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter last name.";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter first name";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
      certificationsError: true,
      contact_methodsError: true,
      // timingsError : true,
      // codeError : true,
      specialityError: true,
      referralSourceError: true,
      referredByError: true,
      otherReferralSourceError: true,
    });
    return formIsValid;
  }
  handleSubmit(e) {
    // console.log("hello",this.state.jobTitle)
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      const formData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        contact_methods: JSON.stringify(this.state.check_contact_methods),
        timings: JSON.stringify(this.state.check_timings),
        certifications: JSON.stringify(this.state.check_certifications),
        email: this.state.email,
        phone: this.state.phone
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        code: this.state.code,
        speciality: this.state.speciality,
        jobTitle: this.state.jobTitle,
        referralBy: this.state.referralBy,
        referralSource:
          this.state.referralSource === "Other"
            ? this.state.otherReferralSource
            : this.state.referralSource,
        recruiter: this.state.recruiter,
      };
      // console.log(formData);
      axios
        .post(AppConstants.Jobs_API + "/jobs/addApplicantDetails", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            setTimeout((e) => {
              if (this.state.jobTitle) {
                if (this.state.jobTitle === "LPN Orlando") {
                  this.props.history.push("/lpn-orlando-job");
                } else {
                  this.props.history.push(
                    "/travel-nurse-job/" + this.state.jobTitle
                  );
                }
              } else {
                this.props.history.push("/");
              }
            }, 4000);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  render() {
    return (
      <div className="apply-form registr-info main-page">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Webpage"
            schema={{
              name: this.state.metaTitle,
              description: this.state.metaDescription,
              contentReferenceTime: new Date(),
            }}
          ></Generic>
        </JSONLD>
        <Helmet>
          <title>Travel Nurses, Inc. | Apply Now</title>
          <meta name="title" content={this.state.metaTitle} />
          <meta name="description" content={this.state.metaDescription} />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={AppConstants.Base_Url + "/apply-job"}
          />
          <meta property="og:title" content={this.state.ogTitle} />
          <meta property="og:description" content={this.state.ogDescription} />
          <meta property="og:image" content={this.state.image} />

          <meta property="twitter:card" content={this.state.image} />
          <meta
            property="twitter:url"
            content={AppConstants.Base_Url + "/apply-job"}
          />
          <meta property="twitter:title" content={this.state.metaTitle} />
          <meta
            property="twitter:description"
            content={this.state.metaDescription}
          />
          <meta property="twitter:image" content={this.state.image} />
        </Helmet>
        <Container>
          <div className="registration-form">
            <div className="title">Travel Nurses, Inc.</div>
            <p className="text-center">
              {/* We're looking for amazing Medical Travelers. Are you ready to see
              the country? */}
              Ready to explore the nation while making a difference in
              healthcare? Apply now and join our team of amazing healthcare
              professionals!
            </p>
            <Form className="inner-block" onSubmit={this.handleSubmit}>
              <Row>
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="FirstName">
                      First Name
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      id="FirstName"
                      value={this.state.firstName}
                      onBlur={() => this.state.firstName}
                      onChange={this.handleChange}
                      ref="firstName"
                    />
                    <span>
                      {this.state.firstNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["firstName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>

                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="Last Name">
                      Last Name
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onBlur={() => this.state.lastName}
                      placeholder="Last Name"
                      id="LastName"
                      onChange={this.handleChange}
                      ref="lastName"
                    />
                    <span>
                      {this.state.lastNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["lastName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <div className="form-block">
                <h4>
                  Preferred Contact Method
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <div className="check-sec">
                  {this.state.contact_methods.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="contact_methods"
                          id={planet.value}
                          onChange={this.handleContactMethod}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="contact_methods"
                        ></Input>
                        <Label for={planet.value} className="check-field">
                          {planet.text}
                        </Label>
                      </span>
                    );
                  })}
                  <div>
                    {this.state.contact_methodsError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errors["contact_methods"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <Row className="codephone-blk">
                  <Col xs="1" className="block small-block country-code col-1">
                    <FormGroup>
                      <Label for="Code">Country Code</Label>
                      <Input
                        type="select"
                        value={this.state.code}
                        name="code"
                        ref="code"
                        onChange={this.handleCodeChange}
                        id="Code"
                      >
                        {this.state.countries.map((item) => {
                          return (
                            <option
                              data-countryCode={item.phoneCode}
                              value={"+" + item.phoneCode}
                            >
                              {item.phoneCode === 1 ? "USA" : item.name}{" "}
                              {"+" + item.phoneCode}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="5" className="block widht-40 col-5 phone-input">
                    <FormGroup>
                      <Label for="Phone">
                        Phone
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="phone"
                        id="Phone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact}
                        value={this.state.phone}
                        onBlur={() => this.state.phone}
                        className="form-control"
                        ref="phone"
                      />
                      <span>
                        {this.state.phone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <span className="code-validation" id="mbl-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  <Col xs="5" className="block" id="mail-field">
                    <FormGroup>
                      <Label for="Email">
                        Email
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="Email"
                        value={this.state.email}
                        onBlur={() => this.state.email}
                        onChange={this.handleChange}
                        ref="email"
                      />
                      <span>
                        {this.state.emailError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["email"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <span className="code-validation col" id="desk-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </Row>
              </div>
              <div className="form-block">
                <h4>
                  What's the best time to contact you?
                  {/* <span className = "error" style={{color: 'red'}}>*</span> */}
                </h4>
                <div className="check-sec">
                  {this.state.timings.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="timings"
                          id={planet.value}
                          onChange={this.handleTimings}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="timings"
                        ></Input>
                        <Label for={planet.value} className="check-field">
                          {planet.text}
                        </Label>
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="form-block">
                <h4>
                  What is your certification?
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <div className="check-sec">
                  {this.state.certifications.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="certifications"
                          id={planet.value}
                          onChange={this.handleCertifications}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="certifications"
                        ></Input>
                        <Label for={planet.value} className="check-field">
                          {planet.text}
                        </Label>
                      </span>
                    );
                  })}
                  <div>
                    {this.state.certificationsError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errors["certifications"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <Row className="form-block">
                <Col xs="6" className="block">
                  <FormGroup>
                    <h4>
                      What is your specialty?
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </h4>
                    <Input
                      type="text"
                      name="speciality"
                      placeholder="MedSurg, OR, ER, L&D, etc..."
                      value={this.state.speciality}
                      onBlur={() => this.state.speciality}
                      onChange={this.handleChange}
                      ref="speciality"
                    />
                    <div>
                      {this.state.specialityError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["speciality"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <h4>Referral Information</h4>
              <Row>
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="ReferralSource">
                      Referral Source<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="select"
                      name="referralSource"
                      id="ReferralSource"
                      value={this.state.referralSource}
                      onChange={this.handleInputChange}
                      ref="referralSource"
                      className="custom-dropdown"
                    >
                      <option value="">Select</option>
                      {this.state.referralSources.map((ele, index) => {
                        return (
                          <option id={index} value={ele.referralSource}>
                            {ele.referralSource}
                          </option>
                        );
                      })}
                    </Input>
                    <span>
                      {this.state.referralSourceError ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["referralSource"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
                {this.state.referralSource === "Other" ? (
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="OtherReferralSource">
                        Other Referral Source
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="otherReferralSource"
                        id="OtherReferralSource"
                        placeholder="Other Referral Source"
                        value={this.state.otherReferralSource}
                        onChange={this.handleInputChange}
                        ref="otherReferralSource"
                      />
                      <span>
                        {this.state.otherReferralSourceError ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["otherReferralSource"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="ReferralBy">
                      Referred By
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="referralBy"
                      id="ReferralBy"
                      placeholder="Referred By"
                      value={this.state.referralBy}
                      onChange={this.handleInputChange}
                      ref="referralBy"
                    />
                    <span
                      style={
                        this.state.respStatus
                          ? { color: "#46ad4d", fontSize: "11px" }
                          : { color: "#46ad4d" }
                      }
                    >
                      If you’re not referred by a Nurse/Employee, enter “N/A”
                    </span>
                    <br />
                    <span>
                      {this.state.referredByError ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["referredBy"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {" "}
                <FormGroup>
                  <Label>
                    You need at least one year of recent licensed nursing
                    experience to work as a traveling nurse with TNI. Once you
                    have your license and experience, we would be excited to
                    work with you.
                  </Label>{" "}
                </FormGroup>
              </Row>
              <h4>Recruiter Information</h4>
              <Row>
                <Col xs="6" className="block">
                  <FormGroup>
                    <Label for="RecruiterSelect">
                      Do you have a recruiter you want to work with ?
                    </Label>
                    <Input
                      type="select"
                      name="recruiter"
                      id="RecruiterSelect"
                      className="custom-dropdown"
                      value={this.state.recruiter}
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select</option>
                      {this.state.recruiters.map((item, index) => {
                        return (
                          <option value={item.label} id={index}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-center">
              <Col md="12">
                <button
                  class="btn btn-prev button"
                  onClick={() => this.props.history.go(-1)}
                >
                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                  Back
                </button>
                <button
                  class="btn btn-next button"
                  title="Submit"
                  onClick={this.handleSubmit}
                  disabled={this.state.button_disable}
                  style={{ backgroundColor: "#334ca4" }}
                >
                  Submit{" "}
                  {this.state.isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </Row>
            {this.state.errMsg}
          </div>
        </Container>
      </div>
    );
  }
}

export default ApplyJobHome;
