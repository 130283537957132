import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import YouTube from "react-youtube";
import AppStoreImage from "../images/app-store-img.png";
import GoogleStoreImage from "../images/google-store-img.png";
import NurseSlideOne from "../images/nurse-app/nurse_slide_1.png";
import NurseSlideTwo from "../images/nurse-app/nurse_slide_2.png";
import NurseSlideThree from "../images/nurse-app/nurse_slide_3.png";


const NurseApp = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errList, setErrlist] = useState({
    fname: "",
    fnameErr: false,
    lname: "",
    lnameErr: false,
  });
  const isMobile = window.innerWidth <= 768;
  const opts = {
    height: "100%",
    width: "100%",
  };
  const onReady = () => {
    setIsLoading(false);
  };

  const handleFChange = (e) => {
    setErrlist({ ...errList, fname: '', fnameErr: false });
    setFirstName(e.target.value);
  };
  const handleLChange = (e) => {
    setErrlist({ ...errList, lname: '', lnameErr: false });
    setLastName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((firstName != "") & (lastName != "")) {
      props.history.push(`registration/fname=${firstName}&lname=${lastName}`);
    } else {
      if (firstName == "") {
        setErrlist({
          ...errList,
          fname: "Please enter your first name",
          fnameErr: true,
        });
      } else if (lastName == "") {
        setErrlist({
          ...errList,
          lname: "Please enter your last name",
          lnameErr: true,
        });
      }
    }
  };

  return (
    <section className="nurse-app refer-friend">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" sm="12">
            <h1
              className="text-center mt-5"
              style={{ color: "#25408f" }}>
              Everything in One Place
            </h1>
            <p className="text-center">
              When you register, you'll get access to a tailored job-matching system that uses advanced technology to match you with the most relevant job opportunities based on your skills, experience, qualifications, and desired location. We prioritize your privacy and never sell your information.
              <br />
              Our user-friendly platform makes it easy to apply for jobs with just one click.<br />
              Register now and start exploring healthcare opportunities today.</p>


            <div className="video-container mb-5 mt-3">
              {/* {isLoading ? <LoadingSpinner /> : null} */}
              <div className="loading-spinner-container">
                {isLoading ? <div className="loading-spinner"></div> : null}
              </div>
              <YouTube
                videoId="nN1dUU3YE3I"
                opts={opts}
                onReady={onReady}
                className={`youtube-video ${!isLoading ? "show" : ""}`}
              />
            </div>
          </Col>

          <div className="w-100" />
          <div className="d-flex justify-content-center">
            <a href="https://apps.apple.com/us/app/travel-nurses-inc/id1637482608">
              <figure className="mr-0 mr-md-5">
                <img
                  src={AppStoreImage}
                  alt="IOS Store"
                  className="img-fluid"
                />
              </figure>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.travelnurses&pli=1">
              <figure>
                <img
                  src={GoogleStoreImage}
                  alt="App Store"
                  className="img-fluid"
                />
              </figure>
            </a>
          </div>
        </Row>
      </Container>
      <div className="form-refer-friend login-page main-page mt-5 pb-3">
        <div className="wrapper">
          <Container className="py-5">
            <Form className="form-block" onSubmit={handleSubmit}>
              <Row className="justify-content-around">
                <Col md="12" className="text-center pb-4">
                  <h2 className="text-white">
                    Register Now and Start Your Adventure
                  </h2>

                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label for="firstName" className="text-white">
                      First Name
                    </Label>
                    <Input
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={handleFChange}
                      className="bg-white"
                    />
                  </FormGroup>
                  {errList.fnameErr ? (
                    <span className="error" style={{ color: "red" }}>
                      {errList.fname}
                    </span>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label for="lastName" className="text-white">
                      Last Name
                    </Label>
                    <Input
                      id="lastName"
                      type="text"
                      value={lastName}
                      onChange={handleLChange}
                      className="bg-white"
                    />
                  </FormGroup>
                  {errList.lnameErr ? (
                    <span className="error" style={{ color: "red" }}>
                      {errList.lname}
                    </span>
                  ) : (
                    ""
                  )}
                </Col>

                <Col md={12} className="text-center mt-3">
                  <Button className="classic-button-type_1" color="link">
                    Register Now
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </div>
      <Container className="py-5">
        <Row className="justify-content-around ">
          <Col className="mb-3 mb-md-5 col-12 col-xl-5 col-md-6 col-sm-12">
            <figure>
              <img
                src={NurseSlideOne}
                alt="nurse"
                className="img-fluid w-100"
              />
            </figure>
          </Col>
          <Col className="my-auto col-12 col-xl-5 col-md-6 col-sm-12">
            <h2 style={{ color: "#25408f" }}>
              Tailored Job Pairing
            </h2>
            <p>
              Nurses can now take advantage of our new and improved app and
              website portal to find the perfect assignment. Our in-depth search
              tool allows Travel Nurses to quickly identify their ideal job
              opportunity, with a wide range of filters to narrow down their
              results. Travel Nursing professionals can save their favorite
              jobs, access salary information, upload documents and credentials,
              and even complete an onboarding checklist!
            </p>
          </Col>
          <div className="w-100" />
          {!isMobile ? (
            <>
              <Col className="my-auto col-12 col-xl-5 col-md-6 col-sm-12">
                <h2 style={{ color: "#25408f" }}>
                  Establish your profile and Revise your credentials
                </h2>
                <p>
                  Travel Nurses can also easily keep track of their hours and
                  submit timesheets. Our Nurse Portal makes finding and managing
                  Travel Nursing jobs more accessible than ever before, so
                  Travel Nurses can focus on what matters most - optimizing
                  patient care.
                </p>
              </Col>
              <Col className="col-12 col-xl-5 col-md-6 col-sm-12">
                <figure>
                  <img
                    src={NurseSlideTwo}
                    alt="nurse"
                    className="img-fluid w-100"
                  />
                </figure>
              </Col>
            </>
          ) : (
            <>
              <Col className="col-12 col-xl-5 col-md-6 col-sm-12">
                <figure>
                  <img
                    src={NurseSlideTwo}
                    alt="nurse"
                    className="img-fluid w-100"
                  />
                </figure>
              </Col>
              <Col className="my-auto col-12 col-xl-5 col-md-6 col-sm-12">
                <h2 style={{ color: "#25408f" }}>
                  Establish your profile and Revise your credentials
                </h2>
                <p>
                  Travel Nurses can also easily keep track of their hours and
                  submit timesheets. Our Nurse Portal makes finding and managing
                  Travel Nursing jobs more accessible than ever before, so
                  Travel Nurses can focus on what matters most - optimizing
                  patient care.
                </p>
              </Col>
            </>
          )}

          <div className="w-100" />
          <Col className="mt-3 mt-md-5 col-12 col-xl-5 col-md-6 col-sm-12">
            <figure>
              <img
                src={NurseSlideThree}
                alt="nurse"
                className="img-fluid w-100"
              />
            </figure>
          </Col>
          <Col className="my-auto col-12  col-xl-5 col-md-6 col-sm-12">
            <h2 style={{ color: "#25408f" }}>
              Streamlined Application
            </h2>
            <p>
              You can easily apply for travel nurse jobs and allied health jobs
              with one click! Our innovative Auto-Apply feature ensures a quick
              and seamless experience, from landing your dream job to
              hassle-free onboarding, timekeeping. and support. Say goodbye to
              job searching headaches and hello to excitement and ease with our
              portal!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NurseApp;
