import React from "react";
import {
  Container,

  Col,

  Form,
  FormGroup,

} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";

class ViewStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      image: "",
      video: "",
      nurseId: "",
      userId: "",
      stories: []
    }
  }
  componentDidMount() {
    const that = this;
    axios.get(AppConstants.API + "/nurseStory/getSingleStory/" + this.props.match.params.id).then((res) => {
      return res.data;
    }).then(function (data1) {
      console.log("data1 data1", data1)
      if (data1.Status === "Success") {
        console.log("data1 data1", data1)
        that.setState({
          description: data1.story.description,
          image: data1.story.image,
          video: data1.story.video
        })
        axios.get("https://ipapi.co/json").then((res) => {
          return res.data;
        }).then((data) => {
          var data2 = {
            viewerDetails: data,
            nurseId: data1.data[0].nurseId,
            storyId: data1.story._id
          };
          axios
            .post(AppConstants.API + "/nurseStory/nurseStoryView", data2)
            .then((res) => {
              return res.data;
            })
            .then((data4) => {
              if (data4.Status) {
                console.log(data4.Status);
              }
            });
        });
      }
    });

  }

  render() {
    return (
      <div className="profile-view main-page p-tb-50 bg-grey">
        <Container>
          <div className="registration-form">
            <div className="title">View Story</div>
            <button class="button back-btn" onClick={() => this.props.history.go(-1)}>
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              Back
            </button>
            <Form
              name="my-story"
              className="view-info  my-story profile-info border-pad"
            >
              <span className="content-sec">
                {this.state.description ?
                  <Col xs="12" className="block">
                    <FormGroup>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.description,
                        }}
                      />
                    </FormGroup>
                  </Col> : ""}
                {this.state.image ?
                  <Col xs="12" className="block">
                    <FormGroup>
                      <img src={this.state.image} style={{ width: 300, height: 200 }} alt={"Nurse Story -  Travel Nurses Inc - "} />
                    </FormGroup>
                  </Col> : ""}
                {this.state.video ?
                  <Col xs="12" className="block">
                    <FormGroup>
                      <iframe
                        src={this.state.video}
                        frameborder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </FormGroup>
                  </Col>
                  : ""}
              </span>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default ViewStory;