import React from 'react';
import BlogImg from '../images/blog-img.png';
import moment from 'moment';
import { FaAngleDoubleLeft } from 'react-icons/fa';
function StoriesDetailPage(props) {
  let id = localStorage.getItem('id');
  return (
    <section className="stories-inner-sec">
      <div className="container">
        <div className="stories-inner-sec-in">
          <figure>
            <img
              src={
                props && props.location && props.location.state && props.location.state.nurseStory
                  ? props.location.state.nurseStory.image
                  : BlogImg
              }
              alt={''}
            />
          </figure>
          <div className="author-block row" id="author-detail-page">
            <div className="author-grid">
              <figure>
                <img
                  src={props && props.location && props.location.state ? props.location.state.profileImage : ''}
                  alt={''}
                />
              </figure>
              <span>
                {props && props.location && props.location.state ? props.location.state.firstName : 'Author Name'}
              </span>
            </div>
            <div className="date">
              <span className="pl-3">
                {moment(
                  props && props.location && props.location.state && props.location.state.nurseStory
                    ? props.location.state.nurseStory.createDate
                    : ''
                ).format('MM-DD-YYYY')}
              </span>
            </div>
          </div>
          <h2
            dangerouslySetInnerHTML={{
              __html:
                props &&
                props.location &&
                props.location.state &&
                props.location.state.nurseStory &&
                props.location.state.nurseStory.title
                  ? props.location.state.nurseStory.title.replace('&#8217;', "'")
                  : '',
            }}
          />
          <p
            class="mb-0"
            dangerouslySetInnerHTML={{
              __html:
                props &&
                props.location &&
                props.location.state &&
                props.location.state.nurseStory &&
                props.location.state.nurseStory.description
                  ? props.location.state.nurseStory.description
                  : '',
            }}
          />
        </div>
        <div className="preview-bttn">
          <button class="button back-btn float-none" onClick={() => props.history.push('/profile/' + id)}>
            <FaAngleDoubleLeft />
            <span>Back To Dashboard</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default StoriesDetailPage;
