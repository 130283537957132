import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Components/footer";
import axios from "axios";
import AppConstants from "../AppConstants";

function Unsubscribe() {
  const [status, setStatus] = useState(null);
  const { nurseId } = useParams();

  const handleUnsubscribe = async () => {
    try {
      setStatus("loading");
      const result = await axios.get(
        `${AppConstants.API}/nurse/UnsubscribeNurseByAdmin/${nurseId}`
      );
      console.log(result, "result");
      if (result.data.Status == "Success") {
        setStatus("Success");
      } else {
        setStatus("Failure");
      }
    } catch (err) {
      setStatus("Failure");
    }
  };

  const handleCancel = () => {
    window.location.href = "https://jobs.travelnursesinc.com/";
  };



  let renderContent;

  if (status === "Success") {
    renderContent = (
      <div className="success-message d-flex flex-column justify-content-center align-items-center">
        <p className="text-success">You have been successfully unsubscribed.</p>
        <p className="text-info">
          If you want to resubscribe, please contact the admin.
        </p>
      </div>
    );
  } else if (status === "Failure") {
    renderContent = (
      <div className="failure-message">
        <p className="text-danger">
          Issue with Unsubscribing! Please try again later.
        </p>
      </div>
    );
  } else if (status === "loading") {
    renderContent = <div className="loading-spinner"></div>;
  } else {
    renderContent = (
      <div
        className=" p-3 mt-4 text-center"
        style={{
          maxWidth: "fit-content",
          width: "70%",
          borderColor: "black",
          borderRadius: "8px",
          borderWidth: "1px", // Increase the border width
          borderStyle: "solid",
        }}
      >
        <h5 style={{ color: "#25408f" }}>Unsubscribe</h5>
        <p>Are you sure you want to unsubscribe?</p>
        <div className="d-flex gap-3 justify-content-center">
          <button className="btn btn-secondary mx-2" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="btn mx-2"
            style={{ backgroundColor: "#25408f", color: "#FFF" }}
            onClick={handleUnsubscribe}
          >
            Unsubscribe
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      {/* Header */}
      <header
        className="d-flex justify-content-center align-items-center text-white"
        style={{ backgroundColor: "#25408f", height: "100px", width: "100%" }}
      >
        <img

          src="https://df8de9x9ieyx9.cloudfront.net/Footer/TN-Logo.png"
          alt="Logo"
          className="img-fluid"
          style={{ maxWidth: "200px" }}
        />
      </header>

      {/* Box with text and buttons */}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >

        {renderContent}
      </div>
      <Footer />
    </div>
  );
}

export default Unsubscribe;
