import React  from "react";
  
import { Input, Container } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
 
import Pagination1 from "./pagination";
import Slider from "react-slick";
import $ from "jquery";

class Propertis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fur: false,
      util: "",
      budget: "",
      pets: false,
      ptype: "",
      start: 5,
      count: 5,
      sort: "",
      dir: "",
      propertiesInfo: [],
      location: "",
      states: [],
      cities: [],
      stateName: "",
      cityName: "",
      citiesCopy: [],
      propertiesInfoMsg: "",
      propertiesDataStatus: true,
      dropDownOpen: "",
      bedRoom: "",
      totalPage: "",
      pageNumber: "",
      totalItemCount: "",
      popularFlag: false,
      popularFilterState: "",
      popularFilterCity: "",
      validationClass: "",
      label: "",
    };

    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handleCitySelect = this.handleCitySelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleBedRooms = this.handleBedRooms.bind(this);
    this.handlePropertyType = this.handlePropertyType.bind(this);
    this.handleFurniture = this.handleFurniture.bind(this);
    this.handlePets = this.handlePets.bind(this);
    this.filterFurniture = this.filterFurniture.bind(this);
    this.filterFurnitureNY = this.filterFurnitureNY.bind(this);
    this.filterFurnitureSF = this.filterFurnitureSF.bind(this);
    this.filterFurnitureDallas = this.filterFurnitureDallas.bind(this);
    this.filterFurnitureMemphis = this.filterFurnitureMemphis.bind(this);
    this.filterFurnitureOrleans = this.filterFurnitureOrleans.bind(this);
  }

  filterFurniture = (e) => {
    this.setState({ stateName: "Florida", cityName: "Miami Beach" }, () =>
      this.handleSearch(e)
    );
  };

  filterFurnitureNY = (e) => {
    this.setState({ stateName: "New York", cityName: "New York" }, () =>
      this.handleSearch(e)
    );
  };

  filterFurnitureSF = (e) => {
    this.setState({ stateName: "California", cityName: "San Francisco" }, () =>
      this.handleSearch(e)
    );
  };

  filterFurnitureDallas = (e) => {
    this.setState({ stateName: "Texas", cityName: "Dallas" }, () =>
      this.handleSearch(e)
    );
  };

  filterFurnitureMemphis = (e) => {
    this.setState({ stateName: "Tennessee", cityName: "Memphis" }, () =>
      this.handleSearch(e)
    );
  };

  filterFurnitureOrleans = (e) => {
    this.setState({ stateName: "Louisiana", cityName: "New Orleans" }, () =>
      this.handleSearch(e)
    );
  };

  // }
  handleFurniture(e) {
    // console.log("line46",e.target.name, "name",e.target.value,"value",e.target.checked)
    this.setState({ [e.target.name]: e.target.checked, label: "" });
  }
  handlePets(e) {
    // console.log("line50",e.target.name, "name",e.target.value,"value",e.target.checked)
    this.setState({ [e.target.name]: e.target.checked, label: "" });
  }
  handlePropertyType(e) {
    this.setState({ [e.target.name]: e.target.value, label: "" });
  }
  handleBedRooms(event) {
    // console.log(event.target.name,"line42",event.target.innerText,"geloo",event.target.value)
    this.setState({
      bedRoom: event.target.value,
    });
  }

  onChangePage = (page) => {
    console.log(page, "hello");
    this.setState({ start: page });
    this.getPropertiesInfoData(
      this.state.stateName,
      this.state.cityName,
      this.state.fur,
      this.state.util,
      this.state.budget,
      this.state.pets,
      this.state.ptype,
      page,
      this.state.count,
      this.state.sort,
      this.state.dir
    );
    // this.setState({
    //   start: page,
    // });
  };

  handleSearch(e) {
    if (this.state.cityName === "" || this.state.stateName === "") {
      this.state.validationClass = "filter-validation";
    } else {
      this.state.validationClass = " ";
    }

    e && e.preventDefault();
    this.state.cityName &&
      this.state.stateName &&
      this.getPropertiesInfoData(
        this.state.stateName,
        this.state.cityName,
        this.state.fur,
        this.state.util,
        this.state.budget,
        this.state.pets,
        this.state.ptype,
        this.state.start,
        this.state.count,
        this.state.sort,
        this.state.dir
      );
  }

  handleCitySelect(e) {
    this.setState({ [e.target.name]: e.target.value, label: "" });
  }

  handleStateSelect(e) {
    this.setState({ [e.target.name]: e.target.value, label: "" });
    if (e.target.value) {
      axios
        .get(
          AppConstants.CMS_API +
            "/properties/getCitiesBasedOnStateName/" +
            e.target.value
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({ cities: data.data });
          } else {
            this.setState({ cities: this.state.citiesCopy });
          }
        });
    }
  }
  componentDidMount() {
    console.log("did mount called");

    this.state.cityName && this.state.stateName && this.handleSearch();
    const that = this;

    if (this.props.location.state) {
      if (this.props.location.state.stateName) {
        // console.log(this.props,"this.props",this.props.location.state,"this.props.location.state",this.props.location.state.stateName,"this.props.location.state.stateName",this.props.location.state.cityName)
        axios
          .get(
            AppConstants.CMS_API +
              "/properties/getStateNameBasedOnStateCode/" +
              this.props.location.state.stateName
          )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            if (data.Status === "Success") {
              // console.log(this.props.location.state.cityName,"hello",data.data[0].name)
              // this.setState({ states: data.data });
              // this.setState({stateName: data.data[0].name,cityName: this.props.location.state.cityName})
              this.setState(
                {
                  stateName: data.data[0].name,
                  cityName: this.props.location.state.cityName,
                },
                () => {
                  // console.log(this.state.dealersOverallTotal, 'dealersOverallTotal1');
                  this.getPropertiesInfoData(
                    this.state.stateName,
                    this.state.cityName,
                    this.state.fur,
                    this.state.util,
                    this.state.budget,
                    this.state.pets,
                    this.state.ptype,
                    this.state.start,

                    this.state.sort,
                    this.state.dir
                  );
                }
              );
            }
          });
      } else {
        this.getPropertiesInfoData(
          this.state.stateName,
          this.state.cityName,
          this.state.fur,
          this.state.util,
          this.state.budget,
          this.state.pets,
          this.state.ptype,
          this.state.start,
          this.state.count,
          this.state.sort,
          this.state.dir
        );
      }
    }
    // else {
    //   this.setState({ propertiesInfoMsg: "Please select state and city." });
    // }
    axios
      // .get(AppConstants.CMS_API + "/properties/getAllStates")
      .get(AppConstants.CMS_API + "/properties/allStatesfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({ states: data.data });
        }
      });
    axios
      // .get(AppConstants.CMS_API + "/properties/getAllCities")
      .get(AppConstants.CMS_API + "/properties/allCitiesfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          this.setState({ cities: data.data, citiesCopy: data.data });
        }
      });
  }
  getPropertiesInfoData(
    stateName,
    cityName,
    fur,
    util,
    budget,
    pets,
    ptype,
    start,
    count,
    sort,
    dir
  ) {
    const that = this;
    const formData = {
      fur: fur,
      util: util,
      budget: budget,
      pets: pets,
      ptype: ptype,
      start: 1,
      count: 10,
      sort: sort,
      dir: dir,
    };
    const formData2 = {
      fur: this.state.fur,
      util: this.state.util,
      budget: this.state.budget,
      pets: this.state.pets,
      ptype: this.state.ptype,
      start: 5,
      count: 5,
      sort: this.state.sort,
      dir: this.state.dir,
    };
    if (stateName && cityName) {
      this.setState({ propertiesDataStatus: false });
      axios
        .post(
          AppConstants.Partners_API +
            "/furnishedfinder-partners-map-list/" +
            cityName +
            "/" +
            stateName,
          formData2
        )
        .then((res) => {
          if (res.status === 200 && res.data.value.length > 0) {
            if (res.data.value.length > 0) {
              const total_pages = Math.ceil(
                res.data.value.length / this.state.count
              );

              console.log("total_pages", total_pages);
              that.setState({
                propertiesInfo: res.data.value,
                propertiesDataStatus: true,
                totalPage: total_pages,
                totalItemCount: res.data.value.length,
              });
            } else {
              that.setState({
                propertiesInfoMsg: "Properties are not available",
                propertiesDataStatus: true,
              });
            }
          } else {
            that.setState({
              propertiesInfoMsg: "Properties are not available",
              propertiesDataStatus: true,
              label: "No Properties Found",
            });
          }
        })
        .catch((e) => {
          that.setState({
            propertiesInfoMsg: "Properties not available",
            propertiesDataStatus: true,
          });
        });
      // axios
      //   .post(
      //     "https://uatpartners.travelnursesinc.com/furnishedfinder-partners-map-list/" +
      //     this.state.cityName +
      //     "/" +
      //     this.state.stateName,
      //     formData2
      //   )
      //   .then((res) => {
      //     if (res.status === 200 && res.data.value.length > 0) {
      //       const total_pages = Math.ceil(
      //         res.data.value.length / this.state.count
      //       );
      //       that.setState({
      //         totalPage: total_pages, totalItemCount: res.data.value.length,
      //       });
      //     } else {
      //       that.setState({
      //         propertiesInfoMsg: "Properties are not available",
      //       });
      //     }
      //   })
      //   .catch((e) => {
      //     that.setState({
      //       propertiesInfoMsg: "Properties not available",
      //       propertiesDataStatus: true,
      //     });
      //   });
    } else {
      that.setState({ propertiesInfoMsg: "Please select state and city." });
    }
  }
  toggle = () => {
    this.setState({
      dropDownOpen: !this.state.dropDownOpen,
    });
  };
  handlePageClick = (data) => {
    this.onChangePage(data.selected + 1);
  };
  preopertyClass() {
    $(".search-bar").toggleClass("bar-open");
  }

  render() {
    // console.log(this.state.cityName,"city name")

    window.scrollTo(0, 0);
    const settings3 = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    

    //.search-block .form-block .form-filed, input, select = {height: '50px'}
    let height = { height: "50px" };

    return (
      <div className="properties">
        <div className="filter-blk">
          {/* <a
            href="javascript:void(0)"
            class="find-area"
            onClick={this.preopertyClass}
          >
            Filters
          </a> */}
          <div
            className="search-block search-bar bar-open"
            id="furnished-search-block"
          >
            <Container>
              <figure className="brand-pic image-fill">
                <img
                  // src={BrandLogo}
                  src={`${AppConstants.Bucket_URL}/furnished/property-logo.png`}
                  alt="Furnished Finder"
                />
              </figure>
              <form className="form-block text-center">
                <Input
                  type="select"
                  name="stateName"
                  className="form-filed drop-arrow icon"
                  value={this.state.stateName}
                  onChange={this.handleStateSelect}
                  style={height}
                >
                  <option value="">State</option>
                  {this.state.states.map((item, index) => {
                    return <option value={item.name}> {item.name} </option>;
                  })}
                </Input>

                <Input
                  type="select"
                  name="cityName"
                  className="form-filed drop-arrow icon"
                  onChange={this.handleCitySelect}
                  value={this.state.cityName}
                  style={height}
                >
                  <option value="">City </option>
                  {this.state.cities.map((item, index) => {
                    return <option value={item.name}> {item.name} </option>;
                  })}
                </Input>

                <Input
                  type="select"
                  name="ptype"
                  value={this.state.ptype}
                  className="form-filed drop-arrow icon house-icon"
                  onChange={this.handlePropertyType}
                  style={height}
                >
                  <option value="">Type</option>
                  <option value="own">Full properties</option>
                  <option value="room">Shared Rooms only</option>
                  <option value="hotel">Hotels only</option>
                  <option value="nohotel">No Hotels</option>
                </Input>

                <div
                  className="form-filed icon furnitured-icon form-action "
                  style={height}
                >
                  <Input
                    type="checkbox"
                    name="fur"
                    id="furnitured"
                    value={this.state.fur}
                    onClick={this.handleFurniture}
                  />
                  <label for="furnitured"></label>
                </div>

                <div
                  className="form-filed icon pet-icon form-action  "
                  style={height}
                >
                  <Input
                    type="checkbox"
                    name="pets"
                    id="pets"
                    value={this.state.pets}
                    onClick={this.handlePets}
                  />
                  <label for="pets"></label>
                </div>

                <button
                  type="submit"
                  className="button"
                  onClick={this.handleSearch}
                >
                  Search
                </button>
              </form>
              {this.state.cityName === "" || this.state.stateName === "" ? (
                <p className={this.state.validationClass}>
                  Please select state and city.
                </p>
              ) : (
                ""
              )}
            </Container>
          </div>
        </div>
        {this.state.propertiesDataStatus === true ? (
          <div className="main-content">
            <Container>
              {this.state.propertiesInfo.length > 0 ? (
                <span className="properties-search-results" id="search-result">
                  <button
                    className={
                      this.state.propertiesInfo.length > 0
                        ? "button back-btn"
                        : "button back-btn no-props-back"
                    }
                    onClick={() => (window.location.href = "/furnished-finder")}
                  >
                    Back
                  </button>
                  {this.state.cityName !== "" &&
                  this.state.stateName !== "" &&
                  this.state.totalItemCount ? (
                    <h4 style={{ color: "#25408f" }}>
                      {this.state.totalItemCount == 1
                        ? "Results: " +
                          this.state.totalItemCount +
                          " Property Found"
                        : "Results: " +
                          +this.state.totalItemCount +
                          " Properties Found"}
                    </h4>
                  ) : (
                    "No Properties Found"
                  )}
                  {this.state.cityName !== "" &&
                    this.state.stateName !== "" &&
                    this.state.propertiesInfo.map((item) => (
                      <div className="properties-blk block-box">
                        <div className="inner-text">
                          <div className="content">
                            <div className="left-block properties-blk-left-slider">
                              <Slider {...settings3}>
                                {item.images.map((imageFile, imgIndex) => {
                                  return (
                                    <figure
                                      className="pic image-fill"
                                      id={imgIndex}
                                    >
                                      <a href={item.url} target="_blank">
                                        <img
                                          src={imageFile.url}
                                          alt={
                                            item.name +
                                            " - Furnished Finder - " +
                                            imgIndex
                                          }
                                        />
                                      </a>
                                    </figure>
                                  );
                                })}
                              </Slider>
                            </div>
                            <div className="right-block">
                              <div className="prop-list">
                                <h4>{item.name}</h4>
                                <h3>{item.price}</h3>
                                <ul>
                                  <li className="list city">
                                    {this.state.cityName +
                                      ", " +
                                      this.state.stateName}
                                  </li>
                                  <li className="list bed">{item.bedrooms}</li>
                                  <li className="list home"> {item.type}</li>
                                  <li className="list bath">
                                    {item.bathrooms}
                                  </li>
                                  {/* <li className="list car">{item.parking}</li> */}
                                  {/* <li className="list water"> {item.water}</li> */}
                                  {item.available ? (
                                    <li className="list check">
                                      {" "}
                                      {item.available}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li className="list pet">
                                    {" "}
                                    {item.pets === "True" ? "Yes" : "No"}{" "}
                                  </li>
                                  <li className="list car">
                                    {item.furnished === "True" ? "Yes" : "No"}
                                  </li>
                                  <li className="list wifi">
                                    {" "}
                                    {item.utilities === "True" ? "Yes" : "No"}
                                  </li>
                                  {/* <li className="list wifi"> {item.wifi}</li>      
                                                <li className="list city">{item.city}</li>
                                                <li className="list bed">{item.beds}</li>
                                                <li className="list home"> {item.PropType}</li>
                                                <li className="list bath">{item.bath}</li>
                                                <li className="list car">{item.parking}</li>
                                                <li className="list water"> {item.water}</li>
                                                <li className="list check"> {item.postdate}</li>
                                                <li className="list pet"> {item.pets}</li>
                                          <li className="list wifi"> {item.wifi}</li>    */}
                                </ul>
                              </div>
                            </div>
                            <div className="clear"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* <ReactPaginate
                      breakLabel={"....."}
                      breakClassName={"break-me page-link false mx-0"}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      // onPageChange={this.onChangePage(i)}
                      containerClassName={"pagination pagination-blk d-flex"}
                      subContainerClassName={"pages pagination page-item "}
                      disabledClassName={"disabledcustom-pagi"}
                      previousLinkClassName={"prve-next  page-link"}
                      nextLinkClassName={"prve-next  page-link"}
                      activeClassName={"active"}
                      pageClassName={"false page-link"}
                      pageLinkClassName={"anchor class"}
                    /> */}
                  {this.state.cityName !== "" &&
                    this.state.stateName !== "" && (
                      <Pagination1
                        aria-label="Page navigation"
                        className="pagination-blk"
                        currentPage={this.state.start}
                        totalPage={this.state.totalPage}
                        onChangePage={(i) => this.onChangePage(i)}
                      />
                    )}
                </span>
              ) : (
                <h3 style={{ textAlign: "center" }}>{this.state.label}</h3>
              )}
            </Container>
          </div>
        ) : (
          <div className="list-job pb-50 loader-center">
            {" "}
            <img
              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
              alt="No Image"
              className="loader-center"
              style={{ width: 80, height: 80 }}
            />{" "}
          </div>
        )}
        <section className="popular-search-sec">
          <Container>
            <div className="popular-search-blocks">
              <div style={{ textAlign: "center" }}>
                {" "}
                <h1 className="main-title-one">
                  Find Your Perfect Travel Nurse Furnished Housing
                </h1>
                <h2 className="main-title-two">
                  Discover the Benefits of Furnished Finder for Traveling Nurses
                </h2>
              </div>
              <div className="row">
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurniture}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg1}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-1.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">Miami Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurnitureNY}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg2}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-2.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">New York City Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurnitureSF}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg3}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-3.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">San Francisco Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurnitureDallas}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg4}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-4.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">Dallas Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurnitureMemphis}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg5}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-5.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">Memphis Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
                <div className="popular-search-grid col-md-4 col-sm-6">
                  <div
                    className="popular-search-grid-in"
                    onClick={this.filterFurnitureOrleans}
                    type="button"
                  >
                    <figure>
                      <img
                        // src={cityImg6}
                        src={`${AppConstants.Bucket_URL}/furnished/city-img-6.jpg`}
                        alt="No Image"
                        className="loader-center"
                      />
                    </figure>
                    <h2>
                      <a href="#">New Orleans Travel Nurse Housing</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default Propertis;
