import React from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { getUserInfo } from "../actions/authentication";
import { connect } from "react-redux";
import {
  BiBookmark,
  BiSpreadsheet,
  BiFileFind,
  BiFile,
  BiPen,
  BiBrain,
  BiBookBookmark,
  BiBookmarkAltPlus,
  BiUserVoice,
} from "react-icons/bi";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import DashboarddDfault from "./dashboard-default";
import Documents from "./documents";
import Stories from "./stories";
import StoriesDetailPage from "./stories-detail";
import Contracts from "./contracts";
import SavedJobs from "./saved-jobs";
import HandBook from "./handbook";
import TimesheetSection from "./timesheet-section";
import ScheduleDocsSection from "./scheduleDocs-section";
import ExpiringDocs from "./expiringDocs";
import styles from "../Styles/profile.css";

class Profile extends React.Component {
  constructor(props, context) {
    super(props, context);
    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      activeTab: "personal-tab",
      fullName: "",
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.email : "",
      phone: user ? user.phone : "",
      comments: "",
      questions: "",
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      commentsError: false,
      state: "",
      city: "",
      recruiter: user ? user.recruiter : "",
      jobList: [],
      nurseId: user ? user.id : "",
      nurseStory: "",
      profilePic: "",
      popularStories: [],
      setPasswordDisplay: "",
      options: [],
      checkListItems: [],
      modal: false,
      currentUser: {},
      hrDocsURL: "",
      skillsURL: "",
      testingURL: "",
      colorClassName: "",
      percentage: "",
      profilePercentage: 0,
      nextStep: "",
      count: 0,
      handbookStatus: "",
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTimesheet = this.handleTimesheet.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.generateSkillsURL = this.generateSkillsURL.bind(this);
    this.generateTestURL = this.generateTestURL.bind(this);
    this.generateDocumentsURL = this.generateDocumentsURL.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleTimesheet() {
    this.props.history.push("/timesheets");
  }
  async componentDidMount() {
    let userId = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    await axios
      .get(
        AppConstants.API + "/nurse/nurseProfilePersentage/" + userId,
        axiosConfig
      )
      .then((res) => {
        // console.log("res23", res);
        var newStatus = [];
        var array = [];
        this.setState({
          profilePercentage: res.data.data.overAllCountPersentage,
          handbookStatus: res.data.data.empHandbookStatus,
        });
        newStatus = [
          ...res.data.data.personalInformation.finalPersonalStatus,
          ...res.data.data.preferances.finalPreferancesStatus,
          ...res.data.data.experiances.finalExperiancesStatus,
          ...res.data.data.acknowledgement.finalAcknowledgementStatus,
        ];
        newStatus.length > 0 &&
          newStatus.map((item) => {
            if (item.profileInformation == "Incompleted") {
              array.push("Profile Information");
            } else if (item.homeAddress == "Incompleted") {
              array.push("Home Address");
            } else if (item.emergency == "Incompleted") {
              array.push("Emergency Info.");
            } else if (item.referal == "Incompleted") {
              array.push("Referral Info.");
            } else if (item.work == "Incompleted") {
              array.push("Work Preferences");
            } else if (item.shift == "Incompleted") {
              array.push("Shift Preferences");
            } else if (item.eduction == "Incompleted") {
              array.push("Experiance");
            } else if (item.workExperiance == "Incompleted") {
              array.push("Experiance");
            } else if (item.references == "Incompleted") {
              array.push("Experiance");
            } else if (item.acknowledgement == "Incompleted") {
              array.push("Acknowledgement");
            }
          });
        this.setState({ nextStep: array[0] });
      });

    setTimeout(() => {
      this.getPersentage();
    }, 1000);
  }

  getPersentage() {
    if (this.state.profilePercentage <= 20) {
      this.setState({ colorClassName: "color-1", percentage: "20%" });
    } else if (
      this.state.profilePercentage > 21 &&
      this.state.profilePercentage <= 40
    ) {
      this.setState({ colorClassName: "color-2", percentage: "40%" });
    } else if (
      this.state.profilePercentage > 41 &&
      this.state.profilePercentage <= 60
    ) {
      this.setState({ colorClassName: "color-3", percentage: "60%" });
    } else if (
      this.state.profilePercentage > 61 &&
      this.state.profilePercentage <= 80
    ) {
      this.setState({ colorClassName: "color-4", percentage: "80%" });
    } else if (
      this.state.profilePercentage > 81 &&
      this.state.profilePercentage <= 100
    ) {
      this.setState({ colorClassName: "color-5", percentage: "100%" });
    }
  }
  componentWillMount() {
    // let user = JSON.parse(localStorage.getItem("userInfo"));

    // this.setState({
    //   firstName: user ? user.firstName : "",
    //   lastName: user ? user.lastName : "",
    //   email: user ? user.primaryEmail : "",
    //   phone: user ? user.primaryPhoneNumber : "",
    // });

    let id = localStorage.getItem("id");
    this.props.getUserInfo(id, this.props.history);
  }

  async generateSkillsURL() {
    var newWindow = window.open();
    let id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };

    await axios
      .post(
        AppConstants.API + "/nurse/getCtmsWebUrl",
        {
          pageName: "Skills",
          nurseId: id,
          device: "web",
        },
        axiosConfig
      )
      .then((res) => {
        let encoded = encodeURI(res.data.url);
        // window.open(encoded, "_blank");
        newWindow.location = encoded;
      });
  }

  async generateTestURL() {
    let id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    await axios
      .post(
        AppConstants.API + "/nurse/getCtmsWebUrl",
        {
          pageName: "Testing",
          nurseId: id,

          device: "web",
        },
        axiosConfig
      )
      .then((res) => {
        let encoded = encodeURI(res.data.url);
        window.open(encoded, "_blank");
      });
  }

  async generateDocumentsURL() {
    var newWindow = window.open();
    let id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    await axios
      .post(
        AppConstants.API + "/nurse/getCtmsWebUrl",
        {
          pageName: "Documents/Home",
          nurseId: id,
          device: "web",
        },
        axiosConfig
      )
      .then((res) => {
        let encoded = encodeURI(res.data.url);
        newWindow.location = encoded;
        // window.open(encoded, "_blank");
      });
  }

  handleEditProfile(e) {
    this.props.history.push("/view-profile/");
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""), //Trim Leading and Trailing
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }
    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "phone") {
      if (e.target.value === "") {
        this.setState({ phoneError: true });
      } else {
        this.setState({ phoneError: false });
      }
    }
    if (e.target.name === "comments") {
      if (e.target.value === "") {
        this.setState({ commentsError: true });
      } else {
        this.setState({ commentsError: false });
      }
    }
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    // if (this.state.emailCheck == true) {
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    // }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter your valid email address.";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }

    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter your last name.";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter your first name.";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (!this.state.comments) {
      formIsValid = false;
      errors["comments"] = "Please enter your comments.";
      ReactDOM.findDOMNode(this.refs.comments).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phoneError: true,
      commentsError: true,
    });
    return formIsValid;
  }
  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  clickHandbook = (e) => {
    e.preventDefault();
    this.setState({ count: this.state.count + 1 })

  };
  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      let formData = {
        actionType: this.state.nurseId ? "loginUser" : "guestUser",
        nurseId: this.state.nurseId ? this.state.nurseId : "",
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        questions: this.state.comments,
      };
      const token = localStorage.getItem("token");
      var config = {
        method: "POST",
        url: AppConstants.Jobs_API + "/jobs/sendMailForContactMyRecruiter",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: formData,
      };

      axios(config).then((res) => {
        if (res.data.Status === "Success") {
          this.setState({ button_disable: true });
          setTimeout((e) => {
            this.toggle();
          }, 4000);
          setTimeout(() => {
            this.setState({
              isLoading: false,
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }, 2000);
        } else {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }, 1000);
          setTimeout(() => this.setState({ errMsg: "" }), 2000);
        }
      });
    }
  }
  handleToggle = () => {
    // this.setState({
    //   modalOpen: !this.state.modalOpen
    // });
    this.setState(
      {

      },
      () => this.dataListRender()
    );
  };
  dataListRender() {
    let userId = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(
        AppConstants.API + "/nurse/nurseProfilePersentage/" + userId,
        axiosConfig
      )
      .then((res) => {
        console.log("res23", res);
        this.setState({
          handbookStatus: res.data.data.empHandbookStatus,
        });
      });
  }
  handleTabSelect = (key) => {
    console.log('activeTab', key)
    this.setState({ activeTab: key });
  };
  render() {
    const settings = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const token = localStorage.getItem("token");
    const { activeTab } = this.state;

    if (token) {
      return (
        <div
          className="profile-block latestjobs profile-search"
          id="profile-dashboard"
        >
          <div className="inner-block p-tb-50">
            <Container>
              <Row>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="personal-tab"
                  activeKey={activeTab}
                  onSelect={this.handleTabSelect}
                >
                  {this.state.setPasswordDisplay}
                  <Col xs="3" className="left-block">
                    <ul className="block-box profile-edit">
                      <li className="profile">
                        <div className="block">
                          <figure className="profile-pic">
                            {this.props &&
                              this.props.userInfo &&
                              this.props.userInfo.data &&
                              this.props.userInfo.data.profileImage ? (
                              <img
                                src={
                                  this.props &&
                                  this.props.userInfo &&
                                  this.props.userInfo.data &&
                                  this.props.userInfo.data.profileImage
                                }
                                alt={""}
                              />
                            ) : (
                              <img src={`${AppConstants.Bucket_URL}/bannerImages/profile-thumb.jpg`} alt="profile-thmb" />
                            )}
                          </figure>
                          <div className="profile-text">
                            <div className="sub-title">
                              {this.props &&
                                this.props.userInfo &&
                                this.props.userInfo.data &&
                                this.capitalizeFirstLetter(
                                  this.props.userInfo.data.firstName
                                )}{" "}
                              {this.props &&
                                this.props.userInfo &&
                                this.props.userInfo.data &&
                                this.capitalizeFirstLetter(
                                  this.props.userInfo.data.lastName
                                )}
                            </div>
                            {this.state.profilePercentage == "100" ? (
                              ""
                            ) : (
                              <p>Next step : {this.state.nextStep}</p>
                            )}
                          </div>
                          <div className="">
                            <div className="profile-updated-grid">
                              <p className="skill css">
                                <span
                                  className={`profile-perc ${this.state.colorClassName
                                    ? this.state.colorClassName
                                    : ""
                                    }`}
                                  style={{
                                    width: `${this.state.percentage
                                      ? this.state.percentage
                                      : ""
                                      }`,
                                  }}
                                ></span>
                                <span className="level">
                                  {this.state.profilePercentage}%
                                </span>
                              </p>
                              <button
                                className="button"
                                disabled={this.state.userId ? true : false}
                                onClick={this.handleEditProfile.bind(this)}
                              >
                                Edit Your Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="tab-drop-list">
                        <div className="stories-cat">
                          <div className="select select-wrapper">
                            <select name="format" id="format" class="select">
                              <option value="Approved">Saved Jobs</option>
                              <option value="Pending">Timesheet</option>
                              <option value="Rejected">Documents</option>
                              <option value="Rejected">
                                Onboarding Documents
                              </option>
                              <option value="Rejected">Contracts</option>
                              <option value="Rejected">Skills Checklist</option>
                              <option value="Rejected">Tests</option>
                              {/* <option value="Rejected">Stories</option> */}
                              <option value="Rejected">Refer a Friend </option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li className="profile-list">
                        <div className="list-block">
                          <Nav variant="pills" className="flex-column">
                            <aside>
                              <Nav.Link
                                eventKey="savedJobs"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiBookmark />
                                </figure>
                                <span>Saved Jobs</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="timesheet"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiSpreadsheet />
                                </figure>
                                <span>Timesheet</span>
                              </Nav.Link>
                            </aside>
                            <aside>
                              <Nav.Link
                                eventKey="schedule"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiSpreadsheet />
                                </figure>
                                <span>Schedule</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="documents"
                                href="#dashboard-scroll"
                                className="d-flex"
                              >
                                <figure>
                                  <BiFileFind />
                                </figure>
                                <span>Upload your Credentials</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="expiring"
                                href="#dashboard-scroll"
                                className="d-flex"
                              >
                                <figure>
                                  <BiSpreadsheet />
                                </figure>
                                <span>Expiring Credentials</span>
                              </Nav.Link>
                            </aside>
                            <aside>
                              <Nav.Link
                                eventKey="hr-documents"
                                href="#dashboard-scroll"
                                className="d-flex"
                              >
                                <figure>
                                  <BiFile />
                                </figure>
                                <span>Onboarding Documents</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="handbook"
                                // href="#dashboard-scroll"
                                onClick={(e) => this.clickHandbook(e)}
                              >
                                <figure>
                                  <BiFile />
                                </figure>
                                <span>Employee Handbook  </span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="contracts"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiPen />
                                </figure>
                                <span>Contracts</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="skills"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiBrain />
                                </figure>
                                <span>Skills Checklist</span>
                              </Nav.Link>
                            </aside>

                            <aside>
                              <Nav.Link
                                eventKey="tests"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiBookBookmark />
                                </figure>
                                <span>Tests</span>
                              </Nav.Link>
                            </aside>

                            {/* <aside>
                              <Nav.Link
                                eventKey="stories"
                                href="#dashboard-scroll"
                              >
                                <figure>
                                  <BiBookmarkAltPlus />
                                </figure>
                                <span>Stories</span>
                              </Nav.Link>
                            </aside> */}

                            <aside>
                              <Nav.Link
                                href={AppConstants.Word_Press_API + "/referral-bonus"}
                                // href="https://travelnursesinc.staffingreferrals.com/company/join"
                                target="_blank"
                              >
                                <figure>
                                  <BiUserVoice />
                                </figure>
                                <span>Referral Bonus</span>
                              </Nav.Link>
                            </aside>
                          </Nav>
                        </div>
                      </li>
                      <li className="share-blk">
                        <div className="share-fields">
                          <a
                            href="javascript:void()"
                            className="popup_link"
                            onClick={this.toggle}
                          >
                            {this.state.recruiter
                              ? "Contact My Recruiter"
                              : "Contact The Team"}
                          </a>
                          <Modal
                            isOpen={this.state.modal}
                            toggle={this.toggle}
                            className="modal-lg contact-rect recruiter-modal request-job-sec-modal"
                            id="Tn-popup"
                            style={{ color: "#25408f", fontWeight: "500" }}
                          >
                            <div className="main-page">
                              <ModalHeader
                                toggle={this.toggle}
                                className="text-center pt-4 pb-1"
                                style={{ color: "#25408f" }}
                              >
                                <h4>
                                  {" "}
                                  {this.state.recruiter
                                    ? "Contact My Recruiter"
                                    : "Contact The Team"}
                                </h4>
                              </ModalHeader>
                              <ModalBody className="p-0">
                                <Form
                                  className="inner-block"
                                  onSubmit={this.handleSubmit}
                                >
                                  <Row>
                                    <Col xs="6" className="block">
                                      <FormGroup>
                                        <Label for="FirstName">
                                          First Name
                                          <span
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </Label>
                                        <Input
                                          type="text"
                                          name="firstName"
                                          placeholder="First Name"
                                          id="FirstName"
                                          value={this.state.firstName}
                                          onBlur={() => this.state.firstName}
                                          onChange={this.handleChange}
                                          ref="firstName"
                                          autoComplete="off"
                                        />
                                        <span>
                                          {this.state.firstNameError ===
                                            true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors["firstName"]}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </FormGroup>
                                    </Col>

                                    <Col xs="6" className="block">
                                      <FormGroup>
                                        <Label for="Last Name">
                                          Last Name
                                          <span
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </Label>
                                        <Input
                                          type="text"
                                          name="lastName"
                                          value={this.state.lastName}
                                          onBlur={() => this.state.lastName}
                                          placeholder="Last Name"
                                          id="LastName"
                                          onChange={this.handleChange}
                                          ref="lastName"
                                          autoComplete="off"
                                        />
                                        <span>
                                          {this.state.lastNameError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors["lastName"]}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </FormGroup>
                                    </Col>

                                    <Col xs="6" className="block">
                                      <FormGroup>
                                        <Label for="Email">
                                          Email
                                          <span
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </Label>
                                        <Input
                                          type="text"
                                          name="email"
                                          value={this.state.email}
                                          onBlur={() => this.state.email}
                                          placeholder="Email"
                                          id="email"
                                          onChange={this.handleChange}
                                          ref="email"
                                          autoComplete="off"
                                        />
                                        <span>
                                          {this.state.emailError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors["email"]}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </FormGroup>
                                    </Col>

                                    <Col xs="6" className="block">
                                      <FormGroup>
                                        <Label for="Mobile Phone">
                                          Mobile Phone
                                          <span
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </Label>
                                        <Input
                                          type="text"
                                          name="phone"
                                          value={this.state.phone}
                                          onBlur={() => this.state.phone}
                                          placeholder="Mobile Phone"
                                          id="phone"
                                          onChange={this.handleChange}
                                          ref="phone"
                                          autoComplete="off"
                                        />
                                        <span>
                                          {this.state.phoneError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors["phone"]}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </FormGroup>
                                    </Col>

                                    <Col xs="12" md="12" className="block">
                                      <FormGroup>
                                        <Label for="Last Name">
                                          Comments/Questions
                                          <span
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </Label>
                                        <Input
                                          type="text"
                                          name="comments"
                                          // value={this.state.lastName}
                                          onBlur={() => this.state.comments}
                                          placeholder="Comments"
                                          id="comments"
                                          onChange={this.handleChange}
                                          ref="comments"
                                          autoComplete="off"
                                        />
                                        <span>
                                          {this.state.commentsError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors["comments"]}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <div className="btn-block">
                                    <Button
                                      value="Submit"
                                      className="btn-reg"
                                      disabled={this.state.button_disable}
                                      style={{ backgroundColor: "#334ca4" }}
                                    >
                                      Submit
                                      {this.state.isLoading ? (
                                        <img
                                          src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                          alt="No Image"
                                          style={{ width: 40, height: 40 }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Button>
                                  </div>
                                  {this.state.errMsg}
                                </Form>
                              </ModalBody>
                            </div>
                          </Modal>
                          <h6>Share Your Experience</h6>
                          <div className="social-icons">
                            <span className="facebook">
                              <FacebookShareButton
                                url={
                                  AppConstants.Base_Url +
                                  this.props.location.pathname
                                }
                                imageURL={this.state.blog_image}
                              >
                                <FacebookIcon size={32} round={true} />
                              </FacebookShareButton>
                            </span>
                            <span className="twitter">
                              <TwitterShareButton
                                url={
                                  AppConstants.Base_Url +
                                  this.props.location.pathname
                                }
                                imageURL={this.state.blog_image}
                                className="share"
                              >
                                <TwitterIcon size={32} round={true} />
                              </TwitterShareButton>
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>

                  <Col
                    xs="9"
                    className="inner-content right-block"
                    id="posts-block"
                  >
                    <div id="dashboard-scroll">Testing</div>
                    <Tab.Content>
                      <Tab.Pane eventKey="personal-tab">
                        <DashboarddDfault
                          popularStories={this.state.popularStories}
                          history={this.props.history}
                        />
                      </Tab.Pane>
                      {activeTab === "personal-tab" && (
                        <Tab.Pane eventKey="personal-tab">
                          <DashboarddDfault
                            popularStories={this.props.popularStories}
                            history={this.props.history}
                          />
                        </Tab.Pane>
                      )}
                      {activeTab === "savedJobs" && (
                        <Tab.Pane eventKey="savedJobs">
                          <div className="profile-cont-grid">
                            <SavedJobs />
                          </div>
                        </Tab.Pane>
                      )}
                      {activeTab ==="documents"&& (<Tab.Pane eventKey="documents">
                        <Documents />
                      </Tab.Pane>)}
                      {activeTab==="hr-documents" && (<Tab.Pane eventKey="hr-documents">
                        <div className="profile-cont-grid">
                          <div className="list-job" id="skills-cont-block">
                            <span className="no-data">
                              <p>
                                Click here to sign Onboarding Documents.
                                <br />
                              </p>

                              <a
                                type="button"
                                className="blue-bttn"
                                onClick={this.generateDocumentsURL}
                                style={{ fontFamily: "Figtree Black" }}
                              >
                                Click here
                              </a>
                            </span>
                          </div>
                        </div>
                      </Tab.Pane>)}
                      {activeTab==="timesheet"&&(<Tab.Pane eventKey="timesheet">
                        <TimesheetSection history={this.props.history} />
                      </Tab.Pane>)}
                      {activeTab==="schedule"&&(<Tab.Pane eventKey="schedule">
                        <ScheduleDocsSection history={this.props.history} />
                      </Tab.Pane>)}
                      {activeTab==="expiring"&&(<Tab.Pane eventKey="expiring">
                        <ExpiringDocs history={this.props.history} />
                      </Tab.Pane>)}
                      
                      {activeTab==="contracts"&&(<Tab.Pane eventKey="contracts">
                        <div className="profile-cont-grid">
                          <Contracts />
                        </div>
                      </Tab.Pane>)}
                      {activeTab==="handbook"&&(<Tab.Pane eventKey="handbook">
                        <div className="profile-cont-grid">
                          <HandBook />
                        </div>
                      </Tab.Pane>)}
                      {activeTab==="skills"&&(<Tab.Pane eventKey="skills">
                        <div className="profile-cont-grid">
                          <div className="list-job" id="skills-cont-block">
                            <span className="no-data">
                              <p>
                                Click here to complete Skills Checklist.
                                <br />
                              </p>

                              <a
                                type="button"
                                className="blue-bttn"
                                onClick={this.generateSkillsURL}
                                style={{ fontFamily: "Figtree Black" }}
                              >
                                Click here
                              </a>
                            </span>
                          </div>
                        </div>
                      </Tab.Pane>)}
                      {
                        activeTab==="tests"&&(<Tab.Pane eventKey="tests">
                        <div className="profile-cont-grid">
                          <div className="list-job" id="skills-cont-block">
                            <span className="no-data">
                              <p>
                                This is a timed test, and you only have 3
                                attempts to pass. Please make sure that you are
                                ready to take this assessment before clicking
                                Next.
                                <br />
                              </p>

                              <a
                                type="button"
                                className="blue-bttn"
                                onClick={this.generateTestURL}
                                style={{ fontFamily: "Figtree Black" }}
                              >
                                Click here
                              </a>
                            </span>
                          </div>
                        </div>
                      </Tab.Pane>)
                      }
                      
                      
                      {/* <Tab.Pane eventKey="stories">
                        <Stories history={this.props.history} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="">
                        <StoriesDetailPage />
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Col>
                </Tab.Container>
              </Row>
            </Container>
            <div className="share-blk" id="footer-social-share">
              <div className="share-fields">
                <a href="#" className="popup_link" onClick={this.toggle}>
                  {this.state.recruiter
                    ? "Contact My Recruiter"
                    : "Contact The Team"}
                </a>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-lg contact-rect recruiter-modal request-job-sec-modal"
                  id="Tn-popup"
                  style={{ color: "#25408f", fontWeight: "500" }}
                >
                  <div className="main-page">
                    <ModalHeader
                      toggle={this.toggle}
                      className="text-center pt-4 pb-1"
                      style={{ color: "#25408f" }}
                    >
                      <h4>
                        {" "}
                        {this.state.recruiter
                          ? "Contact My Recruiter"
                          : "Contact The Team"}
                      </h4>
                    </ModalHeader>
                    <ModalBody className="p-0">
                      <Form
                        className="inner-block"
                        onSubmit={this.handleSubmit}
                      >
                        <Row>
                          <Col xs="6" className="block">
                            <FormGroup>
                              <Label for="FirstName">
                                First Name
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                id="FirstName"
                                value={this.state.firstName}
                                onBlur={() => this.state.firstName}
                                onChange={this.handleChange}
                                ref="firstName"
                                autoComplete="off"
                              />
                              <span>
                                {this.state.firstNameError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["firstName"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col xs="6" className="block">
                            <FormGroup>
                              <Label for="Last Name">
                                Last Name
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                name="lastName"
                                value={this.state.lastName}
                                onBlur={() => this.state.lastName}
                                placeholder="Last Name"
                                id="LastName"
                                onChange={this.handleChange}
                                ref="lastName"
                                autoComplete="off"
                              />
                              <span>
                                {this.state.lastNameError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["lastName"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col xs="6" className="block">
                            <FormGroup>
                              <Label for="Email">
                                Email
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                name="email"
                                value={this.state.email}
                                onBlur={() => this.state.email}
                                placeholder="Email"
                                id="email"
                                onChange={this.handleChange}
                                ref="email"
                                autoComplete="off"
                              />
                              <span>
                                {this.state.emailError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["email"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col xs="6" className="block">
                            <FormGroup>
                              <Label for="Mobile Phone">
                                Mobile Phone
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                name="phone"
                                value={this.state.phone}
                                onBlur={() => this.state.phone}
                                placeholder="Mobile Phone"
                                id="phone"
                                onChange={this.handleChange}
                                ref="phone"
                                autoComplete="off"
                              />
                              <span>
                                {this.state.phoneError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["phone"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col xs="12" md="12" className="block">
                            <FormGroup>
                              <Label for="Last Name">
                                Comments/Questions
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                name="comments"
                                // value={this.state.lastName}
                                onBlur={() => this.state.comments}
                                placeholder="Comments"
                                id="comments"
                                onChange={this.handleChange}
                                ref="comments"
                                autoComplete="off"
                              />
                              <span>
                                {this.state.commentsError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["comments"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="btn-block">
                          <Button
                            value="Submit"
                            className="btn-reg"
                            disabled={this.state.button_disable}
                            style={{ backgroundColor: "#334ca4" }}
                          >
                            Submit{" "}
                            {this.state.isLoading ? (
                              <img
                                src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                alt="No Image"
                                style={{ width: 40, height: 40 }}
                              />
                            ) : (
                              ""
                            )}
                          </Button>
                        </div>
                        {this.state.errMsg}
                      </Form>
                    </ModalBody>
                  </div>
                </Modal>
                <h6>Share Your Experience</h6>
                <div className="social-icons">
                  <span className="facebook">
                    <FacebookShareButton
                      url={AppConstants.Base_Url + this.props.location.pathname}
                      imageURL={this.state.blog_image}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </span>
                  <span className="twitter">
                    <TwitterShareButton
                      url={AppConstants.Base_Url + this.props.location.pathname}
                      imageURL={this.state.blog_image}
                      className="share"
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      window.location.href = "/";
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userInfo, user } = auth;
  return { loading, error, userInfo, user };
};

export default connect(mapStateToProps, { getUserInfo })(Profile);
