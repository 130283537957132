import React, { Component } from "react";
import Subscribeblock from "../Components/subscribe-block";
import Destination from "../Components/destination-block";
import images from "../Components/images";
import styles from "../Styles/team.css";
import ReactPlayer from "react-player";
import AppConstants from '../AppConstants';
import axios from "axios";
 
import { Link } from "react-router-dom";
import {  Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody,  Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';
class MessagePopUp extends React.Component {
    constructor(){
      super();
      this.state={
        errors:{},
        name : "",
        phone : "",
        message:"",
        phone_error : false,
        nameError : false,
        messageError:false,
        isLoading : false
      }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputChangedHandlerContact = this.inputChangedHandlerContact.bind(this);
    this.handleCancel = this.handleCancel.bind(this);  
    }
    handleInputChange(e){
        this.setState({[e.target.name]:e.target.value})
        if(e.target.name == "message"){
            if(e.target.value==""){
                this.setState({messageError:true})
            }else{
                this.setState({messageError:false})
            }
        }
        if(e.target.name == "name"){
            if(e.target.value==""){
                this.setState({nameError:true})
            }else{
                this.setState({nameError:false})
            }
        }
    }
    inputChangedHandlerContact = (values) => {
        this.setState({
          phone: values.value,phone_error:false
        });
      }
    handleValidation(){
        let errors = {};
        let formIsValid = true;
       
        if(!this.state.message){
            formIsValid = false;
            this.setState({messageError:true});
            errors["message"] = "Please enter message";
            ReactDOM.findDOMNode(this.refs.message).focus();
        }
        if(!this.state.phone){
            formIsValid = false;
            this.setState({phone_error:true});
            errors["phone"] = "Please enter phone number";
            ReactDOM.findDOMNode(this.refs.phone).focus();
        }
        if(this.state.phone && this.state.phone.length !== 10){
            formIsValid = false;
            this.setState({phone_error:true});
            errors["phone"] = "Please enter 10 digits phone number"
            ReactDOM.findDOMNode(this.refs.phone).focus();
        }
        if(!this.state.name){
            formIsValid = false;
            this.setState({nameError:true});
            errors["name"] = "Please enter name";
            ReactDOM.findDOMNode(this.refs.name).focus();
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({isLoading: true});
            const formData={
                'phone':this.props.phone,
                "message": this.state.message,
                "contact_number" : this.state.phone,
                "name" : this.state.name,
            }
            axios.post(AppConstants.API+'/users/smsToRecruiter',formData).then(res => {   
                if(res.data.Status === "Success"){
                    this.setState({button_disable:true})
                    setTimeout((e) => {
                    this.props.handleModal();
                    }, 2000);
                    setTimeout(() => {
                        this.setState({
                        isLoading:false,
                        errMsg:  (<div className="alert alert-success fade show mt-3">{res.data.msg}</div>)
                        });
                    }, 1000);
                }else{
                    setTimeout(() => {
                        this.setState({
                        isLoading:false,
                            errMsg: (<div className="alert alert-danger fade show mt-3">{res.data.msg}</div>)
                        });
                    }, 1000);
                }
            });
        }
    } 
    handleCancel () {
        this.props.handleModal();
        this.setState({
            name: "",
            phone: "",
            message: "",
            isLoading: false
        })
    }
    render(){
        return(
            <Modal isOpen={this.props.modal} toggle={this.props.handleModal} className="modal-lg">
                <ModalHeader toggle={this.handleCancel} className="text-center">
                    Message
                </ModalHeader>
                <ModalBody className="p-0">
                    <div className="row m-0">
                        <Card className="w-100 p-0 m-0 md-pop">
                            <CardBody>
                                <Form onSubmit={this.handleSubmit} id="message">
                                    <div className="row m-0">
                                        <FormGroup className="col-md-6 col-12">
                                            <Label for="exampleEmail">Name<span className = "error" style={{color:"red"}}>*</span></Label>
                                            <Input type="text" name="name" placeholder="Name" value={this.state.name} ref="name"  onChange={this.handleInputChange} />
                                            <span className="error" style={{ color: 'red' }}>
                                            {this.state.nameError === true ? this.state.errors["name"] : ""}
                                            </span>
                                        </FormGroup>
                                        <FormGroup className="col-md-6 col-12">
                                            <Label for="exampleEmail">Phone Number<span className = "error" style={{color:"red"}}>*</span></Label>
                                            <NumberFormat
                                                format="(###) ###-####"
                                                mask=""
                                                name="phone"
                                                placeholder="Phone Number"
                                                onValueChange={this.inputChangedHandlerContact}
                                                value={this.state.phone} className="form-control"
                                                ref ="phone"
                                            /> 
                                            <span className="error" style={{color: 'red'}} > {this.state.phone_error == true ? this.state.errors["phone"] : ""}</span>
                                        </FormGroup>
                                        <FormGroup className="col-md-6 col-12">
                                            <Label for="exampleEmail">Message<span className = "error" style={{color:"red"}}>*</span></Label>
                                            <Input type="textarea" name="message" placeholder="Message" value={this.state.message} ref="message"  onChange={this.handleInputChange} />
                                            <span className="error" style={{ color: 'red' }}>
                                            {this.state.messageError === true ? this.state.errors["message"]:""}
                                            </span>
                                        </FormGroup>
                                    </div>
                                    <div className="btn-block">
                                        <Button value="Submit" className="btn-reg mt-4">Send</Button>
                                        {this.state.isLoading ? (
                                            <img src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`} alt="No Image" style={{width: 40, height: 40}} />
                                        ) :""}
                                    </div>
                                    {this.state.errMsg}
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </ModalBody>
                </Modal>
        )
    }
}

export default MessagePopUp;