import React from "react";
import "./pdf.css";

const HandBook = () => {
  return (
    <>
      <div className="handbook-pdf-grid">
        <iframe 
          src="https://travelnurses.s3.us-west-2.amazonaws.com/static/empdoc.pdf"
        />
      </div>
    </>
  );
};

export default HandBook;
