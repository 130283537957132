import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { connect } from "react-redux";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

class ResendOTP extends React.Component {
  constructor(props, context) {
    console.log(props.userID, "userID");
    super(props, context);
    this.state = {
      errors: [],
      isLoading: "",
      errMsg: "",
      button_disable: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  handleSubmit() {
    const userID = localStorage.getItem("userID");
    console.log(userID, "userID");

    const formData = {
      id: userID,
    };
    this.setState({ isLoading: 1 });
    axios
      .post(AppConstants.API + "/nurse/sendOtp", formData)
      .then((res) => {
        if (res.data.Status === "Success") {
          NotificationManager.success(res.data.msg, "", 5000);
          this.props.history.push(`/otp`);
        }
      })
      .catch((err) => {
        if (err) {
          NotificationManager.warning(
            "Something went wrong, please try again!",
            "",
            5000
          );
        }
        this.setState({ isLoading: 0 });
      });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <NotificationContainer />
        <Container>
          <div className="login-grid">
            <div className="inner-block">
              <Row>
                <Col xs="6" className="block left-block">
                  <figure className="pic image-fill">

                    <img

                      src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                      alt={
                        "Best Travel Nursing Company, Nursing Agency | Travel Nurses Inc"
                      }
                    />

                  </figure>
                </Col>
                <Col xs="6" className="block right-block" id="otp-form">
                  <div className="form-sec text-center">
                    <div className="title">
                      Resend One Time <br />
                      Passcode
                    </div>
                    {/* Resend OTP */}
                    <Button
                      className="button"
                      onClick={this.handleSubmit}
                      disabled={this.state.button_disable}
                    >
                      Resend
                      {this.state.isLoading ? (
                        <img
                          src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                          alt="No Image"
                          style={{ width: 40, height: 40 }}
                        />
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userID, msg } = auth;
  return { loading, error, userID, msg };
};

export default connect(mapStateToProps, {})(ResendOTP);
