import React, { Component } from "react";
import images from "../Components/images";
import Subscribeblock from "../Components/subscribe-block";
// import Destination from "../Components/destination-block";
import { Container, Row, Col } from "reactstrap";
import styles from "../Styles/news.css";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "~/../react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { JSONLD, Generic  } from "react-structured-data";
class BlogDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "",
      content: "",
      images: [],
      key_words: [],
      blogger_name: "",
      video: "",
      category: "",
      color: "",
      related_blogs: [],
      user: "",
      showVideo: {},
      mostViwed_blogs: [],
      showPlayButton: true,
      blog_image: "",
      blogger_description: "",
      blogger_image: "",
      authorSocialLinks: [],
      authorAltImgName: ""
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.blogDetails(this.props.match.params.id);
    axios
      .get(
        AppConstants.CMS_API +
        "/blogs/getBlogBySlug/" +
        this.props.match.params.id
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status == "Success") {
          this.blogDetails(data.data._id);
        }
      });
    // this.blogDetails(this.props.location.state.blog_id);
  }
  blogDetails(id) {
    axios
      .get(AppConstants.CMS_API + "/blogs/getBlog/" + id)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status == "Success") {
          var val = "";
          if (data.data.videoType === "url") {
            val = data.data.embededUrl;
          } else {
            val = data.data.videoFile;
          }
          var data1 = [];
          if (data.data.images) {
            this.setState({ blog_image: data.data.images[0] });
            data.data.images.map((item, index) => {
              var info = {};
              info["original"] = item;
              info["thumbnail"] = item;
              data1.push(info);
            });
          }
          if (val) {
            var info = {};
            if (data.data.urlThumbnail) {
              info["thumbnail"] = data.data.urlThumbnail;
              info["original"] = data.data.urlThumbnail;
            } else {
              info[
                "thumbnail"
              ] = `//img.youtube.com/vi/L7OLY4HCctQ/maxresdefault.jpg`;
              info["original"] =
                "//img.youtube.com/vi/L7OLY4HCctQ/maxresdefault.jpg";
            }
            info["embedUrl"] = val;
            info["renderItem"] = this._renderVideo.bind(this);
            info["thumbnailClass"] = "icon_display";
            data1.push(info);
          }
          this.setState({
            title: data.data.title,
            content: data.data.content,
            images: data1,
            key_words: data.data.keyWords,
            blogger_name: data.data.bloggerName,
            video: val,
            category: data.data.category_name,
            category_id: data.data.category,
            created_date: data.data.created_date,
            color: data.data.color,
            user: data.data.user_name,
            metaTitle: data.data.metaTitle,
            metaDescription: data.data.metaDescription,
            metaTags: data.data.metaTags,
            ogTitle: data.data.ogTitle,
            ogDescription: data.data.ogDescription,
            blogger_description: data.data.bloggerDescription,
            authorAltImgName: data.data.authorAltImgName,
            authorSocialLinks: data.data.authorSocialLinks,
            blogger_image: data.data.bloggerImage
          });
          axios
            .get(
              AppConstants.CMS_API +
              "/blogs/getCategoryAndIdBlogs/" +
              data.data.category +
              "/" +
              id
            )
            .then((res) => {
              return res.data;
            })
            .then((data) => {
              if (data.Status == "Success") {
                this.setState({ related_blogs: data.data });
              }
            });
          axios
            .get(AppConstants.CMS_API + "/blogs/mostViewedBlogs")
            .then((res) => {
              return res.data;
            })
            .then((data) => {
              if (data.Status == "Success") {
                this.setState({ mostViwed_blogs: data.data });
              }
            });
        }
      });
  }
  viewBlog(id, slug, e) {
    this.blogDetails(id);
    // this.props.history.push({
    //   pathname: "/blog-details/" + id,
    // });
    this.props.history.push({
      pathname: "/blog-details/" + slug,
      state: { blog_id: id },
    });
    var data1 = {};
    // https://json.geoiplookup.io/api
    // http://www.geoplugin.net/json.gp?jsoncallback=?
    axios
      .get("https://ipapi.co/json")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        data1 = {
          viewerDetails: data,
          blog_id: id,
        };
        axios
          .post(AppConstants.CMS_API + "/blogs/blogView", data1)
          .then((res) => {
            return res.data;
          })
          .then((data4) => {
            if (data4.Status) {
              console.log(data4.Status);
            }
          });
      });
  }
  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }
  _renderVideo(item) {
    return (
      <div>
        {/* {this.state.showVideo[item.embedUrl] ? ( */}
        {item.embedUrl ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="560"
              height="315"
              src={item.embedUrl + "?rel=0"}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className="play-button"></div>
              <img className="image-gallery-image" src={item.original} alt={"blog-image - Travel Nurses Inc"}/>
              {item.description && (
                <span
                  className="image-gallery-description"
                  style={{ right: "0", left: "initial" }}
                >
                  {item.description}
                </span>
              )}
            </a>
          )}
      </div>
    );
  }
  render() {
    const regex = /(<([^>]+)>)/gi;
    const result = this.state.content.replace(regex, "");
    return (
      <div className="newsdetails-block">
        <JSONLD>
          <Generic type="webpage" jsonldtype="Blog"
            schema={{
            blog : this.state.title, 
            description: this.state.metaDescription,
            contentReferenceTime: new Date(),
            breadcrumb:"Home >> Blog >> "+this.state.title,
            author: this.state.blogger_name
          }}
          >
          </Generic>
        </JSONLD>
        <Helmet>
          <title>{AppConstants.Blog}</title>
          <meta name="title" content={this.state.metaTitle} />
          <meta name="description" content={this.state.metaDescription} />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={
              AppConstants.Base_Url +
              "/blog-details/" +
              this.props.match.params.id
            }
          />
          <meta property="og:title" content={this.state.ogTitle} />
          <meta property="og:description" content={this.state.ogDescription} />
          <meta property="og:image" content={this.state.blog_image} />

          <meta property="twitter:card" content={this.state.blog_image} />
          <meta
            property="twitter:url"
            content={
              AppConstants.Base_Url +
              "/blog-details/" +
              this.props.match.params.id
            }
          />
          <meta property="twitter:title" content={this.state.metaTitle} />
          <meta
            property="twitter:description"
            content={this.state.metaDescription}
          />
          <meta property="twitter:image" content={this.state.blog_image} />
        </Helmet>
        <div className="inner-block">
          <Container>
            {/* Breadcrumb Block  Start Here */}
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/blog">Blog</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{this.state.title}</BreadcrumbItem>
            </Breadcrumb>
            {/* Breadcrumb Block  End Here */}
            <Row className="inner-content justify-content-center">
              <Col xs="9" className="left-block">
                <figure className="pic image-fill">
                  <ImageGallery items={this.state.images} />
                  <span
                    className="category"
                    style={{ backgroundColor: this.state.color }}
                  >
                    {this.state.category}
                  </span>
                </figure>
                <div className="content-area">
                  <h2>{this.state.title}</h2>
                  <span className="date">{this.state.created_date}</span>
                  <span className="author">
                    Posted By: <em>{this.state.blogger_name}</em>
                  </span>
                  <div className="share-blk">
                    <span>Share:</span>
                    <div className="social-icons">
                      <span className="facebook">
                        <FacebookShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          imageURL={this.state.blog_image}
                          // imageURL="https://s3-us-west-2.amazonaws.com/travelnurses/blog_files/1594712934615.jpg"
                          // quote={"Hello Travel Nurses"}
                          className="share"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </span>
                      <span className="twitter">
                        <TwitterShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          // media={this.state.blog_image}
                          imageURL={this.state.blog_image}
                          // media="https://s3-us-west-2.amazonaws.com/travelnurses/blog_files/1594712934615.jpg"
                          className="share"
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </span>
                      <span className="twitter">
                        <LinkedinShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          // media={this.state.blog_image}
                          className="share"
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </span>
                      <div className=" clear"></div>
                    </div>
                  </div>
                  {
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.content }}
                    />
                  }
                  {this.state.blogger_image ?
                    <div className="author-blk">
                      <h3>About the Author</h3>
                      <div className="inner-sec">

                        {this.state.blogger_image ? <figure className="author-pic image-fill"><img src={this.state.blogger_image} alt={this.state.authorAltImgName + " - Travel Nurses Inc"} /> </figure> : ""}
                        {this.state.blogger_description ? <div className="text">{this.state.blogger_description}
                          <div className="social-icons">
                            {this.state.authorSocialLinks.map((item1) => {
                              return (
                                <a
                                  href={item1.url}
                                  target="_blank"
                                  className={item1.name}
                                ></a>
                              );
                            })}
                          </div>
                        </div> : ""}

                      </div>
                    </div> : ''}
                  {/* <div className="share-blk">
                    <span>Share:</span>
                    <div className="social-icons">
                      <span className="facebook">
                        <FacebookShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          imageURL={this.state.blog_image}
                          // imageURL="https://s3-us-west-2.amazonaws.com/travelnurses/blog_files/1594712934615.jpg"
                          // quote={"Hello Travel Nurses"}
                          className="share"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </span>
                      <span className="twitter">
                        <TwitterShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          // media={this.state.blog_image}
                          imageURL={this.state.blog_image}
                          // media="https://s3-us-west-2.amazonaws.com/travelnurses/blog_files/1594712934615.jpg"
                          className="share"
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </span>
                      <span className="twitter">
                        <LinkedinShareButton
                          url={
                            AppConstants.Base_Url + this.props.location.pathname
                          }
                          // media={this.state.blog_image}
                          className="share"
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </span>
                      <div className=" clear"></div>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col xs="3" className="right-block side-bar">
                <div className="event-sec mostview-blk text-center">
                  <div className="head-title text-center">Most Viewed</div>
                  <div className="block-row">
                    {this.state.mostViwed_blogs.map((e, idx) => (
                      <div
                        className="most-viewed"
                        onClick={this.viewBlog.bind(this, e._id, e.slug)}
                        style={{ cursor: "pointer" }}
                      >
                        <figure className="pic image-fill">
                          {e.images ? <img src={e.images[0]} alt = {e.title+" - Travel Nurses Inc"}/> : ""}
                        </figure>
                        <div className="text">
                          <h6>{e.title}</h6>
                        </div>
                        <div className="clear"></div>
                      </div>
                    ))}
                  </div>
                  <Link
                    to={{
                      pathname: "/blog",
                      state: { viewCount: "viewCount" },
                    }}
                    className="button"
                  >
                    View More
                  </Link>
                </div>
                {this.state.related_blogs.length !== 0 ? (
                  <div className="event-sec related-news-blk  text-center">
                    <div className="head-title text-center">Related Blogs</div>
                    <div className="block-row">
                      {this.state.related_blogs.map((r_blog, k) => {
                        return (
                          <div
                            className="related-news"
                            onClick={this.viewBlog.bind(
                              this,
                              r_blog._id,
                              r_blog.slug
                            )}
                            style={{ cursor: "pointer" }}
                          >
                            <figure className="pic image-fill">
                              {r_blog.images ? (
                                <img src={r_blog.images[0]} alt = {r_blog.title+ " - Travel Nurses Inc"}/>
                              ) : (
                                  ""
                                )}
                            </figure>
                            <div className="news-text">
                              <h5>{r_blog.title}</h5>
                              <span className="date">
                                {r_blog.created_date}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className="clear"></div>
                      <Link
                        to={{
                          pathname: "/blog",
                          state: { category: this.state.category_id },
                        }}
                        className="button"
                      >
                        View More Blogs
                      </Link>
                    </div>
                  </div>
                ) : (
                    ""
                  )}
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default BlogDetails;
