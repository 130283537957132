import React, { Component } from "react";
import withMousePosition from '../mouseposition';
import ExitPopUp from './exitpopup';

class ExitpopupApp extends Component {
  state = {
    showModal: this.props.showModal,
    modalOpenedOnce: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mousePosition.y > 1 && this.props.mousePosition.y <= 1) {
      this.setState(prevState => {
        if (!prevState.showModal && !prevState.modalOpenedOnce) {
          return { showModal: true, modalOpenedOnce: true };
        }
        return null;
      });
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="app">
        

        <ExitPopUp
          popup={this.state.showModal}
                  handleExitPopup={this.closeModal}
                  />
      </div>
    );
  }
}

export default withMousePosition(ExitpopupApp);
