import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,

  CustomInput,

} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import styles from "../Styles/registration.css";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import pdfExt from "../images/pdfFile.png";
import docsxExt from "../images/docsFile.png";
import heicImage from "../images/heic.jpg";
import TimesheetPopup from "./timesheet-popup";
import Pdf from "../docs/ScheduleDocsInstructions.pdf";
import { FaAngleDoubleLeft } from "react-icons/fa";

class ScheduleDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
      nursescheduledocs: [],
      errors: {},
      firstNameError: false,
      nursetimesheetError: false,
      emailError: false,
      phone_error: false,
      button_disable: false,
      fileEnable: false,
      errMsg: "",
      warning_msg: "",
      questions: "",
      isLoading: false,
      popUp: false,
      imgCollection: "",
      propertyTypeError: null,
      fileError: false,
      files: [],
      message: "",
      propertyPreviewImages: [],
      isFirstNameReadonly: false,
      isEmailReadonly: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDocs = this.handleDocs.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({
      firstName: user ? user.firstName + " " + user.lastName : "",
      // lastName: user ? user.lastName : "",
      email: user && user.primaryEmail ? user.primaryEmail : "",
      phone: user ? user.primaryPhoneNumber : "",
      fileEnable: user ? true : false,
      isFirstNameReadonly: user && user.firstName ? true : false,
      isEmailReadonly: user && user.primaryEmail ? true : false,
    });
  }
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  removePropertyPreviewImage(i) {
    let imagesPreviewData = [...this.state.propertyPreviewImages];
    let files = [...this.state.files];
    imagesPreviewData.splice(i, 1);
    files.splice(i, 1);
    this.setState({
      propertyPreviewImages: imagesPreviewData,
      files: files,
      // nursescheduledocs: [],
    });
  }

  handleDocs(e) {
    let fileObj = [];
    let fileArray =
      this.state.propertyPreviewImages.length > 0
        ? [...this.state.propertyPreviewImages]
        : [];
    let contentType =
      this.state.files.length > 0
        ? this.state.files[0].type.split("/")[1]
        : null;

    fileObj.push(e.target.files);

    let isInvalidFile = false;
    let fileAccepted = true;

    let files = [];
    for (let i = 0; i < fileObj[0].length; i++) {
      console.log(e.target.files[i], " line 77");
      let fileType = fileObj[0][i].type.split("/")[1];
      files.push(e.target.files[i]);
      console.log(fileType, "fileType");
      if (
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileType === "msword" ||
        fileType === "pdf" ||
        fileObj[0][i].type.startsWith("image")
      ) {
        fileArray.push({
          path: URL.createObjectURL(fileObj[0][i]),
          name: fileObj[0][i].name,
        });
      } else isInvalidFile = true;
      if (i === 0 && !contentType) {
        contentType = fileType;
      } else {
        if (contentType !== fileType || !fileAccepted) fileAccepted = false;
      }
    }
    if (files.length > 0) {
      this.setState({ fileError: false });
    } else {
      this.setState({ fileError: true });
    }
    if (!isInvalidFile && fileAccepted) {
      this.setState({
        propertyPreviewImages: fileArray,
        files: this.state.files.concat(
          Array.prototype.slice.call(e.target.files)
        ),
      });
    } else {
      isInvalidFile &&
        this.setState({
          propertyTypeError: "File not supported.",
        });
      !isInvalidFile &&
        !fileAccepted &&
        this.setState({
          propertyTypeError: "Please select only selected file format.",
        });
      setTimeout(() => {
        this.setState({ propertyTypeError: false });
      }, 3000);
    }

    e.target.value = null;
  }

  handleInputChange = async (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
      }
      // () => {
      //   console.log(
      //     this.state.firstName,
      //     "first name",
      //     this.state.lastName,
      //     "last name"
      //   );
      // }
    );
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true, fileEnable: false });
      } else {
        this.setState({ emailError: false }, () => {
          let lastAtPos = this.state.email.lastIndexOf("@");
          let lastDotPos = this.state.email.lastIndexOf(".");
          if (
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state.email.indexOf("@@") === -1 &&
            lastDotPos >= 2 &&
            this.state.email.length - lastDotPos > 2
          ) {
            this.setState({ fileEnable: true });
          }
        });
      }
    }
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos >= 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter your valid email address.";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.files.length > 0 && !this.state.questions.length > 0) {
      formIsValid = false;
      errors["file"] =
        "Please add an image/document of the schedule or enter the schedule manually in the comments below.";
      // ReactDOM.findDOMNode(this.refs.files).focus();
    }

    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter your legal name.";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      fileError: true,
      // nursescheduledocsError: true,
      // timingsError: true,
    });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      let nurseId = localStorage.getItem("id");
      this.setState({ isLoading: true });
      let formData = new FormData();
      // let fullName = this.state.firstName + " " + this.state.lastName;
      // let fullName = this.state.isFirstNameReadonly
      //   ? this.state.firstName + " " + this.state.lastName
      //   : this.state.firstName;
      formData.append("name", this.state.firstName);
      formData.append("email", this.state.email);
      formData.append("nurseId", nurseId ? nurseId : "");
      for (let i = 0; i < this.state.files.length; i++) {
        console.log("item", this.state.files[i]);

        formData.append("file", this.state.files[i]);
      }
      formData.append("comment", this.state.questions);
      axios
        .post(AppConstants.API + "/nurse/scheduleDocuments", formData)
        .then((res) => {
          console.log("data", res.data);
          if (res.data.Status === "Success") {
            this.setState({
              button_disable: true,
              popUp: true,
              isLoading: false,
              message: res.data.msg,
            });
          } else {
            // setTimeout(() => {
            this.setState({
              isLoading: false,
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
            // }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 8000);
          }
        });
    }
  }
  handleDocsPopup() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({
      popUp: !this.state.popUp,
      firstName: user && user.firstName ? user.firstName : "",
      email: user && user.primaryEmail ? user.primaryEmail : "",
      questions: "",
      propertyPreviewImages: [],
      files: [],
      warning_msg: "",
      button_disable: false,
    });
  }
  render() {
    let slug = localStorage.getItem("slug");

    return (
      <div className="apply-form registr-info main-page timesheet-popup">
        <Container>
          <div className="registration-form">
            <div className="preview-bttn">
              <button
                class="button back-btn float-none"
                onClick={() => this.props.history.push("/profile/" + slug)}
              >
                <FaAngleDoubleLeft />
                <span>Back To Dashboard</span>
              </button>
            </div>
            <h1 className="main-title-one">
              {" "}
              Travel Nurse Schedule Management
            </h1>
            <h2 className="main-title-two">
              Submit your schedule in the comments section or by uploading a
              picture
            </h2>

            <Form className="inner-block contact-form">
              <div className="form-block">
                <Row className="codephone-blk">
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="FirstName">
                        Legal Name
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="Name"
                        id="Name"
                        value={this.state.firstName}
                        // value={
                        //   this.state.isFirstNameReadonly
                        //     ? this.state.firstName + " " + this.state.lastName
                        //     : this.state.firstName
                        // }
                        onChange={this.handleInputChange}
                        ref="firstName"
                        readOnly={this.state.isFirstNameReadonly}
                      />
                      <span>
                        {this.state.firstNameError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["firstName"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <ul className="form-list faq-list">
                        <li>
                          <a href={Pdf} className="read-more" target="_blank">
                            Instructions for submitting schedule
                          </a>
                        </li>
                      </ul>
                    </FormGroup>
                  </Col>

                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Email">
                        Email Address
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        ref="email"
                        readOnly={this.state.isEmailReadonly}
                      />
                      <Label className="hcs-label">
                        Please use the email address you used on our
                        application.
                      </Label>
                      <span>
                        {this.state.emailError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["email"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {this.state.fileEnable ? (
                <div>
                  <Row className="codephone-blk scheduleInputFile">
                    <Col xs="4" className="block upload-input">
                      <FormGroup>
                        <Label>
                          Add Schedule
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Label>

                        <CustomInput
                          // key="nursetimesheet"
                          type="file"
                          name="nursetimesheet"
                          accept="image/png, image/jpeg,image/jpg ,image/heic,application/pdf, .docx, .doc,"
                          id="upload"
                          ref="nursetimesheet"
                          label={"choose files"}
                          onChange={this.handleDocs}
                          multiple

                        // value={this.state.nursetimesheet}
                        />
                        <span>
                          {this.state.fileError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["file"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>

                        {this.state.propertyTypeError && (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.propertyTypeError}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.propertyPreviewImages &&
                    this.state.propertyPreviewImages.length > 0 &&
                    this.state.propertyPreviewImages[0].path &&
                    this.state.propertyPreviewImages.map((post, i) => {
                      console.log(post, "post");
                      console.log(post["name"].split(".").pop(), " line 419");
                      if (
                        post["name"].split(".").pop() === "docx" ||
                        post["name"].split(".").pop() === "doc"
                      ) {
                        return (
                          <span className="timesheet-upload-doc">
                            <span className="file-pic">
                              <a href={post["path"]} id={i} target="blank">
                                <img
                                  src={docsxExt}
                                  title={post["name"]}
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                />
                              </a>
                              <input
                                type="button"
                                value="X"
                                className="remove-btn"
                                id={i}
                                onClick={this.removePropertyPreviewImage.bind(
                                  this,
                                  i
                                )}
                              />
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        );
                      } else if (
                        post["name"].split(".").pop() === "jpeg" ||
                        post["name"].split(".").pop() === "png" ||
                        post["name"].split(".").pop() === "jpg"
                      ) {
                        return (
                          <span className="timesheet-upload-doc">
                            <span className="file-pic">
                              <a href={post["path"]} id={i} target="blank">
                                <img
                                  src={post["path"]}
                                  title={post["name"]}
                                  style={{
                                    borderRadius: "8px",
                                  }}
                                />
                              </a>
                              <input
                                type="button"
                                value="X"
                                id={i}
                                onClick={this.removePropertyPreviewImage.bind(
                                  this,
                                  i
                                )}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        );
                      } else if (
                        post["name"].split(".").pop().toLowerCase() === "heic"
                      ) {
                        return (
                          <span className="timesheet-upload-doc">
                            <span className="file-pic">
                              <a href={post["path"]} id={i} target="blank">
                                <img
                                  src={heicImage}
                                  title={post["name"]}
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                />
                              </a>
                              <input
                                type="button"
                                value="X"
                                className="remove-btn"
                                id={i}
                                onClick={this.removePropertyPreviewImage.bind(
                                  this,
                                  i
                                )}
                              />
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        );
                      } else {
                        console.log("pdf");
                        return (
                          <span className="timesheet-upload-doc">
                            <span className="file-pic">
                              <a href={post["path"]} id={i} target="blank">
                                <img
                                  src={pdfExt}
                                  title={post["name"]}
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                />
                              </a>
                              <input
                                type="button"
                                value="X"
                                className="remove-btn"
                                id={i}
                                onClick={this.removePropertyPreviewImage.bind(
                                  this,
                                  i
                                )}
                              />
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        );
                      }
                    })}
                  <div className="clearfix"></div>
                  <div className="codephone-blk comments-input">
                    <div className="block">
                      <Label>
                        If you would like to submit your schedule manually,
                        enter it in the comments below.
                      </Label>
                      <div className="check-sec">
                        <span className="">
                          <Input
                            type="textarea"
                            name="questions"
                            onChange={this.handleInputChange}
                            value={this.state.questions}
                            ref="questions"
                            placeholder="Comments"
                          />
                        </span>
                        {/* <span>
                          {this.state.fileError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["file"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <ul className="form-list">
                    {/* <li>
                      You may submit your schedule in the comments section or by
                      uploading a picture above.
                    </li> */}
                    <li>
                      Center and align your schedule in a well-lit area before
                      taking your photo. Please do not upload blurry or dark
                      images or files.
                    </li>
                    <li>
                      If you have any issues uploading your schedule, contact us{" "}
                      <a
                        href={AppConstants.Word_Press_API + "/div-contact/"}
                        className="read-more"
                        target="_blank"
                      >
                        here
                      </a>
                    </li>
                  </ul>

                  <div className="btn-block">
                    <Button
                      value="Submit"
                      className="btn-reg"
                      disabled={this.state.button_disable}
                      style={{ backgroundColor: "#334ca4", fontFamily: "Figtree Black" }}
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </Button>
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.errMsg}
                </div>
              ) : (
                ""
              )}
              {this.state.popUp ? (
                <TimesheetPopup
                  popup={this.state.popUp}
                  handleTimesheetPopup={this.handleDocsPopup.bind(this)}
                  message={this.state.message}
                />
              ) : (
                ""
              )}
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}

export default ScheduleDocuments;
