import React, { Suspense } from "react";
import { NotificationContainer } from "react-notifications";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import FadeIn from "react-fade-in";
import styles from "./Styles/common.css";
import stylesNew from "./Styles/tnstyles.css";
import Responsive from "./Styles/responsive.css";
import custom from "./Js/custom.js";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store from "./store";

import Header from "./Components/header";
import Layout from "./Components/layout";
import Footer from "./Components/footer";
import LogIn from "./Pages/login";
import OTP from "./Pages/otp";
import ResendOtp from "./Pages/resend-otp";
import ForgotPassword from "./Pages/forgot-password";
import Registration from "./Pages/registration";
import NotFound404 from "./Pages/notFoundPage";
import Unsubscribe from "./Pages/unsubscribe";
import PrivacyPolicy from "./Pages/privacy-policy";
import CookiesBanner from "./Components/CookiesBanner";




// const Layout = React.lazy(() =>
//   import(/* webpackChunkName: "views-layout" */ './Components/layout')
// );
// const Header = React.lazy(() =>
//   import(/* webpackChunkName: "views-header" */ './Components/header')
// );
// const Footer = React.lazy(() =>
//   import(/* webpackChunkName: "views-footer" */ './Components/footer')
// );
// const LogIn = React.lazy(() =>
//   import(/* webpackChunkName: "views-login" */ './Pages/login')
// );
// const OTP = React.lazy(() =>
//   import(/* webpackChunkName: "views-otp" */ './Pages/otp')
// );
// const ResendOtp = React.lazy(() =>
//   import(/* webpackChunkName: "views-resend" */ './Pages/resend-otp')
// );
// const ForgotPassword = React.lazy(() =>
//   import(/* webpackChunkName: "views-forgot" */ './Pages/forgot-password')
// );
// const Registration = React.lazy(() =>
//   import(/* webpackChunkName: "views-registration" */ './Pages/registration')
// );
// const NotFound404 = React.lazy(() =>
//   import(/* webpackChunkName: "views-apnotFoundPagep" */ './Pages/notFoundPage')
// );
// const Unsubscribe = React.lazy(() =>
//   import(/* webpackChunkName: "views-unsubscribe" */ './Pages/unsubscribe')
// );
// const PrivacyPolicy = React.lazy(() =>
//   import(/* webpackChunkName: "views-privacy" */ './Pages/privacy-policy')
// );
// const CookiesBanner = React.lazy(() =>
//   import(/* webpackChunkName: "views-CookiesBanner" */ './Components/CookiesBanner')
// );

const authToken = localStorage.getItem("token");
const auth = authToken ? authToken.split(" ") : "";
const token = auth[1] ? auth[1] : "";
if (token) {
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("id");
    localStorage.removeItem("userID");
    localStorage.removeItem("tempId");
    localStorage.removeItem("user");
    localStorage.removeItem("primaryEmail");
    window.location.href = "/";
  }
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: false,
      loading: false,
      display: true,
      show: false,
    };
    this.handleToggleDisplay = this.handleToggleDisplay.bind(this);
  }

  handleToggleDisplay = (value) => {
    this.setState({ display: value });
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      // console.log("response", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const userCountry = data.country;
      console.log("userCountry:", userCountry);
      if (!userCountry) {
        console.log("Fallback to an alternative service or default value");
      } else {
        if (userCountry !== "IN") {
          this.setState({ page: true, loading: false });
          this.showFunction();
        } else {
          this.setState({ loading: true });
        }
      }
    } catch (error) {
      console.error("Error fetching IP address:", error.message);
    }
  };
  showFunction = async () => {
    if (this.state.page) {
      const currentURL = window.location.href;
      const urlParts = currentURL.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      if (lastPart == "privacy-policy") {
        this.setState({ show: true });
      } else {
        this.setState({ show: false });
      }
    } else {
      this.setState({ show: false });
    }
  };

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <React.Fragment>
            <NotificationContainer />
            <Router>
              {this.state.show && (
                <Header classAdd={this.state.display ? "" : "head-top"} />
              )}
              {this.state.show && (
                <section
                  className={
                    this.state.display ? "cookies-area" : "cookies-area-none"
                  }
                >
                  <CookiesBanner onDisplayToggle={this.handleToggleDisplay} />
                </section>
              )}
              <Suspense fallback={<div className="loading" />}>
                <Switch>
                  {this.state.loading && (
                    <Route
                      exact
                      path="/login"
                      render={(props) => <LogIn {...props} />}
                    />
                  )}
                  {this.state.loading && (
                    <Route path="/otp" render={(props) => <OTP {...props} />} />
                  )}
                  {this.state.loading && (
                    <Route
                      path="/resend-otp"
                      render={(props) => <ResendOtp {...props} />}
                    />
                  )}
                  {this.state.loading && (
                    <Route
                      path="/registration"
                      render={(props) => <Registration {...props} />}
                    />
                  )}

                  {this.state.page && (
                    <Route
                      path="/privacy-policy"
                      render={(props) => <PrivacyPolicy {...props} />}
                    />
                  )}
                  {this.state.loading && (
                    <Route path="/forgot-password" component={ForgotPassword} />
                  )}
                  {this.state.loading && (
                    <Route
                      path="/unsubscribe/:nurseId"
                      component={Unsubscribe}
                    />
                  )}
                  <Route path="/404" component={NotFound404} />
                  {this.state.page && <Redirect to="/404" />}
                  {this.state.loading && (
                    <Route path="/" render={(props) => <Layout {...props} />} />
                  )}
                </Switch>
              </Suspense>
              {this.state.show && (
                <FadeIn>
                  <Footer />
                </FadeIn>
              )}
            </Router>
          </React.Fragment>
        </div>
      </Provider>
    );
  }
}
export default App;
