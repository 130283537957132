import React from "react";
import styles from "../Styles/team.css";
import AppConstants from "../AppConstants";
import axios from "axios";
 
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getCountries } from "../Components/helpers/network-calls";
import {
 
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
   
} from "reactstrap";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
var subjectVal = [];
class ContactRecruiterPopUp extends React.Component {
  constructor() {
    super();
    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      errors: {},
      name: "",
      phone: "",
      message: "",
      phone_error: false,
      nameError: false,
      messageError: false,
      isLoading: false,
      applyData: null,
      jobTitle: "",
      jobId: "",
      contact_methods: [
        { text: "Phone", value: "phone", isChecked: false },
        { text: "Text", value: "text", isChecked: false },
        { text: "Email", value: "email", isChecked: false },
      ],
      certifications: [
        { text: "RN", value: "RN", isChecked: false },
        {
          text: "Surgical Technician",
          value: "Surgical Technician",
          isChecked: false,
        },
        {
          text: "Allied Health Professional",
          value: "Allied Health Professional",
          isChecked: false,
        },
        { text: "Other", value: "Other", isChecked: false },
      ],
      timings: [
        { text: "Morning", value: "Morning", isChecked: false },
        { text: "Afternoon", value: "Afternoon", isChecked: false },
        { text: "Evening", value: "Evening", isChecked: false },
      ],
      check_certifications: [],
      check_contact_methods: [],
      check_timings: [],
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.email : "",
      phone: user ? user.phone : "",
      code: "+1",
      speciality: "",
      errors: [],
      errorsCode: {},
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phone_error: false,
      certificationsError: false,
      contact_methodsError: false,
      timingsError: false,
      codeError: false,
      specialityError: false,
      errMsg: "",
      isLoading: false,
      jobProviderId: "",
      location: "",
      startDate: "",
      endDate: "",
      shift: "",
      positions: "",
      questions: "",
      button_disable: false,
      selectedSpecialities: [],
      specialityCopy: [],
      specialitiesDuplicate: [],
      specialityOriginal: [],
      specialityDropdown: "",
      dropdownStatus: false,
      certifications: [],
      certification: [],
      selectedCerts: [],
      certDropdown: "",
      certsDuplicate: [],
      certsOriginal: [],
      certsCopy: [],
      countries: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTimings = this.handleTimings.bind(this);
    this.handleContactMethod = this.handleContactMethod.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.toggleClosedSpeciality = this.toggleClosedSpeciality.bind(this);
    this.handleCertChange = this.handleCertChange.bind(this);
    this.toggleClosedCert = this.toggleClosedCert.bind(this);
    this.handleSpecChange = this.handleSpecChange.bind(this);
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }
  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }
  handleCertChange(selectedList, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.certifications.length - 1) {
      selectedList = this.state.certifications;
    } else {
      selectedList = selectedList;
    }
    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    this.state.certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.certFunction(selectedList);
    this.setState({
      certifications: result,
      certification: selectedList,
      selectedCerts: selectedList,
      certsDuplicate: result,
    });
  }
  certFunction(selectedList) {
    var certVal = [];
    selectedList.map((item) => {
      if (item.value) {
        certVal.push(item.value);
      } else {
        certVal.push(item);
      }
    });
    let tablefilterdata = {
      certs: certVal,
    };
    if (selectedList.length === 0) {
      this.setState({ items: this.state.specialityOriginal });
    } else if (selectedList.length > 0) {
      axios
        .post(
          AppConstants.Jobs_API +
          "/specialities/getSpecsWithJobsCountBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                items: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityCopy: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedSpecialities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedSpecialities: myArrayFiltered });
              }
            }
          }
        });
    }
  }
  handleCertFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedCerts.length === 0
    ) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    } else if (
      this.state.selectedSpecialities.length === 0 &&
      this.state.selectedCerts.length !== 0
    ) {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }
  handleSpecChange(value, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === this.state.items.length - 1) {
      value = this.state.items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      items: result,
      value: value,
      selectedSpecialities: value,
      specialitiesDuplicate: result,
    });
    this.specialityFunction(value);
  }
  specialityFunction(selectedList) {
    var specVal = [];
    selectedList.map((item) => {
      if (item.value) {
        specVal.push(item.value);
      } else {
        specVal.push(item);
      }
    });

    let tablefilterdata = {
      specialities: specVal,
    };
    if (selectedList.length === 0) {
      this.setState({ certifications: this.state.certsOriginal });
    } else if (selectedList.length > 0) {
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getCertsBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                certifications: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                certsCopy: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCerts.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCerts: myArrayFiltered });
              }
            }
          }
        });
    }
  }
  componentDidMount() {
    this.getAllCountries();
    if (this.props.title === "Requesting Job Match") {
      axios
        .post(AppConstants.Jobs_API + "/specialities/getAllSpecialities_V1", {
          workType: this.props.workType
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            console.log("1")
            var originalArray = [
              { _id: "All", speciality: "All", label: "All", value: "All" },
              ...data.data,
            ];
            var specialityVal = [];
            if (this.props.speciality) {
              this.props.speciality.map((item) => {
                if (item.value) {
                  specialityVal.push(item.value);
                } else {
                  specialityVal.push(item);
                }
              });
            }
            if (specialityVal.length > 0) {
              const myArrayFiltered = originalArray.filter((array) =>
                specialityVal.some((filter) => filter === array.value)
              );
              if (myArrayFiltered.length > 0) {
                this.setState({
                  selectedSpecialities: myArrayFiltered,
                  value: myArrayFiltered,
                  specialityOriginal: originalArray,
                  items: originalArray,
                });
                this.specialityFunction(this.props.speciality);
              } else{
                this.setState({
                  specialityOriginal: originalArray,
                  items: originalArray,
                });
              }
            } else {
              this.setState({
                specialityOriginal: originalArray,
                items: originalArray,
              });
            }
          }
        });
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getAllCertifications_v1", {
          workType: this.props.workType
        }
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            var originalArray = [
              {
                _id: "All",
                certification: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ];
            var certVal = [];
            if (this.props.certification) {
              this.props.certification.map((item) => {
                if (item.value) {
                  certVal.push(item.value);
                } else {
                  certVal.push(item);
                }
              });
            }
            if (certVal.length > 0) {
              const myArrayFiltered = originalArray.filter((array) =>
                certVal.some((filter) => filter === array.value)
              );
              if (myArrayFiltered.length > 0) {
                this.setState({
                  selectedCerts: myArrayFiltered,
                  certification: myArrayFiltered,
                  certifications: originalArray,
                  certsOriginal: originalArray,
                });
                this.certFunction(this.props.certification);
              }else{
                this.setState({
                  certifications: originalArray,
                  certsOriginal: originalArray,
                });
              }
            } else {
              this.setState({
                certifications: originalArray,
                certsOriginal: originalArray,
              });
            }
          }
        });
    }
    subjectVal = [];
    const componentState = this;
    let userId = localStorage.getItem("id");
    axios
      .post(AppConstants.Jobs_API + "/jobs/getJob/" + this.props.jobId, {
        nurseId: userId ? userId : "",
      })
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data) {
          var jobData = data.data;
          // console.log(data.data);
          componentState.setState({
            jobTitle: jobData.title,
            jobId: jobData._id,
            jobProviderId: jobData.providerJobId,
            location: jobData.city,
            startDate: jobData.start_date,
            endDate: jobData.end_date,
            shift: jobData.shift,
            positions: jobData.positions,
          });
        }
      });
    if (this.props) {
      if (this.props.selectedDurationShifts) {
        this.props.selectedDurationShifts.map((item, index) => {
          subjectVal.push(item.value);
        });
      }
      if (this.props.searchText) {
        subjectVal.push(this.props.searchText);
      }
      if (this.props.dateRegion) {
        subjectVal.push(this.props.dateRegion);
      }
    }
    if (
      (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
    ) {
      this.setState({ dropdownStatus: true });
    }
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "firstName") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value === "") {
        this.setState({ lastNameError: true });
      } else {
        this.setState({ lastNameError: false });
      }
    }
    if (e.target.name === "email") {
      if (e.target.value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (e.target.name === "speciality") {
      if (e.target.value === "") {
        this.setState({ specialityError: true });
      } else {
        this.setState({ specialityError: false });
      }
    }
  };
  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }
  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value,
      phone_error: false,
    });
  };
  handleTimings(event) {
    var options = [];
    let timings = this.state.timings;
    timings.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.timings.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    this.setState({ timings: timings, check_timings: options });
  }
  handleContactMethod(event) {
    var options = [];
    let contact_methods = this.state.contact_methods;
    contact_methods.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.contact_methods.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ contact_methodsError: true });
    } else {
      this.setState({ contact_methodsError: false });
    }
    this.setState({
      contact_methods: contact_methods,
      check_contact_methods: options,
    });
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter email";
      ReactDOM.findDOMNode(this.refs.email).focus();
    }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    if (!this.state.lastName) {
      formIsValid = false;
      errors["lastName"] = "Please enter last name.";
      ReactDOM.findDOMNode(this.refs.lastName).focus();
    }
    if (!this.state.firstName) {
      formIsValid = false;
      errors["firstName"] = "Please enter first name";
      ReactDOM.findDOMNode(this.refs.firstName).focus();
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number.";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    this.setState({
      errors: errors,
      firstNameError: true,
      lastNameError: true,
      emailError: true,
      phone_error: true,
    });
    return formIsValid;
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      var formData = {};
      var specArray = [];
      if (this.state.selectedSpecialities.length > 0) {
        this.state.selectedSpecialities.map((item, index) => {
          specArray.push(item.value);
        });
      }
      var certArray = [];
      if (this.state.selectedCerts.length > 0) {
        this.state.selectedCerts.map((item, index) => {
          certArray.push(item.value);
        });
      }
      var cityName = [];
      if (this.props.city) {
        // console.log("line 6286", this.props.city);
        cityName = this.props.city.split(",");
      }
      // console.log("line 628", this.props.city);
      var stateArray = [];
      if (this.props.state) {
        this.props.state.map((item, index) => {
          stateArray.push(item.value);
        });
      }
      if (this.props.title === "Requesting Job Match") {
        formData = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          code: this.state.code,
          phone: this.state.phone,
          email: this.state.email,
          questions: this.state.questions,
          title: this.props.title,
          speciality: JSON.stringify(specArray),
          certification: JSON.stringify(certArray),
          city: cityName[0],
          state: JSON.stringify(stateArray),
          workType: this.props.workType,

        };
      } else {
        formData = {
          jobTitle: this.state.jobTitle,
          jobId: this.state.jobId,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          contact_methods: JSON.stringify(this.state.check_contact_methods),
          timings: JSON.stringify(this.state.check_timings),
          email: this.state.email,
          phone: this.state.phone,
          code: this.state.code,
          jobProviderId: this.state.jobProviderId,
          questions: this.state.questions,
          title: this.props.title,
          workType: this.props.workType,
        };
      }
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/sendMailToContactRecruiter",
          formData
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true });
            if (this.props.title !== "Requesting Job Match") {
              swal(
                "Success",
                `Thank you for your interest! A compensation specialist will be in touch with you within 24 hours between Monday-Friday.\n\n Ready to start your adventure now?`,
                "success",
                {
                  buttons: {
                    cancel: "Ok",
                    catch: {
                      text: "Apply",
                      value: "catch",
                    },
                  },
                }
              ).then((value) => {
                switch (value) {
                  case "catch":
                    window.location.href = "/apply-job/" + this.state.jobId;
                    break;
                }
              });
              this.handleCancel();
            } else {
              setTimeout((e) => {
                this.handleCancel();
              }, 3000);
            }
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-success fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  handleCancel() {
    this.props.handleModal();
    this.setState({
      name: "",
      phone: "",
      message: "",
      isLoading: false,
    });
  }
  handleSpecialityFocus() {
    if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedSpecialities.length === 0
    ) {
      this.setState({
        items: this.state.specialityOriginal,
        specialitiesDuplicate: this.state.specialityOriginal,
        specialityCopy: this.state.specialityOriginal,
      });
    } else if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedSpecialities.length !== 0
    ) {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modal}
        toggle={this.props.handleModal}
        className="modal-lg contact-rect recruiter-modal request-job-sec-modal"
        id="Tn-popup"
        style={{ color: "#25408f", fontWeight: "500" }}
      >
        <ModalHeader
          toggle={this.handleCancel}
          className="text-center pt-4 pb-1"
          style={{ color: "#25408f" }}
        >
          {/* Contact Recruiter */}
          <span style={{ color: "#25408f" }}>
            {/* Request Estimated Weekly Gross */}
            {this.props.title}
          </span>
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="apply-form registr-info main-page">
            <Card
              className="w-100 p-0 m-0 md-pop pb-5"
              style={{ borderRadius: "0px 0px 25px 25px" }}
            >
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md="6" className="block">
                      <FormGroup>
                        <Label for="FirstName">
                          First Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          id="FirstName"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                          ref="firstName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.firstNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["firstName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="6" className="block">
                      <FormGroup>
                        <Label for="Last Name">
                          Last Name
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          value={this.state.lastName}
                          placeholder="Last Name"
                          id="LastName"
                          onChange={this.handleChange}
                          ref="lastName"
                          autoComplete="off"
                        />
                        <span>
                          {this.state.lastNameError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["lastName"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="codephone-blk">
                    <div class="block small-block col-1">
                      <FormGroup>
                        <Label for="Code">
                          Country Code
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="select"
                          value={this.state.code}
                          name="code"
                          ref="code"
                          onChange={this.handleCodeChange}
                          id="Code"
                        >
                          {this.state.countries.map((item) => {
                            return (
                              <option
                                data-countryCode={item.phoneCode}
                                value={"+" + item.phoneCode}
                              >
                                {item.phoneCode === 1 ? "USA" : item.name}{" "}
                                {"+" + item.phoneCode}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </div>
                    <div class="block widht-40 col-5 phone-input">
                      <FormGroup>
                        <Label for="Phone">
                          Phone
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <NumberFormat
                          format="(###) ###-####"
                          mask=""
                          name="phone"
                          placeholder="Phone Number"
                          onValueChange={this.inputChangedHandlerContact}
                          value={this.state.phone}
                          className="form-control"
                          ref="phone"
                        />
                        <span>
                          {this.state.phone_error === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["phone"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                    <span className="code-validation" id="mbl-validation">
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode["code"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <div class="block col-5">
                      <FormGroup>
                        <Label for="Email">
                          Email
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          ref="email"
                          autoComplete="off"
                          className="w-100"
                        />
                        <span>
                          {this.state.emailError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["email"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </div>
                    <span className="code-validation col" id="desk-validation">
                      {this.state.codeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errorsCode["code"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Row>
                  {this.props.title === "Requesting Job Match" ? (
                    <Row className="justify-content-center finder-block">
                      <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                        <div
                          // className="block_new"
                          onClick={() => {
                            this.toggleOpenCertification();
                          }}
                        >
                          <label>Certification</label>
                          <ReactMultiSelectCheckboxes
                            options={this.state.certifications}
                            value={this.state.selectedCerts}
                            onChange={(value, event) => {
                              this.handleCertChange(value, event);
                            }}
                            isSearchable={true}
                            placeholderButtonLabel="All"
                            onFocus={this.handleCertFocus.bind(this)}
                            menuIsOpen={
                              this.state.dropdownStatus === true
                                ? ""
                                : this.state.certDropdown
                            }
                            onBlur={this.handleCertBlur.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                        <div
                          // className="block_new"
                          onClick={() => {
                            this.toggleOpenSpeciality();
                          }}
                        >
                          <label>Specialty</label>
                          <ReactMultiSelectCheckboxes
                            options={this.state.items}
                            value={this.state.selectedSpecialities}
                            onChange={(value, event) => {
                              this.handleSpecChange(value, event);
                            }}
                            isSearchable={true}
                            placeholderButtonLabel="All"
                            onFocus={this.handleSpecialityFocus.bind(this)}
                            menuIsOpen={
                              this.state.dropdownStatus === true
                                ? ""
                                : this.state.specialityDropdown
                            }
                            onBlur={this.handleSpecialityBlur.bind(this)}
                          />
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}

                  <div className="check-sec codephone-blk row">
                    <div className="block col">
                      <h5 style={{ color: "#25408f" }}>
                        Questions or Comments?
                      </h5>
                      <div className="check-sec">
                        <span className="">
                          <Input
                            type="textarea"
                            name="questions"
                            onChange={this.handleChange}
                            value={this.state.questions}
                            ref="questions"
                            placeholder="Questions or Comments"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="btn-block">
                    <Button
                      value="Submit"
                      className="btn-reg"
                      disabled={this.state.button_disable}
                      style={{ backgroundColor: "#334ca4" }}
                    >
                      Send
                    </Button>
                    {this.state.isLoading ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.errMsg}
                </Form>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default ContactRecruiterPopUp;
