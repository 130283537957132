import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import axios from "axios";
import { FaAngleDoubleLeft } from "react-icons/fa";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";

// function this.state() {
class PrivacyForm extends React.Component {
  constructor() {
    super();
    this.state = {
      website: "",
      name: "",
      email: "",
      requestAs: "",
      rightsOf: "",
      requestTo: "",
      details: "",
      termsPenalty: false,
      termsDeletion: false,
      termsValidate: false,
      condi: true,
    };
  }

  handleOnChange = async (e) => {
    if (e.target.value == "OTHERS") {
      this.setState({ requestTo: "null", rightsOf: e.target.value });
    } else {
      this.setState({ rightsOf: e.target.value, requestTo: "" });
    }
  };

  handleSubmit = async (e) => {
    const that = this;
    var config = {
      method: "POST",
      url: AppConstants.API + "/nurse/sendPrivacyFormMail",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        website: this.state.website,
        name: this.state.name,
        email: this.state.email,
        requestAs: this.state.requestAs,
        rightsOf: this.state.rightsOf,
        requestTo: this.state.requestTo,
        details: this.state.details,
        termsPenalty: this.state.termsPenalty,
        termsDeletion: this.state.termsDeletion,
        termsValidate: this.state.termsValidate,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          NotificationManager.success("Mail sent successfully!", "", 3000);
          that.setState({
            website: "",
            name: "",
            email: "",
            requestAs: "",
            rightsOf: "",
            requestTo: "",
            details: "",
            termsPenalty: false,
            termsDeletion: false,
            termsValidate: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  render() {
    console.log(
      this.state.name,
      this.state.website,
      this.state.email,
      this.state.requestAs,
      this.state.rightsOf,
      this.state.rightsOf == "OTHERS" ? true : this.state.requestTo,
      this.state.details,
      this.state.termsPenalty,
      this.state.termsDeletion,
      this.state.termsValidate
    );
    return (
      <section className="registr-info main-page">
        <div className="container">
          <div
            className="preview-bttn"
            style={{ marginBottom: "20px", textAlign: "right" }}
          >
            <button
              class="button back-btn float-none"
              onClick={() => this.props.history.push("/privacy-policy/")}
            >
              <FaAngleDoubleLeft />
              <span>Back</span>
            </button>
          </div>
          <div className="data-form">
            <div class="title">Data Subject Access Request Form</div>
            <p class="text-center">
              Please fill in the information below. The website administrator or
              data protection officer will be notified of your request within 24
              hours, and will need an appropriate amount of time to respond.
            </p>
            <Row style={{ marginTop: "20px" }}>
              <Col xs="12" sm="6" className="block">
                <FormGroup>
                  <Label
                    for="FirstName"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    Website <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="website"
                    placeholder="Travel Nurse Portal/travelnursesinc.com"
                    value={this.state.website}
                    id="website"
                    onChange={(e) => this.setState({ website: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="6" className="block">
                <FormGroup>
                  <Label
                    for="FirstName"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    Full Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter your full name"
                    value={this.state.name}
                    id="name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" className="block">
                <FormGroup>
                  <Label
                    for="FirstName"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    What email address do you use to access the above website /
                    app? <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Enter your email ddress"
                    value={this.state.email}
                    id="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" className="block check-box-block list-boxes">
                <FormGroup>
                  <Label
                    for="Teaching_Facility col-sm-12"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    Are you making this request on behalf of yourself or someone
                    else ? <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="btn-group  col-sm-12" data-toggle="buttons">
                    <label
                      className="btn btn-default active"
                      style={{ maxWidth: "100%", margin: "0" }}
                    >
                      <Input
                        type="radio"
                        id="employerTeaching1"
                        name="requestAs"
                        value="I am making this request on my own behalf."
                        onChange={(e) =>
                          this.setState({ requestAs: e.target.value })
                        }
                        checked={
                          this.state.requestAs ==
                          "I am making this request on my own behalf."
                            ? true
                            : false
                        }
                      />
                      <span>I am making this request on my own behalf.</span>
                    </label>
                  </div>
                  <div className="btn-group  col-sm-12" data-toggle="buttons">
                    <label
                      className="btn btn-default"
                      style={{ maxWidth: "100%", margin: "0" }}
                    >
                      <Input
                        type="radio"
                        id="employerTeaching0"
                        name="requestAs"
                        value="The person, or the parent / guardian of the person, whose name appears above."
                        onChange={(e) =>
                          this.setState({ requestAs: e.target.value })
                        }
                        checked={
                          this.state.requestAs ==
                          "The person, or the parent / guardian of the person, whose name appears above."
                            ? true
                            : false
                        }
                      />
                      <span>
                        The person, or the parent / guardian of the person,
                        whose name appears above.
                      </span>
                    </label>
                  </div>
                  <div className="btn-group  col-sm-12" data-toggle="buttons">
                    <label
                      className="btn btn-default"
                      style={{ maxWidth: "100%", margin: "0" }}
                    >
                      <Input
                        type="radio"
                        id="employerTeaching0"
                        name="requestAs"
                        value="An agent authorized by the consumer to make this request on their behalf."
                        onChange={(e) =>
                          this.setState({ requestAs: e.target.value })
                        }
                        checked={
                          this.state.requestAs ==
                          "An agent authorized by the consumer to make this request on their behalf."
                            ? true
                            : false
                        }
                      />
                      <span>
                        An agent authorized by the consumer to make this request
                        on their behalf.
                      </span>
                    </label>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="12" className="block check-box-block list-boxes">
                <FormGroup>
                  <Label
                    for="Teaching_Facility col-sm-12"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    Under the rights of which law are you making this request?.
                  </Label>
                  <div
                    className="btn-group  col-sm-12"
                    data-toggle="buttons"
                    style={{ padding: "0" }}
                  >
                    <select
                      name="rightsOf"
                      className="form-control custom-dropdown"
                      value={this.state.rightsOf}
                      onChange={(e) => this.handleOnChange(e)}
                    >
                      <option value={""}>Choose Any</option>
                      <option value="GDPR">GDPR</option>
                      <option value="CCPA">CCPA</option>
                      <option value="OTHERS">OTHERS</option>
                    </select>
                  </div>
                </FormGroup>
              </Col>

              {this.state.rightsOf != "OTHERS" && (
                <Col xs="12" className="block check-box-block">
                  <FormGroup>
                    {this.state.rightsOf && (
                      <Label
                        for="Teaching_Facility col-sm-12"
                        style={{
                          fontWeight: "500",
                          color: "#000",
                          fontSize: "15px",
                        }}
                      >
                        I am submitting a request to ___________
                      </Label>
                    )}
                    {this.state.rightsOf == "GDPR" ? (
                      <div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default active"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching1"
                              name="requestTo"
                              value="Confirm that my personal information is being processed"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Confirm that my personal information is being processed"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Confirm that my personal information is being
                              processed
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Access my personal information"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Access my personal information"
                                  ? true
                                  : false
                              }
                            />
                            <span>Access my personal information</span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Edit / correct my personal information"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Edit / correct my personal information"
                                  ? true
                                  : false
                              }
                            />
                            <span>Edit / correct my personal information</span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Have my personal information deleted"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Have my personal information deleted"
                                  ? true
                                  : false
                              }
                            />
                            <span>Have my personal information deleted</span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Restrict the processing of my personal information"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Restrict the processing of my personal information"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Restrict the processing of my personal information
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Ask a question about ThinkProxi's privacy policy"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Ask a question about ThinkProxi's privacy policy"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Ask a question about ThinkProxi's privacy policy
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Withdraw my consent to the processing of my personal information"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Withdraw my consent to the processing of my personal information"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Withdraw my consent to the processing of my
                              personal information
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Deny ThinkProxi the right to use my personal information for purposes of direct marketing, including profiling"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Deny ThinkProxi the right to use my personal information for purposes of direct marketing, including profiling"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Deny ThinkProxi the right to use my personal
                              information for purposes of direct marketing,
                              including profiling
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Other (please specify in the comment box below)"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Other (please specify in the comment box below)"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Other (please specify in the comment box below)
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : this.state.rightsOf == "CCPA" ? (
                      <div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default active"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching1"
                              name="requestTo"
                              value="Know what information is being collected from me"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Know what information is being collected from me"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Know what information is being collected from me
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Have my information deleted"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Have my information deleted"
                                  ? true
                                  : false
                              }
                            />
                            <span>Have my information deleted</span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Opt out of having my data sold to third parties"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Opt out of having my data sold to third parties"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Opt out of having my data sold to third parties
                            </span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Opt in to the sale of my personal data"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Opt in to the sale of my personal data"
                                  ? true
                                  : false
                              }
                            />
                            <span>Opt in to the sale of my personal data</span>
                          </label>
                        </div>
                        <div
                          className="btn-group  col-sm-12"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-default"
                            style={{ maxWidth: "100%", margin: "0" }}
                          >
                            <Input
                              type="radio"
                              id="employerTeaching0"
                              name="requestTo"
                              value="Other (please specify in the comment box below)"
                              onChange={(e) =>
                                this.setState({ requestTo: e.target.value })
                              }
                              checked={
                                this.state.requestTo ==
                                "Other (please specify in the comment box below)"
                                  ? true
                                  : false
                              }
                            />
                            <span>
                              Other (please specify in the comment box below)
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              )}
              <Col xs="12" className="block">
                <FormGroup>
                  <Label
                    for="FirstName"
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "15px",
                    }}
                  >
                    Please leave details regarding your action request or
                    question. <span style={{ color: "red" }}>*</span>
                  </Label>
                  <textarea
                    placeholder="This is an awesome comment box"
                    rows="20"
                    name="details"
                    id="comment_text"
                    cols="40"
                    class="ui-autocomplete-input"
                    autocomplete="off"
                    role="textbox"
                    aria-autocomplete="list"
                    aria-haspopup="true"
                    onChange={(e) => this.setState({ details: e.target.value })}
                    value={this.state.details}
                  ></textarea>
                </FormGroup>
              </Col>

              <Col xs="12" className="block check-box-grid">
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="termsPenalty"
                    value={this.state.termsPenalty}
                    onChange={(e) =>
                      this.setState({ termsPenalty: e.target.checked })
                    }
                    checked={this.state.termsPenalty ? true : false}
                  />
                  <Label>
                    Under penalty of perjury, I declare all the above
                    information to be true and accurate.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="termsDeletion"
                    value={this.state.termsDeletion}
                    onChange={(e) =>
                      this.setState({ termsDeletion: e.target.checked })
                    }
                    checked={this.state.termsDeletion ? true : false}
                  />
                  <Label>
                    I understand that the deletion or restriction of my personal
                    data is irreversible and may result in the termination of
                    services with Travel Nurses Inc.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="termsValidate"
                    value={this.state.termsValidate}
                    onChange={(e) =>
                      this.setState({ termsValidate: e.target.checked })
                    }
                    checked={this.state.termsValidate ? true : false}
                  />
                  <Label>
                    I understand that I will be required to validate my request
                    by email, and I may be contacted in order to complete the
                    request.
                  </Label>
                </FormGroup>
              </Col>
              <div
                className="col-md-12"
                style={{ textAlign: "right", marginTop: "20px" }}
              >
                <button
                  className="blue-bttn"
                  disabled={
                    this.state.name &&
                    this.state.website &&
                    this.state.email &&
                    this.state.requestAs &&
                    this.state.rightsOf &&
                    this.state.requestTo &&
                    this.state.details &&
                    this.state.termsPenalty &&
                    this.state.termsDeletion &&
                    this.state.termsValidate
                      ? false
                      : true
                  }
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Submit{" "}
                </button>
              </div>
            </Row>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivacyForm;
