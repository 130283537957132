import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import images from "../Components/images";
import Subscribeblock from "../Components/subscribe-block";
// import Destination from "../Components/destination-block";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import styles from "../Styles/housing-info.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FacebookProvider, Group } from "react-facebook";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import Pagination1 from "./pagination";
import Popup from "reactjs-popup";
import { ExternalLink } from "react-external-link";
import ReactPaginate from 'react-paginate';
class HousingInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      housing_data: [],
      currentPage: 1,
      pageSizes: [4, 8, 10, 20],
      selectedPageSize: 4,
      selectedOrderOption: "",
      searchText: "",
      state_city: [],
      stateFilter: "",
      cityFilter: "",
      house_links: [],
    };
    this.handlePageValues = this.handlePageValues.bind(this);
    this.onSearchKey = this.onSearchKey.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleFilterData = this.handleFilterData.bind(this);
  }
  handleFilterData(e) {
    let page = 1;
    var filter = e.target.value.split(",").map(Number);
    var filterData = {
      state: filter[1],
      city: filter[0],
    };
    this.setState({ stateFilter: filter[1], cityFilter: filter[0] });
    this.updateResourcesList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      filter[1],
      filter[0]
    );
  }
  handlePageValues(e) {
    // console.log(e.target.value)
    this.setState({ [e.target.name]: parseInt(e.target.value) }, () => {
      this.onChangePage(1);
    });
    let page = 1;
    this.updateResourcesList(
      page,
      parseInt(e.target.value),
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.stateFilter,
      this.state.cityFilter
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(AppConstants.CMS_API + "/properties/getPropertiesInfo")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ state_city: data.data });
      });
    if (this.props.location.state) {
      if (this.props.location.state.state) {
        this.state.stateFilter = this.props.location.state.state;
      }
      if (this.props.location.state.city) {
        this.state.cityFilter = this.props.location.state.city;
      }
      this.setState({
        filterData:
          this.props.location.state.city +
          "," +
          this.props.location.state.state,
      });
    }
    this.updateResourcesList(
      this.state.currentPage,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.stateFilter,
      this.state.cityFilter
    );
    axios
      .get(AppConstants.CMS_API + "/housing_links/getAllLinks")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status) {
          this.setState({ house_links: data.data });
        }
      });
  }

  onChangePage = (page) => {
    this.updateResourcesList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.stateFilter,
      this.state.cityFilter
    );
    this.setState({
      currentPage: page,
    });
  };
  onSearchClick(e) {
    e.preventDefault();
    let page = 1;
    this.updateResourcesList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.stateFilter,
      this.state.cityFilter
    );
    this.setState({ currentPage: page });
  }
  onSearchKey = (e) => {
    // if (e.key === "Enter") {
    this.setState({ searchText: e.target.value });
    // console.log(e.key)
    let page = 1;
    this.updateResourcesList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      e.target.value,
      this.state.stateFilter,
      this.state.cityFilter
    );
    this.setState({ currentPage: page });
  };
  updateResourcesList(
    currentPage,
    selectedPageSize,
    selectedOrderOption,
    searchText,
    stateFilter,
    cityFilter
  ) {
    // console.log("searchText",searchText)
    if (searchText && searchText !== "") {
      searchText = searchText;
    }
    // else if(this.state.searchText && this.state.searchText !== "") {
    //   searchText = this.state.searchText;
    // }
    else {
      searchText = "";
    }
    let tablefilterdata = {
      page: currentPage,
      sizeperpage: selectedPageSize,
      sortname: selectedOrderOption,
      serchtext: searchText,
      defaultSearchText: searchText,
      state: stateFilter,
      city: cityFilter,
    };
    axios
      .post(
        AppConstants.CMS_API + "/properties/getAllPropertiesForFrontEnd",
        tablefilterdata
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        // console.log(data.data)
        this.setState({
          totalPage: data.totalPage,
          housing_data: data.data,
        });
      });
  }
  handlePageClick = (data) => {
    this.onChangePage(data.selected+1);
  };
  render() {
    // window.scrollTo(0, 0);
    const settings = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="housing-info">
        <Helmet>
          <title>{AppConstants.Housing_Info}</title>
          <meta
            name="description"
            content="Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
          />
          <meta name="og:type" content="website" />
          <meta name="og:title" content={AppConstants.Housing_Info} />
          <meta name="og:url" content="" />
          <meta property="og:image" content="" />
          <meta
            property="og:description"
            content="Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
          />
          <meta property="og:site_name" content="Traveling Nurses Inc" />
          <meta name="twitter:card" content="summary_large_image"></meta>
        </Helmet>
        <div className="inner-banner">
          <figure className="pic">
            {images.map((e, idx) => (
              <img src={e.Housingbanner} alt={"Housing Info - "+idx+ " - Travel Nurses Inc"}/>
            ))}
          </figure>
        </div>
        <div className="main-content pad-tb">
          <Container>
            <h1 class="title">{AppConstants.Housing_Info}</h1>
            <p className="text-center">{AppConstants.Sub_Housing_Info}</p>
            <Row className="inner-content">
              <Col xs="9" className="left-block">
                {/* Filter Block  Start Here */}
                <div className="filter-blk">
                  <div className="filter-fielld show-num">
                    <label>Show</label>
                    <select
                      className="select-box"
                      value={this.state.selectedPageSize}
                      name="selectedPageSize"
                      onChange={this.handlePageValues}
                    >
                      {this.state.pageSizes.map((page, index) => {
                        return <option value={page}>{page}</option>;
                      })}
                    </select>
                  </div>

                  <div className="filter-fielld">
                    <div className="filter-value">
                      <label>Location</label>
                      <select
                        className="select-box"
                        value={this.state.filterData}
                        name="filterData"
                        onChange={this.handleFilterData}
                      >
                        <option value={"" + "," + ""}>City, State</option>
                        {this.state.state_city.map((house, index) => {
                          return (
                            <option value={house.city + "," + house.state}>
                              {house.city_name},{house.state_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="filter-fielld">
                    <label>Search</label>
                    <form class="form-block">
                      <input
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={this.onSearchKey}
                      />
                      <button
                        type="submit"
                        class="button"
                        onClick={this.onSearchClick}
                      ></button>
                    </form>
                  </div>
                </div>
                {/* Filter Block  End Here */}

                {/* Details Block Start Here */}
                <div className="detail-block pb-50">
                  {this.state.housing_data.length !== 0 ? (
                    this.state.housing_data.map((house, index) => {
                      return (
                        <div className="block-box block">
                          <ul className="house-list">
                            {house.propertyImage ? (
                              <Popup
                                trigger={
                                  <li
                                    className="house"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {house.propertyName}
                                  </li>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <div>
                                    <img
                                      src={house.propertyImage}
                                      style={{ width: 400, height: 500 }}
                                      alt = {house.propertyName+" - Travel Nurses Inc"}
                                    />
                                    <a
                                      className="close"
                                      style={{ cursor: "pointer" }}
                                      onClick={close}
                                    >
                                      &times;
                                    </a>
                                  </div>
                                )}
                              </Popup>
                            ) : (
                              <li className="house">{house.propertyName}</li>
                            )}

                            {/* <li className="house">{house.propertyName}</li> */}
                            <li className="city">
                              {house.city_name}, {house.state_name}
                              <br />
                              {house.address}
                            </li>
                            {house.phone ? (
                              <li className="phone">
                                {house.phone.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) ($2)-$3"
                                )}
                              </li>
                            ) : (
                              ""
                            )}

                            {house.website ? (
                              <li className="web-site">
                                <a href={house.website} target="_blank">
                                  {house.website}
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <span className="no-data">No data available.</span>
                  )}
                </div>
                {/* Details Block End Here */}
                {this.state.housing_data.length !== 0 ? (
                  <div className="filter-blk bottom-filter">
                    <div className="filter-fielld show-num float-left">
                      <label>Show</label>
                      <select
                        className="select-box"
                        value={this.state.selectedPageSize}
                        name="selectedPageSize"
                        onChange={this.handlePageValues}
                      >
                        {this.state.pageSizes.map((page, index) => {
                          return <option value={page}>{page}</option>;
                        })}
                      </select>
                    </div>
                    {/* <Pagination1
                      aria-label="Page navigation"
                      className="pagination-blk float-right"
                      currentPage={this.state.currentPage}
                      totalPage={this.state.totalPage}
                      onChangePage={(i) => this.onChangePage(i)}
                    /> */}
                    <ReactPaginate
                      breakLabel={"....."}
                      breakClassName={"break-me page-link false mx-0"}
                      pageCount={this.state.totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      // onPageChange={this.onChangePage(i)}
                      containerClassName={"pagination pagination-blk d-flex"}
                      subContainerClassName={"pages pagination page-item "}
                      disabledClassName={"disabledcustom-pagi"}
                      previousLinkClassName={"prve-next  page-link"}
                      nextLinkClassName={"prve-next  page-link"}
                      activeClassName={"active"}
                      pageClassName={"false page-link"}
                      pageLinkClassName={"anchor class"}
                    />
                    <div className="clear"></div>
                  </div>
                ) : (
                  ""
                )}
              </Col>

              <Col xs="3" className="right-block">
                <div className="event-sec">
                  <div className="head-title">{AppConstants.Housing}</div>
                  <div className="facebook-blk block">
                    <FacebookProvider appId="286799819411300">
                      <Group
                        href="https://www.facebook.com/groups/TravelNursesincHousing"
                        width="263"
                        showSocialContext={true}
                        showMetaData={true}
                      />
                    </FacebookProvider>
                  </div>
                </div>
                {this.state.house_links.length !== 0 ? (
                  <div className="event-sec housing-opt">
                    <div className="head-title">
                      {AppConstants.Housing_Options}
                    </div>
                    <Card className="content text-left">
                      <CardBody>
                        <CardText>
                          <ul className="list-arrow">
                            {this.state.house_links.map((item, index) => {
                              return (
                                <li>
                                  {item.icon ? (
                                    <span className="img">
                                      <img src={item.icon} alt = {"house-icon - "+index+" - Travel Nurses Inc"}/>
                                    </span>
                                  ) : (
                                    <span>{item.name[0]}</span>
                                  )}

                                  <a href={item.url} target="_blank">
                                    {item.name}
                                  </a>
                                </li>
                              );
                            })}
                            {/* <li>
                            <a href="#">airbnb</a>
                          </li>
                          <li>
                            <a href="#">zillow</a>
                          </li> */}
                          </ul>
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default HousingInfo;
