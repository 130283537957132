import React, { useEffect, useRef, useState } from "react";
import { Map, TileLayer } from "react-leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from "leaflet";
import { useLeaflet } from "react-leaflet";
import axios from "axios";
import AppConstants from "../AppConstants";
import useComponentVisible from "./useComponentVisible";


const Leaflet = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [position, setPosition] = useState([37.0902, -95.7129]);
  const mapStyle = { height: "90vh" };
  const [loading, setLoading] = useState(true);
  const [resData, setData] = useState([]);
  const [markers, setmarkers] = useState([]);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [jobsByZipCode, setjobsByZipCode] = useState([]);
  const { hotJobsFlag } = props;
  const { city, cityState, state, speciality, certification, searchRadius, shift } =
    props.filters;




  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef();
  // console.log(props, "props");

  let cityVal, stateVal;
  if (props.filters.city.length > 0) {
    cityVal = props.filters.city;
  }

  if (props.filters.state.length > 0) {
    stateVal = props.filters.state;
  }

  useEffect(() => {
    function handler(event) {
      // console.log(event, "event");
      if (!modalRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);
  useEffect(() => {
    const shiftdata = [];
    setShow(false);
    shift.map((item) => {
      shiftdata.push(item.value)
    })
    setLoading(true);

    axios
      .post(AppConstants.Jobs_API + "/jobs/getJobsForMapView", {
        page: 1,
        sizeperpage: 10,
        sortname: "",
        searchtext: "",
        defaultSearchText: "",
        speciality: speciality ? speciality : [],
        state: state ? state : [""],
        cityFullName: "",
        city: city ? city : "",
        cityState: cityState ? cityState : "",
        shift: shiftdata ? shiftdata : [],
        searchRadius: searchRadius ? searchRadius : "20",
        duration: [],
        startDate: "",
        toStartDate: "",
        certification: certification ? certification : [],
        pinned: hotJobsFlag ? hotJobsFlag : false,
        // pinned: true,
      })
      .then((res) => {
        setData([...res.data.data]);
        city.length > 0 ||
          cityState.length > 0 ||
          (state.length > 0 &&
            setPosition([
              res.data.data[0].location.coordinates[1],
              res.data.data[0].location.coordinates[0],
            ]));
        return res.data.data;
      })
      .then((resMark) => {
        let markersArray = [];
        for (let i = 0; i < resMark.length; i++) {
          // if (resMark[i].longitude === 0) {
          //   console.log(resMark[i].state, "resMark")
          // }
          markersArray.push({
            position: {
              lng: resMark[i].longitude,
              lat: resMark[i].latitude,
              pinned: resMark[i].pinned,
            },
          });
        }
        setmarkers(markersArray);
      })
      .then(() => setLoading(false))
      .catch((e) => console.log(e, "error"));
  }, [
    hotJobsFlag,
    city,
    cityState,
    state,
    speciality,
    certification,
    searchRadius,
  ]);

  // const customIcon = new L.Icon({
  //   iconUrl: !hotJobsFlag ? signleJObMarker : signleJObMarker2,
  //   iconSize: [25, 41],
  //   className: hotJobsFlag && "single-marker-fire-hot-jobs",
  // });

  const MarkerCluster = ({ markers, hotJobsFlag }) => {
    const { map } = useLeaflet();

    function iconCreateFunction(cluster) {
      // console.log(cluster.getAllChildMarkers(), "childdddddddddddddddddd");

      var clusterGroupmarkers = cluster.getAllChildMarkers();

      const clusterGroup = clusterGroupmarkers.find((cgm) => {
        // console.log(cgm.options.icon.options, "marker");
        return (
          cgm.options.icon.options.className ===
          "marker-cluster marker-cluster-fire-icon marker-clustermarker-cluster-fire-icon"
        );
      });
      // console.log(clusterGroup, "clusterGroup")
      var childCount = cluster.getChildCount();
      var c = clusterGroup
        ? "marker-cluster-fire-icon marker-clustermarker-cluster-fire-icon"
        : "";
      return new L.DivIcon({
        html: "<div><span>" + childCount + "</span></div>",
        className: "marker-cluster" + c,
        iconSize: new L.Point(40, 40),
      });
    }

    const markerClusters = new L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      iconCreateFunction: iconCreateFunction,
    }).addTo(map);

    useEffect(() => {
      map.invalidateSize();

      map && map.originalEvent && map.originalEvent.preventDefault();
      markerClusters.clearLayers();
      markerClusters.on("clusterclick", (e) => {
        if (markerClusters._zoom === 14) {
          // console.log(e, "============");
          var latFormat = e.layer._cLatLng.lat;
          // latFormat = Number(latFormat.toFixed(6));
          var lngformat = e.layer._cLatLng.lng;
          // lngformat = Number(lngformat.toFixed(6));
          axios
            .post(AppConstants.Jobs_API + "/jobs/getJobsForZipCodes", {
              lat: latFormat,
              lng: lngformat,
              pinned: hotJobsFlag,
              certification: certification ? certification : [],
              speciality: speciality ? speciality : [],
            })
            .then((res) => {
              setjobsByZipCode(res.data.data);
              handleShow();
            })
            .catch((e) => console.log(e, "error"));
        }
      });

      function markerPopUp(e) {
        // console.log(e, "event");
        var latFormat = e.sourceTarget.options.icon.options.lat;
        // latFormat = Number(latFormat.toFixed(6));
        var lngformat = e.sourceTarget.options.icon.options.lng;
        // lngformat = Number(lngformat.toFixed(6));
        axios
          .post(AppConstants.Jobs_API + "/jobs/getJobsForZipCodes", {
            lat: latFormat,
            lng: lngformat,
            pinned: hotJobsFlag,
            certification: certification ? certification : [],
            speciality: speciality ? speciality : [],
          })
          .then((res) => {
            setjobsByZipCode(res.data.data);
            handleShow();
          })
          .catch((e) => console.log(e, "error"));
      }

      let hotJoblayer = new L.layerGroup();
      let normalJobLayer = new L.layerGroup();

      markers.length > 0 &&
        markers.forEach(({ position }) => {
          let pinnedlayer = position.pinned ? hotJoblayer : normalJobLayer;
          let styleLayer = position.pinned
            ? "marker-cluster marker-cluster-fire-icon marker-clustermarker-cluster-fire-icon"
            : "marker-cluster";

          new L.marker(new L.LatLng(position.lat, position.lng), {
            icon: new L.DivIcon({
              html: "<div><span>" + 1 + "</span></div>",
              className: styleLayer,
              iconSize: new L.Point(40, 40),
              pinned: position.pinned,
              lat: position.lat,
              lng: position.lng,
            }),
          })

            .addTo(pinnedlayer)
            .on("click", markerPopUp);
        });
      markerClusters.addLayers([hotJoblayer, normalJobLayer]);
    }, [markers, map]);

    return null;
  };

  // var maxBounds = [
  //   [5.49955, -167.276413], //Southwest
  //   [83.162102, -52.23304], //Northeast
  // ];
  let ZoomObj;
  ZoomObj = cityVal || stateVal ? 6 : 3;

  return (
    <>
      <div
        className="container"
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {!loading ? (
          markers.length > 0 && resData.length > 0 ? (
            <Map
              center={position}
              zoom={ZoomObj}
              style={mapStyle}
              maxZoom={14}
            // maxBounds={maxBounds}
            >
              <TileLayer
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />

              <MarkerCluster markers={markers} hotJobsFlag={hotJobsFlag} />
            </Map>
          ) : (
            <p className="upcoming-text">Hot jobs not available.</p>
          )
        ) : (
          <div className="list-job pb-50 loader-center">
            {" "}
            <img
              src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/loader.gif"
              alt="No Image - Travel Nurses Inc"
              className="loader-center"
              style={{ width: 80, height: 80 }}
            />{" "}
          </div>
        )}

        <div>
          <div class="job-details-sec" ref={modalRef}>
            <div class="job-details-sec-in">
              {show && jobsByZipCode.length > 0 && (
                <button onClick={handleClose}>
                  <span>×</span>
                </button>
              )}
              {isComponentVisible &&
                show &&
                jobsByZipCode &&
                jobsByZipCode.map((item) => {
                  return (
                    <div class="map-details-grid" ref={ref}>
                      {item.pinned && (
                        <figure className="list-fire-icon">
                          <img src="https://df8de9x9ieyx9.cloudfront.net/bannerImages/tn-flame.gif" alt="" />
                        </figure>
                      )}
                      <div class="job-title-block table-wrapper">
                        <aside>
                          {item.title.length > 35 ? (
                            <h2>{`${item.title.substring(0, 35)}... `}</h2>
                          ) : (
                            <h2>{item.title}</h2>
                          )}

                          <p>{item.jobSubSpeciality}</p>
                        </aside>
                        <aside class="Landmark">
                          <span class="city-name">
                            {item.city}, {item.state}
                          </span>
                        </aside>
                      </div>
                      <ul class="job-details row">
                        <li class="col-sm-4">
                          <h3>
                            Start Date: <span>{item.start_date}</span>
                          </h3>
                        </li>
                        <li class="col-sm-4">
                          <h3>
                            Duration: <span>{item.duration} Weeks</span>
                          </h3>
                        </li>
                        <li class="col-sm-4">
                          <h3>
                            Shift: <span>{item.shift}</span>
                          </h3>
                        </li>
                      </ul>
                      <div class="job-view-cont">
                        <div class="table-wrapper">
                          <aside>
                            <p>
                              Est.pay $: <span>${item.grossPay1}/week</span>
                            </p>
                          </aside>
                          <aside class="view-bttn">
                            <a
                              href={AppConstants.Job_Url + "/job/" + item._id}
                              class="blue-bttn"
                              target="_blank"
                            >
                              View Details
                            </a>
                          </aside>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaflet;
