import React, { useState } from "react";
import { Collapse, Button, CardBody, Card, Row } from "reactstrap";
import { FaDownload } from "react-icons/fa";
const Accordion = ({ items }) => {
  const [isActivePanel, setActivePanel] = useState({});
  const toggleItem = (index) => {
    setActivePanel((prevState) => ({
      ...prevState,
      [index]: !Boolean(prevState[index]),
    }));
  };
  return (
    <div>
      {items.map((item, index) => {
        const checkOpen = isActivePanel[index];
        return (
          <div className="TN-accordion" key={item.itemname}>
            <Button
              className={`w-100 text-left py-3  ${checkOpen ? "i-close i-closecheck" : "i-open i-opencheck"
                // checkOpen ? "i-open i-opencheck" : "i-close i-closecheck"
                }`}
              onClick={() => toggleItem(index)}
            >
              <span className="pr-3">{item.itemname}</span>
            </Button>
            <Collapse className="mx-1" isOpen={checkOpen}>
              <Card>
                <CardBody className="px-3 py-2">
                  {item.docDetails.map((item1, index) => {
                    return (
                      <Row
                        className="d-block p-2"
                        style={{
                          border: "solid 1px #ccc",
                          margin: "10px 0px",
                        }}
                      >
                        <div className="top-row pb-0">
                          {item1.document ? (
                            <a href={item1.document} download target="_blank">
                              <span style={{ color: "#21ace3" }} class="p-0">
                                {item1.title}
                              </span>{" "}
                              <FaDownload className="ml-1" />
                            </a>
                          ) : (
                            item1.title
                          )}{" "}
                          <br />
                          {item1.url ? (
                            <a
                              href={item1.url}
                              target="_blank"
                              style={{ color: "#25408f" }}
                            >
                              {item1.url}
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </Row>
                    );
                  })}
                </CardBody>
              </Card>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};
export default Accordion;
