import React, { useState, useEffect } from "react";

import axios from "axios";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import AppConstants from "../AppConstants";
import { BiSad } from "react-icons/bi";
import { Container } from "reactstrap";
import {
    NotificationManager
} from "react-notifications";

const RelaventJobs = ({ dashboardSaveAction }) => {
    const [jobsList, setJobsList] = useState([]);

    const replaceURL = (url) => {
        if (!url) {
            url =
                "https://travelnurses.s3.us-west-2.amazonaws.com/default-job-image.png";
        }
        const replaced = url.replace(
            "https://travelnurses.s3.us-west-2.amazonaws.com",
            AppConstants.Bucket_URL
        );
        return replaced;
    };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const certification = searchParams.get("certification") || "";
    const speciality = searchParams.get("speciality") || "";
    const workType = searchParams.get("workType") || "";

    useEffect(() => {
        const controller = new AbortController();

        const fetchJobs = async () => {
            try {
                const response = await axios.get(
                    `${AppConstants.Jobs_API}/jobs/relavent`,
                    {
                        params: {
                            certification,
                            speciality,
                            workType,
                        },
                        signal: controller.signal,
                    }
                );
                setJobsList(response.data.similarJobs || []);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    NotificationManager.error("Error fetching relevant jobs", "", 3000);
                }
            }
        };

        fetchJobs();

        return () => {
            controller.abort();
        };
    }, [dashboardSaveAction, certification, speciality, workType]);

    const JobCard = ({ job }) => {
        return (
            <div className="inner-content justify-content-center">
                <div className="job-info block-box position-relative">
                    <div
                        className="share-btn position-absolute bg-white share-rotate111"
                        style={{
                            right: "10px",
                            top: "8px",
                        }}
                    >

                    </div>
                    <ul>
                        <Link to={`/job/${job._id}`}>
                            <li className="job-location">

                                <img
                                    src={replaceURL(
                                        job.stateImages[
                                        Math.floor(
                                            Math.random() *
                                            job.stateImages.length
                                        )
                                        ]
                                    )}
                                    alt={
                                        job.title +
                                        " - " +
                                        job.city +
                                        " - " +
                                        job.state +
                                        " - Travel Nurses Inc"
                                    }
                                />

                            </li>
                        </Link>
                        <li className="job-position pt-4">
                            <h4 style={{ fontSize: "25px" }}>{job.degree}</h4>
                            {job.jobSubSpeciality ? (
                                <p
                                    style={{
                                        fontSize: "23px",
                                        color: "rgb(112, 112, 112)",
                                    }}
                                >
                                    {job.jobSubSpeciality}
                                </p>
                            ) : (
                                ""
                            )}
                            <span className="city-name">
                                {job.city + ", " + job.state}
                            </span>
                        </li>
                        <li className="job-time">
                            <p className="date">
                                Start Date: <em>{moment(job.startDate).format("MM/DD/YYYY")}</em>
                            </p>
                            <p className="duration">
                                Duration:{" "}
                                <em>
                                    {job.duration
                                        ? job.duration == 1
                                            ? job.duration + " Week"
                                            : job.duration + " Weeks"
                                        : "N/A"}
                                </em>
                            </p>
                            <p className="time">{job.shift}</p>
                        </li>

                        <li className="btn-section pt-0 pt-lg-4 pt-md-4 ">
                            {job.grossPay1 === 0 ? (
                                ""
                            ) : (
                                <p className="est-pay-res pb-0 text-right mr-3 relevent-jobs-pay">
                                    <h4 className="pb-0 relevent-jobs-pay" style={{ fontSize: "20px",color: "#333333", fontWeight: "bold" }}>
                                        Est.pay
                                        <span className="dollar-sign mx-1">&#36;</span>

                                    </h4>

                                    <h4
                                        className="pb-0"
                                        style={{ color: "rgb(51, 51, 51)" }}
                                    >
                                        <span className="relevent-jobs-pay"
                                        
                                            style={{ fontSize: "27px", fontWeight: "bold",color: "#333333"  }}
                                        >
                                            {"$" + job.grossPay}
                                        </span>{" "}
                                        / week
                                    </h4>
                                </p>
                            )}
                            <Link
                                to={{
                                    pathname: `/job/${job._id}`,

                                }}
                                className="button mt-0"
                            >
                                View Details
                            </Link>
                            <div className="clear"></div>
                        </li>
                    </ul>

                </div>
            </div>
        );
    };

    return (
        <>
            <div className="notfound-block text-center">
                <div className="not-found-page">
                    <Container>
                        <div className="not-found-page-in">
                            <BiSad />
                            <p>The job you are searching for has already been filled.</p>
                        </div>
                    </Container>
                </div>
            </div>
            {/* <p className="text-center">Relevant jobs.</p> */}
            <div class="sub-title text-center">Jobs Recommended for you</div>
            <Container>
                <div className="latestjobs text-center">
                    {jobsList && jobsList.length > 0 ? (
                        jobsList.map((job) => <JobCard key={job._id} job={job} />)
                    ) : (
                        <p className="upcoming-text">No Recommended Jobs Found</p>
                    )}
                </div>
                <div class="btn-block text-center mb-5">
                    <a
                        href={`/${location.search}`}
                        className="read-more"
                    >
                        See All Recommended Jobs.
                    </a>
                </div>
            </Container>
        </>
    );
};

export default RelaventJobs;
