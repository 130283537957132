import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ProgressIcon from "../images/progress-icon.png";
import CompletedIcon from "../images/completed-icon.png";
import DatePicker from "react-datepicker";
import axios from "axios";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert";
import { FaTrashAlt, FaPen } from "react-icons/fa";
import Moment from "moment";
 
class EducationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleEducation: [
        {
          school: "",
          location: "",
          courseofstudy: "",
          degree_earned: "",
          graduation_date: "",
          _id: "",
          educationHistoryId: "",
        },
      ],
      eduItem: {
        eduItemId: "",
        school: "",
        location: "",
        courseofstudy: "",
        graduation_date: "",
        degree_earned: "",
        educationHistoryId: "",
      },
      errors: [],
      states: [],
      errMsg: {
        school: false,
        location: false,
        courseofstudy: false,
        degree_earned: false,
        graduation_date: false,
      },
      modal: false,
      modalType: "",
      loader: false,
      button: false,
      edu: [],
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      userId: localStorage.getItem("id") ? localStorage.getItem("id") : "",
    };
  }

  componentDidMount() {
    this.handleEducationList();
  }

  handleClick = () => {
    this.props.childToParent("1");
  };

  handleEducationList = async () => {
    const that = this;
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + that.state.userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: that.state.token,
      },
    };

    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          if (
            response.data &&
            response.data.data &&
            response.data.data.education_history.length > 0
          ) {
            var multipleEducation1 = [];
            var edu1 = [];
            response.data.data.education_history.map((item, index) => {
              var exp = {};
              if (item.isActive == true) {
                if (item.graduation_date) {
                  exp["graduation_date"] = new Date(item.graduation_date);
                } else {
                  exp["graduation_date"] = "";
                }
                exp["school"] = item.school;
                exp["location"] = item.location;
                exp["courseofstudy"] = item.courseofstudy;
                exp["degree_earned"] = item.degree_earned;
                exp["_id"] = item._id;
                exp["educationHistoryId"] = item.educationHistoryId;
                multipleEducation1.push(exp);
                that.setState({
                  multipleEducation:
                    item.isActive == true
                      ? multipleEducation1
                      : that.state.multipleEducation,
                });
                that.setState({ edu: multipleEducation1 });
              }
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleSelectDate = (event) => {
    this.setState({
      eduItem: { ...this.state.eduItem, graduation_date: event },
    });
    if (!event) {
      this.setState({ graduation_date: true });
    } else {
      this.setState({ graduation_date: false });
    }
  };

  handleChange = (event) => {
    // this.handleExpValidation();
    this.setState({
      eduItem: {
        ...this.state.eduItem,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleExpValidation() {
    let errors = [];
    let formIsValid = true;
    if (!this.state.eduItem.school) {
      formIsValid = false;
      errors["school"] = "Please enter your school.";
    }
    if (!this.state.eduItem.location) {
      formIsValid = false;
      errors["location"] = "Please enter your location.";
    }
    if (!this.state.eduItem.degree_earned) {
      formIsValid = false;
      errors["degree_earned"] = "Please enter your degree/diploma earned.";
    }
    if (!this.state.eduItem.graduation_date) {
      formIsValid = false;
      errors["graduation_date"] = " Please enter your graduation date.";
    }

    this.setState({
      errors: errors,
      errMsg: {
        ...this.state.errMsg,
        school: true,
        location: true,
        degree_earned: true,
        graduation_date: true,
      },
    });

    return formIsValid;
  }

  addToggle = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "save" });
    this.setState({
      eduItem: {
        ...this.state.eduItem,
        school: "",
        location: "",
        courseofstudy: "",
        degree_earned: "",
        graduation_date: "",
      },
    });
  };

  cancel = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "" });
    this.setState({
      errors: [],
      errMsg: {
        ...this.state.errMsg,
        school: false,
        location: false,
        degree_earned: false,
        graduation_date: false,
      },
    });
  };

  editToggle = (e, item) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "edit" });
    this.setState({
      eduItem: {
        ...this.state.eduItem,
        eduItemId: item._id,
        school: item.school,
        location: item.location,
        courseofstudy: item.courseofstudy,
        degree_earned: item.degree_earned,
        graduation_date: item.graduation_date,
        educationHistoryId: item.educationHistoryId,
      },
    });
  };

  handleEduSubmit = (e, type) => {
    const that = this;
    let tempId = localStorage.getItem("tempId");
    e.preventDefault();
    if (type === "save") {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "education_history",
          education_history: [
            {
              school: that.state.eduItem.school,
              location: that.state.eduItem.location,
              courseofstudy: that.state.eduItem.courseofstudy,
              degree_earned: that.state.eduItem.degree_earned,
              graduation_date: that.state.eduItem.graduation_date,
              tempId: tempId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API +
            "/nurse/createProfileSection/" +
            that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleEducationList();
            that.setState({
              loader: false,
              modal: false,
              modalType: "",
              button: false,
            });
            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              that.props.getProfilePersentage();
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "education_history",
          education_history: [
            {
              _id: that.state.eduItem.eduItemId,
              school: that.state.eduItem.school,
              location: that.state.eduItem.location,
              courseofstudy: that.state.eduItem.courseofstudy,
              degree_earned: that.state.eduItem.degree_earned,
              graduation_date: that.state.eduItem.graduation_date,
              tempId: tempId,
              educationHistoryId: that.state.eduItem.educationHistoryId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API + "/nurse/EditProfileSection/" + that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleEducationList();
            that.setState({
              loader: false,
              modal: !that.state.modal,
              modalType: "",
              button: false,
            });
            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              that.props.getProfilePersentage();
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  removeEducation = (e, itemId) => {
    this.setState({ loader: true });
    e.preventDefault();
    const that = this;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // swal("Education item has been deleted!", {
        //   icon: "success",
        // });
        if (itemId) {
          var config = {
            method: "post",
            url:
              AppConstants.API +
              "/nurse/deleteProfileSection/" +
              that.state.userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: that.state.token,
            },
            data: JSON.stringify({
              recordId: itemId,
              section_name: "education_history",
            }),
          };
          axios(config)
            .then(function (response) {
              that.handleEducationList();
              that.props.getProfilePersentage();
              if (that.state.modal) {
                that.setState({ loader: false, modal: !that.state.modal });
              }
              that.setState({ loader: false });
              if (response.data.Status === "Success") {
                NotificationManager.success(response.data.msg, "", 5000);
              } else {
                NotificationManager.error(response.data.msg, "", 5000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          if (this.state.modal) {
            this.setState({ loader: false });
          }
          this.setState({ loader: false, modal: !this.state.modal });
        }
      }
      this.setState({ loader: false });
    });
  };

  mapEducationList() {
    return (
      <div className="education-tab">
        <section className="add-form-block row">
          {this.state.multipleEducation &&
            this.state.multipleEducation.length > 0
            ? this.state.multipleEducation.map((item, i) => {
              return (
                <div className="form-block col-md-4" key={i}>
                  {item._id && (
                    <div className="form-block-in">
                      <figure>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => this.editToggle(e, item)}
                          className="edit"
                        >
                          <FaPen />
                        </a>

                        {this.state.multipleEducation.length > 1 ? (
                          <a
                            href="javascript:void()"
                            className="delete"
                            onClick={(e) => this.removeEducation(e, item._id)}
                          >
                            <FaTrashAlt />
                          </a>
                        ) : null}
                      </figure>
                      <h2>{item.school}</h2>
                      <span>{item.location}</span>
                      <br />
                      <span>{item.courseofstudy}</span>
                      <br />
                      <span>
                        {Moment(item.graduation_date).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  )}
                </div>
              );
            })
            : "null"}{" "}
        </section>
      </div>
    );
  }

  handleAckPop(type) {
    if (type === "edit") {
      this.props.toggle();
    }
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={(e) => this.cancel(e)}
          className="modal-md sign-save-popup profile-form-popup"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={(e) => this.cancel(e)}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <div className="profile-form-block">
                <Row>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="university">
                        School
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="school"
                        placeholder="School"
                        id="School"
                        value={
                          this.state.eduItem.school
                            ? this.state.eduItem.school
                            : ""
                        }
                        onChange={(e) => this.handleChange(e)}
                        onClick={this.handleAckPop(this.state.modalType)}
                      />
                      {this.state.errMsg.school ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["school"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="educity">
                        Location <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="location"
                        className="form-control"
                        placeholder="Location (City/State)"
                        value={
                          this.state.eduItem.location
                            ? this.state.eduItem.location
                            : ""
                        }
                        onChange={(e) => this.handleChange(e)}
                        onClick={this.props.toggle}
                      />
                      {this.state.errMsg.location ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["location"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="edustate">Course of Study</Label>
                      <Input
                        type="text"
                        name="courseofstudy"
                        id="Course"
                        placeholder="Course of Study"
                        value={
                          this.state.eduItem.courseofstudy
                            ? this.state.eduItem.courseofstudy
                            : ""
                        }
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="major">
                        Degree/Diplome Earned{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="degree_earned"
                        placeholder="Degree Earned"
                        value={
                          this.state.eduItem.degree_earned
                            ? this.state.eduItem.degree_earned
                            : ""
                        }
                        onChange={(e) => this.handleChange(e)}
                        onClick={this.props.toggle}
                      />
                      {this.state.errMsg.degree_earned ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["degree_earned"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup className="date-picker">
                      <Label>
                        Graduation Date <span style={{ color: "red" }}>*</span>
                      </Label>
                      <DatePicker
                        name="graduation_date"
                        placeholderText="MM/DD/YYYY"
                        className="form-control"
                        autoComplete="off"
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={this.handleSelectDate.bind(this)}
                        ref="graduation_date"
                        icon="iconsminds-calendar-4"
                        selected={this.state.eduItem.graduation_date}
                        onClick={this.props.toggle}
                      />
                      {this.state.errMsg.graduation_date ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["graduation_date"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div class="form-bttn">
                <Form className="inner-block">
                  <div className="sign-bttn-sec row justify-content-between">
                    <div className="btn-left">
                      {this.state.modalType === "save" ? (
                        ""
                      ) : this.state.edu.length > 1 ? (
                        <div className="btn-left">
                          <button
                            className="blue-bttn"
                            onClick={(e) =>
                              this.removeEducation(
                                e,
                                this.state.eduItem.eduItemId
                              )
                            }
                            style={{ fontFamily: "Figtree Black" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <div className="btn-right">
                      <button
                        className="blue-bttn"
                        value="Submit"
                        onClick={(e) =>
                          this.handleEduSubmit(e, this.state.modalType)
                        }
                        disabled={this.state.button}
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Save{" "}
                        {this.state.loader ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                      <button
                        onClick={(e) => this.cancel(e)}
                        className="clear-bttn"
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Button
          color="link"
          onClick={(e) => this.handleClick(e)}
          aria-expanded={this.props.collapse === "1"}
        >
          Education
          <div class="w-15 w-xs-100">
            <figure className="form-progress-icon ">
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => <Tooltip {...props}>Incomplete</Tooltip>}
                placement="top"
              >
                {(this.props.status &&
                  this.props.status.finalExperiancesStatus &&
                  this.props.status.finalExperiancesStatus[0] &&
                  this.props.status.finalExperiancesStatus[0].eduction &&
                  this.props.status.finalExperiancesStatus[0].eduction ==
                  "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[1] &&
                    this.props.status.finalExperiancesStatus[1].eduction &&
                    this.props.status.finalExperiancesStatus[1].eduction ==
                    "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[2] &&
                    this.props.status.finalExperiancesStatus[2].eduction &&
                    this.props.status.finalExperiancesStatus[2].eduction ==
                    "Completed") ? (
                  <img src={CompletedIcon} alt="Progress Icon" />
                ) : (
                  <span className="form-tag">0 of 1</span>
                )}
              </OverlayTrigger>
            </figure>
          </div>
        </Button>
        <Collapse
          isOpen={this.props.collapse === "1"}
          className="accordian-cont-block"
        >
          <Form className={`form-block contact-info}`}>
            {this.mapEducationList()}
            <a
              href="javascript:void(0)"
              onClick={(e) => this.addToggle(e)}
              className="click-link add-form"
            >
              {" "}
              <span>+</span>
              <em>Add School</em>
            </a>
          </Form>
        </Collapse>
      </>
    );
  }
}

export default EducationSection;
