import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import MessagePopUp from "../Pages/message_popup";
import LazyLoad from 'react-lazyload';
class Teamblock extends React.Component {
  constructor() {
    super();
    this.state = {
      recruiters: [],
      modal: false,
      slidesToShow: 4,
      phoneNumb: "",
    };
    this.handleModal = this.handleModal.bind(this);
  }
  handleMessage(numb, i) {
    this.setState({ modal: true, phoneNumb: numb });
  }
  handleModal(event) {
    event.preventDefault();
    this.setState((prevState) => ({ modal: !prevState.modal }));
    // this.setState({ modal: !this.state.modal });
  }
  componentDidMount() {
    axios
      .get(AppConstants.API + "/users/getRecruitersListRandomly")
      // .get(AppConstants.API + "/users/getRandomRecruitersfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ recruiters: data.data });
      });
  }
  render() {
    const settings = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },

        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrow: false,
            dots: true,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrow: false,
            dots: true,
          },
        },

        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="team-block pad-tb text-center">
        <Container>
          <span>
            <div className="title">{AppConstants.R_Specialist}</div>
          </span>
          <div className="inner-content">
            <Slider {...settings}>
              {this.state.recruiters.map((item, index) => {
                return (
                  <div className="item">
                    <Card className="block-box">
                      <LazyLoad>
                        <figure className="pic image-fill">
                          <img
                            src={item.profileImage}
                            alt={
                              "Recruiter - " + item.name + " - Travel Nurses Inc"
                            }
                          />
                        </figure>
                      </LazyLoad>
                      <CardBody>
                        <CardTitle>
                          <Link
                            to={{
                              pathname: "/team/" + item.slug,
                              state: { recruiter_id: item._id },
                            }}
                            // className="link"
                          >
                            {item.name}
                          </Link>
                          {item.tags.length > 0
                            ? ", " + item.tags.toString()
                            : ""}
                        </CardTitle>
                        <CardText>{item.designation}</CardText>
                        <div className="social-icons">
                          {item.socialMedia.map((item1) => {
                            return (
                              <a
                                href={item1.url}
                                target="_blank"
                                className={item1.name}
                              ></a>
                            );
                          })}
                          {item.phone ? (
                            <Link
                              className="comment"
                              onClick={this.handleMessage.bind(
                                this,
                                item.phone
                              )}
                            ></Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </CardBody>
                      <Link
                        // to={"/our-recruiters/" + item._id}
                        to={{
                          pathname: "/team/" + item.slug,
                          state: { recruiter_id: item._id },
                        }}
                        className="link"
                      />
                    </Card>
                  </div>
                );
              })}
              {this.state.modal === true ? (
                <MessagePopUp
                  modal={this.state.modal}
                  phone={this.state.phoneNumb}
                  handleModal={this.handleModal}
                />
              ) : (
                ""
              )}
            </Slider>
          </div>
          <Link to="/team" className="button">
            View More
          </Link>
        </Container>
      </div>
    );
  }
}
export default Teamblock;
