import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import { Link } from "react-router-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
class Resourceblock extends React.Component {
  constructor() {
    super();
    this.state = {
      housing_data: "",
      benefits: "",
      refer_friend_content: "",
    };
  }
  componentDidMount() {
    const that = this;
    axios
      .get(AppConstants.CMS_API + "/properties/getHousingInformation")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data) {
          // console.log(data.data)
          that.setState({
            housing_data: data.data.housingData,
          });
        } else {
          console.log("invalid data");
        }
      });
    axios
      .get(AppConstants.Jobs_API + "/benefits/normalBenefits")
      // .get(AppConstants.Jobs_API + "/benefits/normalBenefitsFromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data[0]) {
          // console.log(data.data)
          const regex = /(<([^>]+)>)/ig;
          const benefitsResult = data.data[0].benefits.replace(regex, '');
          // console.log (benefitsResult.substring(0, 150) + "  ...","benefitsResult")
          that.setState({
            benefits: benefitsResult,
          });
        } else {
          console.log("invalid data");
        }
      });
    axios
      .get(
        AppConstants.CMS_API + "/referFriendContents/getTotalReferFriendContent"
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data[0]) {
          // console.log(data.data)
          that.setState({
            refer_friend_content: data.data[0].content,
          });
        } else {
          console.log("invalid data");
        }
      });
  }
  render() {
    return (
      <div className="resources-block pad-tb text-center">
        <Container>
          <div className="title">{AppConstants.Resources}</div>
          <p>{AppConstants.Sub_Resources}</p>
          <Row className="inner-content  justify-content-center">
            {/* <Col xs="4" className="block">
              <div className="block-box box-hover">
                <div className="text">
                  <h4>{AppConstants.Res_House_Info}</h4>
                  <p>
                    {this.state.housing_data ? this.state.housing_data : ""}
                  </p>
                  <Link to="/housing-info" className="read-more">
                    Click Here
                  </Link>
                </div>
              </div>
            </Col> */}

            <Col xs="4" className="block">
              <div className="block-box">
                {images.map((e) => (
                  <figure className="pic image-fill">
                    <img src={e.BenefitBg} title={e.type} alt = "Benefit Resource"/>
                  </figure>
                ))}
                <div className="text">
                  <h4>{AppConstants.Res_House_Benefits}</h4>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.benefits.substring(0, 150) + "  ...",
                      }}
                    />
                  </p>
                  <Link to="/benefits" className="read-more">
                    Click Here
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs="4" className="block">
              <div className="block-box">
                {images.map((e) => (
                  <figure className="pic image-fill">
                    <img src={e.ReferBg} title={e.type} alt = "Refer a friend"/>
                  </figure>
                ))}
                <div className="text">
                  <h4>{AppConstants.Refer_Frnd}</h4>
                  {this.state.refer_friend_content ? (
                    <p>{this.state.refer_friend_content}</p>
                  ) : (
                    ""
                  )}
                  <a
                    href="https://travelnursesinc.staffingreferrals.com/company/join"
                    className="read-more"
                    target="_blank"
                  >
                    Click Here
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Resourceblock;
