export const static_about_gallery = [
  {
    image: require("../img/home-slide_1.jpg"),
  },
  {
    image: require("../img/home-slide_2.jpg"),
  },
  {
    image: require("../img/home-slide_3.jpg"),
  },
  {
    image: require("../img/home-slide_4.jpg"),
  },
  {
    image: require("../img/home-slide_5.jpg"),
  },
  {
    image: require("../img/home-slide_6.jpg"),
  },
];

export const static_home_about = [
 
  {
    image: require("../img/staff-2022.png"),
    link: "https://www.clearlyrated.com/staffing/tn-usa/germantown-tn/travel-nurses-inc-germantown-tn",
    name: "Best Staffing Talent - 2022"
  },
  {
    image: require("../img/staff-2021.png"),
    link: "https://www.clearlyrated.com/staffing/tn-usa/germantown-tn/travel-nurses-inc-germantown-tn",
    name: "Best Staffing Talent - 2021"
  },
  {
    image: require("../img/small.png"),
    link: "https://www.clearlyrated.com/staffing/tn-usa/germantown-tn/travel-nurses-inc-germantown-tn",
    name: "Best Staffing Talent - 2020"
  },
  {
    image: require("../img/bluepipes_1.png"),
    // link: "https://blog.bluepipes.com/the-best-travel-nursing-companies-2018/"
    // link: "/about-us",
    link: "https://travelnursdev.wpengine.com/about",
    name: "Blue Pipes"
  },
  {
    image: require("../img/bluepipes_2.png"),
    // link: "https://blog.bluepipes.com/best-travel-nursing-companies-2017/"
    // link: "/about-us",
    link: "https://travelnursdev.wpengine.com/about",
    name: "Blue Pipes"
  },
  {
    image: require("../img/great_recruiter.png"),
    // link: "https://blog.bluepipes.com/best-travel-nursing-companies-2017/"
    // link: "/about-us",
    link: "https://app.greatrecruiters.com/companies/travel-nurses-inc-an-or-nurses-nationwide-co/",
    name: "Great Recruiters"
  },
];
//

export const static_benefits_perks = [
  {
    image: require("../img/benefit/Benefit_HealthCoverage.png"),
    description: "Day One Health Coverage",
  },
  {
    image: require("../img/benefit/Benefit_TravelReimbursment.png"),
    description: "Travel Reimbursement",
  },
  {
    image: require("../img/benefit/Loyalty Benefit Icon.png"),
    description: "$700 Loyalty Bonus",
  },
  {
    image: require("../img/benefit/Benefit_ClinicalSupport.png"),
    description: "24/7 Clinical Support",
  },
  {
    image: require("../img/benefit/Benefit_Bonus.png"),
    description: "Onboarding Cost Coverage",
  },
  {
    image: require("../img/benefit/Benefit_LowCensusPay.png"),
    // description: "Low Census Pay",
    description: "Contract Transparency",
  },
  {
    image: require("../img/benefit/Benefit_ReferFriend.png"),
    description: "Refer a Friend",
  },
  {
    image: require("../img/benefit/Benefit_TravelIncentive.png"),
    description: "First Time Traveler Incentive",
  },
  {
    image: require("../img/benefit/Benefit_WeeklyPay.png"),
    description: "Weekly Pay",
  },
  {
    image: require("../img/benefit/Benefit_CEUReimbursment.png"),
    description: "CEU Reimbursement ",
  },
];

export const static_blogs = [
  {
    image: require("../img/travel.jpg"),
    description: "Travel Nurse Family Bike Ride on the Hiawatha Trail",
  },
  {
    image: require("../img/travel.jpg"),
    description: "Tips for Finding Housing as a Travel Nurse",
  },
  {
    image: require("../img/travel.jpg"),
    description: "Travel Alaska : To-Do's in the 49th State",
  },
  {
    image: require("../img/travel.jpg"),
    description: "Travel Nurse Family Bike Ride on the Hiawatha Trail",
  },
  {
    image: require("../img/travel.jpg"),
    description: "Tips for Finding Housing as a Travel Nurse",
  },
  {
    image: require("../img/travel.jpg"),
    description: "Travel Alaska : To-Do's in the 49th State",
  },
];

export const static_benefits = [
  {
    title: "Day One Health Coverage",
    image: require("../img/benefit/Benefit_HealthCoverage.png"),
    description:
      "Health, Dental, Vision, and Life Insurance are provided to employees working full-time contracts on day one. Our premium BCBS plans provide coverage nationwide. ",
  },
  {
    title: "Travel Reimbursement",
    image: require("../img/benefit/Benefit_TravelReimbursment.png"),
    description:
      "No need to miss out on a new adventure because you can't afford to get there! We will help cover the cost. ",
  },
  {
    title: "$700 Loyalty Bonus",
    image: require("../img/benefit/Loyalty Benefit Icon.png"),
    description:
      " We aredevoted to you and we want you to know it! Make $700 for 700 hours worked in a calendar year with the TNI family.",
  },
  {
    title: "Onboarding Cost Coverage",
    image: require("../img/benefit/Benefit_Bonus.png"),
    description:
      " We know that credentialing fees add up. TNI is committed to covering your onboarding costs including licensure fees, certification reimbursement, physical exams, and immunizations.",
  },
  {
    title: "Weekly Pay",
    image: require("../img/benefit/Benefit_WeeklyPay.png"),
    description:
      " In addition to offering competitive pay and premium insurance, payday is every Friday and direct deposit is available.W-2s are available at the end of the fiscal year.",
  },
  {
    title: "First Time Traveler Incentive",
    image: require("../img/benefit/Benefit_TravelIncentive.png"),
    description:
      "Is this your first travel assignment? Welcome to a life filled with adventure! To celebrate your new lifestyle, you will receive a $100 scrub allotment upfront and a $500 completion bonus to ensure your first travel assignment is a success! ",
  },
  {
    title: "CEU Reimbursement ",
    image: require("../img/benefit/Benefit_CEUReimbursment.png"),
    description:
      "Looking to enhance your resume and qualify for more assignments? TNI will reimburse up to $300 annually for your CEUs. ",
  },
  {
    title: "24/7 Clinical Support",
    image: require("../img/benefit/Benefit_ClinicalSupport.png"),
    description:
      "You work 24/7 and so do we! Situations may emerge at any time, so we always have a clinician available to support you.",
  },
  // {
  //   title: "Low Census Pay",
  //   image: require("../img/benefit/Benefit_LowCensusPay.png"),
  //   description:
  //     " Sometimes the needs at the facility change and we commit to making up the difference in your paycheck if census drops and your hours are cut short. Please note that this only applies if you are on a contract with guaranteed hours and that you will not be paid for hours you volunteered not to work. ",
  // },
  {
    title: "Contract Transparency",
    image: require("../img/benefit/Benefit_LowCensusPay.png"),
    description:
      "When it comes to contract transparency, there's no one better than TNI. We have practices in place that prevent any confusion around pay packages for travel nurses and make sure we met all of your needs before you even start an assignment!",
  },
  {
    title: "Refer a Friend",
    image: require("../img/benefit/Benefit_ReferFriend.png"),
    description:
      "Your friends deserve to be treated well too. Earn $500 per RN national traveler referral and $250 for non-RN referrals (To be paid upon successfully completing a 13-week assignment.) Submit a referral now! ",
  },
];

export const static_items = [
  {
    title: "What hours count towards the $700 for 700 hour loyalty program?",
    description: `
      <ul class="bullets pl-3">
      <li>All WORKING hours count towards the 700-hour loyalty program</li>
      <li>PRN hours DO count towards your 700.</li>
      <li>Orientation and on-Call DO NOT count towards your 700.</li>
      </ul>
      
      `,
  },
  {
    title: "Who will track the $700 loyalty bonus?",
    description:
      "Payroll will track your hours and pay $700 for 700 hours worked in a calendar year.",
  },
  {
    title: "How can I refer a friend to Travel Nurses, Inc.?",
    description:
      "Enter your information and qualified healthcare professionals' contact information to start earning bonuses!",
  },
  {
    title: `How much is the Referral Bonus, and can I refer multiple healthcare professionals?`,
    description: `<ul><li>You are welcome to refer all of your qualified friends and family! Earn $500 PER RN referral and $250 fornon-RN referrals. **To be paid upon successful </li><li>completion of a 13-week assignment OR, If PRN, after Bo worked hrs within 120 days.**</li></ul>`,
  },
];

// export const job_board_details = [
//   {
//     title: `Looking for details? <br/>Our <span class="job-board_label">Travel Nurse</span> Job Board has it all.`,
//   },
//   {
//     title: `Our opportunities update multiple times a day. You never know what you will find.`,
//   },
//   {
//     title: `Like what you see? <br/><span class="job-board_label">Apply now</span> to experience complete career mobility.`,
//   },
//   {
//     title: `Like what you see?<br/><span class="job-board_label">Start now </span> to experience complete career mobility.`,
//   },
// ];

export const job_board_details = [
  {
    title: `
    <p class="s1">Start your Search.</p>
    <p>Get the details</span></p>
    <p>Learn about your destination.</span></p>
    <p>Adventure awaits.</p>
    `,
    image: require("../img/scrn-1.png"),
    mobile_image: require("../img/mobile-job_1.png"),
  },
  {
    title: `<p>Start your Search.</p>
    <p class="s2">Get the details</span></p>
    <p>Learn about your destination.</span></p>
    <p>Adventure awaits.</p>`,
    image: require("../img/scrn-2.png"),
    mobile_image: require("../img/mobile-job_2.png"),
  },
  {
    title: `<p>Start your Search.</p>
    <p>Get the details</span></p>
    <p class="s3">Learn about your destination.</span></p>
    <p>Adventure awaits.</p>`,
    image: require("../img/scrn-4.png"),
    mobile_image: require("../img/mobile-job_3.png"),
  },
  {
    title: `<p>Start your Search.</p>
    <p>Get the details</span></p>
    <p>Learn about your destination.</span></p>
    <p class="s4">Adventure awaits.</p>`,
    image: require("../img/scrn-3.png"),
    mobile_image: require("../img/mobile-job_4.png"),
  },
];

export const static_testimonials = [
  {
    description: `My recruiter and ADON were very supportive, professional and had a literal open door policy. Very hands on. I definitely appreciated them.`,
    from: "Patricia P.",
  },
  {
    description: `I was set up for success and <br/> that is all that needs to be said!`,
    from: "Fortino C.",
  },
  {
    description: `Honors contracts, keeps in touch and in the loop, any issues are handled quickly or a fast explanation given. Plus, a truly nice Nurse's week gift.`,
    from: "Judie S.",
  },
];

export const static_testimonials_mobile = [
  {
    description: `"My recruiter and ADON were very supportive, professional and had a literal open door policy. Very hands on. I definitely appreciated them."`,
    from: "Patricia P.",
  },
  {
    description: `"I was set up for success and that is all that needs to be said!"`,
    from: "Fortino C.",
  },
  {
    description: `"Honors contracts, keeps in touch and in the loop, any issues are handled quickly or a fast explanation given. Plus, a truly nice Nurse's week gift."`,
    from: "Judie S.",
  },
];
