import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { CiUser, CiLock, CiLogout, CiTrash } from "react-icons/ci";
import { AiOutlineDashboard } from "react-icons/ai";
import Sticky from "react-sticky-el";
import images from "../Components/images";

import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { logoutUser, getUserInfo } from "../actions/authentication";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppConstants from "../AppConstants";
import $ from "jquery";
import queryString from "query-string";

const Header = (props) => {
  const query1 = queryString.parse(props.location.search);

  var status1 = localStorage.getItem("status");
  const [status, setStatus] = useState(status1 ? status1 : "1");
  const [dropdownOpenJobs, setDropdownOpenJobs] = useState(false);
  const [dropdownOpenTeams, setDropdownOpenTeams] = useState(false);
  const [dropdownOpenResourses, setDropdownOpenResourses] = useState(false);
  const [dropdownOpenAbout, setDropdownOpenAbout] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenTeam, setDropdownOpenTeam] = useState(false);
  const [dropdownOpenProfile, setDropdownOpenProfile] = useState(false);
  const [classActive, handleClass1] = useState("");
  const [classActives, handleClass2] = useState("");
  const [classActive3, handleClass3] = useState(
    (query1 && query1.workType == 1) || (query1 && query1.workType == 3)
      ? "is-active"
      : ""
  );

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleTeam = () => setDropdownOpenTeam(!dropdownOpen);
  const toggleProfile = () => setDropdownOpenProfile(!dropdownOpenProfile);

  function preopertyClass1(status) {
    setStatus(status);
    localStorage.setItem("status", status);
    $("body").removeClass("menu-open");
    $(".menu-mask").removeClass("active");
  }
  function referClass() {
    $("body").removeClass("menu-open");
    $(".menu-mask").removeClass("active");
  }
  function jobClass1() {
    $("body").removeClass("menu-open");
    $(".menu-mask").removeClass("active");
  }
  function teamClass() {
    $("body").removeClass("menu-open");
    $(".menu-mask").removeClass("active");
  }

  function blogClass1() {
    $("body").removeClass("menu-open");
    $(".menu-mask").removeClass("active");
  }
  function handleLogout() {
    setStatus("1");
    localStorage.setItem("status", "1");
    props.logoutUser(props.history);
  }

  let id = localStorage.getItem("id");

  // Menu code starts
  const handleClick = () => {
    document.body.classList.add("menu-open");
    document.querySelector(".menu-mask").classList.add("active");
  };

  useEffect(() => {
    function handleClick(event) {
      const target = event.target;
      const body = document.body;
      const menuMask = document.querySelector(".menu-mask");

      if (target.matches(".menu-icon")) {
        body.classList.add("menu-open");
        menuMask.classList.add("active");
      } else if (
        target.matches(".menu-close") ||
        target.matches(".menu-mask") ||
        target.matches(".nav-text") ||
        target.matches(".dropdown-menu .dropdown-item li a")
      ) {
        body.classList.remove("menu-open");
        menuMask.classList.remove("active");
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // Menu code ends

  useEffect(() => {
    if (
      (props.location &&
        props.location.pathname == "/" &&
        props.location.search == "?workType=1") ||
      (props.location &&
        props.location.pathname == "/" &&
        props.location.search == "?workType=3") ||
      (props.location &&
        props.location.pathname == "/" &&
        props.location.search == "")
    ) {
      localStorage.setItem("status", "1");
      setStatus("1");
      // console.log("----------111111111----------")
    } else if (
      (props.location && props.location.pathname == "/timesheets") ||
      (props.location && props.location.pathname == "/schedule") ||
      (props.location && props.location.pathname == "/refer-friend") ||
      (props.location && props.location.pathname == "/award-nominee")
    ) {
      localStorage.setItem("status", "2");
      setStatus("2");
      // console.log("------------------22222222222222222222--------------")
    } else if (props.location && props.location.pathname == "/team") {
      localStorage.setItem("status", "3");
      setStatus("3");
      // console.log("------------------333333333--------------")
    } else {
      localStorage.setItem("status", "0");
      setStatus("0");
      // console.log("----------else----------")
    }
  }, [props]);
  useEffect(() => {
    props.getUserInfo(id, props.history);
  }, [id]);
  const handleChangePassword = () => {
    setStatus("0");
    localStorage.setItem("status", "0");
    props.history.push("/change-password");
  };

  const handleDeleteAcc = () => {
    setStatus("0");
    localStorage.setItem("status", "0");
    props.history.push(`/delete-account/${id}`);
  };

  const handleViewProfile = () => {
    setStatus("0");
    localStorage.setItem("status", "0");
    props.history.push("/view-profile");
  };
  const handleViewDashboard = () => {
    setStatus("0");
    localStorage.setItem("status", "0");
    props.history.push("/profile/" + id);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  };

  function splitString(str, N) {
    const arr = [];

    for (let i = 0; i < str.length; i += N) {
      arr.push(str.substring(i, i + N));
    }

    return (
      arr[0].toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()) + "..."
    );
  }
  async function handleRedirection(e) {
    handleClass1("");
  }
  // const handleJobs= async (status,type)=>{
  //   handleClass3(status);
  //   let cpLink = "workType=" + type;
  //   const url = window.location.origin + "/?" + cpLink;
  //   console.log(url, "url----------------------")
  //   window.location.href = url;
  // }
  const handleJobs = async (status, type) => {
    try {
      handleClass3(status); // Wait for handleClass3 to complete
      let cpLink = "workType=" + encodeURIComponent(type);
      const url = cpLink && window.location.origin + "/?" + cpLink;

      window.location.href = url && url;
    } catch (error) {
      console.error("Error handling jobs:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  };

  return (
    <>
      <Sticky
        className={`sticky-header ${props && props.classAdd ? "head-top" : ""}`}
      >
        <div className="header">
          <div className="container">
            <div className="row justify-content-between">
              <div className="left-block">
                <figure>
                  <a
                    href={AppConstants.Word_Press_API}
                    onClick={() => handleClass1("")}
                  >
                    <img
                      src={`${AppConstants.Bucket_URL}/Footer/TN-Logo.png`}
                      alt="Travel Nurses Inc - Logo"
                    />
                  </a>
                </figure>
              </div>
              {id ? (
                <UncontrolledDropdown className="profile-drop mbl-profile-drop">
                  <DropdownToggle caret size="lg">
                    <figure>
                      {props &&
                      props.userInfo &&
                      props.userInfo.data &&
                      props.userInfo.data.profileImage ? (
                        <img
                          src={
                            props &&
                            props.userInfo &&
                            props.userInfo.data &&
                            props.userInfo.data.profileImage
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${AppConstants.Bucket_URL}/bannerImages/profile-thumb.jpg`}
                          alt=""
                        />
                      )}
                    </figure>
                    <span>
                      {props &&
                      props.userInfo &&
                      props.userInfo.data &&
                      props.userInfo.data.firstName.length > 8
                        ? props &&
                          props.userInfo &&
                          props.userInfo.data &&
                          props.userInfo.data.firstName
                            .toLowerCase()
                            .replace(/\b(\w)/g, (s) => s.toUpperCase())
                            .substring(0, 8) + "..."
                        : props &&
                          props.userInfo &&
                          props.userInfo.data &&
                          props.userInfo.data.firstName}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={handleViewProfile}>
                      <CiUser />
                      <span>Profile</span>
                    </DropdownItem>
                    <DropdownItem onClick={handleViewDashboard}>
                      <AiOutlineDashboard />
                      <span>Dashboard</span>
                    </DropdownItem>
                    <DropdownItem onClick={handleChangePassword}>
                      <CiLock />
                      <span>Change Password</span>
                    </DropdownItem>
                    <DropdownItem onClick={handleDeleteAcc}>
                      <CiTrash />
                      <span>Delete Account</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleLogout()}>
                      <CiLogout />
                      Logout
                    </DropdownItem>
                    <DropdownItem>
                      <NavItem>
                        <a
                          href="https://travelnstaging.wpengine.com/locations/"
                          target="_blank"
                          className="nav-text"
                        >
                          Locations
                        </a>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <Link
                  to="/login"
                  className="sky-bttn button login mbl-login"
                  id="mbl-login-btn"
                >
                  Login
                </Link>
              )}
              <div className="right-block float-right menu-block main-nav">
                <a className="menu-close">Close</a>
                <Nav
                  className={
                    props.location.pathname === "/home2" ||
                    props.location.pathname === "/home"
                      ? "float-left specialHome"
                      : "float-left"
                  }
                >
                  <Dropdown
                    nav
                    isOpen={dropdownOpenJobs}
                    toggle={() => setDropdownOpenJobs(!dropdownOpenJobs)}
                  >
                    <DropdownToggle
                      nav
                      caret
                      className={status == "1" ? "is-active" : ""}
                    >
                      Jobs
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("1")}>
                          <a
                            // to="/"
                            // onClick={() => handleClass1("active")}
                            onClick={() => handleJobs("is-active", "1")}
                            activeClassName="is-active"
                          >
                            Contract Jobs
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("1")}>
                          <a
                            // to="/"
                            activeClassName="is-active"
                            // onClick={() => handleClass1("active")}
                            onClick={() => handleJobs("is-active", "2")}
                          >
                            Permanent Jobs
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("1")}>
                          <a
                            // to="/"
                            activeClassName="is-active"
                            // onClick={() => handleClass1("active")}
                            onClick={() => handleJobs("is-active", "3")}
                          >
                            PRN Jobs
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href="https://travelnstaging.wpengine.com/locations/"
                            target="_blank"
                            className="nav-text"
                          >
                            Locations
                          </a>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <NavItem>
                    <a
                      href={AppConstants.Word_Press_API + "/benefits"}
                      className="nav-link nav-text"
                      onClick={(e) => handleRedirection(e)}
                    >
                      Benefits
                    </a>
                  </NavItem>
                  <Dropdown
                    nav
                    isOpen={dropdownOpenResourses}
                    toggle={() =>
                      setDropdownOpenResourses(!dropdownOpenResourses)
                    }
                  >
                    <DropdownToggle
                      nav
                      caret
                      className={status == "2" ? "is-active" : ""}
                    >
                      Resources
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("2")}>
                          <NavLink
                            to="/timesheets"
                            onClick={() => handleClass1("active")}
                            exact
                            activeClassName="is-active"
                          >
                            Timesheet
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("2")}>
                          <NavLink
                            to="/schedule"
                            onClick={() => handleClass1("active")}
                            exact
                            activeClassName="is-active"
                          >
                            Schedule
                          </NavLink>
                        </NavItem>
                      </DropdownItem>

                      <DropdownItem>
                        <NavItem onClick={() => blogClass1()}>
                          <a
                            href={AppConstants.Word_Press_API + "/blog"}
                            onClick={() => handleClass1("active")}
                          >
                            Blog
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("2")}>
                          <NavLink
                            to="/furnished-finder"
                            onClick={() => handleClass1("active")}
                            exact
                            activeClassName="is-active"
                          >
                            Housing
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("2")}>
                          <a
                            href={
                              AppConstants.Word_Press_API + "/referral-bonus"
                            }
                            onClick={() => handleClass1("active")}
                            exact
                            activeClassName="is-active"
                          >
                            Referral Bonus
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href={
                              "https://info.travelnursesinc.com/media-upload"
                            }
                            onClick={() => handleClass1("active")}
                            className="nav-text"
                          >
                            Share your <br /> Adventures
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("2")}>
                          <NavLink
                            to="/award-nominee"
                            onClick={() => handleClass1("active")}
                            className="nav-text"
                          >
                            Daisy Award <br /> Nominee
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    nav
                    isOpen={dropdownOpenAbout}
                    toggle={() => setDropdownOpenAbout(!dropdownOpenAbout)}
                  >
                    <DropdownToggle nav caret>
                      About
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href={AppConstants.Word_Press_API + "/about"}
                            className="nav-link nav-text"
                            onClick={(e) => handleRedirection(e)}
                          >
                            About Us
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href={
                              AppConstants.Word_Press_API +
                              "/company-announcement"
                            }
                            className="nav-link nav-text"
                            onClick={(e) => handleRedirection(e)}
                          >
                            Company
                            <br /> Announcements
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href={"https://travelnursesinc.com/reviews"}
                            className="nav-link nav-text"
                            onClick={(e) => handleRedirection(e)}
                          >
                            Reviews
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem>
                          <a
                            href={AppConstants.Word_Press_API + "/awards"}
                            className="nav-link nav-text"
                            onClick={(e) => handleRedirection(e)}
                          >
                            Awards
                          </a>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    nav
                    isOpen={dropdownOpenTeams}
                    toggle={() => setDropdownOpenTeams(!dropdownOpenTeams)}
                  >
                    <DropdownToggle
                      nav
                      caret
                      className={status == "3" ? "is-active" : ""}
                    >
                      Team
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("3")}>
                          <a
                            href={AppConstants.Word_Press_API + "/team"}
                            className="nav-link nav-text"
                            onClick={() => handleClass2("")}
                          >
                            Our Team
                          </a>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem onClick={() => preopertyClass1("3")}>
                          <a
                            href={AppConstants.Word_Press_API + "/careers"}
                            className="nav-link nav-text"
                            onClick={() => handleClass2("")}
                          >
                            Careers
                          </a>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <NavItem
                    onClick={() => preopertyClass1("3")}
                    className="menu-desktop-hide"
                  >
                    <NavLink
                      to="/registration"
                      onClick={() => handleClass1("active")}
                      className="text-white"
                    >
                      Join Us
                    </NavLink>
                  </NavItem>
                </Nav>

                {props.location.pathname === "/lpn-orlando-job" ? (
                  ""
                ) : (
                  <div className="btn-sec float-right">
                    {id ? (
                      <UncontrolledDropdown className="profile-drop desk-profile-drop">
                        <DropdownToggle caret size="lg">
                          <figure>
                            {props &&
                            props.userInfo &&
                            props.userInfo.data &&
                            props.userInfo.data.profileImage ? (
                              <img
                                src={
                                  props &&
                                  props.userInfo &&
                                  props.userInfo.data &&
                                  props.userInfo.data.profileImage
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${AppConstants.Bucket_URL}/bannerImages/profile-thumb.jpg`}
                                alt=""
                              />
                            )}
                          </figure>
                          <span>
                            {" "}
                            {props &&
                            props.userInfo &&
                            props.userInfo.data &&
                            props.userInfo.data.firstName.length > 8
                              ? props &&
                                props.userInfo &&
                                props.userInfo.data &&
                                props.userInfo.data.firstName
                                  .toLowerCase()
                                  .replace(/\b(\w)/g, (s) => s.toUpperCase())
                                  .substring(0, 8) + "..."
                              : props &&
                                props.userInfo &&
                                props.userInfo.data &&
                                props.userInfo.data.firstName}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={handleViewProfile}>
                            <CiUser />
                            <span>Profile</span>
                          </DropdownItem>
                          <DropdownItem onClick={handleViewDashboard}>
                            <AiOutlineDashboard />
                            <span>Dashboard</span>
                          </DropdownItem>
                          <DropdownItem onClick={handleChangePassword}>
                            <CiLock />
                            <span>Change Password</span>
                          </DropdownItem>
                          <DropdownItem onClick={handleDeleteAcc}>
                            <CiTrash />
                            <span>Delete Account</span>
                          </DropdownItem>
                          <DropdownItem onClick={() => handleLogout()}>
                            <CiLogout />
                            Logout
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : (
                      <>
                        <Link
                          to="/registration"
                          className="sky-bttn button login desk-login"
                          id="desk-login-btn"
                        >
                          <span>Join Us</span>
                        </Link>
                        <Link
                          to="/login"
                          className="sky-bttn button login desk-login"
                          id="desk-login-btn"
                        >
                          <span>Login</span>
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </div>

              <a className="menu-icon" onClick={handleClick}>
                {images.map((e, idx) => (
                  <img
                    src={e.MenuIcon}
                    alt="Menu - Travel Nurses Inc"
                    key={idx}
                  />
                ))}
              </a>
            </div>
          </div>
        </div>
      </Sticky>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loading, error, userInfo, user, profileImgUpdated } = auth;
  return { loading, error, userInfo, user, profileImgUpdated };
};

export default connect(mapStateToProps, {
  logoutUser,
  getUserInfo,
})(withRouter(Header));
