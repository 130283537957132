import React, { useState, useEffect } from "react";
import axios from "axios";
import AppConstants from "../AppConstants";
import Accordion from "../Components/accordian";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
var hideEdit = [];
class CheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      itemName: "",
      documents: [],
      checkListItems: [],
      hideEdit: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    axios
      .get(AppConstants.CMS_API + "/checkList/getCheckistsFrontend")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status === "Success") {
          that.setState({ checkListItems: data.data });
        }
      });
  }
  render() {
    return (
      <div className="profile-view main-page p-tb-50 bg-grey">
        <Container>
          <div className="registration-form">
            <div className="title mb-1" style ={{color:"#25408f"}}>Check List</div>
            <button
              class="button back-btn my-2"
              onClick={() => this.props.history.go(-1)}
            >
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              Back
            </button>
            <div className="edit-profile1">
              <Accordion items={this.state.checkListItems} />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default CheckList;
