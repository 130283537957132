import React from "react";

import { Container, Row, Col } from "reactstrap";
import styles from "../Styles/testimonials.css";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppConstants from "../AppConstants";
import axios from "axios";

import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSizes: [3, 6, 9, 12],
      selectedPageSize: 3,
      selectedOrderOption: "",
      searchText: "",
      nurseStories: [],
      storyListdata: false,
    }
  }
  componentDidMount() {
    this.updateStoryList(
      this.state.currentPage,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
    );
  }
  onChangePage = (page) => {
    this.updateStoryList(
      page,
      this.state.selectedPageSize,
      this.state.selectedOrderOption,
      this.state.searchText,
      this.state.categoryFilter
    );
    this.setState({
      currentPage: page,
    });
  };
  updateStoryList(
    currentPage,
    selectedPageSize,
    selectedOrderOption,
    searchText,
    categoryFilter
  ) {
    if (searchText && searchText !== "") {
      searchText = searchText;
    }
    //  else if (this.state.searchText && this.state.searchText !== "") {
    //   searchText = this.state.searchText;
    // }
    else {
      searchText = "";
    }
    let tablefilterdata = {
      page: currentPage,
      sizeperpage: selectedPageSize,
      sortname: selectedOrderOption,
      serchtext: searchText,
      defaultSearchText: searchText,
      category: categoryFilter,
    };
    axios
      .post(
        AppConstants.API + "/nurseStory/getAllNurseStoriesForFrontEnd",
        tablefilterdata
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        console.log("data", data)
        this.setState({
          storyListdata: true,
          totalPage: data.totalPage,
          nurseStories: data.data,
          selectedItems: [],
          totalItemCount: data.total_records,
          isLoading: true,
        });
      });
  }
  handlePageClick = (data) => {
    this.onChangePage(data.selected + 1);
  };
  render() {
    window.scrollTo(0, 0);
    const settings = {
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
    };
    
    let Logo = `${AppConstants.Bucket_URL}/Footer/TN-Logo.png`;
     
    return (
      <div className="testimonial-page">
        <Helmet>
          <title>Stories From Our Nurses</title>
          <meta name="title" content="Stories From Our Nurses" />
          <meta name="description" content="Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here." />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="" />
          <meta property="og:title" content="Stories From Our Nurses" />
          <meta property="og:description" content="Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here." />
          <meta property="og:image" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />

          <meta property="twitter:card" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />
          <meta property="twitter:url" content="" />
          <meta property="twitter:title" content="Stories From Our Nurses" />
          <meta property="twitter:description" content="Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here." />
          <meta property="twitter:image" content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg" />
        </Helmet>
        <div className="inner-block pad-tb">
          <Container>
            <h1 className="title">Stories From Our Nurses</h1>
            <p className="text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </p>
            {this.state.storyListdata === true ?
              <div className="inner-content text-center">
                {this.state.nurseStories.map((item, index) => (
                  <Link to={"/view-story/" + item.nurseStory._id}>
                    {/* {item.nurseStory.map((story,index1)=>{ */}
                    {/* return ( */}
                    <Row className="testi-block text-left align-middle box-shadow">
                      <Col xs="4" className="left-block block">
                        {/* {item.nurseStory.image || item.nurseStory.video ?  */}
                        <div className="figure-sec  box-shadow">
                          <figure className="pic image-fill">
                            <Slider {...settings}>
                              {item.nurseStory.image ? <item>
                                {console.log("if")}
                                <img src={item.nurseStory.image} alt={"Nurse Story - Travel Nurses Inc - " + index} />
                              </item> : <item><img src={Logo} alt={"Logo - Travel Nurses Inc - " + index} /></item>}
                              {item.nurseStory.video ?
                                <item>
                                  <iframe
                                    src={item.nurseStory.video}
                                    frameborder="0"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  />
                                </item>
                                : ""}

                            </Slider>
                          </figure>
                        </div>
                        {/* // : ""} */}
                      </Col>
                      <Col xs="8" className="right-block block">
                        <div className="content-box">
                          <div className="title-sec">
                            {item.nurseImage.length > 0 ? <figure className="pic image-fill"> <img src={item.nurseImage} alt={item.nurseFirstName + " - Travel Nurses Inc - " + index} /></figure> : <div className="name-class">{item.nurseFirstName[0][0] + item.nurseLastName[0][0]}</div>}
                            <h2>{item.nurseFirstName + " " + item.nurseMiddleName + " " + item.nurseLastName}</h2>
                          </div>
                          {/* <span>{item.catagry}</span> */}
                          <div className="text">
                            <span dangerouslySetInnerHTML={{ __html: item.nurseStory.description }} />
                          </div>
                        </div>
                      </Col>
                      <div className="clear"></div>
                    </Row>
                    {/* ) */}
                    {/* // })} */}
                  </Link>
                ))}
                {this.state.nurseStories.length !== 0 ? (
                  <ReactPaginate
                    breakLabel={"....."}
                    breakClassName={"break-me page-link false mx-0"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage - 1}
                    // onPageChange={this.onChangePage(i)}
                    containerClassName={"pagination pagination-blk d-flex"}
                    subContainerClassName={"pages pagination page-item "}
                    disabledClassName={"disabledcustom-pagi"}
                    previousLinkClassName={"prve-next  page-link"}
                    nextLinkClassName={"prve-next  page-link"}
                    activeClassName={"active"}
                    pageClassName={"false page-link"}
                    pageLinkClassName={"anchor class"}
                  />
                  // <Pagination1
                  //   aria-label="Page navigation"
                  //   className="pagination-blk"
                  //   currentPage={this.state.currentPage}
                  //   totalPage={this.state.totalPage}
                  //   onChangePage={(i) => this.onChangePage(i)}
                  // />
                ) : (
                  ""
                )}
              </div> :
              <span className="no-data">No data available.</span>}
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default Testimonials;
