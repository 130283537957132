import React, { Component } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import axios from "axios";
import AppConstants from "../AppConstants";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Link } from "react-router-dom";
import TN_welcome from "../images/start-your-next-adventure.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Alerticon } from "../images/job-alerts-icon.png";
import "react-notifications/lib/notifications.css";
import { Dropdown, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactAutocomplete from "react-autocomplete";
import UserPopup from "../../src/Pages/user-pop-up";
import queryString from "query-string";

class SearchArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      city: [],
      state: [],
      items: [],
      states: [],
      currentPage: 1,
      pageSizes: [5, 10, 20, 50],
      selectedPageSize: 10,
      selectedOrderOption: "",
      searchText: "",
      cities: [],
      citiesCopy: [],
      searchRadius: "20",
      stateLoading: true,
      cityLoading: true,
      specialityLoading: true,
      selectedSpecialities: [],
      selectedStates: [],
      selectedCities: [],
      stateCopy: [],
      cityDropdown: "",
      specialityCopy: [],
      citiesDuplicate: [],
      statesDuplicate: [],
      specialitiesDuplicate: [],
      citiesOriginal: [],
      statesOriginal: [],
      specialityOriginal: [],
      stateDropdown: "",
      specialityDropdown: "",
      dropdownStatus: false,
      showLink: false,
      copySuccess: false,
      items1: [],
      states1: [],
      cities1: [],
      items2: [],
      states2: [],
      cities2: [],
      showLoc: false,
      certifications: [],
      certification: [],
      selectedCerts: [],
      certDropdown: "",
      value1: "",
      isOpen: true,
      citiesAuto: [],
      city1: "",
      certsDuplicate: [],
      certsOriginal: [],
      certsCopy: [],
      citySearch: "",
      showUserPopUp: false,
      copyUserPopUp: false,
      type: this.props.type,
      resertFilter: this.props.resertFilter,
    };
    this.handlesearchRadius = this.handlesearchRadius.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.toggleOpenCity = this.toggleOpenCity.bind(this);
    this.toggleClosedCity = this.toggleClosedCity.bind(this);
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.toggleClosedState = this.toggleClosedState.bind(this);
    this.toggleOpenSpeciality = this.toggleOpenSpeciality.bind(this);
    this.toggleClosedSpeciality = this.toggleClosedSpeciality.bind(this);
    this.handleCertChange = this.handleCertChange.bind(this);
    this.toggleClosedCert = this.toggleClosedCert.bind(this);
    this.handleUserPopup = this.handleUserPopup.bind(this);
  }

  toggleOpenState() {
    this.setState({ stateDropdown: true });
  }
  handleStateBlur() {
    this.toggleClosedState();
  }
  toggleClosedState() {
    this.setState({ stateDropdown: false });
  }
  toggleOpenSpeciality() {
    this.setState({ specialityDropdown: true });
  }
  handleSpecialityBlur() {
    this.toggleClosedSpeciality();
  }
  toggleClosedSpeciality() {
    this.setState({ specialityDropdown: false });
  }
  toggleOpenCity() {
    if (this.state.selectedStates.length > 1) {
      this.setState({ cityDropdown: false });
    } else {
      this.setState({ cityDropdown: true });
    }
  }
  handleCityBlur() {
    this.toggleClosedCity();
  }
  toggleClosedCity() {
    this.setState({ cityDropdown: false });
  }
  toggleOpenCertification() {
    this.setState({ certDropdown: true });
  }
  handleCertBlur() {
    this.toggleClosedCert();
  }
  toggleClosedCert() {
    this.setState({ certDropdown: false });
  }
  handleCertChange(selectedList, event) {
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.certifications.length - 1) {
      selectedList = this.state.certifications;
    } else {
      selectedList = selectedList;
    }
    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    this.state.certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.certFunction(
      selectedList
      // this.state.selectedStates,
      // this.state.value1,
      // this.state.searchRadius
    );
    this.setState({
      certifications: result,
      certification: selectedList,
      selectedCerts: selectedList,
      certsDuplicate: result,
    });
  }
  // certFunction(selectedList, states, city, radius) {
  certFunction(selectedList) {
    // if (selectedList.length === 0 && states.length === 0 && city === "") {
    if (selectedList.length === 0) {
      this.setState({ items: this.state.specialityOriginal });
    } else {
      var certVal = [];
      selectedList.map((item) => {
        if (item.value) {
          certVal.push(item.value);
        } else {
          certVal.push(item);
        }
      });
      // var stateVal = [];
      // states.map((item) => {
      //   if (item.code) {
      //     stateVal.push(item.code);
      //   } else {
      //     stateVal.push(item);
      //   }
      // });
      // var cityName = "";
      // if (city) {
      //   cityName = city.split(",");
      // }
      let tablefilterdata = {
        certs: certVal,
        workType: this.props.type, //for contarct OR PRN Jobs
        // states: this.state.showLoc ? stateVal : [],
        // city: this.state.showLoc ? "" : cityName[0],
        // cityFullName: city,
        // cityState: city ? cityName[1] : "",
        // searchRadius: this.state.showLoc ? "" : radius,
      };
      axios
        .post(
          AppConstants.Jobs_API +
          "/specialities/getSpecsWithJobsCountBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                items: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                specialityCopy: [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedSpecialities.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedSpecialities: myArrayFiltered });
              } else {
                this.setState({ selectedSpecialities: [] });
              }
            } else {
              this.setState({ selectedSpecialities: [] });
            }
          }
        });
    }
  }
  handleCertFocus() {
    if (
      this.state.selectedSpecialities.length === 0 &&
      // this.state.selectedStates.length === 0 &&
      this.state.selectedCerts.length === 0
      // &&this.state.citySearch === ""
    ) {
      this.setState({
        certifications: this.state.certsOriginal,
        certsCopy: this.state.certsOriginal,
        certsDuplicate: this.state.certsOriginal,
      });
    }
    // else if (
    //   this.state.selectedSpecialities.length === 0 &&
    //   this.state.selectedStates.length === 0 &&
    //   this.state.selectedCerts.length !== 0
    // )
    else {
      this.setState({
        certifications: this.state.certifications,
        certsCopy: this.state.certifications,
        certsDuplicate: this.state.certifications,
      });
    }
  }
  handleChange(value, event) {
    // if (event.action === "select-option" && event.option.value === "All") {
    //   value = this.state.items;
    // }
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === this.state.items.length - 1) {
      value = this.state.items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    this.state.specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    // this.props.searchJobHistory.history.push({
    //   state: {
    //     city: this.props.homeCity,
    //     state: this.props.homeState,
    //     jobDetailPath: "/job/:id",
    //   },
    // });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      items: result,
      value: value,
      selectedSpecialities: value,
      specialitiesDuplicate: result,
    });
    if (value.length > 0) {
      this.setState({ items1: [] });
    }
    this.specialityFunction(value);
  }

  specialityFunction(selectedList) {
    if (selectedList.length === 0) {
      this.setState({ certifications: this.state.certsOriginal });
    } else {
      var specVal = [];
      selectedList.map((item) => {
        if (item.value) {
          specVal.push(item.value);
        } else {
          specVal.push(item);
        }
      });

      let tablefilterdata = {
        specialities: specVal,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getCertsBasedOnFilters",
          tablefilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (data.data.length > 0) {
              this.setState({
                certifications: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
                certsCopy: [
                  {
                    _id: "All",
                    certification: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ],
              });
              const myArrayFiltered = data.data.filter((array) =>
                this.state.selectedCerts.some(
                  (filter) => filter.value === array.value
                )
              );
              if (myArrayFiltered.length > 0) {
                this.setState({ selectedCerts: myArrayFiltered });
              } else {
                this.setState({ selectedCerts: [] });
              }
            } else {
              this.setState({ selectedCerts: [] });
            }
          }
        });
    }
  }
  handleStateChange(selectedList, event) {
    if (event.action === "select-option") {
      if (event.option.value === "All") {
        selectedList = selectedList.filter((o) => o.value === "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      } else if (event.option.value === "Compact") {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value === "Compact");
      } else if (
        event.option.value !== "All" ||
        event.option.value !== "Compact"
      ) {
        selectedList = selectedList.filter((o) => o.value !== "All");
        selectedList = selectedList.filter((o) => o.value !== "Compact");
      }
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === this.state.states.length - 1) {
      selectedList = this.state.states;
    } else {
      selectedList = selectedList;
    }
    this.setState({ state: selectedList });

    if (selectedList.length > 1) {
      this.setState({ cityDropdown: false, selectedCities: [] });
    } else {
      // this.setState({cityDropdown : ""})
    }

    let stateSel = [];
    selectedList.map((item, index) => {
      stateSel.push(item);
    });
    this.state.stateCopy.map((item, index) => {
      if (stateSel.includes(item)) {
      } else {
        stateSel.push(item);
      }
    });
    var result = stateSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      states: result,
      state: selectedList,
      selectedStates: selectedList,
      statesDuplicate: result,
    });
    if (selectedList.length > 0) {
      this.setState({ states1: [] });
    }
  }
  handlesearchRadius(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleDropdownList() {
    if (this.props) {
      if (this.props.searchJobHistory) {
        if (this.props.homeshowLink) {
          this.setState({
            showLink: this.props.homeshowLink,
          });
        }
      }
      if (this.props.homeshowLoc) {
        this.setState({ showLoc: this.props.homeshowLoc });
      }
      if (this.props.homeCity) {
        this.setState({
          city1: this.props.homeCity,
          value1: this.props.homeCity,
          citySearch: this.props.homeCity,
        });
      }
      if (this.props.homesearchRadius) {
        this.setState({ searchRadius: this.props.homesearchRadius });
      }
      if (this.props.homeshowLink === "true") {
        this.setState({ showLink: true });
      }

      if (this.props.homeState) {
        if (this.props.homeState.length > 0) {
          this.setState({
            state: this.props.homeState,
            selectedStates: this.props.homeState,
          });
        }
      }
      if (this.props.homeStates) {
        if (this.props.homeStates.length > 0) {
          this.setState({
            states: this.props.homeStates,
          });
        }
      }
      if (this.props.homeSpeciality) {
        if (this.props.homeSpeciality.length > 0) {
          this.setState({
            value: this.props.homeSpeciality,
            selectedSpecialities: this.props.homeSpeciality,
          });
        }
      }
      if (this.props.homeCertification) {
        if (this.props.homeCertification.length > 0) {
          this.setState({
            certification: this.props.homeCertification,
            selectedCerts: this.props.homeCertification,
          });
        }
      }
      if (
        (this.props,
          window.innerWidth >= 320 && this.props,
          window.innerWidth <= 767)
      ) {
        this.setState({ dropdownStatus: true });
      }
      if (
        this.props.homeSpecialities &&
        this.props.homeSpecialities.length > 0
      ) {
        this.setState({
          items: this.props.homeSpecialities,
          specialitiesDuplicate: this.props.homeSpecialities,
          specialityCopy: this.props.homeSpecialities,
        });
      }
      if (
        this.props.homeCertifications &&
        this.props.homeCertifications.length > 0
      ) {
        this.setState({
          certifications: this.props.homeCertifications,
          certsDuplicate: this.props.homeCertifications,
          certsCopy: this.props.homeCertifications,
        });
      }
      axios.get(AppConstants.Jobs_API + "/jobs/getAllStates").then((res) => {
        if (res.data.Status === "Success") {
          if (this.props.homeCity && this.props.homeCity.length > 0) {
            this.setState({
              value1: this.props.homeCity,
              city1: this.props.homeCity,
              showLoc: false,
              citySearch: this.props.homeCity,
            });
          }
          if (this.props.homesearchRadius) {
            this.setState({ searchRadius: this.props.homesearchRadius });
          }
          // if (Object.keys(this.props.searchJobHistory.match.params).length > 0) {
          if (this.props.homeState && this.props.homeState.length > 0) {
            // var stateArray = JSON.parse(
            //   this.props.searchJobHistory.match.params.state
            // );
            var stateArray = this.props.homeState;
            var originalArray = [
              { _id: "All", code: "All", label: "All", value: "All" },
              {
                _id: "Compact",
                code: "Compact",
                label: "Compact",
                value: "Compact",
              },
              ...res.data.data,
            ];
            const myArrayFiltered = originalArray.filter((array) =>
              stateArray.some((filter) => filter === array.code)
            );
            let stateSel = [];
            myArrayFiltered.map((item, index) => {
              stateSel.push(item);
            });
            originalArray.map((item, index) => {
              if (stateSel.includes(item)) {
              } else {
                stateSel.push(item);
              }
            });
            var result = stateSel.reduce((unique, o) => {
              if (!unique.some((obj) => obj.value === o.value)) {
                unique.push(o);
              }
              return unique;
            }, []);
            if (myArrayFiltered.length > 0) {
              this.setState({
                selectedStates: myArrayFiltered,
                showLoc: true,
                state: myArrayFiltered,
                states: result,
              });
            }
          }
          this.setState({
            statesOriginal: [
              {
                _id: "All",
                code: "All",
                label: "All",
                value: "All",
              },
              {
                _id: "Compact",
                code: "Compact",
                label: "Compact",
                value: "Compact",
              },
              ...res.data.data,
            ],
            stateLoading: false,
          });
        }
      });
      var data = {
        workType: this.props.type,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/specialities/getAllSpecialities_V1",
          (data = data)
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (this.props.homeSpeciality) {
              var specArray = this.props.homeSpeciality;

              // console.log("line 719", specArray);
              if (specArray.length > 0) {
                var originalArray = [
                  { _id: "All", speciality: "All", label: "All", value: "All" },
                  ...data.data,
                ];
                const myArrayFiltered = originalArray.filter((array) =>
                  specArray.some((filter) => filter === array.value)
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({
                    selectedSpecialities: myArrayFiltered,
                    value: myArrayFiltered,
                    items: originalArray,
                  });
                  this.specialityFunction(myArrayFiltered);
                }
              } else {
                this.specialityFunction(this.props.homeSpeciality);
              }
            }
            this.setState({
              specialityOriginal: [
                {
                  _id: "All",
                  speciality: "All",
                  jobsCount: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
      var data = {
        workType: this.props.type,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/certification/getAllCertifications_v1",
          (data = data)
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            if (this.props.homeCertification) {
              var certArray = this.props.homeCertification;
              if (certArray.length > 0) {
                var originalArray = [
                  {
                    _id: "All",
                    certification: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ];

                const myArrayFiltered = originalArray.filter((array) =>
                  certArray.some((filter) => filter === array.value)
                );
                if (myArrayFiltered.length > 0) {
                  this.setState({
                    selectedCerts: myArrayFiltered,
                    certification: myArrayFiltered,
                    certifications: originalArray,
                  });
                  this.certFunction(myArrayFiltered);
                }
              } else {
                this.certFunction(this.props.homeCertification);
              }
            }
            this.setState({
              certsOriginal: [
                {
                  _id: "All",
                  certification: "All",
                  jobsCount: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
    // e.preventDefault();
  }
  componentDidMount() {
    this.handleDropdownList();
    const params = queryString.parse(this.props.searchJobHistory.location?.search);
    console.log('params',params)
    this.setState({
      selectedCerts: params.certification
        ? params.certification.split(",").map((value) => ({ value, label: value }))
        : [],
      selectedSpecialities: params.speciality
        ? params.speciality.split(",").map((value) => ({ value, label: value }))
        : [],
      value1: params.city || "",
      selectedStates: params.state
        ? params.state.split(",").map((value) => ({ code: value, label: value }))
        : [],
      searchRadius: params.radius || "20",
      type: params.workType || this.props.type,
    });
  }

  componentDidUpdate(prevState) {
    if (
      prevState.type != this.state.type ||
      prevState.resertFilter != this.state.resertFilter
    ) {
      this.setState({
        type: this.props.type,
        resertFilter: this.props.resertFilter,
      });
      setTimeout(() => {
        this.handleDropdownList();
        this.handleReset();
      }, 300);
    }
  }
  handleSubmit(e) {
    this.setState({
      cityDropdown: false,
      stateDropdown: false,
      specialityDropdown: false,
      showLink: true,
      city1: this.state.value1,
      copyUserPopUp: true,
    });
    e.preventDefault();
    this.gettingSearchData();
    var stateVal = [];
    if (this.state.showLoc) {
      stateVal = this.state.selectedStates.map((a) => a.code);
    }
    let certVal = this.state.selectedCerts.map((a) => a.value);
    let specVal = this.state.selectedSpecialities.map((a) => a.value);
    var queryUrl = queryString.parse(
      this.props.searchJobHistory.location?.search
    );
    let cpLink = queryUrl?.hotjobs == "1" ? "?hotjobs=1&" : "/?";


    if (certVal.length > 0) {
      cpLink += `certification=${encodeURIComponent(certVal.join(","))}`;
    }

    if (specVal.length > 0) {
      cpLink += certVal.length > 0
        ? `&speciality=${encodeURIComponent(specVal.join(","))}`
        : `speciality=${encodeURIComponent(specVal.join(","))}`;
    }

    if (this.state.value1 && !this.state.showLoc) {
      cpLink += specVal.length > 0 || certVal.length > 0
        ? `&city=${encodeURIComponent(this.state.value1)}`
        : `city=${encodeURIComponent(this.state.value1)}`;
    }

    if (stateVal.length > 0 && this.state.showLoc) {
      cpLink +=
        this.state.value1 || specVal.length > 0 || certVal.length > 0
          ? `&state=${encodeURIComponent(stateVal.join(","))}`
          : `state=${encodeURIComponent(stateVal.join(","))}`;
    }
    if (
      this.state.searchRadius &&
      this.state.value1 &&
      !this.state.showLoc
    ) {
      cpLink += `&radius=${encodeURIComponent(this.state.searchRadius)}`;
    }
    if (this.props.type) {
      cpLink += `&workType=${encodeURIComponent(this.props.type)}`;
    }
    this.props.searchJobHistory.history.push(cpLink);
  }
  // handleResetlist(e){
  //   handleReset(e);
  // }

  handleReset() {
    // e.preventDefault();
    this.setState(
      {
        city: [],
        state: [],
        value: [],
        // searchRadius: "",
        searchRadius: "20",
        cities: this.state.citiesOriginal,
        states: this.state.statesOriginal,
        items: this.state.specialityOriginal,
        selectedSpecialities: [],
        selectedStates: [],
        selectedCities: [],
        cityDropdown: false,
        stateDropdown: false,
        specialityDropdown: false,
        showLink: false,
        copyUserPopUp: false,
        certDropdown: false,
        certifications: this.state.certsOriginal,
        selectedCerts: [],
        showLoc: false,
        city1: "",
        value1: "",
        certification: [],
      },
      () => {
        this.gettingSearchData();
        var queryUrl = queryString.parse(
          this.props.searchJobHistory.location?.search
        );
        let finalUrl = queryUrl?.hotjobs == 1 ? "?hotjobs=1" : "/";
        this.props.searchJobHistory.history.push(finalUrl);
      }
    );
    localStorage.removeItem("theme");
  }
  gettingSearchData() {
    const data = {
      certification: this.state.selectedCerts,
      city: !this.state.showLoc ? this.state.value1 : "",
      state: this.state.showLoc ? this.state.selectedStates : [],
      speciality: this.state.selectedSpecialities,
      searchRadius: !this.state.showLoc ? this.state.searchRadius : "",
      specialities: this.state.items,
      states: this.state.states,
      cities: this.state.cities,
    };
    this.props.searchCallback(data);
  }
  handleStateFocus() {
    if (this.state.selectedStates.length === 0) {
      this.setState({
        states: this.state.statesOriginal,
        stateCopy: this.state.statesOriginal,
        statesDuplicate: this.state.statesOriginal,
      });
    } else {
      this.setState({
        states: this.state.states,
        stateCopy: this.state.states,
        statesDuplicate: this.state.states,
      });
    }
  }
  handleSpecialityFocus() {
    if (
      this.state.selectedCerts.length === 0 &&
      this.state.selectedSpecialities.length === 0
    ) {
      this.setState({
        items: this.state.specialityOriginal,
        specialitiesDuplicate: this.state.specialityOriginal,
        specialityCopy: this.state.specialityOriginal,
      });
    } else {
      this.setState({
        items: this.state.items,
        specialitiesDuplicate: this.state.items,
        specialityCopy: this.state.items,
      });
    }
  }
  handleUserPopup(e) {
    e.preventDefault();
    // this.setState({
    //   showUserPopUp:!this.state.showUserPopUp
    // })
    this.props.userPopCallback(!this.state.showUserPopUp);
  }
  copyCodeToClipboard(e) {
    NotificationManager.info("URL Copied", "", 3000);
    e.preventDefault();
    var stateVal = [];
    if (this.state.showLoc) {
      stateVal = this.state.selectedStates.map((a) => a.code);
    }
    let certVal = this.state.selectedCerts.map((a) => a.value);
    let specVal = this.state.selectedSpecialities.map((a) => a.value);
    let cpLink = `${AppConstants.Job_Url}?`;
    cpLink =
      certVal.length > 0
        ? `${cpLink}certification=${encodeURIComponent(certVal.toString())}`
        : cpLink;
    cpLink =
      specVal.length > 0
        ? certVal.length > 0
          ? `${cpLink}&speciality=${encodeURIComponent(specVal.toString())}`
          : `${cpLink}speciality=${encodeURIComponent(specVal.toString())}`
        : cpLink;
    // cpLink = this.state.value1 && !this.state.showLoc ? `${cpLink}&city=${this.state.value1}` : cpLink;

    cpLink =
      this.state.value1 && !this.state.showLoc
        ? specVal.length > 0
          ? `${cpLink}&city=${encodeURIComponent(this.state.value1)}`
          : `${cpLink}city=${encodeURIComponent(this.state.value1)}`
        : cpLink;

    cpLink =
      stateVal.length > 0 && this.state.showLoc
        ? this.state.value1 > 0
          ? `${cpLink}&state=${encodeURIComponent(stateVal.toString())}`
          : `${cpLink}state=${encodeURIComponent(stateVal.toString())}`
        : cpLink;
    // cpLink = stateVal.length > 0 && this.state.showLoc ? `${cpLink}&state=${stateVal.toString()}` : cpLink;
    cpLink =
      this.state.searchRadius && this.state.value1 && !this.state.showLoc
        ? `${cpLink}&radius=${encodeURIComponent(this.state.searchRadius)}`
        : cpLink;
    cpLink = this.props.type ? `${cpLink}&workType=${encodeURIComponent(this.props.type)}` : cpLink;
    // console.log("cpLink,--------------",cpLink)
    // cpLink = certVal.length > 0 ? `${cpLink}certification=${certVal.toString()}` : cpLink;
    // cpLink = specVal.length > 0 && certVal.length === 0 && !this.state.value1 && stateVal.length === 0 ? `${cpLink}speciality=${specVal.toString()}` : `${cpLink}&speciality=${specVal.toString()}`;
    // cpLink = specVal.length === 0 && certVal.length === 0 && this.state.value1 && stateVal.length === 0 ? `${cpLink}city=${this.state.value1}` : `${cpLink}&city=${this.state.value1}`;
    // cpLink = specVal.length === 0 && certVal.length === 0 && !this.state.value1 && stateVal.length > 0 ? `${cpLink}state=${stateVal.toString()}` : `${cpLink}&state=${stateVal.toString()}`;
    // cpLink = this.state.searchRadius && this.state.value1 && !this.state.showLoc ? `${cpLink}&radius=${this.state.searchRadius}` : cpLink;
    // var cpLink = `${AppConstants.Base_Url}?certification=${JSON.stringify(certVal)}&speciality=${JSON.stringify(specVal)}&city=${this.state.value1}&state=${JSON.stringify(stateVal)}&radius=${this.state.searchRadius}`

    this.setState({ copySuccess: true });
    navigator.clipboard.writeText(cpLink);
  }
  handleCityPop(e) {
    this.setState({ showLoc: false, citySearch: this.state.value1 });
  }
  handleStatePop(e) {
    this.setState({ showLoc: true, citySearch: "" });
  }
  handleAutoloadCity(e) {
    this.setState({ value1: e.target.value, city1: e.target.value });
    if (e.target.value.length < 3) {
      this.setState({ cities: [] });
    } else {
      let searchfilterdata = {
        searchtext: e.target.value,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getAllCitiesWithAutoLoadAndJobCount",
          searchfilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({
              cities: [
                {
                  _id: "All",
                  name: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
  }
  handleAutoLoadSelect(val) {
    if (val) {
      this.setState({ value1: val });

      if (this.state.showLoc) {
        this.setState({ citySearch: "" });
      } else {
        this.setState({ citySearch: val });
      }
    }
  }

  render() {
    // console.log("props", this.props.type)
    // if(this.props.type){
    //   this.handleDropdownList();
    // }
    const menuStyle = {
      borderRadius: "5px",
      boxShadow: "0px 20px 50px 10px rgba(9, 36, 157, 0.1)",
      background: "#fff",
      padding: "2px 0",
      fontSize: "90%",
      position: "fixed",
      overflow: "auto",
      maxHeight: "300px",
      top: "inherit",
      minWidth: "auto",
      margin: "5px 0 0 10px",
      zIndex: "99",
      // TODO: don't cheat, let it flow to the bottom
    };

    return (
      <div className="search-block search-job-grid" style={{ zIndex: "99" }}>
        <Container>
          {/* <h1 className="pick-dest">{AppConstants.pick_dest}</h1> */}
          <form className="form-block mb-4">
            <Row>
              <Col className="col-md-6 offset-md-3 ">
                {/* <img
                  className="img-fluid mb-4"
                  // src={TN_welcome}
                  src={`${AppConstants.Bucket_URL}/start-your-next-adventure.png`}
                  alt="TN_welcome"
                /> */}
                <h1 style={{ textAlign: "center", color: "white" }}>
                  Start your next adventure!
                </h1>
                <h5
                  className="text-white text-center mb-4"
                  style={{ paddingBottom: "0" }}
                >
                  Search Travel Nurse Jobs Nationwide.
                  <br />
                  Catch a wave at 5 am, or hold your new grandchild.
                  <br />
                  Cherish the journey, and it will nourish you in amazing ways!
                </h5>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenCertification();
                  }}
                >
                  <h5 className="pb-0 text-white">Certification</h5>
                  <ReactMultiSelectCheckboxes
                    options={this.state.certifications}
                    value={this.state.selectedCerts}
                    onChange={(value, event) => {
                      this.handleCertChange(value, event);
                    }}
                    isSearchable={true}
                    // placeholderButtonLabel="Certification"
                    placeholderButtonLabel="All"
                    onFocus={this.handleCertFocus.bind(this)}
                    menuIsOpen={
                      this.state.dropdownStatus === true
                        ? ""
                        : this.state.certDropdown
                    }
                    onBlur={this.handleCertBlur.bind(this)}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 form-input">
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenSpeciality();
                  }}
                >
                  <h5 className="pb-0 text-white">My Specialty is...</h5>
                  <ReactMultiSelectCheckboxes
                    options={this.state.items}
                    value={this.state.selectedSpecialities}
                    onChange={(value, event) => {
                      this.handleChange(value, event);
                    }}
                    isSearchable={true}
                    // placeholderButtonLabel="Specialty"
                    placeholderButtonLabel="All"
                    onFocus={this.handleSpecialityFocus.bind(this)}
                    // menuIsOpen = {this.state.specialityDropdown}
                    menuIsOpen={
                      this.state.dropdownStatus === true
                        ? ""
                        : this.state.specialityDropdown
                    }
                    onBlur={this.handleSpecialityBlur.bind(this)}
                  />
                </div>
              </div>

              <div
                className="col-lg-3 col-md-4 col-sm-12 pt-3 pt-md-0 pt-lg-0"
                id="location-filter"
              >
                <h5 className="pb-0 text-white">Location</h5>
                <div
                  className="block_new"
                  onClick={() => {
                    this.toggleOpenCertification();
                  }}
                ></div>
                <Dropdown autoClose={false}>
                  <Dropdown.Toggle className="css-1r4vtzz all-bttn-filter">
                    <b className="city">
                      {this.state.showLoc
                        ? this.state.selectedStates.length > 0
                          ? this.state.selectedStates.map((item, index) => {
                            return (
                              <span>
                                {" "}
                                {this.state.selectedStates.length - 1 ===
                                  index
                                  ? item.code
                                  : item.code + ", "}
                              </span>
                            );
                          })
                          : "All"
                        : this.state.value1
                          ? // this.state.value1
                          this.state.value1 + ` - ${this.state.searchRadius}m`
                          : "All"}
                    </b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Card>
                      <Card.Body>
                        <Button
                          variant="light"
                          onClick={this.handleCityPop.bind(this)}
                          className={
                            !this.state.showLoc
                              ? "location-bttn active"
                              : "location-bttn"
                          }
                        >
                          City
                        </Button>
                        <Button
                          variant="light"
                          onClick={this.handleStatePop.bind(this)}
                          className={
                            this.state.showLoc
                              ? "location-bttn active"
                              : "location-bttn"
                          }
                        >
                          State
                        </Button>
                        {this.state.showLoc ? (
                          <div
                            className="finder"
                            onClick={() => {
                              this.toggleOpenState();
                            }}
                          >
                            <ReactMultiSelectCheckboxes
                              options={this.state.states}
                              value={this.state.selectedStates}
                              onChange={(value, event) => {
                                this.handleStateChange(value, event);
                              }}
                              isSearchable={true}
                              // placeholderButtonLabel="State"
                              placeholderButtonLabel="All"
                              onFocus={this.handleStateFocus.bind(this)}
                              menuIsOpen={
                                this.state.dropdownStatus === true
                                  ? ""
                                  : this.state.stateDropdown
                              }
                              onBlur={this.handleStateBlur.bind(this)}
                            />
                          </div>
                        ) : (
                          <span>
                            <div
                              className={
                                this.state.cities.length > 0
                                  ? "finder city-finder"
                                  : ""
                              }
                              onClick={() => {
                                // this.toggleOpenCity();
                              }}
                            >
                              <ReactAutocomplete
                                className="test"
                                items={[...this.state.cities]}
                                shouldItemRender={(item, value1) =>
                                  item.label
                                    .toLowerCase()
                                    .indexOf(value1.toLowerCase()) > -1
                                }
                                getItemValue={(item) => item.value}
                                renderItem={(item, highlighted) => (
                                  <div
                                    className={
                                      this.state.value1.length >= 3
                                        ? "list"
                                        : ""
                                    }
                                    key={item.id}
                                    style={{
                                      backgroundColor: highlighted
                                        ? "#eee"
                                        : "transparent",
                                    }}
                                  >
                                    {item.label}
                                  </div>
                                )}
                                inputProps={{ placeholder: "City" }}
                                value={this.state.value1}
                                onChange={this.handleAutoloadCity.bind(this)}
                                onSelect={this.handleAutoLoadSelect.bind(this)}
                              />
                            </div>
                            <br />
                            <div className="city-miles">
                              <Input
                                type="select"
                                value={this.state.searchRadius}
                                name="searchRadius"
                                onChange={this.handlesearchRadius}
                                className={
                                  this.state.city === ""
                                    ? "form-filed radius disabled"
                                    : "form-filed radius test"
                                }
                                disabled={
                                  this.state.city === "" &&
                                    this.state.state === ""
                                    ? true
                                    : false
                                }
                              >
                                <option value="20" className="optionname">
                                  Within 20 miles
                                </option>
                                <option value="50" className="optionname">
                                  Within 50 miles
                                </option>
                                <option value="100" className="optionname">
                                  Within 100 miles
                                </option>
                                <option value="250" className="optionname">
                                  Within 250 miles
                                </option>
                              </Input>
                            </div>
                          </span>
                        )}
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-lg col-md-3 pt-4" id="search-bttn">
                <div className="search-field  block_new TN-search_reset w-100">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className={
                      this.state.certification.length > 0 ||
                        this.state.city1 ||
                        this.state.state.length > 0 ||
                        this.state.value.length > 0
                        ? "button"
                        : "button disabled"
                    }
                    // className="button"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={
                      this.state.certification.length > 0 ||
                        this.state.city1 ||
                        this.state.state.length > 0 ||
                        this.state.value.length > 0
                        ? false
                        : true
                    }
                  >
                    Search
                  </button>
                </div>
              </div>
              {/* <div className="col-lg col-md-3 pt-4" id="search-bttn">
                <div className="search-field  block_new TN-search_reset w-100">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                   
                    // className="button"
                    onClick={this.handleUserPopup.bind(this)}
                  
                  >
                   Job Alerts
                  </button>
                </div>
              </div> */}
              <div className="col-lg col-md-3 pt-4" id="search-bttn">
                <div className="reset-field1 block_new TN-search_reset ">
                  <button
                    className={
                      this.state.certification.length > 0 ||
                        this.state.city1 ||
                        this.state.state.length > 0 ||
                        this.state.value.length > 0
                        ? "button reset-btn1 w-100"
                        : "button reset-btn1 disabled w-100"
                    }
                    onClick={this.handleReset.bind(this)}
                    disabled={
                      this.state.certification.length > 0 ||
                        this.state.city1 ||
                        this.state.state.length > 0 ||
                        this.state.value.length > 0
                        ? false
                        : true
                    }
                  >
                    Reset
                  </button>
                </div>
              </div>
              <form className="form-block my-auto search-jobs_copy-btn">
                <NotificationContainer />
                {this.state.showLink ? (
                  <span>
                    <button
                      onClick={this.copyCodeToClipboard.bind(this)}
                      className="copy-btn"
                      title="Copy URL to Clipboard"
                      id="circuleExtern"
                    ></button>
                    {/* {this.state.copySuccess ? <NotificationContainer/>: ""} */}
                  </span>
                ) : (
                  ""
                )}
              </form>
              {this.state.copyUserPopUp ? (
                <span className="subscribe-doc">
                  <button
                    onClick={this.handleUserPopup.bind(this)}
                    className="copy-btn"
                    title="Subscribe to Alerts"
                    id="circuleExtern"
                  ></button>
                  {/* {this.state.copySuccess ? <NotificationContainer/>: ""} */}
                </span>
              ) : (
                ""
              )}
            </Row>
          </form>
        </Container>
      </div>
    );
  }
}
export default SearchArea;
