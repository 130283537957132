import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Button,
  CustomInput,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import axios from "axios";
import AppConstants from "../AppConstants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { NotificationManager } from "react-notifications";
import EditIcon from "../images/edit-icon.png";
 
import CloseIcon from "../images/close-icon.png";
import { CiViewList } from "react-icons/ci";
import CompletedIcon from "../images/completed-icon.png";
import ProgressIcon from "../images/progress-icon.png";
import { FaDownload } from "react-icons/fa";
function ResumeSection(props) {
  const [resumeFileType, setResumeFileType] = useState("");
  const [resume, setResume] = useState("");
  const [resumeData, setResumeData] = useState("");
  const [resumeError, setResumeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [resumeError1, setResumeError1] = useState("");

  const userId = localStorage.getItem("id");

  useEffect(() => {
    getProfileListAPI();
  }, []);

  async function getProfileListAPI() {
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setResumeData(response.data && response.data.data.resume);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleResumeChange(event) {
    const resumeFileType = event.target.files[0].name
      .split(".")
      .pop()
      .toLowerCase();
    const resumeFile = event.target.files[0];
    if (
      resumeFileType === "doc" ||
      resumeFileType === "docx" ||
      resumeFileType === "pdf"
    ) {
      setResume(resumeFile);
      setResumeError1("");
      setResumeError(false);
    } else {
      setResumeError1("Selected file not supported.");
      setResumeError(true);
      setErrors("resume");
    }
  }

  function handleSubmit(e) {
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    e.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    formData.append("resume", resume);
    if (resume == "") {
      setResumeError1("Pleas select your resume.");
      setResumeError(true);
      setErrors("resume");
    } else {
      axios
        .post(
          AppConstants.API + "/nurse/uploadFile/" + userId,
          formData,
          axiosConfig
        )
        .then((res) => {
          if (res.data.Status === "Success") {
            getProfileListAPI();
            setIsLoading(false);
            setResume("");
            NotificationManager.success(res.data.msg, "", 3000);
            props.handleEditButton();
          } else {
            NotificationManager.error(res.data.msg, "", 3000);
            setResume("");
            setIsLoading(false);
          }
        });
    }
  }

  const handleClick = () => {
    props.HandleAccordion("4");
  };
  const filePreview = async (file) => {
    console.log("file", file);
    const url = new URL(file);
    // Extract the full path without query parameters
    const fullPath = `${url.origin}${url.pathname}`;
    console.log("fullpath", fullPath)
    // const spilturl = response.data.data.split('?');
    const splitoriginalname = fullPath.split("/");
    const originalname = splitoriginalname[splitoriginalname.length - 1];
    const namesplit = originalname.split(".");
    const type = namesplit[namesplit.length - 1];
    console.log("type", type);
    if (
      type == "xlsx" ||
      type == "doc" ||
      type == "docx" ||
      type == "csv" ||
      type == "pptx"
    ) {
      var encodedUrl = encodeURIComponent(file);
      var iFrameUrl =
        "https://docs.google.com/viewer?url=" + encodedUrl + "&embedded=true";
      // <iframe name="theFrame"></iframe>
      window.open(iFrameUrl);
    } else {
      window.open(file);
    }
  };
  const fileDownload = async (e, file) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .post(AppConstants.API + "/nurse/downloadResume", { file }, axiosConfig)
      .then((data) => {
        const a = document.createElement("a");
        a.href = data.data.data;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Button
        color="link"
        onClick={(e) => handleClick(e)}
        aria-expanded={props.collapse == "4"}
      >
        Resume
        <div class="w-15 w-xs-100">
          <figure className="form-progress-icon ">
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>Incomplete</Tooltip>}
              placement="top"
            >
              {props.resume ? (
                <img src={CompletedIcon} alt="Progress Icon" />
              ) : (
                <img src={ProgressIcon} alt="Progress Icon" />
              )}
            </OverlayTrigger>
          </figure>
        </div>
      </Button>
      <Collapse isOpen={props.collapse == "4"} className="accordian-cont-block">
        {/* <div className="edit-form-grid">
          <span> */}
        <div className="edit-form-grid">
          <span>
            {props.editStatus === true ? (
              <a href="javascript:void()" className="edit-btn">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>Edit</Tooltip>}
                  placement="top"
                >
                  <img src={EditIcon} alt="" onClick={props.handleEditButton} />
                </OverlayTrigger>
              </a>
            ) : (
              <a href="javascript:void()" className="edit-btn">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>Close</Tooltip>}
                  placement="top"
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    onClick={props.handleEditButton}
                  />
                </OverlayTrigger>
              </a>
            )}
          </span>
        </div>
        {/* {props.editStatus === true ? (
              <a href="javascript:void()" className="edit-btn">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>Edit</Tooltip>}
                  placement="top"
                >
                  <img src={EditIcon} alt="" onClick={props.handleEditButton} />{" "}
                </OverlayTrigger>
              </a>
            ) : (
              <a href="javascript:void()" className="edit-btn">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>Close</Tooltip>}
                  placement="top"
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    onClick={props.handleEditButton}
                  />
                </OverlayTrigger>
              </a>
            )}
          </span>{" "}
        </div> */}
        <Form className={`form-block contact-info resume-tab`}>
          <Row>
            <Col md="12" className="block">
              <div className="row">
                <aside className="col-xs-3">
                  {resumeData && props.editStatus === true && (
                    <div className="resumme-bttn">
                      {" "}
                      <a
                        onClick={() => {
                          filePreview(resumeData);
                        }}
                        target="_blank"
                      >
                        <CiViewList />
                        <span>View Resume</span>
                      </a>
                    </div>
                  )}
                </aside>
                <aside className="col-xs-8">
                  {resumeData && props.editStatus === true ? (
                    <button
                      onClick={(e) => fileDownload(e, resumeData)}
                      className="resume-button-dwn"
                    >
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>Download</Tooltip>
                        )}
                        placement="top"
                      >
                        <FaDownload />
                      </OverlayTrigger>
                    </button>
                  ) : (
                    ""
                  )}
                </aside>
              </div>

              {((resumeData && props.editStatus === false) ||
                (!resumeData && props.editStatus === true) ||
                (!resumeData && props.editStatus === false)) && (
                  <FormGroup>
                    <span className="upload-doc">
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          Upload
                        </InputGroupAddon>
                        <CustomInput
                          type="file"
                          id="upload"
                          name="resume"
                          onChange={handleResumeChange}
                          disabled={
                            !resumeData && props.editStatus === true
                              ? true
                              : false
                          }
                        />
                      </InputGroup>
                    </span>
                    <span>
                      {resumeError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {resumeError1}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                )}
            </Col>
            {props.editStatus === false && (
              <div class="form-bttn">
                <button value="Submit" onClick={handleSubmit} style={{ fontFamily: "Figtree Black" }}>
                  Save
                  {/* {isLoading ? (
                  <img
                    src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                    alt="No Image"
                    style={{ width: 40, height: 40 }}
                  />
                ) : (
                  ""
                )} */}
                </button>
              </div>
            )}
          </Row>
        </Form>
        {errMsg}
      </Collapse>
    </>
  );
}

export default ResumeSection;
