

const images = [
  {

    pointer: require("../images/pointer.png"),
    pointerSm: require("../images/pointer-small.png"),
    MenuIcon: require("../images/menu-icon.png")

  },
];

export default images;
