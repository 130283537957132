import React from "react";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import { Nav, NavItem } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import TopRates from "../images/Top-Rates.png";
import AppStore from "../images/app-store-img.png";
import GoogleStore from "../images/google-store-img.png";
import { FaTiktok } from "react-icons/fa";







class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      address: "",
      phone: "",
      email: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      tiktok: "https://www.tiktok.com/@travelnursesinc?_t=8p0te7a1XWF&_r=1",
      twitter: "",
      about: [],
      content: "",
      image: "",
      hideStatus: false,
    };
  }
  componentDidMount() {
    if (
      (this.props,
        window.innerWidth >= 320 && this.props,
        window.innerWidth <= 767)
    ) {
      this.setState({ hideStatus: true });
    }
    const script = document.createElement("script");
    script.src =
      "https://widget.clearlyrated.com/22436835-5d19-4d9c-b630-25700374fb31/widget.js?audience=talent&layout=vertical&id=cr-widget1623101431&size=small";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      var myInterviewRecorder = new Footer({
        container: "#mycustom-container", // querySelector to the element that will contain the widget

      });
    };
    axios
      .get(AppConstants.CMS_API + "/contactInfo/allContactInfofromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success" && data.data[0]) {
          this.setState({
            address: data.data[0].address,
            phone: data.data[0].phone,
            email: data.data[0].email,
            facebook: data.data[0].facebook,
            instagram: data.data[0].instagram,
            linkedin: data.data[0].linkedin,
            youtube: data.data[0].youtube,
            // twitter: data.data[0].twitter,
          });
        }
      });
  }
  render() {
    return (
      <footer className="ben-footer">
        <Container>
          <Row className="footer-inner">
            <Col xs="4" className="logo-block block">
              <div className="content-area">
                <a className="footer-logo" href="/">
                  {images.map((e, idx) => (
                    <img

                      src={`${AppConstants.Bucket_URL}/Footer/TN-Logo.png`}
                      alt="Footer Logo - Travel Nurses Inc"
                      key={idx}
                    />
                  ))}
                </a>
                <div className="social-icons">
                  {this.state.facebook ? (
                    <a
                      href={this.state.facebook}
                      target="_blank"
                      className="facebook"
                    ></a>
                  ) : (
                    ""
                  )}

                  {this.state.linkedin ? (
                    <a
                      href={this.state.linkedin}
                      target="_blank"
                      className="linkedin"
                    ></a>
                  ) : (
                    ""
                  )}
                  {this.state.instagram ? (
                    <a
                      href={this.state.instagram}
                      target="_blank"
                      className="instagram"
                    ></a>
                  ) : (
                    ""
                  )}
                  {this.state.youtube ? (
                    <a
                      href={this.state.youtube}
                      target="_blank"
                      className="youtube"
                    ></a>
                  ) : (
                    ""
                  )}
                  {this.state.tiktok ? (
                    <a
                      href={this.state.tiktok}
                      target="_blank"
                      className="tiktok"
                    ><FaTiktok />

                    </a>
                  ) : (
                    ""
                  )}
                  <div className=" clear"></div>
                </div>

                <a

                  className="button refer-btn"

                >
                  <a href={AppConstants.Word_Press_API + "/referral-bonus"} className="text-white">
                    Referral Bonus
                  </a>
                  {/* Refer a Friend */}
                </a>
              </div>
              <Row className="mt-3">
                <Col md="6">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.travelnurses&pli=1"
                    target="_blank"
                  >
                    <figure>
                      <img

                        src={"https://travelnstaging.wpengine.com/wp-content/uploads/2023/10/playstore-img.png-160x47.png"}
                        alt="app-store"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </Col>
                <Col md="6">
                  <a
                    href="https://apps.apple.com/us/app/travel-nurses-inc/id1637482608"
                    target="_blank"
                  >
                    <figure>
                      <img

                        src={"https://travelnstaging.wpengine.com/wp-content/uploads/2023/10/app-store-img.png-160x47.png"}
                        alt="app-store"
                        className="img-fluid"
                      />
                    </figure>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs="5" className="sitemap-block block">
              <h2  >Sitemap</h2>
              <Nav>
                <NavItem>
                  <NavLink to="/" exact activeClassName="is-active">
                    Jobs
                  </NavLink>
                </NavItem>

                <NavItem>
                  <a href={AppConstants.Word_Press_API + "/benefits"}>
                    Benefits
                  </a>
                </NavItem>

                <NavItem>
                  <a href={AppConstants.Word_Press_API + "/about"}>About</a>
                </NavItem>

                <NavItem>
                  <NavLink to="/team" exact activeClassName="is-active">
                    Team
                  </NavLink>
                </NavItem>
                <NavItem>
                  <a href={AppConstants.Word_Press_API + "/blog"}>Blog</a>
                </NavItem>

                <NavItem>
                  <NavLink
                    to="/furnished-finder"
                    exact
                    activeClassName="is-active"
                  >
                    Housing
                  </NavLink>
                </NavItem>

                <NavItem>
                  <a href="https://travelnursesinc.com/nurse-labor-laws/">
                    Nurse Labor Laws
                  </a>
                  <br />
                  <a
                    href={"https://www.bcbst.com/tcr"}
                    className="shop-bttn mt-1"
                    target="_blank"
                  >
                    TCR Machine
                  </a>
                </NavItem>

                <NavItem>
                  <a
                    href={
                      AppConstants.Word_Press_API + "/company-announcements"
                    }
                  >
                    Company Announcements
                  </a>
                </NavItem>

              </Nav>
              <div className="top-rated-logo hcs-rated-img d-none d-md-block">

                {/* <img

                  src={`${AppConstants.Bucket_URL}/Footer/top-rated-2024.png`}
                  alt="Top-Rates 2024"
                /> */}

                <div className="hcs-rated-img">
                  <a
                    href="https://www.natho.org/Current-NATHO-Members"
                    className="hcs-img"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: '32px' }}
                  >
                    <img
                      src={`${AppConstants.Bucket_URL}/Footer/new-natho-logo1.jpg`}
                      alt="Health Care Staffing Image"
                    />
                  </a>

                  <a
                    href="https://www.nwboc.org/"
                    className="me-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${AppConstants.Bucket_URL}/Footer/NWBOC-WEB-Certified-logo.jpg`}
                    />
                  </a>
                </div>



              </div>
            </Col>
            <Col xs="3" className="contact-block block contact">
              <h2>Contact</h2>
              <div className="content-area">
                <div className="links">
                  <a
                    href={
                      "https://www.google.com/maps/place/" + this.state.address
                    }
                    className="address"
                    target="_blank"
                  >
                    9037 Poplar Ave STE 103A Germantown, TN 38138
                  </a>

                  {this.state.phone ? (
                    <a href={"tel:+" + this.state.phone} className="phone">
                      {this.state.phone.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "$1.$2.$3"
                      )}
                    </a>
                  ) : (
                    ""
                  )}
                  {this.state.email ? (
                    <a href="mailto:info@travelnursesinc.com" className="mail">
                      {this.state.email}
                    </a>
                  ) : (
                    ""
                  )}
                  {this.state.hideStatus === true ? (
                    <div id="cr-widget1623101431"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="top-rated-logo  d-block d-md-none">



                  <div className="hcs-rated-img mobile">
                    <a
                      href="https://www.natho.org/Current-NATHO-Members"
                      className="hcs-img"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: '32px' }}
                    >
                      <img
                        src="https://travelnstaging.wpengine.com/wp-content/uploads/2025/03/new-natho-logo1.jpg"
                        alt="Health Care Staffing Image"
                      />
                    </a>

                    <a
                      href="https://www.nwboc.org/"
                      className="me-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://travelnstaging.wpengine.com/wp-content/uploads/2025/03/NWBOC-WEB-Certified-logo.jpg"
                      />
                    </a>
                  </div>



                </div>
                <div className="great-rec-logo links">
                  <figure className="pic">
                    <a
                      className=""
                      target="_blank"
                      href="https://app.greatrecruiters.com/companies/travel-nurses-inc-an-or-nurses-nationwide-co/"
                    >
                      {images.map((e, idx) => (

                        <img

                          src={`${AppConstants.Bucket_URL}/Footer/great-recruiter2025.png`}
                          alt="Great Recruiter Logo"
                          key={idx}
                        />
                      ))}
                    </a>
                  </figure>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div class="be-ix-link-block">{/* Link Block Target Div */}</div>
        <div class="copyrights">
          <Container>
            <p className="pr-3">
              © {new Date().getFullYear()} Travel Nurses Inc.
            </p>
            <p>
              <Link to="/privacy-policy">
                Privacy Policy / Joint Commission Certification
              </Link>
            </p>
            <p>
              Created by <a href="https://www.thinkebiz.net">eBiz Solutions</a>
            </p>
          </Container>
          <div className="clear"></div>
        </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Footer);
