import React, { Component } from "react";
import Slider from "../Components/slider";
import Jobdemandblock from "../Components/job-demand-block";
import Latestjobsblock from "../Components/latest-jobs-block";
import Infoblock from "../Components/info-block";
import Testimonialblock from "../Components/testimonial-block";
import Teamblock from "../Components/team-block";
import Resourceblock from "../Components/resources-block";
import Blogblock from "../Components/blog-block";
import Subscribeblock from "../Components/subscribe-block";
import { Container, Row, Col } from "reactstrap";
import images from "../Components/images";
import axios from "axios";
import AppConstants from "../AppConstants";
import SearchPage from "../Components/searchpage";
import { Helmet } from "react-helmet";
import { JSONLD, Generic  } from "react-structured-data";
import { Link } from "react-router-dom";
import AbImg from "../images/img_3102.jpg";
import LpnImage from "../images/lpn-demad.png";
class NewJob extends React.Component {
  constructor() {
    super();
    this.state = {
      jobTitle: "",
      block1Details: {},
      block2Details: {},
      block3Details: {},
      address: "",
      phone: "",
      email: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    axios
      .get(AppConstants.Jobs_API + "/newJobs/getJobByJobTitle/"+this.props.match.params.job)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
            console.log("newjob",data.data)
          that.setState({ 
              jobTitle: data.data.jobTitle,
              block1Details: data.data.block1Data,
              block2Details: data.data.block2Data,
              block3Details: data.data.block3Data,
         });
        }
      });
      axios
      .get(AppConstants.CMS_API + "/home/allHomeMetaTags")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          if (data.data[0]) {
            that.setState({
              items: data.data,
              metaTitle: data.data[0].metaTitle,
              metaDescription: data.data[0].metaDescription,
              metaTags: data.data[0].metaTags,
              ogTitle: data.data[0].ogTitle,
              ogDescription: data.data[0].ogDescription,
              address: data.data[0].address,
              phone: data.contactInformation[0].phone,
              email: data.contactInformation[0].email,
              facebook: data.contactInformation[0].facebook,
              instagram: data.contactInformation[0].instagram,
              linkedin: data.contactInformation[0].linkedin,
              twitter: data.contactInformation[0].twitter,
            });
          }
        }
      });
  }
  render() {
    const menuStyle = {
      borderRadius: "5px",
      boxShadow: "0px 20px 50px 10px rgba(9, 36, 157, 0.1)",
      background: "#fff",
      padding: "2px 0",
      fontSize: "90%",
      position: "fixed",
      overflow: "auto",
      maxHeight: "300px",
      top: "inherit",
      minWidth: "auto",
      margin: "5px 0 0 10px",
      zindex: "99",

      // TODO: don't cheat, let it flow to the bottom
    };
    return (
      <div className="home">
        {this.props.location.pathname === "/" ? (
          <span> 
            <JSONLD>
              <Generic type="webpage" jsonldtype="Home"
                schema={{
                  name: "Travel Nurses Inc. | Best Travel Nursing Agency",
                  description: "New Job - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here.",
                  address: this.state.address,
                  phone: this.state.phone,
                  email: this.state.email,
                  facebook: this.state.facebook,
                  instagram: this.state.instagram,
                  linkedin: this.state.linkedin,
                  twitter: this.state.twitter,
                  contentReferenceTime: new Date()
              }}
              >
              </Generic>
            </JSONLD>
            <Helmet>
              <title>
                Best Travel Nursing Company, Nursing Agency | Travel Nurses Inc
              </title>
              <meta name="title" content={this.state.metaTitle} />
              <meta name="description" content={this.state.metaDescription} />

              <meta property="og:type" content="website" />
              <meta property="og:url" content={AppConstants.Base_Url} />
              <meta property="og:title" content={this.state.ogTitle} />
              <meta
                property="og:description"
                content={this.state.ogDescription}
              />
              <meta
                property="og:image"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />

              <meta
                property="twitter:card"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />
              <meta property="twitter:url" content={AppConstants.Base_Url} />
              <meta property="twitter:title" content={this.state.metaTitle} />
              <meta
                property="twitter:description"
                content={this.state.metaDescription}
              />
              <meta
                property="twitter:image"
                content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
              />
            </Helmet>
          </span>
        ) : (
          <span></span>
        )}
        <Slider />
        <div className="vaccination-page">
        <div className="job-information pad-tb">
        <Container>
        <div className="title">{this.state.block1Details.title}</div>
        <Row>
            <Col xs="6" className="right-block block">
            <div className="content-box descrop">
            <div  
            dangerouslySetInnerHTML={{
                __html: this.state.block1Details.content,
              }}/>        
            <div className="btn-sec">
            <Link to={{pathname: '/apply-job', state: { prevPath: this.props.location.pathname,jobName: this.props.match.params.job }}} className="button">Apply</Link>
            </div>
            </div>
            </Col>
            {this.state.block1Details.image ? <Col xs="6" className="left-block block">
            <figure className="pic image-fill"><img src={this.state.block1Details.image} alt={this.state.block1Details.title+" - Travel Nurses Inc"}/></figure>
            </Col> : ""}
          </Row>
        </Container>
        </div>
        <div className="vac-benifits pad-tb">
          <Container>
          <div className="title">{this.state.block2Details.title}</div>
          <div className="content">            
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.block2Details.content,
              }}
              />
            <div className="btn-sec">
          </div>
          </div>
          </Container>
        </div>
        <div className="about-block pad-tb">
        <Container>
        <div className="title">{this.state.block3Details.title}</div>
          <Row>
            <Col xs="6" className="left-block block">
            <div className="content-box descrop">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.block3Details.content,
              }}
              />
            </div>
            </Col>
            {this.state.block3Details.image ? <Col xs="6" className="right-block block">
            <figure className="pic image-fill"><img src={this.state.block3Details.image} alt={this.state.block3Details.title+"- Travel Nurses Inc."}/></figure>
            </Col> : ""}
          </Row>
        </Container>
        </div>
        <Teamblock />
      </div>
      </div>
    );
  }
}

export default NewJob;
