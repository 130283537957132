import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonial extends React.Component {
  render() {
    const settings = {
      arrow: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrow: false,
            dots: true,
          },
        },
      ],
    };
    let testimonial = [
      {
        descrop:
          "Contrary to popular belief, Lorem Ipsum is not simply random text.It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Richarde McClintock, a Latin professor at Hampden- Sydney College in Virginia, looked up one of the more obscure Latin words",

        img: require("../images/video-image.jpg"),
        name: "Matilda Joyce",
        designation: "Nurses",
        link: "View More Stories",
      },
      {
        descrop:
          "Contrary to popular belief, Lorem Ipsum is not simply random text.It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Richarde McClintock, a Latin professor at Hampden- Sydney College in Virginia, looked up one of the more obscure Latin words",

        img: require("../images/video-image.jpg"),
        name: "Matilda Joyce",
        designation: "Nurses",
        link: "View More Stories",
      },
    ];
    return (
      <div className="testimonial-block pad-tb">
        <Container>
          <div className="title">Stories From Our Nurses</div>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text
          </p>
          <Slider {...settings}>
            {testimonial.map((e,index) => (
              <div className="inner-content  justify-content-center">
                <div className="left-block">
                  <figure className="pic video-block image-fill">
                    <img src={e.img} alt ={e.name+" - Travel Nurses Inc"}/>
                    <a href="#" className="video-play-btn"></a>
                  </figure>
                </div>
                <div className="right-block">
                  <div className="content-area">
                    <p className="descrop">{e.descrop}</p>
                    <h4 className="name-filed">{e.name}</h4>
                    <span>{e.designation}</span>
                    <a href="#" className="button">
                      {e.link}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
    );
  }
}
export default Testimonial;
