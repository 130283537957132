import React from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";
class ApplyJobThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="apply-form registr-info main-page">
        <Helmet>
          <link
            rel="icon"
            type="image/gif"
            href="https://px.ads.linkedin.com/collect/?pid=7677049&fmt=gif"
            sizes="1x1"
          />
        </Helmet>
        <Container>
          <div className="all-jobs-list text-center py-5 bg-white rounded">
            <h1 className="mb-0" style={{ color: "#25408f" }}>
              Thank you,
            </h1>
            <h2 style={{ color: "#21ade4" }}>we received your application</h2>
            <p className="my-4" style={{ color: "#25408f" }}>
              A recruiter will be in touch with you soon!
              <br />
              In the mean time, check to see if there are other job openings
              that interest you.
            </p>

            <a class="sky-bttn" href="/">
              SEE ALL JOBS
            </a>
          </div>
        </Container>
      </div>
    );
  }
}

export default ApplyJobThankYou;
