import React, { useState, useEffect } from "react";
import AppConstants from "../AppConstants";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import Moment from "moment";
import { BiSpreadsheet } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import Tooltip from "react-bootstrap/Tooltip";
import UploadedIcon from "../images/updated-date-icon.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
 
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ScheduleDocsSection = (props) => {
  const [scheduleDocsList, setScheduleDocsList] = useState([]);
  const [pageNo, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [singleItem, setSingleItem] = useState();

  useEffect(() => {
    setLoader(true);
    let primaryEmail = JSON.parse(localStorage.getItem("primaryEmail"));
    axios
      .post(AppConstants.API + "/scheduleDocuments/getAllScheduleDocuments/", {
        email: primaryEmail ? primaryEmail : "",
        pageNumber: pageNo,
        sizeperpage: 10,
      })
      .then((res) => {
        const offset = parseInt(pageNo);
        setPage(offset + 1);
        setScheduleDocsList(res.data && res.data.data);
        setLoader(false);
      })
      .catch((e) => console.log(e, "error"));
  }, []);

  const handleUpload = () => {
    props.history.push("/schedule");
  };

  const hadleDownload = (e, item) => {
    setSingleItem(item);
    setisLoading(true);
    axios
      .post(
        AppConstants.API + "/scheduleDocuments/getAllScheduleDocumentsView",
        {
          filePath: item.filePath,
        }
      )
      .then((res) => {
        setFilePath(res.data && res.data.presignedUrl);
        setPreviewShow(true);
        setisLoading(false);
      })
      .catch((e) => console.log(e, "error"));
  };
  const handleClosePriview = () => {
    setPreviewShow(false);
  };

  const fetchMoreData = async () => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    const offset = parseInt(pageNo);
    await setPage(offset + 1);
    axios
      .post(AppConstants.API + "/scheduleDocuments/getAllScheduleDocuments/", {
        email: user && user.primaryEmail,
        pageNumber: offset + 1,
        sizeperpage: 10,
      })
      .then((data) => {
        if (data.data.data && data.data.data.length < 1) {
          console.log("1");
        } else {
          setScheduleDocsList(scheduleDocsList.concat(data.data.data));
        }
      });
  };
  return (
    <section className="document-sec" id="timesheet-sec">
      <Modal
        isOpen={previewShow}
        toggle={handleClosePriview}
        className="modal-dialog modal-lg contact-rect recruiter-modal request-job-sec-modal contact-rect pdf-popup "
        id="Tn-popup"
        style={{ color: "#25408f", fontWeight: "500", backgroundColor: "#fff" }}
      >
        <div className="main-page">
          <ModalHeader
            toggle={handleClosePriview}
            className="text-center pt-1 pb-1"
            style={{ color: "#25408f" }}
          ></ModalHeader>
          <ModalBody className="p-0">
            <div className="list-job  loader-center">
              {isLoading ? (
                <img
                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                  alt="No Image"
                  style={{ width: 40, height: 40 }}
                />
              ) : (
                <iframe className="w-100" src={filePath} />
              )}
              <div className="d-flex justify-content-between pdf-cont-grid">
                <div className="doc-date">
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>Uploaded</Tooltip>}
                    placement="left"
                  >
                    <span className="date-block">
                      <em id="TooltipExample">
                        <img
                          src={UploadedIcon}
                          alt=""
                          style={{ width: 20, height: 20 }}
                        />
                      </em>
                      <em>
                        {Moment(
                          singleItem ? singleItem.created_Date : ""
                        ).format("MM-DD-YYYY")}
                      </em>
                    </span>
                  </OverlayTrigger>
                </div>
                <span className="comment">
                  Comments: {singleItem ? singleItem.comment : ""}
                </span>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <div className="container">
        <div className="stories-main-block">
          <div class="preview-bttn">
            <button
              class="button back-btn float-none"
              variant="primary"
              onClick={handleUpload}
            >
              <BiSpreadsheet />
              <span>Upload Schedule</span>
            </button>
          </div>
          <InfiniteScroll
            dataLength={scheduleDocsList.length}
            next={fetchMoreData}
            hasMore={true}
          >
            <div id="down">
              <h2 className="doc-title">
                Schedule history from 11/07/2023. If you want to check on any of
                the previous schedules, please contact your recruiter.
              </h2>
              <div className="document-grid row">
                {loader ? (
                  <div className="list-job pb-50 loader-center">
                    {" "}
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image - Travel Nurses Inc"
                      className="loader-center"
                      style={{ width: 80, height: 80 }}
                    />{" "}
                  </div>
                ) : scheduleDocsList.length > 0 ? (
                  scheduleDocsList.map((item, idx) => {
                    const nameFormat = `${item.name}_${Moment(
                      item.created_Date
                    ).format("MM-DD-YYYY")}`;
                    return (
                      <div className="document-block col-sm-6" key={idx}>
                        <div className="document-in">
                          <div className="row">
                            <figure className="col-sm-3 p-0 ">
                              <FaFilePdf />
                            </figure>
                            <div className="doc-cont col-sm-9">
                              <span>{nameFormat}</span>
                              <div className="doc-date">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Uploaded</Tooltip>
                                  )}
                                  placement="left"
                                >
                                  <span className="date-block">
                                    <em id="TooltipExample">
                                      <img src={UploadedIcon} alt="" />
                                    </em>
                                    <em>
                                      {Moment(item.created_Date).format(
                                        "MM-DD-YYYY hh:mm A"
                                      )}
                                    </em>
                                  </span>
                                </OverlayTrigger>
                              </div>
                              <span className="comment">
                                {item.comment.length <= 45
                                  ? item.comment
                                  : item.comment.slice(0, 50) + "..."}
                              </span>
                            </div>
                          </div>
                          <a
                            href="javascript:void()"
                            onClick={(e) => hadleDownload(e, item)}
                            className="timesheet-link"
                          ></a>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="upcoming-text">No History Found</p>
                )}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </section>
  );
};

export default ScheduleDocsSection;
