import React, { useState, useRef, useEffect } from "react";

function ExpandableHTML({ htmlContent, collapsedNumWords, onCollapse }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandableRef = useRef(null);
  const storedScrollPosition = useRef(0);

  useEffect(() => {
    if (!isExpanded) {
      // Restore the previous scroll position when collapsing
      window.scrollTo({
        top: storedScrollPosition.current,
        behavior: "smooth", // Optionally, use smooth scrolling
      });
    }
  }, [isExpanded]);

  const toggleExpand = () => {
    if (!isExpanded) {
      // Store the current scroll position when expanding
      storedScrollPosition.current = window.scrollY;
    }
    setIsExpanded(!isExpanded);
  };

  const renderContent = () => {
    if (isExpanded) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    } else {
      const words = htmlContent.split(" ");
      if (words.length <= collapsedNumWords) {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
      } else {
        const truncatedContent =
          words.slice(0, collapsedNumWords).join(" ") + " ...";
        return (
          <div className="expandable-html">
            <p dangerouslySetInnerHTML={{ __html: truncatedContent }} />
            <div className="text-right">
              <button
                style={{
                  background: "#21ace3",
                  border: "none",
                  fontSize: "14px",
                  fontFamily: "Figtree Black"
                }}
                onClick={toggleExpand}
                className="show-more-button btn-primary w-190 p-2 rounded-pill">
                Show More
              </button>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="expandable-content" ref={expandableRef}>
      {renderContent()}
      {isExpanded && (
        <div className="text-right">
          <button
            style={{
              border: "none",
              fontSize: "14px",
              fontFamily: "Figtree Black"
            }}
            onClick={toggleExpand}
            className="show-less-button btn-secondary  p-2 rounded-pill">
            Show Less
          </button>
        </div>
      )}
    </div>
  );
}

export default ExpandableHTML;
