import axios from "axios";
import AppConstants from "../../AppConstants";


export const getCountries = async () => {
  return new Promise(async (resolve, reject) => {
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllCountries",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          resolve(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};



