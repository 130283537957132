import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import FadeIn from "react-fade-in";
import smartphone from "../images/smartphone.png";
import Header from "../Components/header";
import Footer from "../Components/footer";
import About from "../Pages/about";
import OurApproach from "../Pages/our-approach";
import Profile from "../Pages/profile";
import JobDetails from "../Pages/job-details";
import Team from "../Pages/recruiters";
import TeamDetails from "../Pages/recruiters-details";
import HousingInfo from "../Pages/housing-info";
import PrivacyPolicy from "../Pages/privacy-policy";
import Timesheet from "../Pages/timesheet";
import ScheduleDocuments from "../Pages/scheduleDocuments";
import SetPassword from "../Pages/set-password";
import Blog from "../Pages/blog";
import BlogDetails from "../Pages/blog-details";
import Testimonials from "../Pages/testimonials";
import MakeEasy from "../Pages/make-easy";
import ApplyJob from "../Pages/apply-job";
import ApplyJobHome from "../Pages/apply-job-home";
import Error from "../Pages/error";
import ResetPassword from "../Pages/reset-password";
import OurStory from "../Pages/our-story";
import MyStory from "../Pages/my-stories";
import Properties from "../Pages/properties";
import Resume from "../Pages/resume-view";
import VaccinationPage from "../Pages/vaccination-page";
import NewJob from "../Pages/newJob";
import DivContact from "../Pages/div-contact";
import ViewStory from "../Pages/view-story";
import SearchjobsPath from "../Pages/search-job-path";
import NewBenefits from "../NewUI/benefits";
import CheckList from "../Pages/check-list";
import Professionalreport from "../Pages/Professional-report";
import ClientEvaluationForm from "../Pages/clientEvaluation-form";
import ExitpopupApp from "../Pages/ExitpopupApp";
import ChangePassword from "../Pages/change-password";
import DeleteAccount from "../Pages/delete-account";
import EditProfile from "../Pages/edit-profile";
import StoriesDetailPage from "../Pages/stories-detail";
import ArticleDetailPage from "../Pages/article-detail";
import CreateStory from "../Pages/create-story";
import UpdateStory from "../Pages/update-story";
import PrivacyForm from "../Pages/privacy-form";
import AwardNominee from "../Pages/award-nominee";
import CookiesBanner from "../Components/CookiesBanner";
import ReferFriend from "../Pages/ReferFriend";
import NurseApp from "../Pages/nurseApp";
import RigThankYou from "../Pages/rigThankYou";
import ApplyJobThankYou from "../Pages/applyJobThankYou";
import { Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import RelaventJobs from "../Pages/relevant-jobs";

// const Header = React.lazy(() =>
//   import(/* webpackChunkName: "views-header" */ "../Components/header")
// );
// const Footer = React.lazy(() =>
//   import(/* webpackChunkName: "views-footer" */ "../Components/footer")
// );
// const About = React.lazy(() =>
//   import(/* webpackChunkName: "views-about" */ "../Pages/about")
// );
// const OurApproach = React.lazy(() =>
//   import(/* webpackChunkName: "views-approach" */ "../Pages/our-approach")
// );
// const Profile = React.lazy(() =>
//   import(/* webpackChunkName: "views-profile" */ "../Pages/profile")
// );
// const JobDetails = React.lazy(() =>
//   import(/* webpackChunkName: "views-job-details" */ "../Pages/job-details")
// );
// const Team = React.lazy(() =>
//   import(/* webpackChunkName: "views-recruiters" */ "../Pages/recruiters")
// );
// const TeamDetails = React.lazy(() =>
//   import(/* webpackChunkName: "views-recruiters-details" */ "../Pages/recruiters-details")
// );
// const HousingInfo = React.lazy(() =>
//   import(/* webpackChunkName: "views-housing-info" */ "../Pages/housing-info")
// );
// const PrivacyPolicy = React.lazy(() =>
//   import(/* webpackChunkName: "views-privacy" */ "../Pages/privacy-policy")
// );
// const Timesheet = React.lazy(() =>
//   import(/* webpackChunkName: "views-timesheet" */ "../Pages/timesheet")
// );
// const ScheduleDocuments = React.lazy(() =>
//   import(/* webpackChunkName: "views-scheduleDocuments" */ "../Pages/scheduleDocuments")
// );
// const SetPassword = React.lazy(() =>
//   import(/* webpackChunkName: "views-set-password" */ "../Pages/set-password")
// );
// const Blog = React.lazy(() =>
//   import(/* webpackChunkName: "views-blog" */ "../Pages/blog")
// );
// const BlogDetails = React.lazy(() =>
//   import(/* webpackChunkName: "views-blog-details" */ "../Pages/blog-details")
// );
// const Testimonials = React.lazy(() =>
//   import(/* webpackChunkName: "views-testimonials" */ "../Pages/testimonials")
// );
// const MakeEasy = React.lazy(() =>
//   import(/* webpackChunkName: "views-make-easy" */ "../Pages/make-easy")
// );
// const ApplyJob = React.lazy(() =>
//   import(/* webpackChunkName: "views-apply-job" */ "../Pages/apply-job")
// );
// const ApplyJobHome = React.lazy(() =>
//   import(/* webpackChunkName: "views-apply-job-home" */ "../Pages/apply-job-home")
// );
// const Error = React.lazy(() =>
//   import(/* webpackChunkName: "views-error" */ "../Pages/error")
// );
// const ResetPassword = React.lazy(() =>
//   import(/* webpackChunkName: "views-reset-password" */ "../Pages/reset-password")
// );
// const OurStory = React.lazy(() =>
//   import(/* webpackChunkName: "views-our-story" */ "../Pages/our-story")
// );
// const MyStory = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/my-stories")
// );
// const Properties = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/properties")
// );
// const Resume = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/resume-view")
// );
// const VaccinationPage = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/vaccination-page")
// );
// const NewJob = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/newJob")
// );
// const DivContact = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/div-contact")
// );
// const ViewStory = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/view-story")
// );
// const SearchjobsPath = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/search-job-path")
// );
// const NewBenefits = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../NewUI/benefits")
// );
// const CheckList = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/check-list")
// );
// const Professionalreport = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/Professional-report")
// );
// const ClientEvaluationForm = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/clientEvaluation-form")
// );
// const ExitpopupApp = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/ExitpopupApp")
// );
// const ChangePassword = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/change-password")
// );
// const DeleteAccount = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/delete-account")
// );
// const EditProfile = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/edit-profile")
// );
// const StoriesDetailPage = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/stories-detail")
// );
// const ArticleDetailPage = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/article-detail")
// );
// const CreateStory = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/create-story")
// );
// const UpdateStory = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/update-story")
// );
// const PrivacyForm = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/privacy-form")
// );
// const AwardNominee = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/award-nominee")
// );
// const CookiesBanner = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Components/CookiesBanner")
// );
// const ReferFriend = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/ReferFriend")
// );
// const NurseApp = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/nurseApp")
// );
// const RigThankYou = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/rigThankYou")
// );
// const ApplyJobThankYou = React.lazy(() =>
//   import(/* webpackChunkName: "views-app1" */ "../Pages/applyJobThankYou")
// );

class Layout extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      display: true,
    };
    this.handleToggleDisplay = this.handleToggleDisplay.bind(this);
  }

  handleToggleDisplay = (value) => {
    this.setState({ display: value });
  };
  componentWillMount() {
    if (this.props.location?.search) {
      var splitValue = this.props.location.search.split("=");
      if (splitValue[0] == "?_gl") {
        this.props.history.push(this.props.location?.pathname);
      }
    }
  }

  render() {
    const { location } = this.props;
    const showBanner =
      location.pathname === "/" || location.pathname.startsWith("/job/");
    const hideExitPopup = showBanner;

    return (
      <div className="Layout">
        {!hideExitPopup && <ExitpopupApp />}

        <Header classAdd={this.state.display ? "" : "head-top"} />

        {showBanner && (
          <div
            className="notice custom-alert banner-container"
            style={{ backgroundColor: "#21ade4", fontFamily: "Domine" }}
          >
            <Container className="desktop-banner">
              <p className="py-1 text-white" style={{ fontFamily: "Domine" }}>
                <img src={smartphone} alt="smartphone-img" width={25} /> Stay
                one step ahead -{" "}
                <a
                  href={window.location.origin + "/travel-nurse-portal"}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  download the Travel Nurses, Inc.
                </a>{" "}
                app now to streamline your job search and application process.
              </p>
            </Container>

            <Container className="mobile-banner">
              <a
                href={window.location.origin + "/travel-nurse-portal"}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <img src={smartphone} alt="smartphone-img" width={25} />
                <span>Download the Travel Nurses, Inc.</span>
              </a>
            </Container>
          </div>
        )}

        <section
          className={this.state.display ? "cookies-area" : "cookies-area-none"}
        >
          <CookiesBanner onDisplayToggle={this.handleToggleDisplay} />
        </section>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            <Route path="/award-nominee" exact component={AwardNominee} />
            <Route path="/travel-nurse-portal" exact component={NurseApp} />
            <Route path="/about-us" exact component={About} />
            <Route path="/our-approach" exact component={OurApproach} />
            <Route path="/profile/:slug" exact component={Profile} />
            <Route path="/view-profile" exact component={EditProfile} />
            <Route path="/create-story" exact component={CreateStory} />
            <Route path="/update-story" exact component={UpdateStory} />
            <Route
              path="/stories-detail/"
              exact
              component={StoriesDetailPage}
            />
            <Route
              path="/article-detail/"
              exact
              component={ArticleDetailPage}
            />
            <Route path="/job/:id" exact component={JobDetails} />
            <Route path="/team" exact component={Team} />
            <Redirect exact from="/our-recruiters" to="/team" />
            <Route path="/team/:id" exact component={TeamDetails} />
            <Redirect exact from="/our-recruiters/:id" to="/team/:id" />
            <Route path="/housing-info" exact component={HousingInfo} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/privacy-policy-form" component={PrivacyForm} />
            <Route path="/reset-password/:id" exact component={ResetPassword} />
            <Route path="/change-password/" exact component={ChangePassword} />
            <Route path="/delete-account/:id" exact component={DeleteAccount} />
            <Route path="/set-password/:id" exact component={SetPassword} />
            <Route
              path="/registration-thank-you"
              exact
              component={RigThankYou}
            />
            <Route
              path="/applyjob-thank-you"
              exact
              component={ApplyJobThankYou}
            />
            <Route path="/blog" exact component={Blog} />
            <Route path="/blog-details/:id" exact component={BlogDetails} />
            <Route path="/testimonials" exact component={Testimonials} />
            <Route path="/make-easy" exact component={MakeEasy} />
            <Route path="/apply-job/:id" exact component={ApplyJob} />
            <Route path="/apply-job" exact component={ApplyJobHome} />
            <Route path="/our-story/:slug" exact component={OurStory} />
            <Route path="/my-stories/:slug" exact component={MyStory} />
            <Route path="/footer" exact component={Footer} />
            <Route path="/furnished-finder" exact component={Properties} />
            <Route path="/refer-friend" exact component={ReferFriend} />
            <Route path="/timesheets" exact component={Timesheet} />
            <Route path="/schedule" exact component={ScheduleDocuments} />
            <Route
              path="/professional-report"
              exact
              component={Professionalreport}
            ></Route>
            <Redirect exact from="/properties" to="/furnished-finder" />
            <Route
              path="/client-evaluation"
              exact
              component={ClientEvaluationForm}
            ></Route>
            <Redirect exact from="/properties" to="/furnished-finder" />
            <Route path="/resume-view/:slug" exact component={Resume} />
            <Route path="/lpn-orlando-job" exact component={VaccinationPage} />
            <Route path="/div-contact" exact component={DivContact} />
            <Route path="/travel-nurse-job/:job" exact component={NewJob} />
            <Route path="/view-story/:id" exact component={ViewStory} />
            <Route path="/check-list" exact component={CheckList} />
            <Route path="/benefits" exact component={NewBenefits} />

            <Route path="/error" exact render={() => <Error status={404} />} />
            <Route path="/relevant-jobs" exact component={RelaventJobs} />

            <Route path="/" component={SearchjobsPath} />
          </Switch>
        </Suspense>
        <FadeIn>
          <Footer />
        </FadeIn>
      </div>
    );
  }
}

export default Layout;
