import axios from "axios";
import { SAVE_JOB, SAVE_JOB_ERROR, SAVE_JOB_SUCCESS } from "./types";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";

// SAVE JOB ACTION
export const saveJobAction = (job) => (dispatch) => {
  console.log(job, "job");
  try {
    dispatch({
      type: SAVE_JOB,
    });
    axios
      .post(AppConstants.API + "/nurse/SaveJobAction", job)
      .then((response) => {
        if (response.data.Status === true) {
          dispatch({
            type: SAVE_JOB,
            payload: response.data,
          });
        } else {
          dispatch({
            type: SAVE_JOB_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: SAVE_JOB_ERROR,
          });
        }
      });
  } catch (error) {
    console.log(error, "error");
  }
};
