import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import SignaturePad from "react-signature-canvas";
import {
  BiUserCircle,
  BiUserPin,
  BiZoomIn,
  BiWindowOpen,
} from "react-icons/bi";
import styles from "../Styles/registration.css";
import ProgressIcon from "../images/progress-icon.png";
import CompletedIcon from "../images/completed-icon.png";
import InfoIcon from "../images/info-icon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import EditIcon from "../images/edit-icon.png";
import CloseIcon from "../images/close-icon.png";
import Placeholder from "../images/placeholder.png";
import NumberFormat from "react-number-format";
import moment from "moment";
import { FaAngleDoubleLeft, FaTheaterMasks } from "react-icons/fa";
import AppConstants from "../AppConstants";
import EducationSection from "./education-section";
import WorkSection from "./work-section";
import ReferenceSection from "./reference-section";
import ResumeSection from "./resume-section";
import { getUserInfo } from "../actions/authentication";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
 
import "../Styles/signcanvas.css";
import { NotificationManager } from "react-notifications";

function EditProfile(props) {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("id");
  const [selectedAccordion, setSelectedAccordion] = useState(1);
  const [old_profileImage] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [collapse1, setCollapse1] = useState("1");
  const [collapse2, setCollapse2] = useState("1");
  const [collapse3, setCollapse3] = useState("1");
  const [collapse4, setCollapse4] = useState("1");
  const [collapse15, setCollapse15] = useState(false);
  const [firstName] = useState("");
  const [editStatus, setEditStatus] = useState(true);
  const [editKey, setEditKey] = useState("");

  const [editStatus1, setEditStatus1] = useState(true);
  const [editStatus2, setEditStatus2] = useState(true);
  const [editStatus3, setEditStatus3] = useState(true);
  const [editStatusPre1, setEditStatusPre1] = useState(true);
  const [editStatusPre2, setEditStatusPre2] = useState(true);
  const [editStatusPre3, setEditStatusPre3] = useState(true);
  const [editStatusEdu1, setEditStatusEdu1] = useState(true);
  const [editStatusEdu2, setEditStatusEdu2] = useState(true);
  const [editStatusEdu3, setEditStatusEdu3] = useState(true);
  const [acknowledgement, setAcknowledgement] = useState(true);

  // const [primaryDetails1, setPrimaryDetails1] = useState({
  //   primaryEmail: primaryDetails1 ? primaryDetails1.primaryEmail : "",
  //   primaryPhoneNumber: primaryDetails1
  //     ? primaryDetails1.primaryPhoneNumber
  //     : "",
  // });

  const [primaryDetails1, setPrimaryDetails1] = useState((prevState) => ({
    primaryEmail: prevState ? prevState.primaryEmail : "",
    primaryPhoneNumber: prevState ? prevState.primaryPhoneNumber : "",
  }));

  // const [userInfo1, setUser1] = useState({
  //   firstName: userInfo1 ? userInfo1.firstName : "",
  //   lastName: userInfo1 ? userInfo1.lastName : "",
  // });
  // const [primaryDetails, setPrimaryDetails] = useState({
  //   primaryEmail: primaryDetails ? primaryDetails.primaryEmail : "",
  //   primaryPhoneNumber: primaryDetails ? primaryDetails.primaryPhoneNumber : "",
  // });
  // const [userInfo, setUser] = useState({
  //   firstName: userInfo ? userInfo.firstName : "",
  //   middleName: userInfo ? userInfo.middleName : "",
  //   lastName: userInfo ? userInfo.lastName : "",
  //   maidenName: userInfo ? userInfo.maidenName : "",
  //   nickName: userInfo ? userInfo.nickName : "",
  // });
  // const [userAddress, setAddress] = useState({
  //   Street_Address:
  //     userAddress && userAddress.Street_Address
  //       ? userAddress.Street_Address
  //       : "",
  //   Apartment:
  //     userAddress && userAddress.Apartment ? userAddress.Apartment : "",
  //   city: userAddress && userAddress.city ? userAddress.city : "",
  //   state: userAddress && userAddress.state ? userAddress.state : "",
  //   zipcode: userAddress && userAddress.zipcode ? userAddress.zipcode : "",
  //   country: userAddress && userAddress.country ? userAddress.country : "",
  // });
  // const [userEContact, setEContact] = useState({
  //   contactName:
  //     userEContact && userEContact.contactName ? userEContact.contactName : "",
  //   phoneNumber:
  //     userEContact && userEContact.phoneNumber ? userEContact.phoneNumber : "",
  //   relation:
  //     userEContact && userEContact.relation ? userEContact.relation : "",
  // });
  // const [userReferral, setReferral] = useState({
  //   referralSource:
  //     userReferral && userReferral.referralSource
  //       ? userReferral.referralSource
  //       : "",
  //   referralSourceId:
  //     userReferral && userReferral.referralSourceId
  //       ? userReferral.referralSourceId
  //       : "",
  //   referralBy:
  //     userReferral && userReferral.referralBy ? userReferral.referralBy : "",
  // });

  const [userInfo1, setUser1] = useState((prevState) => ({
    firstName: prevState ? prevState.firstName : "",
    lastName: prevState ? prevState.lastName : "",
  }));

  const [primaryDetails, setPrimaryDetails] = useState((prevState) => ({
    primaryEmail: prevState ? prevState.primaryEmail : "",
    primaryPhoneNumber: prevState ? prevState.primaryPhoneNumber : "",
  }));

  const [userInfo, setUser] = useState((prevState) => ({
    firstName: prevState ? prevState.firstName : "",
    middleName: prevState ? prevState.middleName : "",
    lastName: prevState ? prevState.lastName : "",
    maidenName: prevState ? prevState.maidenName : "",
    nickName: prevState ? prevState.nickName : "",
  }));

  const [userAddress, setAddress] = useState((prevState) => ({
    Street_Address: prevState ? prevState.Street_Address : "",
    Apartment: prevState ? prevState.Apartment : "",
    city: prevState ? prevState.city : "",
    state: prevState ? prevState.state : "",
    zipcode: prevState ? prevState.zipcode : "",
    country: prevState ? prevState.country : "",
  }));

  const [userEContact, setEContact] = useState((prevState) => ({
    contactName: prevState ? prevState.contactName : "",
    phoneNumber: prevState ? prevState.phoneNumber : "",
    relation: prevState ? prevState.relation : "",
  }));

  const [userReferral, setReferral] = useState((prevState) => ({
    referralSource: prevState ? prevState.referralSource : "",
    referralSourceId: prevState ? prevState.referralSourceId : "",
    referralBy: prevState ? prevState.referralBy : "N/A",
  }));



  const [workPreferences, setWorkPreferences] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [workPreferences1, setWorkPreferences1] = useState(new Date());
  // const [shiftPreferences, setShiftPreferences] = useState({
  //   distance:
  //     shiftPreferences && shiftPreferences.distance
  //       ? shiftPreferences.distance
  //       : "",
  // });

  const [shiftPreferences, setShiftPreferences] = useState((prevState) => ({
    distance: prevState ? prevState.distance : "",
  }));


  const [educationHistory, setEducationHistory] = useState([]);
  const [shiftPreferences1, setShiftPreferences1] = useState([]);
  const [worklocation, setWorklocation] = useState([]);
  const [states, setStates] = useState([]);
  const [states1, setStates1] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [positionTypes, setPositionTypes] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [distance, setDistance] = useState([]);
  const [employeTypes, setEmployeTypes] = useState([]);
  const [referralList, setReferralList] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [count, setCount] = useState(0);
  const [referralSource, setReferralSource] = useState(false);
  const [referralSourceError, setReferralSourceError] = useState("");
  const [fullName, setFullName] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [mobileNumber, setMobileNumber] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [relationship, setRelationship] = useState(false);
  const [relationshipError, setRelationshipError] = useState("");
  const [streetAddress, setStreetAddress] = useState(false);
  const [streetAddressError, setStreetAddressError] = useState("");
  const [city, setCity] = useState(false);
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState(false);
  const [stateError, setStateError] = useState("");
  const [zip, setZip] = useState(false);
  const [zipError, setZipError] = useState("");
  const [fName, setFName] = useState(false);
  const [fError, setFError] = useState("");
  const [lName, setLName] = useState(false);
  const [lError, setLError] = useState("");
  const [phone, setPhone] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  // const [comments, setComments] = useState({
  //   commentsErrText: "Please enter comments",
  //   commentsErr: false,
  //   editComments: "",
  // });
  const [comments, setComments] = useState("");
  const [commentsErr, setCommentsErr] = useState(false);

  const [shiftTime, setShift] = useState(false);
  const [shiftError, setShiftError] = useState("");
  const [shiftDistance, setShiftDistance] = useState(false);
  const [distanceError, setDistanceError] = useState("");

  const [position, setPosition] = useState(false);
  const [positionError, setPositionError] = useState("");
  const [date, setDate] = useState(false);
  const [dateError, setDateError] = useState("");

  const [profileImage, setProfileImage] = useState("");
  const [profileImageStatus, setProfileImageStatus] = useState(false);
  const [resume, setResume] = useState((prevState) => (prevState ? prevState : ""));

  // const [resume, setResume] = useState(resume && resume ? resume : "");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subLoader, setSubLoader] = useState(false);
  const [signImageURL, setSignImageURL] = useState("");
  const [signImageAPIData, setSignImageAPIData] = useState("");
  const [signModal, setSignModal] = useState(false);
  const sigCanvas = useRef({});
  const [profilePercentage, setPrfilePersentage] = useState({});
  const [profilePercentage1, setPrfilePersentage1] = useState({});
  const [profilePercentage2, setPrfilePersentage2] = useState({});
  const [profilePercentage3, setPrfilePersentage3] = useState({});
  const [profilePercentage4, setPrfilePersentage4] = useState({});
  const [ackStatus, setAckStatus] = useState({
    ackText: "",
    application: "",
    isAcknowledged: false,
    isFirstAcknowledged: false,
  });
  const [certDropdown, setcertDropdown] = useState(false);
  const [certifications, setcertifications] = useState([]);
  const [certsOriginal, setcertsOriginal] = useState([]);
  const [selectedCerts, setselectedCerts] = useState([]);
  const [cerError1, setcerError1] = useState("");
  const [cerError, setcerError] = useState(false);
  const [certsCopy, setcertsCopy] = useState([]);
  const [certsDuplicate, setcertsDuplicate] = useState([]);
  const [items, setitems] = useState([]);
  const [specialityCopy, setspecialityCopy] = useState([]);
  const [selectedSpecialities, setselectedSpecialities] = useState([]);
  const [dropdownStatus, setdropdownStatus] = useState(false);
  const [specialityDropdown, setspecialityDropdown] = useState(false);
  const [speError1, setspeError1] = useState("");
  const [speError, setspeError] = useState(false);
  const [button, setButton] = useState(false);
  const [saveButton, setSaveButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(false);
  const [specialitiesDuplicate, setspecialitiesDuplicate] = useState([]);
  const [terms, setTerms] = useState({
    isLegal: "",
    isAware: "",
    isMalpracticed: "",
    isInvestigated: "",
    isSuspended: "",
    termsChecked: false,
    readMore: false,
  });
  const [termsErr, setTermsErr] = useState({
    isLegalErr: false,
    isInvestigatedErr: false,
    isTermsErr: false,
    isAwareErr: false,
    isMalpracticedErr: false,
    isSuspendedErr: false,
    sig: false,
    errors: [],
  });

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isWCheck, setIsWCheck] = useState([]);

  let imagePreview;

  const signToggle = (e) => {
    e.preventDefault();
    setSignModal(!signModal);
    setTermsErr({
      ...termsErr,
      isLegalErr: false,
      isInvestigatedErr: false,
      isTermsErr: false,
      isAwareErr: false,
      isMalpracticedErr: false,
      isSuspendedErr: false,
      sig: false,
    });
    setTerms({
      ...terms,
      isLegal: "",
      isAware: "",
      isMalpracticed: "",
      isInvestigated: "",
      isSuspended: "",
      termsChecked: false,
    });
    setSaveButton(true);
    setSubmitButton(false);
  };
  function toggleOpenCertification() {
    ackStatus.isFirstAcknowledged
      ? setcertDropdown(false)
      : setcertDropdown(true);
  }
  const handleSignSave = (e) => {
    e.preventDefault();
    if (!terms.isLegal) {
      setTermsErr({ isLegalErr: true });
    } else if (!terms.isAware) {
      setTermsErr({ isAwareErr: true });
    } else if (!terms.isMalpracticed) {
      setTermsErr({ isMalpracticedErr: true });
    } else if (!terms.isInvestigated) {
      setTermsErr({ isInvestigatedErr: true });
    } else if (!terms.isSuspended) {
      setTermsErr({ isSuspendedErr: true });
    } else if (!terms.termsChecked) {
      setTermsErr({ isTermsErr: true });
    } else {
      submitAcknowledgement(
        sigCanvas.current
          .getTrimmedCanvas()
          .toDataURL("image/png")
          .split(",")[1]
      );
    }
  };
  // handleAckValidation

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const image = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png")
      .split(",")[1];
    setSubLoader(true);
    var config = {
      method: "POST",
      url: AppConstants.API + "/nurse/nurseProfilePdf/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: {
        image: image,
        isLegalChecked: terms.isLegal === "Yes" ? true : false,
        isInvestigatedChecked: terms.isInvestigated === "Yes" ? true : false,
        isAware: terms.isAware === "Yes" ? true : false,
        isMalpracticed: terms.isMalpracticed === "Yes" ? true : false,
        isSuspended: terms.isSuspended === "Yes" ? true : false,
        isReadAckChecked: terms.termsChecked,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setSaveButton(true);
          setSubLoader(false);
          NotificationManager.success("Acknowledgement submitted!", "", 5000);
          setSignModal(!signModal);
          setUser1(response.data && response.data.data && response.data.data);
          getProfilePersentage();
          getProfileListAPI();
          setTermsErr({
            ...termsErr,
            isLegalErr: false,
            isInvestigatedErr: false,
            isTermsErr: false,
            isAwareErr: false,
            isMalpracticedErr: false,
            isSuspendedErr: false,
            sig: false,
          });
          setTerms({
            ...terms,
            isLegal: "",
            isAware: "",
            isMalpracticed: "",
            isInvestigated: "",
            isSuspended: "",
            termsChecked: false,
          });
        } else {
          NotificationManager.error("Acknowledgement failed.", "", 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSignClear = (e) => {
    e.preventDefault();
    sigCanvas.current.clear();
    setSaveButton(true);
    setSubmitButton(false);
  };

  async function submitAcknowledgement(image) {
    if (
      image ==
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
    ) {
      setTimeout(() => {
        setTermsErr({ sig: false });
      }, 2000);
      setTermsErr({ sig: true });
    } else {
      NotificationManager.success("Signature saved!", "", 5000);
      setSaveButton(false);
      setSubmitButton(true);
    }
  }

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    getProfileListAPI();
    getStatesListAPI();
    getCountrysListAPI();
    getTimeSheetListAPI();
    getReferalListAPI();
    getProfilePersentage();
    // getCrtListAPI()
  }, []);

  function handleCertChange(selectedList, event) {
    setcerError1("");
    setcerError(false);
    if (event.action === "select-option" && event.option.value !== "All") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      selectedList = selectedList.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      selectedList = [];
    } else if (event.action === "deselect-option") {
      selectedList = selectedList.filter((o) => o.value !== "All");
    } else if (selectedList.length === certifications.length - 1) {
      selectedList = certifications;
    } else {
      selectedList = selectedList;
    }
    let certSel = [];
    selectedList.map((item, index) => {
      certSel.push(item);
    });
    certsCopy.map((item, index) => {
      if (certSel.includes(item)) {
      } else {
        certSel.push(item);
      }
    });
    var result = certSel.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    certFunction(selectedList);
    setcertifications(result);
    setselectedCerts(selectedList);
    setcertsDuplicate(result);
  }

  const handleSelectCrt = (e) => {
    setspeError(true);
    setcerError(true);
    setcerError1("");
    if (e.target.value) {
      const index = certifications.findIndex((x) => x.value == e.target.value);
      var obj = certifications[index];
      var crtArray = [];
      crtArray.push(obj);
      setselectedCerts(crtArray);
      setcerError(false);
      certFunction(crtArray);
    } else {
      setcerError(true);
      setspeError(true);
      // setcerError1("Please Select Any One");
      setselectedCerts([]);
      setselectedSpecialities([]);
      console.log("selectedSpecialities", selectedSpecialities)
    }
  };

  const handleSelectSpl = async (e) => {
    setspeError(true);
    setspeError1("");
    if (e.target.value) {
      const index = items.findIndex((x) => x.value == e.target.value);
      var obj1 = items[index];
      var spiArray = [];
      spiArray.push(obj1);
      setselectedSpecialities(spiArray);
      setspeError(false);
    } else {
      setspeError(true);
      setselectedSpecialities([]);
    }
    // this.setState({
    //   selectedSpecialities: spiArray,
    // });
  };

  function certFunction(selectedList) {
    var certVal = [];
    selectedList.map((item) => {
      if (item._id) {
        certVal.push(item._id);
      }
    });
    let tablefilterdata = {
      ctmsCertId: certVal,
    };
    axios
      .post(
        AppConstants.Jobs_API + "/specialities/getCtmsMatchedSpecialities",
        tablefilterdata
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          if (data.data.length > 0) {
            var originalArray =
              !userInfo && !userInfo.isTNIUser
                ? [
                  {
                    _id: "All",
                    speciality: "All",
                    jobsCount: "All",
                    label: "All",
                    value: "All",
                  },
                  ...data.data,
                ]
                : [...data.data];
            console.log(
              "userInfo && !userInfo.isTNIUser",
              userInfo && userInfo.isTNIUser
            );
            setitems(originalArray);
            setspecialityCopy([
              {
                _id: "All",
                speciality: "All",
                jobsCount: "All",
                label: "All",
                value: "All",
              },
              ...data.data,
            ]);
          } else {
            setselectedSpecialities([]);
          }
        }
      });
  }
  function handleCertFocus() {
    if (selectedCerts.length === 0) {
      setcertifications(certsOriginal);
      setcertsCopy(certsOriginal);
      setcertsDuplicate(certsOriginal);
    } else {
      setcertifications(certifications);
      setcertsCopy(certifications);
      setcertsDuplicate(certifications);
    }
  }
  function handleCertBlur() {
    toggleClosedCert();
  }
  function toggleClosedCert() {
    setcertDropdown(false);
  }
  useEffect(() => {
    // certifications
    axios
      .get(
        AppConstants.Jobs_API + "/certification/getCtmsMatchedCertifications"
      )
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success") {
          var originalArray =
            !userInfo && !userInfo.isTNIUser
              ? [
                {
                  _id: "All",
                  certification: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ]
              : [...data.data];
          setcertifications(originalArray);
          setcertsOriginal([
            {
              _id: "All",
              certification: "All",
              jobsCount: "All",
              label: "All",
              value: "All",
            },
            ...data.data,
          ]);
        }
      });
  }, [userInfo && userInfo.isTNIUser]);
  async function getProfileListAPI() {
    setLoader(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(config)
      .then(function (response) {
        setLoader(false);
        if (response.status == "200") {
          setAckStatus({
            ackText: response && response.data && response.data.ackText,

            application:
              response &&
                response.data &&
                response.data.data &&
                response.data.data.application
                ? response.data.data.application
                : "",
            isAcknowledged:
              response &&
                response.data &&
                response.data.data &&
                response.data.data
                ? response.data.data.isAcknowledged
                : false,
            isFirstAcknowledged:
              response &&
                response.data &&
                response.data.data &&
                response.data.data
                ? response.data.data.isFirstAcknowledged
                : false,
          });
          setUser1(response.data && response.data.data && response.data.data);
          setPrimaryDetails1(
            response.data &&
            response.data.data &&
            response.data.data.contactInformation
          );
          setUser(response.data && response.data.data && response.data.data);
          const certsArray = [];
          if (
            response.data.data &&
            response.data.data.proffesionalInformation.certifications
          ) {
            response.data.data.proffesionalInformation.certifications.forEach(
              async (data) => {
                const certsObj = {
                  certification: data.ctmsCertName,
                  ctmsCertId: data.ctmsCertId,
                  ctmsCertName: data.ctmsCertName,
                  label: data.ctmsCertName,
                  value: data.ctmsCertName,
                  _id: data.ctmsCertId,
                };

                certsArray.push(certsObj);
              }
            );
            certFunction(certsArray);
            setselectedCerts(certsArray);
          }
          const specArray = [];
          response.data.data.proffesionalInformation.specialities.map(
            async (data) => {
              const specObj = {
                ctmsSpecialityName: data.ctmsSpecName,
                speciality: data.ctmsSpecId,
                label: data.ctmsSpecName,
                value: data.ctmsSpecName,
              };
              specArray.push(specObj);
            }
          );
          console.log(specArray);
          setselectedSpecialities(specArray);
          setProfileImage(
            response.data &&
            response.data.data &&
            response.data.data &&
            response.data.data.profileImage
          );
          setPrimaryDetails(
            response.data &&
            response.data.data &&
            response.data.data.contactInformation
          );
          setAddress(
            response.data &&
            response.data.data &&
            response.data.data.homeAddress[0]
          );
          setEContact(
            response.data &&
            response.data.data &&
            response.data.data.emergencyContactDetails
          );
          setReferral(
            response.data &&
            response.data.data &&
            response.data.data.referralInformation
          );
          var newArray1 = [];
          if (
            response.data &&
            response.data.data &&
            response.data.data.workPreferences.positionType.length > 0
          ) {
            response.data &&
              response.data.data &&
              response.data.data.workPreferences.positionType.map((item) => {
                newArray1.push({ label: item, value: item });
              });
            setWorkPreferences(response.data.data.workPreferences.positionType);
          }
          setWorkPreferences1(
            response.data &&
              response.data.data &&
              response.data.data.workPreferences &&
              response.data.data.workPreferences.dateAvailable
              ? new Date(
                response.data &&
                response.data.data &&
                response.data.data.workPreferences &&
                response.data.data.workPreferences.dateAvailable
              )
              : ""
          );
          setShiftPreferences(
            response.data &&
            response.data.data &&
            response.data.data.shiftPreferences
          );
          setShiftPreferences1(
            response.data &&
            response.data.data &&
            response.data.data.shiftPreferences.shifts
          );
          var newArray = [];
          if (
            response.data &&
            response.data.data &&
            response.data.data.Work_Locations.length > 0
          ) {
            response.data &&
              response.data.data &&
              response.data.data.Work_Locations.map((item) => {
                newArray.push({ label: item, value: item });
              });
            setIsWCheck(response.data.data.Work_Locations);
            setIsCheckAll(
              response.data.data.Work_Locations.length == 51 ? true : false
            );
          }

          setEducationHistory(
            response.data &&
            response.data.data &&
            response.data.data.education_history
          );
          setResume(
            response.data && response.data.data && response.data.data.resume
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getStatesListAPI() {
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllWorkLocationStates",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setStates(response.data && response.data.data && response.data.data);
          // if (response.data && response.data.data && response.data.data) {
          //   var newArray =
          //     response.data && response.data.data && response.data.data;
          //   const add = { value: "All" };
          //   newArray.unshift(add);
          //   setStates1(newArray);
          // }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getCountrysListAPI() {
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllCountries",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setCountrys(
            response.data && response.data.data && response.data.data
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getReferalListAPI() {
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/specialities/getReferralSources",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setReferralList(
            response.data && response.data.data && response.data.data
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getTimeSheetListAPI() {
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/appDropdownsList",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        // if (response.status == "200") {
        setPositionTypes(
          response.data &&
          response.data.data[0] &&
          response.data.data[0].Position_Types
        );
        console.log(
          "=",
          response.data &&
          response.data.data[0] &&
          response.data.data[0].Position_Types
        );
        setShifts(
          response.data &&
          response.data.data[0] &&
          response.data.data[0].Shift_Preferences
        );
        setDistance(
          response.data &&
          response.data.data[0] &&
          response.data.data[0].Distance
        );
        setEmployeTypes(
          response.data &&
          response.data.data[0] &&
          response.data.data[0].Employment_Type
        );
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getProfilePersentage() {
    getProfileListAPI();
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/nurseProfilePersentage/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setPrfilePersentage(
            response.data &&
            response.data.data &&
            response.data.data.overAllCountPersentage
          );
          setPrfilePersentage1(
            response.data &&
            response.data.data &&
            response.data.data.personalInformation
          );
          setPrfilePersentage2(
            response.data &&
            response.data.data &&
            response.data.data.preferances
          );
          setPrfilePersentage3(
            response.data &&
            response.data.data &&
            response.data.data.experiances
          );
          setPrfilePersentage4(
            response.data &&
            response.data.data &&
            response.data.data.acknowledgement
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     const value =  isWCheck.length > 0 && Number(isWCheck.length) == states.length > 0 && Number(states.length) ? true : false;
  //     // if(isWCheck.length > 0 && states.length > 0){
  //       console.log(value)
  //       if(value){
  //         setIsCheckAll(true);
  //       }
  //   }, 5000);

  // }, [isWCheck.length > 0, states.length > 0]);

  const handleClose = (e) => {
    e.preventDefault();
    setModal(false);
    setModalEdit(false);
    // setEditStatus(true);
    setEditStatus1(true);

    // setComments({ commentsErrText: "" });
    // setComments({ commentsErr: false });
    // setComments({
    //   editComments: "",
    // });

    setComments("");
    setCommentsErr(false);
  };
  const toggle = () => {
    if (count == 0 && ackStatus.isAcknowledged) {
      setModal(true);
      setCount(1);
    }
  };

  const togglesendmail = (item) => {
    console.log("item ------------", item);
    if (ackStatus.isFirstAcknowledged) {
      setModalEdit(true);
      if (item == "first name" || item == "last name" || item == "mobile phone" || item == "email" ||
        item == "certification" || item == "speciality" || item == "referral info") {
        setEditKey(item);
      }
    }
  };

  const handleAccordion1 = (item) => {
    if (item == collapse1) {
      setCollapse1("0");
    } else {
      setCollapse1(item);
    }
  };

  const handleAccordion2 = (item) => {
    if (item == collapse2) {
      setCollapse2("0");
    } else {
      setCollapse2(item);
    }
  };

  const handleAccordion3 = (item) => {
    if (item == collapse3) {
      setCollapse3("0");
    } else {
      setCollapse3(item);
    }
  };
  const handleAccordion4 = (item) => {
    if (item == collapse4) {
      setCollapse4("0");
    } else {
      setCollapse4(item);
    }
  };

  const HandleAccordion1 = () => {
    setCollapse1(!collapse1);
  };
  const HandleAccordion2 = () => {
    setCollapse2(!collapse2);
  };
  const HandleAccordion3 = () => {
    setCollapse3(!collapse3);
  };

  const handleAccordion15 = () => {
    setCollapse15(!collapse15);
  };

  const handleEditButton = () => {
    getProfileListAPI();
    setEditStatus(!editStatus);
    setFName(false);
    setLName(false);
    setPhone(false);
    setEmail(false);
    setFError("");
    setLError("");
    setPhoneError("");
    setEmailError("");
  };
  const handleEditButton1 = () => {
    ackStatus.isFirstAcknowledged ? setModalEdit(true) : setModalEdit(false);
    ackStatus.isFirstAcknowledged ? setEditKey("address") : setModalEdit(false);
    getProfileListAPI();
    setEditStatus1(!editStatus1);
    setStreetAddress(false);
    setCity(false);
    setState(false);
    setZip(false);
    setStreetAddressError("");
    setCityError("");
    setStateError("");
    setZipError("");
  };
  const handleEditButton2 = () => {
    getProfileListAPI();
    setEditStatus2(!editStatus2);
    setFullName(false);
    setMobileNumber(false);
    setRelationship(false);
    setFullNameError("");
    setMobileNumberError("");
    setRelationshipError("");
  };
  const handleEditButton3 = () => {
    getProfileListAPI();
    setEditStatus3(!editStatus3);
    setReferralSource(false);
    setReferralSourceError("");
  };
  const handleEditButtonPre1 = () => {
    getProfileListAPI();
    setEditStatusPre1(!editStatusPre1);
    setPosition(false);
    setPositionError("");
    setDate(false);
    setDateError("");
  };
  const handleEditButtonPre2 = () => {
    getProfileListAPI();
    setEditStatusPre2(!editStatusPre2);
    setShift(false);
    setShiftError("");
    setShiftDistance(false);
    setDistanceError("");
  };
  const handleEditButtonPre3 = () => {
    getProfileListAPI();
    setEditStatusPre3(!editStatusPre3);
  };
  const handleEditButtonEdu1 = () => {
    getProfileListAPI();
    setEditStatusEdu1(!editStatusEdu1);
  };
  const handleEditButtonEdu2 = () => {
    getProfileListAPI();
    setEditStatusEdu2(!editStatusEdu2);
  };
  const handleEditButtonEdu3 = () => {
    getProfileListAPI();
    setEditStatusEdu3(!editStatusEdu3);
  };
  const handleAcknowledgement = () => {
    getProfileListAPI();
    setAcknowledgement(!acknowledgement);
  };
  const handlePOnChanges = (e) => {
    setFName(false);
    setLName(false);
    setPhone(false);
    setEmail(false);
    setFError("");
    setLError("");
    setPhoneError("");
    setEmailError("");
    if (
      e.target.name == "primaryEmail" ||
      e.target.name == "primaryPhoneNumber"
    ) {
      setPrimaryDetails({
        ...primaryDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setUser({
        ...userInfo,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleHOnChanges = (e) => {
    setStreetAddress(false);
    setCity(false);
    setState(false);
    setZip(false);
    setStreetAddressError("");
    setCityError("");
    setStateError("");
    setZipError("");
    setAddress({
      ...userAddress,
      [e.target.name]: e.target.value,
    });
  };
  const handleEOnChanges = (e) => {
    setFullName(false);
    setMobileNumber(false);
    setRelationship(false);
    setFullNameError("");
    setMobileNumberError("");
    setRelationshipError("");
    setEContact({
      ...userEContact,
      [e.target.name]: e.target.value,
    });
  };
  const handleROnChanges = (e) => {
    setReferralSource(false);
    setReferralSourceError("");
    setReferral({
      ...userReferral,
      [e.target.name]: e.target.value,
    });
    referralList.filter((x) => {
      if (x.referralSource == e.target.value) {
        var value = x.referralSourceId;
        setReferral({
          ...userReferral,
          referralSourceId: value,
          [e.target.name]: e.target.value,
        });
      }
    });
  };
  const handlePreWOrkOnChanges = (data) => {
    setWorklocation(data);
  };
  const handlePreShiftOnChanges = (e) => {
    setShiftPreferences({
      ...shiftPreferences,
      [e.target.name]: e.target.value,
    });
  };
  const handleOnchange = (e) => {
    setShiftPreferences1(e.target.value);
  };
  const handleComments = (e) => {
    setComments(e.target.value);
    setCommentsErr(false);

    // if (e.target.value == "") {
    //   setComments({ commentsErr: true });
    //   setComments({ commentsErrText: "Please enter your comments." });
    // } else {
    //   setComments({ commentsErr: false });
    //   setComments({ commentsErrText: "" });
    // }
    // // setComments({editComments: e.target.value});
    // setComments({ ...comments, [e.target.name]: e.target.value });
    //   console.log(comments && comments.editComments)

    //   setComments({ commentsErr: false });
    //   setComments({ commentsErrText: "" });
  };

  const handlePreWorkPreOnChanges = (data) => {
    setWorkPreferences(data);
  };
  function toggleOpenSpeciality() {
    ackStatus.isFirstAcknowledged
      ? setspecialityDropdown(false)
      : setspecialityDropdown(true);
  }
  function handleChange(value, event) {
    setspeError1("");
    setspeError(false);
    if (event.action === "select-option" && event.option.value !== "All") {
      value = value.filter((o) => o.value !== "All");
    } else if (
      event.action === "select-option" &&
      event.option.value === "All"
    ) {
      value = value.filter((o) => o.value === "All");
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "All"
    ) {
      value = [];
    } else if (event.action === "deselect-option") {
      value = value.filter((o) => o.value !== "All");
    } else if (value.length === items.length - 1) {
      value = items;
    } else {
      value = value;
    }
    let spec = [];
    value.map((item, index) => {
      spec.push(item);
    });
    specialityCopy.map((item, index) => {
      if (spec.includes(item)) {
      } else {
        spec.push(item);
      }
    });
    var result = spec.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setitems(result);
    setselectedSpecialities(value);
    setspecialitiesDuplicate(result);
  }
  function handleSpecialityFocus() {
    if (selectedSpecialities.length === 0) {
      setitems(items);
      setspecialitiesDuplicate(items);
      setspecialityCopy(items);
    } else {
      setitems(items);
      setspecialitiesDuplicate(items);
      setspecialityCopy(items);
    }
  }
  function handleSpecialityBlur() {
    toggleClosedSpeciality();
  }
  function toggleClosedSpeciality() {
    setspecialityDropdown(false);
  }
  const handlePreSubmit = (e) => {
    console.log(cerError, "selectedSpecialities", speError)
    e.preventDefault();
    if (userInfo.firstName == "") {
      setFName(true);
      setFError("Please Enter First Name");
    } else if (userInfo.lastName == "") {
      setLName(true);
      setLError("Please Enter Last Name");
    } else if (primaryDetails.primaryPhoneNumber == "") {
      setPhone(true);
      setPhoneError("Please Enter Mobile Number");
    } else if (primaryDetails.primaryEmail == "") {
      setEmail(true);
      setEmailError("Please Enter Email");
    } else if (cerError) {
      setcerError1("Please Select Any One");
    } else if (speError) {
      setspeError1("Please Select Any One");
    } else {
      setButton(true);
      setFName(false);
      setLName(false);
      setPhone(false);
      setEmail(false);
      setcerError(false);
      setcerError1("");
      setspeError1("");
      setspeError(false);
      setFError("");
      setLError("");
      setPhoneError("");
      setEmailError("");
      var proffesionalInformation = {};
      var cerA = [];
      var speA = [];
      selectedCerts.map((a) => {
        var certApiArray = {};
        certApiArray["ctmsCertId"] = a._id;
        certApiArray["ctmsCertName"] = a.ctmsCertName;
        cerA.push(certApiArray);
        proffesionalInformation["certifications"] = cerA;
      });
      selectedSpecialities.map((a) => {
        var specApiArray = {};
        specApiArray["ctmsSpecId"] = a.speciality;
        specApiArray["ctmsSpecName"] = a.ctmsSpecialityName;
        speA.push(specApiArray);
        proffesionalInformation["specialities"] = speA;
      });
      var data = JSON.stringify({
        contactInformation: {
          primaryEmail: primaryDetails.primaryEmail,
          primaryPhoneNumber: primaryDetails.primaryPhoneNumber,
        },
        firstName: userInfo.firstName,
        middleName: userInfo.middleName,
        lastName: userInfo.lastName,
        maidenName: userInfo.maidenName,
        nickName: userInfo.nickName,
        proffesionalInformation,
      });

      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setButton(false);
          setSubLoader(true);
          if (response.data.Status == "Success") {
            setSubLoader(false);
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();
            setEditStatus(!editStatus);
            getProfilePersentage();
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }

    if (profileImageStatus) {
      handleProfileImageSubmit(e);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleProfileImageSubmit = (e) => {
    setLoader(true);
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };
    e.preventDefault();
    let formData = new FormData();
    formData.append("profileImage", profileImage);
    var data = JSON.stringify({
      profileImage: userInfo.profileImage,
    });
    let url = AppConstants.API + "/nurse/updateNurseProfileImage/" + userId;
    axios
      .post(url, formData, axiosConfig)
      .then(function (response) {
        setLoader(false);
        if (response.data.Status == "Success") {
          let id = localStorage.getItem("id");
          props.getUserInfo(id);
          getProfileListAPI();
          setEditStatus(!editStatus);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleHomeSubmit = (e) => {
    e.preventDefault();
    if (userAddress.Street_Address == "") {
      setStreetAddress(true);
      setStreetAddressError("Please Enter Street Addres");
    } else if (userAddress.city == "") {
      setCity(true);
      setCityError("Please Enter City Name");
    } else if (userAddress.state == "") {
      setState(true);
      setStateError("Please Enter State Name");
    } else if (userAddress.zipcode == "") {
      setZip(true);
      setZipError("Please Enter Zip Code");
    } else {
      setButton(true);
      setStreetAddress(false);
      setCity(false);
      setState(false);
      setZip(false);
      setStreetAddressError("");
      setCityError("");
      setStateError("");
      setZipError("");
      var data = JSON.stringify({
        homeAddress: [
          {
            _id: userAddress._id,
            Apartment: userAddress.Apartment,
            Street_Address: userAddress.Street_Address,
            city: userAddress.city,
            state: userAddress.state,
            country: userAddress.country,
            zipcode: userAddress.zipcode,
          },
        ],
      });

      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setButton(false);
          setSubLoader(true);
          setEditStatus1(!editStatus1);
          if (response.data.Status == "Success") {
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();
            setSubLoader(false);
            getProfilePersentage();
          } else {
            NotificationManager.error(response.data.msg, "", 5000);
            setSubLoader(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }
  };
  const handleEmrSubmit = (e) => {
    e.preventDefault();
    let cleanedNumber = userEContact.phoneNumber.replace(/\D/g, "");
    if (userEContact.contactName == "") {
      setFullName(true);
      setFullNameError("Please enter your full name.");
    } else if (userEContact.phoneNumber == "") {
      setMobileNumber(true);
      setMobileNumberError("Please enter your valid phone number.");
    } else if (cleanedNumber.length != 10) {
      setMobileNumber(true);
      setMobileNumberError("Please enter your valid phone number.");
    } else if (userEContact.relation == "") {
      setRelationship(true);
      setRelationshipError("Please enter your relationship.");
    } else {
      setButton(true);
      setFullName(false);
      setMobileNumber(false);
      setRelationship(false);
      setFullNameError("");
      setMobileNumberError("");
      setRelationshipError("");
      var data = JSON.stringify({
        emergencyContactDetails: {
          contactName: userEContact.contactName,
          phoneNumber: userEContact.phoneNumber,
          relation: userEContact.relation,
        },
      });

      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setSubLoader(true);
          setEditStatus2(!editStatus2);
          if (response.data.Status == "Success") {
            setButton(false);
            setSubLoader(false);
            // if (ackStatus.isAcknowledged) {
            //   // setSignModal(true);
            // } else {
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();

            getProfilePersentage();
            // }
          } else {
            NotificationManager.error(response.data.msg, "", 5000);
            setSubLoader(false);
            setButton(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }
  };
  const handleRefSubmit = (e) => {
    e.preventDefault();
    if (!userReferral.referralSource) {
      setReferralSource(true);
      setReferralSourceError("Please select how did you hear about us?");
    } else {
      setReferralSource(false);
      setButton(true);
      setReferralSourceError("");
      var data = JSON.stringify({
        referralInformation: {
          referralSource: userReferral && userReferral.referralSource,
          referralSourceId: userReferral && userReferral.referralSourceId,
          referralBy: userReferral && userReferral.referralBy,
        },
      });
      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setSubLoader(true);
          setEditStatus3(!editStatus3);
          if (response.data.Status == "Success") {
            setButton(false);
            setSubLoader(false);
            // if (ackStatus.isAcknowledged) {
            //   setSignModal(true);
            // } else {
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();
            getProfilePersentage();
            // }
          } else {
            NotificationManager.error(response.data.msg, "", 5000);
            setSubLoader(false);
            setButton(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }
  };
  // const locations = states.length > 0 && states.map((item, i)=>{
  //   return(
  //     <input
  //     id={item.code}
  //     name={item.value}
  //     type="checkbox"
  //     onChange={handleWClick}
  //     checked={isWCheck.includes(item.code)}
  //     />
  //     )
  //   })
  //   console.log(locations)

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsWCheck(states.map((li) => li.code));
    if (isCheckAll) {
      setIsWCheck([]);
    }
  };

  const handleWClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(false);
    setIsWCheck([...isWCheck, id]);
    if (!checked) {
      setIsWCheck(isWCheck.filter((item) => item !== id));
    }
  };

  const handleWorkLocationOnSubmit = (e) => {
    e.preventDefault();
    setButton(true);
    var list = [];
    worklocation.length > 0 &&
      worklocation.map((item) => {
        list.push(item.value);
      });
    var data = JSON.stringify({
      Work_Locations: isWCheck,
    });
    var config = {
      method: "post",
      url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setSubLoader(true);
        setEditStatusPre3(!editStatusPre3);
        if (response.data.Status == "Success") {
          setSubLoader(false);
          setButton(false);
          NotificationManager.success(response.data.msg, "", 5000);
          getProfileListAPI();
          getProfilePersentage();
        } else {
          setSubLoader(false);
          setButton(false);
          NotificationManager.error(response.data.msg, "", 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        setButton(false);
      });
  };
  const handleShiftOnSubmit = (e) => {
    e.preventDefault();
    if (shiftPreferences1.length == 0) {
      setShift(true);
      setShiftError("Please Select Shift");
    } else if (shiftPreferences.distance == "") {
      setShiftDistance(true);
      setDistanceError("Please Select Distance");
    } else {
      setShift(false);
      setShiftError("");
      setShiftDistance(false);
      setDistanceError("");
      setButton(true);
      var data = JSON.stringify({
        shiftPreferences: {
          shifts: shiftPreferences1,
          distance: shiftPreferences.distance,
        },
      });
      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setSubLoader(true);
          if (response.data.Status == "Success") {
            setSubLoader(false);
            setEditStatusPre2(true);
            setButton(false);
            // if (ackStatus.isAcknowledged) {
            //   setSignModal(true);
            // } else {
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();
            getProfilePersentage();
            // }
          } else {
            NotificationManager.error(response.data.msg, "", 5000);
            setSubLoader(false);
            setButton(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }
  };
  const handleWorkPreOnSubmit = (e) => {
    e.preventDefault();
    if (workPreferences1 == "") {
      setDate(true);
      setDateError("Please select your date availability.");
    } else if (workPreferences == "") {
      setPosition(true);
      setPositionError(" Please select position type.");
    } else {
      setPosition(false);
      setPositionError("");
      setDate(false);
      setButton(true);
      setDateError("");
      // var list = [];
      // workPreferences.length > 0 &&
      //   workPreferences.map((item) => {
      //     list.push(item.value);
      //   });
      // var data = JSON.stringify({
      //   Work_Locations: list,
      // });
      var data = JSON.stringify({
        workPreferences: {
          positionType: workPreferences,
          dateAvailable: moment(workPreferences1).format("yyyy-MM-DD"),
        },
      });
      var config = {
        method: "post",
        url: AppConstants.API + "/nurse/updateNurseProfile/" + userId,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setSubLoader(true);
          if (response.data.Status == "Success") {
            setSubLoader(false);
            setButton(false);
            setEditStatusPre1(true);
            // if (ackStatus.isAcknowledged) {
            //   setSignModal(true);
            // } else {
            NotificationManager.success(response.data.msg, "", 5000);
            getProfileListAPI();

            getProfilePersentage();
            // }
          } else {
            NotificationManager.error(response.data.msg, "", 5000);
            setSubLoader(false);
            setButton(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setButton(false);
        });
    }
  };

  // update profile image
  const handleProfileImageChanges = (event) => {
    setProfileImageStatus(true);

    if (event.target.files[0]) {
      let reader = new FileReader();

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setProfileImage(event.target.files[0]);
  };

  /** Select File Img **/
  if (profileImage) {
    imagePreview = (
      <div className="previewText image-container">
        <img src={profileImage} alt={" - Travel Nurses Inc"} />
      </div>
    );
  } else {
    imagePreview = (
      <div className="previewText image-container">
        <img src={Placeholder} alt="Placeholder" />
      </div>
    );
  }
  if (imagePreviewUrl) {
    imagePreview = (
      <div className="image-container image-fill">
        <img src={imagePreviewUrl} alt={" - Travel Nurses Inc"} width="95" />{" "}
      </div>
    );
  }
  const handleClick = (e) => {
    const { name, checked } = e.target;
    setShiftPreferences1([...shiftPreferences1, name]);
    if (!checked) {
      setShiftPreferences1(shiftPreferences1.filter((item) => item !== name));
    }
  };

  const handlePClick = (e) => {
    const { name, checked } = e.target;
    setWorkPreferences([...workPreferences, name]);
    if (!checked) {
      setWorkPreferences(workPreferences.filter((item) => item !== name));
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (comments === "") {
      setCommentsErr(true);
    } else {
      setSubLoader(true);
      let axiosConfig = {
        headers: {
          Authorization: token,
        },
      };

      await axios
        .post(
          AppConstants.API + "/nurse/sendMailOnNurseEditProfile",
          { comments: comments, subjectType: editKey },

          axiosConfig
        )
        .then((res) => {
          setSubLoader(false);
          setEditStatus1(true);
          setCommentsErr(false);
          setComments("");
          setModalEdit(false);
          res.data.Status === "Success"
            ? NotificationManager.success(res.data.msg, "", 5000)
            : NotificationManager.error(res.data.msg, "", 5000);
        });
    }
  };

  const handleTerms = (e) => {
    setTermsErr({
      ...termsErr,
      isLegalErr: false,
      isInvestigatedErr: false,
      isTermsErr: false,
      isAwareErr: false,
      isMalpracticedErr: false,
      isSuspendedErr: false,
      sig: false,
    });
    // setTerms({...terms,
    //   isLegal: "",
    //   isAware: "",
    //   isMalpracticed: "",
    //   isInvestigated: "",
    //   isSuspended: "",
    //   termsChecked: false})
    if (e.target.name === "termsChecked") {
      setTerms({ ...terms, termsChecked: e.target.checked });
    } else {
      setTerms({ ...terms, [e.target.name]: e.target.value });
    }
    // if (e.target.name === "isLegal") {
    //   if (e.target.value === "") {
    //     setTermsErr({ ...termsErr, isLegalErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isLegalErr: false });
    //   }
    // }
    // if (e.target.name === "isAware") {
    //   if (e.target.value === "") {
    //     setTermsErr({ ...termsErr, isAwareErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isAwareErr: false });
    //   }
    // }
    // if (e.target.name === "isMalpracticed") {
    //   if (e.target.value === "") {
    //     setTermsErr({ ...termsErr, isMalpracticedErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isMalpracticedErr: false });
    //   }
    // }
    // if (e.target.name === "isInvestigated") {
    //   if (e.target.value === "") {
    //     setTermsErr({ ...termsErr, isInvestigatedErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isInvestigatedErr: false });
    //   }
    // }
    // if (e.target.name === "isSuspended") {
    //   if (e.target.value === "") {
    //     setTermsErr({ ...termsErr, isSuspendedErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isSuspendedErr: false });
    //   }
    // }
    // if (e.target.name === "termsChecked") {
    //   if (e.target.checked === "") {
    //     setTermsErr({ ...termsErr, isTermsErr: true });
    //   } else {
    //     setTermsErr({ ...termsErr, isTermsErr: false });
    //   }
    // }
  };

  const handleAckValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!terms.isLegal) {
      formIsValid = false;
      errors["isLegal"] = "Please select any one.";
    }
    if (!terms.isAware) {
      formIsValid = false;
      errors["isAware"] = "Please select any one.";
    }
    if (!terms.isSuspended) {
      formIsValid = false;
      errors["isSuspended"] = "Please select any one.";
    }
    if (!terms.isMalpracticed) {
      formIsValid = false;
      errors["isMalpracticed"] = "Please select any one.";
    }
    if (!terms.isInvestigated) {
      formIsValid = false;
      errors["isInvestigated"] = "Please select any one.";
    }
    if (!terms.termsChecked) {
      formIsValid = false;
      errors["termsChecked"] = "Please check terms.";
    }
    setTermsErr({
      ...termsErr,
      errors: errors,
      isLegalErr: true,
      isAwareErr: true,
      isMalpracticedErr: true,
      isSuspendedErr: true,
      isInvestigatedErr: true,
      isTermsErr: true,
    });

    return formIsValid;
  };

  const handleReadMore = () => {
    setTerms({ ...terms, readMore: true });
    return <div>{ackStatus.ackText}</div>;
  };

  if (token) {
    return (
      <>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-lg recruiter-modal edit-profile-modal"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={(e) => handleClose(e)}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <Form className="inner-block">
                <div className="btn-block">
                  <h6>
                    Once you have edited the field, you will need to revisit the
                    acknowledgement section to re-answer the Employment
                    Eligibility questions and re-sign.
                  </h6>
                  <a
                    href="javascript:void(0)"
                    value="Submit"
                    className="blue-bttn"
                    onClick={(e) => handleClose(e)}
                    style={{ fontFamily: "Figtree Black", backgroundColor: "#334ca4" }}
                  >
                    Ok
                  </a>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>

        <Modal
          isOpen={modalEdit}
          toggle={togglesendmail}
          className="modal-lg recruiter-modal edit-profile-modal"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={(e) => handleClose(e)}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <Form className="inner-block">
                <div className="btn-block">
                  <h6>
                    Please contact the TNI compliance team to edit your{" "}
                    {editKey}.
                  </h6>
                  <Col xl="12" sm="12" className="block">
                    <FormGroup>
                      <Label for="comments">
                        Comments
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="editComments"
                        placeholder="Please Enter Your Comments..."
                        id="comments"
                        value={comments}
                        onChange={(e) => handleComments(e)}
                      />
                      <span>
                        {commentsErr == true ? (
                          <span className="error" style={{ color: "red" }}>
                            Please enter your comments
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>

                  <a
                    value="Submit"
                    className="blue-bttn"
                    onClick={handleEditSubmit}
                    style={{ fontFamily: "Figtree Black", backgroundColor: "#334ca4" }}
                  >
                    Submit
                    {subLoader ? (
                      <img
                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                        alt="No Image"
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>

        {/* finalPersonalCount
finalExperiancesCount
finalPreferancesCount */}

        <div className="profile-view main-page p-tb-50 bg-grey">
          <Container className="inner-block" id="edit-profile-layout">
            <section className="profile-grid">
              <div className="title">Profile</div>
              <div className="preview-bttn">
                <button
                  class="button back-btn float-none"
                  onClick={() => props.history.push("/profile/" + userId)}
                >
                  <FaAngleDoubleLeft />
                  <span>Back To Dashboard</span>
                </button>
              </div>
              <div className="row">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="personal-tab"
                >
                  <div className="profile-grid-left col-sm-3">
                    <div className="form-percentage-tab">
                      <Button
                        color="link"
                        onClick={() => handleAccordion15("1")}
                        aria-expanded={collapse15 === true}
                      >
                        Progress
                      </Button>
                      <Collapse
                        isOpen={collapse15 === true}
                        className="accordian-cont-block form-percentage-tab-block"
                      >
                        <ul>
                          <li className="row">
                            <span className="left-cont col-sm-6">Personal</span>
                            <span className="right-cont col-sm-6">
                              {profilePercentage1 &&
                                profilePercentage1.finalPersonalCount}
                              %
                            </span>
                            <span className="percentage-tab">
                              {" "}
                              <em
                                style={{
                                  width: `${profilePercentage1 &&
                                    profilePercentage1.finalPersonalCount
                                    }%`,
                                }}
                                className={
                                  profilePercentage1 &&
                                    profilePercentage1.finalPersonalCount > 50
                                    ? "color-two"
                                    : "color-one"
                                }
                              ></em>
                            </span>
                          </li>
                          <li className="row">
                            <span className="left-cont col-sm-6">
                              Preferences
                            </span>
                            <span className="right-cont col-sm-6">
                              {profilePercentage2 &&
                                profilePercentage2.finalPreferancesCount}
                              %
                            </span>
                            <span className="percentage-tab">
                              <em
                                style={{
                                  width: `${profilePercentage2 &&
                                    profilePercentage2.finalPreferancesCount
                                    }%`,
                                }}
                                className={
                                  profilePercentage2 &&
                                    profilePercentage2.finalPreferancesCount > 50
                                    ? "color-two"
                                    : "color-one"
                                }
                              ></em>
                            </span>
                          </li>
                          <li className="row">
                            <span className="left-cont col-sm-6">
                              Experience
                            </span>
                            <span className="right-cont col-sm-6">
                              {profilePercentage3 &&
                                profilePercentage3.finalExperiancesCount}
                              %
                            </span>
                            <span className="percentage-tab">
                              {" "}
                              <em
                                style={{
                                  width: `${profilePercentage3 &&
                                    profilePercentage3.finalExperiancesCount
                                    }%`,
                                }}
                                className={
                                  profilePercentage3 &&
                                    profilePercentage3.finalExperiancesCount > 50
                                    ? "color-two"
                                    : "color-one"
                                }
                              ></em>
                            </span>
                          </li>
                          <li className="row">
                            <span className="left-cont col-sm-6">
                              Acknowledge
                            </span>
                            <span className="right-cont col-sm-6">
                              {profilePercentage4 &&
                                profilePercentage4.finalAcknowledgementCount}
                              %
                            </span>
                            <span className="percentage-tab">
                              {" "}
                              <em
                                style={{
                                  width: `${profilePercentage4 &&
                                    profilePercentage4.finalAcknowledgementCount
                                    }%`,
                                }}
                                className={
                                  profilePercentage4 &&
                                    profilePercentage4.finalAcknowledgementCount >
                                    50
                                    ? "color-two"
                                    : "color-one"
                                }
                              ></em>
                            </span>
                          </li>
                        </ul>
                      </Collapse>
                    </div>

                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="personal-tab">
                          <BiUserCircle /> <span>Personal Info </span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Preferences">
                          <BiUserPin />
                          <span>Preferences </span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Experience">
                          <BiZoomIn />
                          <span>Experience </span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Acknowledgement">
                          <BiWindowOpen />
                          <span>
                            {" "}
                            {profilePercentage4.finalAcknowledgementCount == 100
                              ? "Application"
                              : "Acknowledgement"}{" "}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="profile-grid-right col-sm-9">
                    <Tab.Content>
                      <Tab.Pane eventKey="personal-tab">
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion1("1")}
                            aria-expanded={collapse1 === "1"}
                          >
                            Contact Info{" "}
                            <div class="w-15 w-xs-100">
                              <figure
                                className="form-progress-icon"
                                id="TooltipExample"
                              >
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Complete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {/* <img src={ProgressIcon} alt="Progress Icon" />  */}
                                  {profilePercentage1 &&
                                    profilePercentage1.finalPersonalStatus &&
                                    profilePercentage1.finalPersonalStatus[0] &&
                                    profilePercentage1.finalPersonalStatus[0]
                                      .profileInformation &&
                                    profilePercentage1.finalPersonalStatus[0]
                                      .profileInformation == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse1 === "1"}
                            className="accordian-cont-block"
                          >
                            {editStatus && editStatus === true ? (
                              <div className="profile-cont-grid input-disable">
                                <Form className="view-info  profile-info  border-pad">
                                  <div className="view-page">
                                    <div className="profile-sec file-upload row">
                                      <div className="image-sec col-sm-4">
                                        {imagePreview}
                                      </div>
                                      {loader ? (
                                        <img
                                          className="loader-img"
                                          src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                          alt="No Image"
                                        />
                                      ) : (
                                        <div className="profile-show  col-sm-8">
                                          <h4>
                                            {userInfo1.firstName
                                              ? userInfo1.firstName
                                                .charAt(0)
                                                .toUpperCase() +
                                              userInfo1.firstName.slice(1)
                                              : ""}{" "}
                                            {userInfo1.lastName
                                              ? userInfo1.lastName
                                              : ""}
                                          </h4>

                                          <p className="phone">
                                            <span>
                                              {primaryDetails1.primaryPhoneNumber
                                                ? primaryDetails1.primaryPhoneNumber
                                                : ""}
                                            </span>
                                          </p>
                                          <p className="mail">
                                            <span>
                                              {primaryDetails1.primaryEmail
                                                ? primaryDetails1.primaryEmail
                                                : ""}
                                            </span>
                                          </p>

                                          {/* <p className="city">
                                        Memphis, Tennessee 38138
                                        <br />
                                      </p> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <a
                                    href="javascript:void(0)"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButton}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                </Form>
                              </div>
                            ) : (
                              <div className="profile-cont-grid">
                                <Form className="view-info  profile-info  border-pad">
                                  <div className="view-page">
                                    <div className="profile-sec file-upload row ">
                                      <div className="image-sec col-sm-4">
                                        <div className="">
                                          {imagePreview}
                                          <Input
                                            type="file"
                                            name="profileImage"
                                            id="upload"
                                            accept="image/png, image/jpeg"
                                            className="cursor-pointer"
                                            onChange={handleProfileImageChanges}
                                          />
                                          <Label
                                            for="upload"
                                            className="file-label cursor-pointer"
                                          ></Label>

                                          <div className="clear"></div>
                                        </div>
                                      </div>
                                      <div className="profile-show  col-sm-8">
                                        <h4>
                                          {userInfo1.firstName
                                            ? userInfo1.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            userInfo1.firstName.slice(1)
                                            : ""}{" "}
                                          {userInfo1.lastName
                                            ? userInfo1.lastName
                                            : ""}
                                        </h4>

                                        <p className="phone">
                                          {primaryDetails1.primaryPhoneNumber
                                            ? primaryDetails1.primaryPhoneNumber
                                            : ""}
                                        </p>

                                        <p className="mail">
                                          {primaryDetails1.primaryEmail
                                            ? primaryDetails1.primaryEmail
                                            : ""}
                                        </p>
                                        {/* 
                                      <p className="city">
                                        Memphis, Tennessee 38138
                                        <br />
                                      </p> */}
                                      </div>
                                    </div>
                                  </div>
                                  <a
                                    href="javascript:void(0)"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButton}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                </Form>
                              </div>
                            )}
                            <Form
                              className={`form-block ${editStatus ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col xl="4" sm="6" className="block">
                                  <FormGroup
                                    className={`form-block ${ackStatus.isFirstAcknowledged
                                      ? "input-disable"
                                      : ""
                                      }`}
                                  >
                                    <div className="help-tip">
                                      <Label for="FirstName">
                                        First Name
                                        <span style={{ color: "red" }}>
                                          *{" "}
                                          {ackStatus.isFirstAcknowledged && (
                                            <>
                                              {" "}
                                              <figure>
                                                <img
                                                  src={InfoIcon}
                                                  alt="Info-Icon"
                                                />
                                              </figure>
                                              <p>
                                                You are not allowed to edit this
                                                info, our Recruiter Team will
                                                update your details please
                                                <a
                                                  href="javascript:void()"
                                                  onClick={() =>
                                                    togglesendmail("first name")
                                                  }
                                                >
                                                  {" "}
                                                  click here
                                                </a>
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </Label>

                                      <Input
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        id="FirstName"
                                        disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus}
                                        value={userInfo.firstName}
                                        onChange={(e) => handlePOnChanges(e)}
                                      />
                                    </div>
                                    <span>
                                      {fName == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {fError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col xl="4" sm="6" className="block">
                                  <FormGroup>
                                    <Label for="MiddleName">Middle Name</Label>
                                    <Input
                                      type="text"
                                      name="middleName"
                                      placeholder="Middle Name"
                                      id="MiddleName"
                                      disabled={editStatus}
                                      value={userInfo.middleName}
                                      onChange={(e) => handlePOnChanges(e)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xl="4" sm="6" className="block">
                                  <FormGroup
                                    className={`form-block ${ackStatus.isFirstAcknowledged
                                      ? "input-disable"
                                      : ""
                                      }`}
                                  >
                                    <div className="help-tip">
                                      <Label>
                                        Last Name
                                        <span style={{ color: "red" }}>*</span>
                                        {ackStatus.isFirstAcknowledged && (
                                          <>
                                            <figure>
                                              <img
                                                src={InfoIcon}
                                                alt="Info-Icon"
                                              />
                                            </figure>
                                            <p>
                                              You are not allowed to edit this
                                              info, our Recruiter Team will
                                              update your details please
                                              <a
                                                href="javascript:void()"
                                                onClick={() =>
                                                  togglesendmail("last name")
                                                }
                                              >
                                                {" "}
                                                click here
                                              </a>
                                            </p>
                                          </>
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus}
                                        value={userInfo.lastName}
                                        onChange={(e) => handlePOnChanges(e)}
                                      // onClick={togglesendmail}
                                      />
                                    </div>
                                    <span>
                                      {lName == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {lError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>Nick Name</Label>
                                    <Input
                                      type="text"
                                      name="nickName"
                                      placeholder="Nick Name"
                                      disabled={editStatus}
                                      value={userInfo.nickName}
                                      onChange={(e) => handlePOnChanges(e)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>Maiden Name</Label>
                                    <Input
                                      type="text"
                                      placeholder="Maiden Name"
                                      name="maidenName"
                                      disabled={editStatus}
                                      value={userInfo.maidenName}
                                      onChange={(e) => handlePOnChanges(e)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                            <Form
                              className={`form-block ${editStatus ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col sm="6" className="block">
                                  <FormGroup
                                  // className={`form-block ${
                                  //   ackStatus.isFirstAcknowledged
                                  //     ? "input-disable"
                                  //     : ""
                                  // }`}
                                  >
                                    <div className="help-tip">
                                      <Label>
                                        Mobile Phone
                                        <span style={{ color: "red" }}>
                                          *{" "}
                                          {ackStatus.isFirstAcknowledged && (
                                            <>
                                              <figure>
                                                <img
                                                  src={InfoIcon}
                                                  alt="Info-Icon"
                                                />
                                              </figure>
                                              <p>
                                                You are not allowed to edit this
                                                info, our Recruiter Team will
                                                update your details please
                                                <a
                                                  href="javascript:void()"
                                                  onClick={() =>
                                                    togglesendmail(
                                                      "mobile phone"
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  click here
                                                </a>
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </Label>
                                      <NumberFormat
                                        format="(###) ###-####"
                                        mask=""
                                        name="primaryPhoneNumber"
                                        placeholder="Mobile Phone"
                                        className="form-control"
                                        disabled
                                        value={
                                          primaryDetails.primaryPhoneNumber
                                        }
                                        onChange={(e) => handlePOnChanges(e)}
                                        onClick={toggle}
                                      />
                                    </div>
                                    <span>
                                      {phone == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {phoneError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup
                                  // className={`form-block ${
                                  //   ackStatus.isFirstAcknowledged
                                  //     ? "input-disable"
                                  //     : ""
                                  // }`}
                                  >
                                    <div className="help-tip">
                                      <Label>
                                        Email Address
                                        <span style={{ color: "red" }}>
                                          *{" "}
                                          {ackStatus.isFirstAcknowledged && (
                                            <>
                                              <figure>
                                                <img
                                                  src={InfoIcon}
                                                  alt="Info-Icon"
                                                />
                                              </figure>
                                              <p>
                                                You are not allowed to edit this
                                                info, our Recruiter Team will
                                                update your details please
                                                <a
                                                  href="javascript:void()"
                                                  onClick={() =>
                                                    togglesendmail("email")
                                                  }
                                                >
                                                  {" "}
                                                  click here
                                                </a>
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </Label>
                                      <Input
                                        type="text"
                                        name="primaryEmail"
                                        placeholder="Email Address"
                                        disabled
                                        value={primaryDetails.primaryEmail}
                                        onChange={(e) => handlePOnChanges(e)}
                                        onClick={toggle}
                                      />
                                    </div>
                                    <span>
                                      {email == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {emailError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col
                                  sm="6"
                                  className="inout-block certi-bttn block"
                                >
                                  <div className="help-tip">
                                    <FormGroup
                                      className={`form-block ${ackStatus.isFirstAcknowledged
                                        ? "input-disable"
                                        : ""
                                        }`}
                                    >
                                      <div
                                        className="multi-select-field"
                                        onClick={() => {
                                          toggleOpenCertification();
                                        }}
                                      >
                                        <Label>
                                          Certification
                                          <span style={{ color: "red" }}>
                                            *{" "}
                                            {ackStatus.isFirstAcknowledged && (
                                              <>
                                                <figure>
                                                  <img
                                                    src={InfoIcon}
                                                    alt="Info-Icon"
                                                  />
                                                </figure>
                                                <p>
                                                  You are not allowed to edit
                                                  this info, our Recruiter Team
                                                  will update your details
                                                  please
                                                  <a
                                                    href="javascript:void()"
                                                    onClick={() =>
                                                      togglesendmail(
                                                        "certification"
                                                      )
                                                    }
                                                  >
                                                    {" "}
                                                    click here
                                                  </a>
                                                </p>
                                              </>
                                            )}
                                          </span>
                                        </Label>

                                        {/* {userInfo && userInfo.isTNIUser ? ( */}
                                        <select
                                          name="state"
                                          className="form-control custom-dropdown"
                                          value={
                                            selectedCerts && selectedCerts[0]
                                              ? selectedCerts[0].value
                                              : ""
                                          }
                                          onChange={(e) => handleSelectCrt(e)}
                                          disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus}
                                        >
                                          <option value={""}>
                                            Select Any
                                          </option>
                                          {certifications.map(
                                            (item, index) => (
                                              <option
                                                value={item.value}
                                                key={index}
                                              >
                                                {item.value}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {/* // ) : (
                                        //   <ReactMultiSelectCheckboxes
                                        //     options={certifications}
                                        //     value={selectedCerts}
                                        //     onChange={(value, event) => {
                                        //       handleCertChange(value, event);
                                        //     }}
                                        //     isSearchable={true}
                                        //     placeholderButtonLabel="Select Any"
                                        //     onFocus={() => {
                                        //       handleCertFocus();
                                        //     }}
                                        //     menuIsOpen={
                                        //       dropdownStatus === true
                                        //         ? ""
                                        //         : certDropdown
                                        //     }
                                        //     onBlur={() => {
                                        //       handleCertBlur();
                                        //     }}
                                        //   />
                                        // )} */}

                                        <span>
                                          {cerError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {cerError1}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Col>
                                <Col
                                  sm="6"
                                  className="inout-block certi-bttn block"
                                >
                                  <div className="help-tip">
                                    <FormGroup
                                      className={`form-block ${ackStatus.isFirstAcknowledged
                                        ? "input-disable"
                                        : ""
                                        }`}
                                    >
                                      <div
                                        className="multi-select-field"
                                        onClick={() => {
                                          toggleOpenSpeciality();
                                        }}
                                      >
                                        <label>
                                          My Specialty is...
                                          <span style={{ color: "red" }}>
                                            *{" "}
                                            {ackStatus.isFirstAcknowledged && (
                                              <>
                                                <figure>
                                                  <img
                                                    src={InfoIcon}
                                                    alt="Info-Icon"
                                                  />
                                                </figure>
                                                <p>
                                                  You are not allowed to edit
                                                  this info, our Recruiter Team
                                                  will update your details
                                                  please
                                                  <a
                                                    href="javascript:void()"
                                                    onClick={() =>
                                                      togglesendmail(
                                                        "speciality"
                                                      )
                                                    }
                                                  >
                                                    {" "}
                                                    click here
                                                  </a>
                                                </p>
                                              </>
                                            )}
                                          </span>
                                        </label>
                                        {/* {userInfo && userInfo.isTNIUser ? ( */}
                                        <select
                                          name="state"
                                          className="form-control custom-dropdown"
                                          value={
                                            selectedSpecialities &&
                                              selectedSpecialities[0] &&
                                              selectedSpecialities[0].value
                                              ? selectedSpecialities[0].value
                                              : ""
                                          }
                                          onChange={(e) => handleSelectSpl(e)}
                                          disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus}
                                        >
                                          <option value={""}>
                                            Select Any
                                          </option>
                                          {items.length > 0 &&
                                            items.map((item, index) => (
                                              <option
                                                value={item.value}
                                                key={index}
                                              >
                                                {item.value}
                                              </option>
                                            ))}
                                        </select>
                                        {/* // ) : ( */}
                                        {/* //   <ReactMultiSelectCheckboxes
                                        //     options={items}
                                        //     value={selectedSpecialities}
                                        //     onChange={(value, event) => {
                                        //       handleChange(value, event);
                                        //     }}
                                        //     isSearchable={true}
                                        //     placeholderButtonLabel="Select Any"
                                        //     onFocus={() => {
                                        //       handleSpecialityFocus();
                                        //     }}
                                        //     menuIsOpen={
                                        //       dropdownStatus === true
                                        //         ? ""
                                        //         : specialityDropdown
                                        //     }
                                        //     onBlur={() => {
                                        //       handleSpecialityBlur();
                                        //     }}
                                        //   />
                                        // )} */}

                                        <span>
                                          {speError === true ? (
                                            <span
                                              className="error"
                                              style={{ color: "red" }}
                                            >
                                              {speError1}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Col>
                                <p className="px-3">Note : Please only select 1 specialty in which you have 1 full year of experience within the last 3 years.
                                  If you need to select additional specialties, please notify your recruiter.</p>
                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => {
                                      handlePreSubmit(e);
                                    }}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save{" "}
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>

                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion1("2")}
                            aria-expanded={collapse1 === "2"}
                          // isOpen={collapse1 === "2"}
                          >
                            Home Address
                            <div class="w-15 w-xs-100">
                              <figure
                                className="form-progress-icon"
                                id="Tooltip2"
                              >
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {profilePercentage1 &&
                                    profilePercentage1.finalPersonalStatus &&
                                    profilePercentage1.finalPersonalStatus[1] &&
                                    profilePercentage1.finalPersonalStatus[1]
                                      .homeAddress &&
                                    profilePercentage1.finalPersonalStatus[1]
                                      .homeAddress == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse1 === "2"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              <span>
                                {editStatus1 === true ? (
                                  <a
                                    href="javascript:void(0)"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButton1}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                ) : (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButton1}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                )}
                              </span>
                            </div>
                            <Form
                              className={`form-block emergency-continfo ${editStatus1 || ackStatus.isFirstAcknowledged
                                ? "input-disable"
                                : ""
                                }`}
                            >
                              <Row className="education-tab">
                                <h6 className="col-sm-12">
                                  Enter your primary place of residence.
                                </h6>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>
                                      Street Address
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      name="Street_Address"
                                      id="exampleSelect"
                                      placeholder=" Street Address"
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      value={
                                        userAddress &&
                                        userAddress.Street_Address
                                      }
                                      onChange={(e) => handleHOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {streetAddress == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {streetAddressError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>Apartment/Suite</Label>
                                    <Input
                                      type="text"
                                      name="Apartment"
                                      id="exampleSelect"
                                      placeholder="Apartment/Suite"
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      value={
                                        userAddress && userAddress.Apartment
                                      }
                                      onChange={(e) => handleHOnChanges(e)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>
                                      City
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      name="city"
                                      id="exampleSelect"
                                      placeholder="City"
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      value={userAddress && userAddress.city}
                                      onChange={(e) => handleHOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {city == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {cityError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>
                                      State/Province
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <select
                                      name="state"
                                      className="form-control custom-dropdown"
                                      value={userAddress && userAddress.state}
                                      onChange={(e) => handleHOnChanges(e)}
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      onClick={toggle}
                                    >
                                      <option value={""}>Select State</option>
                                      {states.map((item, index) => (
                                        <option value={item.code} key={index}>
                                          {item.value}
                                        </option>
                                      ))}
                                    </select>
                                    <span>
                                      {state == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {stateError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>
                                      Zip / Postal Code
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      name="zipcode"
                                      id="exampleSelect"
                                      placeholder="Zip / Postal Code *"
                                      // disabled={editStatus1}
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      value={userAddress && userAddress.zipcode}
                                      onChange={(e) => handleHOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {zip == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {zipError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <Label>County</Label>
                                    <Input
                                      type="text"
                                      name="country"
                                      placeholder="County"
                                      disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus1}
                                      value={userAddress && userAddress.country}
                                      onChange={(e) => handleHOnChanges(e)}
                                      onClick={toggle}
                                    />
                                  </FormGroup>
                                </Col>
                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => handleHomeSubmit(e)}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save{" "}
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion1("3")}
                            aria-expanded={collapse1 === "3"}
                          >
                            Emergency Contact
                            <div class="w-15 w-xs-100">
                              <figure className="form-progress-icon ">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {/* <img src={ProgressIcon} alt="Progress Icon" /> */}
                                  {profilePercentage1 &&
                                    profilePercentage1.finalPersonalStatus &&
                                    profilePercentage1.finalPersonalStatus[2] &&
                                    profilePercentage1.finalPersonalStatus[2]
                                      .emergency &&
                                    profilePercentage1.finalPersonalStatus[2]
                                      .emergency == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse1 === "3"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              <span>
                                {editStatus2 === true ? (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButton2}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                ) : (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButton2}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                )}
                              </span>
                            </div>
                            <Form
                              className={`form-block emergency-continfo ${editStatus2 ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col sm="4" className="block">
                                  <FormGroup>
                                    <Label>
                                      Full Name
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      name="contactName"
                                      id="exampleSelect"
                                      placeholder="Full Name"
                                      disabled={editStatus2}
                                      value={
                                        userEContact && userEContact.contactName
                                      }
                                      onChange={(e) => handleEOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {fullName == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {fullNameError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="4" className="block">
                                  <FormGroup>
                                    <Label>
                                      Mobile Phone
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <NumberFormat
                                      format="(###) ###-####"

                                      mask=""
                                      name="phoneNumber"
                                      placeholder="Mobile Phone "
                                      className="form-control"
                                      disabled={editStatus2}
                                      value={
                                        userEContact && userEContact.phoneNumber
                                      }
                                      onChange={(e) => handleEOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {mobileNumber == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {mobileNumberError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="4" className="block">
                                  <FormGroup>
                                    <Label>
                                      Relationship
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      name="relation"
                                      id="exampleSelect"
                                      placeholder="Relationship"
                                      disabled={editStatus2}
                                      value={
                                        userEContact && userEContact.relation
                                      }
                                      onChange={(e) => handleEOnChanges(e)}
                                      onClick={toggle}
                                    />
                                    <span>
                                      {relationship == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {relationshipError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => handleEmrSubmit(e)}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save{" "}
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        className="bttn-loader-icon"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion1("4")}
                            aria-expanded={collapse1 === "4"}
                          >
                            How did you hear about us?
                            <div class="w-15 w-xs-100">
                              <figure className="form-progress-icon ">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {profilePercentage1 &&
                                    profilePercentage1.finalPersonalStatus &&
                                    profilePercentage1.finalPersonalStatus[3] &&
                                    profilePercentage1.finalPersonalStatus[3]
                                      .referal &&
                                    profilePercentage1.finalPersonalStatus[3]
                                      .referal == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse1 === "4"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              {!ackStatus.isFirstAcknowledged && (
                                <span>
                                  {editStatus3 === true ? (
                                    <a
                                      href="javascript:void()"
                                      className="edit-btn"
                                    >
                                      <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>Edit</Tooltip>
                                        )}
                                        placement="top"
                                      >
                                        <img
                                          src={EditIcon}
                                          alt=""
                                          onClick={handleEditButton3}
                                        />
                                      </OverlayTrigger>
                                    </a>
                                  ) : (
                                    <a
                                      href="javascript:void()"
                                      className="edit-btn"
                                    >
                                      <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>Close</Tooltip>
                                        )}
                                        placement="top"
                                      >
                                        <img
                                          src={CloseIcon}
                                          alt=""
                                          onClick={handleEditButton3}
                                        />
                                      </OverlayTrigger>
                                    </a>
                                  )}
                                </span>
                              )}
                            </div>
                            <Form
                              className={`form-block  ${editStatus3 ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <div className="help-tip">
                                      <Label>
                                        How did you hear about us?
                                        <span style={{ color: "red" }}>
                                          *{" "}
                                          {ackStatus.isFirstAcknowledged && (
                                            <>
                                              <figure>
                                                <img
                                                  src={InfoIcon}
                                                  alt="Info-Icon"
                                                />
                                              </figure>
                                              <p>
                                                You are not allowed to edit this
                                                info, our Recruiter Team will
                                                update your details please
                                                <a
                                                  href="javascript:void()"
                                                  onClick={() =>
                                                    togglesendmail(
                                                      "referral info"
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  click here
                                                </a>
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </Label>
                                      <select
                                        name="referralSource"
                                        className="form-control custom-dropdown"
                                        value={
                                          userReferral &&
                                          userReferral.referralSource
                                        }
                                        onChange={(e) => handleROnChanges(e)}
                                        // disabled={editStatus3}
                                        disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus3}
                                        onClick={toggle}
                                      >
                                        <option value={""}>
                                          Select any
                                        </option>
                                        {referralList.map((item, idx) => (
                                          <option
                                            value={item.referralSource}
                                            key={idx}
                                          >
                                            {item.referralSource}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <span>
                                      {referralSource == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {referralSourceError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col sm="6" className="block">
                                  <FormGroup>
                                    <div className="help-tip">
                                      <Label>
                                        Did someone refer you?{" "}
                                        <span>
                                          {ackStatus.isFirstAcknowledged && (
                                            <>
                                              <figure>
                                                <img
                                                  src={InfoIcon}
                                                  alt="Info-Icon"
                                                />
                                              </figure>
                                              <p>
                                                You are not allowed to edit this
                                                info, our Recruiter Team will
                                                update your details please
                                                <a
                                                  href="javascript:void()"
                                                  onClick={() =>
                                                    togglesendmail(
                                                      "referral info"
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  click here
                                                </a>
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </Label>
                                      <Input
                                        type="text"
                                        name="referralBy"
                                        placeholder="Referred By"
                                        disabled={ackStatus.isFirstAcknowledged ? ackStatus.isFirstAcknowledged : editStatus3}
                                        value={
                                          userReferral &&
                                          userReferral.referralBy
                                        }
                                        onChange={(e) => handleROnChanges(e)}
                                      />
                                    </div>
                                    <p className="sub-label">
                                    Please enter “n/a” if not referred by Word of Mouth.
                                    </p>
                                  </FormGroup>
                                </Col>
                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => handleRefSubmit(e)}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save{" "}
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                      </Tab.Pane>

                      {/* Prefernces Block Starts Here  */}

                      <Tab.Pane eventKey="Preferences">
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion2("1")}
                            aria-expanded={collapse2 === "1"}
                          >
                            Work Preferences
                            <div class="w-15 w-xs-100">
                              <figure className="form-progress-icon ">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {/* <img src={ProgressIcon} alt="Progress Icon" /> */}
                                  {profilePercentage2 &&
                                    profilePercentage2.finalPreferancesStatus &&
                                    profilePercentage2
                                      .finalPreferancesStatus[0] &&
                                    profilePercentage2.finalPreferancesStatus[0]
                                      .work &&
                                    profilePercentage2.finalPreferancesStatus[0]
                                      .work == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse2 === "1"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              <span>
                                {editStatusPre1 === true ? (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButtonPre1}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                ) : (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButtonPre1}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                )}
                              </span>
                            </div>
                            <Form
                              className={`form-block   ${editStatusPre1 ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col sm="6" className="block">
                                  <FormGroup className="date-picker">
                                    <Label for="DateAvailable">
                                      Date Available
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <DatePicker
                                      selected={workPreferences1}
                                      placeholderText="MM/DD/YYYY"
                                      onChange={(date) =>
                                        setWorkPreferences1(date)
                                      }
                                      disabled={editStatusPre1}
                                      onInputClick={toggle}
                                    />
                                    <span>
                                      {date == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {dateError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col
                                  sm="6"
                                  className="block work-location position-list"
                                >
                                  <FormGroup>
                                    <Label
                                      for="Position Type"
                                      style={{
                                        width: "100%",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      Position Type
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    {positionTypes.map((answer, i) => {
                                      const index =
                                        workPreferences.length > 0
                                          ? workPreferences.findIndex(
                                            (x) => x == answer.value
                                          )
                                          : -1;
                                      return (
                                        <div key={i}>
                                          <div key={i}>
                                            <label
                                              htmlFor={answer.label}
                                              className="my-auto common-flex shift-check"
                                            >
                                              <input
                                                type="checkbox"
                                                name={answer.value}
                                                id={answer.label}
                                                className="mx-2"
                                                onChange={handlePClick}
                                                checked={
                                                  index != -1 ? true : false
                                                }
                                                disabled={editStatusPre1}
                                                onClick={toggle}
                                              />
                                              {answer.label}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {/* {editStatusPre1 == false ? (
                                      <Select
                                        className="select-field-grid"
                                        isDisabled={editStatusPre1}
                                        options={positionTypes}
                                        placeholder="Select Position Type"
                                        value={workPreferences}
                                        onChange={handlePreWorkPreOnChanges}
                                        isSearchable={true}
                                        isMulti
                                        onMenuOpen={toggle}
                                      />
                                    ) : (
                                      <Select
                                        className="select-field-grid"
                                        isDisabled={editStatusPre1}
                                        options={positionTypes}
                                        placeholder="Select Position Type"
                                        value={workPreferences}
                                        onChange={handlePreWorkPreOnChanges}
                                        isSearchable={true}
                                        isMulti
                                      />
                                    )} */}

                                    <span>
                                      {position == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {positionError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => handleWorkPreOnSubmit(e)}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion2("2")}
                            aria-expanded={collapse2 === "2"}
                          >
                            Shift Preferences
                            <div class="w-15 w-xs-100">
                              <figure className="form-progress-icon ">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {profilePercentage2 &&
                                    profilePercentage2.finalPreferancesStatus &&
                                    profilePercentage2
                                      .finalPreferancesStatus[1] &&
                                    profilePercentage2.finalPreferancesStatus[1]
                                      .shift &&
                                    profilePercentage2.finalPreferancesStatus[1]
                                      .shift == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse2 === "2"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              <span>
                                {editStatusPre2 === true ? (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButtonPre2}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                ) : (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButtonPre2}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                )}
                              </span>
                            </div>
                            <Form
                              className={`form-block emergency-continfo ${editStatusPre2 ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col md="6" className="block shift-block">
                                  <FormGroup>
                                    <Label for="MiddleName">
                                      Shifts
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    {shifts.map((answer, i) => {
                                      const index =
                                        shiftPreferences1.length > 0
                                          ? shiftPreferences1.findIndex(
                                            (x) => x == answer.label
                                          )
                                          : -1;
                                      return (
                                        <div className="shift-time" key={i}>
                                          <div className={"MiddleName"} key={i}>
                                            <label
                                              htmlFor={answer.label}
                                              className="my-auto mx-3 common-flex shift-check"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <input
                                                type="checkbox"
                                                name={answer.label}
                                                id={answer.label}
                                                className="mx-2"
                                                onChange={handleClick}
                                                checked={
                                                  index != -1 ? true : false
                                                }
                                                disabled={editStatusPre2}
                                                onClick={toggle}
                                              />
                                              {answer.label}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <span>
                                      {shiftTime == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {shiftError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md="6" className="block">
                                  <FormGroup>
                                    <Label for="MiddleName">
                                      Distance willing to Travel from Tax Home
                                      (miles)
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <select
                                      name="distance"
                                      className="form-control custom-dropdown"
                                      value={
                                        shiftPreferences &&
                                        shiftPreferences.distance
                                      }
                                      onChange={(e) =>
                                        handlePreShiftOnChanges(e)
                                      }
                                      disabled={editStatusPre2}
                                      onClick={toggle}
                                    >
                                      <option value={""}>
                                        Select Distance
                                      </option>
                                      {distance.map((item, i) => (
                                        <option value={item.label} key={i}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                    <span>
                                      {shiftDistance == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {distanceError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </FormGroup>
                                </Col>

                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) => handleShiftOnSubmit(e)}
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion2("3")}
                            aria-expanded={collapse2 === "3"}
                          >
                            Work Locations
                          </Button>
                          <Collapse
                            isOpen={collapse2 === "3"}
                            className="accordian-cont-block"
                          >
                            <div className="edit-form-grid">
                              <span>
                                {" "}
                                {editStatusPre3 === true ? (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={handleEditButtonPre3}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                ) : (
                                  <a
                                    href="javascript:void()"
                                    className="edit-btn"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Close</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      <img
                                        src={CloseIcon}
                                        alt=""
                                        onClick={handleEditButtonPre3}
                                      />
                                    </OverlayTrigger>
                                  </a>
                                )}
                              </span>
                            </div>
                            <Form
                              className={`form-block emergency-continfo ${editStatusPre3 ? "input-disable" : ""
                                }`}
                            >
                              <Row>
                                <Col
                                  xl="12"
                                  sm="12"
                                  className="block work-location"
                                >
                                  <FormGroup>
                                    <div className="dropdown-container">
                                      <label>
                                        <input
                                          id="selectAll"
                                          name="selectAll"
                                          type="checkbox"
                                          onChange={handleSelectAll}
                                          checked={isCheckAll}
                                          disabled={editStatusPre3}
                                        />
                                        <span>Select All</span>
                                      </label>
                                      {states.length > 0 &&
                                        states.map((item, i) => {
                                          const index =
                                            isWCheck.length > 0
                                              ? isWCheck.findIndex(
                                                (x) => x == item.code
                                              )
                                              : -1;
                                          return (
                                            <>
                                              <label>
                                                <input
                                                  id={item.code}
                                                  name={item.value}
                                                  type="checkbox"
                                                  onChange={handleWClick}
                                                  checked={
                                                    index != -1 ? true : false
                                                  }
                                                  disabled={editStatusPre3}
                                                />
                                                <span>{item.value}</span>
                                              </label>
                                            </>
                                          );
                                        })}
                                      {/* {editStatusPre3 == false ? (
                                        <Select
                                          className="select-field-grid"
                                          isDisabled={editStatusPre3}
                                          options={states1}
                                          placeholder="Select Work Locations"
                                          value={worklocation}
                                          onChange={handlePreWOrkOnChanges}
                                          isSearchable={true}
                                          isMulti
                                        />
                                      ) : (
                                        <Select
                                          className="select-field-grid"
                                          isDisabled={editStatusPre3}
                                          options={states1}
                                          placeholder="Select Work Locations"
                                          value={worklocation}
                                          onChange={handlePreWOrkOnChanges}
                                          isSearchable={true}
                                          isMulti
                                        />
                                      )} */}
                                    </div>
                                  </FormGroup>
                                </Col>

                                <div class="form-bttn">
                                  <button
                                    value="Submit"
                                    disabled={button}
                                    onClick={(e) =>
                                      handleWorkLocationOnSubmit(e)
                                    }
                                    style={{ fontFamily: "Figtree Black" }}
                                  >
                                    Save{" "}
                                    {subLoader ? (
                                      <img
                                        src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                        alt="No Image"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </Row>
                            </Form>
                          </Collapse>
                        </div>
                      </Tab.Pane>

                      {/*  Experience Section Starts Here  */}

                      <Tab.Pane eventKey="Experience">
                        <div className="accordian-block">
                          <EducationSection
                            status={profilePercentage3}
                            ackStatus={ackStatus}
                            setSignModal={setSignModal}
                            getProfilePersentage={getProfilePersentage}
                            childToParent={handleAccordion3}
                            collapse={collapse3}
                            handleEditButtonEdu1={handleEditButtonEdu1}
                            setEditStatusEdu1={setEditStatusEdu1}
                            editStatusEdu1={editStatusEdu1}
                            educationHistory={educationHistory}
                            toggle={toggle}
                          />
                        </div>
                        <div className="accordian-block">
                          <WorkSection
                            status={profilePercentage3}
                            ackStatus={ackStatus}
                            setSignModal={setSignModal}
                            getProfilePersentage={getProfilePersentage}
                            HandleAccordion2={handleAccordion3}
                            collapse={collapse3}
                            handleEditButtonEdu2={handleEditButtonEdu2}
                            setEditStatusEdu2={setEditStatusEdu2}
                            editStatusEdu2={editStatusEdu2}
                            toggle={toggle}
                          />
                        </div>
                        <div className="accordian-block">
                          <ReferenceSection
                            status={profilePercentage3}
                            ackStatus={ackStatus}
                            setSignModal={setSignModal}
                            getProfilePersentage={getProfilePersentage}
                            HandleAccordion={handleAccordion3}
                            collapse={collapse3}
                            handleEditButtonEdu3={handleEditButtonEdu3}
                            setEditStatusEdu3={setEditStatusEdu3}
                            editStatusEdu3={editStatusEdu3}
                            toggle={toggle}
                          />
                        </div>
                        <div className="accordian-block">
                          <ResumeSection
                            HandleAccordion={handleAccordion3}
                            collapse={collapse3}
                            handleEditButton={handleEditButton}
                            editStatus={editStatus}
                            resume={resume}
                          />
                        </div>
                      </Tab.Pane>
                      {/*  Acknowledgement Section Starts Here  */}
                      <Tab.Pane eventKey="Acknowledgement">
                        <div className="accordian-block">
                          <Button
                            color="link"
                            onClick={() => handleAccordion4("1")}
                            aria-expanded={collapse4 === "1"}
                          >
                            {profilePercentage4.finalAcknowledgementCount == 100
                              ? "Application"
                              : "Acknowledgement"}
                            <div class="w-15 w-xs-100">
                              <figure className="form-progress-icon ">
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Incomplete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {profilePercentage4 &&
                                    profilePercentage4.finalAcknowledgementStatus &&
                                    profilePercentage4
                                      .finalAcknowledgementStatus[0] &&
                                    profilePercentage4
                                      .finalAcknowledgementStatus[0]
                                      .acknowledgement &&
                                    profilePercentage4
                                      .finalAcknowledgementStatus[0]
                                      .acknowledgement == "Completed" ? (
                                    <img
                                      src={CompletedIcon}
                                      alt="Progress Icon"
                                    />
                                  ) : (
                                    <img
                                      src={ProgressIcon}
                                      alt="Progress Icon"
                                    />
                                  )}
                                </OverlayTrigger>
                              </figure>
                            </div>
                          </Button>
                          <Collapse
                            isOpen={collapse4 === "1"}
                            className="accordian-cont-block application-sign-block"
                          >
                            <Modal
                              isOpen={signModal}
                              toggle={signToggle}
                              className="modal-md sign-save-popup"
                              id="Tn-popup"
                              style={{ color: "#25408f", fontWeight: "500" }}
                            >
                              <div className="main-page">
                                <ModalHeader
                                  toggle={signToggle}
                                  className="text-center pt-4 pb-1"
                                  style={{ color: "#25408f" }}
                                ></ModalHeader>
                                <ModalBody className="p-0">
                                  <h3>Employment Eligibility</h3>
                                  <em>
                                    Answer the following questions about your
                                    work eligibility.
                                  </em>
                                  <h3>
                                    Are you legally authorized to work in the
                                    United States?{" "}
                                  </h3>

                                  <div className="radio-bttn-sec">
                                    <FormGroup>
                                      <div
                                        className="btn-group"
                                        data-toggle="buttons"
                                      >
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching1"
                                            name="isLegal"
                                            value="Yes"
                                            checked={
                                              terms.isLegal == "Yes"
                                                ? true
                                                : false
                                            }
                                            onChange={handleTerms}
                                          />
                                          <span>Yes</span>
                                        </label>
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching0"
                                            name="isLegal"
                                            value="No"
                                            checked={
                                              terms.isLegal == "No"
                                                ? true
                                                : false
                                            }
                                            onChange={handleTerms}
                                          />
                                          <span> No</span>
                                        </label>
                                      </div>
                                    </FormGroup>
                                    {termsErr.isLegalErr == true ? (
                                      <span
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        Please select any one.
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <h3>
                                    Are you aware of any circumstances, which
                                    may result in a malpractice claim or suit
                                    being made or brought against you?{" "}
                                  </h3>

                                  <div className="radio-bttn-sec">
                                    <FormGroup>
                                      <div
                                        className="btn-group"
                                        data-toggle="buttons"
                                      >
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching1"
                                            name="isAware"
                                            value="Yes"
                                            checked={
                                              terms.isAware == "Yes"
                                                ? true
                                                : false
                                            }
                                            onChange={handleTerms}
                                          // checked={item && item.Teaching_Facility == "Yes"}
                                          />
                                          <span>Yes</span>
                                        </label>
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching0"
                                            name="isAware"
                                            value="No"
                                            onChange={handleTerms}
                                            checked={
                                              terms.isAware == "No"
                                                ? true
                                                : false
                                            }
                                          // checked={item.Teaching_Facility == "Yes"}
                                          />

                                          <span> No</span>
                                        </label>
                                      </div>
                                    </FormGroup>
                                    {termsErr.isAwareErr == true ? (
                                      <span
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        Please select any one.
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <h3>
                                    Has any malpractice claim or suit ever been
                                    brought against you?{" "}
                                  </h3>

                                  <div className="radio-bttn-sec">
                                    <FormGroup>
                                      <div
                                        className="btn-group"
                                        data-toggle="buttons"
                                      >
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching1"
                                            name="isMalpracticed"
                                            value="Yes"
                                            onChange={handleTerms}
                                            // checked={item && item.Teaching_Facility == "Yes"}
                                            checked={
                                              terms.isMalpracticed == "Yes"
                                                ? true
                                                : false
                                            }
                                          />
                                          <span>Yes</span>
                                        </label>
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching0"
                                            name="isMalpracticed"
                                            value="No"
                                            onChange={handleTerms}
                                            // checked={item.Teaching_Facility == "Yes"}
                                            checked={
                                              terms.isMalpracticed == "No"
                                                ? true
                                                : false
                                            }
                                          />

                                          <span> No</span>
                                        </label>
                                      </div>
                                    </FormGroup>
                                    {termsErr.isMalpracticedErr == true ? (
                                      <span
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        Please select any one.
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <h3>
                                    Have you ever been the subject of a
                                    reprimand or disciplinary action or refused
                                    employment or admission to a professional
                                    society or had professional privileges (in
                                    any jurisdiction in which you are licensed)
                                    investigated, suspended or revoked by any
                                    court or administrative agency or ever been
                                    the subject of any ethics investigation at
                                    local, state or national level?
                                  </h3>

                                  <div className="radio-bttn-sec">
                                    <FormGroup>
                                      <div
                                        className="btn-group"
                                        data-toggle="buttons"
                                      >
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching1"
                                            name="isInvestigated"
                                            value="Yes"
                                            onChange={handleTerms}
                                            // checked={item && item.Teaching_Facility == "Yes"}
                                            checked={
                                              terms.isInvestigated == "Yes"
                                                ? true
                                                : false
                                            }
                                          />
                                          <span>Yes</span>
                                        </label>
                                        <label>
                                          <Input
                                            type="radio"
                                            id="employerTeaching0"
                                            name="isInvestigated"
                                            value="No"
                                            onChange={handleTerms}
                                            // checked={item.Teaching_Facility == "Yes"}
                                            checked={
                                              terms.isInvestigated == "No"
                                                ? true
                                                : false
                                            }
                                          />

                                          <span> No</span>
                                        </label>
                                      </div>
                                    </FormGroup>
                                    {termsErr.isInvestigatedErr == true ? (
                                      <span
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        Please select any one.
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <h3>
                                    <h3>
                                      Has your professional license or
                                      certification ever been investigated or
                                      suspended?
                                    </h3>

                                    <div className="radio-bttn-sec">
                                      <FormGroup>
                                        <div
                                          className="btn-group"
                                          data-toggle="buttons"
                                        >
                                          <label>
                                            <Input
                                              type="radio"
                                              id="employerTeaching1"
                                              name="isSuspended"
                                              value="Yes"
                                              onChange={handleTerms}
                                              // checked={item && item.Teaching_Facility == "Yes"}
                                              checked={
                                                terms.isSuspended == "Yes"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span>Yes</span>
                                          </label>
                                          <label>
                                            <Input
                                              type="radio"
                                              id="employerTeaching0"
                                              name="isSuspended"
                                              value="No"
                                              onChange={handleTerms}
                                              // checked={item.Teaching_Facility == "Yes"}
                                              checked={
                                                terms.isSuspended == "No"
                                                  ? true
                                                  : false
                                              }
                                            />

                                            <span> No</span>
                                          </label>
                                        </div>
                                      </FormGroup>
                                      {termsErr.isSuspendedErr == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          Please select any one.
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </h3>

                                  <h3>Acknowledgement</h3>
                                  <div className="check-sec">
                                    <input
                                      type="checkbox"
                                      name="termsChecked"
                                      className="mx-2"
                                      onChange={handleTerms}
                                      checked={terms.termsChecked}
                                    />

                                    <p>
                                      {" "}
                                      Yes, I have read the acknowledgement
                                      statement{" "}
                                      <a
                                        href="javascript:void()"
                                        onClick={handleReadMore}
                                      >
                                        Click Here
                                      </a>
                                    </p>
                                  </div>
                                  {terms.readMore && (
                                    <div
                                      className="readmore-content-box"
                                      dangerouslySetInnerHTML={{
                                        __html: ackStatus.ackText,
                                      }}
                                    />
                                  )}
                                  {termsErr.isTermsErr == true ? (
                                    <span
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      Please check terms
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <div class="form-bttn">
                                    <h3>Your Signature</h3>
                                    <Form className="inner-block">
                                      <SignaturePad
                                        ref={sigCanvas}
                                        canvasProps={{
                                          className: "signatureCanvas",
                                        }}
                                      />
                                      {termsErr.sig == true ? (
                                        <span
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          Please draw your signature..
                                        </span>
                                      ) : (
                                        ""
                                      )}

                                      <div className="form-bttn">
                                        <form className="inner-block">
                                          <div className="sign-bttn-sec row justify-content-between">
                                            <div className="btn-left">
                                              <div className="btn-left">
                                                <button
                                                  onClick={(e) =>
                                                    handleSubmit(e)
                                                  }
                                                  className="blue-bttn"
                                                  disabled={saveButton}
                                                  style={{ fontFamily: "Figtree Black" }}
                                                >
                                                  Submit{" "}
                                                  {subLoader ? (
                                                    <img
                                                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                                      alt="No Image"
                                                      style={{
                                                        width: 40,
                                                        height: 40,
                                                      }}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </button>
                                              </div>
                                            </div>
                                            <div className="btn-right">
                                              <button
                                                onClick={(e) =>
                                                  handleSignSave(e)
                                                }
                                                className="blue-bttn"
                                                disabled={submitButton}
                                                style={{ fontFamily: "Figtree Black" }}
                                              >
                                                Save{" "}
                                              </button>
                                              <button
                                                onClick={(e) =>
                                                  handleSignClear(e)
                                                }
                                                className="clear-bttn"
                                                style={{ fontFamily: "Figtree Black" }}
                                              >
                                                Clear
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>

                                      {/* <div className="sign-bttn-sec">
                                        <button
                                          onClick={(e) => handleSignSave(e)}
                                          className="blue-bttn"
                                        >
                                          Save{" "}
                                        </button>
                                        <button
                                          onClick={(e) => handleSignClear(e)}
                                          className="clear-bttn"
                                        >
                                          Clear
                                        </button>
                                      </div>

                                      <div className="sign-bttn-sec">
                                        <button
                                          onClick={(e) => handleSubmit(e)}
                                          className="blue-bttn"
                                          disabled={saveButton}
                                        >
                                          Submit{" "}
                                          {subLoader ? (
                                            <img
                                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                              alt="No Image"
                                              style={{ width: 40, height: 40 }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </button>
                                      </div> */}
                                    </Form>
                                  </div>
                                </ModalBody>
                              </div>
                            </Modal>

                            {profilePercentage4.finalAcknowledgementCount ==
                              100 ? (
                              <div className="sign-view-bttn">
                                <a
                                  class="blue-bttn"
                                  href={ackStatus.application}
                                  target="_blank"
                                  style={{ fontFamily: "Figtree Black" }}
                                >
                                  {" "}
                                  View Application
                                </a>
                              </div>
                            ) : (
                              <Form>
                                <Row>
                                  <div class="form-bttn">
                                    <FormGroup>
                                      {signImageURL ? (
                                        <img
                                          src={signImageURL}
                                          alt="my signature"
                                          style={{
                                            display: "block",
                                            margin: "0 auto",
                                            border: "1px solid black",
                                            width: "150px",
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {profilePercentage < 75 && (
                                        <p>
                                          Please fill out all other sections
                                          first <br /> before proceeding to
                                          Acknowledgement
                                        </p>
                                      )}

                                      <button
                                        onClick={(e) => signToggle(e)}
                                        disabled={profilePercentage < 75}
                                        style={{ fontFamily: "Figtree Black" }}
                                      >
                                        Sign Here
                                      </button>
                                      <br />
                                      <br />

                                      {profilePercentage < 75 && (
                                        <div className="tab-content">
                                          <h2 className="doc-title">
                                            Please fill out all other actions
                                            first before proceeding to
                                            Acknowledge.
                                          </h2>
                                        </div>
                                      )}
                                    </FormGroup>
                                  </div>
                                </Row>
                              </Form>
                            )}
                          </Collapse>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </section>

            <></>
          </Container>
        </div>
      </>
    );
  } else {
    window.location.href = "/";
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading, error, userInfo, user } = auth;
  return { loading, error, userInfo, user };
};

export default connect(mapStateToProps, {
  getUserInfo,
})(withRouter(EditProfile));
