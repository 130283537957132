import React from "react";
import {

  Modal,
  ModalHeader,
  ModalBody,

  CardBody,
} from "reactstrap";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AppConstants from "../AppConstants";
import { IoIosCloseCircle } from "react-icons/io";


class ExitPopUp extends React.Component {
  /* add class function */
  constructor(props) {
    super(props);
    this.state = {};
    // this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.handleCancel()
    this.props.handleExitPopup();
  }
  // handleCancel(e) {

  // }
  render() {
    return (
      <Modal
        isOpen={this.props.popup}
        // toggle={this.props.handleExitPopup}
        className="modal-lg contact-rect recruiter-modal request-job-sec-modal submit-form exit-popup"
        id="Tn-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ color: "#25408f", fontWeight: "500" }}
      >
        <ModalHeader
          // toggle={this.handleCancel}
          className="text-center pt-4 pb-1"
          style={{ color: "#25408f" }}
        ></ModalHeader>
        <ModalBody className="p-0">
          <div className="apply-form registr-info main-page">
            <Card
              className="w-100 p-0 m-0 md-pop pb-5"
              style={{ borderRadius: "0px 0px 25px 25px" }}
            >
              <CardBody>
                <h1>Best Travel Nurse Agency</h1>
                <ul className="row popup-grid justify-content-center">
                  <li className="item1">
                    <img
                      // src={exitpopupLogo1}
                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/winner2024.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                  <li className="item2">
                    <img
                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/top-rated-2024.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                  <li className="item3">
                    <img

                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/exitpopupLogo6.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                  <li className="item4">
                    <img

                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/exitpopupLogo8.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                  <li className="item5">
                    <img

                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/exitpopupLogo5.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                  <li className="item6">
                    <img

                      src={`${AppConstants.Bucket_URL}/exit-popup-logo/great-recruiter2025.png`}
                      alt="exitpopupLogo"
                    />
                  </li>
                </ul>
                <div className="bttn">
                  <a
                    className="popup-lm"
                    href="https://travelnstaging.wpengine.com/about-us/#certificationblk"
                  >
                    See more&nbsp;
                    <i className="arrow right"></i>
                    <i className="arrow right second"></i>
                  </a>
                </div>
                <span class="sun-rays-img"></span>
                {/* <Form className="inner-block" onSubmit={this.handleSubmit}>
                
                  <Button
                    value="Submit"
                    className="btn-reg"
                  
                    style={{ backgroundColor: "#334ca4" }}
                  >
                    ×
                  </Button>
                </Form> */}

                <div className="inner-block" onClick={this.handleSubmit}>
                  <IoIosCloseCircle type="button" style={{ backgroundColor: "#fff", color: "#c3cddb", fontSize: "35px", margin: "15px", cursor: "pointer" }} />
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ExitPopUp;
