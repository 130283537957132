import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import images from "./images";
import AppConstants from '../AppConstants';
import axios from "axios";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
class Blogblock extends React.Component {
  constructor(){
    super();
    this.state={
      blogs:[]
    }
  }
  componentDidMount(){
    // axios.get(AppConstants.CMS_API+"/blogs/getBlogs").then(res => {
      axios.get(AppConstants.CMS_API+"/blogs/allBlogsfromJsonFile").then(res => {
        return res.data;
      }).then(data => {
        this.setState({blogs:data.data})
      })
  }
  viewBlog(id,e){
    var data1= {}
    axios.get('https://ipapi.co/json').then(res => {
      return res.data;
      }).then(data => {
        data1={
          viewerDetails : data,
          blog_id:id
        }
        axios.post(AppConstants.CMS_API+"/blogs/blogView",data1).then(res => {
          return res.data;
        }).then(data4 => {
          if(data4.Status){
            console.log(data4.Status)
          }
        })
      })
  }
  render() {
    return (
      <div className="news-block pad-tb text-center">
        <Container>
          <div className="title">{AppConstants.Blog}</div>
          <p>{AppConstants.Sub_Blog}</p>
          <Row className="inner-content justify-content-center">
            {this.state.blogs.map((item) => (
              <Col xs="6" className="block">
                <div className="block-box">
                  <LazyLoad>
                    <figure className="pic image-fill">
                      {item.images ?  <img
                        src={item.images[0]}
                        alt={item.title+ " - Travel Nurses Inc"}
                      /> : ""}
                      <span style={{ backgroundColor: item.color}} className="category">{item.category_name}</span>
                    </figure>
                  </LazyLoad>
                  <div className="text">
                    <p className="date">{item.created_date}</p>
                    <h4>{item.title}</h4>
                    <div dangerouslySetInnerHTML={{__html:item.content.substring(0, 100) + '  ...'}} />
                      <Link to={{
                                pathname: "/blog-details/" + item.slug,
                                state: { blog_id: item._id },
                              }} 
                        className="read-more" onClick={this.viewBlog.bind(this,item._id)}>
                          Read More
                      </Link>
                    </div>
                  </div>
              </Col>
            ))}
          </Row>
          {/* <Link to="/blog" className="button">View More</Link> */}
          <a href= {AppConstants.Word_Press_API +"/blog"} className="button">View More</a>
        </Container>
      </div>
    );
  }
}
export default Blogblock;
