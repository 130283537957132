import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Subscribeblock extends React.Component {
  render() {
    return (
      <div className="subscribe-block pad-tb text-center">
        <Container>
          <div className="title">
            Create a Profile to Unlock All Our Features
          </div>
          <p>and get updates about your job in your dream location</p>
          <div className="inner-content justify-content-center">
            <form class="form-block" action="/action_page.php">
              <input
                type="text"
                placeholder="Enter Your Email Here"
                name="search"
              />
              <button type="submit" className="button">
                Create
              </button>
            </form>
          </div>
        </Container>
      </div>
    );
  }
}
export default Subscribeblock;
