import React from "react";
import { Container } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { JSONLD, Generic } from "react-structured-data";
class PrivacyPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      gold_seal: "",
      gold_seal_content: "",
      policy: "",
      title: "",
      data1: "",
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      altImgName: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    axios
      // .get(AppConstants.CMS_API + "/policy/allPolicies")
      .get(AppConstants.CMS_API + "/policy/allPoliciesfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        console.log(data.Status, data.data[0]);
        if (data.Status == "Success" && data.data[0]) {
          that.setState({
            data1: data.data[0],
            title: data.data[0].title,
            gold_seal: data.data[0].goldSealImage,
            gold_seal_content: data.data[0].goldSealContent,
            policy: data.data[0].policyContent,
            metaTitle: data.data[0].metaTitle,
            metaDescription: data.data[0].metaDescription,
            metaTags: data.data[0].metaTags,
            ogTitle: data.data[0].ogTitle,
            ogDescription: data.data[0].ogDescription,
            altImgName: data.data[0].altImgName,
          });
        } else {
          console.log("invalid data");
        }
      });
  }
  render() {
    return (
      <div className="privacy-policy">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Privacy Policy"
            schema={{
              name: this.state.metaTitle,
              description: this.state.metaDescription,
              contentReferenceTime: new Date(),
            }}
          ></Generic>
        </JSONLD>
        <Helmet>
          <title>{AppConstants.Privacy}</title>
          <meta name="title" content={this.state.metaTitle} />
          <meta name="description" content={this.state.metaDescription} />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={AppConstants.Base_Url + "/privacy-policy"}
          />
          <meta property="og:title" content={this.state.ogTitle} />
          <meta property="og:description" content={this.state.ogDescription} />
          <meta property="og:image" content={this.state.gold_seal} />

          <meta property="twitter:card" content={this.state.gold_seal} />
          <meta
            property="twitter:url"
            content={AppConstants.Base_Url + "/privacy-policy"}
          />
          <meta property="twitter:title" content={this.state.metaTitle} />
          <meta
            property="twitter:description"
            content={this.state.metaDescription}
          />
          <meta property="twitter:image" content={this.state.gold_seal} />
        </Helmet>

        <div className="inner-block pad-tb">
          <Container>
            <h1 class="title">{AppConstants.Privacy}</h1>
            <p className="text-center">{AppConstants.Sub_Privacy}</p>
            {this.state.data1 ? (
              <div className="content-area">
                <div className="approval-block">
                  {this.state.gold_seal ? (
                    <figure className="pic sealpic-blk image-auto">
                      <img
                        src={this.state.gold_seal}
                        alt={this.state.altImgName}
                      />
                    </figure>
                  ) : (
                    ""
                  )}
                  <div className="text-sec">
                    <h4>{this.state.title ? this.state.title : ""}</h4>
                    <p>
                      {this.state.gold_seal_content
                        ? this.state.gold_seal_content
                        : ""}
                    </p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="policy-content">
                  {this.state.policy ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: this.state.policy }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <h3 className="text-center">
                Privacy Policy content is not available.
              </h3>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
