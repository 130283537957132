import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { NotificationManager } from "react-notifications";
import ReactDOM from "react-dom";
import AppConstants from "../AppConstants";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

class ForgotPassword extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      phone: "",
      phoneError: false,
      errors: [],
      isLoading: "",
      errMsg: "",
      button_disable: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /* add class function */
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  handleInputChange = (values) => {
    this.setState({
      phone: values.value,
    });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    let filter =
      /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    if (filter.test(this.state.phone)) {
      if (this.state.phone.length < 16) {
        formIsValid = true;
      } else {
        errors["phone"] = "Please enter your valid phone number.";
        formIsValid = false;
      }
    } else {
      errors["phone"] = "Please enter your valid phone number.";
      formIsValid = false;
    }

    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }

    this.setState({
      errors: errors,
      phoneError: true,
    });
    return formIsValid;
  }
  handleSubmit() {
    if (this.handleValidation()) {
      this.setState({ isLoading: 1 });
      const formData = {
        phoneNumber: this.state.phone,
      };
      axios
        .post(AppConstants.API + "/nurse/nurseForgotpassword", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({ button_disable: true, isLoading: false });
            localStorage.setItem("userID", res.data.id);
            NotificationManager.success(res.data.msg, "", 3000);
            this.props.history.push("/otp");
          } else {
            this.setState({ button_disable: false, isLoading: false });
            NotificationManager.error(res.data.msg, "", 3000);
          }
        });
    }
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className="login-page main-page p-tb-50 bg-grey">
        <Container>
          <div className="login-grid">
            <div className="inner-block">
              <Row>
                <Col xs="6" className="block left-block">
                  <figure className="pic image-fill">

                    <img

                      src={`${AppConstants.Bucket_URL}/login-img.jpg`}
                      alt={
                        "Forgot Password - Travel Nurses Inc"
                      }
                    />

                  </figure>
                </Col>
                <Col xs="6" className="block right-block">
                  <div className="form-sec text-center">
                    <div className="title">Forgot Password</div>
                    <Form className="form-block" onSubmit={this.handleSubmit}>
                      <FormGroup>
                        <Label>Phone Number</Label>
                        <NumberFormat
                          format="(###) ###-####"
                          mask=""
                          ref="phone"
                          name="phone"
                          id="Phone"
                          placeholder="Phone Number"
                          onValueChange={this.handleInputChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={this.state.phone}
                          className="form-control"
                        />
                        <div>
                          {this.state.phoneError ? (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              {this.state.errors["phone"]}
                            </span>
                          ) : null}
                        </div>
                      </FormGroup>
                      <div>
                        <Button
                          className="button"
                          onClick={this.handleSubmit}
                          disabled={this.state.button_disable}
                        >
                          Submit
                          {this.state.isLoading ? (
                            <img
                              src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                              alt="No Image - Travel Nurses Inc"
                              style={{ width: 40, height: 40 }}
                            />
                          ) : (
                            ""
                          )}
                        </Button>
                      </div>
                      <Link className="forget-psw" to="/login">
                        <FiArrowLeft /> <span>Back to Login</span>
                      </Link>
                    </Form>
                    {this.state.errMsg}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {})(ForgotPassword);
