import React from "react";
 
import RecruiterTag from "../images/recruiter-tag.png";
import { Container, Row, Col, Input } from "reactstrap";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import MessagePopUp from "./message_popup";
import { Helmet } from "react-helmet";
import { JSONLD, Generic } from "react-structured-data";
import FadeIn from "react-fade-in";
class Team extends React.Component {
  constructor() {
    super();
    this.state = {
      recruiters: [],
      modal: "",
      currentPage: 1,
      pageSizes: [4, 8, 10, 20],
      selectedPageSize: 4,
      selectedOrderOption: "",
      searchText: "",
      phoneNumb: "",
      team: "select",
    };
    this.handleModal = this.handleModal.bind(this);
  }
  handleMessage(numb, i) {
    this.setState({ modal: true, phoneNumb: numb });
  }
  handleModal() {
    this.setState({ modal: !this.state.modal });
  }
  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    axios
      .get(AppConstants.API + "/users/getAllUsersFrontend/" + e.target.value)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ recruiters: data.data });
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(AppConstants.API + "/users/getAllUsersFrontend/" + this.state.team)
      // .get(AppConstants.API + "/users/getAllActiveUsers")
      // .get(AppConstants.API + "/users/getAllActiveUsersfromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ recruiters: data.data });
      });
  }

  updateResourcesList(
    currentPage,
    selectedPageSize,
    selectedOrderOption,
    searchText
  ) {
    if (searchText && searchText !== "") {
      searchText = searchText;
    } else if (this.state.searchText && this.state.searchText !== "") {
      searchText = this.state.searchText;
    } else {
      searchText = "";
    }
    let tablefilterdata = {
      page: currentPage,
      sizeperpage: selectedPageSize,
      sortname: selectedOrderOption,
      serchtext: searchText,
      defaultSearchText: searchText,
    };
    axios
      .post(AppConstants.API + "/users/getRecruiters", tablefilterdata)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({
          totalPage: data.totalPage,
          recruiters: data.data,
          selectedItems: [],
          totalItemCount: data.total_records,
          isLoading: true,
        });
      });
  }
  render() {
    return (
      <div className="team-block">
        <FadeIn>
          <JSONLD>
            <Generic
              type="webpage"
              jsonldtype="Recruiters"
              schema={{
                name: "Top Travel Nursing Recruiters",
                description:
                  "Recruiter - Choose From Thousands Of Travel Nurse Jobs Throughout The US. Best Paying Travel Nursing Agency Jobs in the US.",
                contentReferenceTime: new Date(),
              }}
            ></Generic>
          </JSONLD>
          <Helmet>
            <title>{AppConstants.Our_Specialists}</title>
            <meta name="title" content={AppConstants.Our_Specialists} />
            <meta
              name="description"
              content="Recruiter - Choose From Thousands Of Travel Nurse Jobs Throughout The US. Best Paying Travel Nursing Agency Jobs in the US."
            />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={AppConstants.Base_Url + "/team"} />
            <meta property="og:title" content={AppConstants.Our_Specialists} />
            <meta
              property="og:description"
              content="Recruiter - Choose From Thousands Of Travel Nurse Jobs Throughout The US. Best Paying Travel Nursing Agency Jobs in the US."
            />
            <meta
              property="og:image"
              content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
            />

            <meta
              property="twitter:card"
              content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
            />
            <meta
              property="twitter:url"
              content={AppConstants.Base_Url + "/team"}
            />
            <meta
              property="twitter:title"
              content={AppConstants.Our_Specialists}
            />
            <meta
              property="twitter:description"
              content="Recruiter - Choose From Thousands Of Travel Nurse Jobs Throughout The US. Best Paying Travel Nursing Agency Jobs in the US."
            />
            <meta
              property="twitter:image"
              content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
            />
          </Helmet>

          <div className="inner-block text-center" id="teams-page">
            <div class="container-fluid top-container">
              <div className="row">
                <div className="col-lg-5 col-xs-12 text-left res-width">
                  <div className="left-block">
                    <h2 className="mb-2 w-30">Empowering through service.</h2>
                    <h1>Travel Nurses, Inc. Team</h1>
                    <h3 className="main-title-three">
                      Nurse owned and operated company
                    </h3>
                    <p className="mb-4">
                      As a nurse-owned and nurse-operated company, we take pride
                      in the positive footprint we are making in the travel
                      nurse staffing community. Meet the people dedicated to
                      making sure our clinicians, vendors, and facilities are
                      cared for regardless of their role.
                    </p>
                    <div className="dropdown mt-3 mt-md-0">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <Input
                          type="select"
                          value={this.state.department}
                          name="department"
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="select" className="optionname">
                            See All Departments
                          </option>
                          <option
                            value="Clinician Advocates"
                            className="optionname"
                          >
                            Clinician Advocates
                          </option>
                          <option value="Leadership" className="optionname">
                            Leadership
                          </option>
                          <option value="Support " className="optionname">
                            Support{" "}
                          </option>
                        </Input>
                      </button>
                      <div className="career-bttn">
                        <a
                          class="sky-bttn"
                          href="https://travelnursesinc.com/careers/"
                          style={{ fontFamily: "Figtree Black" }}
                        >
                          Join Our Team
                        </a>
                      </div>
                      <ul className="team-logo-grid">
                        <li>
                          <a href="#" target="_blank">
                            <img
                              // src={teamlogo1}
                              src={`${AppConstants.Bucket_URL}/exit-popup-logo/winner2024.png`}
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.inc.com/inc5000/2022"
                            target="_blank"
                          >
                            <img
                              // src={teamlogo2}
                              src={`${AppConstants.Bucket_URL}/team/team-logo-two.png`}
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://app.greatrecruiters.com/companies/travel-nurses-inc-an-or-nurses-nationwide-co/"
                            target="_blank"
                          >
                            <img
                              // src={teamlogo3}
                              src={"https://df8de9x9ieyx9.cloudfront.net/Footer/great_recruiter.png"}
                              alt=""
                            />
                          </a>
                        </li>
                        <div className="clearfix"></div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-xs-12 p-0 image-displaynone">
                  <img
                    className="card-img-top"
                    // src={teamsimg}
                    src={`${AppConstants.Bucket_URL}/team/teams-img.png`}
                  />
                </div>
              </div>
            </div>
            <Container>
              <div className="inner-content pt-lg-5" id="profile-grid">
                <Row className="justify-content-center">
                  {this.state.recruiters.map((item, index) => (
                    <Col xs="3" className="block profile" id={index}>
                      <div className="item">
                        <Card
                          className={`block-box ${item.isGreatRecruiter ? "" : "remove-logo"
                            }`}
                        >
                          <figure className="pic image-fill">
                            <img
                              src={item.profileImage}
                              alt={item.name + " - Travel Nurses Inc"}
                            />
                            <img
                              src={RecruiterTag}
                              alt=""
                              className="profile-tag"
                            />
                          </figure>
                          <CardBody>
                            <CardTitle>
                              <Link
                                to={{
                                  pathname: "team/" + item.slug,
                                  state: { recruiter_id: item._id },
                                }}
                              >
                                {item.name}
                              </Link>
                              {item.tags.length > 0
                                ? ", " + item.tags.toString()
                                : ""}
                            </CardTitle>
                            {/* <CardText>{item.designation}</CardText> */}
                            <CardText>
                              {item.title ? item.title : item.designation}
                            </CardText>
                            <div className="social-icons">
                              {item.socialMedia.map((item1) => {
                                return (
                                  <a
                                    href={item1.url}
                                    target="_blank"
                                    className={item1.name}
                                  ></a>
                                );
                              })}
                              {item.phone ? (
                                <Link
                                  className="comment"
                                  onClick={this.handleMessage.bind(
                                    this,
                                    item.phone
                                  )}
                                ></Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </CardBody>
                        </Card>
                        <Link
                          to={{
                            pathname: "team/" + item.slug,
                            state: { recruiter_id: item._id },
                          }}
                          className="link"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              {this.state.modal === true ? (
                <MessagePopUp
                  modal={this.state.modal}
                  phone={this.state.phoneNumb}
                  handleModal={this.handleModal}
                />
              ) : (
                ""
              )}
            </Container>
          </div>
        </FadeIn>
      </div>
    );
  }
}

export default Team;
