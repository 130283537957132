import React  from "react";
import {
  Container,
  Row,
  Col,
 
  Form,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import ReactDOM from "react-dom";
import EditIcon from "../images/edit-icon.png";
import CKEditor from "react-ckeditor-component";
 
import CloseIcon from "../images/close-icon.png";
var hideEdit = [];
var imageFilesPreview = [];
var imageFiles = [];
var videoFiles = [];
var videoFilesPreview = [];
var description = [];
class MyStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      image: "",
      video: "",
      nurseId: "",
      userId: "",
      nurseStories: [],
      hideEdit: [],
      imageFiles: [],
      videoFiles: [],
      recordId: "",
      disableEditStory: false,
    };
    // this.handleEditStory = this.handleEditStory.bind(this);
  }
  handleCancelStory(index, e) {
    let val = {};
    val[index] = false;
    hideEdit[index] = false;
    this.setState({ hideEdit: val });
    this.getNurseStories();
  }
  handleUpdateStory(id, e) {
    e.preventDefault();
    console.log(id, e);
    let formData = new FormData();
    formData.append("id", this.state.recordId);
    formData.append("nurseId", this.state.nurseId);
    formData.append("userId", this.state.userId);
    formData.append("storyId", id[1]);
    formData.append("description", description[id[0]]);
    formData.append("image", imageFiles[id[0]]);
    formData.append("video", videoFiles[id[0]]);
    console.log(description[id], "description[id]", description[id[0]]);
    axios
      .post(AppConstants.API + "/nurseStory/updateNurseStory", formData)
      .then((res) => {
        if (res.data.Status === "Success") {
          this.getNurseStories();
          let val = {};
          val[id[0]] = false;
          hideEdit[id[0]] = false;
          this.setState({ hideEdit: val });
          // setTimeout((e) => {
          //   // this.props.history.push("/login")

          // }, 2000);
          setTimeout(() => {
            this.setState({
              isLoading: false,
              errMsg: (
                <div className="alert alert-success fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              errMsg: (
                <div className="alert alert-danger fade show mt-3">
                  {res.data.msg}
                </div>
              ),
            });
          }, 1000);
        }
      });
  }
  handleEditStory(index, e) {
    e.preventDefault();
    let val = {};
    val[index] = true;
    hideEdit[index] = true;
    this.setState({ hideEdit: val });
    console.log("line32", index, "index", e);
  }
  componentDidMount() {
    console.log(
      this.props.location,
      "this.props",
      this.props,
      this.props.location.customObj
    );
    // this.getNurseStories ();

    const that = this;
    if (this.props.location.customObj) {
      that.setState({ disableEditStory: false });
    } else {
      that.setState({ disableEditStory: true });
    }
    axios
      .get(
        AppConstants.API +
          "/nurse/getSlugBasedNurse/" +
          this.props.match.params.slug
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status === "Success") {
          that.setState({
            nurseId: data.data._id,
            userId: data.data.recruiter,
          });
          axios
            .get(
              AppConstants.API + "/nurseStory/getNurseStory/" + data.data._id
            )
            .then((res) => {
              return res.data;
            })
            .then(function (data1) {
              console.log(data1, "data1");
              if (data1.Status === "Success") {
                // var videoFiles = [];
                // var imageFiles = [];
                data1.data.nurseStory.map((item, index) => {
                  console.log(item.image, "item.image");
                  // var imageFile = {};
                  // var videoFile = {};
                  // imageFile[index] = item.image;
                  // videoFile[index] = item.video;
                  imageFiles.push(item.image);
                  videoFiles.push(item.video);
                  videoFilesPreview.push(item.video);
                  hideEdit.push(false);
                  imageFilesPreview.push(item.image);
                  description.push(item.description);
                });
                that.setState({
                  recordId: data1.data._id,
                  nurseStories: data1.data.nurseStory,
                  videoFiles: videoFiles,
                  imageFiles: imageFiles,
                  // imageFilesPreview: imageFiles
                });
              }
            });
        }
      });
  }
  getNurseStories() {
    const that = this;
    axios
      .get(
        AppConstants.API +
          "/nurse/getSlugBasedNurse/" +
          this.props.match.params.slug
      )
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status === "Success") {
          that.setState({
            nurseId: data.data._id,
            userId: data.data.recruiter,
          });
          axios
            .get(
              AppConstants.API + "/nurseStory/getNurseStory/" + data.data._id
            )
            .then((res) => {
              return res.data;
            })
            .then(function (data1) {
              console.log(data1, "data1");
              if (data1.Status === "Success") {
                that.setState({
                  nurseStories: data1.data.nurseStory,
                });
              }
            });
        }
      });
  }
  onChange(index1, evt) {
    console.log("hello", evt, "evt", index1);
    var newContent = evt.editor.getData();
    console.log("hello", evt, "evt", index1, "newContent", newContent);
    // this.setState({
    //   description: newContent
    // })
    description[index1] = newContent;
    // this.state.nurseStories.map((item,index) => {
    //   if (item._id ===  index1) {
    //     console.log( item._id ,"item._id ===  index", index1, "index", index)
    //     this.state.nurseStories[index].description = newContent
    //     this.setState({description:newContent})
    //   }
    // })
  }
  handleFile(id, e) {
    console.log(id, "id97", e.target.files[0]);
    imageFiles[id] = e.target.files[0];
    imageFilesPreview[id] = URL.createObjectURL(e.target.files[0]);
    this.setState({ image: e.target.files[0] });
  }
  handleVideo(id, e) {
    console.log(id, "id100", e.target.files[0]);
    videoFiles[id] = e.target.files[0];
    videoFilesPreview[id] = URL.createObjectURL(e.target.files[0]);
    this.setState({ video: e.target.files[0] });
  }
  render() {
    console.log(
      "heoifed",
      this.state.disableEditStory,
      "this.state.imageFiles",
      imageFiles,
      "imageFilesPreview",
      imageFilesPreview,
      "videoFiles",
      videoFiles,
      "videoFilesPreview",
      videoFilesPreview
    );
    return (
      <div className="profile-view main-page p-tb-50 bg-grey">
        <Container>
          <div className="registration-form">
            <div className="title">My Stories</div>
            <div className="w-100 d-block text-right mb-2">
              <button
                class="button back-btn float-none"
                onClick={() => this.props.history.go(-1)}
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Back
              </button>
            </div>
            <Form
              name="my-story"
              className="view-info  my-story profile-info border-pad"
            >
              {this.state.nurseStories.map((item, index) => {
                return (
                  <Row
                    style={{
                      padding: "20px",
                      border: "solid 1px #ccc",
                      margin: "0 0 20px",
                    }}
                    id={index}
                  >
                    {" "}
                    <div className="top-row">
                      {item.approval ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            border: "solid 1px #fff",
                            borderRadius: "5px",
                          }}
                        >
                          Approved
                        </span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "#e2ba0fcc",
                            color: "#fff",
                            border: "solid 1px #fff",
                            borderRadius: "5px",
                          }}
                        >
                          {" "}
                          Pending{" "}
                        </span>
                      )}
                      <div className="btn-sec">
                        {hideEdit[index] ? (
                          <button
                            className="edit-btn"
                            onClick={this.handleCancelStory.bind(this, index)}
                          >
                            <img
                              src={CloseIcon}
                              alt="CloseIcon - Nurse Story - Travel Nurses Inc"
                            />
                          </button>
                        ) : (
                          <button
                            className="edit-btn"
                            onClick={this.handleEditStory.bind(this, index)}
                            disabled={this.state.disableEditStory}
                          >
                            <img
                              src={EditIcon}
                              alt="EditIcon - Nurse Story - Travel Nurses Inc"
                            />
                          </button>
                        )}
                      </div>
                      <div className="clear"></div>
                    </div>
                    {hideEdit[index] ? (
                      <Form
                        name=""
                        className="view-info profile-info border-pad"
                        onSubmit={this.handleLicenseSubmit}
                      >
                        <Row className="hii">
                          <Col xs="12" className="block">
                            <FormGroup>
                              <Label for="description">
                                Description
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <CKEditor
                                activeClass="editor12"
                                content={item.description}
                                config={{ allowedContent: true }}
                                events={{
                                  change: this.onChange.bind(this, index),
                                }}
                                ref="content"
                              />
                              <span>
                                {this.state.contenError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["content"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>
                          <Col xs="12" className="block">
                            <FormGroup>
                              <Label>Image</Label>
                              {imageFilesPreview[index] ? (
                                <img
                                  src={imageFilesPreview[index]}
                                  style={{ width: 300, height: 200 }}
                                  alt={
                                    "Nurse Story - Travel Nurses Inc - " + index
                                  }
                                />
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="image"
                                // value={this.state.image}
                                ref="image"
                                onChange={this.handleFile.bind(this, index)}
                                accept="image/png, image/jpeg"
                              />
                              <span>
                                {this.state.imageError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["image"]}
                                    {/* {this.state.imageError1 ? this.state.errorImage : ""} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>
                          <Col xs="12" className="block">
                            <FormGroup>
                              <Label>Video</Label>
                              {videoFilesPreview[index] ? (
                                <iframe
                                  src={videoFilesPreview[index]}
                                  frameborder="0"
                                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              ) : (
                                ""
                              )}
                              <Input
                                type="file"
                                name="video"
                                // value={this.state.video}
                                ref="video"
                                onChange={this.handleVideo.bind(this, index)}
                                accept="video/mp4"
                              />
                              <span style={{ color: "green" }}>
                                Upload Mp4 type file
                              </span>
                              <span>
                                {this.state.upload_videoError === true ? (
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["video"]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </FormGroup>
                          </Col>
                          <div className="btn-block">
                            <span
                              href="javascript:void(0)"
                              className="btn btn-prev button"
                              title="Cancel"
                              onClick={this.handleCancelStory.bind(this, index)}
                            >
                              Cancel
                            </span>
                            <span
                              class="btn btn-next button"
                              title="Update"
                              onClick={this.handleUpdateStory.bind(this, [
                                index,
                                item._id,
                              ])}
                            >
                              Update
                              {this.state.isLoading ? (
                                <img
                                  src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                  alt="No Image"
                                  style={{ width: 40, height: 40 }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </Row>
                      </Form>
                    ) : (
                      <span className="content-sec">
                        {item.description ? (
                          <Col xs="12" className="block">
                            <FormGroup>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          ""
                        )}
                        {item.image ? (
                          <Col xs="12" className="block">
                            <FormGroup>
                              <img
                                src={item.image}
                                style={{ width: 300, height: 200 }}
                                alt={
                                  "Nurse Story -  Travel Nurses Inc - " + index
                                }
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          ""
                        )}
                        {item.video ? (
                          <Col xs="12" className="block">
                            <FormGroup>
                              <iframe
                                src={item.video}
                                frameborder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </FormGroup>
                          </Col>
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                  </Row>
                );
              })}
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default MyStory;
