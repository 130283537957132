import React from "react";
import { Container, Row, Col } from "reactstrap";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import styles from "../Styles/search-job.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RelatedJobs from "../Components/related-job";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import TeamBlock from "../Components/job-team-block";
import SearchPage from "../Components/searchpage";
import { JSONLD, Generic } from "react-structured-data";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import { Helmet } from "react-helmet";
import BrandLogo from "../images/property-logo.png";

import GifImg from "../images/TNI-mobile-Gif1.gif";
import AppStore from "../images/app-store-img.png";
import GoogleStore from "../images/google-store-img.png";
import ContactRecruiterPopUp from "../Pages/contact_recruiter";
import { BiBookmark } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { FaClone } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import AskQuestion from "./askquestion";

const moment = require("moment-timezone");
class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      saveJob: false,
      weather: null,
      job: null,
      properties: [],
      total_properties: 0,
      benefits: "",
      job_benefits: "",
      description: "",
      title: "",
      cityJob: "",
      stateJob: "",
      propertiesInfo: [],
      propertiesDataStatus: true,
      prevPath: "",
      modal: false,
      userLocationData: null,
      jobLocationData: null,
      userCity: "",
      userState: "",
      jobLoader: true,
      full_stateName: "",
      specialty: "",
      certification: "",
      readMore: false,
      readMoreBenefits: false,
      showUserPopUp: false,
      workType: "",
      prnBenefits: "",
      permanentJobBenefits: "",
      prnDescription: "",
      permanentJobDescription: "",
      nurseId: localStorage.getItem("id"),
    };
  }

  componentDidMount() {
    const currentParams = new URLSearchParams(window.location.search);

    const queryString = currentParams.toString();
    window.scrollTo(0, 0);
    if (this.props.location.state) {
      if (this.props.location.state.prevPath) {
        this.setState({ prevPath: this.props.location.state.prevPath });
      }
    }
    this.handleJobDetails(this.props.match.params.id, queryString);
  }
  handleJobDetails(jobId, queryString) {
    const componentState = this;
    let userId = localStorage.getItem("id");

    axios
      .post(AppConstants.Jobs_API + "/jobs/getJob/" + jobId, {
        nurseId: userId ? userId : "",
      })
      .then((res) => {
        // console.log("Click here to view all jobs. ", res)
        return res.data;
      })
      .catch((err) => {
        console.log("err--", err);

        if (queryString) {
          componentState.props.history.push(`/relevant-jobs?${queryString}`);
        } else {
          componentState.props.history.push("/error");
        }
      })
      .then(function (data) {
        // console.log(data)
        if (data.Status == "Success" && data.data) {
          var jobData = data.data;

          // google structured data adding into prod here 3/1/2023 by Shravani and Rahul
          // google structured data adding here by sai bandari and rahul 6/3/2023

          var newlist = [];
          // var obj = {
          //   "@context": "https://schema.org/",
          //   "@type": "JobPosting",
          //   title: jobData.title,
          //   jobBenefits: jobData.benefits
          //     .replace(/(<([^>]+)>)/gi, "")
          //     .replace(/(\t\n|\n|\t)/gm, ""),
          //   employmentType: jobData.shift,
          //   experienceRequirements: jobData.minExperienceRequired,
          //   industry: "Travel Nurses",
          //   salaryCurrency: "USD",
          //   skills: jobData.speciality,
          //   qualifications: jobData.degree,

          //   breadcrumb: "Home >> Job Search >> " + jobData.title,
          //   contentReferenceTime: new Date(),
          //   description: jobData.description
          //     .replace(/(<([^>]+)>)/gi, "")
          //     .replace(/(\t\n|\n|\t)/gm, ""),
          //   datePosted: jobData.postDate,
          //   validThrough: jobData.endDate,
          //   hiringOrganization: {
          //     "@type": "Organization",
          //     name: "Travel Nurses",
          //     sameAs: "https://jobs.travelnursesinc.com/",
          //     logo: "https://jobs.travelnursesinc.com/tnlogo.png",
          //   },
          //   jobLocation: {
          //     "@type": "Place",
          //     address: {
          //       "@type": "PostalAddress",
          //       // streetAddress: '',
          //       streetAddress: jobData.address || "N/A",
          //       addressLocality: jobData.city,
          //       addressRegion: jobData.state,
          //       postalCode: jobData.zipcode,
          //       addressCountry: "US",
          //     },
          //   },
          //   baseSalary: {
          //     "@type": "MonetaryAmount",
          //     currency: "USD",
          //     value: {
          //       "@type": "QuantitativeValue",
          //       value: jobData.grossPay1,
          //       unitText: "WEEK",
          //     },
          //   },
          // };
          // newlist.push(obj);
          // // }

          // const script = document.createElement("script");
          // script.setAttribute("type", "application/ld+json");
          // script.textContent = JSON.stringify(newlist);
          // // console.log('jobList', JSON.stringify(newlist));
          // var key = document.head.appendChild(script);

          componentState.setState({
            saveJob: jobData.isSaved,
            job: jobData,
            jobId: jobData._id,
            title: jobData.title,
            workType: jobData.workType,
            description: jobData.description,
            job_benefits: jobData.benefits,
            prnDescription: jobData.prnDescription,
            permanentJobDescription: jobData.permanentJobDescription,
            prnBenefits: jobData.prnBenefits,
            permanentJobBenefits: jobData.permanentJobBenefits,
            cityJob: jobData.city,
            stateJob: jobData.state,
            full_stateName: jobData.stateName,
            specialty: jobData.speciality,
            certification: jobData.degree,
            jobLoader: false,
          });

          const formData = {
            fur: false,
            util: "",
            budget: "",
            pets: false,
            ptype: "",
            start: 5,
            count: 5,
            sort: "",
            dir: "",
          };
          componentState.setState({ propertiesDataStatus: false });
          axios
            .post(
              AppConstants.Partners_API +
                "/furnishedfinder-partners-map-list/" +
                data.data.city.trim() +
                "/" +
                data.data.stateName.trim(),
              formData
            )
            .then((res) => {
              console.log("res", res);
              if (res.status === 200 && res.data.value.length > 0) {
                componentState.setState({
                  propertiesInfo: res.data.value,
                  propertiesDataStatus: true,
                });
              } else {
                componentState.setState({
                  propertiesInfoMsg: "Properties not available",
                  propertiesDataStatus: true,
                });
              }
            })
            .catch((e) => {
              componentState.setState({
                propertiesInfoMsg: "Properties not available",
                propertiesDataStatus: true,
              });
            });
          axios
            .get(
              AppConstants.CMS_API +
                "/events/getEvents/" +
                jobData.city.trim() +
                "/" +
                jobData.state.trim()
            )
            .then((res) => {
              return res.data;
            })
            .then(function (data) {
              if (data.status == "Success" && data.data) {
                componentState.setState({
                  events: data.data,
                });
              } else {
                console.log("events data failed");
              }
            });

          axios
            .get(
              AppConstants.CMS_API +
                "/weather/getWeatherData/" +
                jobData.city.trim() +
                "/" +
                jobData.state.trim()
            )
            .then((res) => {
              return res.data;
            })
            .then(function (data) {
              if (data.status == "success" && data.data) {
                componentState.setState({
                  weather: data.data,
                });
              } else {
                console.log("weather data failed");
              }
            });
        } else {
          if (queryString) {
            componentState.props.history.push(`/relevant-jobs?${queryString}`);
          } else {
            componentState.props.history.push("/error");
          }
          // componentState.props.history.push("/error");
        }
      })
      .catch((err) => {
        console.log(err);
        componentState.props.history.push("/error");
        // window.location.href = `${AppConstants.Job_Url}/error`;
      });
  }
  specialityCallback1 = (speciality) => {
    this.handleJobDetails(speciality);
    window.scrollTo(0, 0);
  };
  handleContactRecruiter() {
    this.setState({ modal: true });
  }

  handleModal = (event) => {
    event.preventDefault(); 
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };
  copyCodeToClipboard(id, e) {
    NotificationManager.info("URL Copied", "", 3000);
    e.preventDefault();
    var cpLink = AppConstants.Base_Url + "/job/" + id;
    navigator.clipboard.writeText(encodeURI(cpLink));
  }

  setReadMore() {
    this.setState({
      readMore: !this.state.readMore,
    });
  }

  setReadMoreBenefits() {
    this.setState({
      readMoreBenefits: !this.state.readMoreBenefits,
    });
  }

  handleUserPopup(e) {
    e.preventDefault();
    this.setState({ showUserPopUp: true });
  }
  handleUserModelPopup() {
    this.setState({ showUserPopUp: !this.state.showUserPopUp });
  }
  async handleSaveJobClick() {
    let userId = localStorage.getItem("id");
    this.setState({
      saveJob: !this.state.saveJob,
    });
    await axios
      .post(AppConstants.API + "/nurse/SaveJobAction", {
        nurseId: userId,
        jobId: this.props.match.params.id,
      })
      .then((response) => {
        NotificationManager.success(response.data.msg, "", 3000);
      });
  }

  render() {
    const descReadmoreContent =
      this.state.workType == "1"
        ? this.state.description.split("<span>")
        : this.state.workType == "3"
        ? this.state.prnDescription.split("<span>")
        : this.state.permanentJobDescription.split("<p>");
    const afterDescReadmore = descReadmoreContent.slice(0, 2).join("<span>");
    const beforeDescReadmore =
      "<span>" + descReadmoreContent.slice(2).join("</span>");
    const BenReadmoreContent =
      this.state.workType == "1"
        ? this.state.job_benefits.split("<ul")
        : this.state.workType == "3"
        ? this.state.prnBenefits.split("<ul")
        : this.state.permanentJobBenefits.split("<ul");
    const afterBenReadmore = BenReadmoreContent.slice(0, 1).join("<ul");
    const beforeBenReadmore = "<ul " + BenReadmoreContent.slice(1).join("<ul");

    const linkName = this.state.workType !== "2" && !this.state.readMore && (
      <span className="readmore-icon">Read More</span>
    );
    const readLessLink = this.state.workType !== "2" && this.state.readMore && (
      <span className="readless-icon">Read Less</span>
    );
    const linkNameBenefit = this.state.workType !== "2" &&
      !this.state.readMoreBenefits && (
        <p className="readmore-icon">Read More</p>
      );
    const readLessLinkBenefi = this.state.workType !== "2" &&
      this.state.readMoreBenefits && <p className="readless-icon">Read Less</p>;

    const settings = {
      arrow: true,
      adaptiveHeight: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
    };

    const settings2 = {
      arrow: true,
      adaptiveHeight: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
    };

    const settings3 = {
      // arrow: false,
      dots: true,
      infinite: true,
      adaptiveHeight: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
    };

    const { events, weather, job } = this.state;
    var result = "";
    if (this.state.description) {
      result = this.state.description.replace(/(<([^>]+)>)/gi, "");
    }

    const ben = this.state.benefits.replace(/(<([^>]+)>)/gi, "");

    result = this.state.description.replace(/(<([^>]+)>)/gi, "");

    console.log(
      "job details",
      !this.props.location &&
        !this.props.location.state &&
        !this.props.location.state.pnr
    );
    return (
      <div className="job-details-block job-details-ui">
        <JSONLD>
          <Generic
            type="JobPosting"
            jsonldtype="JobPosting"
            schema={{
              jobBenefits: ben.replace(/(\t\n|\n|\t)/gm, ""),
              datePosted: this.state.job?.postDate.toString().slice(0, 10),
              description: result.replace(/(\t\n|\n|\t)/gm, ""),
              experienceRequirements: this.state.minExperienceRequired,
              industry: "Travel Nurses, Inc",
              salaryCurrency: "USD",
              skills: this.state.speciality,
              qualifications: this.state.degree,
              title: this.state.title ? this.state.title : "Travel Nurses Inc",
              breadcrumb: "Home >> Job Search >> " + this.state.title,
              contentReferenceTime: new Date(),
              validThrough: this.state.job?.endDate?.toString().slice(0, 10),
              employmentType: this.state.job?.shift,
            }}
          >
            <Generic
              type="hiringOrganization"
              jsonldtype="Organization"
              schema={{
                name: "Travel Nurses, Inc",
                sameAs: "https://jobs.travelnursesinc.com/",
                logo: "https://jobs.travelnursesinc.com/tnlogo.png",
              }}
            />
            <Generic type="jobLocation" jsonldtype="Place">
              <Generic
                type="address"
                jsonldtype="PostalAddress"
                schema={{
                  streetAddress: this.state.job?.address || "N/A",
                  addressLocality: this.state.cityJob ? this.state.cityJob : "",
                  addressRegion: this.state.stateJob ? this.state.stateJob : "",
                  postalCode: this.state.job?.zipcode,
                  addressCountry: "US",
                }}
              />
            </Generic>
            <Generic
              type="baseSalary"
              jsonldtype="MonetaryAmount"
              schema={{
                currency: "USD",
              }}
            >
              <Generic
                type="value"
                jsonldtype="QuantitativeValue"
                schema={{
                  value: this.state.job?.grossPay1,
                  unitText: "Week",
                }}
              />
            </Generic>
          </Generic>
        </JSONLD>
        <Helmet>
          <title>{this.state.title + " - Traveling Nurses Inc"}</title>
          <meta
            name="title"
            content={this.state.title + " - Traveling Nurses Inc"}
          />
          <meta
            name="description"
            content={
              this.state.title +
              " - Traveling Nurses Inc - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
            }
          />
          <link
            rel="canonical"
            href={AppConstants.Job_Url + "/job/" + this.props.match.params.id}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={
              AppConstants.Base_Url + "/job/" + this.props.match.params.id
            }
          />
          <meta
            property="og:title"
            content={this.state.title + " - Traveling Nurses Inc"}
          />
          <meta
            property="og:description"
            content={
              this.state.title +
              " - Traveling Nurses Inc - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
            }
          />
          <meta
            property="og:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />

          <meta
            property="twitter:card"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />
          <meta
            property="twitter:url"
            content={
              AppConstants.Base_Url + "/job/" + this.props.match.params.id
            }
          />
          <meta
            property="twitter:title"
            content={this.state.title + " - Traveling Nurses Inc"}
          />
          <meta
            property="twitter:description"
            content={
              this.state.title +
              " - Traveling Nurses Inc - Nurses are the head and heart of our organization. Founded in 1988 by nurses, we now provide complete services for career mobility in nursing. Learn more here."
            }
          />
          <meta
            property="twitter:image"
            content="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
          />
        </Helmet>
        <SearchPage searchHistory={this.props} />
        <div className="main-content">
          {job ? (
            <Container>
              <div className="inner-content">
                {/* Breadcrumb Block  Start Here */}
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/">Home</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {/* <Link to="/jobs">Job Search</Link> */}
                    <Link to="/">Job Search</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/job/${job._id}`}>{job.title}</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem active>
                      RN Med-Surg Tele Jobs in Memphis
                    </BreadcrumbItem> */}
                </Breadcrumb>
                {/* Breadcrumb Block  End Here */}

                {/* Jobs Details Block Start Here */}
                {this.state.jobLoader ? (
                  <div className="list-job pb-50 loader-center">
                    {" "}
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      className="loader-center"
                      style={{ width: 80, height: 80 }}
                    />{" "}
                  </div>
                ) : (
                  <Row>
                    <Col xs="9" className="left-block details_content_block">
                      {/* Job-info Block Start Here */}
                      <div className="title-sec d-flex">
                        <h4 className="job-id col">
                          Job ID: <em>{job.providerJobId}</em>
                          {/* Job ID: <em>{job.orderId}</em>  */}
                        </h4>
                        <div className="btn-sec col">
                          <Link
                            to={{
                              pathname: this.state.prevPath
                                ? this.state.prevPath
                                : `/`,
                              state: {
                                speciality: this.props.location.state
                                  ? this.props.location.state.speciality
                                  : "",
                                city: this.props.location.state
                                  ? this.props.location.state.city
                                  : "",
                                state: this.props.location.state
                                  ? this.props.location.state.state
                                  : "",
                                searchRadius: this.props.location.state
                                  ? this.props.location.state.searchRadius
                                  : "",
                                specialities: this.props.location.state
                                  ? this.props.location.state.specialities
                                  : "",
                                cities: this.props.location.state
                                  ? this.props.location.state.cities
                                  : "",
                                states: this.props.location.state
                                  ? this.props.location.state.states
                                  : "",
                                duration: this.props.location.state
                                  ? this.props.location.state.duration
                                  : [],
                                shift: this.props.location.state
                                  ? this.props.location.state.shift
                                  : [],
                                currentPage: this.props.location.state
                                  ? this.props.location.state.currentPage
                                  : "",
                                selectedPageSize: this.props.location.state
                                  ? this.props.location.state.selectedPageSize
                                  : "",
                                searchText: this.props.location.state
                                  ? this.props.location.state.searchText
                                  : "",
                                // check_duration: this.props.location.state ? this.props.location.state.check_duration : "",
                                selectedDuration: this.props.location.state
                                  ? this.props.location.state.selectedDuration
                                  : "",
                                selectedShifts: this.props.location.state
                                  ? this.props.location.state.selectedShifts
                                  : "",
                                startDate: this.props.location.state
                                  ? this.props.location.state.startDate
                                  : "",
                                toStartDate: this.props.location.state
                                  ? this.props.location.state.toStartDate
                                  : "",
                                jobDetailPath: this.props.match.path,
                                pnr: this.props.location.state
                                  ? this.props.location.state.pnr
                                  : false,
                              },
                            }}
                            className="read-more back-btn"
                          >
                            Back
                          </Link>
                        </div>
                      </div>

                      <div className="job-info block-box">
                        <div className="inner-text">
                          <ul className="detail-info">
                            <div className="job-inner-save-block">
                            <h3 className="job_detail__job_type" >{job.workType == "2"?"Permanent":job.workType=="3"?"PRN":""}</h3>

                              <div
                                className="share-btn position-absolute bg-white share-rotate111"
                                style={{
                                  right: "-128px",
                                  top: "0px",
                                }}
                              >
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Slide to left"
                                  class="button-share pr-0 d-flex"
                                >
                                  <span class="icon-share">
                                    <i
                                      class="fa fa-share-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span class="text">
                                    <Row className="pr-2 mr-0">
                                      <Col
                                        className="rounded-left text-white text-center px-1 pt-1 "
                                        style={{ background: "#25408f" }}
                                      >
                                        <NotificationContainer />
                                        <FaClone
                                          className=""
                                          onClick={this.copyCodeToClipboard.bind(
                                            this,
                                            job._id
                                          )}
                                        />
                                      </Col>
                                      <Col
                                        className="pl-0 pr-2"
                                        style={{ background: "#00aced" }}
                                      >
                                        <span className="twitter">
                                          <TwitterShareButton
                                            url={
                                              AppConstants.Base_Url +
                                              `/job/${job._id}`
                                            }
                                            imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                            className="share"
                                            title={`${job.title} Job in ${job.city}, ${job.state}\n Travel Nurses, Inc. has an opportunity open for a ${job.title} to work ${job.duration} weeks in ${job.city}, ${job.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                          >
                                            <TwitterIcon
                                              size={32}
                                              round={true}
                                            />
                                          </TwitterShareButton>
                                        </span>
                                      </Col>
                                      <Col
                                        className="pl-0 pr-2 "
                                        style={{ background: "#405992" }}
                                      >
                                        <span className="facebook">
                                          <FacebookShareButton
                                            url={
                                              AppConstants.Base_Url +
                                              `/job/${job._id}`
                                            }
                                            imageURL="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                            className="share w-100"
                                            quote={`${job.title} Job in ${job.city}, ${job.state}\n Travel Nurses, Inc. has an opportunity open for a ${job.title} to work ${job.duration} weeks in ${job.city}, ${job.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                          >
                                            <FacebookIcon
                                              size={32}
                                              round={true}
                                            />
                                          </FacebookShareButton>
                                        </span>
                                      </Col>
                                      <Col
                                        className="pl-0 pr-2 rounded-right"
                                        style={{ background: "#007fb1" }}
                                      >
                                        <LinkedinShareButton
                                          url={
                                            AppConstants.Base_Url +
                                            `/job/${job._id}`
                                          }
                                          className="share"
                                          title={`${job.title} Job in ${job.city}, ${job.state} `}
                                          summary={`Travel Nurses, Inc. has an opportunity open for a ${job.title} to work ${job.duration} weeks in ${job.city}, ${job.state}. Apply now to start your next adventure with great benefits, fair pay, and a supportive team behind you!`}
                                          media="https://travelnurses.s3-us-west-2.amazonaws.com/static/logo.jpg"
                                        >
                                          <LinkedinIcon
                                            size={32}
                                            round={true}
                                          />
                                        </LinkedinShareButton>
                                      </Col>
                                      {/* <Col
                              className="text-white text-center pt-1 px-1 "
                              style={{ background: "#8651f7" }}
                            >
                              <FaCode />
                            </Col> */}
                                    </Row>
                                  </span>
                                </span>
                              </div>

                              {this.state.nurseId && job.workType == "1" && (
                                <div
                                  className={`saved-text ${
                                    this.state.saveJob ? "" : "addSavedClass"
                                  }`}
                                >
                                  <button
                                    onClick={this.handleSaveJobClick.bind(this)}
                                  >
                                    {this.state.saveJob ? (
                                      <BsFillBookmarkFill />
                                    ) : (
                                      <BiBookmark />
                                    )}{" "}
                                    <span>
                                      {this.state.saveJob ? "Remove" : "Save"}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                            <li className="job-detail position-relative">
                              {/* <h3>{job.title}</h3> */}
                              <div className="table-wrapper title-bar-grid">
                                <aside className="title-bar-grid-left">
                                  <h3 style={{ color: "#333333" }}>
                                    {job.degree}
                                  </h3>
                                  <p>{job.jobSubSpeciality}</p>
                                </aside>
                                <aside className="title-bar-grid-right">
                                  {this.state.workType == "1" &&
                                    (job.grossPay1 < 500 ? (
                                      <span className="est-pay-nill pl-0 pl-sm-3 pl-lg-3 pb-1">
                                        Est.pay:{" "}
                                        <em
                                          style={{
                                            cursor: "pointer",
                                            color: "#21ace3",
                                          }}
                                          onClick={this.handleContactRecruiter.bind(
                                            this
                                          )}
                                        >
                                          Request Weekly Gross
                                        </em>
                                        {this.state.modal === true ? (
                                          <ContactRecruiterPopUp
                                            modal={this.state.modal}
                                            jobId={this.props.match.params.id}
                                            title="Request Estimated Weekly Gross"
                                            handleModal={this.handleModal.bind(
                                              this
                                            )}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    ) : (
                                      <span className="pl-0 pl-sm-3 pl-lg-3 pb-1">
                                        <p>Est.pay:</p>
                                        <em>
                                          {" $" + job.grossPay1 + "/Week"}
                                        </em>
                                        <br />
                                      </span>
                                    ))}
                                </aside>
                              </div>
                              <b className="city">
                                {job.city + ", " + job.state}
                              </b>
                              <div className="date-sec">
                                <p className="date">
                                  Start Date:{" "}
                                  <em>
                                    {job.workType == "1"
                                      ? job.start_date
                                        ? job.start_date
                                        : "N/A"
                                      : "ASAP"}
                                  </em>
                                </p>

                                {this.state.workType == "1" && (
                                  <p className="duration">
                                    Duration:{" "}
                                    <em>
                                      {job.duration
                                        ? job.duration == 1
                                          ? job.duration + " Week"
                                          : job.duration + " Weeks"
                                        : "N/A"}
                                    </em>
                                  </p>
                                )}
                              </div>
                            </li>
                            <li className="btn-section">
                              <a
                                href="#"
                                className="popup_link"
                                onClick={this.handleUserPopup.bind(this)}
                              >
                                Ask a question about this job
                              </a>

                              {this.state.showUserPopUp ? (
                                <AskQuestion
                                  showUserPopUp={this.state.showUserPopUp}
                                  handleUserModelPopup={this.handleUserModelPopup.bind(
                                    this
                                  )}
                                  title="Ask a Question About This Job"
                                  jobId={job.providerJobId}
                                  workType={job.workType}
                                />
                              ) : (
                                ""
                              )}
                              <Link
                                to={`/apply-job/${job._id}`}
                                className="button mt-0 mt-lg-5 jod-detail_apply-top"
                              >
                                Apply
                              </Link>
                              {job.pinned ? (
                                <figure
                                  className="position-absolute rounded-circle bg-white"
                                  style={{
                                    top: "43%",
                                    boxShadow:
                                      "0px 20px 50px 10px rgb(9 36 157 / 10%)",
                                    right: "-21px",
                                  }}
                                >
                                  <img
                                    src={`${AppConstants.Bucket_URL}/bannerImages/tn-flame.gif`}
                                    style={{
                                      width: "43px",
                                      padding: "0px 6px 6px 6px",
                                    }}
                                  />
                                </figure>
                              ) : (
                                ""
                              )}
                              {/* <a href="" className="forward-link">
                                  {item.reflink}
                                </a> */}
                              <div className="clear"></div>
                            </li>
                          </ul>

                          <div className="job-post-info">
                            <span>
                              Posted on:{" "}
                              <em>
                                {job.postDate
                                  ? moment(job.postDate).format("MMM DD,YYYY")
                                  : "N/A"}
                              </em>
                            </span>
                            <span className="time">
                              Shift:{" "}
                              {job.workType == "1" ? job.shift : job.shiftType}
                            </span>
                            {job.workType == "1" && (
                              <span>
                                Positions:{" "}
                                <em>{job.positions ? job.positions : "N/A"}</em>
                              </span>
                            )}
                          </div>

                          <ul className="job-status">
                            <li class="w-auto pl-2 pl-lg-0 mr-0 mr-lg-5">
                              Status: <em>{job.status ? job.status : ""}</em>
                            </li>
                            <li className="certification w-auto mr-0 mr-lg-5">
                              Certification:{" "}
                              <em>{job.degree ? job.degree : ""}</em>
                            </li>

                            {this.state.workType == "1" && (
                              <li className="w-100 pt-2  pt-lg-0 pl-1 pl-lg-0 wages-expense">
                                * Wages and expense reimbursement dependent on
                                your custom pay package
                              </li>
                            )}
                          </ul>
                        </div>
                        {job.licenses ? (
                          <div className="grey-block">
                            <p>
                              <em>Licenses:</em>{" "}
                              {job.licenses ? job.licenses : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* Job-info Block End Here */}

                      <div className="block-box benefits-blk">
                        <div className="benefit-list inner-text">
                          {/* <h3>Benefits</h3> */}
                          <h3>Job Description</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: afterDescReadmore,
                            }}
                          ></div>

                          <p>
                            <span className="read-more-link">
                              <br />
                              <a onClick={this.setReadMore.bind(this)}>
                                {linkName}
                              </a>
                              {this.state.readMore && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: beforeDescReadmore,
                                  }}
                                ></div>
                              )}
                              <a onClick={this.setReadMore.bind(this)}>
                                {readLessLink}
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="block-box benefits-blk benefits-list">
                        <div className="benefit-list inner-text">
                          <h3>Benefits and Perks</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: afterBenReadmore,
                            }}
                          ></div>

                          <div className="read-more-link">
                            <a onClick={this.setReadMoreBenefits.bind(this)}>
                              {linkNameBenefit}
                            </a>
                            {this.state.readMoreBenefits && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: beforeBenReadmore,
                                }}
                              ></div>
                            )}
                            <a onClick={this.setReadMoreBenefits.bind(this)}>
                              {readLessLinkBenefi}
                            </a>
                          </div>
                        </div>
                      </div>
                      {this.state.workType == "1" && (
                        <div>
                          {this.state.propertiesDataStatus === true ? (
                            this.state.propertiesInfo.length > 0 ? (
                              <div
                                className="properties-blk block-box"
                                id="properties-nearby"
                              >
                                <div className="inner-text">
                                  <h3>Properties nearby</h3>
                                  <figure className="brand-pic image-fill prop-image">
                                    <img
                                      src={BrandLogo}
                                      alt="Furnished Finder Logo"
                                    />
                                  </figure>
                                  <div className="clear"></div>
                                  <Slider {...settings2}>
                                    {this.state.propertiesInfo.map((item) => (
                                      <div className="content">
                                        <div className="left-block">
                                          <Slider {...settings3}>
                                            {item.images.map(
                                              (imageFile, imgIndex) => {
                                                return (
                                                  <figure
                                                    className="pic image-fill"
                                                    id={imgIndex}
                                                  >
                                                    <a
                                                      href={item.url}
                                                      target="_blank"
                                                    >
                                                      <img
                                                        src={imageFile.url}
                                                        alt={
                                                          item.name +
                                                          " - " +
                                                          imgIndex +
                                                          " - Furnished Finder"
                                                        }
                                                      />
                                                    </a>
                                                  </figure>
                                                );
                                              }
                                            )}
                                          </Slider>
                                        </div>

                                        <div className="right-block">
                                          <div className="prop-list">
                                            <h4>{item.name}</h4>
                                            <h3>{item.price}</h3>
                                            <ul>
                                              <li className="list city">
                                                {this.state.cityJob}
                                              </li>
                                              <li className="list bed">
                                                {item.bedrooms}
                                              </li>
                                              <li className="list home">
                                                {" "}
                                                {item.type}
                                              </li>
                                              <li className="list bath">
                                                {item.bathrooms}
                                              </li>
                                              {item.available ? (
                                                <li className="list check">
                                                  {" "}
                                                  {item.available}
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              <li className="list pet">
                                                {" "}
                                                {item.pets === "True"
                                                  ? "Yes"
                                                  : "No"}{" "}
                                              </li>
                                              <li className="list car">
                                                {item.furnished === "True"
                                                  ? "Yes"
                                                  : "No"}
                                              </li>
                                              <li className="list wifi">
                                                {" "}
                                                {item.utilities === "True"
                                                  ? "Yes"
                                                  : "No"}
                                              </li>
                                            </ul>
                                            <div className="btn-blk">
                                              <a
                                                href={item.url}
                                                target="_blank"
                                                className="button"
                                              >
                                                {window.innerWidth >= 320 &&
                                                window.innerWidth <= 767
                                                  ? "View"
                                                  : "View this Accommodation"}
                                              </a>
                                              <Link
                                                to={{
                                                  pathname: "/furnished-finder",
                                                  state: {
                                                    stateName: this.state
                                                      .stateJob
                                                      ? this.state.stateJob
                                                      : "",
                                                    cityName: this.state.cityJob
                                                      ? this.state.cityJob
                                                      : "",
                                                  },
                                                }}
                                                className="read-more"
                                              >
                                                Find Similar Accommodation
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </Slider>
                                </div>
                              </div>
                            ) : (
                              <span className="no-data">
                                {this.state.propertiesInfoMsg}
                              </span>
                            )
                          ) : (
                            <div className="list-job pb-50 loader-center">
                              {" "}
                              <img
                                src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                                alt="No Image"
                                className="loader-center"
                                style={{ width: 80, height: 80 }}
                              />{" "}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="btn-section">
                        <Link
                          to={`/apply-job/${job._id}`}
                          className="button job-detail-apply"
                        >
                          Apply Now
                        </Link>
                        <a
                          href="#"
                          className="popup_link"
                          onClick={this.handleUserPopup.bind(this)}
                        >
                          Ask a question about this job
                        </a>
                      </div>
                    </Col>

                    <Col
                      xs="3"
                      className="right-block side-bar details_content_block"
                    >
                      {/* {this.state.workType == "3" && (
                        <div className="gif-img-grid">
                          <figure>
                            <img src={GifImg} alt="" />
                          </figure>
                        </div>
                      )} */}
                      {this.state.workType == "3" && (
                        <>
                          <Row className="justify-content-center w-100">
                            <Col md="12">
                              <a href="https://apps.apple.com/us/app/travel-nurses-inc/id1637482608">
                                <figure>
                                  <img
                                    src={AppStore}
                                    alt="app-store"
                                    className="img-fluid"
                                  />
                                </figure>
                              </a>
                            </Col>
                            <Col md="12">
                              <a href="https://play.google.com/store/apps/details?id=com.travelnurses&pli=1">
                                <figure>
                                  <img
                                    src={GoogleStore}
                                    alt="app-store"
                                    className="img-fluid"
                                  />
                                </figure>
                              </a>
                            </Col>
                          </Row>
                          {/* <ul className="app-store">
                          
                          <li>
                          <a href='https://apps.apple.com/us/app/travel-nurses-inc/id1637482608'>
                            <figure>
                              <img src={AppStore} alt='' />
                            </figure>
                          </a>
                        </li>
                        <li>
                          <a href='https://play.google.com/store/apps/details?id=com.travelnurses&pli=1'>
                            <figure>
                              <img src={GoogleStore} alt='' />
                            </figure>
                          </a>
                        </li> 
                        </ul>
                      */}
                        </>
                      )}
                      <div className="clearfix"></div>
                      {this.state.workType == "1" && weather ? (
                        <div className="weather-sec block">
                          <div className="weather">
                            <div>
                              <h4 className="weather-city">
                                {job.city},{job.state}
                                <span className="weather-city">Weather</span>
                              </h4>
                              <div className="text-left">
                                <span className="sessions">
                                  <img
                                    src={`../weather-icons/${weather.weatherData[0].weather.icon}.png`}
                                    alt={job.city + " - Weather"}
                                  />
                                </span>
                                <span className="weather-degrees">
                                  {weather.weatherData[0].temp}
                                  <sup>o</sup>F
                                  <em>{weather.weatherData[0].weather.main}</em>
                                </span>
                              </div>
                            </div>
                            <div className="day-list"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.workType == "1" && events.length > 0 ? (
                        <div className="event-sec events block">
                          <div className="head-title text-center">Events</div>
                          <Slider {...settings}>
                            {events.map((event, index) => {
                              return (
                                <item key={index}>
                                  <Card className="content text-left">
                                    <figure className="pic image-fill">
                                      <img
                                        src={event.image}
                                        alt={event.name + " - " + index}
                                      />
                                    </figure>
                                    <CardBody className="px-4">
                                      <span className="date">
                                        {event.startDate && event.timezone
                                          ? moment
                                              .tz(
                                                event.startDate,
                                                event.timezone
                                              )
                                              .format("lll")
                                          : event.startDate
                                          ? moment(event.startDate).format("ll")
                                          : ""}
                                      </span>
                                      <CardTitle>{event.name}</CardTitle>
                                      <ul className="location">
                                        <li>
                                          {event.venue.name},<br />
                                          {event.venue.city}, {""}
                                          {event.venue.state.stateCode}
                                        </li>
                                      </ul>
                                      {/* <em>Venue Details</em> */}
                                      <CardText>
                                        {window.innerWidth >= 320 &&
                                        window.innerWidth <= 767 ? (
                                          ""
                                        ) : event.info ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                event.info.substring(0, 50) +
                                                "  ...",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <a href={event.url} target="_blank">
                                          {" "}
                                          More
                                        </a>
                                      </CardText>
                                    </CardBody>
                                  </Card>
                                </item>
                              );
                            })}
                          </Slider>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.workType == "1" && (
                        <>
                          <Row className="justify-content-center w-100">
                            <Col md="12">
                              <a href="https://apps.apple.com/us/app/travel-nurses-inc/id1637482608">
                                <figure>
                                  <img
                                    src={AppStore}
                                    alt="app-store"
                                    className="img-fluid"
                                  />
                                </figure>
                              </a>
                            </Col>
                            <Col md="12">
                              <a href="https://play.google.com/store/apps/details?id=com.travelnurses&pli=1">
                                <figure>
                                  <img
                                    src={GoogleStore}
                                    alt="app-store"
                                    className="img-fluid"
                                  />
                                </figure>
                              </a>
                            </Col>
                          </Row>
                          {/* <ul className="app-store">
                          <li>
                            <a href="https://apps.apple.com/us/app/travel-nurses-inc/id1637482608">
                              <figure>
                                <img src={AppStore} alt="" />
                              </figure>
                            </a>
                          </li>
                          <li>
                            <a href="https://play.google.com/store/apps/details?id=com.travelnurses&pli=1">
                              <figure>
                                <img src={GoogleStore} alt="" />
                              </figure>
                            </a>
                          </li>
                        </ul> */}
                        </>
                      )}

                      <TeamBlock />
                    </Col>
                  </Row>
                )}
                {/* Jobs List Block End Here */}
              </div>

              <RelatedJobs
                speciality={job.jobSpeciality}
                jobHistory={this.props}
                specialityCallback={this.specialityCallback1}
                workType={job.workType}
              />
            </Container>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default JobDetails;
