import React from "react";
 
import Slider from "react-slick";
import { Accordion, Card } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import ArrowIcon from "../images/sortarrow.png";

import axios from "axios";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";

class AwardNominee extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      nomineefirstName: "",
      nomineelastName: "",
      nomineefacilityName: "",
      nomineeDesc: "",
      nomineeFile: "",
      nursefirstName: "",
      nursefirstName: "",
      nurselastName: "",
      nurseEmail: "",
      nursePhone: "",
      relationWithNominee: "",
      nomineefirstNameErr: "",
      nomineelastNameErr: "",
      nomineefacilityNameErr: "",
      nursefirstNameErr: "",
      nurselastNameErr: "",
      nurseEmailErr: "",
      nursePhoneErr: "",
      relationWithNomineeErr: "",
      nomineefirstNameErrFlag: false,
      nomineelastNameErrFlag: false,
      nomineefacilityNameErrFlag: false,
      nursefirstNameErrFlag: false,
      nurselastNameErrFlag: false,
      nurseEmailErrFlag: false,
      nursePhoneErrFlag: false,
      relationWithNomineeErrFlag: false,
      isOpen: false,
      isLoading: false,
      dropdown: [],
      faqList: [],
      activeIndex: null,
    };

    this.handleInputsChange = this.handleInputsChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  componentDidMount() {
    axios.get(AppConstants.API + "/awardNominee/getContent").then((res) => {
      this.setState({
        dropdown: res && res.data && res.data.relationDropdown,
        faqList: res && res.data && res.data.faqList,
      });
    });
  }
  handleToggle = (index) => {
    this.setState({
      activeIndex: index === this.state.activeIndex ? null : index,
    });
  };

  handleInputsChange(e) {
    this.setState({
      nomineefirstNameErr: "",
      nomineelastNameErr: "",
      nomineefacilityNameErr: "",
      nursefirstNameErr: "",
      nurselastNameErr: "",
      nurseEmailErr: "",
      nursePhoneErr: "",
      relationWithNomineeErr: "",
      nomineefirstNameErrFlag: false,
      nomineelastNameErrFlag: false,
      nomineefacilityNameErrFlag: false,
      nursefirstNameErrFlag: false,
      nurselastNameErrFlag: false,
      nurseEmailErrFlag: false,
      nursePhoneErrFlag: false,
      relationWithNomineeErrFlag: false,
    });

    this.setState({ [e.target.name]: e.target.value });
  }

  handleFileChange(e) {
    this.setState({ nomineeFile: e.target.files[0] });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.nomineefirstName) {
      formIsValid = false;
      this.setState({
        nomineefirstNameErr: "Please enter nominee first name.",
        nomineefirstNameErrFlag: true,
      });
    }
    if (!this.state.nomineelastName) {
      formIsValid = false;
      this.setState({
        nomineelastNameErr: "Please enter nominee last name.",
        nomineelastNameErrFlag: true,
      });
    }
    if (!this.state.nomineefacilityName) {
      formIsValid = false;
      this.setState({
        nomineefacilityNameErr: "Please enter nominee last name.",
        nomineefacilityNameErrFlag: true,
      });
    }
    if (!this.state.nursefirstName) {
      formIsValid = false;
      this.setState({
        nursefirstNameErr: "Please enter your first name.",
        nursefirstNameErrFlag: true,
      });
    }
    if (!this.state.nursefirstName) {
      formIsValid = false;
      this.setState({
        nursefirstNameErr: "Please enter your last name.",
        nursefirstNameErrFlag: true,
      });
    }
    if (!this.state.nurseEmail) {
      formIsValid = false;
      this.setState({
        nurseEmailErr: "Please enter your email.",
        nurseEmailErrFlag: true,
      });
    }

    if (!this.state.nursePhone) {
      formIsValid = false;
      this.setState({
        nursePhoneErr: "Please enter your phone number.",
        nursePhoneErrFlag: true,
      });
    }
    if (this.state.nursePhone && this.state.nursePhone.length !== 10) {
      this.setState({
        nursePhoneErr: "Please enter your phone number.",
        nursePhoneErrFlag: true,
      });
    }

    if (!this.state.relationWithNominee) {
      formIsValid = false;
      this.setState({
        relationWithNomineeErr: "Please select relation with nominee.",
        relationWithNomineeErrFlag: true,
      });
    }

    return formIsValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      let formDataSet = new FormData();
      formDataSet.append("nomineeFirstName", this.state.nomineefirstName);
      formDataSet.append("nomineeLastName", this.state.nomineelastName);
      formDataSet.append("nomineeFacilityName", this.state.nomineefacilityName);
      formDataSet.append("nomineeDesc", this.state.nomineeDesc);
      formDataSet.append("nomineeStroyFile", this.state.nomineeFile);
      formDataSet.append("nurseFirstName", this.state.nursefirstName);
      formDataSet.append("nurseLastName", this.state.nurselastName);
      formDataSet.append("nursePhone", this.state.nursePhone);
      formDataSet.append("nurseEmail", this.state.nurseEmail);
      formDataSet.append("relationWithNominee", this.state.relationWithNominee);
      axios
        .post(AppConstants.API + "/awardNominee/submitNominee", formDataSet)
        .then((res) => {
          console.log("res", res);
          if (res.data.Status == "Success") {
            this.setState({ isLoading: false });
            NotificationManager.success(res.data.message, "", 3000);
          } else {
            this.setState({ isLoading: false });
            NotificationManager.error(res.data.message, "", 3000);
          }
          this.setState({
            nomineefirstName: "",
            nomineelastName: "",
            nomineefacilityName: "",
            nomineeDesc: "",
            nomineeFile: "",
            nursefirstName: "",
            nursefirstName: "",
            nurselastName: "",
            nurseEmail: "",
            nursePhone: "",
          });
        });
    }
  };
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992, // Adjust the breakpoint value as needed
          settings: {
            slidesToShow: 4, // Number of slides to show at this breakpoint
            slidesToScroll: 1, // Number of slides to scroll at this breakpoint
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // Add more breakpoints and settings as necessary
      ],
    };

    return (
      <>
        <section className="award-banner">
          <div className="award-banner-layer">
            <div className="container">
              <div className="row">
                <aside className="col-md-9 award-banner-left">
                  <figure className="mbl-banner-img">
                    <img
           
                      src={`${AppConstants.Bucket_URL}/award-nominee/award-banner-img.png`}
                      alt=""
                    />
                  </figure>
                  <div className="cont-grid-area">
                    <h2>DAISY Award Nominations</h2>

                    <figure className="desk-banner-logo">
                      <img
             
                        src={`${AppConstants.Bucket_URL}/award-nominee/award-banner-logo.png`}
                        alt=""
                      />
                    </figure>
                    <figure className="mbl-banner-logo">
                      <img
                   
                        src={`${AppConstants.Bucket_URL}/award-nominee/award-banner-logo-white.png`}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="my-3">
                    <p style={{ color: "#25408f" }}>
                      Honor a nurse for their extraordinary achievement!
                    </p>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container">
            <p>
              Travel Nurses Inc. is honored to collaborate with The DAISY
              Foundation, a renowned organization dedicated to recognizing
              exceptional nurses. Through this partnership, we celebrate
              presenting The DAISY Award, which acknowledges nurses who embody
              the very essence of Travel Nurses, Inc's mission: delivering
              extraordinary patient care. Together, we strive to honor these
              outstanding healthcare professionals and their unwavering
              commitment to making a positive impact on patients' lives.
            </p>
            <p>
              Do you know a remarkable travel nurse who perfectly embodies the
              qualities we seek? Whether you're a patient, recruiter, or
              co-worker, we wholeheartedly encourage you to recognize and
              nominate an exceptional Travel Nurses Inc. nurse for the
              prestigious DAISY Award. By completing the simple nomination form,
              you can play a vital role in honoring these extraordinary
              healthcare professionals who consistently go above and beyond in
              their dedication to delivering exceptional patient care. Your
              participation allows us to acknowledge and celebrate their
              outstanding contributions to the field of nursing.
            </p>
            <h3>Nominee Criteria:</h3>
            <p>Must be a current or former Travel Nurses, Inc. nurse</p>
            <div className="row">
              <ul className="col-md-6">
                <li>
                  Shows empathy and consistently showcases a caring demeanor
                </li>
                <li>
                  Displays exceptional clinical expertise while providing
                  compassionate patient care
                </li>
              </ul>
              <ul className="col-md-6">
                <li>
                  Goes above and beyond to make a difference in a patient’s life
                </li>
                <li>
                  Embodies the core values of Travel Nurses, nc.: Integrity,
                  Service, and Excellence
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="nominee-form">
          <div className="container">
            <div className="form-block">
              <h2>Know a Travel Nurse who fits the bill?</h2>
              <div className="row">
                <Form>
                  <div className="row">
                    <FormGroup className="col-md-6">
                      <Label for="Name">
                        Nominee's First Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="name"
                        name="nomineefirstName"
                        ref="nomineefirstName"
                        id="exampleEmail"
                        placeholder="Nominee's First Name"
                        value={this.state.nomineefirstName}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nomineefirstNameErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nomineefirstNameErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label for="Name">
                        Nominee's Last Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="name"
                        name="nomineelastName"
                        ref="nomineelastName"
                        id="exampleEmail"
                        placeholder="Nominee's Last Name"
                        value={this.state.nomineelastName}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nomineelastNameErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nomineelastNameErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-12">
                      <Label for="Name">
                        Facility Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="name"
                        name="nomineefacilityName"
                        ref="nomineefacilityName"
                        id="exampleEmail"
                        placeholder="Facility Name"
                        value={this.state.nomineefacilityName}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nomineefacilityNameErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nomineefacilityNameErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-12">
                      <Label for="exampleText">
                        Please include a minimum of 50 words explaining your
                        nomination.
                      </Label>
                      <Input
                        type="textarea"
                        onChange={this.handleInputsChange}
                        name="nomineeDesc"
                        id="exampleText"
                        placeholder="Tell us why you are nominating this person or upload your nomination story below."
                      />
                    </FormGroup>
                    <span>Upload Nomination Story</span>
                    <FormGroup className="col-md-12 select-field-grid">
                      <Input
                        type="file"
                        name="nomineeFile"
                        id="exampleFile"
                        onChange={this.handleFileChange}
                      />
                      <FormText color="muted">Max. file size: 50 MB.</FormText>
                    </FormGroup>

                    <h2>About You</h2>
                    <FormGroup className="col-md-6">
                      <Label for="Name">
                        Your First Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="name"
                        name="nursefirstName"
                        ref="nursefirstName"
                        id="exampleEmail"
                        placeholder="Your First Name"
                        value={this.state.nursefirstName}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nursefirstNameErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nursefirstNameErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label for="Name">Your Last Name</Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        type="name"
                        name="nurselastName"
                        ref="nurselastName"
                        id="exampleEmail"
                        placeholder="Your Last Name"
                        value={this.state.nurselastName}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nurselastNameErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nurselastNameErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label for="Name">Your Email</Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        type="email"
                        name="nurseEmail"
                        id="exampleEmail"
                        ref="nurseEmail"
                        placeholder="Your Email"
                        value={this.state.nurseEmail}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nurseEmailErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nurseEmailErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label for="Name">Your Phone Number</Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        type="name"
                        name="nursePhone"
                        id="exampleEmail"
                        ref="nursePhone"
                        placeholder="Your Phone Number"
                        value={this.state.nursePhone}
                        onChange={this.handleInputsChange}
                      />
                      {this.state.nursePhoneErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.nursePhoneErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-12">
                      <Label for="exampleSelect">
                        Your Relationship to your Nominee
                      </Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <img src={ArrowIcon} alt="" />
                      <Input
                        type="select"
                        name="relationWithNominee"
                        ref="relationWithNominee"
                        id="exampleSelect"
                        onChange={this.handleInputsChange}
                        value={this.state.relationWithNominee}
                      >
                        <option value="">Select Any</option>
                        {this.state.dropdown &&
                          this.state.dropdown.length > 0 &&
                          this.state.dropdown.map((item) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })}
                      </Input>
                      {this.state.staterelationWithNomineeErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.staterelationWithNomineeErr}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.relationWithNomineeErrFlag === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.relationWithNomineeErr}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <div className="form-bttn-grid">
                      <a
                        href="javascript:void(0)"
                        className="blue-bttn"
                        onClick={this.handleSubmit}

                      >
                        Submit
                        {this.state.isLoading ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <section className="honorees-slider">
          <div className="container">
            <h2>Our Honorees</h2>
            <Slider {...settings}>
              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg1}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-1.png`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg2}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-2.png`}
                    alt=""
                  />
                </figure>
              </div>

              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg3}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-3.png`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg4}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-4.png`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg5}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-5.png`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="honorees-slider-block">
                <figure>
                  <img
                    // src={SliderImg6}
                    src={`${AppConstants.Bucket_URL}/award-nominee/honorees-slider-img-6.png`}
                    alt=""
                  />
                </figure>
              </div>
            </Slider>
          </div>
        </section>
        <section className="faq-sec">
          <div className="container">
            <h2>FAQs</h2>
            <Accordion>
              {this.state.faqList &&
                this.state.faqList.length > 0 &&
                this.state.faqList.map((item, index) => {
                  return (
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => this.handleToggle(index)}
                        className={`toggle-${index} ${index === this.state.activeIndex
                          ? "active-toggle"
                          : ""
                          }`}
                        eventKey={(index - 1).toString()}
                        dangerouslySetInnerHTML={{ __html: item.question }}
                      />
                      <Accordion.Collapse eventKey={(index - 1).toString()}>
                        <Card.Body>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
            </Accordion>
          </div>
        </section>
      </>
    );
  }
}
export default AwardNominee;
