import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../Styles/banner.css";
import { Row, Col } from "reactstrap";
import AppConstants from "../AppConstants";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import LazyLoad from 'react-lazyload';
class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nurses: [],
      destinations: [],
      company_alert: "",
      url: "",
      alternateUrlName: "",
      nursesResponsive: [],
      destinationsResponsive: [],
    };
  }
  componentDidMount() {
    axios
      .get(AppConstants.CMS_API + "/covidAlert/getAllCovidAlertsFrontEnd")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success" && data.data[0]) {
          this.setState({
            company_alert: data.data[0].covid_alert,
            url: data.data[0].url,
            alternateUrlName: data.data[0].alternateUrlName
          });
        }
      });
    if (
      (this.props,
      window.innerWidth >= 320 && this.props,
      window.innerWidth <= 767)
    ) {
      axios
        .get(
          AppConstants.CMS_API +
            "/bannerImages/randomNursesBannerImagesForResponsive"
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.data) {
            this.setState({
              // nursesResponsive: data.data,
              nurses: data.data,
            });
          }
        });
      axios
        .get(
          AppConstants.CMS_API +
            "/bannerImages/randomDestinationBannerImagesForResponsive"
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.data) {
            this.setState({
              // destinationsResponsive: data.data,
              destinations: data.data,
            });
          }
        });
    } else {
      axios
        .get(
          AppConstants.CMS_API + "/bannerImages/randomDestinationBannerImages"
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.data) {
            this.setState({
              destinations: data.data,
            });
          }
        });
      axios
        .get(AppConstants.CMS_API + "/bannerImages/randomNursesBannerImages")
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.data) {
            this.setState({
              nurses: data.data,
            });
          }
        });
    }
  }
  render() {
    var banner = [];
    this.state.nurses.map((item, i) =>
      this.state.destinations[i] !== undefined
        ? i >= 3
          ? banner.push(this.state.destinations[i], item)
          : banner.push(item, this.state.destinations[i])
        : banner.push(item, "")
    );
    const settings = {
      dots: false,
      arrow: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="banner">
        {/* <Slider {...settings}> */}
        <div>
          <Row>
            {banner.map((item, index) => (
              <Col xs="2" className="pic-box">
                <LazyLoad className="pic image-fill" >
                  <img
                    src={item.bannerImage}
                    alt={item.category + " - " + index + " - Travel Nurses Inc"}
                  />
                </LazyLoad>
              </Col>
            ))}
          </Row>
          <div className="banner-text">
            <div className="banner-title">
              {this.props.location.pathname === "/lpn-orlando-job" ||
              this.props.match.path === "/travel-nurse-job/:job"
                ? this.props.match.params.job
                  ? this.props.match.params.job
                  : AppConstants.vaccination_text
                : AppConstants.pick_dest}
            </div>
          </div>
          {this.state.company_alert ? (
            <div className="coivd-alert">
              <marquee>
                 <div
                  dangerouslySetInnerHTML={{ __html: this.state.company_alert }}
                />
              </marquee>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
SimpleSlider.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(withRouter(SimpleSlider));
