import React from "react";

function withMousePosition(WrappedComponent) {
  return class extends React.Component {
    state = {};

    componentDidMount() {
      window.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
      window.removeEventListener("mousemove", this.handleMouseMove);
    }

    handleMouseMove = e => {
      this.setState({
        x: e.pageX,
        y: e.pageY
      });
    };

    render() {
      return <WrappedComponent mousePosition={this.state} {...this.props} />;
    }
  };
}

export default withMousePosition;
