import React, { useState } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";

const Accordion = ({ items }) => {
  const [isActivePanel, setActivePanel] = useState({});
  const toggleItem = (index) => {
    setActivePanel((prevState) => ({
      ...prevState,
      [index]: !Boolean(prevState[index]),
    }));
  };
  return (
    <div>
      {items.map((item, index) => {
        const checkOpen = isActivePanel[index];
        return (
          <div className="TN-accordion" key={item.title}>
            <Button
              // className="w-100 text-left py-3  i-open"
              className={`pl-3 w-100 text-left py-3  ${
                checkOpen ? "i-open" : "i-close"
              }`}
              onClick={() => toggleItem(index)}
            >
              <span className="pr-3">{item.question}</span>
              {/* <span className="pr-3">{item.title}</span> */}
              
              {/* <span className="TN-accordion__icon">
                {checkOpen ? "-" : "+"}{" "}
              </span> */}
            </Button>
            <Collapse className="mx-1" isOpen={checkOpen}>
              <Card>
                <CardBody>
                  {/* {item.description} */}
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};
export default Accordion;
