import React from "react";
import { Form, Modal, ModalHeader, ModalBody, CardBody } from "reactstrap";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

class TimesheetPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.handleTimesheetPopup();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.popup}
        toggle={this.props.handleTimesheetPopup}
        className="modal-lg contact-rect recruiter-modal request-job-sec-modal submit-form"
        id="Tn-popup"
        style={{ color: "#25408f", fontWeight: "500" }}
      >
        <ModalHeader
          toggle={this.handleCancel}
          className="text-center pt-4 pb-1"
          style={{ color: "#25408f" }}
        ></ModalHeader>
        <ModalBody className="p-0">
          <div className="apply-form registr-info main-page">
            <Card
              className="w-100 p-0 m-0 md-pop pb-5"
              style={{ borderRadius: "0px 0px 25px 25px" }}
            >
              <CardBody>
                <Form className="inner-block">
                  <div>{this.props.message}</div>
                  <span style={{ textAlign: "right", display: "block" }}>
                    <a
                      value="Submit"
                      className="blue-bttn"
                      href="javascript: void(0)"
                      style={{ backgroundColor: "#334ca4", color: "#fff" }}
                      onClick={this.handleSubmit}
                    >
                      Ok
                    </a>
                  </span>
                </Form>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default TimesheetPopup;
