import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Collapse,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
 
import CompletedIcon from "../images/completed-icon.png";
import DatePicker from "react-datepicker";
import axios from "axios";
import AppConstants from "../AppConstants";
import { NotificationManager } from "react-notifications";
import swal from "sweetalert";
import { FaTrashAlt, FaPen } from "react-icons/fa";
 

export default class WorkSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleWork: [
        {
          facility_name: "",
          startDate: "",
          endDate: "",
          street_address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          job_title: "",
          unit: "",
          unit_beds: "",
          patient_ratio: "",
          description_of_duties: "",
          Reason_for_Leaving: "",
          Supervisors_Name: "",
          Supervisors_Title: "",
          Work_Phone: "",
          Employment_Type: "",
          Agency_Name: "",
          Teaching_Facility: "",
          Charge_Experience: "",
          May_we_contact_this_employer: "",
          _id: "",
          ctmsWorkHistoryId: "",
        },
      ],
      wrkItem: {
        wrkItemId: "",
        facility_name: "",
        startDate: "",
        endDate: "",
        street_address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        job_title: "",
        unit: "",
        unit_beds: "",
        patient_ratio: "",
        description_of_duties: "",
        Reason_for_Leaving: "",
        Supervisors_Name: "",
        Supervisors_Title: "",
        Work_Phone: "",
        Employment_Type: "",
        Agency_Name: "",
        Teaching_Facility: "",
        Charge_Experience: "",
        May_we_contact_this_employer: "",
        ctmsWorkHistoryId: "",
      },
      empTypes: [],
      states: [],
      countrys: [],
      errors: [],
      errMsg: {
        startDate: false,
        facility_name: false,
        city: false,
        country: false,
        state: false,
        job_title: false,
      },
      editStatus: false,
      index: "",
      modal: false,
      modalType: "",
      wrk: [],
      button: false,
      loader: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      userId: localStorage.getItem("id") ? localStorage.getItem("id") : "",
    };
  }

  componentDidMount() {
    this.handleExperianceListAPI();
    this.getCountrysListAPI();
    this.getEmpTypesList();
  }

  getEmpTypesList = async () => {
    const _this = this;
    const token = localStorage.getItem("token");
    var config = {
      method: "GET",
      url: AppConstants.API + "/nurse/appDropdownsList",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.data.Status === "Success") {
          _this.setState({
            empTypes:
              response.data &&
              response.data.data[0] &&
              response.data.data[0].Employment_Type,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleExperianceListAPI = async () => {
    const userId = localStorage.getItem("id");
    const that = this;
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: AppConstants.API + "/nurse/getNurse/" + userId,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          if (
            response.data &&
            response.data.data &&
            response.data.data.work_history.length > 0
          ) {
            var multipleWork1 = [];
            response.data.data.work_history.map((item, index) => {
              var exp = {};
              if (item.isActive == true) {
                exp["facility_name"] = item.facility_name;
                if (item.startDate) {
                  exp["startDate"] = new Date(item.startDate);
                } else {
                  exp["startDate"] = "";
                }
                if (item.endDate) {
                  exp["endDate"] = new Date(item.endDate);
                } else {
                  exp["endDate"] = "";
                }
                exp["street_address"] = item.street_address
                  ? item.street_address
                  : "";
                exp["city"] = item.city ? item.city : "";
                exp["state"] = item.state ? item.state : "";
                exp["postal_code"] = item.postal_code ? item.postal_code : "";
                exp["country"] = item.country ? item.country : "";
                exp["job_title"] = item.job_title ? item.job_title : "";
                exp["unit"] = item.unit ? item.unit : "";
                exp["unit_beds"] = item.unit_beds ? item.unit_beds : "";
                exp["patient_ratio"] = item.patient_ratio
                  ? item.patient_ratio
                  : "";
                exp["description_of_duties"] = item.description_of_duties
                  ? item.description_of_duties
                  : "";
                exp["Reason_for_Leaving"] = item.Reason_for_Leaving
                  ? item.Reason_for_Leaving
                  : "";
                exp["Supervisors_Name"] = item.Supervisors_Name
                  ? item.Supervisors_Name
                  : "";
                exp["Supervisors_Title"] = item.Supervisors_Title
                  ? item.Supervisors_Title
                  : "";
                exp["Work_Phone"] = item.Work_Phone ? item.Work_Phone : "";
                exp["Employment_Type"] = item.Employment_Type
                  ? item.Employment_Type
                  : "";
                exp["Agency_Name"] = item.Agency_Name ? item.Agency_Name : "";
                exp["Teaching_Facility"] = item.Teaching_Facility
                  ? item.Teaching_Facility
                  : "";
                exp["Charge_Experience"] = item.Charge_Experience
                  ? item.Charge_Experience
                  : "";
                exp["May_we_contact_this_employer"] =
                  item.May_we_contact_this_employer
                    ? item.May_we_contact_this_employer
                    : "";
                exp["_id"] = item._id;
                exp["ctmsWorkHistoryId"] = item.ctmsWorkHistoryId;
                multipleWork1.push(exp);
                that.setState({
                  multipleWork:
                    item.isActive == true
                      ? multipleWork1
                      : that.state.multipleWork,
                  wrk: multipleWork1,
                });
              }
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getStatesListAPI = async (code) => {
    const _this = this;
    let countryCode;
    if (code === "Canada") {
      countryCode = 38;
    } else if (code === "Australia") {
      countryCode = 13;
    } else if (code === "United States") {
      countryCode = 231;
    }
    var data = JSON.stringify({
      countryCode: countryCode,
    });
    var config = {
      method: "POST",
      url: AppConstants.Jobs_API + "/jobs/getStatesBasedOnCountry",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        // console.log("==========", response)
        if (response.data.Status === "Success") {
          _this.setState({
            states: response.data && response.data.data && response.data.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getCountrysListAPI = async () => {
    const _this = this;
    var config = {
      method: "get",
      url: AppConstants.Jobs_API + "/jobs/getAllCountries",
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        if (response.status == "200") {
          var cou = response.data && response.data.data && response.data.data;
          cou.unshift({
            countryCode: 231,
            name: "United States",
            phoneCode: 1,
            _id: "5bcefd25ab144c59602b95b9",
          });

          _this.setState({
            countrys: cou,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleStartDate = (event) => {
    this.setState({
      wrkItem: { ...this.state.wrkItem, startDate: event },
    });
  };

  handleEndDate = (event) => {
    this.setState({
      wrkItem: { ...this.state.wrkItem, endDate: event },
    });
  };

  handleChange = (event) => {
    if (
      event.target.value == "Canada" ||
      event.target.value == "Australia" ||
      event.target.value == "United States"
    ) {
      this.getStatesListAPI(event.target.value);
    }
    this.setState({
      wrkItem: {
        ...this.state.wrkItem,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleExpValidation() {
    let errors = [];
    let formIsValid = true;
    if (!this.state.wrkItem.job_title) {
      formIsValid = false;
      errors["job_title"] = "Please enter your job title. ";
    }
    if (!this.state.wrkItem.startDate) {
      formIsValid = false;
      errors["startDate"] = " Please select a start date.";
    }
    if (!this.state.wrkItem.facility_name) {
      formIsValid = false;
      errors["facility_name"] = "Please enter facility name.";
    }
    if (!this.state.wrkItem.city) {
      formIsValid = false;
      errors["city"] = "Please enter your city.";
    }
    if (!this.state.wrkItem.country) {
      formIsValid = false;
      errors["country"] = "Please enter your country.";
    }
    this.setState({
      errors: errors,
      errMsg: {
        ...this.state.errMsg,
        startDate: true,
        facility_name: true,
        city: true,
        country: true,
        job_title: true,
      },
    });
    return formIsValid;
  }

  createWorkUI() {
    return (
      <div className="education-tab">
        <section className="add-form-block row">
          {this.state.multipleWork && this.state.multipleWork.length > 0
            ? this.state.multipleWork.map((item, i) => {
              return (
                <div className="form-block col-md-4" key={i}>
                  {item._id && (
                    <div className="form-block-in">
                      <figure>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => this.editToggle(e, item)}
                          className="edit"
                        >
                          <FaPen />
                        </a>

                        {this.state.multipleWork.length > 1 ? (
                          <a
                            href="javascript:void()"
                            className="delete"
                            onClick={(e) => this.removeWorkItem(e, item._id)}
                          >
                            <FaTrashAlt />
                          </a>
                        ) : null}
                      </figure>
                      <h2>{item.job_title}</h2>
                      <span>{item.city}</span>
                      <br />
                      <span>{item.state}</span>
                      <br />
                      <span>{item.country}</span>
                    </div>
                  )}
                </div>
              );
            })
            : "null"}{" "}
        </section>
      </div>
    );
  }

  removeWorkItem = (e, itemId) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // swal("Work item has been deleted!", {
        //   icon: "success",
        // });
        if (itemId) {
          const that = this;
          var config = {
            method: "post",
            url:
              AppConstants.API +
              "/nurse/deleteProfileSection/" +
              this.state.userId,
            headers: {
              "Content-Type": "application/json",
              Authorization: this.state.token,
            },
            data: JSON.stringify({
              recordId: itemId,
              section_name: "work_history",
            }),
          };
          axios(config)
            .then(function (response) {
              that.handleExperianceListAPI();
              that.props.getProfilePersentage();
              if (that.state.modal) {
                that.setState({ loader: false, modal: !that.state.modal });
              }
              that.setState({ loader: false });
              if (response.data.Status === "Success") {
                NotificationManager.success(response.data.msg, "", 5000);
              } else {
                NotificationManager.error(response.data.msg, "", 5000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          if (this.state.modal) {
            this.setState({ loader: false });
          }
          this.setState({ loader: false, modal: !this.state.modal });
        }
      }
      this.setState({ loader: false });
    });
  };

  handleWorkExpOnSubmit = (e, type) => {
    const that = this;
    this.props.setEditStatusEdu2(true);
    const userId = localStorage.getItem("id");
    let tempId = localStorage.getItem("tempId");
    e.preventDefault();
    if (type === "save") {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "work_history",
          work_history: [
            {
              facility_name: this.state.wrkItem.facility_name,
              startDate: this.state.wrkItem.startDate,
              endDate: this.state.wrkItem.endDate,
              street_address: this.state.wrkItem.street_address,
              city: this.state.wrkItem.city,
              state: this.state.wrkItem.state,
              country: this.state.wrkItem.country,
              job_title: this.state.wrkItem.job_title,
              unit: this.state.wrkItem.unit,
              unit_beds: this.state.wrkItem.unit_beds,
              patient_ratio: this.state.wrkItem.patient_ratio,
              description_of_duties: this.state.wrkItem.description_of_duties,
              Reason_for_Leaving: this.state.wrkItem.Reason_for_Leaving,
              Supervisors_Name: this.state.wrkItem.Supervisors_Name,
              Supervisors_Title: this.state.wrkItem.Supervisors_Title,
              Work_Phone: this.state.wrkItem.Work_Phone,
              Employment_Type: this.state.wrkItem.Employment_Type,
              Agency_Name: this.state.wrkItem.Agency_Name,
              Teaching_Facility: this.state.wrkItem.Teaching_Facility,
              Charge_Experience: this.state.wrkItem.Charge_Experience,
              May_we_contact_this_employer:
                this.state.wrkItem.May_we_contact_this_employer,
              tempId: tempId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API +
            "/nurse/createProfileSection/" +
            that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleExperianceListAPI();
            that.props.getProfilePersentage();
            that.setState({
              loader: false,
              modal: !that.state.modal,
              modalType: "",
              button: false,
            });

            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      if (that.handleExpValidation()) {
        this.setState({ loader: !this.state.loader, button: true });
        var data = JSON.stringify({
          section_name: "work_history",
          work_history: [
            {
              _id: this.state.wrkItem.wrkItemId,
              facility_name: this.state.wrkItem.facility_name,
              startDate: this.state.wrkItem.startDate,
              endDate: this.state.wrkItem.endDate,
              street_address: this.state.wrkItem.street_address,
              city: this.state.wrkItem.city,
              state: this.state.wrkItem.state,
              country: this.state.wrkItem.country,
              job_title: this.state.wrkItem.job_title,
              unit: this.state.wrkItem.unit,
              unit_beds: this.state.wrkItem.unit_beds,
              patient_ratio: this.state.wrkItem.patient_ratio,
              description_of_duties: this.state.wrkItem.description_of_duties,
              Reason_for_Leaving: this.state.wrkItem.Reason_for_Leaving,
              Supervisors_Name: this.state.wrkItem.Supervisors_Name,
              Supervisors_Title: this.state.wrkItem.Supervisors_Title,
              Work_Phone: this.state.wrkItem.Work_Phone,
              Employment_Type: this.state.wrkItem.Employment_Type,
              Agency_Name: this.state.wrkItem.Agency_Name,
              Teaching_Facility: this.state.wrkItem.Teaching_Facility,
              Charge_Experience: this.state.wrkItem.Charge_Experience,
              May_we_contact_this_employer:
                this.state.wrkItem.May_we_contact_this_employer,
              tempId: tempId,
              ctmsWorkHistoryId: this.state.wrkItem.ctmsWorkHistoryId,
            },
          ],
        });
        var config = {
          method: "post",
          url:
            AppConstants.API + "/nurse/EditProfileSection/" + that.state.userId,
          headers: {
            "Content-Type": "application/json",
            Authorization: that.state.token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            that.handleExperianceListAPI();
            that.props.getProfilePersentage();
            that.setState({
              loader: false,
              modal: !that.state.modal,
              modalType: "",
              button: false,
            });

            if (response.data.Status === "Success") {
              // if (that.props.ackStatus.isAcknowledged) {
              //   that.props.setSignModal(true);
              // } else {
              NotificationManager.success(response.data.msg, "", 5000);
              // }
            } else {
              NotificationManager.error(response.data.msg, "", 5000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };
  handleClick = () => {
    this.props.HandleAccordion2("2");
  };

  addWokkExp = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "save" });
    this.setState({
      wrkItem: {
        ...this.state.wrkItem,
        facility_name: "",
        startDate: "",
        endDate: "",
        street_address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        job_title: "",
        unit: "",
        unit_beds: "",
        patient_ratio: "",
        description_of_duties: "",
        Reason_for_Leaving: "",
        Supervisors_Name: "",
        Supervisors_Title: "",
        Work_Phone: "",
        Employment_Type: "",
        Agency_Name: "",
        Teaching_Facility: "",
        Charge_Experience: "",
        May_we_contact_this_employer: "",
      },
    });
  };

  editToggle = (e, item) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "edit" });
    if (
      (item && item.country == "Canada") ||
      (item && item.country == "Australia") ||
      (item && item.country == "United States")
    ) {
      this.getStatesListAPI(item && item.country);
    }
    this.setState({
      wrkItem: {
        ...this.state.wrkItem,
        facility_name: item && item.facility_name,
        startDate: item && item.startDate,
        endDate: item && item.endDate,
        street_address: item && item.street_address,
        city: item && item.city,
        state: item && item.state,
        zip: item && item.zip,
        country: item && item.country,
        job_title: item && item.job_title,
        unit: item && item.unit,
        unit_beds: item && item.unit_beds,
        patient_ratio: item && item.patient_ratio,
        description_of_duties: item && item.description_of_duties,
        Reason_for_Leaving: item && item.Reason_for_Leaving,
        Supervisors_Name: item && item.Supervisors_Name,
        Supervisors_Title: item && item.Supervisors_Title,
        Work_Phone: item && item.Work_Phone,
        Employment_Type: item && item.Employment_Type,
        Agency_Name: item && item.Agency_Name,
        Teaching_Facility: item && item.Teaching_Facility,
        Charge_Experience: item && item.Charge_Experience,
        May_we_contact_this_employer: item && item.May_we_contact_this_employer,
        wrkItemId: item._id,
        ctmsWorkHistoryId: item.ctmsWorkHistoryId,
      },
    });
  };

  handleAckPop(type) {
    if (type === "edit") {
      this.props.toggle();
    }
  }

  cancel = (e) => {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, modalType: "" });
    this.setState({
      errors: [],
      errMsg: {
        ...this.state.errMsg,
        startDate: false,
        facility_name: false,
        city: false,
        country: false,
        job_title: false,
      },
    });
  };

  render() {
    // console.log("wrkItem", this.state.wrkItem)
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={(e) => this.cancel(e)}
          className="modal-md sign-save-popup profile-form-popup"
          id="Tn-popup"
          style={{ color: "#25408f", fontWeight: "500" }}
        >
          <div className="main-page">
            <ModalHeader
              toggle={(e) => this.cancel(e)}
              className="text-center pt-4 pb-1"
              style={{ color: "#25408f" }}
            ></ModalHeader>
            <ModalBody className="p-0">
              <div className="profile-form-block">
                <Row>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="major">
                        Facility Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="facility_name"
                        placeholder=" Facility Name"
                        value={
                          this.state.wrkItem.facility_name
                            ? this.state.wrkItem.facility_name
                            : ""
                        }
                        onChange={(e) => this.handleChange(e)}
                        onClick={this.handleAckPop(this.state.modalType)}
                      />
                      {this.state.errMsg.facility_name ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["facility_name"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup className="date-picker">
                      <Label>
                        Start Date: <span style={{ color: "red" }}>*</span>
                      </Label>
                      <DatePicker
                        name="startDate"
                        placeholderText="MM/DD/YYYY"
                        className="form-control"
                        selected={this.state.wrkItem.startDate}
                        onChange={this.handleStartDate.bind(this)}
                        onKeyDown={(e) => e.preventDefault()}
                        ref="startDate"
                        icon="iconsminds-calendar-4"
                      />
                      {this.state.errMsg.startDate ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["startDate"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="block">
                    <FormGroup className="date-picker">
                      <Label>
                        End Date (Leave blank it this is your current job)
                      </Label>
                      <DatePicker
                        placeholderText="MM/DD/YYYY"
                        name="endDate"
                        className="form-control"
                        selected={this.state.wrkItem.endDate}
                        onChange={this.handleEndDate.bind(this)}
                        autoComplete="off"
                        onKeyDown={(e) => e.preventDefault()}
                        ref="endDate"
                        icon="iconsminds-calendar-4"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="street_address">Street Address</Label>
                      <Input
                        type="text"
                        name="street_address"
                        placeholder="Street Address"
                        value={this.state.wrkItem.street_address}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="city">
                        City <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={this.state.wrkItem.city}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {this.state.errMsg.city ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["city"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="country">
                        Country <span style={{ color: "red" }}>*</span>
                      </Label>
                      <select
                        name="country"
                        className="form-control custom-dropdown"
                        placeholder="Country"
                        value={this.state.wrkItem.country}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value={""}>Select Country</option>
                        {this.state.countrys.map((item, idx) => (
                          <option value={item.name} key={idx}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {this.state.errMsg.country ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["country"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  {this.state.wrkItem.country == "Australia" ||
                    this.state.wrkItem.country == "Canada" ||
                    this.state.wrkItem.country == "United States" ? (
                    <Col sm="6" className="block">
                      <FormGroup>
                        <Label for="state">
                          State <span style={{ color: "red" }}>*</span>
                        </Label>
                        <select
                          name="state"
                          className="form-control custom-dropdown"
                          placeholder="State"
                          value={this.state.wrkItem.state}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value={""}>Select State</option>
                          {this.state.states.map((item, idx) => {
                            return (
                              <option value={item.code} key={idx}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="state">Postal Code </Label>
                      <Input
                        name="zip"
                        className="form-control"
                        placeholder="Postal Code"
                        value={this.state.wrkItem.zip}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="job_title">
                        Job Title <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="job_title"
                        placeholder="Job Title"
                        value={this.state.wrkItem.job_title}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {this.state.errMsg.job_title ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["job_title"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="unit">Unit</Label>
                      <Input
                        type="text"
                        name="unit"
                        placeholder="Unit"
                        value={this.state.wrkItem.unit}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="unit_beds">Unit Beds</Label>
                      <Input
                        type="text"
                        name="unit_beds"
                        placeholder="Unit Beds"
                        value={this.state.wrkItem.unit_beds}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="patient_ratio">Patient Ratio</Label>
                      <Input
                        type="text"
                        name="patient_ratio"
                        placeholder="Patient Ratio"
                        value={this.state.wrkItem.patient_ratio}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="description_of_duties">
                        Description of Duties (4000 Characters remaining)
                      </Label>
                      <Input
                        type="text"
                        name="description_of_duties"
                        placeholder="Description of Duties"
                        value={this.state.wrkItem.description_of_duties}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Reason_for_Leaving">
                        Reason for Leaving (4000 Characters remaining)
                      </Label>
                      <Input
                        type="text"
                        name="Reason_for_Leaving"
                        placeholder="Description of Duties"
                        value={this.state.wrkItem.Reason_for_Leaving}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Supervisors_Name">Supervisors Name</Label>
                      <Input
                        type="text"
                        name="Supervisors_Name"
                        placeholder="Supervisors  Name"
                        value={this.state.wrkItem.Supervisors_Name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Supervisors_Title">Supervisors Title</Label>
                      <Input
                        type="text"
                        name="Supervisors_Title"
                        placeholder="Supervisors Title"
                        value={this.state.wrkItem.Supervisors_Title}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Work_Phone">Work Phone</Label>
                      <Input
                        type="text"
                        name="Work_Phone"
                        placeholder="Work Phone"
                        value={this.state.wrkItem.Work_Phone}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Employment_Type">Employment Type</Label>
                      <select
                        name="Employment_Type"
                        className="form-control custom-dropdown"
                        placeholder="Employment Type"
                        value={this.state.wrkItem.Employment_Type}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value={""}>Select Employment Type</option>
                        {this.state.empTypes.map((item, idex) => {
                          return (
                            <option value={item.value} key={idex}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="block">
                    <FormGroup>
                      <Label for="Agency_Name">Agency Name</Label>
                      <Input
                        type="text"
                        name="Agency_Name"
                        placeholder="Agency Name"
                        value={this.state.wrkItem.Agency_Name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12" className="block check-box-block">
                    <FormGroup>
                      <Label for="Teaching_Facility">Teaching Facility</Label>
                      <div
                        className="btn-group  col-sm-12"
                        data-toggle="buttons"
                      >
                        <label className="btn btn-default active">
                          <Input
                            type="radio"
                            id="employerTeaching1"
                            name="Teaching_Facility"
                            value="Yes"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.Teaching_Facility == "Yes"
                            }
                          />
                          <span>Yes</span>
                        </label>
                        <label className="btn btn-default">
                          <Input
                            type="radio"
                            id="employerTeaching0"
                            name="Teaching_Facility"
                            value="No"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.Teaching_Facility == "No"
                            }
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="block check-box-block">
                    <FormGroup>
                      <Label for="Charge_Experience">Charge Experience</Label>
                      <div
                        className="btn-group col-sm-12"
                        data-toggle="buttons"
                      >
                        <label className="btn btn-default active">
                          <Input
                            type="radio"
                            id="employerTeaching1"
                            name="Charge_Experience"
                            value="Yes"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.Charge_Experience == "Yes"
                            }
                          />
                          <span>Yes</span>
                        </label>
                        <label className="btn btn-default">
                          <Input
                            type="radio"
                            id="employerTeaching0"
                            name="Charge_Experience"
                            value="No"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.Charge_Experience == "No"
                            }
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="block check-box-block">
                    <FormGroup>
                      <Label for="May_we_contact_this_employer">
                        May we contact this employer?
                      </Label>
                      <div
                        className="btn-group col-sm-12"
                        data-toggle="buttons"
                      >
                        <label className="btn btn-default active">
                          <Input
                            type="radio"
                            id="employerTeaching1"
                            name="May_we_contact_this_employer"
                            value="Yes"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.May_we_contact_this_employer ==
                              "Yes"
                            }
                          />
                          <span> Yes</span>
                        </label>
                        <label className="btn btn-default">
                          <Input
                            type="radio"
                            id="employerTeaching0"
                            name="May_we_contact_this_employer"
                            value="No"
                            onChange={(e) => this.handleChange(e)}
                            checked={
                              this.state.wrkItem.May_we_contact_this_employer ==
                              "No"
                            }
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div class="form-bttn">
                <Form className="inner-block">
                  <div className="sign-bttn-sec row justify-content-between">
                    <div className="btn-left">
                      {this.state.modalType === "save" ? (
                        ""
                      ) : this.state.wrk.length > 1 ? (
                        <div className="btn-left">
                          <button
                            className="blue-bttn"
                            onClick={(e) =>
                              this.removeWorkItem(
                                e,
                                this.state.wrkItem.wrkItemId
                              )
                            }
                            style={{ fontFamily: "Figtree Black" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <div className="btn-right">
                      <button
                        className="blue-bttn"
                        onClick={(e) =>
                          this.handleWorkExpOnSubmit(e, this.state.modalType)
                        }
                        disabled={this.state.button}
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Save
                        {this.state.loader ? (
                          <img
                            src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                            alt="No Image"
                            style={{ width: 40, height: 40 }}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                      <button
                        onClick={(e) => this.addWokkExp(e)}
                        className="clear-bttn"
                        style={{ fontFamily: "Figtree Black" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Button
          color="link"
          onClick={(e) => this.handleClick(e)}
          aria-expanded={this.props.collapse === "2"}
        >
          Work
          <div class="w-15 w-xs-100">
            <figure className="form-progress-icon ">
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => <Tooltip {...props}>Incomplete</Tooltip>}
                placement="top"
              >
                {(this.props.status &&
                  this.props.status.finalExperiancesStatus &&
                  this.props.status.finalExperiancesStatus[1] &&
                  this.props.status.finalExperiancesStatus[1].workExperiance &&
                  this.props.status.finalExperiancesStatus[1].workExperiance ==
                  "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[0] &&
                    this.props.status.finalExperiancesStatus[0].workExperiance &&
                    this.props.status.finalExperiancesStatus[0].workExperiance ==
                    "Completed") ||
                  (this.props.status &&
                    this.props.status.finalExperiancesStatus &&
                    this.props.status.finalExperiancesStatus[2] &&
                    this.props.status.finalExperiancesStatus[2].workExperiance &&
                    this.props.status.finalExperiancesStatus[2].workExperiance ==
                    "Completed") ? (
                  <img src={CompletedIcon} alt="Progress Icon" />
                ) : (
                  <span className="form-tag">0 of 1</span>
                )}
              </OverlayTrigger>
            </figure>
          </div>
        </Button>
        <Collapse
          isOpen={this.props.collapse === "2"}
          className="accordian-cont-block"
        >
          <Form
            className={`form-block contact-info ${this.state.editStatus === false ? "input-disable" : ""
              }`}
          >
            {this.createWorkUI()}

            <a
              href="javascript:void(0)"
              onClick={(e) => this.addWokkExp(e)}
              className="click-link add-form"
            >
              {" "}
              <span>+</span>
              <em>Add Work</em>
            </a>
          </Form>
        </Collapse>
      </>
    );
  }
}
