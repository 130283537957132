import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AppConstants from "../AppConstants";

import { Table, Dropdown, Spinner } from "react-bootstrap";

const headeroptions = ["Name", "Expiry Date", "Days", "Status"];

const ExpiringDocs = () => {
  const [expiredDocs, setExpiredDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getExpiryAPICall = async (id) => {

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      const url =
        AppConstants.API + `/document/getNurseExpirationDocuments/${id}`;


      const result = await axios.get(url, config);
      if (result.data.Status == "Success") {
        setExpiredDocs(result.data.data);
      } else {
        console.log("error retrieving Expired documents in try");
      }
      setIsLoading(false);
    } catch (err) {
      console.log("error retrieving Expired documents in catch");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let userId = localStorage.getItem("tempId");
    getExpiryAPICall(userId);
  }, []);

  // return <div>Hi</div>;

  return (
    <Table
      responsive
      striped
      bordered
      //   borderless

      //   className="professional-form-list"
      style={{
        // borderCollapse: "separate",
        borderSpacing: "0 8px",
      }}
    >
      <thead>
        <tr key="tr-item">
          {headeroptions.map((header, idx) => {
            return (
              <th
                key={idx}
                style={{ backgroundColor: "#25408F", color: "#FFF" }}
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr key="tr-item">
            <td colSpan="4" className="text-center">
              <Spinner animation="border" />
            </td>
          </tr>
        ) : expiredDocs.length == 0 ? (
          <tr key="tr-item">
            <td colSpan="4" className="text-center">
              No Expiring Documents
            </td>
          </tr>
        ) : (
          expiredDocs.map((doc, index) => {
            const tooltip = (
              <Tooltip
                id={`tooltip-${index}`}
              // style={{ backgroundColor: "blue", color: "white" }}
              >
                {doc.licenseName}
              </Tooltip>
            );
            const statusColor = doc.isExpired === true ? "red" : "#00a67e";
            return (
              <tr key={`tr-item-${index}`}>
                {/* <td>{doc.licenseName}</td>   */}

                <td bsStyle="default">
                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <span>{doc.licenseName}</span>
                  </OverlayTrigger>
                </td>
                <td>{doc.licenseExpiration}</td>
                <td>{doc.days}</td>
                <td style={{ color: statusColor }}>{doc.status}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default ExpiringDocs;
