var AppConstants = {};
if (window.location.hostname === "localhost") {
  AppConstants = {
    //S3
    // Bucket_URL: "https://travelnurses.s3.us-west-2.amazonaws.com",

    // Local

    // API: "http://localhost:1901",
    // Base_Url: "http://localhost:3000",
    // Jobs_API: "http://localhost:1902",
    // CMS_API: "http://localhost:1903",
    // Word_Press_API: "https://travelnstaging.wpengine.com",
    // Job_Url: "http://localhost:3000",
    // Bucket_URL: "https://df8de9x9ieyx9.cloudfront.net",

    // Staging

    API: "https://stagingusers.travelnursesinc.com",
    Base_Url: "https://staging.travelnursesinc.com",
    Jobs_API: "https://stagingjobs.travelnursesinc.com",
    CMS_API: "https://stagingcms.travelnursesinc.com",
    Word_Press_API: "https://travelnstaging.wpengine.com",
    // Reviews_Word_Press_API: 'https://travelnstaging.wpengine.com',
    Job_Url: "https://staging.travelnursesinc.com",
    Partners_API: "https://stagingpartners.travelnursesinc.com",
    Bucket_URL: "https://df8de9x9ieyx9.cloudfront.net",

    // Production

    // API: "https://users.travelnursesinc.com",
    // Base_Url: "https://travelnursesinc.com",
    // Jobs_API: "https://jobsapi.travelnursesinc.com",
    // CMS_API: "https://cms.travelnursesinc.com",
    // Word_Press_API: "https://travelnursesinc.com",
    // // Reviews_Word_Press_API: 'https://travelnursesinc.com',
    // Job_Url: "https://jobs.travelnursesinc.com",
    // Partners_API: "https://partners.travelnursesinc.com",
    // Bucket_URL: "https://df8de9x9ieyx9.cloudfront.net",

    // uat

    // API: "https://uatusers.travelnursesinc.com",
    // Base_Url: "https://uat.travelnursesinc.com",
    // Jobs_API: "https://uatjobs.travelnursesinc.com",
    // CMS_API: "https://uatcms.travelnursesinc.com",
    // Word_Press_API: "https://travelnuat.wpengine.com",
    // Job_Url: "https://uat.travelnursesinc.com",
    // Partners_API: "https://uatpartners.travelnursesinc.com",

    /* Blog */
    Blog: "Travel Nurses Blog",
    Sub_Blog: "A blog where we share insights from the field",

    /* Resources */
    Resources: "Resources",
    Sub_Resources: "Resources",
    Res_House_Info: "Housing Info",
    Res_House_Benefits: "Benefits",

    /* Housing */
    Housing: "Travel Nurses Housing",
    Housing_Options: "Other Housing Options",
    Housing_Info: "Housing Information",
    Sub_Housing_Info: "Why travel with us?",

    /*Privacy Policy*/
    Privacy: "Privacy Policy",
    Sub_Privacy: "Joint Commission Certified",

    /*We Make It Easy*/
    Make_Easy: "We Make It Easy",
    Sub_Make_Easy: "Who are Travel Nurses?",

    /* Social Media */
    Social_Media: "Follow Travel Nurses on Social Media!",
    Sub_Social_Media: "Connect with us on Facebook, Twitter and Instagram",

    /* About */
    About: "About Travel Nurses Inc.",
    Sub_About: "Who are Travel Nurses ?",

    /* Our Approach */
    Approach: "Our Approach",
    Sub_Approach: "Why travel with us?",

    /* Jobs */
    Jobs: "Latest Jobs",
    Sub_Job_Title: "Browse latest job postings",
    R_Jobs: "Related Jobs",

    /* Recruiters */
    R_Specialist: "Meet The Team",
    // R_Specialist: "Meet Our Recruitment Specialists",
    Sub_R_Specialist: "They’re more than just recruiters",
    // Our_Specialists: "Our Recruitment Specialists",
    Our_Specialists: "Meet The Team",

    /* Jobs in demand*/
    Jobs_In_Demand: "Medical Traveler Jobs In Demand",
    Sub_Jobs_In_Demand:
      "Some of the top medical traveler jobs in the United States",

    /* Benefits */
    Benefits: "Benefits",
    Add_Benefits: "Additional Benefits",
    Sub_Benefits: "Why travel with us?",

    /*Refer a friend */
    Refer_Frnd: "Refer a friend",

    /*Destination */
    // pick_dest: "Find Your Next Adventure",
    pick_dest: "Start A New Adventure",

    /* Vaccination Text */
    vaccination_text: "LPNs in Demand",
    /* Nurse Registration */
    Nurse_Reg: "Join Us",
    Nurse_Reg_Sub: "Enter your details below to continue..",
  };
} else if (window.location.hostname === "staging.travelnursesinc.com") {
  AppConstants = {
    // Staging
    API: "https://stagingusers.travelnursesinc.com",
    Base_Url: "https://staging.travelnursesinc.com",
    Jobs_API: "https://stagingjobs.travelnursesinc.com",
    CMS_API: "https://stagingcms.travelnursesinc.com",
    Word_Press_API: "https://travelnstaging.wpengine.com",
    // Word_Press_API: "https://travelnursesinc.com",
    Job_Url: "https://staging.travelnursesinc.com",
    Partners_API: "https://stagingpartners.travelnursesinc.com",
    // Bucket_URL: "https://travelnurses.s3.us-west-2.amazonaws.com",
    Bucket_URL: "https://df8de9x9ieyx9.cloudfront.net",

    /******************  Static text ******************/

    /* Blog */
    Blog: "Travel Nurses Blog",
    Sub_Blog: "A blog where we share insights from the field",

    /* Resources */
    Resources: "Resources",
    Sub_Resources: "Resources",
    Res_House_Info: "Housing Info",
    Res_House_Benefits: "Benefits",

    /* Housing */
    Housing: "Travel Nurses Housing",
    Housing_Options: "Other Housing Options",
    Housing_Info: "Housing Information",
    Sub_Housing_Info: "Why travel with us?",

    /*Privacy Policy*/
    Privacy: "Privacy Policy",
    Sub_Privacy: "Joint Commission Certified",

    /*We Make It Easy*/
    Make_Easy: "We Make It Easy",
    Sub_Make_Easy: "Who are Travel Nurses?",

    /* Social Media */
    Social_Media: "Follow Travel Nurses on Social Media!",
    Sub_Social_Media: "Connect with us on Facebook, Twitter and Instagram",

    /* About */
    About: "About Travel Nurses Inc.",
    Sub_About: "Who are Travel Nurses ?",

    /* Our Approach */
    Approach: "Our Approach",
    Sub_Approach: "Why travel with us?",

    /* Jobs */
    Jobs: "Latest Jobs",
    Sub_Job_Title: "Browse latest job postings",
    R_Jobs: "Related Jobs",

    /* Recruiters */
    R_Specialist: "Meet The Team",
    // R_Specialist: "Meet Our Recruitment Specialists",
    Sub_R_Specialist: "They’re more than just recruiters",
    // Our_Specialists: "Our Recruitment Specialists",
    Our_Specialists: "Meet The Team",

    /* Jobs in demand*/
    Jobs_In_Demand: "Medical Traveler Jobs In Demand",
    Sub_Jobs_In_Demand:
      "Some of the top medical traveler jobs in the United States",

    /* Benefits */
    Benefits: "Benefits",
    Add_Benefits: "Additional Benefits",
    Sub_Benefits: "Why travel with us?",

    /*Refer a friend */
    Refer_Frnd: "Refer a friend",

    /*Destination */
    // pick_dest: "Find Your Next Adventure",
    pick_dest: "Start A New Adventure",
    /* Vaccination Text */
    vaccination_text: "LPNs in Demand",
    /* Nurse Registration */
    Nurse_Reg: "Join Us",
    Nurse_Reg_Sub: "Enter your details below to continue.",
  };
} else {
  AppConstants = {
    /*** HTTPS Production ***/

    API: "https://users.travelnursesinc.com",
    Base_Url: "https://travelnursesinc.com",
    Jobs_API: "https://jobsapi.travelnursesinc.com",
    CMS_API: "https://cms.travelnursesinc.com",
    Word_Press_API: "https://travelnursesinc.com",
    Job_Url: "https://jobs.travelnursesinc.com",
    Partners_API: "https://partners.travelnursesinc.com",
    // Bucket_URL: "https://travelnurses.s3.us-west-2.amazonaws.com",
    Bucket_URL: "https://df8de9x9ieyx9.cloudfront.net",

    /******************  Static text ******************/
    /* Blog */
    Blog: "Travel Nurses Blog",
    Sub_Blog: "A blog where we share insights from the field",

    /* Resources */
    Resources: "Resources",
    Sub_Resources: "Resources",
    Res_House_Info: "Housing Info",
    Res_House_Benefits: "Benefits",

    /* Housing */
    Housing: "Travel Nurses Housing",
    Housing_Options: "Other Housing Options",
    Housing_Info: "Housing Information",
    Sub_Housing_Info: "Why travel with us?",

    /*Privacy Policy*/
    Privacy: "Privacy Policy",
    Sub_Privacy: "Joint Commission Certified",

    /*We Make It Easy*/
    Make_Easy: "We Make It Easy",
    Sub_Make_Easy: "Who are Travel Nurses?",

    /* Social Media */
    Social_Media: "Follow Travel Nurses on Social Media!",
    Sub_Social_Media: "Connect with us on Facebook, Twitter and Instagram",

    /* About */
    About: "About Travel Nurses Inc.",
    Sub_About: "Who are Travel Nurses ?",

    /* Our Approach */
    Approach: "Our Approach",
    Sub_Approach: "Why travel with us?",

    /* Jobs */
    Jobs: "Latest Jobs",
    Sub_Job_Title: "Browse latest job postings",
    R_Jobs: "Related Jobs",

    /* Recruiters */
    R_Specialist: "Meet The Team",
    // R_Specialist: "Meet Our Recruitment Specialists",
    Sub_R_Specialist: "They’re more than just recruiters",
    // Our_Specialists: "Our Recruitment Specialists",
    Our_Specialists: "Meet The Team",

    /* Jobs in demand*/
    Jobs_In_Demand: "Medical Traveler Jobs In Demand",
    Sub_Jobs_In_Demand:
      "Some of the top medical traveler jobs in the United States",

    /* Benefits */
    Benefits: "Benefits",
    Add_Benefits: "Additional Benefits",
    Sub_Benefits: "Why travel with us?",

    /*Refer a friend */
    Refer_Frnd: "Refer a friend",

    /*Destination */
    // pick_dest: "Find Your Next Adventure",
    pick_dest: "Start A New Adventure",

    /* Vaccination Text */
    vaccination_text: "LPNs in Demand",
    /* Nurse Registration */
    Nurse_Reg: "Join Us",
    Nurse_Reg_Sub: "Enter your details below to continue.",
  };
}

// 	/*** */ Server urls ***/
// 	/*** HTTP Urls ***/
// 	// API : 'http://tnusers.ebiz.digital',
// 	// Base_Url : 'http://travelnurses.ebiz.digital',
// 	// Jobs_API : 'http://tnjobs.ebiz.digital',
// 	// CMS_API : 'http://tncms.ebiz.digital',

// 	// API: 'https://cors-anywhere.herokuapp.com/https://users.travelnursesinc.com',
// 	// Base_Url: 'https://travelnursesinc.com',
// 	// Jobs_API: 'https://cors-anywhere.herokuapp.com/https://jobs.travelnursesinc.com',
// 	// CMS_API: 'https://cors-anywhere.herokuapp.com/https://cms.travelnursesinc.com',

export default AppConstants;
