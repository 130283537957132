import React from "react";
import "animate.css";
import { Container, Row, Col } from "reactstrap";
// import ReactDOM from "react-dom";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {
  static_benefits,
  static_items,
  static_benefits_perks,
} from "./data/HomeData";
import Accordion from "./components/Accordion";
import TN_HOME_IMAGE from "./img/TN_HOME.png";
import TN_HOME_IMAGE_1 from "./img/TN_HOME_1.png";
import TN_Benefits from "./img/Benefits_Header.png";
import AppConstants from "../AppConstants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { JSONLD, Generic } from "react-structured-data";
// window.addEventListener("scroll", function () {
//   if (
//     window.location.pathname === "/benefitsnew" &&
//     window.innerWidth >= 320 &&
//     window.innerWidth <= 767
//   ) {
//     if (window.scrollY > 1) {

//       document.getElementsByClassName("onscroll-hide")[0].style.visibility =
//         "hidden";
//       document.getElementsByClassName("onscroll-hide")[0].style.background =
//         "#18bdfd";
//       document.getElementsByClassName("onscroll-hide")[0].style.opacity = "0";
//       document.getElementsByClassName("onscroll-hide")[0].style.transition =
//         "1s";
//       document.getElementsByClassName("onscroll-hide")[0].style.height = "0";
//       document.getElementsByClassName("onscroll-hide1")[0].style.visibility =
//         "hidden";
//       document.getElementsByClassName("onscroll-hide1")[0].style.opacity = "0";
//       document.getElementsByClassName("onscroll-hide1")[0].style.transition =
//         "1s";
//       document.getElementsByClassName("onscroll-hide1")[0].style.height = "0";
//       document.getElementsByClassName("onscroll-hide")[0].style.background =
//         "#18bdfd";
//       document
//         .getElementsByClassName("benefits-mobile-header")[0]
//         .classList.add("benefit-ani");

//     }
//   }
// });

// window.addEventListener("DOMContentLoaded", function () {
//   if (
//     window.location.pathname === "/benefitsnew" &&
//     window.innerWidth >= 320 &&
//     window.innerWidth <= 767
//   ) {
//     document.getElementsByClassName("App")[0].style.background = "#000";
//     document.getElementsByClassName("scroll-show")[0].style.display = "none";
//   }
// });

// window.addEventListener("scroll", function () {
//   if (
//     window.location.pathname === "/benefitsnew" &&
//     window.innerWidth >= 320 &&
//     window.innerWidth <= 767
//   ) {
//     if (window.scrollY > 1) {
//       document.getElementsByClassName("onscroll-hide")[0].style.visibility =
//         "hidden";
//       if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
//         document
//           .querySelector(".onscroll-hide1")
//           .scrollIntoView({ behavior: "smooth" });
//       }
//       document.getElementsByClassName("scroll-hide")[0].style.opacity = "0";
//       document.getElementsByClassName("scroll-hide")[0].style.marginTop ="-60px";
//       document.getElementsByClassName("scroll-hide")[0].style.height = "0";
//       document.getElementsByClassName("scroll-hide")[0].style.transition = "1s";
//       document.getElementsByClassName("onscroll-hide1")[0].style.height = "auto";
//       document.getElementsByClassName("header-benefits-mobile")[0].style.paddingTop = "100px";
//       document.getElementsByClassName("header-benefits-mobile")[0].style.overflowScroll = "hidden";

//         var mq = window.matchMedia( "(max-width: 570px)" );
//         if (mq.matches) {
//           document.getElementsByClassName("mb-curve-hdr")[0].style.height = "100%";
//         }
//         else {
//           document.getElementsByClassName("mb-curve-hdr")[0].style.height = "100%";
//         }
//          document.getElementsByClassName("hd-cntr-bl")[0].style.height = "auto";

//       document.getElementsByClassName("header-benefits-after")[0].style.height =
//         "35vh";
//       document.getElementsByClassName("scroll-show")[0].style.display = "block";
//       document.getElementsByClassName( "benefits-mobile-ui")[0].style.display = "block";

//       document.getElementsByClassName("mb-curve-hdr")[0].style.transition ="2s";

//       document
//         .getElementsByClassName("header-benefits-mobile")[0]
//         .classList.add("benefit-ani");
//       document.getElementsByClassName( "benefits-mobile-ui")[0].style.opacity = "1";

//       document
//         .getElementsByClassName("mb-curve-hdr")[0]
//         .classList.add("benefit-ani");

//       setTimeout(() => {
//         document.getElementsByClassName("App")[0].style.background = "#fff";
//         document.getElementsByClassName("App")[0].style.transition =
//           "background-color 500ms linear";
//       }, 2000);
//       // header-benefits-mobile__text-box
//     }
//   }
// });

// window.addEventListener("scroll", function () {
//   if (window.location.pathname === "/benefitsnew") {
//     if (window.scrollY > 250) {
//       document.getElementsByClassName("App")[0].style.background = "#fff";
//     }
//   }
// });

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBenefit: [],
      benfitArray: [],
      hideSlide: [],
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      image: "",
      id: "",
      accordionItems: [],
    };
    this.openBenefit = this.openBenefit.bind(this);
    this.firstsect = React.createRef();
    this.secondsect = React.createRef();
    this.firstsect.current = "firstSect";
    this.secondsect.current = "secondSect";
  }

  scrollNext = (e) => {
    e.preventDefault();
    document.querySelector("#section-1").scrollIntoView({ behavior: "smooth" });
    // document.querySelector("#section-1").style.position = "absolute";
    // document.querySelector("#section-1").style.top = "0";
    document.getElementsByClassName("onscroll-hide")[0].style.visibility =
      "hidden";

    document
      .querySelector(".onscroll-hide1")
      .scrollIntoView({ behavior: "smooth" });
    document.getElementsByClassName("scroll-hide")[0].style.opacity = "0";
    document.getElementsByClassName("scroll-hide")[0].style.marginTop = "-60px";
    document.getElementsByClassName("scroll-hide")[0].style.height = "0";
    document.getElementsByClassName("scroll-hide")[0].style.transition = "2s";
    document.getElementsByClassName("onscroll-hide1")[0].style.height =
      "initial";
    document.getElementsByClassName("header-benefits-after")[0].style.height =
      "35vh";
    document.getElementsByClassName("scroll-show")[0].style.display = "block";
    document.getElementsByClassName(
      "header-benefits-mobile"
    )[0].style.transition = "2s";
    document
      .getElementsByClassName("header-benefits-mobile")[0]
      .classList.add("benefit-ani");
    setTimeout(() => {
      document.getElementsByClassName("App")[0].style.background = "#fff";
    }, 2000);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };

  openBenefit(i, e) {
    //console.log(e, i);
    let benfitArray = { ...this.state.benfitArray };
    if (benfitArray[i]) {
      benfitArray[i] = !benfitArray[i];
    } else {
      benfitArray[i] = true;
    }
    let showBenefit = { ...this.state.showBenefit };
    showBenefit[i] = true;
    this.setState({
      benfitArray: benfitArray,
      showBenefit: showBenefit,
      id: i,
    });
  }
  slideBenefit(i, e) {
    let showBenefit = { ...this.state.showBenefit };
    showBenefit[i] = false;
    let hideSlide = { ...this.state.hideSlide };
    hideSlide[i] = true;
    this.setState({ showBenefit: showBenefit, hideSlide: hideSlide, id: i });
    let hideSlide1 = { ...this.state.hideSlide };
    hideSlide1[i] = false;
    setTimeout(() => {
      this.setState({
        hideSlide: hideSlide1,
      });
    }, 1000);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const that = this;
    axios
      // .get(AppConstants.Jobs_API + "/benefits/normalBenefits")
      .get(AppConstants.Jobs_API + "/benefits/normalBenefitsFromJsonFile")
      .then((res) => {
        return res.data;
      })
      .then(function (data) {
        if (data.Status == "Success" && data.data[0]) {
          that.setState({
            benefits: data.data[0].benefits,
            metaTitle: data.data[0].metaTitle,
            metaDescription: data.data[0].metaDescription,
            metaTags: data.data[0].metaTags,
            ogTitle: data.data[0].ogTitle,
            ogDescription: data.data[0].ogDescription,
          });
          axios
            // .get(AppConstants.Jobs_API + "/benefits/normalBenefits")
            .get(
              AppConstants.Jobs_API +
                "/benefitQuestion/allActiveBenefitQuestions"
            )
            .then((res) => {
              return res.data;
            })
            .then(function (data1) {
              if (data1.Status == "Success" && data1.data[0]) {
                that.setState({
                  accordionItems: data1.data,
                });
              }
            });
        } else {
          console.log("invalid data");
        }
      });
    if (this.props.location.pathname === "/benefits") {
      document.getElementsByClassName("ben-footer")[0].style.marginTop = "50px";
    }
  }
  // firstsect(e) {
  //   console.log(e,"e")
  // }
  handleClose() {}
  render() {
    console.log(this.state.showBenefit, "******");
    console.log(this.state.showBenefit[this.state.id], "########");
    const benefits = {
      dots: false,
      infinite: this.state.showBenefit[this.state.id] === true ? false : true,
      speed: 500,
      autoplay: this.state.showBenefit[this.state.id] === true ? false : true,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: this.state.showBenefit[this.state.id] === true ? false : true,
      touchMove: this.state.showBenefit[this.state.id] === true ? false : true,
      centerPadding: "0px",
      centerMode: true,

      focusOnSelect: false,
      focusOnChange: false,
      accessibility: false,

      cssEase: "linear",

      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };
    return (
      <React.Fragment className="">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Benefits"
            schema={{
              name: this.state.metaTitle,
              description: this.state.metaDescription,
              contentReferenceTime: new Date(),
            }}
          ></Generic>
        </JSONLD>
        <Helmet>
          {/* <title>{AppConstants.Benefits}</title> */}
          <title>
            {" "}
            {/* Travel Nurses Benefits, Travel Nursing Agency | Travel Nurses Inc */}
            Travel Nurse Benefits: Competitive Pay, Health Insurance, & More | Travel Nurses, Inc.
          </title>
          <meta name="title" content={this.state.metaTitle} />
          <meta name="description" content={this.state.metaDescription} />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={AppConstants.Base_Url + "/benefits"}
          />
          <meta property="og:title" content={this.state.ogTitle} />
          <meta property="og:description" content={this.state.ogDescription} />
          <meta property="og:image" content={this.state.image} />

          <meta property="twitter:card" content={this.state.image} />
          <meta
            property="twitter:url"
            content={AppConstants.Base_Url + "/benefits"}
          />
          <meta property="twitter:title" content={this.state.metaTitle} />
          <meta
            property="twitter:description"
            content={this.state.metaDescription}
          />
          <meta property="twitter:image" content={this.state.image} />
        </Helmet>
        <header className="header-benefits onscroll-hide header-benefits-after hide-on-mobile">
          <Container>
            <div className="row w-100">
              <div className="col-md-6 ">
                <div className="header-benefits__text-box ">
                  <h4 className="pb-2 header-benefits__sub-heading header-benefits_mobile--hide">
                    Benefits and Perks
                  </h4>
                  <h1 className="heading-primary animatted-content ">
                    The best travel nurses deserve the best support.
                  </h1>
                  <p className="text-white animatted-content header-benefits_mobile--hide">
                    Go further with the best benefits and perks travel nursing
                    has to offer. We provide everything you need to ensure you
                    are covered on and off the clock.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pr-0  header-benefits__bg">
                <figure className="desktop-hide text-right">
                  <img
                    width="350"
                    src={TN_HOME_IMAGE_1}
                    className="img-fluid"
                    alt="hrllo"
                  />
                </figure>
              </div>
            </div>
          </Container>
        </header>
        <section
          className="header-benefits-mobile onscroll-hide1"
          id="firstSect"
          ref={this.firstSect}
        >
          <Container className="hd-cntr-bl">
            <div className="benefits_mobile_content">
              <h4 className="text-white">Benefits and Perks</h4>
              <h1 className=" animatted-content">
                The best travel nurses deserve the best support.
              </h1>
              <p className="animatted-content">
                Go further with the best benefits and perks travel nursing has
                to offer. We provide everything you need to ensure you are
                covered on and off the clock.
              </p>
            </div>
            <span className="background-curve"></span>
          </Container>
        </section>

        {/* <section
          className="header-benefits-mobile onscroll-hide1"
          id="firstSect"
          ref={this.firstSect}
        >
          <div className="mb-curve-hdr onscroll-hide1"></div>
          <Container className="hd-cntr-bl">
            <div className="row h-100">
              <div className="col-md-12 my-auto ">
                <div className="header-benefits-mobile__text-box " >
                  <div className="scroll-hide">
                    <h4 className="text-white">Benefits and Perks</h4>
                    <h1 className=" animatted-content">
                      The best travel nurses deserve the best support.
                    </h1>
                    <p className="animatted-content">
                      Go further with the best benefits and perks travel nursing
                      has to offer. We provide everything you need to ensure you
                      are covered on and off the clock.
                    </p>

                    <p
                      className="w-100 text-center d-block "
                      onClick={this.scrollNext.bind(this)}
                    >
                      <span>&nbsp;</span>
                    </p>
                  </div>

                  <p className="py-5 scroll-show" id="section-1">
                    Go further with the best benefits and perks travel nursing
                    has to offer. We provide everything you need to ensure you
                    are covered on and off the clock.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </section> */}

        <section
          className="benefits--details my-5"
          id="secondSect"
          ref={this.secondSect}
        >
          <Container>
            <Row>
              {static_benefits.map((item, key) => {
                return (
                  <div className="col-lg-6 col-md-12 position-relative">
                    <div className="card mb-3 border-0">
                      <div className="row g-0">
                        <div className="col-md-3 my-auto px-0 benefits--details--image">
                          <img
                            width="150"
                            src={item.image}
                            className="img-fluid rounded-start"
                            alt={item.title}
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="card-body pl-0">
                            <a href="https://travelnursesinc.staffingreferrals.com/company/join">
                              <h5 className="card-title pb-2">{item.title}</h5>
                            </a>
                            {item.title === "Refer a Friend" ? (
                              <p className="card-text">
                                Your friends deserve to be treated well too.
                                Earn $500 per RN national traveler referral and
                                $250 for non-RN referrals (To be paid upon
                                successfully completing 468 hours worked.){" "}
                                <a
                                  href="https://travelnursesinc.staffingreferrals.com/company/join"
                                  target="_blank"
                                >
                                  Refer Now
                                </a>
                              </p>
                            ) : (
                              <p className="card-text">{item.description}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Row>
          </Container>
        </section>

        {/* <div className="benefits-mobile-header" id="section-1">
          <Container>
            <Row>
              <p className="py-4 text-white">
                Go further with the best benefits and perks travel nursing has
                to offer. We provide everything you need to ensure you are
                covered on and off the clock.
              </p>
            </Row>
          </Container>
        </div> */}

        <section
          className="benefits-mobile-ui mb-5 w-100"
          // id="section-1"
          ref={this.element}
          style={{ overflow: "unset", position: "relative" }}
        >
          <div className="benefits-mobile-ui-slider">
            <Container>
              {/* {console.log("line 358", this.state.showBenefit[this.state.id])} */}
              {/* <div className="w-100"> */}
              {/* {!this.state.showBenefit[this.state.id] ? */}
              <Slider {...benefits} style={{ overflow: "hidden" }}>
                {static_benefits.map((item, i) => {
                  return (
                    <div className="section--home__benefits__block">
                      <div>
                        <figure
                          className="d-flex justify-content-center"
                          onClick={this.openBenefit.bind(this, i)}
                        >
                          <img
                            width="130"
                            height="130"
                            className=" text-center mx-auto"
                            alt={item.title}
                            src={item.image}
                          />
                        </figure>
                        <p className="pb-0">{item.title}</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>

              {/* } */}
              {/* </div> */}

              <div className="col-12 mt-1 footer-text">
                <p className="text-center bottom-text">
                  Scroll and tap to learn more about our premium benefits and
                  perks.
                </p>
              </div>
            </Container>
          </div>
          {static_benefits.map((item, i) => {
            return (
              <div
                className="section--home__benefits__block"
                style={{
                  position: "absolute",
                  width: "90%",
                  left: "5%",
                  zIndex: "999",
                }}
              >
                {/* <React.Fragment> */}
                {this.state.showBenefit[i] ? (
                  <div
                    className="pop-card card mb-3 border-0 px-2 animate__animated animate__slideInUp"
                    onClick={this.slideBenefit.bind(this, i)}
                    style={{
                      zIndex: "999",
                    }}
                  >
                    <div className="row g-0">
                      <div className="col-12 my-auto text-center  px-0 ">
                        <figure className="w-100 text-center d-block mb-0">
                          <img
                            width="150"
                            src={item.image}
                            className="img-fluid rounded-start"
                            alt={item.title}
                          />
                        </figure>
                      </div>
                      <div className="col-12">
                        <div className="card-body px-1">
                          <h5
                            className="card-title pb-0"
                            style={{ color: "#25408f" }}
                          >
                            {item.title}
                          </h5>
                          {item.title === "Refer a Friend" ? (
                            <p className="card-text">
                              Your friends deserve to be treated well too. Earn
                              $500 per RN national traveler referral and $250
                              for non-RN referrals (To be paid upon successfully
                              completing 468 hours worked.){" "}
                              <a
                                href="https://travelnursesinc.staffingreferrals.com/company/join"
                                target="_blank"
                              >
                                Refer Now
                              </a>
                            </p>
                          ) : (
                            <p className="card-text">{item.description}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.hideSlide[i] ? (
                  <div
                    className="pop-card  card mb-3 border-0 animate__animated animate__slideOutDown"
                    onClick={this.slideBenefit.bind(this, i)}
                  >
                    <div className="row g-0">
                      <div className="col-12 my-auto text-center  px-0 ">
                        <figure className="w-100 text-center d-block">
                          <img
                            width="150"
                            src={item.image}
                            className="img-fluid rounded-start"
                            alt={item.title}
                          />
                        </figure>
                      </div>
                      <div className="col-12">
                        <div className="card-body px-1">
                          <h5 className="card-title pb-0">{item.title}</h5>
                          {item.title === "Refer a Friend" ? (
                            <p className="card-text">
                              Your friends deserve to be treated well too. Earn
                              $500 per RN national traveler referral and $250
                              for non-RN referrals (To be paid upon successfully
                              completing 468 hours worked.){" "}
                              <a
                                href="https://travelnursesinc.staffingreferrals.com/company/join"
                                target="_blank"
                              >
                                Refer Now
                              </a>
                            </p>
                          ) : (
                            <p className="card-text">{item.description}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* </React.Fragment> */}
              </div>
            );
          })}
        </section>
        {this.state.accordionItems.length > 0 ? (
          <section
            className="benefit_qa mb-5 pb-5 animate__animated  animate__fadeInUp"
            style={{ display: "inline" }}
          >
            <Container>
              <h2 className="heading-secondary pb-5">
                Do you have questions? Ask away!
              </h2>
              <div className="col-lg-10 col-md-12 px-0 justify-content-center benefit_qa-row">
                <Accordion items={this.state.accordionItems} />
              </div>
            </Container>
          </section>
        ) : (
          ""
        )}
        {/* <section
            className="benefit_qa mb-5 pb-5 animate__animated  animate__fadeInUp"
            style={{ display: "inline" }}
          >
            <Container>
              <h2 className="heading-secondary pb-5">
                Do you have questions? Ask away!
              </h2>
              <div className="col-lg-10 col-md-12 px-0 justify-content-center benefit_qa-row">
                <Accordion items={static_items} />
              </div>
            </Container>
          </section> */}
      </React.Fragment>
    );
  }
}

export default Benefits;
