import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../AppConstants";
import axios from "axios";
import styles from "../Styles/registration.css";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
 
import DateIcon from "../images/calender-icon.png";
import { JSONLD, Generic } from "react-structured-data";
import moment from "moment";
import ReactAutocomplete from "react-autocomplete";
import TimesheetPopup from "./timesheet-popup";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { getCountries } from "../Components/helpers/network-calls";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy HH:MM");
class Professionalreport extends React.Component {
  /* add class function */
  constructor(props) {
    super(props);
    this.state = {
      applyData: null,

      rehire: [
        { text: "Yes", value: "Yes", isChecked: false },
        { text: "No", value: "No", isChecked: false },
      ],
      check_rehire: [],
      name: "",
      supervisor: "",
      recruiteremail: "",
      recruiterError: false,
      code: "+1",
      phone: "",
      telephone: "",
      email: "",
      errors: [],
      errorsCode: {},
      firstNameError: false,
      supervisorError: false,
      emailError: false,
      phone_error: false,
      telephone_error: false,
      rehireError: false,
      codeError: false,
      errMsg: "",
      isLoading: false,
      startDate: "",
      endDate: "",
      positions: "",
      jobTitle: "",
      metaTitle: "",
      metaDescription: "",
      metaTags: [],
      cities: [],
      value1: "",

      ogTitle: "",
      ogDescription: "",
      startDateRange: null,
      endDateRange: null,
      Date1: null,
      Date1Error: false,
      startDateError: false,
      correctDateError: false,
      endDate_err: false,
      title: "",
      title1: "",
      titleError1: false,
      titleError: false,
      hospital: "",
      hospitalError: false,
      cityError: false,
      comments: "",
      commentsError: false,
      reportcompletedby: "",
      reportcompletedbyError: false,
      strengths: "",
      strengthsError: false,
      unit: "",
      unitError: false,
      popUp: false,

      qualityOfWork: "",
      quantityOfWork: "",
      abilitytofollowdirections: "",
      dependability: "",
      abilitytorelatetostaff: "",
      abilitytochange: "",
      attendance: "",
      punctuality: "",
      prefessionalappearance: "",
      AbilitytorelatetoPatients: "",
      GeneralAttitude: "",
      radioboxError: false,
      message: "",
      toggle: false,
      toggle1: false,
      toggle2: false,
      personalevaluation: [
        "Superior",
        "Exceeds",
        "Meets",
        "Does Not Meet",
        "N/A",
      ],
      countries: [],
    };
    this.handleRehire = this.handleRehire.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // (e)=>this.handleChange(e) = (e)=>this.handleChange(e).bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.inputChangedHandlerContact =
      this.inputChangedHandlerContact.bind(this);
    this.inputChangedHandlerContact2 =
      this.inputChangedHandlerContact2.bind(this);
    this.openDatepicker = this.openDatepicker.bind(this);
    this.baseState = this.state;
  }
  inputChangedHandlerContact = (values) => {
    this.setState({
      phone: values.value,
    });
    if (values.value.length === 10) {
      this.setState({ phone_error: false });
    }
  };
  inputChangedHandlerContact2 = (values) => {
    this.setState({
      telephone: values.value,
    });
    if (values.value.length === 10) {
      this.setState({ telephone_error: false });
    }
  };
  componentWillMount() {
    document.getElementById("overall").className = "hide-block";
  }
  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (
      name === "qualityOfWork" ||
      name === "quantityOfWork" ||
      name === "abilitytofollowdirections" ||
      name === "dependability" ||
      name === "abilitytorelatetostaff" ||
      name === "abilitytochange" ||
      name === "attendance" ||
      name === "punctuality" ||
      name === "prefessionalappearance" ||
      name === "AbilitytorelatetoPatients" ||
      name === "GeneralAttitude"
    ) {
      if (value === "") {
        this.setState({ radioboxError: true });
      } else {
        this.setState({ radioboxError: false });
      }
    }
  };
  handleChange = (e) => {
    // this.setState({[e.target.name]: e.target.value})
    this.setState({
      [e.target.name]: e.target.value.trimLeft(/(^\s+|\s+$)/g, ""),
    });
    if (e.target.name === "name") {
      if (e.target.value === "") {
        this.setState({ firstNameError: true });
      } else {
        this.setState({ firstNameError: false });
      }
    }

    if (e.target.name === "recruiteremail") {
      if (e.target.value === "") {
        this.setState({ recruiterError: true });
      } else {
        this.setState({ recruiterError: false });
      }
    }
    if (e.target.name === "unit") {
      if (e.target.value === "") {
        this.setState({ unitError: true });
      } else {
        this.setState({ unitError: false });
      }
    }
    if (e.target.name === "title") {
      if (e.target.value === "") {
        this.setState({ titleError: true });
      } else {
        this.setState({ titleError: false });
      }
    }
    if (e.target.name === "title1") {
      if (e.target.value === "") {
        this.setState({ titleError1: true });
      } else {
        this.setState({ titleError1: false });
      }
    }
    if (e.target.name === "strengths") {
      if (e.target.value === "") {
        this.setState({ strengthsError: true });
      } else {
        this.setState({ strengthsError: false });
      }
    }
    // if (e.target.name === "email") {
    //   if (e.target.value === "") {
    //     this.setState({ emailError: true });
    //   } else {
    //     this.setState({ emailError: false });
    //   }
    // }
    if (e.target.name === "speciality") {
      if (e.target.value === "") {
        this.setState({ specialityError: true });
      } else {
        this.setState({ specialityError: false });
      }
    }
    if (e.target.name === "hospital") {
      if (e.target.value === "") {
        this.setState({ hospitalError: true });
      } else {
        this.setState({ hospitalError: false });
      }
    }
    if (e.target.name === "comments") {
      if (e.target.value === "") {
        this.setState({ commentsError: true });
      } else {
        this.setState({ commentsError: false });
      }
    }
    if (e.target.name === "reportcompletedby") {
      if (e.target.value === "") {
        this.setState({ reportcompletedbyError: true });
      } else {
        this.setState({ reportcompletedbyError: false });
      }
    }
    // if (e.target.name === "city") {
    //   if (e.target.value === "") {
    //     this.setState({ cityError: true });
    //   } else {
    //     this.setState({ cityError: false });
    //   }
    // }
    // if (e.target.name === 'code') {
    //   if (e.target.value === '') {
    //     this.setState({codeError:true});
    //   } else {
    //     this.setState({codeError:false})
    //   }
    // }
  };
  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
    if (e.target.name === "code") {
      if (e.target.value !== "+1") {
        this.setState({ codeError: true, button_disable: true }, () =>
          this.handleValidationCode()
        );
      } else {
        this.setState({ codeError: false, button_disable: false }, () =>
          this.handleValidationCode()
        );
      }
    }
  };

  handleRehire(event) {
    var options = [];
    let rehire = this.state.rehire;
    rehire.forEach((option) => {
      if (option.value === event.target.value) {
        option.isChecked = event.target.checked;
      }
    });
    this.state.rehire.map((option, i) => {
      if (option.isChecked === true) {
        options.push(option.value);
      }
    });
    if (options.length === 0) {
      this.setState({ rehireError: true });
    } else {
      this.setState({ rehireError: false });
    }
    this.setState({ rehire: rehire, check_rehire: options });
  }
  handleValidationCode() {
    let errorsCode = {};
    let formIsValidCode = true;
    if (this.state.code !== "+1") {
      formIsValidCode = false;
      errorsCode["code"] =
        "Travel Nurses, Inc. (TNI) does not sponsor international healthcare professionals at this time. We are only able to hire nurses residing in the United States.";
      ReactDOM.findDOMNode(this.refs.code).focus();
    }
    this.setState({
      errorsCode: errorsCode,
      codeError: true,
    });
    return formIsValidCode;
  }

  componentDidMount() {
    const search = new URL(window.location).searchParams;
    this.getAllCountries();
    const name = search.get("pname");
    const supervisor = search.get("sname");
    const recruiteremail = search.get("remail");
    if (recruiteremail) {
      this.setState({
        recruiteremail: recruiteremail,
      });
    }
    if (supervisor) {
      this.setState({
        supervisor: supervisor,
      });
    }
    if (name) {
      this.setState({
        name: name,
      });
    }
  }

  getAllCountries = async () => {
    const result = await getCountries();
    this.setState({ countries: result && result });
  };
  openDatepicker = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  openDatepickerFromDdate = () => {
    this.setState({ toggle1: !this.state.toggle1 });
  };
  openDatepickerToDate = () => {
    this.setState({ toggle2: !this.state.toggle2 });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    // if (!this.state.email) {
    //   formIsValid = false;
    //   errors["email"] = "Please enter email";
    //   ReactDOM.findDOMNode(this.refs.email).focus();
    // }
    if (this.state.email !== "") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.email).focus();
      }
    }
    // if (!this.state.recruiteremail) {
    //   formIsValid = false;
    //   errors["recruiter"] = "Please enter recruiter email";
    //   ReactDOM.findDOMNode(this.refs.recruiteremail).focus();
    // }
    if (this.state.recruiteremail !== "") {
      let lastAtPos = this.state.recruiteremail.lastIndexOf("@");
      let lastDotPos = this.state.recruiteremail.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.recruiteremail.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.recruiteremail.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["recruiter"] = "Please enter valid email";
        ReactDOM.findDOMNode(this.refs.recruiteremail).focus();
      }
    }
    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "Please enter phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    if (this.state.phone && this.state.phone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Please enter 10 digits phone number";
      ReactDOM.findDOMNode(this.refs.phone).focus();
    }
    // if (!this.state.telephone) {
    //   formIsValid = false;
    //   errors["telephone"] = "Please enter telephone number";
    //   ReactDOM.findDOMNode(this.refs.phone).focus();
    // }
    // if (this.state.telephone && this.state.telephone.length !== 10) {
    //   formIsValid = false;
    //   errors["telephone"] = "Please enter 10 digits phone number";
    //   ReactDOM.findDOMNode(this.refs.telephone).focus();
    // }
    // if (!this.state.code) {
    //   formIsValid = false;
    //   errors["code"] = "Please enter phone number code";
    //   ReactDOM.findDOMNode(this.refs.code).focus();
    // }
    // if (this.state.check_contact_methods.length === 0) {
    //   formIsValid = false;
    //   errors["contact_methods"] = "Please select contact method";
    //   ReactDOM.findDOMNode(this.refs.contact_methods).focus();
    // }

    if (!this.state.name) {
      formIsValid = false;
      errors["firstName"] = "Please enter professional name";
      ReactDOM.findDOMNode(this.refs.name).focus();
    }

    if (!this.state.value1) {
      formIsValid = false;
      errors["city"] = "Please enter city&state";
    }
    // if (!this.state.strengths) {
    //   formIsValid = false;
    //   errors["strengths"] = "field is required";
    //   ReactDOM.findDOMNode(this.refs.strengths).focus();
    // }
    if (!this.state.unit) {
      formIsValid = false;
      errors["unit"] = "Please select Unit";
      ReactDOM.findDOMNode(this.refs.unit).focus();
    }
    if (!this.state.reportcompletedby) {
      formIsValid = false;
      errors["reportcompletedby"] = "Please enter name";
      ReactDOM.findDOMNode(this.refs.reportcompletedby).focus();
    }
    if (!this.state.hospital) {
      formIsValid = false;
      errors["hospital"] = "Please enter FacilityHospital name";
      ReactDOM.findDOMNode(this.refs.hospital).focus();
    }
    if (!this.state.title) {
      formIsValid = false;
      errors["title"] = "Please enter title";
      ReactDOM.findDOMNode(this.refs.title).focus();
    }
    if (
      !this.state.qualityOfWork ||
      !this.state.quantityOfWork ||
      !this.state.abilitytofollowdirections ||
      !this.state.dependability ||
      !this.state.abilitytorelatetostaff ||
      !this.state.abilitytochange ||
      !this.state.attendance ||
      !this.state.punctuality ||
      !this.state.prefessionalappearance ||
      !this.state.AbilitytorelatetoPatients
    ) {
      formIsValid = false;
      errors["radiobox"] = "Please Rate Every Personal Evaluation";
      // ReactDOM.findDOMNode(this.refs.title).focus();
    }
    // if (!this.state.title1) {
    //   formIsValid = false;
    //   errors["title1"] = "Please enter title";
    //   ReactDOM.findDOMNode(this.refs.title1).focus();
    // }
    if (this.state.check_rehire.length === 0) {
      formIsValid = false;
      errors["rehire"] = "Please select One";
      ReactDOM.findDOMNode(this.refs.rehire).focus();
    }
    if (!this.state.supervisor) {
      formIsValid = false;
      errors["supervisor"] = "Please enter supervisor name";
      ReactDOM.findDOMNode(this.refs.supervisor).focus();
    }
    if (!this.state.Date1) {
      formIsValid = false;
      errors["date1"] = "Please select date";
      // ReactDOM.findDOMNode(this.refs.Date1).focus();
    }
    if (!this.state.startDateRange) {
      formIsValid = false;
      errors["fromdate"] = "Please enter from date";
    }
    if (!this.state.endDateRange) {
      formIsValid = false;
      errors["todate"] = "Please enter to date";
    }
    // if (!this.state.city) {
    //   formIsValid = false;
    //   errors['city'] = "Please enter city"
    // }
    // if (!this.state.comments) {
    //   formIsValid = false;
    //   errors["comments"] = "Please enter comments";
    //   ReactDOM.findDOMNode(this.refs.comments).focus();
    // }
    this.setState({
      errors: errors,
      firstNameError: true,
      supervisorError: true,
      emailError: true,
      phone_error: true,
      // telephone_error:true,
      certificationsError: true,
      contact_methodsError: true,
      // timingsError : true,
      // codeError : true,
      specialityError: true,
      referralSourceError: true,
      otherReferralSourceError: true,
      startDateError: true,
      endDate_err: true,
      titleError: true,
      hospitalError: true,
      rehireError: true,
      cityError: true,
      commentsError: true,
      reportcompletedbyError: true,
      Date1Error: true,
      strengthsError: true,
      unitError: true,
      recruiterError: true,
      radioboxError: true,
    });
    return formIsValid;
  }

  handleSubmit(e) {
    // console.log("hello",this.state.jobTitle)
    e.preventDefault();

    if (this.handleValidation()) {
      this.setState({ isLoading: true });
      let formData = {
        name: this.state.name,
        supervisor: this.state.supervisor,
        recruiter: this.state.recruiteremail,
        title: this.state.title,
        phone: this.state.phone,
        email: this.state.email,

        hospital: this.state.hospital,
        unit: this.state.unit,
        fromDate: this.state.startDateRange
          ? moment(this.state.startDateRange).format("MM/DD/YYYY")
          : "",
        toDate: this.state.endDateRange
          ? moment(this.state.endDateRange).format("MM/DD/YYYY")
          : "",
        rehire: this.state.check_rehire,
        citystate: this.state.value1,
        telephone: this.state.telephone ? this.state.telephone : null,
        strengths: this.state.strengths ? this.state.strengths : null,
        comments: this.state.comments ? this.state.comments : null,
        date: this.state.Date1
          ? moment(this.state.Date1).format("MM/DD/YYYY")
          : "",
        reportcompletedby: this.state.reportcompletedby,
        qualityOfWork: this.state.qualityOfWork,
        quantityOfWork: this.state.quantityOfWork,
        abilitytofollowdirections: this.state.abilitytofollowdirections,
        dependability: this.state.dependability,
        abilitytorelatetostaff: this.state.abilitytorelatetostaff,
        abilitytochange: this.state.abilitytochange,
        attendance: this.state.attendance,
        punctuality: this.state.punctuality,
        prefessionalappearance: this.state.prefessionalappearance,
        AbilitytorelatetoPatients: this.state.AbilitytorelatetoPatients,
        // GeneralAttitude: this.state.GeneralAttitude,
      };
      // console.log("formdata", formData);
      axios
        .post(AppConstants.API + "/nurse/professionalreport", formData)
        .then((res) => {
          if (res.data.Status === "Success") {
            this.setState({
              button_disable: true,
              popUp: true,

              isLoading: false,
              message: res.data.msg,
            });
          } else {
            setTimeout(() => {
              this.setState({
                isLoading: false,
                errMsg: (
                  <div className="alert alert-danger fade show mt-3">
                    {res.data.msg}
                  </div>
                ),
              });
            }, 1000);
            setTimeout(() => this.setState({ errMsg: "" }), 2000);
          }
        });
    }
  }
  handleStartDate(date) {
    if (date && date != null) {
      this.setState({ startDateRange: date, startDateError: false });
    }
    var sDate = new Date(date).toISOString();
    var eDate = new Date(this.state.endDateRange).toISOString();
    if (this.state.endDateRange != null && sDate > eDate) {
      this.setState({
        correctDateError: true,
      });
    } else {
      this.setState({
        correctDateError: false,
      });
    }
  }
  handleStartDate1(date) {
    if (date && date != null) {
      this.setState({ Date1: date, Date1Error: false });
    }
  }
  handleEndDate(date) {
    if (date && date != null) {
      this.setState({
        endDate_err: false,
        endDateRange: date,
      });
    }
    var sDate = new Date(this.state.startDateRange).toISOString();
    var eDate = new Date(date).toISOString();
    if (sDate > eDate) {
      this.setState({
        correctDateError: true,
      });
    } else {
      this.setState({
        correctDateError: false,
      });
    }
  }
  handleAutoloadCity(e) {
    this.setState({
      value1: e.target.value,
      city1: e.target.value,
      cityError: false,
    });
    if (e.target.value.length < 3) {
      this.setState({ cities: [] });
    } else {
      let searchfilterdata = {
        searchtext: e.target.value,
      };
      axios
        .post(
          AppConstants.Jobs_API + "/jobs/getAllCitiesWithAutoLoadAndJobCount",
          searchfilterdata
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data.Status === "Success") {
            this.setState({
              cities: [
                {
                  _id: "All",
                  name: "All",
                  label: "All",
                  value: "All",
                },
                ...data.data,
              ],
            });
          }
        });
    }
  }
  handleAutoLoadSelect(val) {
    if (val) {
      this.setState({ value1: val });
    }
  }
  handleTimesheetPopup() {
    this.setState(
      {
        popUp: !this.state.popUp,
      },
      () => {
        this.setState(this.baseState);
      }
    );
  }
  render() {
    return (
      <div className="apply-form registr-info main-page">
        <JSONLD>
          <Generic
            type="webpage"
            jsonldtype="Webpage"
            schema={{
              name: this.state.metaTitle,
              description: this.state.metaDescription,
              contentReferenceTime: new Date(),
            }}
          ></Generic>
        </JSONLD>

        <Container>
          <div className="registration-form" id="professional-form">
            <div className="title">
              Healthcare Professional Reference Report
            </div>

            <Form className="inner-block">
              <Row>
                <Col xs="6" className="block big-field">
                  <FormGroup>
                    <Label for="FirstName">
                      Healthcare Professional Name (Clinician You Are Completing This For):
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      // placeholder="First Name"
                      id="name"
                      value={this.state.name}
                      onChange={(e) => this.handleChange(e)}
                      ref="name"
                    />
                    <span>
                      {this.state.firstNameError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["firstName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>

                <Col xs="6" className="block" id="mail-field">
                  <FormGroup>
                    <Label for="Email">
                      Recruiter Email
                      {/* <span className="error" style={{ color: "red" }}>
                        *
                      </span> */}
                    </Label>
                    <Input
                      type="text"
                      name="recruiteremail"
                      value={this.state.recruiteremail}
                      // placeholder="Last Name"
                      id="recruiteremail"
                      onChange={(e) => this.handleChange(e)}
                      ref="recruiteremail"
                    />
                    <span>
                      {this.state.recruiterError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["recruiter"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <div className="form-block">
                {/* <Row className="codephone-blk"> */}
                <Row>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Last Name">
                        Supervisor
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="supervisor"
                        value={this.state.supervisor}
                        placeholder="YOUR NAME HERE"
                        id="supervisor"
                        onChange={(e) => this.handleChange(e)}
                        ref="supervisor"
                      />
                      <span>
                        {this.state.supervisorError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["supervisor"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>

                  <Col xs="6" className="block" id="mail-field">
                    <FormGroup>
                      <Label for="Email">
                        Supervisor's Title
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="title"
                        value={this.state.title}
                        // placeholder="Last Name"
                        id="title"
                        onChange={(e) => this.handleChange(e)}
                        ref="title"
                      />
                      <span>
                        {this.state.titleError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["title"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="codephone-blk">
                  <div class="block small-block col-1">
                    <FormGroup>
                      <Label for="Code">Country Code</Label>
                      <Input
                        type="select"
                        value={this.state.code}
                        name="code"
                        ref="code"
                        onChange={this.handleCodeChange}
                        id="Code"
                      >
                        {this.state.countries.map((item) => {
                          return (
                            <option
                              data-countryCode={item.phoneCode}
                              value={"+" + item.phoneCode}
                            >
                              {item.phoneCode === 1 ? "USA" : item.name}{" "}
                              {"+" + item.phoneCode}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </div>

                  <div class="block widht-40 col-4 phone-input">
                    <FormGroup>
                      <Label for="Phone">
                        Phone
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="phone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact}
                        value={this.state.phone}
                        className="form-control"
                        ref="phone"
                      />
                      <span>
                        {this.state.phone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </div>
                  <span className="code-validation" id="mbl-validation">
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  <div class="block col-6">
                    <FormGroup>
                      <Label for="Last Name">
                        Email Address
                        {/* <span className="error" style={{ color: "red" }}>
                          *
                        </span> */}
                      </Label>
                      <Input
                        type="text"
                        name="email"
                        value={this.state.email}
                        // placeholder="Last Name"
                        id="email"
                        onChange={(e) => this.handleChange(e)}
                        ref="email"
                      />
                      {/* <span>
                        {this.state.emailError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["email"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span> */}
                    </FormGroup>
                  </div>
                  <span
                    className="code-validation col-sm-12"
                    id="desk-validation"
                  >
                    {this.state.codeError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errorsCode["code"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </Row>
                <Row>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Last Name">
                        Facility/Hospital
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="hospital"
                        value={this.state.hospital}
                        // placeholder="Last Name"
                        id="hospital"
                        onChange={(e) => this.handleChange(e)}
                        ref="hospital"
                      />
                      <span>
                        {this.state.hospitalError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["hospital"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Last Name">
                        Unit
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="unit"
                        value={this.state.unit}
                        // placeholder="Last Name"
                        id="unit"
                        onChange={(e) => this.handleChange(e)}
                        ref="unit"
                      />
                      <span>
                        {this.state.unitError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["unit"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>

                  <Col xs="6" className="block">
                    <FormGroup className="professional-search">
                      <Label for="Last Name">
                        City & State
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <span>
                        <div
                          className={
                            this.state.cities.length > 0
                              ? "default-city-filed finder city-finder"
                              : ""
                          }
                          onClick={() => {
                            // this.toggleOpenCity();
                          }}
                        >
                          <ReactAutocomplete
                            className="test"
                            items={[...this.state.cities]}
                            shouldItemRender={(item, value1) =>
                              item.label
                                .toLowerCase()
                                .indexOf(value1.toLowerCase()) > -1
                            }
                            getItemValue={(item) => item.value}
                            renderItem={(item, highlighted) => (
                              <div
                                className={
                                  this.state.value1.length >= 3 ? "list" : ""
                                }
                                key={item.id}
                                style={{
                                  backgroundColor: highlighted
                                    ? "#eee"
                                    : "transparent",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            // inputProps={{ placeholder: "City" }}
                            value={this.state.value1}
                            onChange={this.handleAutoloadCity.bind(this)}
                            onSelect={this.handleAutoLoadSelect.bind(this)}
                          />
                        </div>
                      </span>
                      <span>
                        {this.state.cityError === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["city"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  {/* <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="exampleSelect">State</Label>
                      <Input id="exampleSelect" name="select" type="select">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                  <Col
                    xs="6"
                    className="block widht-40 col-4 phone-input tel-block"
                  >
                    <FormGroup>
                      <Label for="Phone">
                        Telephone
                        {/* <span className="error" style={{ color: "red" }}>
                          *
                        </span> */}
                      </Label>
                      <NumberFormat
                        format="(###) ###-####"
                        mask=""
                        name="telephone"
                        placeholder="Phone Number"
                        onValueChange={this.inputChangedHandlerContact2}
                        value={this.state.telephone}
                        className="form-control"
                        ref="telephone"
                      />
                      <span>
                        {this.state.telephone_error === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["telephone"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="form-block">
                <h4>
                  Dates Supervisor Worked With HCP
                  {/* <span className = "error" style={{color: 'red'}}>*</span> */}
                </h4>
                <Row>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Last Name">
                        From
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <DatePicker
                        customInput={
                          <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                        className="form-control"
                        placeholderText="MM/DD/YYYY"
                        selected={this.state.startDateRange}
                        onChange={this.handleStartDate.bind(this)}
                        open={this.state.toggle1}
                        onClickOutside={this.openDatepickerFromDdate.bind(this)}
                        // minDate={new Date()}
                        // selectsStart
                        // showMonthDropdown
                        // showYearDropdown
                        autoComplete="off"
                        // onKeyDown={(e) => e.preventDefault()}
                        startDate={this.state.startDateRange}
                        endDate={this.state.endDateRange}
                        icon="iconsminds-calendar-4"
                      />
                      <figure className="date-icon">
                        <img
                          src={DateIcon}
                          onClick={this.openDatepickerFromDdate.bind(this)}
                        />
                      </figure>
                      {this.state.startDateError ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["fromdate"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6" className="block">
                    <FormGroup>
                      <Label for="Last Name">
                        To
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </Label>
                      <DatePicker
                        customInput={
                          <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                        className="form-control"
                        placeholderText="MM/DD/YYYY"
                        selected={this.state.endDateRange}
                        onChange={this.handleEndDate.bind(this)}
                        open={this.state.toggle2}
                        onClickOutside={this.openDatepickerToDate.bind(this)}
                        // minDate={new Date()}
                        // selectsStart
                        // showMonthDropdown
                        // showYearDropdown
                        autoComplete="off"
                        // onKeyDown={(e) => e.preventDefault()}
                        startDate={this.state.startDateRange}
                        endDate={this.state.endDateRange}
                        icon="iconsminds-calendar-4"
                      />
                      <figure className="date-icon">
                        <img
                          src={DateIcon}
                          onClick={this.openDatepickerToDate.bind(this)}
                        />
                      </figure>
                      <span>
                        {this.state.endDate_err === true ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["todate"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <div>
                        {this.state.correctDateError ? (
                          <span className="error" style={{ color: "red" }}>
                            End date should be greater than Start date{" "}
                          </span>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="form-block">
                <h4>
                  Is this person eligible for rehire?
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <FormGroup check>
                  <div>
                    {this.state.rehire.map((planet, index) => {
                      return (
                        <span className="form-action" id={index}>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="rehire"
                              onChange={this.handleRehire}
                              value={planet.value}
                              checked={planet.isChecked}
                              ref="rehire"
                            ></Input>
                            {planet.text}
                          </Label>
                        </span>
                      );
                    })}
                    <div>
                      {this.state.rehireError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["rehire"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </FormGroup>
                {/* <div className="check-sec">
                  {this.state.rehire.map((planet, index) => {
                    return (
                      <span className="form-action" id={index}>
                        <Input
                          type="checkbox"
                          name="rehire"
                          onChange={this.handleRehire}
                          value={planet.value}
                          checked={planet.isChecked}
                          ref="rehire"
                        ></Input>
                        <Label className="check-field">{planet.text}</Label>
                      </span>
                    );
                  })}
                  <div>
                    {this.state.rehireError === true ? (
                      <span className="error" style={{ color: "red" }}>
                        {this.state.errors["rehire"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
              </div>

              <div className="form-block">
                <h4>
                  Please rate the following: (Superior, Exceeds, Meets, Does Not Meet, N/A)
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </h4>
                <Table responsive borderless className="professional-form-list">
                  <thead>
                    <tr>
                      <th>Personal Evaluation</th>
                      <th>Superior</th>
                      <th>Exceeds</th>
                      <th>Meets</th>
                      <th>Does Not Meet </th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Documentation:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Accurately documented treatment and plans; Sought support and coaching for challenges
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="qualityOfWork"
                              checked={this.state.qualityOfWork == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Adaptability:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Applied knowledge, skills, and professional attitude
                          to implement individualized plan
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="quantityOfWork"
                              checked={this.state.quantityOfWork == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Communication:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Communicated effectively with individual and
                          interdisciplinary team
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="abilitytofollowdirections"
                              checked={
                                this.state.abilitytofollowdirections == value
                              }
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Competency:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Complied with site policies and procedures
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="dependability"
                              checked={this.state.dependability == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Safety/Emergency Protocol:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          : Promoted safety and adhered to
                          departmental policies and procedures
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="abilitytorelatetostaff"
                              checked={
                                this.state.abilitytorelatetostaff == value
                              }
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Initiative:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Identified problems and used available resources to take
                          appropriate action; Inquired about site policy when necessary; Kept
                          leader informed of any concerns with timely escalations
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="abilitytochange"
                              checked={this.state.abilitytochange == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Professionalism:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Respected other’s preferences, values, and needs;
                          Acted as an advocate for site, adhered to policies, and maintained
                          strict confidentiality
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="attendance"
                              checked={this.state.attendance == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Quality of Work:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Provided education and training based on
                          individuals needs and abilities; Coordinated education and training
                          with all disciplines involved in treatment and services; Demonstrated
                          application of knowledge and skills necessary for assignment
                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="punctuality"
                              checked={this.state.punctuality == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Reliability and Attendance:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Demonstrated regular and punctual
                          attendance; Followed site policies and procedures for absences

                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="prefessionalappearance"
                              checked={
                                this.state.prefessionalappearance == value
                              }
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th scope="row" style={{ lineHeight: "1" }}>
                        Teamwork/Cooperation:
                        <i
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {" "}
                          Worked effectively with the
                          interdisciplinary team

                        </i>
                      </th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="AbilitytorelatetoPatients"
                              checked={
                                this.state.AbilitytorelatetoPatients == value
                              }
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    {/* <tr>
                      <th scope="row">General Attitude</th>
                      {this.state.personalevaluation.map((value) => {
                        return (
                          <td>
                            <input
                              type="radio"
                              value={value}
                              name="GeneralAttitude"
                              checked={this.state.GeneralAttitude == value}
                              onChange={this.handleRadioChange}
                            />
                          </td>
                        );
                      })}
                    </tr> */}
                  </tbody>
                </Table>
                <span className="table-validation">
                  {this.state.radioboxError === true ? (
                    <span className="error" style={{ color: "red" }}>
                      {this.state.errors["radiobox"]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                <div className="block">
                  <FormGroup>
                    <Label for="Last Name">
                      Please tell us about any strengths or weaknesses you have
                      witnessed with this person
                      {/* <span className="error" style={{ color: "red" }}>
                        *
                      </span> */}
                    </Label>
                    <Input
                      id="strengths"
                      name="strengths"
                      type="textarea"
                      value={this.state.strengths}
                      onChange={(e) => this.handleChange(e)}
                      ref="strengths"
                    />
                    {/* <span>
                      {this.state.strengthsError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["strengths"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span> */}
                  </FormGroup>
                </div>
                <div className="block">
                  <FormGroup>
                    <Label for="Last Name">
                      Additional Comments
                      {/* <span className="error" style={{ color: "red" }}>
                        *
                      </span> */}
                    </Label>
                    <Input
                      id="comments"
                      name="comments"
                      type="textarea"
                      value={this.state.comments}
                      onChange={(e) => this.handleChange(e)}
                      ref="comments"
                    />
                    {/* <span>
                      {this.state.commentsError === true ? (
                        <span className="error" style={{ color: "red" }}>
                          {this.state.errors["comments"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span> */}
                  </FormGroup>
                  <div className="row">
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="Last Name">
                          Report Completed By
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="reportcompletedby"
                          value={this.state.reportcompletedby}
                          // placeholder="Last Name"
                          id="reportcompletedby"
                          onChange={(e) => this.handleChange(e)}
                          ref="reportcompletedby"
                        />
                        <span>
                          {this.state.reportcompletedbyError === true ? (
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["reportcompletedby"]}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs="6" className="block">
                      <FormGroup>
                        <Label for="Last Name">
                          Date
                          <span className="error" style={{ color: "red" }}>
                            *
                          </span>
                        </Label>
                        <DatePicker
                          customInput={
                            <MaskedInput
                              pipe={autoCorrectedDatePipe}
                              mask={[
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          selected={this.state.Date1}
                          onChange={this.handleStartDate1.bind(this)}
                          open={this.state.toggle}
                          onClickOutside={this.openDatepicker}
                          // onBlur={this.onBlur.bind(this)}
                          // minDate={0}
                          // selectsStart
                          // showMonthDropdown
                          // showYearDropdown
                          autoComplete="off"
                          // onKeyDown={(e) => e.preventDefault()}
                          // startDate={this.state.startDateRange}
                          // endDate={this.state.endDateRange}
                          icon="iconsminds-calendar-4"

                        // shouldCloseOnSelect={true}
                        // ref={(c) => this._calendar = c}
                        />
                        <figure className="date-icon">
                          <img src={DateIcon} onClick={this.openDatepicker} />
                        </figure>
                        {this.state.Date1Error ? (
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["date1"]}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Col>
                  </div>
                </div>
              </div>
            </Form>
            <Row className="justify-content-center submit-bttn">
              <Col md="12">
                <button
                  class="btn btn-next button"
                  title="Submit"
                  onClick={this.handleSubmit}
                  disabled={this.state.button_disable}
                  style={{ backgroundColor: "#334ca4" }}
                >
                  Submit{" "}
                  {this.state.isLoading ? (
                    <img
                      src={`${AppConstants.Bucket_URL}/bannerImages/loader.gif`}
                      alt="No Image"
                      style={{ width: 40, height: 40 }}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </Row>
            {this.state.errMsg}
            {this.state.popUp ? (
              <TimesheetPopup
                popup={this.state.popUp}
                handleTimesheetPopup={this.handleTimesheetPopup.bind(this)}
                message={this.state.message}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default Professionalreport;
