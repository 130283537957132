import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import AppConstants from "../AppConstants";

import {
  FaUserAlt,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaClock,
  FaGlobe,
} from "react-icons/fa";
import axios from "axios";
function Contracts(props) {
  const [toggle, setToggle] = useState(true);
  const [pastContractsData, setpastContractsData] = useState([]);
  const [currentContractsData, setCurrentContractsData] = useState([]);
  const [upcomingContractsData, setUpcomingContractsData] = useState([]);

  useEffect(() => {
    getContractsdata();
  }, []);

  async function getContractsdata() {
    let token = localStorage.getItem("token");
    let tempId = JSON.parse(localStorage.getItem("tempId"));
    let axiosConfig = {
      headers: {
        Authorization: token,
      },
    };

    const baseURL = AppConstants.API + "/nurse/getContracts/" + tempId;
    await axios
      .get(baseURL, axiosConfig)
      .then((response) => {
        setpastContractsData(response.data.data.pastContracts);
        setCurrentContractsData(response.data.data.currentContracts);
        setUpcomingContractsData(response.data.data.upcommingContracts);
      })
      .catch((err) => console.log(err));
  }
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div className="stories-main-block" id="contracts-tab">
      <Tabs
        defaultActiveKey="Completed"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Completed" title="Completed">
          <div className="row">
            {pastContractsData.length === 0 ? (
              <p className="upcoming-text">No Compeleted Contracts Found</p>
            ) : (
              pastContractsData.map((data) => (
                <div className="contracts-cont-grid col-sm-6">
                  <div className="contracts-cont-grid-in">
                    <h2>
                      <FaUserAlt />
                      <em>{data.clientName ? data.clientName : "N/A"}</em>
                    </h2>
                    <span>
                      <FaCalendarAlt />
                      <em>
                        {moment(data.dateStart).format("MM-DD-YYYY")} To{" "}
                        {moment(data.dateEnd).format("MM-DD-YYYY")}
                      </em>
                    </span>
                    <span>
                      <FaClock />
                      <em>{data.shiftName ? data.shiftName : "N/A"}</em>
                    </span>
                    <p>
                      <FaMapMarkerAlt />
                      <em>
                        {data.city} {data.state} {data.zipCode}
                      </em>
                    </p>
                    <p>
                      <FaGlobe />
                      <em>
                        {data.clientRegionName ? data.clientRegionName : "N/A"}
                      </em>
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </Tab>
        <Tab eventKey="On-Going" title="On-Going">
          <div className="row">
            {currentContractsData.length === 0 ? (
              <p className="upcoming-text">No On-Going Contracts Found</p>
            ) : (
              currentContractsData.map((data) => (
                <div className="contracts-cont-grid col-sm-6">
                  <div className="contracts-cont-grid-in">
                    <h2>
                      <FaUserAlt />
                      <em>{data.clientName}</em>
                    </h2>
                    <span>
                      <FaCalendarAlt />
                      <em>
                        {moment(data.dateStart).format("MM-DD-YYYY")} To{" "}
                        {moment(data.dateEnd).format("MM-DD-YYYY")}
                      </em>
                    </span>
                    <span>
                      <FaClock />
                      <em>{data.shiftName}</em>
                    </span>
                    <p>
                      <FaMapMarkerAlt />
                      <em>
                        {data.city} {data.state} {data.zipCode}
                      </em>
                    </p>
                    <p>
                      <FaGlobe />
                      <em>{data.clientRegionName}</em>
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </Tab>
        <Tab eventKey="Upcoming" title="Upcoming">
          <div className="row">
            {upcomingContractsData.length === 0 ? (
              <p className="upcoming-text">No Upcoming Contracts Found</p>
            ) : (
              upcomingContractsData.map((data) => (
                <div className="contracts-cont-grid col-sm-6">
                  <div className="contracts-cont-grid-in">
                    <h2>
                      <FaUserAlt />
                      <em>{data.clientName}</em>
                    </h2>
                    <span>
                      <FaCalendarAlt />
                      <em>
                        {moment(data.dateStart).format("MM-DD-YYYY")} To{" "}
                        {moment(data.dateEnd).format("MM-DD-YYYY")}
                      </em>
                    </span>
                    <span>
                      <FaClock />
                      <em>{data.shiftName}</em>
                    </span>
                    <p>
                      <FaMapMarkerAlt />
                      <em>
                        {data.city} {data.state} {data.zipCode}
                      </em>
                    </p>
                    <p>
                      <FaGlobe />
                      <em>{data.clientRegionName}</em>
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Contracts;
