import React, { Component } from "react";
import images from "../Components/images";
import Subscribeblock from "../Components/subscribe-block";
// import Destination from "../Components/destination-block";
import { Container, Row, Col } from "reactstrap";
import styles from "../Styles/about.css";
import axios from "axios";
import AppConstants from "../AppConstants";
import { Helmet } from "react-helmet";
import { JSONLD, Generic  } from "react-structured-data";
class OurApproach extends React.Component {
  constructor() {
    super();
    this.state = {
      content: "",
      image: "",
      metaTitle: "",
      metaDescription : "",
      metaTags: [],
      ogTitle: "",
      ogDescription: "",
      altImgName: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(AppConstants.CMS_API + "/approach/TotalApproachContent")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.Status === "Success" && data.data[0]) {
          this.setState({
            content: data.data[0].content,
            image: data.data[0].image1,
            metaTitle: data.data[0].metaTitle,
            metaDescription: data.data[0].metaDescription,
            metaTags: data.data[0].metaTags,
            ogTitle: data.data[0].ogTitle,
            ogDescription: data.data[0].ogDescription,
            altImgName: data.data[0].altImgName,
          });
        }
      });
  }
  render() {
    console.log(this.props)
    return (
      <div className="about-block approach-page ">
        <JSONLD>
          <Generic type="webpage" jsonldtype="Our Approach"
            schema={{
            name : this.state.metaTitle, 
            description: this.state.metaDescription,
            contentReferenceTime: new Date()
          }}
          >
          </Generic>
        </JSONLD>
        <Helmet>
          <title>{AppConstants.Approach}</title>
          <meta name="title" content={this.state.metaTitle}/>
          <meta name="description" content={this.state.metaDescription}/>

          <meta property="og:type" content="website"/>
          <meta property="og:url" content={AppConstants.Base_Url+"/our-approach"}/>
          <meta property="og:title" content={this.state.ogTitle}/>
          <meta property="og:description" content={this.state.ogDescription}/>
          <meta property="og:image" content={this.state.image}/>

          <meta property="twitter:card" content={this.state.image}/>
          <meta property="twitter:url" content={AppConstants.Base_Url+"/our-approach"}/>
          <meta property="twitter:title" content={this.state.metaTitle}/>
          <meta property="twitter:description" content={this.state.metaDescription}/>
          <meta property="twitter:image" content={this.state.image}/>
        </Helmet> 
       
        <div className="inner-block pad-tb">
          <Container>
            <h1 className="title">{AppConstants.Approach}</h1>
            <p className="text-center">{AppConstants.Sub_Approach}</p>
            <div className="inner-content">
              <Row>
                {this.state.content ? (
                  <Col xs="6" className="left-block block">
                    <div
                      className="content-box descrop"
                      dangerouslySetInnerHTML={{ __html: this.state.content }}
                    />
                  </Col>
                ) : (
                  ""
                )}
                {this.state.image ? (
                  <Col xs="6" className="right-block block">
                    <figure className="pic image-fill">
                      <img src={this.state.image} alt={AppConstants.Approach+ " - Travel Nurses Inc"}/>
                    </figure>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Container>
        </div>
        {/* <Destination destinationHistory={this.props} /> */}
        {/* <Subscribeblock /> */}
      </div>
    );
  }
}

export default OurApproach;
